import {
    AsyncFiltersChoicesAction, CLEAR_ASYNC_FILTERS, ClearAsyncFiltersAction,
    SET_ASYNC_FILTERS,
    SET_CHOICES,
    SET_CHOICES_WITH_SCROLL,
    SET_GLOBAL_CHOICES,
    SetAsyncFiltersAction,
    SetChoicesAction, SetGlobalFilterChoicesAction
} from './actions';
import { initialState } from './constants';
import { AsyncFiltersChoicesState } from './types';
import { FilterNames } from 'store/Filters/types';

export const asyncFiltersChoicesReducer = (
    state: AsyncFiltersChoicesState = initialState,
    action: AsyncFiltersChoicesAction
) => {
    switch (action.type) {
        case SET_CHOICES_WITH_SCROLL: {
            const setChoicesAction = <SetChoicesAction> action;
            const choices = [
                ...state[setChoicesAction.page][setChoicesAction.filterName].results,
                ...setChoicesAction.results,
            ];
            return {
                ...state,
                [setChoicesAction.page]: {
                    ...state[setChoicesAction.page],
                    [setChoicesAction.filterName]: {
                        ...state[setChoicesAction.page][setChoicesAction.filterName],
                        results: choices,
                    },
                },
            };
        }
        case SET_CHOICES: {
            const setChoicesAction = <SetChoicesAction> action;
            return {
                ...state,
                [setChoicesAction.page]: {
                    ...state[setChoicesAction.page],
                    [setChoicesAction.filterName]: {
                        ...state[setChoicesAction.page][setChoicesAction.filterName],
                        results: setChoicesAction.results,
                        count: setChoicesAction.count,
                    },
                },
            };
        }
        case SET_ASYNC_FILTERS: {
            const setAsyncChoicesAction = <SetAsyncFiltersAction> action;
            return {
                ...state,
                [setAsyncChoicesAction.pageName]: {
                    ...state[setAsyncChoicesAction.pageName],
                    [setAsyncChoicesAction.filterName]: {
                        ...state[setAsyncChoicesAction.pageName][setAsyncChoicesAction.filterName],
                        filter: setAsyncChoicesAction.filter,
                    },
                },
            };
        }
        case CLEAR_ASYNC_FILTERS:
            action = <ClearAsyncFiltersAction> action;

            let pageFilter;
            if (action.filterName) {
                pageFilter = {
                    ...state[action.pageName],
                    [action.filterName]: {
                        ...initialState[action.pageName][action.filterName]
                    }
                };
            } else {
                pageFilter = {
                    ...initialState[action.pageName]
                };
            }
            return {
                ...state,
                [action.pageName]: pageFilter
            };
        case SET_GLOBAL_CHOICES: {
            const setGlobalChoicesAction = <SetGlobalFilterChoicesAction> action;
            return {
                ...state,
                [FilterNames.global]: {
                    results: setGlobalChoicesAction.results,
                    count: setGlobalChoicesAction.count
                }
            };
        }
        default:
            return state;
    }
};
