import { AttributeModel, EntityDatasets, FundModel, NewFundModel } from 'store/Fund/models/fund';
import { ContactModel } from './models/contact';
import { FundFilterModel } from './models/filter';
import { Frequency } from 'store/Requests/types';
import { HistoryPeriodChoices } from 'store/constants';
import { Id, TableHeader } from 'store/types';
import { BenchmarkType } from 'store/Profiles/Groups/types';
import { DatasetWithRowType } from 'store/DataManager/models/dataset';

export interface DataReportingState {
    history: FundReportingHistory[];
}

export interface FundState {
    list: FundModel[];
    count: number;
    permissions: FundListPermissions;
    firmCount: number;
    filter: FundFilterModel;
    newFund: NewFundModel;
    fund: FundModel;
    contacts: ContactModel[];
    addingContactResult?: boolean;
    potentialFunds: any;
    dataReporting: DataReportingState;
    fundProfile: FundProfileState;
    datasets: DatasetWithRowType[];
    datasetsCount: number;
    datasetListIsUpdated: boolean;
    credit_officer: AttributeModel;
    product: AttributeModel;
    strategy: AttributeModel;
    fundListTemporaryHeaders: TableHeader[];
    dataEntries: DataEntryPart[];
    statistics: FundStatistics[];
    chartConfig: ChartConfig;
}

export interface FundReportingHistory {
    year: number;
    frequency: Frequency;
    num: number;
    shift: number;
}

export interface FundProfileState {
    historyPeriod: HistoryPeriodChoices;
    selectedPeriod: string | null;
}

export interface FundProfileTableData {
    multiCurrency: boolean;
    data: YearMonthData[];
}

export interface YearMonthData {
    year: string;
    months: MonthData[];
}

export interface MonthData {
    value: number | string | null;
    currency: string | null;
    dataEntry: DataEntry | null;
}

export type StatsValue = string | number | null;

export interface FundStatisticsDataset {
    data_entry: string;
    ending_balance: string;
    perf_month: string;
    perf_ytd: string | null;
    period: string;
    is_empty: boolean;
    currency: string[] | null;
}

export enum StatisticsDataType {
    Partial = 'partial',
    NoData = 'no_data',
    AllData = 'all_data',
}

export interface FundStatistics {
    id: string;
    name: string;
    currency: string;
    entity_type: BenchmarkType;
    datasets: EntityDatasets;
    volatility_ann: StatsValue;
    return_ann: StatsValue;
    drawdown_ann: StatsValue;
    data_type?: StatisticsDataType;
}

export enum FundStatus {
    Partial = 'partial',
    OnTime = 'on_time',
    Late = 'late',
    Overdue = 'overdue',
    AwaitingData = 'awaiting_data',
    NoRequest = 'no_request',
}

export interface DataEntryPart {
    data_entry_key: string;
    data_entry_count: number;
}

export enum FundAttributes {
    CreditOfficer = 'credit_officer',
    Product = 'product',
    Strategy = 'strategy',
    InternalId = 'internal_id'
}

export enum DataEntry {
    Requestor = 'requestor_account',
    AlphaPipe = 'alpha_pipe',
    FundManager = 'fund_manager',
}

export interface FundProfileFilterState {
    display_fund_data_source: boolean;
}

export interface TargetFund {
    id: Id;
    firm: string;
    fund: string;
    credit_officer?: string;
    strategy?: string;
    product?: string;
    internal_id?: string;
}

export interface PeerGroup {
    id: Id;
    name: string;
    updated_at?: string;
    fund_count: number;
    ending_balance: string | number | null;
    perf_monthly: string | number | null;
    currency: string;
}

export interface ChartConfig {
    start: number;
    end: number;
}

export enum GridConfigurationType {
    FundList = 'fund_list',
    GroupTargetSection = 'group_target_section',
}

export enum PermissionType {
    Read = 'read',
    Write = 'write',
    Act = 'act',
}

export interface DatasetPeriodPermission {
    [key: string]: {
        can_create_dataset: boolean;
        can_make_latest: boolean;
        can_edit_dataset: boolean;
    };
}

export interface FundPermissions {
    can_bulk_upload_datasets: boolean;
    can_create_contact: boolean;
    can_edit_contact: boolean;
    can_delete_contact: boolean;
    can_create_request: boolean;
    can_edit_request: boolean;
    can_send_request: boolean;

    periods: DatasetPeriodPermission | null;
}

export interface FundListPermissions {
    can_bulk_upload_funds: boolean;
    can_add_fund: boolean;
}
