import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { closeWhiteIcon } from 'store/constants';
import { BulkUploadProgress } from 'store/BulkUpload/types';

const styles = require('./BottomProgressBar.scss');

interface Props {
    progress: BulkUploadProgress;
    hide: () => void;
    uploadTypeName: string;
}

class SuccessPortfolioMessage extends React.PureComponent<Props> {
    render() {
        const {progress, uploadTypeName} = this.props;
        return (
            <div className="progressBlock">
                <div className={styles['progress-area']}>
                    <div className={styles['completed-process-text']}>
                        <span className={styles['success-message']}>
                            {uploadTypeName} Upload Complete
                        </span>
                        .
                        <span>
                            <NavLink
                                className={styles['link-to-file']}
                                to={`/portfolio/${progress.portfolio}/bulk-upload/${progress.id}`}
                                onClick={this.props.hide}
                            >
                                Click here
                            </NavLink>
                             to review the results.
                        </span>
                    </div>
                    <img
                        src={closeWhiteIcon}
                        className={styles['img-close']}
                        onClick={this.props.hide}
                    />
                </div>
            </div>
        );
    }
}

export default SuccessPortfolioMessage;
