import { DataManagerState } from './types';
import {
    SetDatasetDetailTemporaryFilterAction,
    SET_DATASET_DETAIL_TEMPORARY_FILTER,
    SET_DATASET_DETAIL_TEMPORARY_HEADERS,
    SetDatasetDetailTemporaryHeadersAction,
    SET_DATASET_LIST,
    SET_PUBLISHED_DATASETS,
    SET_PUBLISHED_DATASETS_WITH_SCROLL,
    SET_ALL_REQUESTORS_TOGGLE,
    SET_REQUESTOR_CHECKBOXES,
    SET_DAYS_BEFORE_DUE_DATE,
    SET_REQUESTORS_TO_CONFIRM_PUBLISH,
    SET_REQUESTORS_TO_SET_PENDING_PUBLISH,
    SET_DATASET_DETAIL,
    START_INVITING_ACL_USER,
    CHANGE_INVITING_ACL_USER,
    DELETE_ACL_USERS_SUCCESS,
    INVITE_ACL_USER_SUCCESS,
    SET_ROLE_ACL_USERS_SUCCESS,
    SET_ACL_USERS,
    SET_DAYS_BEFORE_DUE_DATE_FOR_EDIT_MODAL,
    SetDatasetListAction,
    SetAllRequestorsToggleAction,
    SetRequestorCheckboxesAction,
    SetDaysBeforeDueDateAction,
    SetRequestorsToConfirmPublishAction,
    SetRequestorsToSetPendingPublishAction,
    SetDatasetDetailAction,
    SetAclUsersAction,
    ChangeInvitingAclUserAction,
    DeleteAclUsersSuccessAction,
    InviteAclUserSuccessAction,
    SetRoleAclUsersSuccessAction,
    SetDaysBeforeDueDateForEditModalAction,
    SetUpdatingDatasetDetailFieldAction,
    SET_UPDATING_DATASET_DETAIL_FIELD,
    SET_DATASET_DETAIL_WITH_SCROLL,
    SetDatasetHistoryAction,
    SET_DATASET_HISTORY,
    SET_DATASET_LIST_WITH_SCROLL,
    SET_DATASET_FUND,
    SetDatasetFundAction,
    SET_DATASET_FUND_CLEAR,
    CLEAR_DATASET_HISTORY,
    SET_CURRENT_DATASET,
    SetCurrentDatasetAction,
    CLEAR_CURRENT_DATASET,
    SET_REQUESTORS,
    UPDATE_REQUEST,
    SET_PUBLISHED_DATASETS_TEMPORARY_HEADERS,
    SetPublishedDatasetsTemporaryHeadersAction,
    SetPublishedDatasetsAction,
    SET_IS_EDITING_DATASET,
    CLEAR_IS_EDITN_DATASET,
    SET_CHANGE_CACHED_STATUS_FOR_FUND,
    SetChangeCachedStatusForFundAction, SET_PENDING_REQUESTS, SET_PENDING_NOTIFICATION_FLAG, SetPendingRequestsAction,
    SetPendingNotificationFlagAction, DataManagerAction, SetIsEditingDatasetAction,
    SetRequestorsAction, UpdateRequestAction, SET_DATASET_FLAGS, SetFlags, SET_INTERNAL_DATA,
    SET_INTERNAL_DATA_WITH_SCROLL, SetInternalDataAction,
} from './actions';
import { initialState, initialInvitedUser } from './constants';
import { AclUser } from './models/aclUser';
import GlobalHelper from 'helpers/globalHelper';
import { CustomTableRow } from '../types';
import { Id } from '../types';

export const dataManagerReducer = (state: DataManagerState = initialState, action: DataManagerAction) => {
    let aclUsers: AclUser[], ids: Id[];
    switch (action.type) {
        case SET_DATASET_LIST:
            return {
                ...state,
                datasetList: (<SetDatasetListAction> action).datasetList,
                datasetListCount: (<SetDatasetListAction> action).datasetListCount,
            };
        case SET_DATASET_LIST_WITH_SCROLL:
            const datasetList = [
                ...state.datasetList,
                ...(<SetDatasetListAction> action).datasetList
            ];
            return {
                ...state,
                datasetList
            };
        case SET_INTERNAL_DATA:
            return {
                ...state,
                internalData: (<SetInternalDataAction> action).results,
                internalDataCount: (<SetInternalDataAction> action).count,
                permissions: (<SetInternalDataAction> action).permissions,
            };
        case SET_INTERNAL_DATA_WITH_SCROLL:
            const internalData = [
                ...state.internalData,
                ...(<SetInternalDataAction> action).results
            ];
            return {
                ...state,
                internalData
            };
        case SET_PUBLISHED_DATASETS:
            return {
                ...state,
                publishedDatasets: (<SetPublishedDatasetsAction> action).publishedDatasets,
                publishedDatasetsCount: (<SetPublishedDatasetsAction> action).publishedDatasetsCount,
            };
        case SET_PUBLISHED_DATASETS_WITH_SCROLL:
            const publishedDatasets = [
                ...state.publishedDatasets,
                ...(<SetPublishedDatasetsAction> action).publishedDatasets
            ];
            return {
                ...state,
                publishedDatasets
            };
        case SET_DATASET_DETAIL:
            const { datasetDetailCount, datasetDetail } = (<SetDatasetDetailAction> action);
            return {
                ...state,
                datasetDetail: [
                    ...datasetDetail
                ],
                datasetDetailCount
            };
        case SET_CHANGE_CACHED_STATUS_FOR_FUND:
            const  bstatus  = (<SetChangeCachedStatusForFundAction> action).status;

            return {
                ...state,
                datasetFund: {
                    ...state.datasetFund,
                    current_unpublished_dataset: {
                        ...state.datasetFund.current_unpublished_dataset,
                        cached_ds_status: bstatus
                    }
                }
            };
        case SET_DATASET_FUND:
            const datasetFund = (<SetDatasetFundAction> action).result;
            return {
                ...state,
                datasetFund: datasetFund
            };
        case SET_DATASET_FLAGS:
            return {
                ...state,
                datasetFund: {
                    ...state.datasetFund,
                    ...(<SetFlags> action).payload,
                }
            };
        case SET_DATASET_FUND_CLEAR:

            return {
                ...state,
                datasetFund: initialState.datasetFund
            };
        case SET_IS_EDITING_DATASET:

            return {
                ...state,
                editingRowNumber: (<SetIsEditingDatasetAction> action).row
            };
        case CLEAR_IS_EDITN_DATASET:

            return {
                ...state,
                editingRowNumber: new Date().getTime()
            };
        case CLEAR_DATASET_HISTORY:
            const clearDatasetState = GlobalHelper.clearRowType(state.datasetDetail);
            return {
                ...state,
                parentId: initialState.parentId,
                datasetDetail: [
                    ...clearDatasetState
                ]
            };
        case SET_DATASET_HISTORY:
            const { results, parentId } = (<SetDatasetHistoryAction> action);
            let childrenItems = GlobalHelper.setRowType(results, CustomTableRow.DatasetDetail);
            const clearState = GlobalHelper.clearRowType(state.datasetDetail);
            const parentIndex = GlobalHelper.getIndexInArray(clearState, parentId);

            clearState.splice(parentIndex + 1, 0, ...childrenItems);

            return {
                ...state,
                parentId: parentId,
                datasetDetail: [
                    ...clearState
                ]
            };
        case SET_DATASET_DETAIL_WITH_SCROLL:
            const data = (<SetDatasetDetailAction> action);
            return {
                ...state,
                datasetDetail: [
                    ...state.datasetDetail,
                    ...data.datasetDetail
                ]
            };
        case SET_UPDATING_DATASET_DETAIL_FIELD:
            const { payload } = (<SetUpdatingDatasetDetailFieldAction> action);

            return {
                ...state,
                datasetDetail: state.datasetDetail.map((item: any) => {
                    let answer = item;
                    if (item.id === payload.id) {
                        answer = {
                            ...item,
                            ...payload
                        };
                    }

                    return answer;
                }),
            };
        case SET_DATASET_DETAIL_TEMPORARY_FILTER:
            const { temporaryFilter } = (<SetDatasetDetailTemporaryFilterAction> action);
            return {
                ...state,
                temporaryFilter: {
                    ...state.temporaryFilter,
                    ...temporaryFilter
                }
            };
        case SET_DATASET_DETAIL_TEMPORARY_HEADERS: {
            const {temporaryHeaders} = (<SetDatasetDetailTemporaryHeadersAction> action);
            return {
                ...state,
                temporaryHeaders: [
                    ...temporaryHeaders
                ]
            };
        }
        case SET_PUBLISHED_DATASETS_TEMPORARY_HEADERS: {
            const {temporaryHeaders} = (<SetPublishedDatasetsTemporaryHeadersAction> action);
            return {
                ...state,
                publishedDatasetsTemporaryHeaders: [
                    ...temporaryHeaders
                ]
            };
        }
        case SET_CURRENT_DATASET:
            const { dataset } = (<SetCurrentDatasetAction> action);
            return {
                ...state,
                currentDataset: dataset
            };
        case CLEAR_CURRENT_DATASET:
            return {
                ...state,
                currentDataset: initialState.currentDataset
            };
        case SET_ACL_USERS:
            const {aclUsersCount} = (<SetAclUsersAction> action);
            aclUsers = (<SetAclUsersAction> action).aclUsers;
            return {
                ...state,
                aclUsers,
                aclUsersCount
            };
        case START_INVITING_ACL_USER:
            return {
                ...state,
                invitedAclUser: initialInvitedUser
            };
        case CHANGE_INVITING_ACL_USER:
            return {
                ...state,
                invitedAclUser: {
                    ...(<ChangeInvitingAclUserAction> action).invitedUser
                }
            };
        case INVITE_ACL_USER_SUCCESS:
            const {aclUser} = (<InviteAclUserSuccessAction> action);
            return {
                ...state,
                aclUsers: [
                    ...state.aclUsers,
                    aclUser
                ]
            };
        case DELETE_ACL_USERS_SUCCESS:
            ids = (<DeleteAclUsersSuccessAction> action).ids;
            return {
                ...state,
                aclUsers: state.aclUsers.filter((user: AclUser) => ids.indexOf(user.id) === -1)
            };
        case SET_ROLE_ACL_USERS_SUCCESS:
            aclUsers = (<SetRoleAclUsersSuccessAction> action).aclUsers;
            return {
                ...state,
                aclUsers: state.aclUsers.map((user: AclUser) => {
                    const updatedAclUser = aclUsers.find((updatedUser: AclUser) => updatedUser.id === user.id);

                    return updatedAclUser
                        ? {...user, ...updatedAclUser}
                        : user;
                })
            };
        case SET_REQUESTORS_TO_CONFIRM_PUBLISH:
            const { requestors } = (<SetRequestorsToConfirmPublishAction> action);
            return {
                ...state,
                confirmPublishModal: {
                    ...state.confirmPublishModal,
                    requestors,
                }
            };
        case SET_ALL_REQUESTORS_TOGGLE:
            const { toggleValue } = (<SetAllRequestorsToggleAction> action);
            return {
                ...state,
                confirmPublishModal: {
                    ...state.confirmPublishModal,
                    allRequestorsToggle: toggleValue,
                }
            };
        case SET_REQUESTOR_CHECKBOXES:
            const { requestorCheckboxes } = (<SetRequestorCheckboxesAction> action);
            return {
                ...state,
                confirmPublishModal: {
                    ...state.confirmPublishModal,
                    requestorCheckboxes,
                }
            };
        case SET_REQUESTORS_TO_SET_PENDING_PUBLISH:
            const requestorsForSchedule = (<SetRequestorsToSetPendingPublishAction> action).requestors;
            return {
                ...state,
                setPendingPublishModal: {
                    ...state.setPendingPublishModal,
                    requestors: requestorsForSchedule,
                }
            };
        case SET_DAYS_BEFORE_DUE_DATE:
            const { value } = (<SetDaysBeforeDueDateAction> action);
            return {
                ...state,
                setPendingPublishModal: {
                    ...state.setPendingPublishModal,
                    daysBeforeDueDate: value,
                }
            };
        case SET_DAYS_BEFORE_DUE_DATE_FOR_EDIT_MODAL:
            const daysBeforeDueDateValue = (<SetDaysBeforeDueDateForEditModalAction> action).value;
            return {
                ...state,
                editPendingPublishModal: {
                    ...state.editPendingPublishModal,
                    daysBeforeDueDate: daysBeforeDueDateValue,
                }
            };

        case SET_REQUESTORS:
            return {
                ...state,
                requestors: (<SetRequestorsAction> action).requestors
            };

        case UPDATE_REQUEST:
            const indexToUpdate = state.requestors.findIndex(
                item => item.id.toString() === (<UpdateRequestAction> action).request.id.toString()
            );

            let finalRequestors = [...state.requestors];
            const { acceptance_at, status, acceptance_by } = (<UpdateRequestAction> action).request;

            if (indexToUpdate !== -1) {
                finalRequestors[indexToUpdate] = {
                    ...finalRequestors[indexToUpdate],
                    acceptance_at,
                    status,
                    acceptance_by
                };
            }

            return {
                ...state,
                requestors: finalRequestors
            };
        case SET_PENDING_REQUESTS:
            const { count, fundId } = (<SetPendingRequestsAction> action);
            let newPendingRequestInfo;
            if (state.pendingRequests && !state.pendingRequests[fundId]) {
                newPendingRequestInfo = {
                    count,
                    notificationFlag: true,
                };
            } else {
                newPendingRequestInfo = {
                    ...state.pendingRequests[fundId],
                    count,
                };
            }
            return {
                ...state,
                pendingRequests: {
                    ...state.pendingRequests,
                    [fundId]: {
                        ...newPendingRequestInfo
                    }
                }
            };
        case SET_PENDING_NOTIFICATION_FLAG:
            const { flag } = (<SetPendingNotificationFlagAction> action);
            const fund = (<SetPendingNotificationFlagAction> action).fundId;
            return {
                ...state,
                pendingRequests: {
                    ...state.pendingRequests,
                    [fund]: {
                        ...state.pendingRequests[fund],
                        notificationFlag: flag,
                    },
                }
            };
        default:
            return state;
    }
};
