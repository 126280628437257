import { put, all, select } from 'redux-saga/effects';

import * as profilesApi from 'services/profiles';

import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { setIsLoading } from 'store/Filters/actionCreators';
import { getPeerGroupStatisticIds } from 'store/Fund/selectors';
import { FiltersState } from 'store/Filters/types';
import { AppState, Id } from 'store/types';

export function* getPlainPeerGroupList(
    actionType: string,
    filter: FiltersState,
    setResponseActionCreator: (response: any, withScroll: boolean) => any,
    selectedIdsSelector: (state: AppState) => Id[] = getPeerGroupStatisticIds
) {
    try {
        const withScroll = filter.scroller;

        yield put(initAction(actionType));
        if (withScroll) {
            yield put(setIsLoading(true));
        }
        const selectedIds = yield select(selectedIdsSelector);
        const response = yield profilesApi.getPeerGroups({...filter, selected_id: selectedIds});

        yield put(setResponseActionCreator(response, withScroll));

        if (withScroll) {
            yield put(setIsLoading(false));
        }

        yield put(doneActionSuccess(actionType));
    } catch (errors) {
        yield put(doneActionFail(actionType, errors));
        yield put(setIsLoading(false));
    }
}

export default function* root() {
    yield all(
        []
    );
}