import * as React from 'react';
import * as moment from 'moment';
import { match, RouteComponentProps } from 'react-router';
import { omit } from 'lodash';
import { cloneDeep } from 'lodash';

import GlobalHelper from 'helpers/globalHelper';
import { formatText } from 'helpers/tableHelper';

import UiDataTable from 'components/Shared/Ui/Tables/UiDataTable';
import DataTable from 'components/Shared/DataTable/DataTable';

import { Id, TableHeader, TableRowAction } from 'store/types';
import {
    DEFAULT_DATE_FORMAT, stateAdd,
    TableRowActionType,
    TableRowActionIcon,
    FormatTypeChoices,
    CellTypeChoices,
    Alignment,
    TypesFormat, currencyItems
} from 'store/constants';
import { CreateDatasetByPeriod, DatasetFund, DatasetRow } from 'store/DataManager/models/dataset';
import {
    DATASET_CONFIGURATION_MODAL, GetDatasetHistory, NAV_CONFIGURATION_MODAL, PERFORMANCE_CONFIGURATION_MODAL,
    UpdatingDatasetDetailField
} from 'store/DataManager/types';
import { datasetAlphaPipePermission, datasetDetailPermission, isPublished } from 'store/DataManager/datasetHelper';
import { headerNames, HeaderWidthObject } from 'store/VisibleHeaders/types';
import {
    CONFIRM_PUBLISH_MODAL, DATASET_UPLOAD_MODAL, SET_PENDING_PUBLISH_MODAL
} from 'store/DataManager/constants';

import DatasetConfigurationModal, { DATASET_VERSION, dataVersion }
from 'containers/DataManager/DatasetDetailModals/DatasetConfigurationModal';
import ConfirmPublishModal from 'containers/Shared/PublishModals/ConfirmPublishModal';
import SetPendingPublishModal from 'containers/Shared/PublishModals/SetPendingPublishModal';

import BulkUploadModal from 'containers/DataManager/BulkUploadModal';
import { DropDownItem } from 'components/Shared/Ui/DropDowns/DropDownPanel';
import Loader from 'components/Shared/Ui/Loader';
import ActiveUser from 'store/Auth/models/activeUser';
import { Dataset as DatasetType } from 'store/DataManager/models/dataset';
import { SetHeadersAction } from 'store/VisibleHeaders/actions';

import DatasetPanel from './Panels/DatasetPanel';

interface Props {
    user: ActiveUser;
    headers: TableHeader[];
    visibleHeadersCount: number;
    results: DatasetType[];
    fund: DatasetFund;
    count: number;
    match: match<{fundId: string}>;
    currentDataset: any;
    setFilters: any;
    openModal: (name: string, data: any) => void;
    currentFilter: any;
    isLoading: boolean;
    isGridLoading: boolean;
    isCreatingDatasetByPeriod: boolean;
    handleChange: (data: any) => void;
    handleColumns: (columns: any) => void;
    setHeaders: (block: string, headers: TableHeader[]) => SetHeadersAction;
    unlockDatasetRow: (datasetRow: DatasetRow, fundId: Id) => void;
    getDatasetHistory: (payload: GetDatasetHistory) => void;
    addDatasetToFund: (payload: CreateDatasetByPeriod) => void;
    updateDatasetDetailField: (payload: UpdatingDatasetDetailField) => void;
    confirmPublishDatasetFromDetail: (fundId: any, requestorIds: any) => any;
    setPendingPublishDatasetDetail: (fundId: Id, daysBeforeDueDate: number, requestorIds: Id[]) => any;
    checkedAll: boolean;
    checkedIds: Id[];
    chooseAll: () => void;
    chooseRow: (id: Id) => void;
    setHeadersWidth: (headerName: string, headerObject: HeaderWidthObject) => void;
    clearCurrentDataset: () => void;
    setIsEditingDataset: (rowId: any) => void;
    download: (checkedIds: Id[], checkedAll: boolean) => any;
    isDownloadAccessible: boolean;
    isUploadAccessible: boolean;
    isAddPeriodAccessible: boolean;
    isPublishAccessible: boolean;
    isScheduleAccessible: boolean;
    editingRowNumber: number;
    pendingRequestsCount: number;
    pendingNotificationsFlag: boolean;
    setPendingNotificationFlag: (fundId: Id, flag: boolean) => void;
}

const styles = require('./DataManager.scss');
export const clssNameNav = 'dataset-nav';
export const clssNamePerformance = 'dataset-performance';
const classOOSResponseDate = 'oos-response-date';
export const classVersion = 'version';

export const headers: TableHeader[] = [
    {
        name: 'period',
        title: 'Period',
        class: 'primary w-min',
        formatType: FormatTypeChoices.DateFormatShort,
        active: true,
        excludeEditField: () => { return true; },
        minWidth: 70,
        valueAlignment: Alignment.Right,
    },
    {
        name: 'beginning_balance',
        columnName: 'beginning_balance',
        title: 'Beginning Balance',
        extraTitle: 'Beginning Balance',
        valueAlignment: Alignment.Right,
        formatType: FormatTypeChoices.NumeralDefault,
        class: clssNameNav,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: true,
    },
    {
        name: 'subscriptions',
        columnName: 'subscriptions',
        title: 'Subscriptions',
        extraTitle: 'Subscriptions',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: true,
    },
    {
        name: 'redemptions',
        columnName: 'redemptions',
        title: 'Redemptions',
        extraTitle: 'Redemptions',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: true,
    },
    {
        name: 'income',
        columnName: 'income',
        title: 'Income',
        extraTitle: 'Income',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: true,
    },
    {
        name: 'ending_balance',
        columnName: 'ending_balance',
        title: 'Ending Balance',
        extraTitle: 'Ending Balance',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: true,
    },
    {
        name: 'known_future_redemptions',
        columnName: 'known_future_redemptions',
        title: 'Known Future Redemptions',
        extraTitle: 'Known Future Redemptions',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: false,
    },
    {
        name: 'nav_per_share',
        columnName: 'nav_per_share',
        title: 'NAV per Share',
        extraTitle: 'NAV per Share',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: false,
    },
    {
        name: 'perf_daily',
        columnName: 'perf_daily',
        title: 'Daily',
        extraTitle: 'Daily',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: false,
    },
    {
        name: 'perf_weekly',
        columnName: 'perf_weekly',
        title: 'Weekly',
        extraTitle: 'Weekly',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: false,
    },
    {
        name: 'perf_month',
        columnName: 'perf_month',
        title: 'Month',
        extraTitle: 'Month',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: true,
    },
    {
        name: 'perf_mtd',
        columnName: 'perf_mtd',
        title: 'MTD',
        extraTitle: 'MTD',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: false,
    },
    {
        name: 'perf_qtd',
        columnName: 'perf_qtd',
        title: 'QTD',
        extraTitle: 'QTD',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: false,
    },
    {
        name: 'perf_ytd',
        columnName: 'perf_ytd',
        title: 'YTD',
        extraTitle: 'YTD',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: true,
    },
    {
        name: 'currency',
        title: 'Currency',
        placeholder: 'currency',
        valueAlignment: Alignment.Right,
        class: classVersion,
        cellType: CellTypeChoices.Select,
        options: currencyItems,
        editable: true,
        active: true,
    },
    {
        name: 'data_version',
        title: 'Version',
        class: classVersion,
        cellType: CellTypeChoices.Select,
        options: dataVersion,
        editable: true,
        active: true,
        valueAlignment: Alignment.Right,
    },
];

class Dataset extends React.PureComponent<Props & RouteComponentProps<{}>>  {
    hasEditing = ((row: DatasetType) => datasetDetailPermission(this.props.fund) && !row.is_locked);

    headers: any = {
        beginning_balance: {
            hasEditing: this.hasEditing,
        },
        subscriptions: {
            hasEditing: this.hasEditing,
        },
        redemptions: {
            hasEditing: this.hasEditing,
        },
        income: {
            hasEditing: this.hasEditing,
        },
        ending_balance: {
            hasEditing: this.hasEditing,
        },
        known_future_redemptions: {
            hasEditing: this.hasEditing,
        },
        nav_per_share: {
            hasEditing: this.hasEditing,
        },
        perf_daily: {
            hasEditing: this.hasEditing,
        },
        perf_weekly: {
            hasEditing: this.hasEditing,
        },
        perf_month: {
            hasEditing: this.hasEditing,
        },
        perf_mtd: {
            hasEditing: this.hasEditing,
        },
        perf_qtd: {
            hasEditing: this.hasEditing,
        },
        perf_ytd: {
            hasEditing: this.hasEditing,
        },
        currency: {
            hasEditing: this.hasEditing,
        },
        data_version: {
            params: {
                optionsFunc: (options: any[], headerName: string, fieldPeriod: any) => {
                    if (headerName === 'data_version' && fieldPeriod) {
                        if (moment().diff(moment(fieldPeriod), 'days') < -1) {
                            return options.filter(item => item.value !== DATASET_VERSION.final);
                        }
                    }

                    return options;
                },
                field: 'period',
            },
            hasEditing: this.hasEditing,
        },
    };

    rowActions: TableRowAction[] = [
        {
            type: TableRowActionType.Image,
            icon: TableRowActionIcon.Discard,
            tooltip: 'Show/hide publish history',
            handler: (id: Id, row: DatasetType) => this.handleShowHistoryAction(id, row),
            isVisible: () => {
                return isPublished(this.props.fund) && datasetDetailPermission(this.props.fund);
            },
            hideClass: 'invisible-action',
        },
        {
            type: TableRowActionType.Image,
            icon: TableRowActionIcon.Locked,
            tooltip: 'Unlock dataset - Make amendments to a locked dataset',
            handler: (id: Id, row: DatasetType) => this.handleUnlockAction(id, {dataset: row, locked: false}),
            isVisible: (row: DatasetType) => {
                return datasetDetailPermission(this.props.fund) && !!row.is_locked;
            }
        },
        {
            type: TableRowActionType.Image,
            icon: TableRowActionIcon.Unlocked,
            tooltip:  'Lock dataset - Make dataset read-only to prevent accidental changes',
            handler: (id: Id, row: DatasetType) => this.handleUnlockAction(id, {dataset: row, locked: true}),
            isVisible: (row: DatasetType) => {
                return datasetDetailPermission(this.props.fund) && !row.is_locked;
            }
        },
    ];

    dropdownItemsCollection: DropDownItem[] = [];

    headerFields: any = [];

    defaultState = {
        queue: {},
        dataResults: [],
        headers: [],
        editingRow: { id: '', fund_ap_id: '', period: '' },
        isEditing: false,
        editingRowNumber: -1,
        headerFields: []
    };

    state = this.defaultState;

    componentDidMount() {
        this.setState({ dataResults: this.props.results});
        if (datasetAlphaPipePermission(this.props.user) &&
            !headers.find((item: TableHeader) => {
                return item.name === 'oos_response_date';
            })
        ) {
            headers.push( {
                name: 'oos_response_date',
                title: 'OOS Response Date',
                class: classOOSResponseDate,
                cellType: CellTypeChoices.Date,
                valueAlignment: Alignment.Right,
                formatType: FormatTypeChoices.DateFormat,
                editable: true,
                active: true,
                hasEditing: (row: DatasetType) => datasetAlphaPipePermission(this.props.user) && !row.is_locked,
                isVisible: (row: DatasetType) => datasetAlphaPipePermission(this.props.user),
            });
        }

        this.props.setHeaders(headerNames.datasetDetail, headers);
    }

    componentWillUnmount() {
        this.props.clearCurrentDataset();
    }

    componentWillReceiveProps(nextProps: Props) {
        let newState: any = {};

        if (!GlobalHelper.isEqualArrays(nextProps.results, this.props.results)) {
            newState = {
                dataResults: nextProps.results
            };
        }

        if (nextProps.editingRowNumber !== this.props.editingRowNumber) {
            newState = {
                ...newState,
                editingRowNumber: -1,
                isEditing: false
            };
        }

        if (nextProps.results !== this.props.results) {
            newState = {
                ...newState,
                headers: this._getHeaders(),
            };
        }

        this.dropdownItemsCollection = [
            { content: 'Configure',  handleClick: () => this.toggleDatasetConfigModal()},
            { divider: true },
            { content: 'Download Data', handleClick: () => this.handleDownload(),
                permission: nextProps.isDownloadAccessible,
            },
            { content: 'Upload Data', handleClick: () => this.handleBulkUpload(),
                permission: nextProps.isUploadAccessible,
            },
            { divider: true },
            {
                content: 'Publish',
                handleClick: () => this.handlePublish(),
                permission: nextProps.isPublishAccessible
            },
            {
                content: 'Schedule',
                handleClick: () => this.setPendingPublish(),
                permission: nextProps.isScheduleAccessible
            },
        ];

        this.setState({
            ...newState
        });
    }

    setPendingPublish = () => {
        this.props.openModal(SET_PENDING_PUBLISH_MODAL, {
            fundId: this.props.fund.id || 0,
            fundName: this.props.fund.name || '',
        });
    }

    handleEditAction = (id: Id) => {
        const chosenId = this.state.dataResults.findIndex((resultRow: any) => {
            return resultRow.id === id;
        });
        const chosenRow = this.state.dataResults.find((resultRow: any) => {
            return resultRow.id === id;
        });

        this.setState({ editingRow: chosenRow, isEditing: true, editingRowNumber: chosenId });
    }

    handleColumnChange = (value: any, name: string) => {
        const editingRow: any = cloneDeep(this.state.editingRow);

        editingRow[name] = value;

        const dataResults: any = this.state.dataResults.map((item: any) => {
            let answer = item;
            if (item.id === editingRow.id) {
                answer = {
                    ...editingRow
                };
            }

            return answer;
        });

        this.setState({editingRow, dataResults: dataResults});

        let currentQueue: any = {};

        // Todo Reafactoring after MVP
        if (this.state.queue.hasOwnProperty(editingRow.id)) {
            const potentialItem = this.state.queue[editingRow.id].find((item: any) => {
                return item.name === name;
            });

            if (!potentialItem) {
                currentQueue[editingRow.id] = [
                    ...this.state.queue[editingRow.id],
                    {name, value}
                ];
            } else {
                currentQueue[editingRow.id] = this.state.queue[editingRow.id].map((item: any) => {
                    let answer = item;

                    if (item.name === name ) {
                        answer = {
                            name,
                            value
                        };
                    }
                    return answer;
                });
            }
        } else {
            currentQueue = {
                [editingRow.id]: [
                    {
                        value, name
                    }
                ]
            };

            if (!this.props.fund.has_datasets) {
                currentQueue = {
                    newDataset: [
                        {
                            value, name
                        }
                    ]
                };
            }
        }

        this.setState(
            { queue: {...this.state.queue, ...currentQueue}},
            () => {
                if (['data_version', 'currency'].indexOf(name) >= 0) {
                    this.handleSendRequest();
                }
            }
        );
    }

    handleCreateDatasetByPeriod =  () => {
        const newPayload: CreateDatasetByPeriod = {
            fund_id: this.props.fund.id,
            fund_ap_id: this.props.fund.ap_id,
            period: this.props.fund.empty_period_for_creation,
        };

        if (datasetAlphaPipePermission(this.props.user)) {
            newPayload.oos_response_date = moment().format(DEFAULT_DATE_FORMAT);
        }

        this.props.addDatasetToFund(newPayload);
    }

    handleSendRequest = () => {

        // Todo Reafactoring after MVP
        if (!GlobalHelper.isEmptyData(this.state.queue)) {
            let removedItems: any[] = [];
            const keysElements = Object.keys(this.state.queue);
            keysElements.map((item: any) => {
                this.state.queue[item].map((fieldItem: any) => {

                    if (this.props.fund.has_datasets) {
                        const payload: UpdatingDatasetDetailField = {
                            fund_ap_id: this.state.editingRow.fund_ap_id,
                            id: item,
                            editableRow: {
                                [fieldItem.name]: fieldItem.value !== undefined ?
                                    GlobalHelper.replaceElemInString(
                                        fieldItem.value, /[.,\/#!$%\^&\*;:{}=_`~()]/g, '') : null
                            }
                        };
                        this.props.updateDatasetDetailField(payload);
                    }

                });
                removedItems.push(item);
            });
            this.setState({queue: omit(this.state.queue, removedItems)});
        }
    }

    handleUnlockAction = (id: Id, datasetRow: DatasetRow) => {
        this.setState({editingRowNumber: this.defaultState.editingRowNumber}, () => {
            this.props.unlockDatasetRow(datasetRow, this.props.fund.id);
        });

    }

    handleShowHistoryAction = (id: Id, row: DatasetType) => {
        const payload: GetDatasetHistory = {
            fundId: this.props.fund.id,
            parentId: id,
            parentObject: row
        };

        this.setState({editingRowNumber: this.defaultState.editingRowNumber}, () => {
            this.props.getDatasetHistory(payload);
        });
    }

    toggleDatasetConfigModal = () => {
        this.props.openModal(DATASET_CONFIGURATION_MODAL, {});
    }

    togglePerformanceConfigModal = () => {
        this.props.openModal(PERFORMANCE_CONFIGURATION_MODAL, {});
    }

    toggleNavConfiguration = () => {
        this.props.openModal(NAV_CONFIGURATION_MODAL, {});
    }

    handlePublish = () => {
        this.props.openModal(CONFIRM_PUBLISH_MODAL, {
            fundId: this.props.fund ? this.props.fund.id : 0,
            fundName: this.props.fund ? this.props.fund.name : '',
        });
    }

    handleBulkUpload = () => {
        this.props.openModal(DATASET_UPLOAD_MODAL, {});
    }

    handleDownload = () => {
        this.props.download([this.props.fund.id], false);
    }

    handleSetWidthHeaders = (headerObject: HeaderWidthObject) => {
        const visibleHeaders = this.props.headers
            .filter(hdr => hdr.active)
            .map(hdr => hdr.name);

        const header = this.headerFields.find(item => item.name === headerObject.name);

        if (!header) {
            this.headerFields.push(headerObject);
        } else {
            this.headerFields = this.headerFields.map(item => item.name === headerObject.name ? headerObject : item);
        }

        this.setState({
            headerFields: this.headerFields.filter(hdr => visibleHeaders.indexOf(hdr.name) >= 0)
        });
    }

    _getHeaders = (): TableHeader[] => headers.map((header: TableHeader) => {
        if (this.props.fund.requested_fields && this.props.fund.requested_fields.indexOf(header.name) === -1) {
            return {
                ...header,
                ...this.headers.hasOwnProperty(header.name) ? this.headers[header.name] : {}
            };
        } else {
            return {
                ...header,
                ...this.headers.hasOwnProperty(header.name) ? this.headers[header.name] : {},
                ...{requested: true},
            };
        }
    })

    publishDataset = (fundId: any, requestorIds: any) => {
        this.props.confirmPublishDatasetFromDetail(fundId, requestorIds);
    }

    setPendingSchedule = (fundId: Id, daysBeforeDueDate: number, requestorIds: Id[]) => {
        this.props.setPendingPublishDatasetDetail(fundId, daysBeforeDueDate, requestorIds);
    }

    render () {
        let CustomColumnDateNotFound: any = null;
        if (this.props.fund.empty_period_for_creation && this.props.isAddPeriodAccessible) {
            let contentForCreatingDatasetByPeriod: JSX.Element | null =  (
                <Loader
                    position={`center`}
                    isLoading={this.props.isCreatingDatasetByPeriod && !this.props.isGridLoading}
                />
            );

            if (!this.props.isCreatingDatasetByPeriod && !this.props.isGridLoading) {
                contentForCreatingDatasetByPeriod =  (
                    <button className="ui-button--clear" onClick={(event: any) => this.handleCreateDatasetByPeriod()}>
                        <img src={stateAdd} alt=""/>
                        <span className="ui-inlineBlock ui-pl-5">Click here to provide data for &nbsp;</span>
                        {formatText(this.props.fund.empty_period_for_creation, FormatTypeChoices.DateFormat)}
                    </button>
                );
            }

            CustomColumnDateNotFound = (
                <>
                    <tbody className="j-body_items">
                    <tr>
                        <td className="primary w-min text-right">
                            {formatText(this.props.fund.empty_period_for_creation, FormatTypeChoices.DateFormatShort)}
                        </td>
                        <td className="triangledBlueTd text-center" colSpan={this.props.visibleHeadersCount}>
                            {contentForCreatingDatasetByPeriod}
                        </td>
                    </tr>
                    </tbody>
                </>
            );

            if (this.props.isGridLoading) {
                CustomColumnDateNotFound = null;
            }
        }

        const customJsx = (
            <DatasetPanel
                fund={this.props.fund}
                dropdownItemsCollection={this.dropdownItemsCollection}
                headerFieldsWithWidth={this.state.headerFields}
                currentFilter={this.props.currentFilter}
                toggleNavConfigModal={this.toggleNavConfiguration}
                togglePerformanceConfigModal={this.togglePerformanceConfigModal}
                isDownloadAccessible={this.props.isDownloadAccessible}
                isUploadAccessible={this.props.isUploadAccessible}
                pendingRequestsCount={this.props.pendingRequestsCount}
                pendingNotificationsFlag={this.props.pendingNotificationsFlag}
                setPendingNotificationFlag={this.props.setPendingNotificationFlag}
            />
        );

        return (
            <div className={styles.dataset}>
                <UiDataTable className="table-text--center table-text--vertical-middle">
                    <DataTable
                        handleSetWidthHeaders={this.handleSetWidthHeaders}
                        handleClickDispatcher={this.toggleDatasetConfigModal}
                        count={this.props.count}
                        customContent={customJsx}
                        rows={this.state.dataResults}
                        headers={this.state.headers}
                        visibleHeaders={this.props.headers}
                        handleChange={this.props.handleChange}
                        hasPagination={true}
                        hasSorting={false}
                        currentFilter={this.props.currentFilter}
                        withCheckboxes={false}
                        rowActions={this.rowActions}
                        isLoading={this.props.isLoading}
                        isGridLoading={this.props.isGridLoading}
                        handleOnBlurInput={this.handleSendRequest}
                        handleOnFocusInput={this.handleEditAction}
                        editingRowNumber={this.state.editingRowNumber}
                        editingRow={this.state.editingRow}
                        handleColumnChange={this.handleColumnChange}
                        customDataNotFound={CustomColumnDateNotFound}
                        withoutNoDataFound={true}
                        checkedAll={this.props.checkedAll}
                        checkedIds={this.props.checkedIds}
                        chooseAll={this.props.chooseAll}
                        chooseRow={this.props.chooseRow}
                    />
                </UiDataTable>
                <ConfirmPublishModal
                    allowChooseRequestors={true}
                    customConfirmPublishDataset={this.publishDataset}
                />
                <BulkUploadModal />
                <DatasetConfigurationModal
                    handleColumns={this.props.handleColumns}
                    handleFilterChange={this.props.handleChange}
                    setFilters={this.props.setFilters}
                />
                <SetPendingPublishModal
                    customSetPendingPublishDataset={this.setPendingSchedule}
                />
            </div>
        );
    }
}

export default Dataset;
