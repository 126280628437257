import * as React from 'react';

import { FilterDecoratorChange } from 'decorators/FilterDecorator';
import Menu from 'components/Shared/Menu/Menu';
import MainHeader from 'components/Shared/Ui/Headers/MainHeader';
import { FilterItem, NavigationButton } from 'store/types';
import { Account } from 'store/Account/models/account';

const styles = require('./MainLayout.scss');

interface Props {
    menuItems: NavigationButton[];
    menuSubItems?: React.ReactNode[];

    title?: React.ReactNode;
    breadCrumbs?: any;
    filters?: FilterItem[];
    handleChange?: FilterDecoratorChange;
    currentFilter?: any;
    account?: Account;

    contentClassName?: string;
}

const MainLayout: React.FC<Props> = (props) => (
    <>
        <MainHeader
            title={props.title}
            filters={props.filters}
            handleChange={props.handleChange}
            currentFilter={props.currentFilter}
            account={props.account}
        >
            {props.breadCrumbs}
        </MainHeader>
        <div className={`${styles.contentWrapper} d-flex flex-column`}>
            {props.menuItems && props.menuItems.length
                ? <Menu items={props.menuItems} subItems={props.menuSubItems} />
                : ''
            }
            <div className={`${styles.content} ${props.contentClassName || ''}`}>
                {props.children}
            </div>
        </div>

    </>
);

export default MainLayout;
