import { fork, call, take } from 'redux-saga/effects';

export const takeFirst = (pattern, saga, ...args) => fork(function* () {
    while (true) {
        const action = yield take(pattern);

        // because of TS compiler error
        const callSaga: any = call;
        yield callSaga(saga, ...args.concat(action));
    }
});