import * as React from 'react';

import { Form, FormGroup } from 'reactstrap';
import Input from '../Ui/Input';
import { InvitedUser } from 'store/Shared/models/invitedUser';
import { SelectItem } from 'store/types';
import SelectFilter from '../Filters/SelectFilter';

interface Props {
    invitedUser: InvitedUser;
    roles: SelectItem[];
    roleIsPossible?: boolean;
    change: (field: string, value: string) => void;
}

const styles = require('./InviteUserForm.scss');

const InviteUserForm: React.FC<Props> = ({invitedUser, roles, roleIsPossible, change}) => {
    return (
        <Form>
            <FormGroup>
                <Input
                    id={`email`}
                    label={`Email`}
                    material={true}
                    type={`email`}
                    value={invitedUser.email}
                    placeholder={`Enter an email`}
                    handleChange={(value: string) => change('email', value)}
                    focused={true}
                />
            </FormGroup>

            <FormGroup>
                <Input
                    id={`first-name`}
                    label={`First Name`}
                    material={true}
                    type={`text`}
                    value={invitedUser.first_name}
                    placeholder={`Enter a first name`}
                    handleChange={(value: string) => change('first_name', value)}
                />
            </FormGroup>

            <FormGroup>
                <Input
                    id={`last-name`}
                    label={`Last Name`}
                    material={true}
                    type={`text`}
                    value={invitedUser.last_name}
                    placeholder={`Enter a last name`}
                    handleChange={(value: string) => change('last_name', value)}
                />
            </FormGroup>

            {
                roleIsPossible &&
                <FormGroup className={styles['role-selector']}>
                    <SelectFilter
                        name={`role`}
                        label={`Role`}
                        value={invitedUser.role ? invitedUser.role : ''}
                        placeholder={`Choose a role`}
                        handleChange={(chosenValue: any) => {
                            return change('role', chosenValue.role);
                        }}
                        options={roles.map((role, idx) => ({
                            value: role.value,
                            label: role.label,
                            key: role.value,
                        }))}
                        innerClassName={styles['role-select-dropdown']}
                        classNamePrefix="material-select"
                        customMenuPortalClass={{
                            zIndex: 9999,
                            fontSize: '14',
                        }}
                        clearable={true}
                    />
                </FormGroup>
            }
        </Form>
    );
};

InviteUserForm.defaultProps = {
    roleIsPossible: true
};

export default InviteUserForm;
