import * as React from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import * as classNames from 'classnames';

import { DATE_PICKER_DISPLAY_FORMAT } from 'store/constants';

interface Props {
    startDate: any;
    endDate: any;
    startTitle: any;
    endTitle: any;
    displayFormat?: string;
    handleChange: HandleDateChange;
}

export interface HandleDateChange {
    (start: any, end: any): void;
}

const styles = require('./DateRangePicker.scss');

class UiDateRangePicker extends React.PureComponent<Props> {

    static defaultProps = {
        displayFormat: DATE_PICKER_DISPLAY_FORMAT
    };

    state = {
        focusedInput: null
    };

    render() {
        const ClassNames = classNames({
            'ui-input': true,
            [styles.date_range_picker]: !!styles.date_range_picker,
        });
        return (
            <div className={ClassNames}>
                <DateRangePicker
                    displayFormat={this.props.displayFormat}
                    showClearDates={true}
                    startDatePlaceholderText={this.props.startTitle}
                    endDatePlaceholderText={this.props.endTitle}
                    startDateId={this.props.startTitle}
                    endDateId={this.props.endTitle}
                    isOutsideRange={() => false}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    onDatesChange={({ startDate, endDate }) => this.props.handleChange(startDate, endDate)}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput: any) => this.setState({ focusedInput })}
                />
            </div>
        );
    }
}

export default UiDateRangePicker;
