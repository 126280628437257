import { all, put, takeLatest } from 'redux-saga/effects';

import * as userApi from '../../services/user';
import { getUser, setUser } from '../Auth/actionCreators';
import { messages, UPGRADE_ADHOC_ACCOUNT_MODAL_NAME } from './constants';
import { doneActionFail, doneActionSuccess, initAction } from '../Actions/actionCreators';

import {
    UPDATE_PASSWORD, UPDATE_PROFILE, UPGRADE_ADHOC_ACCOUNT,
    UpdatePasswordAction, UpdateProfileAction, UpgradeAdhocAccountAction, InitUpgradeAdhocAccountAction,
    INIT_UPGRADE_ADHOC_ACCOUNT
} from './actions';
import { closeModal, openModal } from '../Modals/General/actionCreators';

function* updateProfile(action: UpdateProfileAction) {
    try {
        yield put(initAction(action.type));
        yield userApi.updateProfile(action.request);
        yield put(getUser());
        yield put(doneActionSuccess(action.type, messages.UPDATE_PROFILE_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.UPDATE_PROFILE_FAIL_MESSAGE));
    }
}

function* updatePassword(action: UpdatePasswordAction) {
    try {
        yield put(initAction(action.type));
        const user = yield userApi.updatePassword(action.request);
        yield put(doneActionSuccess(action.type, messages.UPDATE_PASSWORD_SUCCESS_MESSAGE));
        yield put(closeModal());
        yield put(setUser(user));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.UPDATE_PASSWORD_FAIL_MESSAGE));
    }
}

function* upgradeAdhocAccount(action: UpgradeAdhocAccountAction) {
    try {
        yield put(initAction(action.type));
        const user = yield userApi.upgradeAdhocAccount();
        yield put(doneActionSuccess(action.type, messages.UPGRADE_ADHOC_ACCOUNT_SUCCESS_MESSAGE));
        yield put(closeModal());
        yield put(setUser(user));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.UPGRADE_ADHOC_ACCOUNT_FAIL_MESSAGE));
    }
}

function* initUpgradeAdhocAccount(action: InitUpgradeAdhocAccountAction) {
    yield put(openModal(UPGRADE_ADHOC_ACCOUNT_MODAL_NAME, {}));
}

function* watchUpdateProfile() {
    yield takeLatest(UPDATE_PROFILE, updateProfile);
}

function* watchUpdatePassword() {
    yield takeLatest(UPDATE_PASSWORD, updatePassword);
}

function* watchUpgradeAdhocAccount() {
    yield takeLatest(UPGRADE_ADHOC_ACCOUNT, upgradeAdhocAccount);
}

function* watchInitUpgradeAdhocAccount() {
    yield takeLatest(INIT_UPGRADE_ADHOC_ACCOUNT, initUpgradeAdhocAccount);
}

export default function* root() {
    yield all([
        watchUpdateProfile(), watchUpdatePassword(), watchUpgradeAdhocAccount(), watchInitUpgradeAdhocAccount()
    ]);
}