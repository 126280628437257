import { connect } from 'react-redux';

import PublishedDatasetColumnsModal from 'components/DataManager/PublishedDatasetColumnsModal';

import { AppState } from 'store/types';
import { closeModal } from 'store/Modals/General/actionCreators';
import { getModalParams, isModalOpen } from 'store/Modals/General/selectors';
import { PUBLISHED_DATASETS_COLUMNS_MODAL } from 'store/DataManager/types';
import { getHeaders } from 'store/VisibleHeaders/selectors';
import { headerNames } from 'store/VisibleHeaders/types';
import { getPublishedDatasetsTemporaryHeaders } from 'store/DataManager/selectors';
import { setPublishedDatasetsTemporaryHeaders } from 'store/DataManager/actionCreators';

const mapStateToProps = (state: AppState) => {
    const modalParams = getModalParams(state) || {};
    return {
        temporaryHeaders: getPublishedDatasetsTemporaryHeaders(state),
        headers: getHeaders(state, {name: headerNames.publishedDatasets}),
        isOpen: isModalOpen(state, {name: PUBLISHED_DATASETS_COLUMNS_MODAL}),
        modalParams,
    };
};

const mapDispatchToProps = {
    closeModal,
    setPublishedDatasetsTemporaryHeaders
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishedDatasetColumnsModal);