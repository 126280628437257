import CreditApiHelper, { API_GLOBAL_SEARCH } from 'helpers/creditApiHelper';
import { SearchFilterChoicesSource } from '../store/AsyncFiltersChoices/types';

export const search = async (term: string, limit: number, sourceType: SearchFilterChoicesSource) => {
    let source;
    switch (sourceType) {
        case SearchFilterChoicesSource.FundList:
            source = 'funds';
            break;
        case SearchFilterChoicesSource.Dashboard:
        default:
            source = 'dashboard';
            break;
    }

    return CreditApiHelper.doRequest(
        `${API_GLOBAL_SEARCH}/${source}/suggestions/`,
        'get',
        {
            params: {
                search: term,
                limit: limit,
            }
        }
    ).then((response) => {
        return {
            ...response,
            results: response.results.map((r) => {
                return {
                    id: r.identification,
                    name: r.name,
                    type: r.entity_type,
                };
            })
        };
    });
};
