import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationButton } from 'store/types';

const styles = require('./Button.scss');

interface Props {
    button: NavigationButton;
}

const Button: React.FC<Props> = ({button}) => {
    let icon;
    if (!button.hasFont) {
        const iconSrc = require(`../../../assets/img/navicons/${button.icon}.svg`);
        icon = (
            <div className={`${styles.image}`}>
                <img src={iconSrc} width="40" alt={button.title}/>
            </div>
        );
    } else {
        icon = (
            <div className={`${styles.icon}`}>
                <span className={`icon-${button.icon}`}/>
            </div>
        );
    }
    const buttonContents = (
        <>
            {icon}
            <div className={`${styles.title}`}>
                {button.title}
            </div>
        </>
    );

    const target = button.inNewTab ? '_blank' : '';

    let linkProps = {
        to: button.path,
        activeClassName: styles.selected,
        className: `${styles.navButton} d-flex`,
        isActive: (match, location) => match,
        target,
    };

    if (button.hasOwnProperty('children')) {
        linkProps.isActive = (match, location) => {
            if (match) {
                return true;
            }

            return button.children &&
                !!button.children.find(child => location.pathname.indexOf(child.path) !== -1);
        };
    }

    // Use a standard anchor element when the button path is a full path
    return button.path.startsWith('http') ?
        (
            <a href={button.path} className={`${styles.navButton} d-flex`} target={target}>
                {buttonContents}
            </a>
        ) :
        (
            <NavLink {...linkProps}>
                {buttonContents}
            </NavLink>
        );
};

export default Button;
