import { Request } from './models/request';
import { Fund, FundRequest } from './models/fund';
import { ContactModel } from '../Fund/models/contact';
import { Id } from '../types';

export interface RequestEditorState {
    request: Request | null;
    contacts: ContactModel[];
    isEditMode: boolean;
}

export interface RequestsState {
    requests: Fund[];
    permissions: RequestListPermissions;
    managerScreenRequests: FundRequest[];
    managerScreenRequestsCount: number;
    requestsCount: number;
    pendingRequestsCount: number;
    pendingNotificationsFlag: boolean;
    editor: RequestEditorState;
}

export interface FundRequestRelation {
    fundId: Id;
    requestId: Id | null;
}

export interface GetListResponse {
    count: number;
    results: Request[];
    next: string;
    previous: string;
}

export enum Frequency {
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly'
}

export enum DueDateType {
    Calendar = 'calendar',
    Business = 'business'
}

export enum RequestType {
    Recurring = 'recurring',
    Adhoc = 'adhoc'
}

export enum RequestStatus {
    Pending = 'pending',
    Active = 'active',
    AwaitingApproval = 'awaiting_approval',
    Denied = 'denied',
    Inactive = 'inactive',
    Sending = 'sending',
    Archived = 'archived',
}

export enum RequestedField {
    BeginningBalance = 'beginning_balance',
    EndingBalance = 'ending_balance',
    Subscriptions = 'subscriptions',
    Redemptions = 'redemptions',
    KnownFutureRedemptions = 'known_future_redemptions',
    Income = 'income',
    NavPerShare = 'nav_per_share',
    PerfDaily = 'perf_daily',
    PerfWeekly = 'perf_weekly',
    PerfMonth = 'perf_month',
    PerfMtd = 'perf_mtd',
    PerfQtd = 'perf_qtd',
    PerfYtd = 'perf_ytd'
}

export interface RequestListPermissions {
    can_bulk_upload_requests: boolean;
    can_bulk_edit_requests: boolean;
    can_bulk_send_requests: boolean;
}

export interface RequestPermissions {
    can_send_request: boolean;
    can_edit_request: boolean;
    can_create_request: boolean;
}