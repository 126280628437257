import * as React from 'react';
import * as classNames from 'classnames';

import { FilterDecoratorChange } from 'decorators/FilterDecorator';
import { replaceParam } from 'helpers/tableHelper';
import { Id, TableBulkAction, TableHeader, TableRowAction } from 'store/types';
import { FiltersState } from 'store/Filters/types';

import Checkbox from '../Ui/Checkbox';
import HeaderColumn from './HeaderColumn';
import { defaultColumnMinWidth, checkboxColumnWidth } from './constants';
import { FixedElementType } from 'store/constants';

const styles = require('./DataTableHeader.scss');

interface Props {
    isFixed?: boolean;

    currentFilter?: FiltersState;
    headers: TableHeader[];
    countFixedColumns: number;
    visibleHeaders?: TableHeader[];
    hasSorting: boolean;
    checkedIds?: Id[];
    withCheckboxes: boolean;
    disabledCheckboxes?: boolean;
    rowActions?: TableRowAction[];
    checkedAll?: boolean;
    highlightHeaderOnCheck?: boolean;
    count?: number;
    actionsTitle?: string;
    textForBulkActions?: string | JSX.Element;
    showRowActionsTitle?: boolean;
    bulkActions?: TableBulkAction[];

    chooseAll?: () => void;
    handleSetWidthHeaders?: (headerObject: any) => void;
    handleChange?: FilterDecoratorChange;
    handleClickDispatcher?: any;

    isMainHeader: boolean;
    forwardedRef?: React.RefObject<HTMLTableRowElement>;

    possibleFixedElementClassName: string;
    fixedElementType: FixedElementType;

    editingRowCount?: number;
}

interface ActionProps {
    className: string;
    onClick: () => void;
    href?: string;
    type?: string;
}

class DataTableHeader extends React.PureComponent<Props> {
    static defaultProps = {
        isFixed: false,
        actionsTitle: 'Actions',
        editingRowCount: 0,
    };

    private keySalt: number;

    constructor(props: Props) {
        super(props);

        this.keySalt = Math.random();
    }

    get countSelectedItems() {
        const {checkedIds, checkedAll, count, editingRowCount} = this.props;
        if (checkedIds && count) {
            if (checkedAll) {
                return (checkedIds.length > 0)
                    ? (count - checkedIds.length - (editingRowCount || 0))
                    : count - (editingRowCount || 0);
            } else {
                if (checkedIds.length > 0) {
                    return checkedIds.length;
                }
            }
        }

        return 0;
    }

    get isAnyIdChecked() {
        const {checkedAll, checkedIds, count, editingRowCount} = this.props;
        const itemsCount = (count || 0) - (editingRowCount || 0);
        return (!checkedAll && checkedIds && checkedIds.length !== 0) ||
            (checkedAll && checkedIds && checkedIds.length !== itemsCount);
    }

    get highlightHeader() {
        return this.props.highlightHeaderOnCheck || this.props.highlightHeaderOnCheck === undefined;
    }

    render() {
        const {
            isFixed,
            headers, countFixedColumns, visibleHeaders, currentFilter, actionsTitle, textForBulkActions,
            hasSorting, checkedIds, withCheckboxes, disabledCheckboxes, rowActions,
            showRowActionsTitle, chooseAll, bulkActions, handleSetWidthHeaders, handleChange, handleClickDispatcher,
            forwardedRef, possibleFixedElementClassName, fixedElementType, isMainHeader, checkedAll
        } = this.props;

        const isAnyIdChecked = this.isAnyIdChecked;
        const countSelectedItems = this.countSelectedItems;
        let offset = withCheckboxes ? checkboxColumnWidth : 0;

        const trClassNames = classNames({
            ['hasSorting']: hasSorting,
            ['fixedRow']: isFixed
        });

        const checkboxThClassNames = classNames({
            ['checkboxHeader']: true,
            [styles['checkbox-fixed-header']]: countFixedColumns > 0 && !isAnyIdChecked && this.highlightHeader,
            [styles['fixed-style']]: countFixedColumns > 0,
            [styles['hide-column']]: this.props.isMainHeader && isAnyIdChecked && this.highlightHeader,
        });

        const theadClassNames = classNames({
            [styles.dataTableHeader]: true,
            is_action_bulk_checked: isAnyIdChecked && this.highlightHeader,
            [possibleFixedElementClassName]: fixedElementType === FixedElementType.TableHeader && !isMainHeader,
        });

        const colspanForBulkActions = (withCheckboxes && !rowActions || rowActions && !withCheckboxes)
            ? headers.length
            : headers.length + 1;

        const bulkActionsContent = this.renderBulkActionsContent(),
            lastFixedColumnIndex = this.lastFixedColumnIndex;

        return (
            <thead className={theadClassNames}>
                <tr className={trClassNames} ref={forwardedRef}>
                    {
                        withCheckboxes &&
                        <th
                            className={checkboxThClassNames}
                            style={{
                                width: checkboxColumnWidth,
                                minWidth: checkboxColumnWidth,
                                maxWidth: checkboxColumnWidth,
                            }}
                        >
                            <Checkbox
                                idx={'checkbox-header-' + this.keySalt}
                                value={`checkedAll`}
                                checked={checkedAll}
                                handleChange={chooseAll}
                                disabled={!!disabledCheckboxes}
                            />
                        </th>
                    }

                    {
                        isAnyIdChecked && !this.props.isMainHeader && this.highlightHeader &&
                        <th colSpan={colspanForBulkActions} className={'bulkActions'}>
                            <div>
                                {
                                    checkedIds && (
                                        <div className="text-primary items-selected">
                                            <strong>
                                                {countSelectedItems} {textForBulkActions}
                                                item{(countSelectedItems !== 1 ? 's ' : ' ')} selected
                                            </strong>
                                        </div>
                                    )
                                }
                                {
                                    bulkActions && bulkActionsContent
                                }
                            </div>
                        </th>
                    }

                    {
                        (!isAnyIdChecked || this.props.isMainHeader || !this.highlightHeader)
                        && headers.map((header, idx) => {
                            const headerWidth = header.minWidth || defaultColumnMinWidth;
                            let styleForHeader = {};
                            if (idx <= lastFixedColumnIndex) {
                                styleForHeader = {
                                    left: offset,
                                    width: headerWidth,
                                    minWidth: headerWidth,
                                    maxWidth: headerWidth,
                                };
                            }

                            offset += headerWidth;

                            return <HeaderColumn
                                key={idx + '-' + this.keySalt}
                                className={
                                    classNames({
                                        [styles['fixed-header']]: true,
                                        [styles['last-fixed-column']]: (idx === lastFixedColumnIndex),
                                        [styles['fixed-style']]: idx <= lastFixedColumnIndex,
                                        [styles['hide-column']]: this.props.isMainHeader && isAnyIdChecked
                                            && this.highlightHeader,
                                    })
                                }
                                style={styleForHeader}
                                visibleHeaders={visibleHeaders}
                                header={header}
                                handleSetWidthHeaders={handleSetWidthHeaders}
                                hasSorting={hasSorting}
                                currentFilter={currentFilter}
                                handleClick={handleChange}
                                handleClickDispatcher={handleClickDispatcher}
                                isMainHeader={isMainHeader}
                            />;
                        })
                    }

                    {
                        (!isAnyIdChecked || this.props.isMainHeader || !this.highlightHeader) && rowActions
                        && rowActions.length > 0 &&
                        <th className="action-cell">
                            {showRowActionsTitle && actionsTitle}
                        </th>
                    }
                </tr>
            </thead>
        );
    }

    private renderBulkActionsContent() {
        const {bulkActions} = this.props;

        if (!bulkActions) {
            return null;
        }

        return bulkActions.filter(action => action.isVisible).map((action: TableBulkAction, actionId: number) => {
            let props: ActionProps = {
                className: `${styles['bulk-action-link']} text-primary`,
                onClick: () => {
                    if (this.isAnyIdChecked) {
                        action.handler();
                    }
                }
            };

            if (action.link) {
                props = {
                    ...props,
                    href: replaceParam(action.link, this.props),
                    type: action.download ? 'download' : ''
                };
            }

            return (
                <a key={actionId} {...props}>
                    <strong>{action.type.replace('_', ' ')}</strong>
                </a>
            );
        });
    }

    private get lastFixedColumnIndex() {
        const {withCheckboxes, countFixedColumns} = this.props;

        return countFixedColumns - 1 - +withCheckboxes;
    }
}

export default DataTableHeader;
