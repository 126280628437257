import * as moment from 'moment';
import { CustomTableRow } from 'store/types';
import { FundState } from './types';
import {
    APPLY_FILTER, SET_FUNDS, SET_FUND, CHANGE_NEW_FUND_FIELD, SET_CONTACTS, SET_FUNDS_WITH_SCROLL, CLEAR_NEW_FUND_FIELD,
    ApplyFilterAction,
    SetFundsAction,
    SetFundAction,
    ChangeNewFundFieldAction,
    SetContactsAction, CHANGE_FUND_REQUEST, ChangeFundRequestAction,
    CHANGE_FUND, ChangeFundAction, SET_FUND_REPORTING_HISTORY, SetFundReportingHistoryAction, SET_FIRM_FROM_ERM,
    SetFirmsFromErmAction, CLEAR_FIRM_FROM_ERM, SET_FUND_PROFILE_HISTORY_PERIOD, SetFundProfileHistoryPeriodAction,
    CLEAR_FUND, APPLY_DATASETS_FILTER, ApplyDatasetsFilterAction, SET_DATASETS, SetDatasetsAction,
    SET_DATASETS_WITH_SCROLL, SET_FUND_DATASET_HISTORY, SetFundDatasetHistoryAction, CLEAR_FUND_DATASET_HISTORY,
    SET_FUND_LIST_TEMPORARY_HEADERS, SetFundListTemporaryHeadersAction, FundAction,
    ClearFundDatasetHistoryAction, SET_FUND_ATTRIBUTES, SetFundAttributesAction, SET_FUND_ATTRIBUTES_WITH_SCROLL,
    SET_FUNDS_DATA_ENTRIES, SetFundsDataEntriesAction, SET_ADDING_CONTACT_RESULT, UNSET_ADDING_CONTACT_RESULT,
    ALTER_FUND_PROFILE_STATISTICS, REMOVE_ENTITY_FROM_PROFILE_STATISTICS, RemoveEntityFromProfileStatisticsAction,
    AlterFundProfileStatisticsAction, SET_FUND_PROFILE_USER_SETTINGS, SetFundProfileUserSettingsAction,
    CHANGE_CHART_INTERVAL, ChangeChartIntervalAction, UpdateRequestorDatasetAction, UPDATE_REQUESTOR_DATASET,
    SELECT_PERIOD, SelectPeriodAction, CLEAR_SELECTED_PERIOD, SET_DATASET_LIST_IS_UPDATED,
    SetDatasetListIsUpdatedAction, UPDATE_REQUESTOR_AND_HISTORY_DATASET, UpdateRequestorAndHistoryDatasetAction,
} from './actions';
import { initialDetailedFundModel, initialState } from './constants';
import GlobalHelper from 'helpers/globalHelper';
import { PAGINATION_LARGE_LIMIT } from 'store/constants';
import { DatasetWithRowType } from 'store/DataManager/models/dataset';

export const fundReducer = (state: FundState = initialState, action: FundAction) => {
    switch (action.type) {
        case APPLY_FILTER:
            return {
                ...state,
                filter: (<ApplyFilterAction> action).filter
            };
        case APPLY_DATASETS_FILTER:
            return {
                ...state,
                filter: (<ApplyDatasetsFilterAction> action).filter
            };
        case SET_FUNDS: {
            return {
                ...state,
                list: (<SetFundsAction> action).funds,
                count: (<SetFundsAction> action).count,
                firmCount: (<SetFundsAction> action).firmCount,
                permissions: (<SetFundsAction> action).permissions,
            };
        }
        case SET_FUNDS_WITH_SCROLL: {
            const list = [
                ...state.list,
                ...(<SetFundsAction> action).funds
            ];
            return {
                ...state,
                list
            };
        }
        case SET_DATASETS:
            return {
                ...state,
                datasets: (<SetDatasetsAction> action).datasets,
                datasetsCount: (<SetDatasetsAction> action).count
            };
        case CLEAR_FUND:
            return {
                ...state,
                fund: {
                    ...initialDetailedFundModel
                }
            };
        case SET_FUND:
            return {
                ...state,
                fund: (<SetFundAction> action).fund
            };
        case SET_FUND_ATTRIBUTES_WITH_SCROLL:
            const attributes = [
                ...state[(<SetFundAttributesAction> action).attributeType].attributes,
                ...(<SetFundAttributesAction> action).results
            ];
            return {
                ...state,
                [(<SetFundAttributesAction> action).attributeType]: {
                    ...state[(<SetFundAttributesAction> action).attributeType],
                    attributes,
                },
            };
        case SET_FUND_ATTRIBUTES:
            return {
                ...state,
                [(<SetFundAttributesAction> action).attributeType]: {
                    countAttributes: (<SetFundAttributesAction> action).count,
                    attributes: (<SetFundAttributesAction> action).results
                },
            };
        case SET_FIRM_FROM_ERM:
            return {
                ...state,
                potentialFunds: (<SetFirmsFromErmAction> action).potentialFunds
            };
        case CLEAR_FIRM_FROM_ERM:
            return {
                ...state,
                potentialFunds: []
            };
        case CLEAR_FUND_DATASET_HISTORY: {
            const {id, period} = (<ClearFundDatasetHistoryAction> action);
            let pureState = state.datasets.filter((item: DatasetWithRowType) => !item.rowType
                || !(period && item.rowType === CustomTableRow.RequestorHistoryDataset && item.period === period)
            );
            const rowIndex = GlobalHelper.getIndexInArray(pureState, id);
            if (pureState.hasOwnProperty(rowIndex)) {
                pureState[rowIndex].activeRow = false;
            }
            return {
                ...state,
                datasets: [
                    ...pureState
                ]
            };
        }
        case SET_FUND_DATASET_HISTORY:
            const { results, parentId } = (<SetFundDatasetHistoryAction> action);
            let childrenItems = GlobalHelper.setRowType(results, CustomTableRow.RequestorHistoryDataset);
            const clearState = GlobalHelper.clearRowType(state.datasets);
            const parentIndex = GlobalHelper.getIndexInArray(clearState, parentId);

            clearState.splice(parentIndex + 1, 0, ...childrenItems);

            clearState[parentIndex] = {
                ...clearState[parentIndex],
                activeRow: true,
            };

            return {
                ...state,
                parentId: parentId,
                datasets: [
                    ...clearState
                ]
            };
        case SET_DATASETS_WITH_SCROLL:
            const datasets = [
                ...state.datasets,
                ...(<SetDatasetsAction> action).datasets
            ];
            return {
                ...state,
                datasets
            };
        case UPDATE_REQUESTOR_DATASET: {
            const dataset = (<UpdateRequestorDatasetAction> action).dataset;
            const updatedObjectIndex = state.datasets.findIndex(item => item.period === dataset.period);
            let newDatasets;
            if (updatedObjectIndex !== -1) {
                // update an existing row
                newDatasets = state.datasets.map(item => {
                    if (item.period === dataset.period) {
                        return {
                            ...item,
                            ...dataset
                        };
                    }
                    return item;
                });
                return {
                    ...state,
                    datasets: newDatasets,
                };
            } else {
                // inserting new row
                const datasetPeriod = dataset.period;
                let minPeriodDiff = PAGINATION_LARGE_LIMIT;
                let minPeriodIndex = 0;
                state.datasets.forEach((item, index) => {
                    const diff = Math.abs(moment(item.period).diff(moment(datasetPeriod), 'month', true));

                    if (diff < minPeriodDiff) {
                        minPeriodDiff = diff;
                        minPeriodIndex = index;
                    }
                });
                const indexToInsert = state.datasets && state.datasets[minPeriodIndex]
                    && moment(state.datasets[minPeriodIndex].period).diff(moment(datasetPeriod)) > 0
                        ? minPeriodIndex + 1
                        : minPeriodIndex;
                return {
                    ...state,
                    datasets: [
                        ...state.datasets.slice(0, indexToInsert),
                        dataset,
                        ...state.datasets.slice(indexToInsert)
                    ],
                    datasetsCount: state.datasetsCount + 1,
                };
            }
        }
        case UPDATE_REQUESTOR_AND_HISTORY_DATASET: {
            const {historyDataset, updatedDataset} = (<UpdateRequestorAndHistoryDatasetAction> action);
            const updatedObjectIndex = state.datasets.findIndex(
                item => item.period === historyDataset.period && !item.rowType
            );
            if (updatedObjectIndex !== -1) {
                const newDatasets = state.datasets.map(item => {
                    if (item.period === historyDataset.period && !item.rowType) {
                        return {
                            ...item,
                            ...updatedDataset
                        };
                    } else if (item.period === historyDataset.period
                        && item.rowType === CustomTableRow.RequestorHistoryDataset
                    ) {
                        return {
                            ...item,
                            is_latest: item.id === historyDataset.id,
                        };
                    }
                    return item;
                });
                return {
                    ...state,
                    datasets: newDatasets,
                };
            }
            return {...state};
        }
        case SET_CONTACTS:
            return {
                ...state,
                contacts: (<SetContactsAction> action).contacts
            };
        case SET_ADDING_CONTACT_RESULT:
            return {
                ...state,
               addingContactResult: true,
            };
        case UNSET_ADDING_CONTACT_RESULT:
            return {
                ...state,
                addingContactResult: false,
            };
        case CHANGE_NEW_FUND_FIELD:
            const {fieldName, fieldValue} = (<ChangeNewFundFieldAction> action);
            return {
                ...state,
                newFund: {
                    ...state.newFund,
                    [fieldName]: fieldValue
                }
            };
        case CLEAR_NEW_FUND_FIELD:
            return {
                ...state,
                newFund: {}
            };
        case CHANGE_FUND:
            const { field: fld, value: val } = (<ChangeFundAction> action);
            return {
                ...state,
                fund: {
                    ...state.fund,
                    [fld]: val
                }
            };
        case CHANGE_FUND_REQUEST:
            const { request } = state.fund;
            const { field, value } = (<ChangeFundRequestAction> action);
            return {
                ...state,
                fund: {
                    ...state.fund,
                    request: {
                        ...request,
                        [field]: value
                    }
                }
            };
        case SET_FUND_REPORTING_HISTORY:
            return {
                ...state,
                dataReporting: {
                    ...state.dataReporting,
                    history: (<SetFundReportingHistoryAction> action).history
                }
            };
        case SET_FUND_PROFILE_HISTORY_PERIOD:
            return {
                ...state,
                fundProfile: {
                    ...state.fundProfile,
                    historyPeriod: (<SetFundProfileHistoryPeriodAction> action).historyPeriod
                }
            };
        case SET_FUND_LIST_TEMPORARY_HEADERS:
            const { temporaryHeaders } = (<SetFundListTemporaryHeadersAction> action);
            return {
                ...state,
                fundListTemporaryHeaders: [
                    ...temporaryHeaders
                ]
            };
        case SET_FUNDS_DATA_ENTRIES: {
            const {data} = (<SetFundsDataEntriesAction> action);
            return {
                ...state,
                dataEntries: data
            };
        }
        case ALTER_FUND_PROFILE_STATISTICS: {
            const {data, initial} = (<AlterFundProfileStatisticsAction> action);

            const statistics = initial ? [] : [...state.statistics];
            data.forEach(stat => {
                let isExist = false;
                for (let i = 0; i < statistics.length; i++) {
                    if (stat.id === statistics[i].id) {
                        statistics[i] = stat;
                        isExist = true;
                        break;
                    }
                }
                if (!isExist) {
                    statistics.push(stat);
                }
            });

            return {
                ...state,
                statistics: [...statistics]
            };
        }
        case REMOVE_ENTITY_FROM_PROFILE_STATISTICS:
            const {removedEntityId} = (<RemoveEntityFromProfileStatisticsAction> action);

            return {
                ...state,
                statistics: state.statistics.filter(stat => stat.id !== removedEntityId)
            };
        case SET_FUND_PROFILE_USER_SETTINGS: {
            const {settings} = (<SetFundProfileUserSettingsAction> action);
            return {
                ...state,
                fund: {
                    ...state.fund,
                    fund_profile_user_settings: {
                        ...state.fund.fund_profile_user_settings,
                        ...settings
                    }
                }
            };
        }
        case CHANGE_CHART_INTERVAL: {
            const {start, end} = (<ChangeChartIntervalAction> action);

            return {
                ...state,
                chartConfig: {
                    ...state.chartConfig,
                    start, end
                }
            };
        }
        case SELECT_PERIOD: {
            const {period} = (<SelectPeriodAction> action);
            return {
                ...state,
                fundProfile: {
                    ...state.fundProfile,
                    selectedPeriod: period,
                }
            };
        }
        case CLEAR_SELECTED_PERIOD:
            return {
                ...state,
                fundProfile: {
                    ...state.fundProfile,
                    selectedPeriod: null,
                }
            };
        case SET_DATASET_LIST_IS_UPDATED:
            return {
                ...state,
                datasetListIsUpdated: (<SetDatasetListIsUpdatedAction> action).flag
            };
        default:
            return state;
    }
};
