import * as React from 'react';
import * as classNames from 'classnames';

interface Props {
    forwardedRef?: React.RefObject<HTMLTableRowElement>;
    className?: any;
    withoutMargin?: boolean;
}

const styles = require('./Wrapper.scss');
const UiWrapper: React.FC<Props> = ({children, forwardedRef, className, withoutMargin}) => {
    const classes = classNames('ui-wrapper', {
        [styles.wrapper]: true,
        [className]: !!className,
        [styles.wrapper_without_margin]: withoutMargin
    });

    return (
        <div className={classes} ref={forwardedRef} >
            {children}
        </div>
    );
};

export default UiWrapper;
