import { RuleExclusion, RulePeriod, RulesetStatus, RulesetType, RuleType } from './types';
import { SelectItem } from '../../types';

export const BULK_UPLOAD_NAME = 'rulesets';
export const BULK_UPLOAD_MODAL = 'BULK_UPLOAD_MODAL';

export const CONFIRM_DELETE_RULESET_MODAL = 'CONFIRM_DELETE_RULESET_MODAL';
export const CONFIRM_PUBLISH_RULESET_MODAL = 'CONFIRM_PUBLISH_RULESET_MODAL';
export const REQUESTOR_TARGET_FUND_LIST_MODAL = 'REQUESTOR_TARGET_FUND_LIST_MODAL';
export const RESPONDER_TARGET_FUND_LIST_MODAL = 'RESPONDER_TARGET_FUND_LIST_MODAL';

export const SAVE_RULESET_FAIL_MESSAGE = 'Ruleset saving failed';
export const PUBLISH_RULESET_SUCCESS_MESSAGE = 'The ruleset has been published';
export const PUBLISH_RULESET_FAIL_MESSAGE = 'Ruleset publishing failed';
export const DELETE_RULESET_SUCCESS_MESSAGE = 'The ruleset has been deleted';
export const DELETE_RULESET_FAIL_MESSAGE = 'Ruleset deletion failed';

export const defaultRulesetListPermissions = {
    can_bulk_upload_rulesets: true,
    can_create_ruleset: true,
};

export const defaultRulesetPermissions = {
    can_delete_ruleset: true,
    can_edit_ruleset: true,
    can_publish_ruleset: true,
};

export const initialState = {
    list: [],
    count: 0,
    permissions: {...defaultRulesetListPermissions},
    edit: {},
    editValidation: {},
    targetFundList: {
        list: [],
        count: 0,
        firmCount: 0,
    },
};

export const RULESET_TYPE: SelectItem[] = [
    {label: 'None', value: RulesetType.None},
    {label: 'ISDA', value: RulesetType.Isda},
    {label: 'FX', value: RulesetType.Fx},
    {label: 'Margin Loan', value: RulesetType.MarginLoan},
    {label: 'PB', value: RulesetType.Pb},
    {label: 'PB Margin', value: RulesetType.PbMargin},
];

export const RULESET_STATUS: SelectItem[] = [
    {label: 'Active', value: RulesetStatus.Active},
    {label: 'Inactive', value: RulesetStatus.Inactive},
    {label: 'Incomplete', value: RulesetStatus.Incomplete},
];

export const RuleTypeOptions = [
    {label: 'Starting Balance', value: RuleType.StartingBalance},
    {label: 'Peak Balance', value: RuleType.PeakBalance},
    {label: 'Perf', value: RuleType.Perf},
    {label: 'Floor', value: RuleType.Floor},
];

export const RuleExclusionOptions = [
    {label: 'None', value: RuleExclusion.None},
    {label: 'Redemptions', value: RuleExclusion.Redemptions},
    {label: 'Net Flow', value: RuleExclusion.NetFlow}
];

export const RuleTypeTitles = {
    [RuleType.Floor]: 'Floor',
    [RuleType.Perf]: 'Perf',
    [RuleType.PeakBalance]: 'Peak Balance',
    [RuleType.StartingBalance]: 'Starting Balance'
};

export const RuleExclusionsTitles = {
    [RuleExclusion.NetFlow]: 'Net Flow',
    [RuleExclusion.None]: 'None',
    [RuleExclusion.Redemptions]: 'Redemptions',
};

export const RulePeriodTitles = {
    [RulePeriod.OneMonth]: '1 Month',
    [RulePeriod.ThreeMonths]: '3 Months',
    [RulePeriod.SixMonths]: '6 Months',
    [RulePeriod.TwelveMonths]: '12 Months',
    [RulePeriod.ITD]: 'Amount',
    [RulePeriod.Other]: 'Other',
};

export const RulePeriodOptions = [
    {label: RulePeriodTitles[RulePeriod.OneMonth], value: RulePeriod.OneMonth},
    {label: RulePeriodTitles[RulePeriod.ThreeMonths], value: RulePeriod.ThreeMonths},
    {label: RulePeriodTitles[RulePeriod.SixMonths], value: RulePeriod.SixMonths},
    {label: RulePeriodTitles[RulePeriod.TwelveMonths], value: RulePeriod.TwelveMonths},
    {label: `ITD`, value: RulePeriod.ITD},
];

export const MonthsCountByPeriod = {
    [RulePeriod.OneMonth]: 1,
    [RulePeriod.ThreeMonths]: 3,
    [RulePeriod.SixMonths]: 6,
    [RulePeriod.TwelveMonths]: 12,
};