import * as React from 'react';

import { Action, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { find } from 'lodash';

import { AppState, Id } from 'store/types';
import { setFilters, setCheckedIds } from 'store/Filters/actionCreators';
import { FilterNames } from 'store/Filters/types';
import { getFilters, getIsLoading } from 'store/Filters/selectors';
import { DefaultEntityFilterState, messages } from 'store/DataManager/constants';
import {
    applyFilterAclUsers, startInvitingAclUser, deleteAclUsers, setRoleAclUsers
} from 'store/DataManager/actionCreators';
import { getAclUsers, getAclUsersCount } from 'store/DataManager/selectors';
import { openModal, closeModal } from 'store/Modals/General/actionCreators';
import { AclRole } from 'store/DataManager/types';
import ActionDecorator from 'decorators/ActionDecorator';
import FilterDecorator from 'decorators/FilterDecorator';

import ConfirmModal, { name as confirmModalName } from '../Shared/ConfirmModal';
import AclUsers, { Props as AclUsersProps } from 'components/DataManager/AclUsers';
import { name as inviteModalName } from './InviteAclUserModal';
import InviteAclUserModal from './InviteAclUserModal';
import { getModalParams } from 'store/Modals/General/selectors';
import MainLayout from '../../components/Shared/Layouts/MainLayout';

const filterName = FilterNames.aclUsers;

interface Props {
    modalParams: any;
    openModal: (name: string, params: any) => any;
    closeModal: () => any;
    deleteAclUsers: (fundId: Id, ids: Id[]) => void;
    setRoleAclUsers: (fundId: Id, ids: Id[], role: AclRole, closeModal: boolean, useFundIdForLoadList: boolean) => void;
}

class AclPendingUsersContainer extends React.PureComponent<Props & AclUsersProps> {
    getUserById(id: string) {
        return find(this.props.results, { id });
    }

    handleDelete = (id: Id) => {
        this.props.openModal(confirmModalName, {id});
    }

    handleDeleteYes = () => {
        const {id} = this.props.modalParams;

        const user = this.getUserById(id);

        if (user) {
            this.props.deleteAclUsers(user.fund.id, [id]);
        }
    }

    handleChangeRoles = (id: Id, checkedIds: Id[], role: AclRole) => {
        const user = this.getUserById(id);

        if (user) {
            this.props.setRoleAclUsers(user.fund.id, [id], role, true, false);
        }
    }

    render() {
        const confirmBody = messages.CONFIRM_DELETE_ACL_USER_QUESTION;
        const confirmTitle = messages.CONFIRM_DELETE_ACL_USER_TITLE;

        return (
            <MainLayout title="Fund Users" menuItems={[]}>
                <AclUsers
                    {...this.props}
                    withCheckboxes={false}
                    withFund={true}
                    remove={this.handleDelete}
                    changeRoles={this.handleChangeRoles}
                />
                <ConfirmModal
                    title={confirmTitle}
                    body={confirmBody}
                    chooseYes={this.handleDeleteYes}
                    chooseNo={this.props.closeModal}
                />
                <InviteAclUserModal />
            </MainLayout>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    results: getAclUsers(state),
    count: getAclUsersCount(state),
    currentFilter: getFilters(state, FilterNames.aclUsers),
    isLoading: getIsLoading(state),
    inviteIsPossible: false,
    editIsPossible: true,
    modalParams: getModalParams(state)
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        ...bindActionCreators(
            {
                openModal,
                closeModal,
                applyFilter: () => applyFilterAclUsers({}),
                setFilters,
                setCheckedIds,
                deleteAclUsers,
                setRoleAclUsers
            },
            dispatch
        ),
        ...{
            openInviteModal: () => {
                dispatch(startInvitingAclUser());
                dispatch(openModal(inviteModalName, {}));
            }
        }
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    (component: React.Component) => ActionDecorator(component, filterName),
    (component: React.Component) => FilterDecorator(component, {...DefaultEntityFilterState}, filterName)
)(AclPendingUsersContainer);