import {
    FieldValidation,
    Rule,
    RulePeriod,
    Ruleset,
    RulesetValidation,
    RuleType,
    RuleValidation
} from './types';

/*
 * 1. IF 'rule_type' is START_BAL or PEAK_BAL THEN
 *
 * 'rule_period' is required (valid values: 1M, 3M, 6M, 12M);
 * 'amount' is invalid value;
 * 'exclusion' and 'perf share' are valid but not required
 *
 * 2. IF 'rule_type' is FLOOR THEN
 *
 * 'rule_period' is required and ITD value is only valid;
 * 'amount' is valid value and required value;
 * 'exclusion' and 'perf share' are invalid
 *
 * IF 'rule_type' is PERF THEN
 *
 * 'rule_period' is required (valid values: 1M, 3M, 6M, 12M);
 * 'amount', 'exclusion' and 'perf share' are invalid values.
 *
 * ALWAYS
 * 'threshold' is always required
 *
 * */

export const isFieldValidOrUntouched = (validation: FieldValidation) => !!validation.valid || !validation.touched;

export const isFieldValidation = (field: string) => {
    return ['valid', 'validOrUntouched'].indexOf(field) < 0;
};

const periodValidator = (rule: Rule): FieldValidation => {
    let validValues = [
        RulePeriod.OneMonth,
        RulePeriod.ThreeMonths,
        RulePeriod.SixMonths,
        RulePeriod.TwelveMonths,
        RulePeriod.ITD
    ];

    if (rule.type) {
        validValues = rule.type === RuleType.Floor
            ? [RulePeriod.ITD]
            : [RulePeriod.OneMonth, RulePeriod.ThreeMonths, RulePeriod.SixMonths, RulePeriod.TwelveMonths];
    }

    return {
        valid: !!rule.period && validValues.indexOf(rule.period) >= 0,
        disabled: false,
        validValues
    };
};

const amountValidator = (rule: Rule) => {
    let valid = (rule.amount !== undefined && rule.amount !== null), disabled = false;

    if (rule.type && ([RuleType.StartingBalance, RuleType.PeakBalance, RuleType.Perf].indexOf(rule.type) >= 0)) {
        disabled = true;
        valid = false;
    }

    return {
        disabled, valid: (valid || disabled),
        validValues: null
    };
};

const exclusionsValidator = (rule: Rule) => {
    let valid = true, disabled = false;

    if (rule.type && ([RuleType.Floor, RuleType.Perf].indexOf(rule.type) >= 0)) {
        disabled = true;
        valid = false;
    }

    return { disabled, valid: (valid || disabled), validValues: null };
};

const perfShareValidator = (rule: Rule) => {
    let valid = true, disabled = false;

    if (rule.type && ([RuleType.Floor, RuleType.Perf].indexOf(rule.type) >= 0)) {
        valid = false;
        disabled = true;
    }

    return { disabled, valid: (valid || disabled), validValues: null };
};

const thresholdValidator = (rule: Rule) => {
    return {
        valid: rule.threshold !== undefined && rule.threshold !== null,
        disabled: false,
        validValues: null
    };
};

const typeValidator = (rule: Rule) => {
    return {
        valid: rule.type !== undefined && rule.type !== null,
        disabled: false,
        validValues: null
    };
};

export const validateRule = (rule: Rule): RuleValidation => {
    const validators = {
        period: [periodValidator],
        threshold: [thresholdValidator],
        type: [typeValidator],
        amount: [amountValidator],
        exclusions: [exclusionsValidator],
        perf_share: [perfShareValidator]
    };

    let ruleValidation: RulesetValidation = {};

    let valid = true, validOrUntouched = true;
    Object.keys(validators).forEach(field => {
        validators[field].forEach(validator => {
            let validation = validator(rule);
            valid = valid && !!validation.valid;
            validOrUntouched = validOrUntouched && isFieldValidOrUntouched(validation);

            // TODO: Implement merging of validation results
            ruleValidation[field] = validation;
        });
    });
    ruleValidation.valid = valid;
    ruleValidation.validOrUntouched = validOrUntouched;

    return ruleValidation;
};

export const validateRuleset = (ruleset: Ruleset): RulesetValidation => {
    let ruleValidations: RuleValidation[] = [];

    let valid = true, validOrUntouched = true;
    if (ruleset.rules) {
        ruleValidations = ruleset.rules.map((rule, idx) => {
            let validation = validateRule(rule);
            valid = valid && !!validation.valid;
            validOrUntouched = validOrUntouched && !!validation.validOrUntouched;
            return validation;
        });
    }

    return {
        valid, validOrUntouched,
        rules: ruleValidations
    };
};

export const mergeRuleValidations = (validation1: RuleValidation, validation2: RuleValidation) => {
    const resultValidation = {...validation1};

    Object.keys(validation2).forEach(field => {
        if (!isFieldValidation(field)) {
            resultValidation[field] = validation2[field];
        } else {
            if (resultValidation.hasOwnProperty(field)) {
                resultValidation[field] = {...resultValidation[field], ...validation2[field]};
            } else {
                resultValidation[field] = {...validation2[field]};
            }
        }
    });

    return resultValidation;
};