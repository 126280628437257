import { all } from 'redux-saga/effects';

import addItemsModalSagas from 'store/Modals/AddItems/sagas';

export default function* root() {
    yield all(
        [
            addItemsModalSagas(),
        ]
    );
}