import { ChipValueMappingType } from 'store/constants';
import { Id, SelectItem } from 'store/types';

export const FilterNames = {
    fundList: 'fund_list',
    fundDetail: 'fund_detail',
    firmList: 'firm_list',
    fundNew: 'firm_new',
    firmDetail: 'firm_detail',
    firmFundsList: 'firm_funds_list',
    firmProfileFunds: 'firm_profile_funds',
    portfolioList: 'portfolio_list',
    portfolioDetail: 'portfolio_detail',
    portfolioUploadedHistory: 'portfolio_uploaded_history',
    portfolioUploadedRows: 'portfolio_uploaded_rows',
    requestsList: 'request_list',
    requestManagerScreen: 'request_manager_screen',
    datasetList: 'dataset_list',
    aclUsers: 'acl_users',
    datasetData: 'dataset_data',
    datasetsTab: 'datasets_tab',
    publishedDatasets: 'published_datasets',
    usersList: 'users_list',
    internalDataTab: 'internal_data_tab',
    requestorDashboard: 'requestor_dashboard',
    requestorDashboardDispersion: 'requestor_dashboard_dispersion',
    rulesetList: 'ruleset_list',
    rulesetRequestorTargetFundList: 'ruleset_requestor_target_fund_list',
    rulesetResponderTargetFundList: 'ruleset_responder_target_fund_list',
    alertList: 'alert_list',
    alertView: 'alert_view',
    contactList: 'contact_list',
    requestorAddItemsModalFundList: 'requestor_add_items_modal_fund_list',
    responderAddItemsModalFundList: 'responder_add_items_modal_fund_list',
    requestorAddItemsModalPeerGroupsList: 'requestor_add_items_modal_peer_groups_list',
    fundProfile: 'fund_profile',
    profilesGroupList: 'profiles_group_list',
    profilesGroupTargetSection: 'profiles_group_target_section',
    profilesGroupBenchmarks: 'profiles_group_benchmarks',

    global: 'global'
};

export const AsyncFilterName = {
    fund: 'async_fund_list',
    firm: 'async_firm_list',
    portfolio: 'async_portfolio_list',
    creditOfficer: 'async_credit_officer_list',
    product: 'async_product_list',
    strategy: 'async_strategy_list',
    internalId: 'async_internal_id_list',
    rulesetName: 'async_ruleset_name_list',
    counterparty: 'async_counterparty_list',
};

export interface Chip {
    name: string;
    value: string;
    nameTitle?: string;
    valueTitle?: string;
}

export interface ChipSetting {
    name: string;
    nameTitle?: string;
    mapType?: ChipValueMappingType;
    // is set in case filter value is cleared using chip, set filterPageName to apply logic with emptyValue
    emptyValue?: any;
}

export interface FilterChipSettings {
    [key: string]: ChipSetting[];
}

export interface FiltersState {
    [key: string]: any;
}

export interface FilterInfo {
    filter: FiltersState;
    count: number;
    choices: SelectItem[];
}

export interface ItemWithId {
    id: Id;
    [key: string]: any;
}
