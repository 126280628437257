import { eventChannel } from 'redux-saga';
import {
    all,
    cancel,
    cancelled,
    takeEvery,
    put,
    take,
    fork,
    call
} from 'redux-saga/effects';

import socketHelper from '../../helpers/socketHelper';
import { SOCKET_CONNECT, SOCKET_DISCONNECT } from './actions';
import { socketClosed, socketError, socketOpened } from './actionCreators';
import { DO_APP_HEART_BEAT, DoAppHeartBeatAction } from '../actions';
import { getUser } from '../Auth/actionCreators';
import { MAX_ATTEMPTS_TO_CONNECT_BEFORE_CHECK_AUTH_STATUS } from './constants';

function initWebsocket() {
    return eventChannel(emitter => {
        socketHelper.connect();

        socketHelper.addEventListener(
            'open',
            () => emitter(socketOpened())
        );

        socketHelper.addEventListener(
            'error',
            (error: any) => emitter(socketError(error))
        );

        return () => {
            socketHelper.disconnect();
            return emitter(socketClosed());
        };
    });
}

function* connect() {
    const connection = yield call(initWebsocket);
    try {
        while (true) {
            const action = yield take(connection);
            yield put(action);
        }
    } finally {
        if (yield cancelled()) {
            connection.close();
        }
    }
}

let socketConnectAttempt = 0;
function* checkSocketConnection(action: DoAppHeartBeatAction) {
    if (socketHelper.isAvailableToConnect()) {
        socketConnectAttempt++;
        socketHelper.connect();

        if (socketConnectAttempt % MAX_ATTEMPTS_TO_CONNECT_BEFORE_CHECK_AUTH_STATUS === 0) {
            yield put(getUser());
        }
    } else {
        socketConnectAttempt = 0;
    }
}

function* subscribe() {
    const connectTask = yield fork(connect);
    yield take(SOCKET_DISCONNECT);
    yield cancel(connectTask);
}

function* watchForSubscribe() {
    yield takeEvery(SOCKET_CONNECT, subscribe);
}

function* watchForDisconnect() {
    yield takeEvery(SOCKET_DISCONNECT, () => {
        socketHelper.disconnect();
        return put(socketClosed());
    });
}

function* watchAppHeartBeat() {
    yield takeEvery(DO_APP_HEART_BEAT, checkSocketConnection);
}

export default function* root() {
    yield all([
        fork(watchForSubscribe),
        fork(watchForDisconnect),
        watchAppHeartBeat()
    ]);
}
