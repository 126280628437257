import * as React from 'react';
import * as moment from 'moment';
import { createSelector } from 'reselect';

import FundHelper from 'helpers/fundHelper';
import NumericHelper from 'helpers/NumericInputHelper';
import { formatText, joinListOfStrings } from 'helpers/tableHelper';
import { InfoTableData } from 'components/Shared/DataTable/InfoTable';
import {
    DataEntry, FundAttributes, FundProfileState, FundState, FundStatistics,
} from './types';
import { AppState, Id } from 'store/types';
import {
    FundAttributeModel,
    FundDetails,
    FundModel,
    ServiceProviders
} from './models/fund';
import {
    initialState,
    initialFundDetailsModel,
    initialUserSettings,
    COUNT_VISIBLE_YEARS_ON_PROFILE_PAGE,
    initialFundDatasets,
    defaultFundListPermissions,
} from './constants';
import { DEFAULT_VALUE_DASH, FormatTypeChoices } from '../constants';
import FundStatisticCalculator from 'store/Fund/helpers/statistics';
import { BenchmarkType } from 'store/Profiles/Groups/types';

interface Params {
    field: string;
}

export const getFundState = (state: AppState): FundState => state.funds || initialState;

export const getFundListPermissions = createSelector(
    getFundState,
    (state: FundState) => state.permissions || defaultFundListPermissions
);

export const getFieldParam = (state: AppState, params: Params) => params.field || 'ending_balance';

export const getSingleFundState = createSelector(
    getFundState,
    (state: FundState) => state.fund || initialFundDetailsModel
);

export const getSingleFundId = createSelector(
    getSingleFundState,
    (fund: FundModel) => fund.id || ''
);

export const getFundProfile = createSelector(
    getFundState,
    (state: FundState) => state.fundProfile
);

export const getFundProfileSelectedPeriod = createSelector(
    getFundProfile,
    (state: FundProfileState) => state.selectedPeriod
);

export const getDataReportingState = createSelector(
    getFundState,
    (state: FundState) => state.dataReporting
);

export const getDatasetsState = createSelector(
    getFundState,
    (state: FundState) => state.datasets
);

export const getDatasetsCountState = createSelector(
    getFundState,
    (state: FundState) => state.datasetsCount
);

export const getDatasetListIsUpdated = createSelector(
    getFundState,
    (state: FundState) => state.datasetListIsUpdated
);

export const getFundsDataEntry = createSelector(
    getFundState,
    (state: FundState) => state.dataEntries
);

export const getFundDetailsData = createSelector(
    getSingleFundState,
    (fund: FundModel): InfoTableData[] => {
        let creditOfficers, products, strategies, internalIds;
        const fundAttributes: FundAttributeModel[] = fund.fund_attributes;
        const fundDetails: FundDetails = fund.fund_details;

        if (fundAttributes) {
            creditOfficers = FundHelper.getAttrValues(fundAttributes, FundAttributes.CreditOfficer);
            products = FundHelper.getAttrValues(fundAttributes, FundAttributes.Product);
            strategies = FundHelper.getAttrValues(fundAttributes, FundAttributes.Strategy);
            internalIds = FundHelper.getAttrValues(fundAttributes, FundAttributes.InternalId);
        }

        let fundDetailsData: InfoTableData[] = [];
        if (fundDetails) {
            const dataSources = [
                {header: 'LEI:', field: 'lei'},
                {header: 'SEC:', field: 'sec'},
                {header: 'CIK:', field: 'cik'},
                {header: 'Series ID:', field: 'series_id'},
                {header: 'Class ID:', field: 'class_id'},
                {header: 'Ticker:', field: 'ticker'},
            ];

            dataSources.forEach((item: { header: string; field: string; }) => {
                let dataSource = fundDetails[item.field];
                if (dataSource && dataSource.length) {
                    fundDetailsData.push({
                        header: item.header,
                        value: joinListOfStrings(dataSource),
                        dataEntry: DataEntry.AlphaPipe,
                    });
                }
            });

            if (fundAttributes) {
                fundDetailsData.push({
                    header: 'Internal ID:',
                    value: joinListOfStrings(internalIds),
                    dataEntry: DataEntry.Requestor,
                });
                fundDetailsData.push({
                    header: 'Coverage:',
                    value: joinListOfStrings(creditOfficers),
                    dataEntry: DataEntry.Requestor,
                });
                fundDetailsData.push({
                    header: 'Product:',
                    value: joinListOfStrings(products),
                    dataEntry: DataEntry.Requestor,
                });
                fundDetailsData.push({
                    header: 'Strategy:',
                    value: joinListOfStrings(strategies),
                    dataEntry: DataEntry.Requestor
                });
            }
            fundDetailsData.push({
                header: 'Fund Type:',
                value: formatText(fundDetails.fund_type, FormatTypeChoices.ContainsUnderscore),
                dataEntry: DataEntry.AlphaPipe,
            });
            fundDetailsData.push({
                header: 'Domicile Country:',
                value: fundDetails.domicile_country,
                dataEntry: DataEntry.AlphaPipe,
            });
            fundDetailsData.push({
                header: 'Domicile State:',
                value: fundDetails.domicile_state,
                dataEntry: DataEntry.AlphaPipe,
            });
            fundDetailsData.push({
                header: 'Currency:',
                value: FundHelper.getCurrencyLabel(FundHelper.getCurrencyItem(fund.currency)),
                dataEntry: DataEntry.FundManager,
            });
            fundDetailsData.push({
                header: 'Number of Owners:',
                value: fundDetails.number_of_owners,
                dataEntry: DataEntry.AlphaPipe,
            });
            fundDetailsData.push({
                header: 'Form D File Number:',
                value: joinListOfStrings(fundDetails.form_file_number),
                dataEntry: DataEntry.AlphaPipe,
            });

            fundDetailsData.push({
                header: 'Min. Investment:',
                value: NumericHelper.getFormattedNumeral(
                    fundDetails.minimum_investment,
                    fund.currency,
                    true,
                    FormatTypeChoices.NumeralDefault,
                    DEFAULT_VALUE_DASH
                ),
                dataEntry: DataEntry.AlphaPipe,
            });
        }
        return fundDetailsData;
    }
);

export const getServiceProvidersData = createSelector(
    getSingleFundState,
    (fund: FundModel): InfoTableData[] => {
        const serviceProviders: ServiceProviders = fund.service_providers;
        let serviceProvidersData: InfoTableData[] = [];

        const serviceProvidersMap = [
            {header: 'Administrators:', field: 'administrator'},
            {header: 'Auditors:', field: 'auditor'},
            {header: 'Custodians:', field: 'custodian'},
            {header: 'Marketers:', field: 'marketers'},
            {header: 'Prime Brokers:', field: 'prime_broker'},
            {header: 'Directors:', field: 'director'},
            {header: 'Underwriters:', field: 'underwriter'},
            {header: 'Securities Lending Agents:', field: 'security_lending_agent'},
            {header: 'Collateral Managers:', field: 'collateral_manager'},
            {header: 'Pricing Services:', field: 'pricing_service'},
            {header: 'Shareholder Servicers:', field: 'shareholder_servicing_agent'},
            {header: 'Affiliated Broker Dealers:', field: 'broker_dealer'},
            {header: 'Top 10 Brokers:', field: 'top10_broker_dealer'},
            {header: 'Line of Credit Providers:', field: 'loc_provider'},
            {header: 'UIT Depositors:', field: 'uit_depositor'},
        ];

        if (serviceProviders) {
            serviceProvidersMap.forEach((item: { header: string; field: string; }) => {
                let fieldValue = serviceProviders[item.field];
                if (fieldValue && fieldValue.length) {
                    serviceProvidersData.push({
                        header: item.header,
                        value: joinListOfStrings(fieldValue),
                        dataEntry: DataEntry.AlphaPipe,
                    });
                }
            });
        }

        return serviceProvidersData;
    }
);

export const getFundListTemporaryHeaders = createSelector(
    getFundState,
    state => state.fundListTemporaryHeaders
);

export const getChartConfig = createSelector(
    getFundState,
    state => state.chartConfig
);

export const getFundStatisticsData = createSelector(
    getFundState,
    state => state.statistics
);

export const getFundStatistics = createSelector(
    getFundStatisticsData, getChartConfig,
    (statistics: FundStatistics[], interval) => {
        return statistics.map((item: FundStatistics) => {
            const statisticsFund = new FundStatisticCalculator(),
                statisticsItem = statisticsFund.getStatistics(item, interval.start, interval.end);

            item.volatility_ann = statisticsItem.volatility;
            item.return_ann = statisticsItem.return;
            item.drawdown_ann = statisticsItem.drawdown;
            item.data_type = statisticsItem.data_type;

            return item;
        });
    }
);

export const getFundStatisticIds = createSelector(
    getFundStatistics,
    (statistics: FundStatistics[]): Id[] => statistics
        .filter((fundStat: FundStatistics) => fundStat.entity_type
            && fundStat.entity_type === BenchmarkType.Fund)
        .map((fundStat: FundStatistics) => fundStat.id)
);

export const getPeerGroupStatisticIds = createSelector(
    getFundStatistics,
    (statistics: FundStatistics[]): Id[] => statistics
        .filter((fundStat: FundStatistics) => fundStat.entity_type
            && fundStat.entity_type === BenchmarkType.FundGroup)
        .map((fundStat: FundStatistics) => fundStat.id)
);

export const getAlertsOverviewState = createSelector(
    getSingleFundState,
    (fund: FundModel) => fund.alerts
);

export const getFundProfileSettings = createSelector(
    getSingleFundState,
    (fund: FundModel) => fund.fund_profile_user_settings || initialUserSettings
);

export const getYearMonthData = createSelector(
    getFundStatisticsData,
    getFieldParam,
    (statistics, field) => {
        let multiCurrency = false, currencies: Set<string> = new Set();

        if (!statistics.length) {
            return {multiCurrency, data: []};
        }

        const dataset = statistics[0].datasets;

        if (!dataset || !Array.isArray(dataset.history_datasets)) {
            return {multiCurrency, data: []};
        }

        const years = {};

        // Initial data filling
        const curYear = (new Date()).getFullYear();
        let y, m;
        for (y = curYear; y > (curYear - COUNT_VISIBLE_YEARS_ON_PROFILE_PAGE); y--) {
            years[y] = [];
            for (m = 12; m >= 1; m--) {
                years[y].push({
                    value: null,
                    dataEntry: null,
                    currency: null,
                });
            }
        }

        dataset.history_datasets.forEach(item => {
            const date = moment(item.period);

            const year = date.format('YYYY');
            const month = +date.format('M');

            if (!multiCurrency && item.currency) {
                item.currency.forEach(currency => currencies.add(currency));
                if (currencies.size > 1) {
                    multiCurrency = true;
                }
            }

            if (date.year() <= (curYear - COUNT_VISIBLE_YEARS_ON_PROFILE_PAGE)) {
                return;
            }

            if (!Array.isArray(years[year])) {
                years[year] = [];

                for (let i = 0; i < 12; i++) {
                    years[year].push({
                        value: '',
                        currency: ''
                    });
                }
            }

            years[year][(month - 1)].value = item[field];
            years[year][(month - 1)].currency = item.currency;
            years[year][(month - 1)].dataEntry = item.data_entry;
        });

        return {
            multiCurrency,
            data: Object.keys(years).sort((a, b) => a < b ? 1 : -1).map(key => {
                return {
                    year: key,
                    months: [...years[key]]
                };
            })
        };
    }
);

export const getMainFundDatasets = createSelector(
    getFundStatisticsData,
    (fundStatistics: FundStatistics[]) => fundStatistics && fundStatistics[0] && fundStatistics[0].datasets
        && !!fundStatistics[0].datasets.latest_dataset
            ? fundStatistics[0].datasets
            : initialFundDatasets
);
