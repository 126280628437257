import * as React from 'react';
import { BulkUploadProgress, UploadAction } from '../../../store/BulkUpload/types';
import {
    BULK_ITEM_NAME_PLURAL, BULK_ITEM_NAME_PLURAL_DEFAULT
} from '../../../store/BulkUpload/constants';

const styles = require('./ModalProgressBar.scss');

interface Props {
    progress: BulkUploadProgress;
}

class ModalProgressBar extends React.PureComponent<Props> {
    render() {
        const { progress } = this.props;
        const itemTitle = BULK_ITEM_NAME_PLURAL[progress.type]
            ? BULK_ITEM_NAME_PLURAL[progress.type]
            : BULK_ITEM_NAME_PLURAL_DEFAULT;
        const percent = progress.count_total !== 0
            ? progress.count_processed / progress.count_total * 100
            : 0;
        const actionName = progress.action === UploadAction.IMPORT
            ? 'Uploading'
            : 'Downloading';
        const percentTitle = `${Math.round(percent)}%`;
        return (
            <div className={styles['progress-area']}>
                <div className={styles['background-progress']}>
                    <div className={styles['done-progress']} style={{width: percentTitle}}/>
                </div>
                <div className={styles.notes}>
                    <div>{actionName} {progress.count_processed} of {progress.count_total} {itemTitle}</div>
                    <div>{percentTitle}</div>
                </div>
            </div>
        );
    }
}

export default ModalProgressBar;