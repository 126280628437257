import * as React from 'react';

const styles = require('./CenteredWindowLayout.scss');

class CenteredWindowLayout extends React.Component {
    render() {
        return (
            <div className={`${styles.contentWrapper} d-flex`}>
                <div className={`${styles.content} ${styles.alignerItemFixed}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default CenteredWindowLayout;