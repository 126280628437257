import { Action } from 'redux';

import ActiveUser from './models/activeUser';
import { Id } from 'store/types';
import UpdateTFARequest from 'store/Diligence/User/models/updateTFARequest';
import TwoFactorAuthSettings from './models/twoFactorAuthSettings';

export const LOGIN = 'store/diligence/user/login';
export const LOGOUT = 'store/diligence/user/logout';
export const GET_USER = 'store/diligence/user/getUser';
export const SET_USER = 'store/diligence/user/setUser';
export const GET_DILIGENCE_USER = 'store/diligence/user/getDiligenceUser';
export const SWITCH_ACCOUNT = 'store/diligence/user/switchAccount';
export const INITIALIZE_RESET_PASSWORD = 'store/diligence/user/initializeResetPassword';
export const RESET_PASSWORD = 'store/diligence/user/resetPassword';
export const DISABLE_TWO_FACTOR_AUTHENTICATION =
    'store/diligence/auth/disableTwoFactorAuthentication';
export const GENERATE_TFA_SECRET = 'store/diligence/auth/generateTFASecret';
export const SET_TFA_SECRET = 'store/diligence/auth/setTFASecret';
export const VERIFY_SETUP_CODE = 'store/diligence/auth/verifySetupCode';
export const SET_TWO_FACTOR_AUTH_SETTINGS = 'store/diligence/auth/setTwoFactAuthSettings';
export const CHANGE_REMEMBER_COOKIE = 'store/diligence/auth/changeRememberCookie';

export type GetUserAction = Action;
export type GetDiligenceUserAction = Action;

export interface SetUserAction extends Action {
    user: ActiveUser;
}

export interface SwitchAccountAction extends Action {
    relationId: Id;
}

export type LogoutAction = Action;
export type LoginAction = Action;

export interface InitializeResetPasswordAction extends Action {
    email: string;
}

export interface ResetPasswordAction extends Action {
    password: string;
    token: string;
    resetKey: string;
}

export interface UpdateTFAAction extends Action {
    request: UpdateTFARequest;
}

export interface GenerateTFASecretAction extends Action {
    tfaType: string;
    phone: string | null;
}

export interface SetTFASecretAction extends Action {
    secret: string;
    qrURI: string;
}

export interface VerifySetupCodeAction extends Action {
    user: ActiveUser;
    code: string;
}
export interface SetTwoFactorAuthSettingsAction extends Action {
    data: TwoFactorAuthSettings;
}

export interface DisableTwoFactorAuthAction extends Action {
    user: ActiveUser;
}

export interface ChangeRememberCookieAction extends Action {
    user: ActiveUser;
    remember: boolean;
}

export interface SetTwoFactorAuthSettingsAction extends Action {
    data: TwoFactorAuthSettings;
    user: ActiveUser;
}

export type AuthAction = GetUserAction | SetUserAction | SwitchAccountAction | LogoutAction | LoginAction |
    InitializeResetPasswordAction | ResetPasswordAction | SetTwoFactorAuthSettingsAction |
    UpdateTFAAction | GenerateTFASecretAction | SetTFASecretAction | VerifySetupCodeAction |
    DisableTwoFactorAuthAction | ChangeRememberCookieAction | SetTwoFactorAuthSettingsAction;