import * as React from 'react';
import { closeWhiteIcon } from 'store/constants';
import { BulkUploadProgress } from 'store/BulkUpload/types';

const styles = require('./BottomProgressBar.scss');

interface Props {
    progress: BulkUploadProgress;
    itemTitle: string;
    actionName: string;
    handleCloseClick: () => void;
}

class ProcessingMessage extends React.PureComponent<Props> {
    render() {
        const { progress, itemTitle, actionName, handleCloseClick } = this.props;
        const percent = progress.count_processed !== 0 ? progress.count_processed / progress.count_total * 100 : 0;
        const percentTitle = `${Math.round(percent)}%`;

        return (
            <div className="progressBlock">
                <div className={styles['progress-area']}>
                    <div className="text-div">
                        {actionName} {progress.count_processed} of {progress.count_total} {itemTitle}
                    </div>
                    <div className={styles['background-progress']}>
                        <div className={styles['done-progress']} style={{width: percentTitle}}/>
                    </div>
                    <div className="text-div">{percentTitle}</div>
                    <img
                        src={closeWhiteIcon}
                        className={styles['img-close']}
                        onClick={handleCloseClick}
                    />
                </div>
            </div>
        );
    }
}

export default ProcessingMessage;
