import {
    APPLY_FILTER,
    APPLY_DATASETS_FILTER,
    BULK_UPLOAD,
    DOWNLOAD_FUNDS,
    SET_FUNDS,
    CHANGE_NEW_FUND_FIELD,
    CREATE_NEW_FUND,
    GET_FUND_DATASET_HISTORY,
    SET_FUND_DATASET_HISTORY,
    CLEAR_FUND_DATASET_HISTORY,
    EXPORT_FUND_DATASETS,
    GET_FUND, SET_FUND, DELETE_CONTACTS, SET_CONTACTS, GET_CONTACTS, EDIT_CONTACT, ADD_CONTACT, SET_FUNDS_WITH_SCROLL,
    CLEAR_NEW_FUND_FIELD, DELETE_FUNDS,
    GET_FUND_REPORTING_HISTORY, GET_FIRM_FROM_ERM,
    SET_FIRM_FROM_ERM, CLEAR_FIRM_FROM_ERM, SET_FUND_PROFILE_HISTORY_PERIOD, SET_DATASETS,
    SET_FUND_LIST_TEMPORARY_HEADERS, SAVE_FUND_LIST_COLUMNS,
    SET_DATASETS_WITH_SCROLL, SET_FUND_ATTRIBUTES, GET_FUND_ATTRIBUTES, SET_FUND_ATTRIBUTES_WITH_SCROLL,
    GET_GRID_CONFIGURATION, GET_FUNDS_DATA_ENTRIES, SET_FUNDS_DATA_ENTRIES, GetFundsDataEntriesAction,
    SET_ADDING_CONTACT_RESULT, UNSET_ADDING_CONTACT_RESULT, ALTER_FUND_PROFILE_STATISTICS,
    REMOVE_ENTITY_FROM_PROFILE_STATISTICS, GET_FUND_PROFILE_STATISTICS, ADD_FUNDS_TO_PROFILE_STATISTICS,
    ADD_PEER_GROUPS_TO_PROFILE_STATISTICS, UPDATE_FUND_PROFILE_USER_SETTINGS, SET_FUND_PROFILE_USER_SETTINGS,
    CHANGE_CHART_INTERVAL, GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL, GET_FUND_LIST_FOR_ADD_ITEMS_MODAL,
    SAVE_REQUESTOR_DATASET, UPDATE_REQUESTOR_DATASET, SELECT_PERIOD, CLEAR_SELECTED_PERIOD, SET_DATASET_LIST_IS_UPDATED,
    MAKE_DATASET_THE_LATEST, UPDATE_REQUESTOR_AND_HISTORY_DATASET,
} from './actions';
import { FundAttributeModel, FundModel, NewFundModel, FundProfileUserSettingsModel } from './models/fund';
import { ContactModel, NewContactModel } from './models/contact';
import { DataEntryPart, FundStatistics, FundProfileFilterState, FundListPermissions } from './types';
import { HistoryPeriodChoices } from '../constants';
import { Id, TableHeader } from '../types';
import { FiltersState } from '../Filters/types';
import { APPLY_FILTER_FUND_PROFILE } from './actions';
import { SearchFilterChoicesSource } from '../AsyncFiltersChoices/types';
import { SEARCH_CHOICES } from '../AsyncFiltersChoices/actions';
import { Dataset, DatasetWithRowType } from 'store/DataManager/models/dataset';
import { defaultFundListPermissions } from './constants';

export const applyFilter = (filter: any) => ({
    type: APPLY_FILTER,
    filter
});

export const getGridConfiguration = (gridType: string, instanceId: Id) => ({
    type: GET_GRID_CONFIGURATION,
    gridType,
    instanceId
});

export const applyDatasetsFilter = (filter: FiltersState) => ({
    type: APPLY_DATASETS_FILTER,
    filter
});

export const getFundAttributes = (attributeType: string, filter: FiltersState) => ({
    type: GET_FUND_ATTRIBUTES,
    attributeType,
    filter,
});

export const setFundAttributes = (
    attributeType: string,
    count: number,
    results: FundAttributeModel[],
    withScroll = false
) => ({
    type: withScroll
        ? SET_FUND_ATTRIBUTES_WITH_SCROLL
        : SET_FUND_ATTRIBUTES,
    attributeType,
    count,
    results,
});

export const bulkUpload = (files: FormData) => ({
    type: BULK_UPLOAD,
    files
});

export const setDatasets = (count: number, datasets: Dataset[], withScroll = false) => ({
    type: withScroll
        ? SET_DATASETS_WITH_SCROLL
        : SET_DATASETS,
    count,
    datasets,
});

export const setFunds = (
    count: number,
    firmCount: number,
    funds: FundModel[],
    permissions: FundListPermissions = defaultFundListPermissions,
    withScroll = false
) => ({
    type: withScroll
        ? SET_FUNDS_WITH_SCROLL
        : SET_FUNDS,
    count,
    firmCount,
    funds,
    permissions,
});

export const getFirmFromErm = (filter: any) => ({
    type: GET_FIRM_FROM_ERM,
    filter
});

export const clearFirmFromErm = () => ({
    type: CLEAR_FIRM_FROM_ERM,
});

export const downloadFunds = (checkedIds: Id[], checkedAll: boolean) => ({
    type: DOWNLOAD_FUNDS,
    checkedIds,
    checkedAll,
});

export const setFirmFromErm = (potentialFunds: any) => ({
    type: SET_FIRM_FROM_ERM,
    potentialFunds
});

export const changeNewFund = (fieldName: string, fieldValue: string) => ({
    type: CHANGE_NEW_FUND_FIELD,
    fieldName,
    fieldValue,
});

export const createFund = (newFund: NewFundModel) => ({
    type: CREATE_NEW_FUND,
    newFund,
});
export const clearNewFund = () => ({
    type: CLEAR_NEW_FUND_FIELD,
});

export const getFund = (id: Id, withRequest: boolean = false) => ({
    type: GET_FUND,
    id, withRequest
});

export const getFundsDataEntries = (period?: string): GetFundsDataEntriesAction => ({
    type: GET_FUNDS_DATA_ENTRIES,
    period
});

export const getFundDatasetHistory = (id: Id, dataset: Dataset) => ({
    type: GET_FUND_DATASET_HISTORY,
    id,
    dataset,
});

export const setFundDatasetHistory = (parentId: Id, results: Dataset[]) => ({
    type: SET_FUND_DATASET_HISTORY,
    parentId,
    results,
});

export const clearFundDatasetHistory = (id: Id, period?: string) => ({
    type: CLEAR_FUND_DATASET_HISTORY,
    id,
    period,
});

export const exportFundDatasets = (id: Id) => ({
    type: EXPORT_FUND_DATASETS,
    id,
});

export const saveRequestorDataset = (dataset: Dataset) => ({
    type: SAVE_REQUESTOR_DATASET,
    dataset,
});

export const makeDatasetTheLatest = (dataset: DatasetWithRowType, fundId: Id) => ({
    type: MAKE_DATASET_THE_LATEST,
    dataset,
    fundId,
});

export const updateRequestorDataset = (dataset: Dataset) => ({
    type: UPDATE_REQUESTOR_DATASET,
    dataset,
});

export const updateRequestorAndHistoryDataset = (historyDataset: DatasetWithRowType, updatedDataset: Dataset) => ({
    type: UPDATE_REQUESTOR_AND_HISTORY_DATASET,
    historyDataset,
    updatedDataset,
});

export const deleteFunds = (ids: Id[], portfolioId: Id, checkedAll: boolean) => ({
    type: DELETE_FUNDS,
    ids,
    portfolioId,
    checkedAll,
});

export const setFund = (fund: FundModel) => ({
    type: SET_FUND,
    fund
});

export const getContacts = (fundId: Id) => ({
    type: GET_CONTACTS,
    fundId
});

export const addContact = (contact: NewContactModel) => ({
    type: ADD_CONTACT,
    contact,
});

export const setContacts = (contacts: ContactModel[]) => ({
    type: SET_CONTACTS,
    contacts
});

export const editContact = (fundId: Id, contactId: Id, contact: ContactModel) => ({
    type: EDIT_CONTACT,
    fundId,
    contactId,
    contact,
});

export const deleteContacts = (fundId: Id, contactIds: Id[]) => ({
    type: DELETE_CONTACTS,
    fundId,
    contactIds
});

export const getFundReportingHistory = (fundId: Id) => ({
    type: GET_FUND_REPORTING_HISTORY,
    fundId
});

export const setFundProfileHistoryPeriod = (historyPeriod: HistoryPeriodChoices) => ({
    type: SET_FUND_PROFILE_HISTORY_PERIOD,
    historyPeriod
});

export const setFundListTemporaryHeaders = (temporaryHeaders: TableHeader[]) => ({
    type: SET_FUND_LIST_TEMPORARY_HEADERS,
    temporaryHeaders
});

export const setFundsDataEntries = (data: DataEntryPart[]) => ({
    type: SET_FUNDS_DATA_ENTRIES,
    data
});

export const saveFundListColumns = (visibleHeaders: string[], instanceId: Id, gridType: string) => ({
    type: SAVE_FUND_LIST_COLUMNS,
    visibleHeaders,
    instanceId,
    gridType,
});

export const setAddingContactResult = () => ({type: SET_ADDING_CONTACT_RESULT});
export const unsetAddingContactResult = () => ({type: UNSET_ADDING_CONTACT_RESULT});

export const getFundStatistics = (fundId: Id) => ({type: GET_FUND_PROFILE_STATISTICS, fundId});

export const alterFundProfileStatistics = (data: FundStatistics[], initial: boolean = false) => ({
    type: ALTER_FUND_PROFILE_STATISTICS,
    data,
    initial,
});

export const removeEntityFromProfileStatistics = (
    baseFundId: Id,
    removedEntityId: Id,
    rememberSelected?: boolean
) => ({
    type: REMOVE_ENTITY_FROM_PROFILE_STATISTICS,
    baseFundId,
    removedEntityId,
    rememberSelected,
});

export const updateFundProfileUserSettings = (fundId: Id, settings: FundProfileUserSettingsModel) => ({
    type: UPDATE_FUND_PROFILE_USER_SETTINGS,
    fundId,
    settings,
});

export const setFundProfileUserSettings = (settings: FundProfileUserSettingsModel) => ({
    type: SET_FUND_PROFILE_USER_SETTINGS,
    settings,
});

export const addFundsToProfileStatistics = (baseFundId: Id, filterName: string, rememberSelected?: boolean) => ({
    type: ADD_FUNDS_TO_PROFILE_STATISTICS,
    baseFundId,
    filterName,
    rememberSelected,
});

export const addPeerGroupsToProfileStatistics = (baseFundId: Id, filterName: string, rememberSelected?: boolean) => ({
    type: ADD_PEER_GROUPS_TO_PROFILE_STATISTICS,
    baseFundId,
    filterName,
    rememberSelected,
});

export const applyFilterFundProfile = (filter: FundProfileFilterState) => ({
    type: APPLY_FILTER_FUND_PROFILE,
    filter
});

export const changeChartInterval = (start: number, end: number) => ({
    type: CHANGE_CHART_INTERVAL,
    start,
    end
});

export const getFundListForAddItemsModal = (filter: FiltersState) => ({
    type: GET_FUND_LIST_FOR_ADD_ITEMS_MODAL,
    filter,
});

export const getPeerGroupListForAddItemsModal = (filter: FiltersState) => ({
    type: GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL,
    filter,
});

export const searchFilterChoices = (
    query: string, filter: FiltersState, filterName: string
) => ({
    type: SEARCH_CHOICES,
    query,
    filter,
    filterName,
    source: SearchFilterChoicesSource.FundList
});

export const selectPeriod = (period: string) => ({
    type: SELECT_PERIOD,
    period,
});

export const clearSelectedPeriod = () => ({
    type: CLEAR_SELECTED_PERIOD,
});

export const setDatasetListIsUpdated = (flag: boolean) => ({
    type: SET_DATASET_LIST_IS_UPDATED,
    flag,
});