import * as React from 'react';

import { dataSourcesTypes, currencyItems } from 'store/constants';
import { ChooseType } from 'containers/DataManager/DatasetDetailModals/NavConfigurationModal';
import { FundAttributeModel } from 'store/Fund/models/fund';
import { FundAttributes } from 'store/Fund/types';
import { ERMResolvedFund, DataSource, PortfolioFundSuggestion } from 'store/Portfolio/types';

import GlobalHelper from './globalHelper';

class FundHelper {

    static getOwnerFirm(data: any) {
        let answer: any = null;

        if (data && data.advising_firm) {
            if (data.advising_firm.length === 1) {
                answer = {
                    ap_id: data.advising_firm[0].id,
                    name: data.advising_firm[0].legal_name,
                    dataSources: data.advising_firm[0].datasources
                };
            } else if (data.advising_firm.length === 0) {
                answer = {
                    error: 'fund has no adviser'
                };
            } else if (data.advising_firm.length > 1) {
                answer = {
                    error: 'fund has multiple advisers'
                };
            }
        }

        return answer;
    }

    static getAllDataSources(dataSources: DataSource[]) {

        const answer: any = {
            [dataSourcesTypes.lei]: [],
            [dataSourcesTypes.sec]: [],
            [dataSourcesTypes.crd]: []
        };

        if (dataSources && dataSources.length > 0) {
            dataSources.forEach((item: DataSource) => {
                if (answer.hasOwnProperty(item.data_source_key_type)) {
                    answer[item.data_source_key_type] = [
                        ...answer[item.data_source_key_type],
                        {
                            'data_source_key': item.data_source_key
                        }
                    ];
                }
            });
        }

        return answer;
    }

    static getAdvisingFirm (advisingFirms: Array<any> | null ): string | null | void {
        let answer: string | null = null;
        if (advisingFirms && advisingFirms.length > 0) {
            const firstFirm = advisingFirms[0];
            answer = `(${firstFirm.legal_name})`;
        }

        return answer;
    }

    static getEntitiesContentForNewFund (data: any) {
        const dataSourceTypes = [dataSourcesTypes.lei, dataSourcesTypes.sec, dataSourcesTypes.crd];
        let answer: JSX.Element | string = '';

        if (data) {
            const name = data.legal_name;
            const advisingFirm = FundHelper.getAdvisingFirm(data.advising_firm);
            const dataSources = FundHelper.getAllDataSources(data.datasources);
            answer =  (
                <div>
                    <h4>{name} <span className="item--entity"> {advisingFirm} </span></h4>
                    <ul className="entity--items">
                        {
                            dataSourceTypes.map((item: string, key: number) => {
                                let dataSourcesHtml: any = '';
                                if (dataSources[item].length > 0) {
                                    dataSourcesHtml = (
                                        <h5 key={key}>
                                            {item}:
                                            {
                                                dataSources[item].map((dItem: any, idx: number) => {
                                                    return (<li key={idx} className="entity--code">
                                                            <span> {dItem.data_source_key}; </span>
                                                    </li>);
                                                })
                                            }
                                        </h5>);
                                }
                                return dataSourcesHtml;
                            })
                        }
                    </ul>
                </div>
             );
        }

        return answer;
    }

    static getCurrencyItem(currency: string[] | string | undefined | null) {
        if (!currency) {
            return null;
        }

        let currencies: string[] = Array.isArray(currency) ? currency : [currency];

        return currencyItems.filter(item => {
            return !!currencies.find(curr => curr.toUpperCase() === item.value.toUpperCase());
        });
    }

    static getCurrencyLabel (currency: ChooseType[] | ChooseType | undefined | null) {
        if (!currency) {
            return null;
        }

        let currencies: ChooseType[] = Array.isArray(currency) ? currency : [currency];
        const labels = currencies.map(item => item.label);

        return (labels.length === 1)
            ? labels[0]
            : `(${labels.join(', ')})`;
    }

    static getCurrencyIcon (currency: ChooseType[] | ChooseType | undefined | null) {
        if (!currency) {
            return null;
        }

        let currencies: ChooseType[] = Array.isArray(currency) ? currency : [currency];
        const icons = currencies.map(item => item.icon);

        return (icons.length === 1)
            ? icons[0]
            : `(${icons.join(', ')})`;
    }

    static setLimitAndOffset(currentFilter: any) {
        return {
            ...currentFilter,
            limit: currentFilter.limit + currentFilter.offset,
            offset: 0
        };
    }

    static getBoundaryLabel(value: number, isMax: boolean): string {
        let boundaryValue = parseInt(value + '', 10);
        let firstDigit = (boundaryValue >= 0) ? +`${boundaryValue}`.charAt(0) : +`${boundaryValue}`.charAt(1);
        let sign = (boundaryValue >= 0) ? '' : '-';
        let zerosCount = (boundaryValue >= 0) ? `${boundaryValue}`.length - 1 : `${boundaryValue}`.length - 2;

        if (firstDigit === 9 && (isMax && boundaryValue >= 0 || !isMax && boundaryValue < 0)) {
            firstDigit = 1;
            zerosCount++;
        } else if (firstDigit === 1 && (isMax && boundaryValue < 0 || !isMax && boundaryValue >= 0)) {
            zerosCount--;
            firstDigit = boundaryValue === 10 ? 0 : 9;
        } else if (isMax && boundaryValue >= 0 || !isMax && boundaryValue < 0) {
            firstDigit++;
        } else {
            firstDigit--;
        }

        return `${sign}${firstDigit}${'0'.repeat(zerosCount)}`;
    }

    static getAttrValues(attrArray: FundAttributeModel[], attrType: FundAttributes) {
        return attrArray
            .filter((attribute: FundAttributeModel) => attribute.attribute_type === attrType)
            .map((attribute: FundAttributeModel) => attribute.value);
    }

    static portfolioImportSuggestion(row: ERMResolvedFund): PortfolioFundSuggestion {
        const firm = FundHelper.getOwnerFirm(row);
        const firmDataSources = FundHelper.getAllDataSources(firm.dataSources || []);
        const fundDataSources = FundHelper.getAllDataSources(row.datasources);

        return {
            status: 99, // unreachable status
            firm_name: firm.name || null,
            ap_id: firm.ap_id || null, // Note that this is FIRM id. keeping in order not to change everywhere
            id: row.id,
            fund_name: row.legal_name,
            firm_sec: firmDataSources.sec.map(sec => sec.data_source_key).join() || null,
            firm_lei: firmDataSources.lei.map(lei => lei.data_source_key).join() || null,
            firm_crd: firmDataSources.crd.map(crd => crd.data_source_key).join() || null,
            fund_sec: fundDataSources.sec.map(sec => sec.data_source_key).join() || null,
            fund_lei: fundDataSources.lei.map(lei => lei.data_source_key).join() || null,
            fund_crd: fundDataSources.crd.map(crd => crd.data_source_key).join() || null,
        };
    }

}

export default FundHelper;
