import { Action } from 'redux';
import { RequestModel } from './models/request';
import { FiltersState } from 'store/Filters/types';

export const APPLY_FILTER = 'store/diligence/requests/applyFilter';
export const SET_REQUESTS = 'store/diligence/requests/setRequests';
export const SET_REQUESTS_WITH_SCROLL = 'store/diligence/requests/setRequestsWithScroll';
export const SEARCH_REQUEST_ATTRIBUTE = 'store/diligence/requests/searchRequestAttribute';
export const SET_REQUEST_ATTRIBUTES = 'store/diligence/requests/setRequestAttributes';
export const GET_REQUESTS = 'store/diligence/requests/getRequests';
export const GET_SAVED_DATA = 'store/diligence/requests/getSavedData';
export const SAVE_FILTER_DATA = 'store/diligence/requests/saveFilterData';
export const SET_ALL_REQUESTS = 'store/diligence/requests/setAllRequests';

export type GetRequestsAction = Action;

export interface SaveFilterDataAction extends Action {
    filters: any[];
    sortOrder: any[];
}

export interface GetSavedDataAction extends Action {
    filterName: string;
}

export interface ApplyFilterAction extends Action {
    filter: FiltersState;
}

export interface SetRequestsAction extends Action {
    count: number;
    requests: RequestModel[];
}

export interface SearchRequestAttributeAction extends Action {
    attributeType: string;
    filter: FiltersState;
}

export interface SetRequestAttributesAction extends Action {
    results: any[];
    count: number;
    attributeType: string;
    withScroll?: boolean;
}

export interface SetAllRequestsAction extends Action {
    requests: RequestModel[];
}

export type DiligenceRequestAction = ApplyFilterAction |
    SetRequestsAction |
    GetRequestsAction |
    SearchRequestAttributeAction |
    SetRequestAttributesAction |
    GetSavedDataAction |
    SaveFilterDataAction |
    SetAllRequestsAction;
