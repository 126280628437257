import * as React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { Attributes } from 'react';
import * as classNames from 'classnames';

interface Props {
    body: React.ReactNode | string;
    enabled: boolean;
    id: string | HTMLElement;
    children: React.ReactElement<any> | React.ReactElement<any>[];
    className?: string;
    placement?: any;
}

interface State {
    isPopoverVisible: boolean;
}

class UiPopover extends React.PureComponent<Props, State> {
    static defaultProps = {
        placement: 'top-start',
    };

    state = {
        isPopoverVisible: false
    };

    handleShowPopover = () => {
        this.setState(prevState => ({isPopoverVisible: true}));
    }

    handleHidePopover = () => {
        this.setState(prevState => ({isPopoverVisible: false}));
    }

    render() {
        const { children } = this.props;
        const childrenWithProps = React.Children.map(
            children,
            (child: React.ReactElement<Partial<any> & Attributes>) => {
                if (React.isValidElement(child) && this.props.enabled) {
                    return React.cloneElement(child, {
                        id: this.props.id,
                        onMouseOver: this.handleShowPopover,
                        onMouseOut: this.handleHidePopover,
                    });
                }
                return child;
            }
        );

        let classes = {};
        if (this.props.className) {
            classes[this.props.className] = !!this.props.className;
        }

        return (
            <>
                {childrenWithProps}
                {
                    this.props.enabled &&
                    <Popover
                        placement={this.props.placement}
                        isOpen={this.state.isPopoverVisible}
                        target={this.props.id}
                        modifiers={{preventOverflow: {enabled: true, boundariesElement: 'window'}}}
                        className={classNames(classes)}
                    >
                        <PopoverBody>
                            {this.props.body}
                        </PopoverBody>
                    </Popover>
                }
            </>
        );
    }
}

export default UiPopover;