import * as React from 'react';

interface Props {
    message: string;
    errors: string[];
}

const ErrorToast: React.FC<Props> = ({message, errors}) => (
    <div>
        <div>{message}</div>
        {
            errors.length > 0 &&
            <ul>
                {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
        }
    </div>
);

export default ErrorToast;
