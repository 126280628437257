import { RequestModel } from './models/request';
import { DiligenceRequestFilterModel } from './models/filter';

export interface DiligenceRequestState {
    list: RequestModel[];
    count: number;
    filter: DiligenceRequestFilterModel;
    allRequests: RequestModel[];
}

export enum DiligenceRequestStatus {
    NotStarted = 'new',
    InProgress = 'open',
    Overdue = 'overdue',
    Rescinded = 'rescinded',
    Published = 'closed',
    Reopened = 'reopened',
    new = 'Not Started',
    open = 'In Progress',
    overdue = 'Overdue',
    rescinded = 'Rescinded',
    closed = 'Published',
    reopened = 'Reopened'
}

export const DiligenceFilterTypeTitle = {
    account: 'Responding As',
    requester: 'Requester',
    survey: 'Questionnaire',
    status: 'Status',
    dateDue: 'Due',
    dateSent: 'Received',
    dateReceived: 'Published'
};

export interface FilterEntity {
    id?: string;
    name: string;
    type: string;
    filterName?: string;
}