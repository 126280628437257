import { AppState } from 'store/types';

import { createSelector } from 'reselect';

import {
    AvailablePeriodsStat, BoxChartResult,
    ChartData,
    ChartPeriodResult,
    DashboardChartType,
    DashboardState,
    StatisticDonutDataType
} from './types';
import { initialState } from './constants';
import { DonutPiece } from 'store/Shared/types';
import { ChartColumn } from 'store/Dashboard/types';

interface Params {
    donutName?: string;
    chartType?: DashboardChartType;
    chartPeriod?: string;
}

const roundFloat = (num: number) => Math.round(num * 100) / 100;

export const getDashboardState = (state: AppState): DashboardState => state.dashboard || initialState;
export const getDonutName = (state: AppState, params: Params) => params.donutName || '';
export const getChartType = (state: AppState, params: Params) => params.chartType || '';
export const getChartPeriod = (state: AppState, params: Params) => params.chartPeriod || '';

export const getChartResults = createSelector(
    getDashboardState,
    (state: DashboardState) => state.chart.result
);

export const getChartResult = createSelector(
    getChartResults,
    getChartPeriod,
    (results: ChartPeriodResult[], period: string): ChartPeriodResult | null => {
        const result = results.find(data => data.period === period);

        return result || null;
    }
);

export const getChartData = createSelector(
    getChartResult,
    (result: ChartPeriodResult): ChartData | null => {
        return result ? result.data : null;
    }
);

export const getChartDataAvailablePeriodsStat = createSelector(
    getChartResults,
    getChartPeriod,
    (results: ChartPeriodResult[], period: string): AvailablePeriodsStat => {
        let before = 0, after = 0;

        results.forEach(res => {
            if (res.period < period) {
                before += 1;
            } else if (res.period > period) {
                after += 1;
            }
        });

        return {before, after};
    }
);

export const getChartDatePeriod = createSelector(
    getDashboardState,
    (state: DashboardState) => ({
        first_period: state.chart.first_period,
        last_period: state.chart.last_period
    })
);

export const getDonutPieces = createSelector(
    getDashboardState,
    getDonutName,
    (state: DashboardState, donutName: string): DonutPiece[] => {
        const donuts = state.donuts;

        if (donuts.hasOwnProperty(donutName)) {
            return donuts[donutName];
        }

        return [];
    }
);

export const getDonutTotal = createSelector(
    getDonutPieces,
    getDonutName,
    (pieces: DonutPiece[], donutName: string): number => {
        const total = pieces.reduce(
            (accumulator, piece: DonutPiece) => {
                let value;
                switch (donutName) {
                    case StatisticDonutDataType.Alerts:
                        value = (['alert', 'partial_data_alert'].indexOf(piece.name) >= 0)
                            ? piece.value
                            : 0;
                        break;
                    case StatisticDonutDataType.PerformanceReport:
                        value = (['overdue', 'partial'].indexOf(piece.name) >= 0)
                            ? piece.value
                            : 0;
                        break;
                    default:
                        value = 0;
                        break;
                }

                return accumulator + value;
            },
            0
        );

        return total;
    }
);

export const makeGetChartData = () => {
    return createSelector(
        getChartData,
        getChartType,
        (data: ChartData, chartType: DashboardChartType) => {
            let items: ChartColumn[] = [];

            if (data && data.hasOwnProperty(chartType) && data[chartType]) {
                items = data[chartType].items;
            }

            return items.map(item => ({
                value: roundFloat(item.value),
                count: roundFloat(item.count),
                extreme_items: {
                    from: roundFloat(item.extreme_items.from),
                    to: roundFloat(item.extreme_items.to),
                },
            }));
        }
    );
};

export const makeGetChartCount = () => {
    return createSelector(
        getChartData,
        getChartType,
        (data: ChartData, chartType: DashboardChartType) => {
            return data && data.hasOwnProperty(chartType) && data[chartType]
                ? data[chartType].count
                : 0;
        }
    );
};

export const getBoxChartDatePeriod = createSelector(
    getDashboardState,
    (state: DashboardState) => ({
        first_period: state.boxCharts.first_period,
        last_period: state.boxCharts.last_period
    })
);

const getBoxChartPeriodData = createSelector(
    getDashboardState,
    (state: DashboardState) => state.boxCharts.result.length > 0
        ? state.boxCharts.result[0]
        : null
);

export const getBoxChartResults = createSelector(
    getBoxChartPeriodData,
    getChartPeriod,
    (result: BoxChartResult | null, periodType: string) => {
        if (result && result.data.hasOwnProperty(periodType)) {
            return result.data[periodType].items;
        }
        return [];
    }
);

export const getBoxChartCount = createSelector(
    getBoxChartPeriodData,
    getChartPeriod,
    (result: BoxChartResult | null, periodType: string) => {
        if (result && result.data.hasOwnProperty(periodType)) {
            return result.data[periodType].fund_count;
        }
        return 0;
    }
);

export const getBoxChartData = createSelector(
    getBoxChartResults,
    results => results.map(result => ({
        ...result,
        low: roundFloat(+result.low),
        q1: roundFloat(+result.q1),
        median: roundFloat(+result.median),
        q3: roundFloat(+result.q3),
        high: roundFloat(+result.high),
    }))
);

export const getDashboardRows = createSelector(
    getDashboardState,
    (state: DashboardState) => {
        return state.rows;
    }
);

export const getDashboardRowsCount = createSelector(
    getDashboardState,
    (state: DashboardState) => {
        return state.rowsCount;
    }
);

export const getDashboardFundsCount = createSelector(
    getDashboardState,
    (state: DashboardState) => {
        return state.fundsCount;
    }
);

export const getDashboardFirmsCount = createSelector(
    getDashboardState,
    (state: DashboardState) => {
        return state.firmsCount;
    }
);
