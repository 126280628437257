import * as React from 'react';
import DottedToggleSwitch from '../Ui/DottedToggleSwitch';
import { Account } from 'store/Account/models/account';

interface Props {
    name: string;
    placeholder?: string;
    withLabel?: boolean;
    value: any;
    handleChange: (data: any) => void;
    account?: Account;
}

const DottedToggleFilter: React.FC<Props> = ({
    name, value, placeholder, withLabel, handleChange, account
}) => {
    return (
        <DottedToggleSwitch
            value={value}
            title={placeholder || ''}
            withLabel={withLabel}
            handleChange={(checked: boolean) => handleChange({[name]: checked})}
            account={account}
        />
    );
};

DottedToggleFilter.defaultProps = {
    withLabel: false
};

export default DottedToggleFilter;
