import * as React from 'react';
import * as classNames from 'classnames';

import { CSSProperties } from 'react';
import { FixedElementType } from 'store/constants';

const styles = require('./BasePanel.scss');

interface Props {
    isFixed?: boolean;
    scrollbarWidth?: number;
    initialMargin?: number;
    possibleFixedElementClassName: string;
    fixedElementType?: FixedElementType;
}

const defaultMargin = 32;
const measure = 'px';

const TableFilterPanel: React.FC<Props> = (
    {children, scrollbarWidth, isFixed, initialMargin, fixedElementType, possibleFixedElementClassName}
) => {
    let style: CSSProperties = {};
    if (isFixed) {
        const initialMarginProp = initialMargin !== undefined ? initialMargin : defaultMargin;
        style.marginRight = scrollbarWidth
            ? (initialMarginProp + scrollbarWidth) + measure
            : initialMarginProp + measure;
    }

    const className = classNames({
        [styles.table_filter_panel]: true,
        [possibleFixedElementClassName]: fixedElementType === FixedElementType.TableFilter
    });

    return (
        <div className={className} style={style}>
            {children}
        </div>
    );
};

export default TableFilterPanel;
