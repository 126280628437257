import * as React from 'react';
import * as classnames from 'classnames';

import { NavigationButton } from '../../../store/types';

import Button from './Button';

const styles = require('./Panel.scss');

interface Props {
    buttons: NavigationButton[];
}

const Panel: React.FC<Props> = ({buttons}) => {
    const panelClasses = classnames(styles.panel, 'd-flex', 'flex-column', 'align-items-stretch', 'l-left-column');
    return (
        <div className={panelClasses}>
            {
                buttons.length > 0 &&
                buttons.map((button, idx) => <Button button={button} key={idx} />)
            }
        </div>
    );
};

export default Panel;
