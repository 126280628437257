import * as React from 'react';

import { formatText } from 'helpers/tableHelper';

import { TableHeader } from 'store/types';
import { FormatTypeChoices } from 'store/constants';
import { DEFAULT_VALUE_DASH } from 'store/constants';

interface Props {
    row: any;
    visibleHeaders: TableHeader[];
    getCustomStyles?: (row: any) => string[];
}

const styles = require('./DatasetRow.scss');

const DatasetRow: React.FC<Props> = ({row, visibleHeaders, getCustomStyles}) => {
    const emptyColumns = { period: true };

    let visibleHeadersCount: number = 0;
    visibleHeaders.forEach((item: TableHeader) => {
        if (item.active) {
            visibleHeadersCount++;
        }
    });

    let answer: any;

    if (!row.errorMessage) {
        answer = visibleHeaders.map((item: TableHeader, idx: number) => {
            let currentAnswer: JSX.Element | null = null;

            if (item.active) {
                if (item.name === 'data_version') {
                    currentAnswer =  (
                        <td className="triangledBlueTd text-right" key={idx}>
                            {formatText(row[item.name], item.formatType)} &nbsp;
                             published &nbsp;
                            {formatText(row.created_at, FormatTypeChoices.DateFormatShort)}
                        </td>
                    );
                } else {
                    currentAnswer =  (
                        <td className="triangledBlueTd text-right" key={idx}>
                            {formatText(row[item.name], item.formatType, DEFAULT_VALUE_DASH)}
                        </td>
                    );
                }
            }
            if (emptyColumns[item.name]) {
                currentAnswer = <td className="triangledBlueTd" key={idx}/>;
            }

            return currentAnswer;
        });
    } else {
        answer = (
            <>
                <td className="triangledBlueTd"/>
                <td colSpan={visibleHeadersCount - 1}>Not found history</td>
            </>
        );
    }
    const customStyles: string[] = typeof getCustomStyles === 'function' ? getCustomStyles(row) : [];

    return (
        <tbody className={styles.dataset_row}>
            <tr className={[styles.tr, ...customStyles].join(' ')}>
                {answer}
                <td/>
            </tr>
        </tbody>
    );
};

export default DatasetRow;
