import * as React from 'react';

import { DEFAULT_VALUE_DASH } from 'store/constants';
import { Dataset } from 'store/DataManager/models/dataset';
import { DATA_SOURCES } from 'store/Fund/constants';
import { DataEntry } from 'store/Fund/types';
import User from 'store/Auth/models/user';

interface Props {
    row: Dataset;
    defaultValue?: string;
}
const DataSourceCell: React.FC<Props> = ({row, defaultValue}) => {
    const dataSource = row.data_source || null;
    let dataSourceLabel = dataSource && DATA_SOURCES.hasOwnProperty(dataSource)
        ? DATA_SOURCES[dataSource]
        : dataSource;
    if (dataSource && dataSource === DataEntry.Requestor) {
        const updatedBy = row.updated_by as Partial<User>;
        const userName = updatedBy && (updatedBy.hasOwnProperty('first_name') || updatedBy.hasOwnProperty('last_name'))
            ? (updatedBy.first_name || '') + ' ' + (updatedBy.last_name || '')
            : '';
        dataSourceLabel = dataSourceLabel + (userName ? (' (' + userName + ') ' ) : '');
    }

    const output = dataSource ? dataSourceLabel : defaultValue || DEFAULT_VALUE_DASH;

    return (
        <div>
            {output}
        </div>
    );
};

export default DataSourceCell;
