export const getSortClass = (currentFieldCode: string, orderingValue?: string) => {
    const extractedPoint = currentFieldCode.replace('.', '_');
    if (orderingValue && orderingValue.charAt(0) === '-') {
        if (orderingValue.replace(/-/g, '') === extractedPoint) {
            return 'desc';
        }
    } else if (orderingValue && orderingValue === extractedPoint) {
        return 'asc';
    }
    return '';
};

export const getSortValue = (orderingValue: string, currentFieldCode: string) => {
    const extractedPoint = currentFieldCode.replace('.', '_');
    if (orderingValue && orderingValue.charAt(0) !== '-') {
        let orderingParams = extractedPoint.split(',');
        if (orderingParams.length > 1) {
            return orderingParams.map(param => '-' + param).join();
        } else {
            return `-${extractedPoint}`;
        }
    }
    return extractedPoint;
};

/**
 * Return 'asc' or 'desc' based on field name like 'name' or '-name'
 */
export const getOrderingDirection = (field: string) => {
    return field.startsWith('-')
        ? 'desc'
        : 'asc';
};

/**
 * Return clear field name based on field name like 'name' or '-name'
 * @return 'name'
 */
export const getOrderingField = (field: string) => {
    return field.startsWith('-')
        ? field.substr(1)
        : field;
};

/**
 * Return prepared ordering field like '-name' or 'name' by string direction (desc or asc)
 * @param field
 * @param direction
 */
export const prepareOrderingField = (field: string, direction: string): string => {
    return (direction === 'desc' ? '-' : '') + field;
};