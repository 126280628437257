import * as React from 'react';

import { formatText } from 'helpers/tableHelper';
import { FormatTypeChoices } from 'store/constants';
import RowActionsColumn from 'components/Shared/DataTable/RowActionsColumn';
import { TableRowAction } from 'store/types';

interface Props {
    row: any;
    actions?: TableRowAction[];
}

const styles = require('./NotStartedRow.scss');

const PublishedRow: React.FC<Props> = ({row, actions}) => {
    return (
        <tbody className={styles.niceBody}>
        <tr className={styles.tr}>
            <td />
            <td>-</td>
            <td />
            <td>{row.account && row.account.name}</td>
            <td>{formatText(row.due_date, FormatTypeChoices.DateFormat)}</td>
            <td>{formatText(row.publish_date, FormatTypeChoices.DateFormat)}</td>
            <td
                className="triangleTd"
            >
                Published {formatText(row.publish_date, FormatTypeChoices.DateFormat)}
            </td>
            <td className={styles.actionsRow}>
                {
                    actions &&
                    actions.map((action: any, actionId: number) => {
                        const hide = action.isVisible && !action.isVisible(row)
                            ? 'hidden-action'
                            : '';
                        return <RowActionsColumn
                            key={actionId}
                            action={action}
                            row={row}
                            className={'action-items ' + hide}
                            isEditing={false}
                        />;
                    })
                }
            </td>
        </tr>
        </tbody>
    );
};

export default PublishedRow;
