import CreditApiHelper, { API_RULE_MANAGER } from 'helpers/creditApiHelper';
import { ApiHelper } from 'helpers/apiHelper';

import { FiltersState } from 'store/Filters/types';
import { Id } from 'store/types';
import { defaultDeniedGetParams } from 'store/constants';

export const getList = async (filter: FiltersState): Promise<any> => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    const response = await CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/alert/`,
        'get',
        {
            params
        }
    );

    return Promise.resolve(response);
};

export const getAlertStatuses = async (period: string): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/alert-chart/`,
        'get',
        {
            params: {period}
        }
    );

    return Promise.resolve(response);
};

export const getFundStatistic = async (id: Id, period: string): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/alert/${id}/`,
        'get',
        {
            params: {period}
        }
    );

    return Promise.resolve(response);
};

export const getFundEvaluationResults = async (id: Id, filter: FiltersState): Promise<any> => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    const response = await CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/extended-alert/`,
        'get',
        {
            params: {...params, fund_id: id}
        }
    );

    return Promise.resolve(response);
};
