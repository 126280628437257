import * as React from 'react';
import * as classNames from 'classnames';

import { DataEntry } from 'store/Fund/types';
import { isAlphaPipe, isFundManager, isRequestor } from 'store/Fund/helpers/dataEntry';
import { DEFAULT_VALUE_DASH } from 'store/constants';

const styles = require('./DataEntryCircle.scss');

interface Props {
    dataEntry?: DataEntry | null;
    value?: any;
}

const DataEntryCircle: React.FC<Props> = ({dataEntry, value}) => {
    const withDataEntry = !!dataEntry && value && value !== DEFAULT_VALUE_DASH;
    const circleClass = classNames({
        [styles.circle]: true,
        [styles.requestor]: withDataEntry && isRequestor(dataEntry),
        [styles['alpha-pipe']]: withDataEntry && isAlphaPipe(dataEntry),
        [styles['fund-manager']]: withDataEntry && isFundManager(dataEntry),
    });
    return (
        <div className={circleClass} />
    );
};

export default DataEntryCircle;
