import * as React from 'react';
import * as classNames from 'classnames';

interface TextColorType {
    type?: any;
    className?: any;
}

const styles = require('./TextColor.scss');
const TextColor: React.FC<TextColorType> = ({ children, className, type }) => {
    const classNameWrapper = classNames({
        [styles.ui_text_color]: !!styles.ui_text_color,
    });

    const classNameElement = classNames({
        [className]: !!className,
        [type]: !!type,
    });

    return   (
        <p className={classNameWrapper}><span className={classNameElement}>{children}</span></p>
    );
};

export default TextColor;
