import { put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { doneActionFail, doneActionSuccess, initAction } from '../Actions/actionCreators';
import * as accountApi from '../../services/account';
import {
    ApplyFilterAction, DeleteUserAction, GetUsersAction, UpdateUserAction, UpdateUserRoleAction,
    APPLY_FILTER, GET_USERS, DELETE_USER, UPDATE_USER, InviteUserAction, INVITE_USER, UPDATE_USER_ROLE,
    DeleteUsersAction, DELETE_USERS
} from './actions';
import { getUsers, setUsers } from './actionCreators';
import { messages } from './constants';
import { closeModal } from '../Modals/General/actionCreators';
import { FilterNames } from '../Filters/types';
import { clearChecked } from '../Filters/actionCreators';

function* getAccountUsers(action: GetUsersAction) {
    try {
        yield put(initAction(action.type));
        const response = yield accountApi.getUsers(action.filter);
        yield put(setUsers(response.count, response.results));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* deleteAcountUser(action: DeleteUserAction) {
    try {
        yield put(initAction(action.type));
        yield accountApi.deleteUser(action.id);
        yield put(doneActionSuccess(action.type, messages.DELETE_ACCOUNT_USER_SUCCESS_MESSAGE));

        if (action.closeModal) {
            yield put(closeModal());
        }
        yield put(getUsers(action.filter));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.DELETE_ACCOUNT_USER_FAIL_MESSAGE));
    }
}

function* deleteUsers(action: DeleteUsersAction) {
    try {
        yield put(initAction(action.type));
        yield accountApi.deleteUsers(action.ids, action.checkedAll);
        yield put(doneActionSuccess(action.type, 'Successfully deleted!'));
        yield put(closeModal());
        yield put(clearChecked(FilterNames.usersList));
        yield put(getUsers());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.DELETE_ACCOUNT_USER_FAIL_MESSAGE));
    }
}

function* updateAccountUser(action: UpdateUserAction) {
    try {
        yield put(initAction(action.type));
        yield accountApi.updateUser(action.userAccount);
        yield put(doneActionSuccess(action.type, messages.UPDATE_ACCOUNT_USER_SUCCESS_MESSAGE));

        if (action.closeModal) {
            yield put(closeModal());
        }
        yield put(getUsers(action.filter));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.UPDATE_ACCOUNT_USER_FAIL_MESSAGE));
    }
}

function* updateUserRole(action: UpdateUserRoleAction) {
    try {
        yield put(initAction(action.type));
        yield accountApi.updateUserRole(action.id, action.role);
        yield put(doneActionSuccess(action.type, messages.UPDATE_ACCOUNT_USER_SUCCESS_MESSAGE));
        yield put(getUsers());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.UPDATE_ACCOUNT_USER_FAIL_MESSAGE));
    }
}

function* inviteAccountUser(action: InviteUserAction) {
    try {
        yield put(initAction(action.type));
        yield accountApi.inviteUser(action.invitedUser);
        yield put(doneActionSuccess(action.type, messages.INVITE_ACCOUNT_USER_SUCCESS_MESSAGE));

        if (action.closeModal) {
            yield put(closeModal());
        }
        yield put(getUsers(action.filter));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.INVITE_ACCOUNT_USER_FAIL_MESSAGE));
    }
}

function* applyFilter(action: ApplyFilterAction) {
    try {
        yield put(initAction(action.type));
        const response = yield accountApi.getUsers(action.filter);
        yield put(setUsers(response.count, response.results));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchApplyFilter() {
    yield takeLatest(APPLY_FILTER, applyFilter);
}

function* watchGetAccountUsers() {
    yield takeLatest(GET_USERS, getAccountUsers);
}

function* watchDeleteAccountUser() {
    yield takeEvery(DELETE_USER, deleteAcountUser);
}

function* watchDeleteUsers() {
    yield takeEvery(DELETE_USERS, deleteUsers);
}

function* watchUpdateAccountUser() {
    yield takeEvery(UPDATE_USER, updateAccountUser);
}

function* watchUpdateUserRole() {
    yield takeEvery(UPDATE_USER_ROLE, updateUserRole);
}

function* watchInviteAccountUser() {
    yield takeEvery(INVITE_USER, inviteAccountUser);
}

export default function* root() {
    yield all([
        watchApplyFilter(),
        watchGetAccountUsers(),
        watchDeleteAccountUser(),
        watchDeleteUsers(),
        watchUpdateAccountUser(),
        watchInviteAccountUser(),
        watchUpdateUserRole(),
    ]);
}