import * as React from 'react';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as firmApi from 'services/firm';
import * as fundApi from 'services/fund';
import * as rulesetApi from 'services/ruleset';
import * as portfolioApi from 'services/portfolio';
import * as globalSearchApi from 'services/globalSearch';
import * as diligenceRequestApi from 'services/diligence/request';
import {
    GET_CHOICES,
    GetChoicesAction,
    GetChoicesByFundAction,
    SEARCH_CHOICES,
    SearchFilterChoicesAction,
} from './actions';
import { setChoices, setGlobalChoices } from './actionCreators';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { AsyncFilterName } from 'store/Filters/types';
import { FundAttributes } from 'store/Fund/types';
import { getUserAccountType } from 'store/Auth/selectors';
import { DiligenceFilterNames } from 'store/Diligence/Filters/types';

function* getChoices(action: GetChoicesAction) {
    try {
        yield delay(500);
        yield put(initAction(action.type));
        let response;
        const userAccountType = yield select(getUserAccountType);
        switch (action.filterName) {
            case AsyncFilterName.firm:
                response = yield firmApi.getFirms(action.filter, userAccountType);
                break;
            case AsyncFilterName.internalId:
                response = yield fundApi.getFundInternalIdList(action.filter);
                break;
            case AsyncFilterName.portfolio:
                response = yield portfolioApi.getPortfolios(action.filter);
                break;
            case AsyncFilterName.creditOfficer:
                response = yield fundApi.getFundAttributes(
                    {...action.filter, attribute_type: FundAttributes.CreditOfficer}
                );
                break;
            case AsyncFilterName.product:
                response = yield fundApi.getFundAttributes({...action.filter, attribute_type: FundAttributes.Product});
                break;
            case AsyncFilterName.strategy:
                response = yield fundApi.getFundAttributes({...action.filter, attribute_type: FundAttributes.Strategy});
                break;
            case AsyncFilterName.fund:
                response = yield fundApi.getPlainFunds(action.filter, userAccountType);
                break;
            case AsyncFilterName.rulesetName: {
                const getChoicesByFundAction = action as GetChoicesByFundAction;
                response = yield rulesetApi.getRulesetNames(
                    getChoicesByFundAction.filter,
                    getChoicesByFundAction.fundId,
                );
                break;
            }
            case AsyncFilterName.counterparty: {
                const getChoicesByFundAction = action as GetChoicesByFundAction;
                response = yield rulesetApi.getCounterparties(
                    getChoicesByFundAction.filter,
                    getChoicesByFundAction.fundId,
                );
                break;
            }
            default:
        }
        yield put(setChoices(action.page, action.filterName, response.results, response.count, action.filter.scroller));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* searchChoices(action: SearchFilterChoicesAction) {
    try {
        yield delay(500);
        yield put(initAction(action.type));
        let response;
        if (action.filterName === DiligenceFilterNames.diligenceRequest) {
            response = yield diligenceRequestApi.suggestRequests({
                accountId: 'current',
                search: action.query
            });
        } else {
            response = yield globalSearchApi.search(action.query, action.filter.limit, action.source);
        }
        yield put(setGlobalChoices(response.results, response.count));
        yield put(doneActionSuccess(action.type));
    } catch (error) {
        yield put(doneActionFail(action.type, error));
    }
}

function* watchGetChoices() {
    yield takeEvery(GET_CHOICES, getChoices);
}

function* watchSearchFilterChoices() {
    yield takeLatest(SEARCH_CHOICES, searchChoices);
}

export default function* root() {
    yield all([
        watchGetChoices(),
        watchSearchFilterChoices()
    ]);
}
