import * as React from 'react';
import { connect } from 'react-redux';

import DatasetConfigurationModal from 'components/DataManager/DatasetDetailModals/DatasetConfigurationModal';

import { AppState, FilterItem, SelectItem } from 'store/types';
import { closeModal } from 'store/Modals/General/actionCreators';
import { getModalParams, isModalOpen } from 'store/Modals/General/selectors';
import { AclRole, DATASET_CONFIGURATION_MODAL } from 'store/DataManager/types';
import { getFilters } from 'store/Filters/selectors';
import { FilterNames } from 'store/Filters/types';
import {
    getDatasetDetailTemporaryFilter, getDatasetDetailTemporaryHeaders, getDatasetFundSelector
} from 'store/DataManager/selectors';
import {
    clearIsEdingDataset, saveSettingsDatasetFund, setDatasetDetailTemporaryFilter
} from 'store/DataManager/actionCreators';
import { hasRole } from 'store/DataManager/aclHelper';
import { tooltipIcon, FilterType } from 'store/constants';

const filterName = FilterNames.datasetData;
const styles = require('./ConfigurationModal.scss');
const chooseTypes: SelectItem[] = [
    {label: 'Monthly', value: 'monthly'}
];

export const DATASET_VERSION = {
    estimate: 'estimate',
    final: 'final'
};

export const dataVersion = [
    { label: 'Estimate', value: DATASET_VERSION.estimate },
    { label: 'Final', value: DATASET_VERSION.final }
];

const filters: FilterItem[] = [
    {
        name: 'frequency',
        className: 'col-md-6 right',
        type: FilterType.Select,
        title: 'Frequency',
        innerClassName: styles['select-dropdown'],
        classNamePrefix: 'material-select',
        choices: chooseTypes,
        disabled: true,
        defaultValue: chooseTypes[0],
        withLabel: true,
        customMenuPortalClass: {
            zIndex: 9999,
            fontSize: '12',
        },
    },
    { name: 'content', type: FilterType.Content, content:
            (
                <div className="comment">
                    <span className="icon-tooltip">
                         <img src={tooltipIcon} alt=""/>
                    </span>
                    Have weekly, daily or quarterly data reporting requirements?
                    <a href="#"> Please contact support here</a></div>
            ),
     className: 'col-md-6 left'},
    { name: 'start_date', type: FilterType.SingleDate, title: 'Start Date',
        material: true, customInputIcon: true,
        className: 'col-md-6 ui-material right', withLabel: true },
    { name: 'end_date', type: FilterType.SingleDate,  material: true,
        title: 'End Date', customInputIcon: true,
        className: 'col-md-6 ui-material left', withLabel: true },
];

const mapStateToProps = (state: AppState) => {
    const modalParams = getModalParams(state) || {};
    const fund = getDatasetFundSelector(state) || '';
    return {
        fund,
        modalParams,
        filters,
        temporaryFilter: getDatasetDetailTemporaryFilter(state),
        currentFilter: getFilters(state, filterName),
        temporaryHeaders: getDatasetDetailTemporaryHeaders(state),
        isEditor: hasRole(fund, AclRole.Editor),
        isOpen: isModalOpen(state, {name: DATASET_CONFIGURATION_MODAL}),
    };
};

const mapDispatchToProps = {
    closeModal,
    setDatasetDetailTemporaryFilter,
    saveSettingsDatasetFund,
    clearIsEdingDataset,
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetConfigurationModal);
