import * as React from 'react';
import { connect } from 'react-redux';

import ConfirmModal from 'components/Shared/Modals/ConfirmModal';

import { AppState } from 'store/types';
import { isModalOpen } from 'store/Modals/General/selectors';
import { closeModal } from 'store/Modals/General/actionCreators';

export const name = 'CONFIRM_MODAL';

const mapStateToProps = (state: AppState) => ({
    isOpen: isModalOpen(state, {name})
});

const mapDispatchToProps = {toggle: closeModal};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);