import { createSelector } from 'reselect';
import { DiligenceAuthState } from './types';
import { AppState } from 'store/types';
import { initialState } from './constants';
import ActiveUser from './models/activeUser';

export const getAuthState = (state: AppState): DiligenceAuthState => state.diligenceAuth || initialState;

export const isAuthenticated = createSelector(
    getAuthState,
    (state: DiligenceAuthState) => {
        return !!state.user && state.user.hasOwnProperty('email') && state.user.active_account &&
            (!state.user.hasOwnProperty('is_authenticated') || state.user.is_authenticated);
    }
);

export const isInitialized = createSelector(
    getAuthState,
    (state: DiligenceAuthState) => {
        return state.isInitialized;
    }
);

export const getDiligenceUser = createSelector(
    getAuthState,
    (state: DiligenceAuthState) => state.user
);

export const getActiveAccount = createSelector(
    getDiligenceUser,
    (user: ActiveUser) => user.active_account
);

export const getTFASecret = createSelector(
    getAuthState,
    (state: DiligenceAuthState) => state.secret
);

export const getQRUri = createSelector(
    getAuthState,
    (state: DiligenceAuthState) => state.qrURI
);