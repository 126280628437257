// window width in pixels
export const WINDOW_WIDTH_EXTRA_SMALL = 576;
export const WINDOW_WIDTH_SMALL = 768;
export const WINDOW_WIDTH_MEDIUM = 992;
export const WINDOW_WIDTH_LARGE = 1200;

export enum Device {
    Desktop = 'DESKTOP',
    Tablet = 'TABLET',
    SmallTablet = 'SMALL_TABLET',
    MobileDevice = 'MOBILE_DEVICE',
    SmallMobileDevice = 'SMALL_MOBILE_DEVICE',
}

class DeviceHelper {

    static getWidth() {
        return window.innerWidth ||
            (document.documentElement && document.documentElement.clientWidth) ||
            document.body.clientWidth;
    }

    static isDesktop(width: number = DeviceHelper.getWidth()) {
        return width >= WINDOW_WIDTH_LARGE;
    }

    static isTablet(width: number = DeviceHelper.getWidth()) {
        return width >= WINDOW_WIDTH_MEDIUM && width < WINDOW_WIDTH_LARGE;
    }

    static isSmallTablet(width: number = DeviceHelper.getWidth()) {
        return width >= WINDOW_WIDTH_SMALL && width < WINDOW_WIDTH_MEDIUM;
    }

    static isMobileDevice(width: number = DeviceHelper.getWidth()) {
        return width >= WINDOW_WIDTH_EXTRA_SMALL && width < WINDOW_WIDTH_SMALL;
    }

    static isSmallMobileDevice(width: number = DeviceHelper.getWidth()) {
        return width < WINDOW_WIDTH_EXTRA_SMALL;
    }

    static isTouchSupported() {
        return !!('ontouchstart' in window || navigator.msMaxTouchPoints);
    }
}

export default DeviceHelper;