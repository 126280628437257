import { DiligenceRequestState } from './types';
import {
    ApplyFilterAction,
    APPLY_FILTER,
    DiligenceRequestAction,
    SET_REQUESTS,
    SetRequestsAction,
    SetRequestAttributesAction, SET_REQUEST_ATTRIBUTES, SET_ALL_REQUESTS,
    SetAllRequestsAction, SET_REQUESTS_WITH_SCROLL
} from './actions';
import { initialRequestsState } from './constants';

export const diligenceRequestReducer = (
    state: DiligenceRequestState = initialRequestsState,
    action: DiligenceRequestAction) => {
    switch (action.type) {
        case APPLY_FILTER:
            return {
                ...state,
                filter: (<ApplyFilterAction> action).filter
            };
        case SET_REQUESTS:
            return {
                ...state,
                list: (<SetRequestsAction> action).requests,
                count: (<SetRequestsAction> action).count
            };
        case SET_REQUESTS_WITH_SCROLL:
            return {
                ...state,
                list: [
                    ...state.list,
                    ...(<SetRequestsAction> action).requests
                ],
                count: (<SetRequestsAction> action).count
            };
        case SET_REQUEST_ATTRIBUTES:
            return {
                ...state,
                [(<SetRequestAttributesAction> action).attributeType]: {
                    countAttributes: (<SetRequestAttributesAction> action).count,
                    attributes: (<SetRequestAttributesAction> action).results
                },
            };
        case SET_ALL_REQUESTS:
            return {
                ...state,
                allRequests: (<SetAllRequestsAction> action).requests
            };
        default:
            return state;
    }
};
