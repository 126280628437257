import { delay, eventChannel } from 'redux-saga';
import { put, all, takeEvery, call, take, cancelled, fork, cancel, takeLatest } from 'redux-saga/effects';

import socketHelper from 'helpers/socketHelper';
import { startDownload } from 'helpers/fileHelper';

import {
    cleanLocalStorage, getBySocketMessageData, getFullUploadProgress,
    setUploadHistory, updateUploadProgress, endUploading, startUploading
} from './actionCreator';
import { SOCKET_CONNECT, SOCKET_DISCONNECT, SOCKET_OPENED } from '../Socket/actions';
import { SocketActions, StorageDownloadFileSession, UploadAction, UploadStatus } from './types';
import {
    GetUploadHistoryAction,
    SetCancelImportExportAction,
    FileDownloadAction,
    GET_FULL_UPLOAD_PROGRESS,
    GET_UPLOAD_HISTORY,
    SET_CANCEL_IMPORT_EXPORT,
    DOWNLOAD_EXPORT_FILE,
    CLEAN_LOCAL_STORAGE,
    UPDATE_UPLOAD_PROGRESS_DEBOUNCE,
    UpdateUploadProgressDebounceAction,
    UpdateUploadStatusAction
} from './actions';
import { CURRENT_SESSION_ID } from '../constants';
import { DownloadProgressStoragePrefix, END_UPLOAD_STATUSES } from './constants';

function* createSocketChannel() {
    return eventChannel(emitter => {
        const messageHandler = (event: any) => emitter(getBySocketMessageData(JSON.parse(event.data)));
        socketHelper.addEventListener('message', messageHandler);

        return () => {
            socketHelper.removeEventListener('message', messageHandler);
        };
    });
}

function* createCleanerChannel() {
    return eventChannel(emitter => {
        let cleanerId = 0;
        const cleanStep = 2 * 60 * 1000;
        (function cln() {
            // clean local storage every two minutes
            cleanerId = window.setTimeout(
                () => {
                    emitter(cleanLocalStorage());
                    cln();
                },
                cleanStep
            );
        })();

        return () => {
            if (cleanerId) {
                clearTimeout(cleanerId);
            }
        };
    });
}

function* connect() {
    const channel = yield call(createSocketChannel);
    try {
        while (true) {
            const action = yield take(channel);
            yield put(action);
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

function* subscribe() {
    yield take(SOCKET_OPENED);
    yield put(getFullUploadProgress());

    const connectTask = yield fork(connect);
    yield take(SOCKET_DISCONNECT);
    yield cancel(connectTask);
}

function* clean() {
    const channel = yield call(createCleanerChannel);
    try {
        while (true) {
            const action = yield take(channel);
            yield put(action);
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

function* downloadFile(action: FileDownloadAction) {
    const progressKey = `${DownloadProgressStoragePrefix}-${action.uploadProgress.id}`;

    let session = localStorage.getItem(progressKey);
    if (!session) {
        localStorage.setItem(progressKey, JSON.stringify({
            sessionId: CURRENT_SESSION_ID,
            expireAt: (new Date()).getTime() + 60 * 1000
        }));

        setTimeout(
            () => {
                session = localStorage.getItem(progressKey);
                if (session) {
                    const sessionJson: StorageDownloadFileSession = JSON.parse(session);
                    if (CURRENT_SESSION_ID === sessionJson.sessionId) {
                        startDownload(action.link);
                        setTimeout(() => localStorage.removeItem(progressKey), 1000);
                    }
                }
            },
            300
        );
    }
}

function* cleanDownloadFileGarbage() {
    Object.keys(localStorage)
        .filter(key => key.indexOf(DownloadProgressStoragePrefix) === 0)
        .forEach(key => {
            const session = localStorage.getItem(key);
            if (session) {
                const sessionJson: StorageDownloadFileSession = JSON.parse(session);
                if (sessionJson.expireAt < (new Date()).getTime()) {
                    localStorage.removeItem(key);
                }
            }
        });
}

function* watchSubscribe() {
    yield takeEvery(SOCKET_CONNECT, subscribe);
}

function* watchGetUploadProgress() {
    while (true) {
        yield take(GET_FULL_UPLOAD_PROGRESS);
        socketHelper.send({action: SocketActions.InProgressFull});
    }
}

function* watchUploadHistory() {
    yield take(SOCKET_OPENED);
    while (true) {
        const action: GetUploadHistoryAction = yield take(GET_UPLOAD_HISTORY);
        put(setUploadHistory(action.uploadType, []));
        socketHelper.send({
            action: SocketActions.History,
            type: action.uploadType,
            filter: action.filter
        });
    }
}

function* watchUpdateUploadProgressDebounce() {
    yield takeLatest(UPDATE_UPLOAD_PROGRESS_DEBOUNCE, function* (action: UpdateUploadProgressDebounceAction) {
        yield delay(10);
        yield put(updateUploadProgress(action.progress));
    });
}

function* watchUpdateUploadStatus() {
    yield takeEvery(UPDATE_UPLOAD_PROGRESS_DEBOUNCE, function* (action: UpdateUploadStatusAction) {
        if (action.progress.action === UploadAction.IMPORT) {
            if (action.progress.status === UploadStatus.New) {
                yield put(startUploading(action.progress));
            }
            if (END_UPLOAD_STATUSES.indexOf(action.progress.status) !== -1) {
                yield put(endUploading(action.progress));
            }
        }
    });
}

function* cancelImportExport(action: SetCancelImportExportAction) {
    socketHelper.send({
        action: SocketActions.Cancel,
        file_id: action.fileId
    });

    yield put(getFullUploadProgress());
}

function* watchCancelImportExport() {
    yield takeEvery(SET_CANCEL_IMPORT_EXPORT, cancelImportExport);
}

function* watchDownloadFile() {
    yield takeEvery(DOWNLOAD_EXPORT_FILE, downloadFile);
}

function* runCleaner() {
    yield fork(clean);

    yield takeEvery(CLEAN_LOCAL_STORAGE, cleanDownloadFileGarbage);
}

export default function* root() {
    yield all(
        [
            watchSubscribe(),
            watchGetUploadProgress(),
            watchUpdateUploadProgressDebounce(),
            watchUpdateUploadStatus(),
            watchUploadHistory(),
            watchCancelImportExport(),
            watchDownloadFile(),
            runCleaner()
        ]
    );
}
