import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';

import mockRequests from './mockRequests';
import mockUser from './mockUser';
import mockFundReporting from './mockFundReporting';

export default (axios: AxiosInstance) => {
    let mock = new MockAdapter(axios);
    // mockUser(mock);
    // mockRequests(mock);
    // mockFundReporting(mock);

    mock.onAny().passThrough();
};