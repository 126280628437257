import { Action } from 'redux';
import { Request } from './models/request';
import { FundRequestRelation, RequestListPermissions } from './types';
import { Fund } from './models/fund';
import { ContactModel } from '../Fund/models/contact';
import { Id, RequestChangeValue } from '../types';

export const APPLY_FILTER = 'store/requests/applyFilter';
export const APPLY_FILTER_MANAGER_SCREEN = 'store/requests/applyFilter/managerScreen';
export const CLOSE_ROW = 'store/requests/closeRow';
export const SET_MANAGER_SCREEN_REQUESTS = 'store/requests/setManagerScreenRequests';
export const SET_REQUESTS = 'store/requests/setRequests';
export const BULK_UPDATE_REQUESTS = 'store/requests/bulkUpdateRequests';
export const SAVE_EDITED_REQUEST = 'store/requests/saveRequest';
export const CHANGE_EDITED_REQUEST = 'store/requests/changeEditedRequest';
export const SET_EDITED_REQUEST = 'store/requests/setEditedRequest';
export const GET_REQUESTORS = 'store/requests/getRequestors';
export const SET_REQUESTORS = 'store/requests/setRequestors';
export const CANCEL_BULK_UPLOAD = 'store/requests/cancelBulkUpload';
export const BULK_UPLOAD = 'store/requests/bulkUpload';
export const DOWNLOAD_REQUESTS = 'store/requests/downloadRequests';
export const GET_FUND_CONTACTS = 'store/requests/getFundContacts';
export const SET_FUND_CONTACTS = 'store/requests/setFundContacts';
export const SET_PENDING_REQUESTS = 'store/requests/setPendingRequests';
export const SET_PENDING_NOTIFICATION_FLAG = 'store/requests/hidePendingNotificationFlag';
export const TOGGLE_EDIT_MODE = 'store/requests/toggleEditMode';
export const SEND_REQUEST = 'store/requests/send';
export const SEND_REQUEST_FROM_DATA_REPORTING = 'store/requests/sendFromDataReporting';
export const SEND_BULK_REQUESTS = 'store/requests/sendBulk';

export interface ApplyFilterAction extends Action {
    filter: any;
}

export interface ApplyFilterManagerScreenAction extends Action {
    filter: any;
}

export interface CloseRowAction extends Action {
    id: Id;
}

export interface SetManagerScreenRequestsAction extends Action {
    count: number;
    requests: any;
    withScroll: boolean;
}

export interface SetRequestsAction extends Action {
    count: number;
    funds: Fund[];
    permissions: RequestListPermissions;
    withScroll: boolean;
}

export interface BulkUpdateRequestsAction extends Action {
    requests: Request[];
}

export interface SaveEditedRequestAction extends Action {
    relations: FundRequestRelation[];
    request: Request;
    withContacts: boolean;
    checkedAll: boolean;
}

export interface ChangeEditedRequestAction extends Action {
    field: string;
    value: RequestChangeValue | ContactModel[];
}

export interface SetEditedRequestAction extends Action {
    request: Request;
}

export interface CancelBulkUploadAction extends Action {
    payload: any;
}

export interface BulkUploadAction extends Action {
    files: any;
}

export interface DownloadRequestsAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
}

export interface GetRequestorsAction extends Action {
    id: Id;
    status: any;
}

export interface SetRequestorsAction extends Action {
    requestors: any;
    parentId: Id;
    status: any;
}

export interface GetFundContactsAction extends Action {
    fundId: Id;
}

export interface SetFundContactsAction extends Action {
    contacts: ContactModel[];
}

export interface SetPendingRequestsAction extends Action {
    count: number;
}

export interface SetPendingNotificationFlagAction extends Action {
    flag: boolean;
}

export interface ToggleEditMode extends Action {
    mode?: boolean;
}

export interface SendBulkRequestsAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
}

export interface SendRequestAction extends Action {
    fundId: Id;
}

export interface SendRequestFromDataReportingAction extends Action {
    fundId: Id;
}

export type RequestsAction = ApplyFilterAction |
    ApplyFilterManagerScreenAction |
    CloseRowAction |
    SetRequestsAction |
    BulkUpdateRequestsAction |
    SaveEditedRequestAction |
    ChangeEditedRequestAction |
    SetEditedRequestAction |
    CancelBulkUploadAction |
    GetRequestorsAction |
    SetRequestorsAction |
    GetFundContactsAction |
    BulkUploadAction |
    DownloadRequestsAction |
    SetFundContactsAction |
    SetPendingRequestsAction |
    SetPendingNotificationFlagAction |
    ToggleEditMode |
    SendBulkRequestsAction |
    SendRequestAction |
    SendRequestFromDataReportingAction;