import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { API_BULK_IMPORT_URL } from 'helpers/creditApiHelper';
import { BulkUploadModal } from 'components/Shared/BulkUpload/BulkUploadModal';

import { isModalOpen } from 'store/Modals/General/selectors';
import { closeModal } from 'store/Modals/General/actionCreators';
import { AppState, Id, TableRowAction } from 'store/types';
import { getBulkUploadHistory, getBulkUploadProgress } from 'store/BulkUpload/selectors';
import { BulkUploadProgress, UploadType } from 'store/BulkUpload/types';
import { FormatTypeChoices, TableRowActionIcon, TableRowActionType } from 'store/constants';
import { BULK_UPLOAD_TYPE_DATASET, DATASET_UPLOAD_MODAL } from 'store/DataManager/constants';
import { datasetUpload } from 'store/DataManager/actionCreators';
import { getUploadHistory, setCancelImportExport } from 'store/BulkUpload/actionCreator';
import { BULK_ITEM_NAME, BULK_ITEM_NAME_PLURAL } from 'store/BulkUpload/constants';
import { getUser } from 'store/Auth/selectors';
import ActiveUser from 'store/Auth/models/activeUser';

interface Props {
    isOpen: boolean;
    closeModal: () => any;
    uploadHistory: any;
    getUploadHistory: (uploadType: UploadType) => any;
    importProgress: BulkUploadProgress[];
    datasetUpload: any;
    user: ActiveUser;
    setCancelImportExport: (fileId: Id) => any;
}

interface RouteProps {
}

class BulkUploadModalContainer extends React.PureComponent<Props & RouteComponentProps<RouteProps>> {
    rowActions: TableRowAction[] = [
        {
            type: TableRowActionType.Link,
            icon: TableRowActionIcon.BulkUpload,
            link: `${API_BULK_IMPORT_URL}/files/{id}/`,
        },
        {
            type: TableRowActionType.Link,
            icon: TableRowActionIcon.Download,
            link: `${API_BULK_IMPORT_URL}/files/{id}/error/`,
            isVisible: (row: any) => {
                return !!row.related_file;
            },
        },
    ];

    componentDidMount() {
        this.props.getUploadHistory(UploadType.DataManager);
    }

    handleCancelUpload = (lastFileId: Id) => {
        this.props.setCancelImportExport(lastFileId);
    }

    handleEditResult = (lastFileId: Id) => {
        this.props.closeModal();
    }

    handleUpload = (files: File[]) => {
        const formData = new FormData();
        formData.append('import_file', files[0]);
        formData.append('type', BULK_UPLOAD_TYPE_DATASET);
        this.props.datasetUpload(formData);
    }

    render() {
        const {isOpen} = this.props;

        const successMessage = (
            <>
                <div>Success! Upload Complete</div>
                <div>You can now view and edit your recently uploaded dataset or upload new datasets</div>
            </>
        );

        const processedWithErrorsMessage = (
            <>
                <div>Completed - contains errors! Upload Complete</div>
                <div>You can now view and edit your recently uploaded dataset or upload new datasets below</div>
            </>
        );

        return (
            <BulkUploadModal
                isOpen={isOpen}
                size={'lg'}
                rowActions={this.rowActions}
                closeModal={this.props.closeModal}
                uploadHistory={this.props.uploadHistory}
                progress={this.props.importProgress}
                upload={this.handleUpload}
                cancelUpload={this.handleCancelUpload}
                editResult={this.handleEditResult}
                entityNameSingle={BULK_ITEM_NAME[UploadType.DataManager]}
                entityNamePlural={BULK_ITEM_NAME_PLURAL[UploadType.DataManager]}
                entityNameFormatType={FormatTypeChoices.CountDatasets}
                user={this.props.user}
            >
                {{successMessage, processedWithErrorsMessage}}
            </BulkUploadModal>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    isOpen: isModalOpen(state, {name: DATASET_UPLOAD_MODAL}),
    importProgress: getBulkUploadProgress(state, { type: UploadType.DataManager }),
    uploadHistory: getBulkUploadHistory(state, { type: UploadType.DataManager }),
    user: getUser(state),
});

const mapDispatchToProps = {
    setCancelImportExport,
    closeModal,
    datasetUpload,
    getUploadHistory,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(BulkUploadModalContainer);