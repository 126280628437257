import { FiltersState } from '../Filters/types';
import {
    APPLY_FILTER,
    APPLY_DISPERSION_FILTER,
    GET_STATISTIC_DONUT_REPORT,
    SET_STATISTIC_DONUT_REPORT,
    SET_DASHBOARD_DATA_WITH_SCROLL,
    SET_DASHBOARD_DATA,
    GET_PERFORMANCE_DONUT_REPORT,
    GET_ALERT_DONUT_REPORT,
    GET_CHART_DATA,
    SET_CHART_DATA,
    GET_DASHBOARD_DATA,
    SET_BOX_CHART_DATA,
    GET_BOX_CHART_DATA,
    UPDATE_DISPERSION_SETTINGS, UPDATE_DASHBOARD_SETTINGS,
} from './actions';
import {
    StatisticDonutDataType,
    DashboardGridItem,
    ChartsDataForPeriod,
    BoxChartsDataForPeriod,
    DispersionChartType,
} from './types';
import { DonutPiece } from '../Shared/types';
import { UserAccountSettings } from '../Auth/models/userAccount';

export const applyFilter = (filter: FiltersState) => {
    return {
        type: APPLY_FILTER,
        filter
    };
};

export const applyDispersionFilter = (filter: FiltersState) => {
    return {
        type: APPLY_DISPERSION_FILTER,
        filter
    };
};

export const getDashboardData = (filter: FiltersState) => {
    return {
        type: GET_DASHBOARD_DATA,
        filter
    };
};

export const setDashboardData = (
    filter: FiltersState,
    rows: DashboardGridItem[],
    rowsCount: number,
    firmsCount: number,
    fundsCount: number,
    withScroll: boolean = false,
) => ({
    type: withScroll
        ? SET_DASHBOARD_DATA_WITH_SCROLL
        : SET_DASHBOARD_DATA,
    filter, rows, rowsCount, fundsCount, firmsCount
});

export const getStatisticDonutReport = (
    filter: FiltersState,
    dataType: StatisticDonutDataType
) => ({
    type: GET_STATISTIC_DONUT_REPORT,
    filter, dataType
});

export const getPerformanceDonutReport = (filter: FiltersState) => ({
    type: GET_PERFORMANCE_DONUT_REPORT,
    filter
});

export const getAlertDonutReport = (filter: FiltersState) => ({
    type: GET_ALERT_DONUT_REPORT,
    filter
});

export const setStatisticDonutReport = (
    data: DonutPiece[],
    dataType: StatisticDonutDataType
) => ({
    type: SET_STATISTIC_DONUT_REPORT,
    data, dataType
});

export const setChartData = (data: ChartsDataForPeriod, force: boolean = false) => ({
    type: SET_CHART_DATA,
    data, force
});

export const getChartData = (
    filter: FiltersState,
    force: boolean = false
) => ({
    type: GET_CHART_DATA,
    filter, force
});

export const setBoxChartData = (data: BoxChartsDataForPeriod) => ({
    type: SET_BOX_CHART_DATA,
    data
});

export const getBoxChartData = (filter: FiltersState) => ({
    type: GET_BOX_CHART_DATA,
    filter
});

export const updateDispersionSettings = (
    filterName: string,
    userAccountSettings: UserAccountSettings,
    waitResponse: boolean
) => ({
    type: UPDATE_DISPERSION_SETTINGS,
    filterName, userAccountSettings, waitResponse
});

export const updateDashboardSettings = (
    filterName: string,
    userAccountSettings: UserAccountSettings,
    waitResponse: boolean
) => ({
    type: UPDATE_DASHBOARD_SETTINGS,
    filterName, userAccountSettings, waitResponse
});
