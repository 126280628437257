import { createSelector } from 'reselect';
import { getModalsState } from 'store/Modals/selectors';
import { ModalsState } from 'store/Modals/types';
import { AppState } from 'store/types';
import { GeneralModalsState } from 'store/Modals/General/types';

export const getGeneralModalsState = createSelector(
    getModalsState,
    (modals: ModalsState) => modals.general
);

export const getModalName = (state: AppState, props: any) => props.hasOwnProperty('name') ? props.name : '';

export const isModalOpen = createSelector(
    getGeneralModalsState,
    getModalName,
    (state: GeneralModalsState, name: string) => !!state.name && state.name === name
);

export const getModalParams = createSelector(
    getGeneralModalsState,
    (state: GeneralModalsState) => state.params
);