import * as React from 'react';
import * as classNames from 'classnames';
import Checkbox from 'components/Diligence/Shared/Ui/Checkbox';
import Scrollbar from 'components/Shared/Scroll/Scrollbar';
import { ScrollStatus } from 'smooth-scrollbar/interfaces';
import { FiltersState } from 'store/Filters/types';
import { RequestFilterItem } from 'store/Diligence/types';
import { SelectItem, FilterItem } from 'store/types';
import { DiligenceAttributeTypes } from 'decorators/AdvancedFilterDecorator';

interface Props {
    filterEntities?: SelectItem[];
    filter: FilterItem;
    handleChange: (data: FiltersState) => void;
    value: RequestFilterItem;
    filterPageName?: string;
}

const styles = require('./DropdownInputFilterResults.scss');
const checkboxStyles = require('components/Diligence/Shared/Ui/Checkbox.scss');

const DropdownInputFilterResults: React.FC<Props> = (props) => {
    const {filterEntities, handleChange, value, filter, filterPageName} = props;
    const classes = classNames({
        [styles.dropdown]: true,
        [styles.active]: filterEntities && filterEntities.length > 0,
    });
    const handleScroll = (status: ScrollStatus) => {
        const setFilters = filter.setFilters;
        const handleRequest = filter.handleRequest;

        if (setFilters && handleRequest) {
            const limit = filter.currentFilter ? filter.currentFilter.limit : 0;
            const offset = filter.currentFilter ? filter.currentFilter.offset : 0;
            const sum = limit + offset;
            if (filter.choicesCount && sum < filter.choicesCount) {
                const bottom = status.limit.y === status.offset.y;
                if (bottom && filter.filterName) {
                    setFilters(
                        filter.filterName,
                        {...filter.currentFilter, offset: sum},
                        filterPageName,
                    );
                    handleRequest({
                        ...filter.currentFilter,
                        offset: sum,
                        scroller: true,
                    });
                }
            }
        }
    };
    return (
        <div className={classes}>
            <Scrollbar
                alwaysShowTracks={false}
                className="select-with-checkboxes"
                onScroll={handleScroll}
            >
                {filterEntities && filterEntities.map((entity: SelectItem, idx: number) => {
                    let checked = false;
                    let result: SelectItem[];
                    let data = {};
                    if (Array.isArray(value)) {
                        result = value as SelectItem[];
                        const valueNames = value.map((v: any) => v.label || v.name);
                        const valueIndex = valueNames.indexOf(entity.label);
                        checked = valueIndex >= 0;
                        result = checked
                            ? result.filter((valueItem: SelectItem) => entity.value !== valueItem.value)
                            : [...result, {label: entity.label, value: String(entity.value)}];

                        if (DiligenceAttributeTypes.includes(filter.name)) {
                            data = {name: entity.label, type: entity.value};
                        } else {
                            data = {[filter.name]: result};
                        }
                    }

                    const uniqueKey = `${entity.label}-${entity.value}-${idx}`;
                    return (
                            <div key={uniqueKey}>
                                <Checkbox
                                    idx={uniqueKey}
                                    className={`${checkboxStyles.checkbox} aligned-checkboxes`}
                                    checked={checked}
                                    handleChange={() => handleChange(data)}
                                >
                                    {entity.label}
                                </Checkbox>
                            </div>
                    );
                })}
            </Scrollbar>
        </div>
    );
};

export default DropdownInputFilterResults;
