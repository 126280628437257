import { Action } from 'redux';

import { FiltersState } from 'store/Filters/types';

export const UPDATE_FILTERS = 'store/shared/modals/advancedModal/update';

export interface UpdateFiltersAction extends Action {
    filter: FiltersState;
    filterName: string;
}

export type AdvancedFiltersModalAction = UpdateFiltersAction;
