import * as React from 'react';
import AlertDefault from '../../Shared/Ui/Alerts/AlertDefault';
import { RequestorModel } from '../../../store/DataManager/models/requestor';
import Input from '../../Shared/Ui/Input';
import { formatText } from '../../../helpers/tableHelper';
import { FormatTypeChoices } from '../../../store/constants';
import { Id } from '../../../store/types';

interface Props {
    fundId: Id;
    fundName: string;
    daysBeforeDueDate: number;
    requestors: RequestorModel[];
    getRequestors: (fundId: Id) => any;
    setDaysBeforeDueDate: (value: number) => any;
}

const styles = require('./PublishModals.scss');

export default class SetPendingPublishModal extends React.Component<Props> {
    componentDidMount() {
        this.props.getRequestors(this.props.fundId);
    }

    componentWillUnmount() {
        this.props.setDaysBeforeDueDate(0);
    }

    handleInputChange = (inputValue: any) => {
        const value = +inputValue;
        this.props.setDaysBeforeDueDate(value);
    }

    render () {
        const requestors: RequestorModel[] = this.props.requestors ? this.props.requestors : [];
        const daysBeforeDueDate: string = '' + this.props.daysBeforeDueDate;

        return (
            <>
                <div className={styles.label}>
                    Days Before Due Date
                </div>
                <Input
                    className={styles['input-number']}
                    value={daysBeforeDueDate}
                    placeholder="0"
                    type="number"
                    handleChange={this.handleInputChange}
                />
                <AlertDefault hasCloseIcon={false}>
                    <span>
                        Dataset(s) regarding <b>{this.props.fundName}</b> will be published to the following
                        requestors:
                    </span>
                    <br />
                    <ul className={styles['ul-list']}>
                        {requestors.map((requestor: RequestorModel, idx: number) => {
                            const dueDate = requestor.due_date
                                ? ': ' + formatText(requestor.due_date, FormatTypeChoices.DateFormat)
                                : '';
                            const inDays = requestor.due_date ?
                                '(' + formatText(requestor.due_date, FormatTypeChoices.FromNow) + ')'
                                : '';
                            return (<li key={idx}>{requestor.requesting_account.name} {dueDate} {inDays}</li>);
                        })}
                    </ul>
                </AlertDefault>
            </>
        );
    }
}