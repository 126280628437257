import * as React from 'react';
import { connect } from 'react-redux';

import ButtonDefault from 'components/Shared/Ui/Buttons/ButtonDefault';
import ButtonPrimary from 'components/Shared/Ui/Buttons/ButtonPrimary';
import SetPendingPublishModal from 'components/DataManager/PublishModals/SetPendingPublishModal';
import Modal from 'components/Shared/Modals/Modal';

import { AppState, Id } from 'store/types';
import { closeModal } from 'store/Modals/General/actionCreators';
import { getModalParams, isModalOpen } from 'store/Modals/General/selectors';
import { SET_PENDING_PUBLISH_MODAL } from 'store/DataManager/constants';
import {
    setPendingPublishDataset, setDaysBeforeDueDate, getRequestorsToSetPendingPublish
} from 'store/DataManager/actionCreators';
import { getSetPendingPublishModal } from 'store/DataManager/selectors';
import { RequestorModel } from 'store/DataManager/models/requestor';

const styles = require('../../../components/DataManager/PublishModals/PublishModals.scss');

interface Props {
    isOpen: boolean;
    closeModal: () => any;
    fundId: Id;
    fundName: string;
    daysBeforeDueDate: number;
    requestors: RequestorModel[];
    getRequestors: (fundId: Id) => any;
    setDaysBeforeDueDate: (value: number) => any;
    setPendingPublishDataset: (fundId: Id, daysBeforeDueDate: number, requestorIds: Id[]) => any;
    customSetPendingPublishDataset?: (fundId: Id, daysBeforeDueDate: number, requestorIds: Id[]) => any;
}

class SetPendingPublish extends React.PureComponent<Props> {

    schedulePublishData = () => {
        let requestorIds: Id[] = [];
        if (this.props.requestors) {
            requestorIds = this.props.requestors.map((requestor: RequestorModel) => requestor.requesting_account.id);
        }

        if (this.props.customSetPendingPublishDataset) {
            this.props.customSetPendingPublishDataset(this.props.fundId, this.props.daysBeforeDueDate, requestorIds);
        } else {
            this.props.setPendingPublishDataset(this.props.fundId, this.props.daysBeforeDueDate, requestorIds);
        }

    }

    render () {
        const buttons = [
            (
                <ButtonPrimary buttonLarge={true} handleClick={this.schedulePublishData}>
                    Schedule
                </ButtonPrimary>
            ),
            (
                <ButtonDefault buttonLarge={true} inverse={true} handleClick={this.props.closeModal}>
                    Cancel
                </ButtonDefault>
            )
        ];

        const body = (
            <SetPendingPublishModal
                fundId={this.props.fundId}
                fundName={this.props.fundName}
                daysBeforeDueDate={this.props.daysBeforeDueDate}
                requestors={this.props.requestors}
                getRequestors={this.props.getRequestors}
                setDaysBeforeDueDate={this.props.setDaysBeforeDueDate}
            />
        );

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.closeModal}
                title="Set Pending Publish Schedule"
                body={body}
                buttons={buttons}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => {
    const modalParams = getModalParams(state) || {};
    const fundId = modalParams && modalParams.fundId ? modalParams.fundId : 0;
    const fundName = modalParams && modalParams.fundName ? modalParams.fundName : '';
    return {
        isOpen: isModalOpen(state, {name: SET_PENDING_PUBLISH_MODAL}),
        requestors: getSetPendingPublishModal(state).requestors,
        daysBeforeDueDate: getSetPendingPublishModal(state).daysBeforeDueDate,
        fundId,
        fundName,
    };
};

const mapDispatchToProps = {
    closeModal,
    setPendingPublishDataset,
    getRequestors: getRequestorsToSetPendingPublish,
    setDaysBeforeDueDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPendingPublish);