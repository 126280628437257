import { connect } from 'react-redux';

import { AppState } from '../../../store/types';
import PerformanceConfigurationModal
    from '../../../components/DataManager/DatasetDetailModals/PerformanceConfigurationModal';
import { getHeaders } from '../../../store/VisibleHeaders/selectors';
import { headerNames } from '../../../store/VisibleHeaders/types';
import { getDatasetDetailTemporaryHeaders } from '../../../store/DataManager/selectors';
import { setDatasetDetailTemporaryHeaders } from '../../../store/DataManager/actionCreators';

const mapStateToProps = (state: AppState) => ({
    temporaryHeaders: getDatasetDetailTemporaryHeaders(state),
    headers: getHeaders(state, {name: headerNames.datasetDetail}),
});

const mapDispatchToProps = {
    setDatasetDetailTemporaryHeaders,
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceConfigurationModal);