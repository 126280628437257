export const ADD_PORTFOLIO_MODAL_TITLE = 'Add New Portfolio';
export const CONFIRM_DELETE_PORTFOLIO_MODAL_TITLE = 'Confirm Portfolio Deletion';
export const CREATE_PORTFOLIO_SUCCESS_MESSAGE = 'New portfolio has been created';
export const CREATE_PORTFOLIO_FAIL_MESSAGE = 'Portfolio creation failed';
export const CREATE_ENTITIES_SUCCESS_MESSAGE = 'Entities are creating';
export const CREATE_ENTITIES_FAIL_MESSAGE = 'Entities creation failed';
export const ADD_PORTFOLIO_MODAL = 'ADD_PORTFOLIO_MODAL';
export const CONFIRM_DELETE_PORTFOLIO_MODAL = 'CONFIRM_DELETE_PORTFOLIO_MODAL';

export const CREATE_FUND_SUCCESS_MESSAGE = 'The new fund has been added';
export const CREATE_FUND_FAIL_MESSAGE = 'New fund creation failed';
export const ADD_BULK_UPLOAD_MODAL = 'ADD_BULK_UPLOAD_MODAL';

export const EDIT_PORTFOLIO_TITLE = 'EDIT_PORTFOLIO_TITLE';

export const initialPortfolioModel = {
    name: '',
    description: ''
};

export const initialState = {
    filter: {
        page: 0
    },
    hideBottomArea: false,
    count: 0,
    countUploadedRows: 0,
    list: [],
    importProgress: {
        flag: false,
        count_total: 0,
        count_processed: 0,
    },
    newPortfolio: {
        ...initialPortfolioModel
    },
    portfolio: {
        id: '',
        ...initialPortfolioModel
    },
};

export const PORTFOLIO_BULK_UPLOAD_NAME = 'portfolio';