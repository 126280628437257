import { UserGlobalRole } from 'store/Auth/types';

export const SWITCH_ACCOUNT_SUCCESS_MESSAGE = 'Account was switched successfully';
export const SWITCH_ACCOUNT_FAIL_MESSAGE = 'Account switching failed';

const defaultUser = {
    id: '',
    _id: {id: '', _string: ''},
    email: '',
    first_name: '',
    last_name: '',
    firstName: '',
    lastName: '',
    name: '',
    type: '',
    status: '',
    last_login: '',
    created_at: '',
    account_relations: [],
    is_internal: false,
    global_role: UserGlobalRole.None,
    has_diligence_account: true,
    twoFactorAuthSettings: {enabled: false, rememberCookie: false, type: ''}
};

export const initialState = {
    isInitialized: false,
    qrURI: '',
    secret: '',
    user: defaultUser
};

export const messages = {
    TWO_FACTOR_AUTH_SUCCESS_MESSAGE: 'Two-factor authentication is enabled',
    TWO_FACTOR_AUTH_FAIL_MESSAGE: 'Two-factor authentication was not enabled',
    DISABLE_TWO_FACTOR_AUTH_SUCCESS_MESSAGE: 'Two-factor authentication was disabled',
    DISABLE_TWO_FACTOR_AUTH_FAIL_MESSAGE: 'Two-factor authentiation was not properly disabled',
    ENABLE_REMEMBER_COOKIE_SUCCESS_MESSAGE: 'Two-factor authentication code will only be required ' +
        'every 30 days on this machine, starting today.',
    DISABLE_REMEMBER_COOKIE_SUCCESS_MESSAGE: 'Two-Factor authentication code will now be required each ' +
        'time you login to this machine.',
    UPDATE_REMEMBER_COOKIE_FAIL_MESSAGE: 'Update remember cookie failed.',
    GENERATE_TFA_SECRET_FAIL: 'Could not generate two-factor auth secret'
};