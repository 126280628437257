import { UserAccount } from 'store/Auth/models/userAccount';
import ActiveUser from 'store/Auth/models/activeUser';

export function hasDiligenceId(activeAccount: UserAccount) {
    return !!activeAccount.account.diligence_id;
}

export function userAccountHasMasterPortfolio(activeAccount: UserAccount) {
    return hasDiligenceId(activeAccount);
}

export function userHasMasterPortfolio(user: ActiveUser) {
    return hasDiligenceId(user.active_account);
}
