import * as React from 'react';
import * as classNames from 'classnames';

interface LoaderType {
    isLoading: boolean | undefined;
    className?: any;
    position?: any;
    size?: any;
}

const styles = require('./Loader.scss');
const Loader: React.FC<LoaderType> = ({ isLoading, className, size, position }) => {
    const ClassNames = classNames({
        [className]: !!className,
        [styles.ui_loader]: !!styles.ui_loader,
        [styles.ui_loader_xs]: !!size,
        [`loader-${position}`]: !!position,
    });
    let answer: any = '';
    if (isLoading) {
        answer =
            (
                <div className={ClassNames}/>
        );
    }
    return answer;
};
Loader.defaultProps = {
    size: 'xs'
};

export default Loader;
