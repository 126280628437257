import * as React from 'react';
import { NavLink } from 'react-router-dom';
import * as classNames from 'classnames';

import { replaceParam } from 'helpers/tableHelper';
import DeviceHelper from 'helpers/deviceHelper';

import { Id, TableRowAction } from 'store/types';
import { getIconSourceByName } from 'store/helpers';
import { TableRowActionIcon, TableRowActionType } from 'store/constants';

import Popover from 'components/Shared/Ui/Popover';

import DropdownIcon from 'components/Shared/Ui/IconButtons/DropdownIcon';
const styles = require('./DataTable.scss');
const rowStyles = require('./RowActionsColumn.scss');

interface Props {
    action: TableRowAction;
    idx?: number;
    rowIdx?: number;
    row: any;
    isEditing?: boolean;
    className?: string;
}

class RowActionsColumn extends React.PureComponent<Props> {

    isActionDisabled = (): boolean => {
        const {action, row, rowIdx} = this.props;
        return !!action.disabled && action.disabled(row, rowIdx);
    }

    get icon(): TableRowActionIcon | undefined  {
        const {action, row} = this.props;
        return typeof action.icon === 'function' ? action.icon(row) : action.icon;
    }

    render() {
        const {action, row, className, isEditing, idx} = this.props;
        let alt = this.icon;

        const {icon, widthClass} = this.getIconInfo();

        const linkTemplate = action.link ? action.link : '';
        const route = replaceParam(linkTemplate, row);

        let actionElement: React.ReactNode = null;

        const iconClass = classNames({
            [styles.tableIcon]: true,
            [widthClass]: true,
            [styles.disabled]: this.isActionDisabled(),
        });

        if (action.type === TableRowActionType.NavLink && action.link && !isEditing) {
            actionElement = (
                <span className={className}>
                    <NavLink to={route}><img src={icon} alt={alt} className={iconClass} /></NavLink>
                </span>
            );
        } else if (action.type === TableRowActionType.Link && action.link && !isEditing) {
            actionElement = (
                <span className={className}>
                    <a href={route}><img src={icon} alt={alt} className={iconClass}/></a>
                </span>
            );
        } else if (action.type === TableRowActionType.Image) {
            actionElement = this.renderTooltip(`id-${action.type}-${idx ? idx : '' }-${row.id}`, iconClass);
        } else if (action.type === TableRowActionType.Dropdown) {
            actionElement = (
                <DropdownIcon
                    icon={TableRowActionIcon.Download}
                    items={action.props ? action.props.items : []}
                    onClick={(item) => action.props
                        ? action.props.onClick(item, row)
                        : () => true}
                />
            );
        } else if (action.type === TableRowActionType.Custom && action.transformer) {
            actionElement = action.transformer(row);
        }

        return actionElement;
    }

    private renderTooltip(id: string, iconClassName: string) {
        const {action, className} = this.props;

        return (
            <span className={className}>
                <Popover
                    id={id}
                    body={action.tooltip}
                    enabled={!!action.tooltip && !DeviceHelper.isTouchSupported()}
                >
                    {this.renderIcon(iconClassName)}
                </Popover>
            </span>
        );
    }

    private renderIcon(iconClassName: string) {
        const {icon, fontClassName} = this.getIconInfo();

        if (fontClassName) {
            return (
                <span
                    className={`${fontClassName} ${iconClassName} ${rowStyles['font-icon']}`}
                    onClick={this.handleClick}
                />
            );
        }

        return (
            <img
                src={icon}
                alt={this.icon}
                className={iconClassName}
                onClick={this.handleClick}
            />
        );
    }

    private getIconInfo() {
        let widthClass = 'w-23',
            icon = getIconSourceByName(this.icon),
            fontClassName = '';

        switch (this.icon) {
            case TableRowActionIcon.Pencil:
                widthClass = 'w-15';
                break;
            case TableRowActionIcon.BigPencil:
                widthClass = 'w-24';
                break;
            case TableRowActionIcon.Trash:
                widthClass = 'w-15';
                break;
            case TableRowActionIcon.BigTrash:
                break;
            case TableRowActionIcon.Download:
                break;
            case TableRowActionIcon.Upload:
                widthClass = 'w-24';
                break;
            case TableRowActionIcon.BulkUpload:
                break;
            case TableRowActionIcon.Close:
                widthClass = 'w-15';
                break;
            case TableRowActionIcon.Cross:
                widthClass = 'w-15';
                break;
            case TableRowActionIcon.CrossGray:
                widthClass = 'w-15';
                break;
            case TableRowActionIcon.Refresh:
                widthClass = 'w-15';
                break;
            case TableRowActionIcon.Clock:
                widthClass = 'w-20';
                break;
            case TableRowActionIcon.Locked:
                fontClassName = 'icon-locked';
                break;
            case TableRowActionIcon.Unlocked:
                fontClassName = 'icon-unlocked';
                break;
            case TableRowActionIcon.Discard:
                fontClassName = 'icon-discard';
                break;
            case TableRowActionIcon.Send:
                widthClass = 'w-15';
                break;
            case TableRowActionIcon.UpdateInDB:
                fontClassName = 'icon-update-in-db';
                break;
            case TableRowActionIcon.View:
            default:
                widthClass = 'w-24';
                break;
        }

        return {
            widthClass, icon, fontClassName
        };
    }

    private handleClick = () => {
        const {action, row} = this.props;
        return action.handler && !this.isActionDisabled()
            ? action.handler(row.id, row)
            : (id: Id) => { return; };
    }
}

export default RowActionsColumn;
