import { HistoryPeriodChoices } from '../constants';
import { DataEntry, FundAttributes, FundStatus } from './types';
import { SelectItem } from 'store/types';
import DataManagerHelper from 'helpers/dataManagerHelper';

export const FUNDS_BULK_UPLOAD_NAME = 'funds';

export const BULK_UPLOAD_TYPE_FUND = '7';
export const BULK_UPLOAD_TYPE_FUND_DATASETS = '5';
export const ID_FOR_NEW_ROW = '';

export const ADD_FUND_MODAL_TITLE = 'Add New Fund';
export const CREATE_FUND_SUCCESS_MESSAGE = 'New fund has been created';
export const FUND_ALREADY_EXISTS_MESSAGE = 'This fund has already been added';
export const CREATE_FUND_FAIL_MESSAGE = 'New fund creation is failed';
export const ADD_FUND_MODAL = 'ADD_FUND_MODAL';
export const BULK_UPLOAD_MODAL = 'BULK_UPLOAD_MODAL';

export const CONFIRM_DELETE_CONTACT_MODAL = 'CONFIRM_DELETE_CONTACT_MODAL';
export const CONFIRM_DELETE_CONTACT_MODAL_TITLE = 'Delete Contact';

export const CONFIRM_DELETE_FUND_MODAL = 'CONFIRM_DELETE_FUND_MODAL';
export const CONFIRM_DELETE_FUND_MODAL_TITLE = 'Delete Funds';

export const ADD_ITEMS_TO_STATISTICS_MODAL = 'ADD_ITEMS_TO_STATISTICS_MODAL';
export const ADD_ITEMS_TO_STATISTICS_MODAL_TITLE = 'Add Another...';

export const DATASETS_LEAVE_PAGE_MODAL_TITLE = 'Warning';

export const DELETE_FUND_SUCCESS = 'Fund was successfully removed';
export const DELETE_FUNDS_SUCCESS = 'Funds were successfully removed';
export const DELETE_FUND_FAIL = 'Fund removal failed';

export const DELETE_FUND_CONTACT_SUCCESS_MESSAGE = 'Contact has been successfully removed';
export const DELETE_FUND_CONTACT_FAIL_MESSAGE = 'Contact removal failed';
export const UPDATE_FUND_CONTACT_SUCCESS_MESSAGE = 'Contact has been successfully updated';
export const UPDATE_FUND_CONTACT_FAIL_MESSAGE = 'Contact update failed';
export const CREATE_FUND_CONTACT_SUCCESS_MESSAGE = 'Contact has been successfully created';
export const CREATE_FUND_CONTACT_FAIL_MESSAGE = 'Contact creation failed';

export const SAVE_REQUESTOR_DATASET_FAIL_MESSAGE = 'Dataset save failed';

export const FUND_LIST_COLUMNS_MODAL = 'FUND_LIST_COLUMNS_MODAL';
export const FUND_PROFILE_SETTINGS_MODAL = 'FUND_PROFILE_SETTINGS_MODAL';

export const COUNT_VISIBLE_YEARS_ON_PROFILE_PAGE = 6;

export const defaultFundPermissions = {
    can_bulk_upload_datasets: true,
    can_create_contact: true,
    can_edit_contact: true,
    can_delete_contact: true,
    can_create_request: true,
    can_edit_request: true,
    can_send_request: true,
    periods: null,
};

export const allPeriodsKey = 'all';

export const initialFundModel = {
    id: '',
    ap_id: '',
    name: '',
    alias: '',
    internal_id: '',
    firm: {
        id: '',
        name: '',
    },
    fund_attributes: [],
    fund_details: {
        lei: [],
        sec: [],
        cik: [],
        ticker: [],
        class_id: [],
        series_id: [],
        fund_type: null,
        domicile_state: null,
        domicile_country: null,
        number_of_owners: null,
        form_file_number: [],
        minimum_investment: null
    },
    service_providers: {
        administrator: [],
        prime_broker: [],
        custodian: [],
        auditor: [],
        marketers: []
    },
    permissions: {
        ...defaultFundPermissions
    }
};

export const initialFundDetailsModel = {
    lei: [],
    sec: [],
    cik: [],
    ticker: [],
    class_id: [],
    series_id: [],
    fund_type: '',
    domicile_state: '',
    domicile_country: '',
    number_of_owners: '',
    form_file_number: [],
    minimum_investment: '',
};

export const initialServiceProvidersModel = {
    administrator: [],
    prime_broker: [],
    custodian: [],
    auditor: [],
    marketers: [],
};

export const initialUserSettings = {
    remembered_selected_funds: [],
    remembered_selected_groups: [],
};

export const initialFundDatasets = {
    history_datasets: [],
    latest_dataset: {
        ending_balance: null,
        perf_month: null,
        perf_ytd: null,
        period: DataManagerHelper.getActivePeriod(),
        data_entry: null,
        is_empty: null,
    }
};

export const initialDetailedFundModel = {
    ...initialFundModel,
    fund_details: {
        ...initialFundDetailsModel,
    },
    service_providers: {
        ...initialServiceProvidersModel,
    },
    fund_profile_user_settings: {
        ...initialUserSettings,
    }
};

export const attributeModel = {
    countAttributes: 0,
    attributes: [],
};

export const defaultFundListPermissions = {
    can_bulk_upload_funds: true,
    can_add_fund: true,
};

export const initialState = {
    list: [],
    count: 0,
    permissions: {...defaultFundListPermissions},
    firmCount: 0,
    filter: {
        page: 0
    },
    fund: {
        ...initialDetailedFundModel,
    },
    newFund: {
        ...initialFundModel,
    },
    potentialFunds: [],
    contacts: [],
    dataReporting: {
        history: []
    },
    fundProfile: {
        historyPeriod: HistoryPeriodChoices.YTD,
        selectedPeriod: null,
    },
    datasets: [],
    datasetsCount: 0,
    datasetListIsUpdated: false,
    credit_officer: {
        ...attributeModel
    },
    product: {
        ...attributeModel
    },
    strategy: {
        ...attributeModel
    },
    fundListTemporaryHeaders: [],
    dataEntries: [],
    statistics: [],
    alertsOverview: [],
    chartConfig: {
        start: 0,
        end: 0
    },
};

export const DefaultFundProfileState = {
    filter: {
        display_fund_data_source: false
    }
};

export const historyPeriods = [
    { label: 'YTD', value: HistoryPeriodChoices.YTD, },
    { label: '1YR', value: HistoryPeriodChoices.OneYear, },
    { label: '3YR', value: HistoryPeriodChoices.ThreeYears, },
    { label: '5YR', value: HistoryPeriodChoices.FiveYears, },
];

export const FUND_STATUS_TITLES = {
    [FundStatus.Partial]: 'Partial Data',
    [FundStatus.OnTime]: 'On Time',
    [FundStatus.Late]: 'Late',
    [FundStatus.Overdue]: 'Overdue',
    [FundStatus.AwaitingData]: 'Awaiting Data',
    [FundStatus.NoRequest]: 'No Request',
};

export const FUND_STATUS_CHOICES: SelectItem[] = [
    {label: FUND_STATUS_TITLES[FundStatus.AwaitingData], value: FundStatus.AwaitingData},
    {label: FUND_STATUS_TITLES[FundStatus.Late], value: FundStatus.Late},
    {label: FUND_STATUS_TITLES[FundStatus.NoRequest], value: FundStatus.NoRequest},
    {label: FUND_STATUS_TITLES[FundStatus.OnTime], value: FundStatus.OnTime},
    {label: FUND_STATUS_TITLES[FundStatus.Overdue], value: FundStatus.Overdue},
    {label: FUND_STATUS_TITLES[FundStatus.Partial], value: FundStatus.Partial},
];

export const DATA_ENTRIES = {
    [DataEntry.Requestor]: 'Requestor',
    [DataEntry.AlphaPipe]: 'AlphaPipe',
    [DataEntry.FundManager]: 'Fund Manager',
};

export const FUND_ATTRIBUTES_TITLES = {
    [FundAttributes.CreditOfficer]: 'Coverage',
    [FundAttributes.Product]: 'Product',
    [FundAttributes.Strategy]: 'Strategy'
};

export const DATA_SOURCES = {
    [DataEntry.Requestor]: 'Internal',
    [DataEntry.AlphaPipe]: 'AlphaPipe',
    [DataEntry.FundManager]: 'Fund Manager',
};