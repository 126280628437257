import { AppState } from '../types';
import { ActionInfo, ActionsState, ActionStatus } from './types';
import { initialState } from './constants';
import { createSelector } from 'reselect';

export const getActionsState = (state: AppState): ActionsState => state.actions || initialState;
export const getActionName = (state: AppState, props: {name: string}) => props.name;

export const getActionInfo = createSelector(
    getActionsState,
    getActionName,
    (state: ActionsState, name: string) => {
        if (state.hasOwnProperty(name)) {
            return state[name];
        }
        return undefined;
    }
);

export const getActionIsInProgress = createSelector(
    getActionInfo,
    (info ?: ActionInfo) => !!info && info.status === ActionStatus.InProgress
);
