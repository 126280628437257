import Nanobar from 'nanobar';
import { ActionsState, ActionStatus } from './types';
import {
    ActionsAction,
    DONE_FAIL_ACTION, DONE_SUCCESS_ACTION, INIT_ACTION
} from './actions';
import { actionsThatNeedLoader, initialState } from './constants';

const nanobar = new Nanobar({classname: 'nanobar'});

export const actionsReducer = (state: ActionsState = initialState, action: ActionsAction) => {
    switch (action.type) {
        case INIT_ACTION:
            if (actionsThatNeedLoader.indexOf(action.name) !== -1) {
                nanobar.go(30);
            }
            return {
                ...state,
                [action.name]: {
                    ...action,
                    status: ActionStatus.InProgress,
                    errors: []
                }
            };
        case DONE_SUCCESS_ACTION:
            if (actionsThatNeedLoader.indexOf(action.name) !== -1) {
                nanobar.go(100);
            }
            return {
                ...state,
                [action.name]: {
                    ...action,
                    status: ActionStatus.Success,
                    errors: []
                }
            };
        case DONE_FAIL_ACTION:
            if (actionsThatNeedLoader.indexOf(action.name) !== -1) {
                nanobar.go(100);
            }
            return {
                ...state,
                [action.name]: {
                    ...action,
                    status: ActionStatus.Fail
                }
            };
        default:
            return state;
    }
};