import {
    APPLY_FILTER,
    APPLY_FILTER_MANAGER_SCREEN,
    BULK_UPLOAD,
    BULK_UPDATE_REQUESTS,
    CANCEL_BULK_UPLOAD,
    CHANGE_EDITED_REQUEST,
    CLOSE_ROW,
    GET_REQUESTORS,
    SAVE_EDITED_REQUEST,
    SET_EDITED_REQUEST,
    SET_MANAGER_SCREEN_REQUESTS,
    SET_REQUESTS,
    SET_REQUESTORS,
    GET_FUND_CONTACTS,
    SET_FUND_CONTACTS,
    DOWNLOAD_REQUESTS,
    SET_PENDING_REQUESTS,
    SET_PENDING_NOTIFICATION_FLAG,
    TOGGLE_EDIT_MODE,
    SEND_BULK_REQUESTS,
    SEND_REQUEST,
    SEND_REQUEST_FROM_DATA_REPORTING,
} from './actions';
import { Request } from './models/request';
import { FundRequestRelation, RequestListPermissions } from './types';
import { Fund } from './models/fund';
import { ContactModel } from '../Fund/models/contact';
import { Id, RequestChangeValue } from '../types';
import { defaultRequestListPermissions } from './constants';

export const applyFilter = (filter: any) => ({
    type: APPLY_FILTER,
    filter
});

export const applyFilterManagerScreen = (filter: any) => ({
    type: APPLY_FILTER_MANAGER_SCREEN,
    filter
});

export const closeRow = (id: Id) => ({
    type: CLOSE_ROW,
    id,
});

export const getRequestors = (id: Id, status: any) => ({
    type: GET_REQUESTORS,
    id,
    status,
});

export const setRequests = (
    count: number,
    funds: Fund[],
    permissions: RequestListPermissions = defaultRequestListPermissions,
    withScroll: boolean,
) => ({
    type: SET_REQUESTS,
    funds,
    count,
    permissions,
    withScroll
});

export const setManagerScreenRequests = (count: number, requests: any, withScroll: boolean) => ({
    type: SET_MANAGER_SCREEN_REQUESTS,
    requests,
    count,
    withScroll,
});

export const bulkUpdateRequests = (requests: Request[]) => ({
    type: BULK_UPDATE_REQUESTS,
    requests
});

export const saveEditedRequest = (
    relations: FundRequestRelation[],
    request: Request,
    withContacts = true,
    checkedAll: boolean,
) => ({
    type: SAVE_EDITED_REQUEST,
    relations, request, withContacts, checkedAll,
});

export const changeEditedRequest = (field: string, value: RequestChangeValue | ContactModel[]) => ({
    type: CHANGE_EDITED_REQUEST,
    field, value
});

export const setEditedRequest = (request: Request) => ({
    type: SET_EDITED_REQUEST,
    request
});

export const cancelBulkUpload = (payload: any) => ({
    type: CANCEL_BULK_UPLOAD,
    payload
});

export const setRequestors = (requestors: any, parentId: Id, status: any) => ({
    type: SET_REQUESTORS,
    requestors,
    parentId,
    status,
});

export const downloadRequest = (checkedIds: Id[], checkedAll: boolean) => ({
    type: DOWNLOAD_REQUESTS,
    checkedIds,
    checkedAll,
});

export const bulkUpload = (files: any, id: Id) => ({
    type: BULK_UPLOAD,
    files
});

export const getFundContacts = (fundId: Id) => ({
    type: GET_FUND_CONTACTS,
    fundId
});

export const setFundContacts = (contacts: ContactModel) => ({
    type: SET_FUND_CONTACTS,
    contacts
});

export const setPendingRequests = (count: number) => ({
    type: SET_PENDING_REQUESTS,
    count
});

export const setPendingNotificationFlag = (flag: boolean) => ({
    type: SET_PENDING_NOTIFICATION_FLAG,
    flag
});

export const toggleEditMode = (mode?: boolean) => ({
    type: TOGGLE_EDIT_MODE,
    mode
});

export const sendBulkRequests = (checkedIds: Id[], checkedAll: boolean) => ({
    type: SEND_BULK_REQUESTS,
    checkedIds,
    checkedAll
});

export const sendRequest = (fundId: Id) => ({
    type: SEND_REQUEST,
    fundId,
});

export const sendRequestFromDataReporting = (fundId: Id) => ({
    type: SEND_REQUEST_FROM_DATA_REPORTING,
    fundId,
});