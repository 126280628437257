import * as React from 'react';

interface Props {
    className: string;
    colSpan: number;
}

const DataTableNoDataRow: React.FC<Props> = ({className, colSpan}) => (
    <tr>
        <td className={className} colSpan={colSpan}>
            No data
        </td>
    </tr>
);

export default DataTableNoDataRow;
