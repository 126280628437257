import { Action } from 'redux';
import { FiltersState } from './types';

export const SET_IS_LOADING = 'store/filters/SetIsLoading';
export const SET_FILTERS = 'store/filters/SetFilters';
export const CALL_APPLY_FILTERS = 'store/filters/CallApplyFilters';
export const SET_CHECKED_IDS = 'store/filters/setCheckedIds';
export const CLEAR_CHECKED_IDS = 'store/filters/clearCheckedIds';

export interface SetFiltersAction extends Action {
    filterName: string;
    payload: FiltersState;
}

export interface SetIsLoadingAction extends Action {
    isLoading: boolean;
}

export interface CallApplyFiltersAction extends Action {
    currentFilter: string;
    actionType: string;
    otherFilter: FiltersState;
    actionParams: Object;
}

export interface SetCheckedIdsAction extends Action {
    filterName: string;
    filter: FiltersState;
}

export interface ClearCheckedIdsAction extends Action {
    filterName: string;
}

export type FiltersAction =
    SetFiltersAction |
    SetIsLoadingAction |
    CallApplyFiltersAction |
    SetCheckedIdsAction |
    ClearCheckedIdsAction;
