import { Id, TableHeader } from 'store/types';
import { TargetFund } from 'store/Fund/types';

export enum GroupType {
    BenchmarkGroup = 'group_benchmark_type',
    PeerGroup = 'group_peer_type',
}

export enum GroupWeighting {
    EqualWeighted = 'equal_weighted',
    AumWeighted = 'aum_weighted',
}

export enum BenchmarkType {
    Fund = 'fund',
    FundGroup = 'fundgroup',
}

export enum GroupStatus {
    Active = 'active',
    ActiveWithChanges = 'active_with_changes',
    Inactive = 'inactive',
}

export interface Target {
    search?: string;
    product?: string[];
    strategy?: string[];
    credit_officer?: string[];
    firm_ids?: Id[];
    portfolio_ids?: Id[];
    internal_ids?: string[];
    checked_all?: boolean;
    fund_ids?: Id[];
}

export interface BenchmarkItem {
    id?: Id;
    content_type: BenchmarkType;
    content_object: {
        id: Id,
        [key: string]: any
    };
    ending_balance?: string | number | null;
    perf_month?: string | number | null;
    date_removed?: string | null;
}

export interface Version {
    current: boolean;
    date: string;
    version: number;
}

export interface Group {
    id: Id;
    name: string;
    group_type: GroupType;
    status: GroupStatus;
    weighting?: GroupWeighting | null;
    target_data: Partial<Target>;
    benchmark_items: BenchmarkItem[];
    fund_count: number;
    updated_at?: string;
    versions: Version[];
    version: number;
    group_id: Id;
    permissions: GroupPermissions;
}

export type GroupProperty = string | GroupType | GroupWeighting;

export interface GroupsState {
    list: Group[];
    count: number;
    edit: GroupEditState;
    permissions: GroupListPermissions;
}

export interface GroupEditState {
    group: Partial<Group>;
    targetSection: TargetSectionState;
}

export interface TargetFundList {
    list: TargetFund[];
    count: number;
    firmCount: number;
}

export interface TargetSectionState {
    fundList: TargetFundList;
    temporaryHeaders: TableHeader[];
}

export interface DeleteGroupsModel {
    checkedIds: Id[];
    id?: Id;
    checkedAll: boolean;
    count: number;
}

export enum GroupEntityType {
    FundGroup = 'fund_group'
}

export interface GroupListPermissions {
    can_create_group: boolean;
    can_bulk_delete_groups: boolean;
}

export interface GroupPermissions {
    can_edit_group: boolean;
    can_publish_group: boolean;
    can_delete_group: boolean;
}
