import { DiligenceFilterDropdownState } from './types';
import { initialState } from './constants';
import { DiligenceFilterDropdownAction, OPEN, CLOSE } from './actions';

export const diligenceFilterDropdownReducer = (
    state: DiligenceFilterDropdownState = initialState,
    action: DiligenceFilterDropdownAction) => {
    switch (action.type) {
        case OPEN:
            return {
                ...action
            };
        case CLOSE:
            return initialState;
        default:
            return state;
    }
};