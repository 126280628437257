import { UploadStatus, UploadType } from './types';

export const initialState = {
    in_progress: [],
    history: {}
};

export const DownloadProgressStoragePrefix = 'DownloadProgress';

export const emptyHistory = [];

export const emptyProgress = {
    count_total: 0,
    count_processed: 0,
    visible: true
};

export const BULK_ITEM_NAME_DEFAULT = 'item';
export const BULK_ITEM_NAME_PLURAL_DEFAULT = 'items';
export const END_UPLOAD_STATUSES = [
    UploadStatus.Success,
    UploadStatus.Failed,
    UploadStatus.Cancelled,
    UploadStatus.ProcessedWithErrors
];
export const BULK_ITEM_NAME = {
    [UploadType.Undefined]: BULK_ITEM_NAME_DEFAULT,
    [UploadType.Portfolio]: 'fund',
    [UploadType.Request]: 'request',
    [UploadType.DataManager]: 'fund',
    [UploadType.DataManagerRequestor]: 'item',
    [UploadType.PublishedDatasetHistory]: 'dataset',
    [UploadType.Ruleset]: 'ruleset',
};

export const BULK_ITEM_NAME_PLURAL = {
    [UploadType.Undefined]: BULK_ITEM_NAME_PLURAL_DEFAULT,
    [UploadType.Portfolio]: 'funds',
    [UploadType.Request]: 'requests',
    [UploadType.DataManager]: 'funds',
    [UploadType.DataManagerRequestor]: 'items',
    [UploadType.PublishedDatasetHistory]: 'datasets',
    [UploadType.Ruleset]: 'rulesets',
};

export const BULK_UPLOAD_COMPLETED_NAME = {
    [UploadType.Undefined]: BULK_ITEM_NAME_PLURAL_DEFAULT,
    [UploadType.Portfolio]: 'funds',
    [UploadType.Request]: 'request',
    [UploadType.DataManager]: 'data',
    [UploadType.DataManagerRequestor]: 'data',
    [UploadType.PublishedDatasetHistory]: 'data',
    [UploadType.Ruleset]: 'ruleset',
};