import { initialState } from './constants';
import { ModalsAction, OPEN, CLOSE } from './actions';
import { GeneralModalsState } from 'store/Modals/General/types';

export const generalModalsReducer = (state: GeneralModalsState = initialState, action: ModalsAction) => {
    switch (action.type) {
        case OPEN:
            return {
                ...action
            };
        case CLOSE:
            return initialState;
        default:
            return state;
    }
};