import {
    APPLY_FILTER, CHANGE_EDITING_USER,
    CHANGE_INVITING_USER, DELETE_USER,
    GET_USERS, INVITE_USER, SET_USERS,
    START_EDITING_USER, START_INVITING_USER,
    UPDATE_USER, UPDATE_USER_ROLE,
    DELETE_USERS,
} from './actions';
import { UserAccount } from 'store/Auth/models/userAccount';
import AccountUsersFilter from 'store/Account/models/filter';
import { InvitedUser } from 'store/Shared/models/invitedUser';
import { Id } from 'store/types';

export const getUsers = (filter: AccountUsersFilter = {page: 0}) => ({
    type: GET_USERS,
    filter
});

export const setUsers = (count: number, users: UserAccount[]) => ({
    type: SET_USERS,
    count, users
});

export const deleteUser = (id: Id, filter: AccountUsersFilter, closeModal = true) => ({
    type: DELETE_USER,
    id, filter, closeModal
});

export const deleteUsers = (ids: Id[], checkedAll: boolean) => ({
    type: DELETE_USERS,
    ids, checkedAll,
});

export const applyFilter = (filter: AccountUsersFilter) => ({
    type: APPLY_FILTER,
    filter
});

export const startEditingUser = (id: Id) => ({
    type: START_EDITING_USER,
    id
});

export const changeEditingUser = (userAccount: UserAccount) => ({
    type: CHANGE_EDITING_USER,
    userAccount
});

export const updateUser = (userAccount: UserAccount, filter: AccountUsersFilter, closeModal = true) => ({
    type: UPDATE_USER,
    userAccount, filter, closeModal
});

export const updateUserRole = (id: Id, role: string) => ({
    type: UPDATE_USER_ROLE,
    id, role,
});

export const startInvitingUser = () => ({
    type: START_INVITING_USER
});

export const changeInvitingUser = (invitedUser: InvitedUser) => ({
    type: CHANGE_INVITING_USER,
    invitedUser
});

export const inviteUser = (invitedUser: InvitedUser, filter: AccountUsersFilter, closeModal = true) => ({
    type: INVITE_USER,
    invitedUser, filter, closeModal
});