import * as React from 'react';
import * as classNames from 'classnames';

import Checkbox from '../../Shared/Ui/Checkbox';
import AlertDefault from '../../Shared/Ui/Alerts/AlertDefault';
import { CheckboxOption } from '../../../store/DataManager/types';
import { RequestorModel } from '../../../store/DataManager/models/requestor';
import DataManagerHelper from '../../../helpers/dataManagerHelper';
import Loader from '../../Shared/Ui/Loader';
import ColoredScrollbar from '../../Shared/Scroll/ColoredScrollbar';

interface Props {
    // ability to choose requestors manually from the checkbox list
    allowChooseRequestors: boolean;
    // the toggle to switch between 'all requestors' or 'certain requestors'
    allRequestorsToggle: boolean;
    fundId: any;
    fundName: string;
    requestorCheckboxes: CheckboxOption[];
    getRequestors: (fundId: any) => any;
    setRequestors: (requestors: RequestorModel[]) => any;
    setAllRequestorsToggle: (value: boolean) => any;
    setRequestorCheckboxes: (requestorCheckboxes: CheckboxOption[]) => any;
    isModalLoading: boolean;
}

const styles = require('./PublishModals.scss');

export default class ConfirmPublish extends React.Component<Props> {
    componentDidMount() {
        this.props.getRequestors(this.props.fundId);
        this.props.setAllRequestorsToggle(true);
    }

    componentWillUnmount() {
        this.props.setRequestors([]);
    }

    onCheckBoxClick = (index: number) => {
        this.props.setRequestorCheckboxes(
            this.props.requestorCheckboxes.map((requestor: CheckboxOption, id) => id === index
                ? { ...requestor, checked: !requestor.checked }
                : requestor)
        );
    }

    toggleAllRequestors = () => {
        this.props.setAllRequestorsToggle(!this.props.allRequestorsToggle);
    }

    render () {
        const checkedRequestors = this.props.requestorCheckboxes.filter(
            (requestor: CheckboxOption) => requestor.checked
        );
        const requestorsString = DataManagerHelper.requestorsToString(
            this.props.allRequestorsToggle ? this.props.requestorCheckboxes : checkedRequestors
        );

        return (
            <>
                <Loader
                    className={styles.loader}
                    isLoading={this.props.isModalLoading}
                />
                <AlertDefault hasCloseIcon={false}>
                    <span>
                        Dataset(s) regarding <b>{this.props.fundName}</b> will be published immediately
                        to <b>{requestorsString}</b>
                    </span>
                </AlertDefault>
                {
                    this.props.allowChooseRequestors && this.props.requestorCheckboxes
                    && this.props.requestorCheckboxes.length > 1  && (
                        <div onClick={this.toggleAllRequestors} className={styles['toggle-requestors']}>
                            {
                                this.props.allRequestorsToggle
                                    ? 'or publish to a single requestor...'
                                    : 'or publish to all requestors...'
                            }
                        </div>
                    )
                }
                {
                    this.props.allowChooseRequestors && !this.props.allRequestorsToggle
                        && this.props.requestorCheckboxes &&
                    <>
                        <div className={styles.label}>
                            Select Requestors:
                        </div>
                        <ColoredScrollbar alwaysShowTracks={true} className={`confirm-publish-requestors`}>
                            <div>
                                {this.props.requestorCheckboxes.map((requestor: CheckboxOption, idx: number) => {
                                    const requestorItemClass = classNames({
                                        [styles['checkbox-label']]: true,
                                        [styles.checked]: requestor.checked,
                                    });
                                    return (
                                        <div key={'modal-' + idx} className={styles['checkbox-row']}>
                                            <Checkbox
                                                idx={'modal-' + idx}
                                                value={requestor.value}
                                                handleChange={() => this.onCheckBoxClick(idx)}
                                                checked={requestor.checked}
                                                className={styles.checkbox}
                                            >
                                                <div className={requestorItemClass}>{requestor.label}</div>
                                            </Checkbox>
                                        </div>
                                    );
                                })}
                            </div>
                        </ColoredScrollbar>
                    </>
                }
            </>
        );
    }
}