import * as React from 'react';

import { closeWhiteIcon } from 'store/constants';
const styles = require('./BottomProgressBar.scss');

interface Props {
    hide: () => void;
    uploadTypeName: string;
    action: string;
}

class SuccessMessage extends React.PureComponent<Props> {
    render() {
        const {uploadTypeName, action} = this.props;

        return (
            <div className="progressBlock">
                <div className={styles['progress-area']}>
                    <div className={styles['completed-process-text']}>
                        <span className={styles['success-message']}>
                            {`${uploadTypeName} ${action} Complete!`}
                        </span>
                    </div>
                    <img
                        src={closeWhiteIcon}
                        className={styles['img-close']}
                        onClick={this.props.hide}
                    />
                </div>
            </div>
        );
    }
}

export default SuccessMessage;
