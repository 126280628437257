import { FiltersState } from '../Filters/types';

export interface AsyncFiltersChoicesState {
    [page: string]: FilterItem;
}

interface FilterItem {
    [filterName: string]: {
        filter: FiltersState;
        results: any[];
        count: number;
    };
}

export enum SearchFilterChoicesSource {
    Dashboard = 'Dashboard',
    FundList = 'FundList'
}
