import * as moment from 'moment';

import DataManagerHelper from 'helpers/dataManagerHelper';
import { DEFAULT_DATE_FORMAT, DEFAULT_ITEMS_PER_PAGE } from 'store/constants';
import { FundAttributes } from '../Fund/types';

import { BoxChartPeriodType, DashboardChartGroup, DashboardChartType, StatisticDonutDataType } from './types';

export const MIN_AVAILABLE_PERIODS_FOR_CHART = -1;

export const dispersionChartsTypes = [
    BoxChartPeriodType.Month, BoxChartPeriodType.Ytd, BoxChartPeriodType.Year,
    BoxChartPeriodType.Years3, BoxChartPeriodType.Years5
];

export const dashboardChartTypes = [
    DashboardChartType.AumDistribution, DashboardChartType.PerfMonth,
    DashboardChartType.PerfYtd, DashboardChartType.PerfYear,
    DashboardChartType.Perf3Years, DashboardChartType.Perf5Years,
    DashboardChartType.Alerts, DashboardChartType.ReportingPerformance
];

export const dashboardChartDonutMapping = {
    [DashboardChartType.Alerts]: StatisticDonutDataType.Alerts,
    [DashboardChartType.ReportingPerformance]: StatisticDonutDataType.PerformanceReport
};

export const dashboardChartGroups = [
    DashboardChartGroup.AumDistributionPlusPerfMonth,
    DashboardChartGroup.PerfYtdPlusPerfYear,
    DashboardChartGroup.Perf3YearsPlusPerf5Years,
    DashboardChartGroup.AlertsPlusReportingPerformance,
];

export const dashboardChartGroupsMapping = {
    [DashboardChartGroup.AumDistributionPlusPerfMonth]: [
        DashboardChartType.AumDistribution, DashboardChartType.PerfMonth
    ],
    [DashboardChartGroup.PerfYtdPlusPerfYear]: [
        DashboardChartType.PerfYtd, DashboardChartType.PerfYear
    ],
    [DashboardChartGroup.Perf3YearsPlusPerf5Years]: [
        DashboardChartType.Perf3Years, DashboardChartType.Perf5Years
    ],
    [DashboardChartGroup.AlertsPlusReportingPerformance]: [
        DashboardChartType.Alerts, DashboardChartType.ReportingPerformance
    ],
};

export const DefaultDashboardFilterState = {
    filter: {
        period: DataManagerHelper.getActivePeriod(),

        search: '',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        portfolio_id: [],
        credit_officer: [],
        product: [],
        strategy: [],
        firm_id: [],
        attribute_type: FundAttributes.CreditOfficer,
        chart_types: null,
    },
    headerType: 'requestorDashboard'
};

export const DefaultDashboardDispersionFilterState = {
    ...DefaultDashboardFilterState,
    headerType: 'requestorDashboardDispersion'
};

export const initialState = {
    rows: [],
    rowsCount: 0,
    fundsCount: 0,
    firmsCount: 0,
    donuts: {},
    chart: {
        first_period: moment().subtract(1, 'year').format(DEFAULT_DATE_FORMAT),
        last_period: moment().format(DEFAULT_DATE_FORMAT),
        result: []
    },
    boxCharts: {
        first_period: moment().subtract(1, 'year').format(DEFAULT_DATE_FORMAT),
        last_period: moment().format(DEFAULT_DATE_FORMAT),
        result: []
    },
};

export const COMBINE_DELTA_PERC = 10;

export const BoxChartPeriodTitles = {
    [BoxChartPeriodType.Month]: 'Month',
    [BoxChartPeriodType.Ytd]: 'YTD',
    [BoxChartPeriodType.Year]: '1Y',
    [BoxChartPeriodType.Years3]: '3Y',
    [BoxChartPeriodType.Years5]: '5Y',
};

export const BoxChartDropdownPeriodTitles = {
    [BoxChartPeriodType.Month]: 'Show Dispersion (Month)',
    [BoxChartPeriodType.Ytd]: 'Show Dispersion (YTD)',
    [BoxChartPeriodType.Year]: 'Show Dispersion (1 Year)',
    [BoxChartPeriodType.Years3]: 'Show Dispersion (3 Year)',
    [BoxChartPeriodType.Years5]: 'Show Dispersion (5 Year)',
};

export const DispersionChartTypeTitles = BoxChartPeriodTitles;

export const DashboardChartTypeTitles = {
    [DashboardChartType.AumDistribution]: 'AUM Distribution',
    [DashboardChartType.PerfMonth]: 'Performance Distribution (Month)',
    [DashboardChartType.PerfYtd]: 'Performance Distribution (YTD)',
    [DashboardChartType.PerfYear]: 'Performance Distribution (1 Year)',
    [DashboardChartType.Perf3Years]: 'Performance Distribution (3 Year)',
    [DashboardChartType.Perf5Years]: 'Performance Distribution (5 Year)',
    [DashboardChartType.Alerts]: 'Alerts',
    [DashboardChartType.ReportingPerformance]: 'Reporting Performance',
};

export const DashboardChartGroupsTitles = {
    [DashboardChartGroup.AumDistributionPlusPerfMonth]: 'Show AUM & Perf (Month)',
    [DashboardChartGroup.PerfYtdPlusPerfYear]: 'Show Perf (YTD) & Perf (1 Year)',
    [DashboardChartGroup.Perf3YearsPlusPerf5Years]: 'Show Perf (3 Year) & Perf (5 Year)',
    [DashboardChartGroup.AlertsPlusReportingPerformance]: 'Show Alerts & Reporting Performance',
};

export const StatisticDonutDataTypeTitles = {
    [StatisticDonutDataType.PerformanceReport]: 'Reporting Performance',
    [StatisticDonutDataType.Alerts]: 'Alerts',
};
