import {
    CLEAR_ASYNC_FILTERS,
    GET_CHOICES,
    SEARCH_CHOICES,
    SET_ASYNC_FILTERS,
    SET_CHOICES,
    SET_CHOICES_WITH_SCROLL,
    SET_GLOBAL_CHOICES
} from './actions';
import { FiltersState } from 'store/Filters/types';
import { FundModel } from 'store/Fund/models/fund';
import { FirmModel } from 'store/Firm/models/firm';
import { PortfolioModel } from 'store/Portfolio/models/portfolio';
import { Id } from 'store/types';
import { SearchFilterChoicesSource } from './types';

export const getChoices = (page: string, filterName: string, filter: FiltersState) => ({
    type: GET_CHOICES,
    page,
    filterName,
    filter,
});

export const getChoicesByFund = (page: string, filterName: string, filter: FiltersState, fundId: Id) => ({
    type: GET_CHOICES,
    page,
    filterName,
    filter,
    fundId,
});

export const setChoices = (
    page: string,
    filterName: string,
    results: FirmModel[] | FundModel[] | PortfolioModel[],
    count: number,
    withScroll = false
) => ({
    type: withScroll
        ? SET_CHOICES_WITH_SCROLL
        : SET_CHOICES,
    page,
    filterName,
    results,
    count,
});

export const setAsyncFilters = (filterName: string, filter: FiltersState, pageName: string) => ({
    type: SET_ASYNC_FILTERS,
    filterName,
    filter,
    pageName,
});

export const searchFilterChoices = (
    query: string, filter: FiltersState, filterName: string
) => ({
    type: SEARCH_CHOICES,
    query,
    filter,
    filterName,
    source: SearchFilterChoicesSource.Dashboard
});

export const setGlobalChoices = (
    results: FirmModel[] | FundModel[] | PortfolioModel[],
    count: number
) => ({
    type: SET_GLOBAL_CHOICES,
    results,
    count
});

export const clearAsyncFilters = (pageName: string, filterName?: string) => ({
    type: CLEAR_ASYNC_FILTERS,
    pageName, filterName
});
