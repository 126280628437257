import * as React from 'react';

import { Dataset } from 'store/DataManager/models/dataset';
import { formatText } from 'helpers/tableHelper';
import { FormatTypeChoices } from 'store/constants';

interface Props {
    row: Dataset;
}
const LastUpdatedPopover: React.FC<Props> = ({row}) => {
    const userName = row.made_latest_by ? row.made_latest_by.first_name + ' ' + row.made_latest_by.last_name : 'user';
    return (
        <div>
            {`Made Latest by ${userName}; Dataset on
            ${formatText(row.latest_dataset_received_at, FormatTypeChoices.DateFormat)}`}
        </div>
    );
};

export default LastUpdatedPopover;
