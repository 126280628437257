import * as React from 'react';
import 'react-dates/initialize';
import * as moment from 'moment';
import * as classNames from 'classnames';

import { calendarGrayIcon, DATE_PICKER_DISPLAY_FORMAT } from 'store/constants';

import { SingleDatePickerShape, OpenDirectionShape, AnchorDirectionShape } from 'react-dates';

const ReactDates = require('react-dates');
const UiSingleDatePicker: React.ClassicComponentClass<UiSingleDatePickerInterface> = ReactDates.SingleDatePicker;

interface UiSingleDatePickerInterface extends SingleDatePickerShape {
    openDirection: OpenDirectionShape;
}

interface Props {
    date: moment.Moment | null;
    id: string;
    disabled?: boolean;
    customInputIcon?: boolean;
    inputIconPosition?: 'before' | 'after';
    showClearDate?: boolean;
    placeholder?: string;
    displayFormat?: string;
    material?: boolean;
    handleChange: (date: moment.Moment | null) => void;
    isOutsideRange?: (day: any) => boolean;
    handleFocus?: () => void;
    handleBlur?: () => void;
    keepOpenOnDateSelect?: boolean;
    anchorDirection?: AnchorDirectionShape;
}

interface State {
    focused: boolean | null;
}

const styles = require('./DatePicker.scss');
const CALENDAR_HEIGHT = 330;

class UiDatePicker extends React.PureComponent<Props, State> {

    static defaultProps = {
        displayFormat: DATE_PICKER_DISPLAY_FORMAT,
        customInputIcon: false,
        showClearDate: true,
        placeholder: 'Date',
        keepOpenOnDateSelect: false,
        disabled: false,
    };

    state = {
        focused: false
    };

    handleChangeFocus = ({ focused }: { focused: boolean }) => {
        this.setState({
            focused
        });
        if (this.props.handleFocus && focused) {
            this.props.handleFocus();
        }
        if (this.props.handleBlur && !focused) {
            this.props.handleBlur();
        }
    }

    defaultIsOutsideRange = () => {
        return false;
    }

    render() {
        const ClassNames = classNames({
            'ui-input': true,
            [styles.date_picker]: !!styles.date_picker,
            [styles.date_picker_material]: this.props.material,
        });

        let customIcon: any = null;

        if (this.props.customInputIcon) {
            customIcon = (
                <img src={calendarGrayIcon} alt="calendar" className={styles.calendar}/>
            );
        }

        let openDirection: OpenDirectionShape = `down`;
        if (this.state.focused) {
            const currentElement = window.document.getElementById(this.props.id);
            const scrollableElement = window.document.querySelector('.j-scroll');
            const fixedElement = window.document.querySelector('.j-filter');
            if (currentElement && scrollableElement) {
                const inputBottomLine = currentElement.getBoundingClientRect().bottom;
                const toBottomHeight = scrollableElement.scrollHeight - scrollableElement.scrollTop -
                    scrollableElement.clientHeight;

                let fixedBottomLine: number = toBottomHeight;
                if (fixedElement) {
                    fixedBottomLine = fixedElement.getBoundingClientRect().bottom;
                }

                if (toBottomHeight - inputBottomLine < CALENDAR_HEIGHT
                    && inputBottomLine > CALENDAR_HEIGHT + currentElement.offsetHeight
                    && inputBottomLine - fixedBottomLine > CALENDAR_HEIGHT + currentElement.offsetHeight
                ) {
                    openDirection = `up`;
                }
            }
        }

        const {
            id, disabled, displayFormat, showClearDate, placeholder, date, inputIconPosition,
            keepOpenOnDateSelect, anchorDirection,
            isOutsideRange, handleChange
        } = this.props;

        return (
            <div className={ClassNames}>
                <UiSingleDatePicker
                    customInputIcon={customIcon}
                    inputIconPosition={inputIconPosition}
                    id={id}
                    disabled={disabled}
                    displayFormat={displayFormat}
                    showClearDate={showClearDate}
                    placeholder={placeholder}
                    isOutsideRange={isOutsideRange ? isOutsideRange : this.defaultIsOutsideRange}
                    date={date}
                    onDateChange={handleChange}
                    focused={this.state.focused}
                    onFocusChange={this.handleChangeFocus}
                    numberOfMonths={1}
                    keepOpenOnDateSelect={keepOpenOnDateSelect}
                    anchorDirection={anchorDirection}
                    openDirection={openDirection}
                    verticalSpacing={10}
                />
            </div>
        );
    }
}

export default UiDatePicker;
