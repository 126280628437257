import { initialState } from './constants';
import * as moment from 'moment';

import { DEFAULT_DATE_FORMAT } from 'store/constants';
import {
    DashboardAction, SET_BOX_CHART_DATA, SET_CHART_DATA,
    SET_DASHBOARD_DATA, SET_DASHBOARD_DATA_WITH_SCROLL,
    SET_STATISTIC_DONUT_REPORT, SetBoxChartDataAction, SetChartDataAction,
    SetDashboardDataAction,
    SetStatisticDonutReportAction
} from './actions';
import { ChartPeriodResult, DashboardGridItem, DashboardState } from './types';
import { getDonutName } from './helpers';

export const dashboardReducer = (state: DashboardState = initialState, action: DashboardAction) => {
    let rows: DashboardGridItem[];

    switch (action.type) {
        case SET_STATISTIC_DONUT_REPORT:
            const {dataType, data} = (<SetStatisticDonutReportAction> action);
            const donutName = getDonutName(dataType);
            return {
                ...state,
                donuts: {
                    ...state.donuts,
                    [donutName]: data
                }
            };
        case SET_DASHBOARD_DATA:
            const {rowsCount, fundsCount, firmsCount} = (<SetDashboardDataAction> action);
            rows = (<SetDashboardDataAction> action).rows;
            return {
                ...state,
                rows, rowsCount, fundsCount, firmsCount
            };
        case SET_DASHBOARD_DATA_WITH_SCROLL:
            rows = (<SetDashboardDataAction> action).rows;
            const newRows = [
                ...state.rows,
                ...rows,
            ];
            return {
                ...state,
                rows: newRows
            };
        case SET_CHART_DATA:
            action = <SetChartDataAction> action;
            const {first_period, last_period} = action.data;

            const newResult = {};
            // copy old value only if 'force' flag is false
            if (!action.force) {
                state.chart.result.forEach((result, idx) => {
                    newResult[result.period] = result;
                });
            }

            action.data.result.forEach(result => {
                if (newResult.hasOwnProperty(result.period)) {
                    newResult[result.period].data = result.data;
                } else {
                    newResult[result.period] = result;
                }
            });

            return {
                ...state,
                chart: {
                    ...state.chart,
                    first_period, last_period,
                    result: Object.keys(newResult)
                        .map(key => newResult[key])
                        .sort((a: ChartPeriodResult, b: ChartPeriodResult) => {
                            if (a.period > b.period) {
                                return 1;
                            } else if (a.period < b.period) {
                                return -1;
                            }
                            return 0;
                        })
                }
            };
        case SET_BOX_CHART_DATA:
            action = (<SetBoxChartDataAction> action);
            return {
                ...state,
                boxCharts: {
                    ...action.data,
                    first_period: action.data.first_period ||
                        moment(action.data.last_period).subtract(2, 'month').format(DEFAULT_DATE_FORMAT),
                }
            };
        default:
            return state;
    }
};
