export interface SocketEventListener {
    type: string;
    listener: EventListenerOrEventListenerObject;
}

export class SocketHelper {
    host: string;
    ws: WebSocket;
    listeners: SocketEventListener[] = [];

    // list of data for sending after opening socket
    pendingPool: any[] = [];

    constructor(host: string = '') {
        this.host = host;
    }

    isOpened(): boolean {
        return this.ws && (this.ws.readyState === WebSocket.OPEN);
    }

    isConnecting() {
        return this.ws && (this.ws.readyState === WebSocket.CONNECTING);
    }

    isClosed() {
        return !this.ws || (this.ws.readyState === WebSocket.CLOSED);
    }

    isClosing() {
        return this.ws && (this.ws.readyState === WebSocket.CLOSING);
    }

    reconnect() {
        this.disconnect();
        this.connect();
    }

    isAvailableToConnect() {
        return !this.isOpened() && !this.isConnecting();
    }

    connect() {
        if (!this.isAvailableToConnect()) {
            return;
        }

        if (this.ws) {
            this.removeSocketEventListeners(this.ws);
        }

        this.ws = new WebSocket(this.host);
        this.ws.onopen = () => {
            this.sendPending();
            this.listeners.forEach(info => this.addEventListener(info.type, info.listener));
        };
    }

    disconnect() {
        if (this.isOpened()) {
            this.ws.close();
        }
    }

    send(data: any) {
        if (this.isClosed()) {
            this.pendingPool.push(data);
            this.connect();
        } else if (this.isConnecting()) {
            this.pendingPool.push(data);
        } else if (this.isClosing()) {
            // what should we do? reopen again?
        } else if (this.isOpened()) {
            this.ws.send(JSON.stringify(data));
        } else {
            // unknown socket state
        }
    }

    sendPending() {
        if (this.isOpened()) {
            let data;
            while (this.pendingPool.length > 0) {
                data = this.pendingPool.shift();
                this.send(data);
            }
        }
    }

    addEventListener(type: string, listener: EventListenerOrEventListenerObject) {
        this.listeners.push({
            type, listener
        });

        this.ws.addEventListener(type, listener);
    }

    removeEventListener(type: string, listener: EventListenerOrEventListenerObject) {
        this.ws.removeEventListener(type, listener);
        this.listeners = this.listeners.filter(info => info.type !== type || info.listener !== listener);
    }

    removeSocketEventListeners(ws: WebSocket) {
        this.listeners.forEach(info => ws.removeEventListener(info.type, info.listener));
    }
}

export default new SocketHelper(process.env.REACT_APP_SOCKET_HOST);
