import * as React from 'react';
import { Calendar } from 'react-date-range';
import * as moment from 'moment';
import * as classNames from 'classnames';
import { DATE_FORMAT, DEFAULT_DATE_FORMAT } from 'store/constants';
import { FiltersState } from 'store/Filters/types';
import { DateItem } from 'store/Diligence/types';
import { DiligenceDateFilters } from 'store/Diligence/constants';
import { RangeEndings } from 'store/types';

const styles = require('./DateFilter.scss');

interface Props {
    handleChangeCurrentFilter: (data: FiltersState) => void;
    attributeName: string;
    value?: DateItem;
    start?: string;
    end?: string;
    rangeEndings?: RangeEndings;
    currentFilter: FiltersState;
}

interface State {
    hideStartCalendar: boolean;
    hideEndCalendar: boolean;
    startDate: any;
    endDate: any;
}

class DateFilter extends React.PureComponent<Props, State> {
    startDateDisplay: React.RefObject<HTMLInputElement>;
    endDateDisplay: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            hideStartCalendar: true,
            hideEndCalendar: true,
            startDate: this.props.value && this.props.value.start !== ''
                ? new Date(this.props.value.start)
                : this.props.start
                    ? new Date(this.props.start)
                    : null,
            endDate: this.props.value && this.props.value.end !== ''
                ? new Date(this.props.value.end)
                : this.props.end
                    ? new Date(this.props.end)
                    : null,
        };
        this.startDateDisplay = React.createRef();
        this.endDateDisplay = React.createRef();
    }

    componentDidMount() {
        if (
            ((this.props.value && this.props.value.start !== '') || this.props.start)
            && this.startDateDisplay.current
        ) {
            this.startDateDisplay.current.value = moment(this.state.startDate).format(DATE_FORMAT);
        }
        if (
            ((this.props.value && this.props.value.end !== '') || this.props.end)
            && this.endDateDisplay.current
        ) {
            this.endDateDisplay.current.value = moment(this.state.endDate).format(DATE_FORMAT);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.currentFilter !== this.props.currentFilter) {
            this.setState(
                {
                    startDate: this.props.value && this.props.value.start !== ''
                        ? new Date(this.props.value.start)
                        : this.props.start
                            ? new Date(this.props.start)
                            : null,
                    endDate: this.props.value && this.props.value.end !== ''
                        ? new Date(this.props.value.end)
                        : this.props.end
                            ? new Date(this.props.end)
                            : null,
                },
                () => {
                    if (this.startDateDisplay.current) {
                        if (this.state.startDate) {
                            this.startDateDisplay.current.value = moment(this.state.startDate)
                                .format(DATE_FORMAT);
                        } else {
                            this.startDateDisplay.current.value = '';
                        }
                    }
                    if (this.endDateDisplay.current) {
                        if (this.state.endDate) {
                            this.endDateDisplay.current.value = moment(this.state.endDate)
                                .format(DATE_FORMAT);
                        } else {
                            this.endDateDisplay.current.value = '';
                        }
                    }
                }
            );
        }
    }

    handleChange = () => {
        const { attributeName, rangeEndings } = this.props;
        if (DiligenceDateFilters.indexOf(attributeName) < 0  && rangeEndings) { // credit
            this.props.handleChangeCurrentFilter({
                [`${attributeName}${rangeEndings.start}`]: this.state.startDate
                    ? moment(this.state.startDate).format(DEFAULT_DATE_FORMAT)
                    : null,
                [`${attributeName}${rangeEndings.end}`]: this.state.endDate
                    ? moment(this.state.endDate).format(DEFAULT_DATE_FORMAT)
                    : null
            });
        } else { // diligence
            this.props.handleChangeCurrentFilter({
                ...this.props.value,
                start: this.state.startDate ? moment(this.state.startDate).format(DEFAULT_DATE_FORMAT) : '',
                end: this.state.endDate ? moment(this.state.endDate).format(DEFAULT_DATE_FORMAT) : ''
            });
        }
        this.setState({hideStartCalendar: true, hideEndCalendar: true});
    }

    handleSelectStartDate = (date: any) => {
        this.setState({startDate: date, hideStartCalendar: false}, () => {
            if (this.startDateDisplay.current) {
                this.startDateDisplay.current.value = moment(this.state.startDate)
                    .format(DATE_FORMAT);
            }
            this.handleChange();
        });
    }

    handleSelectEndDate = (date: any) => {
        this.setState({endDate: date, hideEndCalendar: false}, () => {
            if (this.endDateDisplay.current) {
                this.endDateDisplay.current.value = moment(this.state.endDate).format(DATE_FORMAT);
            }
            this.handleChange();
        });
    }

    toggleHideCalendar = (whichCalendar: string) => {
        if (whichCalendar === 'start') {
            this.setState(
                (prevState: State) => ({
                    hideStartCalendar: !prevState.hideStartCalendar
                }),
                () => {
                    if (!this.state.hideStartCalendar && !this.state.hideEndCalendar) {
                        this.setState({
                            hideEndCalendar: true
                        });
                    }
                });
        } else if (whichCalendar === 'end') {
            this.setState(
                (prevState: State) => ({
                    hideEndCalendar: !prevState.hideEndCalendar
                }),
                () => {
                    if (!this.state.hideStartCalendar && !this.state.hideEndCalendar) {
                        this.setState({
                            hideStartCalendar: true
                        });
                    }
                });
        }
    }

    render() {
        const startClassNames = classNames({
            [styles.calendar_wrapper]: true,
            [styles.hidden_calendar]: this.state.hideStartCalendar
        });
        const endClassNames = classNames({
            [styles.calendar_wrapper]: true,
            [styles.hidden_calendar]: this.state.hideEndCalendar
        });
        return (
            <div className={styles.date_filter}>
                <input
                    type="text"
                    placeholder={'Start Date'}
                    ref={this.startDateDisplay}
                    onMouseUp={() => this.toggleHideCalendar('start')}
                    readOnly={true}
                    className={`form-control ${styles.input}`}
                />
                <Calendar
                    className={startClassNames}
                    date={this.state.startDate}
                    onChange={this.handleSelectStartDate}
                />
                <input
                    type="text"
                    placeholder={'End Date'}
                    ref={this.endDateDisplay}
                    onMouseUp={() => this.toggleHideCalendar('end')}
                    readOnly={true}
                    className={`form-control ${styles.input}`}
                />
                <Calendar
                    className={endClassNames}
                    date={this.state.endDate}
                    onChange={this.handleSelectEndDate}
                />
            </div>
        );
    }
}

export default DateFilter;
