import { UserAccountRole, UserAccountStatus } from './types';

export const SWITCH_ACCOUNT_FAIL_MESSAGE = 'Account switching failed';

export const INITIALIZE_RESET_PASSWORD_SUCCESS_MESSAGE = 'The password reset process is initialized. ' +
    'Please check your email.';

export const RESET_PASSWORD_SUCCESS_MESSAGE = 'The password reset task is queued. Please check your email.';

export const initialState = {
    isInitialized: false
};

export const UserAccountStatuses = [
    {label: 'Pending', value: UserAccountStatus.Pending},
    {label: 'Active', value: UserAccountStatus.Active},
    {label: 'Suspended', value: UserAccountStatus.Suspended},
];

export const UserAccountRoles = [
    {label: 'Account Admin', value: UserAccountRole.AccountAdmin},
    {label: 'Content Admin', value: UserAccountRole.ContentAdmin},
    {label: 'User', value: UserAccountRole.User},
];

export const ADHOC_NAME = 'adhoc';