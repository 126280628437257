import { Action } from 'redux';

import { FirmModel } from './models/firm';
import { Id } from 'store/types';
import { ErmFundRelationshipModel } from 'store/Fund/models/fund';
import { FiltersState } from 'store/Filters/types';

export const APPLY_FILTER = 'store/firm/applyFilter';
export const SET_FIRMS = 'store/firm/setFirms';
export const SET_FIRMS_WITH_SCROLL = 'store/firm/setFirmsWithScroll';
export const GET_FIRM = 'store/firm/getFirm';
export const SET_FIRM = 'store/firm/setFirm';
export const APPLY_FILTER_FOR_FUNDS = 'store/firm/applyFilterForFunds';
export const SET_FIRM_FUNDS = 'store/firm/setFirmFunds';
export const SET_FIRM_FUNDS_WITH_SCROLL = 'store/firm/setFirmFundsWithScroll';

export interface ApplyFilterAction extends Action {
    filter: FiltersState;
}

export interface SetFirmsAction extends Action {
    count: number;
    firms: FirmModel[];
}

export interface ChangeNewFirmFieldAction extends Action {
    fieldName: string;
    fieldValue: string;
}

export interface GetFirmAction extends Action {
    firmId: Id;
}

export interface SetFirmAction extends Action {
    firm: Partial<FirmModel>;
}

export interface ApplyFilterForFundsAction extends Action {
    firmId: Id;
    filter: any;
}

export interface SetFirmFundsAction extends Action {
    count: number;
    funds: ErmFundRelationshipModel[];
}

export type FirmAction = ApplyFilterAction |
    SetFirmsAction |
    ChangeNewFirmFieldAction |
    GetFirmAction |
    SetFirmAction |
    ApplyFilterForFundsAction |
    SetFirmFundsAction;