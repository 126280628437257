import * as React from 'react';
import ChipComponent from 'components/Shared/Ui/Chip';
import { formatText } from 'helpers/tableHelper';
import {
    DEFAULT_DATE_RANGE_ENDINGS, FILTERS_WITH_CHIPS_ENABLED,
    FormatTypeChoices
} from 'store/constants';
import { Chip, ChipSetting, FiltersState } from 'store/Filters/types';
import {
    DiligenceFilterTypeDictionary, DiligenceFilterAttributeDictionary, DiligenceDateFilters,
} from 'store/Diligence/constants';
import { DiligenceFilterTypeTitle } from 'store/Diligence/DiligenceRequest/types';
import { FilterDecoratorChange } from 'decorators/FilterDecorator';
import { FilterItem } from 'store/Diligence/types';
import { DiligenceFilterNames } from 'store/Diligence/Filters/types';
import { SelectItem } from 'store/types';
import { instanceOfSelectItem } from 'store/Filters/helpers';

const styles = require('./ChipsPanel.scss');
interface Props {
    disabled?: boolean;
    chips: Chip[];
    filterPageName?: string;
    currentFilter?: FiltersState;
    handleChange?: FilterDecoratorChange;
}
class ChipsPanel extends React.PureComponent<Props> {

    diligenceClose = (chip: Chip) => {
        if (this.props.handleChange && this.props.currentFilter) {
            let newFilterValue: any = chip.value;
            let filterType: string = DiligenceFilterAttributeDictionary[chip.name];
            if (this.props.currentFilter[filterType]) {
                if (DiligenceDateFilters.indexOf(filterType) !== -1) {
                    newFilterValue = {
                        ...DEFAULT_DATE_RANGE_ENDINGS,
                        id: DiligenceFilterTypeDictionary[filterType],
                        type: DiligenceFilterTypeDictionary[filterType]
                    };
                } else {
                    const removeIndex = this.props.currentFilter[filterType].findIndex((filter: FilterItem) => {
                        return filter.name === newFilterValue;
                    });
                    if (removeIndex > -1) {
                        newFilterValue = this.props.currentFilter[filterType];
                        newFilterValue.splice(removeIndex, 1);
                    }
                }
            }
            this.props.handleChange({[filterType]: newFilterValue});
        }
    }

    creditClose = (chip: Chip) => {
        if (this.props.handleChange && this.props.currentFilter) {
            let newFilterValue: any = chip.value;
            const filterNameToClearValue = Object.keys(this.props.currentFilter).find((filterName: string) => {
                return filterName === chip.name;
            });
            if (filterNameToClearValue) {
                const filterToClearValue = this.props.currentFilter[filterNameToClearValue];
                const chipsSettings = this.props.filterPageName
                    && FILTERS_WITH_CHIPS_ENABLED[this.props.filterPageName].find(
                        (chipSettings: ChipSetting) => (chipSettings.name === chip.name)
                    );
                if (filterToClearValue && Array.isArray(filterToClearValue)) {
                    const foundValue: SelectItem | string | undefined = filterToClearValue.find((
                        item: SelectItem | string
                    ) => {
                        if (instanceOfSelectItem(item)) {
                            return item.value === chip.value;
                        } else if (typeof item === 'string') {
                            return item === chip.value;
                        } else {
                            return false;
                        }
                    });
                    if (foundValue) {
                        const index = filterToClearValue.indexOf(foundValue);
                        filterToClearValue.splice(index, 1);
                        newFilterValue = filterToClearValue;
                    }
                } else if (filterToClearValue) {
                    newFilterValue = chipsSettings && chipsSettings.emptyValue !== 'undefined'
                        ? chipsSettings.emptyValue
                        : '';
                }
                this.props.handleChange({[chip.name]: newFilterValue});
            }
        }
    }

    handleClose = (chip: Chip) => {
        if (this.props.filterPageName === DiligenceFilterNames.diligenceRequest) {
            this.diligenceClose(chip);
        } else {
            this.creditClose(chip);
        }
    }

    creditClearFilters = () => {
        if (this.props.handleChange) {
            let clearedFiltersWithChips = {};
            this.props.chips.forEach((item: Chip) => {
                const chipsSettings = this.props.filterPageName
                    && FILTERS_WITH_CHIPS_ENABLED[this.props.filterPageName].find(
                        (chipSettings: ChipSetting) => (chipSettings.name === item.name)
                    );
                clearedFiltersWithChips[item.name] = chipsSettings && chipsSettings.emptyValue !== 'undefined'
                    ? chipsSettings.emptyValue
                    : '';
            });
            this.props.handleChange(clearedFiltersWithChips);
        }
    }

    diligenceClearFilters = () => {
        if (this.props.handleChange) {
            let clearedFiltersWithChips = {};
            this.props.chips.forEach((item: Chip) => {
                const chipsSettings = this.props.filterPageName
                    && FILTERS_WITH_CHIPS_ENABLED[this.props.filterPageName].find(
                        (chipSettings: ChipSetting) => (chipSettings.nameTitle === item.name)
                    );
                const dictValues = Object.keys(DiligenceFilterAttributeDictionary);
                for (let i in dictValues) {
                    if (item.name === dictValues[i]) {
                        clearedFiltersWithChips[Object.keys(DiligenceFilterTypeDictionary)[i]] = chipsSettings &&
                        chipsSettings.emptyValue !== 'undefined'
                            ? chipsSettings.emptyValue
                            : [];
                    }
                }
            });
            this.props.handleChange(clearedFiltersWithChips);
        }
    }

    handleClearFilters = () => {
        if (this.props.filterPageName === DiligenceFilterNames.diligenceRequest) {
            this.diligenceClearFilters();
        } else {
            this.creditClearFilters();
        }
    }

    render() {
        const { disabled, chips, filterPageName } = this.props;
        const chipsToShow = chips.filter((chip: Chip) => chip.value);
        return (
            <div className={styles['chips-panel']}>
                {
                    chipsToShow && chipsToShow.length > 0 && chipsToShow.map((chip: Chip, index: number) => {
                        return (
                            <ChipComponent
                                disabled={disabled}
                                key={index}
                                name={chip.name}
                                value={chip.value}
                                nameTitle={
                                    filterPageName === DiligenceFilterNames.diligenceRequest
                                        ? DiligenceFilterTypeTitle[chip.name]
                                        : chip.nameTitle
                                }
                                valueTitle={chip.valueTitle}
                                handleClose={this.handleClose}
                            />
                        );
                    })
                }
                {
                    (!disabled && chipsToShow && chipsToShow.length > 0) &&
                    <span className={styles['clear-filters-button']} onClick={this.handleClearFilters}>
                        {formatText(`Clear filters`, FormatTypeChoices.ToUppercase)}
                    </span>
                }
            </div>
        );
    }
}
export default ChipsPanel;
