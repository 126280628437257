import CreditApiHelper, {
    API_ERM_ENTITIES, API_PORTFOLIO_URL, API_REQUEST_URL,
    API_FUND_CONTACTS_URL, API_FUND_REQUESTOR_URL, API_FUND_RESPONDER_URL
} from 'helpers/creditApiHelper';
import { ApiHelper } from 'helpers/apiHelper';

import { PortfolioFilterModel } from 'store/Portfolio/models/filter';
import { NewFundModel, FundProfileUserSettingsModel } from 'store/Fund/models/fund';
import { NewContactModel, UpdateContactModel } from 'store/Fund/models/contact';
import { defaultDeniedGetParams, deniedGetParams, ExportPageType, PAGINATION_LARGE_LIMIT } from 'store/constants';
import { Id } from 'store/types';
import { FiltersState } from 'store/Filters/types';
import { AccountType } from 'store/Account/types';
import { prepareFilterState } from 'store/Filters/helpers';
import { BenchmarkType } from 'store/Profiles/Groups/types';

export const getFunds = async (filter: FiltersState, userType: string): Promise<any> => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    return CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/${userType}/funds/`,
        'get',
        {
            params
        }
    );
};

export const getFundById = async (id: Id, withRequest: boolean = false, userType: string): Promise<any> => {
    const url = withRequest
        ? `${API_REQUEST_URL}/funds/${id}/`
        : `${API_PORTFOLIO_URL}/${userType}/funds/${id}/`;

    return CreditApiHelper.doRequest(url, 'get', {});
};

export const getFundAttributes = async (filter: any): Promise<any> => {
    const url = `${API_PORTFOLIO_URL}/requestor/fund-attributes/`;

    return CreditApiHelper.doRequest(
        url,
        'get',
        {
            params: {
                ...filter
            }
        }
    );
};

export const createFund = async (newPortfolio: NewFundModel, userType: string) => {
    return CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/${userType}/funds/`,
        'post',
        {
            ...newPortfolio
        }
    );
};

export const getAllFunds = async ( filter: any): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_ERM_ENTITIES}/resolving_entities`,
        'get',
        {
            params: {
                entity_type: 'fund',
                q: true,
                ...filter
            }
        }
    );
};

export const deleteFunds = async (
    ids: Id[],
    portfolioId: Id,
    userType: string,
    checkedAll: boolean,
    filter: PortfolioFilterModel
): Promise<any> => {
    let url = `${API_PORTFOLIO_URL}/${userType}/funds/bulk-delete/`;
    const params = ApiHelper.getClearParams(filter, deniedGetParams);
    const body = {
        portfolio_id: portfolioId,
        ids,
        checkedAll,
    };
    return CreditApiHelper.doRequest(url, 'post', body, '', true, params);
};

export const getContactsList = async (fundId: Id): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_FUND_CONTACTS_URL}/?fund=${fundId}&limit=${PAGINATION_LARGE_LIMIT}`,
        'get',
        {}
    );
};

export const updateContact = async (contactId: Id, contact: UpdateContactModel): Promise<any> => {
    return await CreditApiHelper.doRequest(
        `${API_FUND_CONTACTS_URL}/${contactId}/`,
        'patch',
        { ...contact }
    );
};

export const createContact = async (newContact: NewContactModel) => {
    return CreditApiHelper.doRequest(
        `${API_FUND_CONTACTS_URL}/`,
        'post',
        { ...newContact }
    );
};

export const deleteContacts = async (contactIds: Id[]): Promise<any> => {
    if (contactIds.length === 1) {
        return CreditApiHelper.doRequest(
            `${API_FUND_CONTACTS_URL}/${contactIds[0]}/`,
            'delete',
        );
    }

    return CreditApiHelper.doRequest(
        `${API_FUND_CONTACTS_URL}/bulk-delete/`,
        'post',
        {
            ids: contactIds
        }
    );
};

export const getFundsDataEntries = async (period?: string) => {
    return CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/requestor/funds/data-entry`,
        'get',
        {
            params: {
                period
            }
        });
};

export const exportFile = async (
    checkedIds: Id[], checkedAll: boolean, exportType: ExportPageType, filter?: FiltersState
) => {
    let filteredParams = filter ? ApiHelper.getClearParams(filter, deniedGetParams) : {};
    filteredParams = prepareFilterState(filteredParams);
    // set default ordering to export funds in constant order
    filteredParams.ordering = 'firm_name,name';
    return CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/requestor/funds/export-funds-list/`,
        'get',
        {
            params: {
                ...filteredParams,
                checkedAll,
                checked_ids: checkedIds,
                export_type: exportType,
            }
        }
    );
};

export const bulkUpload = async (files: FormData) => {
    try {
        const response = await CreditApiHelper.post(
            `${API_PORTFOLIO_URL}/requestor/funds/import-file/`,
            files,
            'multipart/form-data'
        );

        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getFundInternalIdList = async (filter: FiltersState): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_FUND_REQUESTOR_URL}/fund-internal-ids/`,
        'get',
        { params: filter }
    );
};

export const getEntityDatasets = async (
    isRequestor: boolean,
    baseFundId: Id,
    entityIds: Id[],
    filters: FiltersState,
    entityType?: BenchmarkType,
): Promise<any> => {
    const url = isRequestor ? API_FUND_REQUESTOR_URL : API_FUND_RESPONDER_URL;
    let params = ApiHelper.getClearParams(filters, [
        'checkedIds', 'checkedAll', 'scroller', 'currentPage', 'ordering', 'offset',
    ]);
    if (entityIds && entityIds.length > 0) {
        params.entity_id = entityIds;
    }

    if (entityType) {
        params.entity_type = entityType;
    }

    return CreditApiHelper.doRequest(
        `${url}/entity-datasets`,
        'get',
        {
            params: {
                base_id: baseFundId,
                ...params,
            }
        }
    );
};

export const getPlainFunds = async (filter: FiltersState, userAccountType: AccountType): Promise<any> => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    return CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/${userAccountType}/plain-funds/`,
        'get',
        {
            params
        }
    );
};

export const updateFundProfileUserSettings = async (
    fundId: Id,
    settings: FundProfileUserSettingsModel
): Promise<FundProfileUserSettingsModel> => {
    /**
     * Update fund profile settings endpoint that accept settings that should be updated (partial update)
     */
    return CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/update-fund-profile-settings/${fundId}`,
        'patch',
        {
            ...settings
        }
    );
};
