import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import ReactstrapButtomGroup from 'reactstrap/lib/ButtonGroup';
import * as classNames from 'classnames';

import { ButtonGroupTypes } from './ButtonTypes';

const styles = require('./ButtonPrimary.scss');
const ButtorGroup: React.FC<ButtonGroupTypes> = (
    {
        className, disabled, size, handleClick, firstValue,
        secondValue, firstTitle, secondTitle, firstInverse, secondInverse
    }
) => {
    const btnClass1 = classNames({
        'ui-btn': true,
        [className]: !!className,
        [styles.button_primary]: !!styles.button_primary,
        [styles.button_primary_inverse]: firstInverse,
    });

    const btnClass2 = classNames({
        'ui-btn': true,
        [className]: !!className,
        [styles.button_primary]: !!styles.button_primary,
        [styles.button_primary_inverse]: secondInverse,
    });

    return (
        <ReactstrapButtomGroup>
            <Button
                className={btnClass1}
                size={size}
                disabled={disabled}
                onClick={() => handleClick && handleClick(firstValue)}
            >
                {firstTitle}
            </Button>
            <Button
                className={btnClass2}
                size={size}
                disabled={disabled}
                onClick={() => handleClick && handleClick(secondValue)}
            >
                {secondTitle}
            </Button>
        </ReactstrapButtomGroup>

    );
};

ButtorGroup.defaultProps = {
    size: 'md',
    disabled: false,
};

export default ButtorGroup;
