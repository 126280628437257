import * as React from 'react';
import * as classNames from 'classnames';
import * as moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';

import { FilterItem, RangeEndings } from 'store/types';
import { FilterType } from 'store/constants';
import { FiltersState } from 'store/Filters/types';
import DataManagerHelper from 'helpers/dataManagerHelper';

import SelectFilter from './SelectFilter';
import DateFilter from './DateFilter';
import DottedToggleFilter from './DottedToggleFilter';
import SingleDateFilter from './SingleDateFilter';
import { Account } from 'store/Account/models/account';

interface Props {
    filters?: FilterItem[];
    currentFilter?: FiltersState;
    handleChange?: (data: FiltersState) => void;
    count?: number;
    setFilters?: any;
    countLabel?: string;
    rangeEndings?: RangeEndings;
    className?: any;
    account?: Account;
}

const styles = require('./HeaderFilterPanel.scss');

class HeaderFilterPanel extends React.PureComponent<Props> {

    handleChangeFilter = (data: FiltersState) => {
        if (this.props.handleChange) {
            this.props.handleChange(data);
        }
    }

    handleChangeSingleDateFilter = (data: FiltersState) => {
        if (data) {
            Object.keys(data).forEach((key: string) => {
                if (!data[key]) {
                    data[key] = DataManagerHelper.getActivePeriod();
                }
            });
        }
        this.handleChangeFilter(data);
    }

    render() {
        const {children, filters, currentFilter, className, account} = this.props;

        const handleChange = this.handleChangeFilter;

        let content: any = '';

        if (filters && currentFilter && handleChange) {
            const resultClassName = classNames({
                [styles.filter_panel]: !className,
                'ui-filter-panel': !className,
                [className]: !!className,
            });

            content = (
                <div className={resultClassName}>
                    <div className="row">
                        {
                            filters.map((filter: FilterItem, idx: number) => {
                                let itemClass = 'filter--item ';
                                itemClass += filter.className ? filter.className : 'col-lg-4 col-xl-3';
                                let input: any = '';

                                if (!filter.hasOwnProperty('visible') || filter.visible) {
                                    if (filter.type === FilterType.Select) {
                                        input = (
                                            <SelectFilter
                                                name={filter.name}
                                                label={filter.title}
                                                placeholder={filter.title}
                                                material={filter.material}
                                                disabled={filter.disabled}
                                                defaultValue={filter.defaultValue}
                                                value={currentFilter[filter.name]}
                                                options={filter.choices}
                                                handleChange={handleChange}
                                            />
                                        );
                                    } else if (filter.type === FilterType.CreditDate) {
                                        let rangeEndings: RangeEndings = {
                                            start: '_gte',
                                            end: '_lte',
                                        };
                                        if (filter.rangeEndings) {
                                            rangeEndings = filter.rangeEndings;
                                        }
                                        input = (
                                            <DateFilter
                                                title={filter.title}
                                                displayFormat={filter.displayFormat}
                                                withLabel={filter.withLabel}
                                                name={filter.name}
                                                value={currentFilter[filter.name]}
                                                start={currentFilter[`${filter.name}${rangeEndings.start}`]}
                                                end={currentFilter[`${filter.name}${rangeEndings.end}`]}
                                                handleChange={handleChange}
                                                rangeEndings={rangeEndings}
                                            />
                                        );
                                    } else if (filter.type === FilterType.SingleDate) {
                                        input = (
                                            <SingleDateFilter
                                                id={`date-filter-${idx}`}
                                                title={filter.title}
                                                displayFormat={filter.displayFormat}
                                                withLabel={filter.withLabel}
                                                name={filter.name}
                                                customInputIcon={filter.customInputIcon}
                                                material={filter.material}
                                                value={currentFilter[filter.name]}
                                                handleChange={this.handleChangeSingleDateFilter}
                                                className={styles['single-date-header-filter']}
                                                showClearDate={true}
                                                isOutsideRange={(day: any) => !isInclusivelyBeforeDay(day, moment())}
                                            />
                                        );
                                    } else if (filter.type === FilterType.DottedToggle) {
                                        input = (
                                            <DottedToggleFilter
                                                name={filter.name}
                                                withLabel={filter.withLabel}
                                                placeholder={filter.title}
                                                value={currentFilter[filter.name]}
                                                handleChange={handleChange}
                                                account={account}
                                            />
                                        );
                                    }
                                }

                                let filterComponent: string | JSX.Element = '';
                                if (input.type || input.length) {
                                    filterComponent = <div key={idx} className={itemClass}>{input}</div>;
                                }
                                return filterComponent;
                            })
                        }
                        {children}
                    </div>
                </div>

            );
        }
        return content;

    }
}
export default HeaderFilterPanel;