import { Action } from 'redux';

import UpdateProfileRequest from './models/updateProfileRequest';
import UpdatePasswordRequest from './models/updatePasswordRequest';
import ActiveUser from 'store/Diligence/Auth/models/activeUser';

export const UPDATE_PROFILE = 'store/diligence/user/updateProfile';
export const UPDATE_PASSWORD = 'store/diligence/user/updatePassword';

export interface UpdateProfileAction extends Action {
    request: UpdateProfileRequest;
}

export interface UpdatePasswordAction extends Action {
    request: UpdatePasswordRequest;
    user: ActiveUser;
}

export type UserAction = UpdateProfileAction | UpdatePasswordAction;