import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { formatText } from '../../../helpers/tableHelper';
import { FormatTypeChoices } from '../../../store/constants';
import { RequestStatus } from '../../../store/Requests/types';

interface Props {
    row: any;
}

const styles = require('./NotStartedRow.scss');

const NotStartedRow: React.FC<Props> = ({row}) => {
    return (
        <tbody className={styles.niceBody}>
            <tr className={styles.tr}>
                <td />
                <td>-</td>
                <td />
                <td>{row.account && row.account.name}</td>
                <td>{formatText(row.due_date, FormatTypeChoices.DateFormat)}</td>
                <td>{formatText(row.publish_date, FormatTypeChoices.DateFormat)}</td>
                <td
                    className="triangleTdColspan2"
                    colSpan={2}
                >
                    {
                        row.status !== RequestStatus.AwaitingApproval &&
                        (
                            <div>Awaiting data</div>
                        )
                    }
                    {
                        row.status === RequestStatus.AwaitingApproval &&
                        (
                            <div>Awaiting approval.
                                <NavLink
                                    to={'/requests/pending-requests'}
                                    className={styles.pendingLink}
                                >
                                    View pending requests.
                                </NavLink>
                            </div>
                        )
                    }
                </td>
            </tr>
        </tbody>
    );
};

export default NotStartedRow;
