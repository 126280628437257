import { TableHeader } from '../types';

export interface HeadersState {
    [key: string]: TableHeader[];
}

export const headerNames = {
    datasetDetail: 'dataset_detail',
    publishedDatasets: 'published_datasets',
    fundDatasets: 'fund_datasets',
    fundList: 'fund_list',
    profilesGroupsTargetSection: 'profiles_groups_target_section',
};

export interface HeaderWidthObject {
    name: string;
    width: number;
    active: boolean;
}
