import { createSelector } from 'reselect';

import { DiligenceRequestState, FilterEntity } from './types';
import { initialRequestsState } from './constants';
import { AppState, SelectItem } from 'store/types';

export const getDiligenceRequestState = (state: AppState): DiligenceRequestState =>
    state.diligenceRequests || initialRequestsState;

export const getType = (state: AppState, props: any) => props.hasOwnProperty('type') ? props.type : '';

export const getRequestAttributesChoices = createSelector(
    getDiligenceRequestState,
    getType,

    (state: DiligenceRequestState, type: string) => {
        let attributesChoices: {[key: string]: SelectItem} = {};

        if (state.filter.hasOwnProperty(type)) {
            // Add selected items to list
            state.filter[type].forEach(attribute => {
                if (!attributesChoices.hasOwnProperty(attribute.name)) {
                    attributesChoices[attribute.name] = {
                        value: attribute.type,
                        label: attribute.name
                    };
                }
            });
        }

        if (state && state[type]) {
            state[type].attributes.forEach((attribute: FilterEntity) => {
                if (!attributesChoices.hasOwnProperty(attribute.name)) {
                    attributesChoices[attribute.name] = {
                        value: attribute.type,
                        label: attribute.name
                    };
                }
            });
        }

        return Object.keys(attributesChoices).map(key => attributesChoices[key]);
    }
);
