import { GET_HEADERS, SET_HEADERS, SET_HEADERS_WIDTH } from './actions';
import { HeaderWidthObject } from './types';
import { TableHeader } from '../types';

export const getHeaders = (block: string, headers: TableHeader[]) => ({
    type: GET_HEADERS,
    block, headers
});

export const setHeaders = (block: string, headers: TableHeader[], isFromBackend: boolean = false) => ({
    type: SET_HEADERS,
    block, headers, isFromBackend
});

export const setHeadersWidth = (block: string, element: HeaderWidthObject) => ({
    type: SET_HEADERS_WIDTH,
    block, element
});
