import {
    APPLY_FILTER,
    APPLY_FILTER_DATASET_DETAIL,
    APPLY_FILTER_PUBLISHED_DATASETS,
    SET_DATASET_DETAIL,
    SET_DATASET_DETAIL_TEMPORARY_FILTER,
    SET_PUBLISHED_DATASETS,
    SET_PUBLISHED_DATASETS_WITH_SCROLL,
    SET_PUBLISHED_DATASETS_TEMPORARY_HEADERS,
    SET_DATASET_DETAIL_TEMPORARY_HEADERS,
    SET_DATASET_LIST,
    APPLY_FILTER_ACL_USERS,
    CHANGE_INVITING_ACL_USER,
    DELETE_ACL_USERS,
    DELETE_ACL_USERS_SUCCESS,
    INVITE_ACL_USER,
    INVITE_ACL_USER_SUCCESS,
    SET_ACL_USERS,
    SET_ROLE_ACL_USERS,
    SET_ROLE_ACL_USERS_SUCCESS,
    START_INVITING_ACL_USER,
    BECOME_ACL_OWNER,
    REQUEST_ACL_ACCESS,
    SET_PENDING_PUBLISH_DATASET,
    SET_ALL_REQUESTORS_TOGGLE,
    SET_REQUESTOR_CHECKBOXES,
    SET_DAYS_BEFORE_DUE_DATE,
    SET_REQUESTORS_TO_CONFIRM_PUBLISH,
    GET_REQUESTORS_TO_CONFIRM_PUBLISH,
    GET_REQUESTORS_TO_SET_PENDING_PUBLISH,
    SET_REQUESTORS_TO_SET_PENDING_PUBLISH,
    CONFIRM_PUBLISH_DATASET,
    SET_DAYS_BEFORE_DUE_DATE_FOR_EDIT_MODAL,
    EDIT_PENDING_PUBLISH_DATASET,
    UPDATE_DATASET_DETAIL_FIELD,
    UNLOCK_DATASET_DETAIL_ROW,
    SET_DATASET_DETAIL_WITH_SCROLL,
    GET_DATASET_HISTORY,
    SET_DATASET_HISTORY,
    SET_DATASET_LIST_WITH_SCROLL,
    GET_DATASET_FUND,
    SET_DATASET_FUND,
    SET_DATASET_FUND_CLEAR,
    ADD_DATASET_TO_FUND,
    CLEAR_DATASET_HISTORY,
    SET_CURRENT_DATASET,
    CLEAR_CURRENT_DATASET,
    DATASET_UPLOAD,
    SET_REQUESTORS,
    GET_REQUESTORS,
    ACCEPT_REQUEST,
    DENY_REQUEST,
    UPDATE_REQUEST,
    SET_IS_EDITING_DATASET,
    CLEAR_IS_EDITN_DATASET,
    SAVE_SETTINGS_DATASET_FUND,
    CHANGE_CACHED_STATUS_FOR_FUND,
    SET_CHANGE_CACHED_STATUS_FOR_FUND,
    CONFIRM_PUBLISH_DATASET_FROM_DETAIL, SET_PENDING_PUBLISH_DATASET_DETAIL, SET_PENDING_REQUESTS,
    SET_PENDING_NOTIFICATION_FLAG, DATASET_LIST_DOWNLOAD, DATASET_DOWNLOAD, SET_DATASET_FLAGS,
    PUBLISHED_DATASET_DOWNLOAD, APPLY_FILTER_INTERNAL_DATA, SET_INTERNAL_DATA, SET_INTERNAL_DATA_WITH_SCROLL,
    INTERNAL_DATA_DOWNLOAD, COMBINED_DATA_DOWNLOAD, INTERNAL_DATA_UPLOAD,
} from './actions';
import { CreateDatasetByPeriod, DatasetFund, DatasetRow, PublishedDataset } from './models/dataset';
import { AclUser } from './models/aclUser';
import {
    AclRole,
    DataManagerListPermissions,
    FilterAclUsers,
    GetDatasetHistory,
    UpdatingDatasetDetailField
} from './types';
import { InvitedUser } from '../Shared/models/invitedUser';
import { RequestorModel } from './models/requestor';
import { CheckboxOption } from './types';
import { RequestStatus } from '../Requests/types';
import { Id, TableHeader } from '../types';
import { FiltersState } from '../Filters/types';
import { defaultDataManagerListPermissions } from './constants';

export const applyFilter = (filter: any) => ({
    type: APPLY_FILTER,
    filter
});

export const applyFilterInternalData = (filter: FiltersState) => ({
    type: APPLY_FILTER_INTERNAL_DATA,
    filter
});

export const applyFilterAclUsers = (filter: FilterAclUsers) => ({
    type: APPLY_FILTER_ACL_USERS,
    filter
});

export const applyFilterPublishedDatasets = (filter: any) => ({
    type: APPLY_FILTER_PUBLISHED_DATASETS,
    filter
});

export const setDatasetList = (datasetListCount: number, datasetList: DatasetFund[], withScroll = false) => ({
    type: withScroll
        ? SET_DATASET_LIST_WITH_SCROLL
        : SET_DATASET_LIST,
    datasetListCount,
    datasetList,
});

export const setInternalData = (
    count: number,
    results: any[],
    permissions: DataManagerListPermissions = defaultDataManagerListPermissions,
    withScroll: boolean = false,
) => ({
    type: withScroll
        ? SET_INTERNAL_DATA_WITH_SCROLL
        : SET_INTERNAL_DATA,
    count,
    results,
    permissions
});

export const setPublishedDatasets = (
    publishedDatasetsCount: number,
    publishedDatasets: PublishedDataset[],
    withScroll = false
) => ({
    type: withScroll
        ? SET_PUBLISHED_DATASETS_WITH_SCROLL
        : SET_PUBLISHED_DATASETS,
    publishedDatasetsCount,
    publishedDatasets,
});

export const getRequestorsToConfirmPublish = (fundId: Id) => ({
    type: GET_REQUESTORS_TO_CONFIRM_PUBLISH,
    fundId,
});

export const setRequestorsToConfirmPublish = (requestors: RequestorModel[]) => ({
    type: SET_REQUESTORS_TO_CONFIRM_PUBLISH,
    requestors,
});

export const applyFilterDataset = (filter: any) => ({
    type: APPLY_FILTER_DATASET_DETAIL,
    filter
});

export const setDatasetDetail = (datasetDetailCount: number, datasetDetail: any, withScroll = false) => ({
    type: withScroll
        ? SET_DATASET_DETAIL_WITH_SCROLL
        : SET_DATASET_DETAIL,
    datasetDetailCount,
    datasetDetail,
});

export const setAclUsers = (aclUsersCount: number, aclUsers: AclUser[], withScroll: boolean) => ({
    type: SET_ACL_USERS,
    aclUsersCount, aclUsers, withScroll
});

export const startInvitingAclUser = () => ({
    type: START_INVITING_ACL_USER
});

export const changeInvitingAclUser = (invitedUser: InvitedUser) => ({
    type: CHANGE_INVITING_ACL_USER,
    invitedUser
});

export const inviteAclUser = (fundId: Id, invitedUser: InvitedUser, closeModal = true) => ({
    type: INVITE_ACL_USER,
    fundId, invitedUser, closeModal
});

export const inviteAclUserSuccess = (aclUser: AclUser) => ({
    type: INVITE_ACL_USER_SUCCESS,
    aclUser
});

export const deleteAclUsers = (fundId: Id, ids: Id[], closeModal = true) => ({
    type: DELETE_ACL_USERS,
    fundId, ids, closeModal
});

export const deleteAclUsersSuccess = (ids: Id[]) => ({
    type: DELETE_ACL_USERS_SUCCESS,
    ids
});

export const setRoleAclUsers = (fundId: Id, ids: Id[], role: AclRole,
                                closeModal = true, useFundIdForLoadList = true) => ({
    type: SET_ROLE_ACL_USERS,
    fundId, ids, role, closeModal, useFundIdForLoadList
});

export const setRoleAclUsersSuccess = (aclUsers: AclUser[]) => ({
    type: SET_ROLE_ACL_USERS_SUCCESS,
    aclUsers
});

export const becomeAclOwner = (fundId: Id) => ({
    type: BECOME_ACL_OWNER,
    fundId
});

export const requestAclAccess = (fundId: Id) => ({
    type: REQUEST_ACL_ACCESS,
    fundId
});

export const getRequestorsToSetPendingPublish = (fundId: Id) => ({
    type: GET_REQUESTORS_TO_SET_PENDING_PUBLISH,
    fundId,
});

export const setRequestorsToSetPendingPublish = (requestors: RequestorModel[]) => ({
    type: SET_REQUESTORS_TO_SET_PENDING_PUBLISH,
    requestors,
});

export const setAllRequestorsToggle = (toggleValue: boolean) => ({
    type: SET_ALL_REQUESTORS_TOGGLE,
    toggleValue,
});

export const setRequestorCheckboxes = (requestorCheckboxes: CheckboxOption[]) => ({
    type: SET_REQUESTOR_CHECKBOXES,
    requestorCheckboxes,
});

export const setRequestors = (requestors: any[]) => ({
    type: SET_REQUESTORS,
    requestors
});

export const getRequestors = (fundId?: Id) => ({
    type: GET_REQUESTORS,
    fundId
});

export const confirmPublishDataset = (fundId: Id, requestorIds: Id[]) => ({
    type: CONFIRM_PUBLISH_DATASET,
    fundId,
    requestorIds,
});

export const confirmPublishDatasetFromDetail = (fundId: Id, requestorIds: Id[]) => ({
    type: CONFIRM_PUBLISH_DATASET_FROM_DETAIL,
    fundId,
    requestorIds,
});

export const setPendingPublishDataset = (fundId: Id, daysBeforeDueDate: number, requestorIds: Id[]) => ({
    type: SET_PENDING_PUBLISH_DATASET,
    fundId,
    daysBeforeDueDate,
    requestorIds,
});

export const setPendingPublishDatasetDetail = (fundId: Id, daysBeforeDueDate: number, requestorIds: Id[]) => ({
    type: SET_PENDING_PUBLISH_DATASET_DETAIL,
    fundId,
    daysBeforeDueDate,
    requestorIds,
});

export const setDaysBeforeDueDate = (value: number) => ({
    type: SET_DAYS_BEFORE_DUE_DATE,
    value,
});

export const setDaysBeforeDueDateForEditModal = (value: number) => ({
    type: SET_DAYS_BEFORE_DUE_DATE_FOR_EDIT_MODAL,
    value,
});

export const editPendingPublishDataset = (fundId: Id, requestorId: Id, daysBeforeDueDate: number) => ({
    type: EDIT_PENDING_PUBLISH_DATASET,
    fundId,
    requestorId,
    daysBeforeDueDate,
});

export const setDatasetDetailTemporaryFilter = (temporaryFilter: any) => ({
    type: SET_DATASET_DETAIL_TEMPORARY_FILTER,
    temporaryFilter
});

export const setDatasetDetailTemporaryHeaders = (temporaryHeaders: TableHeader[]) => ({
    type: SET_DATASET_DETAIL_TEMPORARY_HEADERS,
    temporaryHeaders
});

export const setPublishedDatasetsTemporaryHeaders = (temporaryHeaders: TableHeader[]) => ({
    type: SET_PUBLISHED_DATASETS_TEMPORARY_HEADERS,
    temporaryHeaders
});

export const updateDatasetDetailField = (payload: UpdatingDatasetDetailField) => ({
    type: UPDATE_DATASET_DETAIL_FIELD,
    payload
});

export const getDatasetHistory = (payload: GetDatasetHistory) => ({
    type: GET_DATASET_HISTORY,
    payload
});

export const setDatasetHistory = (parentId: Id, results: any) => ({
    type: SET_DATASET_HISTORY,
    parentId,
    results
});

export const cleartDatasetHistory = (parentId: Id) => ({
    type: CLEAR_DATASET_HISTORY,
    parentId
});

export const unlockDatasetRow = (datasetRow: DatasetRow, fundId: Id) => ({
    type: UNLOCK_DATASET_DETAIL_ROW,
    datasetRow,
    fundId,
});

export const getDatasetFund = (id: Id) => ({
    type: GET_DATASET_FUND,
    id
});

export const saveSettingsDatasetFund = (id: string, payload: any, requestor = false) => ({
    type: SAVE_SETTINGS_DATASET_FUND,
    id,
    payload,
    requestor,
});

export const setIsEditingDataset = (row: any) => ({
    type: SET_IS_EDITING_DATASET,
    row
});

export const clearIsEdingDataset = () => ({
    type: CLEAR_IS_EDITN_DATASET,
});

export const setDatasetFund = (result: DatasetFund) => ({
    type: SET_DATASET_FUND,
    result
});

export const setDatasetFundFlags = (payload: any) => ({
    type: SET_DATASET_FLAGS,
    payload
});

export const addDatasetToFund = (payload: CreateDatasetByPeriod) => ({
    type: ADD_DATASET_TO_FUND,
    payload
});

export const setDatasetFundClear = () => ({
    type: SET_DATASET_FUND_CLEAR
});

export const datasetUpload = (files: any, id: Id) => ({
    type: DATASET_UPLOAD,
    files
});

export const internalDataUpload = (files: FormData, id: Id) => ({
    type: INTERNAL_DATA_UPLOAD,
    files
});

export const datasetListDownload = (checkedIds: Id[], checkedAll: boolean) => ({
    type: DATASET_LIST_DOWNLOAD,
    checkedIds,
    checkedAll,
});

export const datasetDownload = (checkedIds: Id[], checkedAll: boolean) => ({
    type: DATASET_DOWNLOAD,
    checkedIds,
    checkedAll,
});

export const publishedDatasetDownload = (checkedIds: Id[], checkedAll: boolean) => ({
    type: PUBLISHED_DATASET_DOWNLOAD,
    checkedIds,
    checkedAll,
});

export const internalDataDownload = (checkedIds: Id[], checkedAll: boolean) => ({
    type: INTERNAL_DATA_DOWNLOAD,
    checkedIds,
    checkedAll,
});

export const combinedDataDownload = (checkedIds: Id[], checkedAll: boolean) => ({
    type: COMBINED_DATA_DOWNLOAD,
    checkedIds,
    checkedAll,
});

export const setCurrentDataset = (dataset: any) => ({
    type: SET_CURRENT_DATASET,
    dataset
});

export const clearCurrentDataset = () => ({
    type: CLEAR_CURRENT_DATASET,
});

export const acceptRequest = (requestId: any) => ({
    type: ACCEPT_REQUEST,
    requestId,
    status: RequestStatus.Active
});

export const changeCachedStatusForFund = (period: string, status: string) => ({
    type: CHANGE_CACHED_STATUS_FOR_FUND,
    period,
    status
});

export const setChangeCachedStatusForFund = (status: string) => ({
    type: SET_CHANGE_CACHED_STATUS_FOR_FUND,
    status
});

export const denyRequest = (requestId: any, message: string | null = null) => ({
    type: DENY_REQUEST,
    requestId,
    message,
    status: RequestStatus.Denied,
});

export const updateRequest = (request: any) => ({
    type: UPDATE_REQUEST,
    request
});

export const setPendingRequests = (fundId: Id, count: number) => ({
    type: SET_PENDING_REQUESTS,
    fundId,
    count,
});

export const setPendingNotificationFlag = (fundId: Id, flag: boolean) => ({
    type: SET_PENDING_NOTIFICATION_FLAG,
    fundId,
    flag,
});