import * as React from 'react';
import User from '../../../../store/Auth/models/user';

interface Props {
    user: User;
}

const UserEmail: React.FC<Props> = ({user}) => (
    <div>
        <span>{user.first_name}</span> <span>{user.last_name}</span>
    </div>
);

export default UserEmail;
