import {
    UPDATE_PASSWORD, UPDATE_PROFILE,
    UPGRADE_ADHOC_ACCOUNT, INIT_UPGRADE_ADHOC_ACCOUNT
} from './actions';
import UpdateProfileRequest from './models/updateProfileRequest';
import UpdatePasswordRequest from './models/updatePasswordRequest';

export const updateProfile = (request: UpdateProfileRequest) => ({
    type: UPDATE_PROFILE,
    request
});

export const updatePassword = (request: UpdatePasswordRequest) => ({
    type: UPDATE_PASSWORD,
    request
});

export const upgradeAdhocAccount = () => ({
    type: UPGRADE_ADHOC_ACCOUNT
});

export const initUpgradeAdhocAccount = () => ({
    type: INIT_UPGRADE_ADHOC_ACCOUNT
});