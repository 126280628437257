import {
    AlertsAction,
    SET_FUND_EVALUATION_RESULTS,
    SET_FUND_STATISTIC,
    SetFundEvaluationResultsAction,
    SetFundStatisticAction,

    SET_ALERT_LIST,
    SET_ALERT_LIST_WITH_SCROLL,
    SET_ALERT_STATUS_ITEMS,
    SetAlertListAction,
    SetAlertStatusItemsAction, SET_FUND_EVALUATION_RESULTS_WITH_SCROLL
} from './actions';
import { initialState } from './constants';
import { AlertsState } from './types';

export const alertsReducer = (state: AlertsState = initialState, action: AlertsAction) => {
    switch (action.type) {
        case SET_ALERT_LIST:
            const alertList = (<SetAlertListAction> action);
            return {
                ...state,
                list: alertList.list,
                count: alertList.count,
                alertCount: alertList.alertCount,
            };
        case SET_ALERT_LIST_WITH_SCROLL: {
            const list = [
                ...state.list,
                ...(<SetAlertListAction> action).list
            ];
            return {
                ...state,
                list
            };
        }
        case SET_ALERT_STATUS_ITEMS:
            const { data } = (<SetAlertStatusItemsAction> action);
            return {
                ...state,
                alertStatusItems: data
            };
        case SET_FUND_STATISTIC:
            const {permissions, ...restStatistic} = (<SetFundStatisticAction> action).statistic;
            return {
                ...state,
                viewedFund: {
                    ...state.viewedFund,
                    statistic: {...restStatistic}
                },
            };
        case SET_FUND_EVALUATION_RESULTS:
            return {
                ...state,
                viewedFund: {
                    ...state.viewedFund,
                    evaluation_results: {...(<SetFundEvaluationResultsAction> action).results},
                }
            };
        case SET_FUND_EVALUATION_RESULTS_WITH_SCROLL: {
            const list = [
                ...(state.viewedFund && state.viewedFund.evaluation_results
                    ? state.viewedFund.evaluation_results.results
                    : []
                ),
                ...(<SetFundEvaluationResultsAction> action).results.results
            ];
            return {
                ...state,
                viewedFund: {
                    ...state.viewedFund,
                    evaluation_results: {
                        ...(state.viewedFund ? state.viewedFund.evaluation_results : {}),
                        results: list
                    }
                }
            };
        }
        default:
            return state;
    }
};
