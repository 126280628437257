import { DiligenceRequestStatus } from './types';
import { SelectItem } from 'store/types';

export const initialRequestsState = {
    list: [],
    count: 0,
    filter: {
        page: 0
    },
    allRequests: []
};

export const DILIGENCE_REQUEST_STATUS_TITLES = {
    [DiligenceRequestStatus.NotStarted]: 'Not Started',
    [DiligenceRequestStatus.InProgress]: 'In Progress',
    [DiligenceRequestStatus.Overdue]: 'Overdue',
    [DiligenceRequestStatus.Rescinded]: 'Rescinded',
    [DiligenceRequestStatus.Published]: 'Published',
    [DiligenceRequestStatus.Reopened]: 'Reopened',
};

export const DILIGENCE_REQUEST_STATUS_CHOICES: SelectItem[] = [
    {
        label: DILIGENCE_REQUEST_STATUS_TITLES[DiligenceRequestStatus.Published],
        value: DiligenceRequestStatus.Published
    },
    {
        label: DILIGENCE_REQUEST_STATUS_TITLES[DiligenceRequestStatus.Reopened],
        value: DiligenceRequestStatus.Reopened
    },
    {
        label: DILIGENCE_REQUEST_STATUS_TITLES[DiligenceRequestStatus.NotStarted],
        value: DiligenceRequestStatus.NotStarted
    },
    {
        label: DILIGENCE_REQUEST_STATUS_TITLES[DiligenceRequestStatus.InProgress],
        value: DiligenceRequestStatus.InProgress
    },
    {
        label: DILIGENCE_REQUEST_STATUS_TITLES[DiligenceRequestStatus.Overdue],
        value: DiligenceRequestStatus.Overdue
    },
    {
        label: DILIGENCE_REQUEST_STATUS_TITLES[DiligenceRequestStatus.Rescinded],
        value: DiligenceRequestStatus.Rescinded
    },
];
