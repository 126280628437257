import { Id, TableHeader } from 'store/types';
import { FiltersState } from 'store/Filters/types';
import { PeerGroup, TargetFund } from 'store/Fund/types';

import { Group, GroupListPermissions, GroupProperty } from './types';
import {
    DELETE_GROUPS,
    GET_GROUPS,
    INIT_GROUP_CREATION,
    INIT_GROUP_EDITING,
    SET_EDITED_GROUP,
    SET_GROUPS,
    SET_GROUPS_WITH_SCROLL,
    GET_TARGET_FUND_LIST,
    SET_TARGET_FUND_LIST,
    SET_TARGET_FUND_LIST_WITH_SCROLL,
    UPDATE_GROUP_PROPERTY,
    SET_TARGET_TEMPORARY_HEADERS,
    UPDATE_GROUP_TARGET,
    SAVE_GROUP,
    GET_FUND_LIST_FOR_ADD_ITEMS_MODAL,
    HANDLE_FUND_LIST_FROM_ADD_ITEMS_MODAL,
    ADD_FUND_LIST_TO_BENCHMARKS, DELETE_ITEM_LIST_FROM_BENCHMARKS, GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL,
    HANDLE_PEER_GROUP_LIST_FROM_ADD_ITEMS_MODAL, ADD_PEER_GROUP_LIST_TO_BENCHMARKS, GET_GRID_CONFIGURATION,
    PUBLISH_GROUP,
    SELECT_VERSION, SAVE_TARGET_GROUP_COLUMNS,
} from './actions';
import { FundModel } from 'store/Fund/models/fund';
import { defaultGroupListPermissions } from './constants';

export const getGroups = (filter: FiltersState) => ({
    type: GET_GROUPS,
    filter
});

export const getGridConfiguration = (gridType: string, instanceId: Id) => ({
    type: GET_GRID_CONFIGURATION,
    gridType,
    instanceId
});

export const setGroups = (
    groups: Group[],
    count: number,
    permissions: GroupListPermissions = defaultGroupListPermissions,
    withScroll = false,
) => ({
    type: withScroll
        ? SET_GROUPS_WITH_SCROLL
        : SET_GROUPS,
    groups,
    count,
    permissions,
});

export const setEditedGroup = (group: Partial<Group>) => ({
    type: SET_EDITED_GROUP,
    group
});

export const deleteGroups = (ids: Id[], checkedAll: boolean) => ({
    type: DELETE_GROUPS,
    ids,
    checkedAll,
});

export const initGroupCreation = () => ({
    type: INIT_GROUP_CREATION,
});

export const initGroupEditing = (id: Id) => ({
    type: INIT_GROUP_EDITING,
    id
});

export const getTargetFundList = (filter: FiltersState, entityType?: string, entityId?: Id) => ({
    type: GET_TARGET_FUND_LIST,
    filter, entityType, entityId
});

export const updateGroupProperty = (name: string, value: GroupProperty) => ({
    type: UPDATE_GROUP_PROPERTY,
    name, value
});

export const updateGroupTarget = () => ({
    type: UPDATE_GROUP_TARGET
});

export const saveGroup = (group: Group) => ({
    type: SAVE_GROUP,
    group
});

export const publishGroup = (groupId: Id, id: Id) => ({
    type: PUBLISH_GROUP,
    groupId, id
});

export const selectVersion = (id: Id, version: number, isHistoryVersion: boolean) => ({
    type: SELECT_VERSION,
    id, version, isHistoryVersion
});

export const setTargetFundList = (count: number, firmCount: number, list: TargetFund[], withScroll = false) => ({
    type: withScroll
        ? SET_TARGET_FUND_LIST_WITH_SCROLL
        : SET_TARGET_FUND_LIST,
    count, firmCount, list,
});

export const setTargetTemporaryHeaders = (headers: TableHeader[]) => ({
    type: SET_TARGET_TEMPORARY_HEADERS,
    headers
});

export const getFundListForAddItemsModal = (filter: FiltersState) => ({
    type: GET_FUND_LIST_FOR_ADD_ITEMS_MODAL,
    filter,
});

export const getPeerGroupListForAddItemsModal = (filter: FiltersState) => ({
    type: GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL,
    filter,
});

export const handleFundListFromAddItemsModal = (filterName: string) => ({
    type: HANDLE_FUND_LIST_FROM_ADD_ITEMS_MODAL,
    filterName
});

export const handlePeerGroupListFromAddItemsModal = (filterName: string) => ({
    type: HANDLE_PEER_GROUP_LIST_FROM_ADD_ITEMS_MODAL,
    filterName
});

export const addFundListToBenchmark = (funds: FundModel[]) => ({
    type: ADD_FUND_LIST_TO_BENCHMARKS,
    funds
});

export const addPeerGroupListToBenchmark = (groups: PeerGroup[]) => ({
    type: ADD_PEER_GROUP_LIST_TO_BENCHMARKS,
    groups
});

export const deleteItemListFromBenchmark = (ids: Id[]) => ({
    type: DELETE_ITEM_LIST_FROM_BENCHMARKS,
    ids
});

export const saveTargetGroupColumns = (visibleHeaders: string[], instanceId: Id, gridType: string) => ({
    type: SAVE_TARGET_GROUP_COLUMNS,
    visibleHeaders,
    instanceId,
    gridType,
});
