import * as React from 'react';
import * as classNames from 'classnames';

import SelectFilter from 'components/Shared/Filters/SelectFilter';
import Statistics from 'components/Shared/Filters/Statistics';
import SelectWithCheckboxes from 'components/Shared/Filters/SelectWithCheckboxes';
import SelectWithCheckboxesSync from 'components/Shared/Filters/SelectWithCheckboxesSync';
import InputFilter from 'components/Shared/Filters/InputFilter';
import DateFilter from 'components/Shared/Filters/DateFilter';
import SingleDateFilter from 'components/Shared/Filters/SingleDateFilter';
import ToggleFilter from 'components/Shared/Filters/ToggleFilter';
import SingleCheckboxFilter from 'components/Shared/Filters/SingleCheckboxFilter';
import SearchFilter from 'components/Diligence/Shared/Filters/SearchFilter';
import OldSearchFilter from 'components/Shared/Filters/SearchFilter';
import { RangeEndings, FilterItem, AdvancedFilterItem } from 'store/types';
import { FilterType } from 'store/constants';
import { Chip, FiltersState } from 'store/Filters/types';
import ChipsPanel from 'components/Shared/Panels/ChipsPanel';
import {
    FilterDecoratorChange,
} from 'decorators/FilterDecorator';
import AdvancedFiltersModal from 'components/Shared/Modals/AdvancedFiltersModal/AdvancedFiltersModal';
import {
    AdvancedFilterDecoratorSearch,
    AdvancedFilterDecoratorToggle,
    AdvancedFilterDecoratorUpdate,
} from 'decorators/AdvancedFilterDecorator';
import { FilterEntity } from 'store/Diligence/DiligenceRequest/types';
import { DiligenceFilterNames } from 'store/Diligence/Filters/types';
import { ADVANCED_FILTERS_MODAL_TITLE } from 'store/AdvancedFilters/constants';

interface Props {
    filters?: FilterItem[];
    currentFilter: FiltersState;
    handleChange?: FilterDecoratorChange;
    handleToggleModal?: AdvancedFilterDecoratorToggle;
    handleUpdateModal?: AdvancedFilterDecoratorUpdate;
    handleAttributeSearch?: AdvancedFilterDecoratorSearch;
    count?: number;
    countLabel?: string;
    rangeEndings?: RangeEndings;
    className?: string;
    chips?: Chip[];
    filterPageName?: string;
    disabled?: boolean;

    isFilterModalOpen?: boolean;
    advancedFilters?: AdvancedFilterItem[];
    searchFilterEntities?: FilterEntity[];
    currentModalFilter?: FiltersState;
}

const styles = require('./FilterPanel.scss');

class FilterPanel extends React.PureComponent<Props> {
    state = {
        filters: [],
    };

    componentDidMount () {
        this.setState({
            filters: this.filteredFilters
        });
    }

    get filteredFilters(): FilterItem[] {
        return this.props.filters
            ? this.props.filters.filter(item => !item.hasOwnProperty('visible') || item.visible)
            : [];
    }

    handleChangeFilter = (data: FiltersState) => {
        if (this.props.handleChange) {
            this.props.handleChange(data);
        }
    }

    handleToggleFiltersModal = () => {
        if (this.props.handleToggleModal) {
            this.props.handleToggleModal();
        }
    }

    handleUpdateFiltersModal = (filter: FiltersState) => {
        if (this.props.handleUpdateModal) {
            this.props.handleUpdateModal(filter);
        }
    }

    handleSearch = (attributeType: string, filter: FiltersState) => {
        if (this.props.handleAttributeSearch) {
            this.props.handleAttributeSearch(attributeType, filter);
        }
    }

    handleShowFilters = (flag: boolean) => {

        const newFilters = this.state.filters.map((item: FiltersState) => {
            return {
                ...item,
                isShownInitially: flag
            };
        });
        this.setState({filters: newFilters});
    }

    isFiltersShowed = () => {
        return this.state.filters.find((item: FiltersState) => item.isShownInitially === false);
    }

    isIssetHiddenFilters = () => {
        const filters: FilterItem[] = this.filteredFilters;
        return !!filters && !!filters.find((item: FilterItem) => {
            if (!item.hasOwnProperty('isShownInitially')) {
                return false;
            }

            return item.isShownInitially === false;
        });
    }

    render() {
        const {children, currentFilter, className, chips, filterPageName,
            isFilterModalOpen, advancedFilters, currentModalFilter, disabled} = this.props;
        const handleChange = this.handleChangeFilter;
        const handleToggle = this.handleToggleFiltersModal;
        const handleUpdate = this.handleUpdateFiltersModal;
        const handleSearch = this.handleSearch;

        let content: string | JSX.Element = '';

        if (this.filteredFilters && currentFilter && handleChange) {
            let isShowed: boolean | undefined = false;
            let isIssetHiddenFilter: boolean = false;
            if (this.state.filters.length) {
                isShowed = this.isFiltersShowed();
                isIssetHiddenFilter = this.isIssetHiddenFilters();
            }

            const resultClassName = classNames({
                [styles.filter_panel]: !className,
                'ui-filter-panel': !className,
                [`${className}`]: !!className,
            });

            content = (
                <>
                    <div className={resultClassName}>
                        <div>
                            <div className="row">
                                {
                                    this.filteredFilters.map((filter, idx: number) => {
                                        const isVisible: FilterItem = this.state.filters[idx];

                                        const classes = {
                                            'filter--item': true,
                                            [`${filter.className}`]: !!filter.className,
                                            'col-lg-5': !filter.className,
                                            'col-xl-2': !filter.className,
                                            'd-none': !(!filter.hasOwnProperty('isShownInitially') ||
                                                filter.isShownInitially || (isVisible && isVisible.isShownInitially)
                                            )
                                        };

                                        const input = this.getFilterElement(filter, idx, handleChange, handleToggle);

                                        let answer: string | JSX.Element = '';
                                        if (input) {
                                            answer = (
                                                <div
                                                    key={`${filter.name}-${idx}`}
                                                    className={classNames(classes)}
                                                >
                                                    {input}
                                                </div>
                                            );
                                        }
                                        return answer;
                                    })
                                }
                                {

                                    isShowed && isIssetHiddenFilter &&
                                    <div
                                        className=" col-lg-12 text-primary toggle-show"
                                        onClick={() => this.handleShowFilters(true)}
                                    >
                                        <a>Show more filters</a>
                                    </div>

                                }
                                {

                                    !isShowed && isIssetHiddenFilter &&
                                    <div className=" col-lg-12 mt-3 text-primary toggle-show">
                                        <a onClick={() => this.handleShowFilters(false)}>
                                            Less filters
                                        </a>
                                    </div>

                                }
                                {
                                    chips && chips.length > 0 &&
                                    <div className="col-lg-12">
                                        <ChipsPanel
                                            disabled={disabled || false}
                                            chips={chips}
                                            handleChange={handleChange}
                                            currentFilter={currentFilter}
                                            filterPageName={filterPageName}
                                        />
                                    </div>
                                }
                                {children}
                            </div>
                        </div>
                    </div>
                    {advancedFilters && currentModalFilter &&
                        <AdvancedFiltersModal
                            isOpen={!!isFilterModalOpen}
                            title={ADVANCED_FILTERS_MODAL_TITLE}
                            filters={advancedFilters.filter(item => !item.hasOwnProperty('visible') || item.visible)}
                            toggle={handleToggle}
                            currentFilter={currentFilter}
                            filterPageName={filterPageName}
                            handleChange={handleChange}
                            handleUpdate={handleUpdate}
                            handleSearch={handleSearch}
                            advancedFilter={currentModalFilter}
                        />
                    }
                </>
            );
        }
        return content;

    }

    private getFilterElement(
        filter: FilterItem, idx: number, handleChange: (data: FiltersState) => void, handleToggle: () => void
    ) {
        let input: React.ReactElement<any> | React.ReactElement<any>[] | null = null;
        const {currentFilter, filterPageName, advancedFilters, currentModalFilter} = this.props;

        if (filter.type === FilterType.DiligenceSearch || filter.type === FilterType.Search) {
            /*
             * The new Search Filter with the Advanced Filter Modal currently only shows on dashboard and Funds page
             */
            if (filterPageName === DiligenceFilterNames.diligenceRequest
                || advancedFilters && advancedFilters.length > 0 && currentModalFilter) {
                input = (
                    <SearchFilter
                        name={filter.name}
                        withLabel={filter.withLabel}
                        value={currentFilter[filter.name]}
                        placeholder={filter.title}
                        handleChange={handleChange}
                        handleToggle={handleToggle}
                        currentFilter={currentFilter}
                        filterEntities={this.props.searchFilterEntities}
                        filterPageName={filterPageName}
                    />
                );
            } else {
                input = (
                    <OldSearchFilter
                        disabled={filter.disabled}
                        name={filter.name}
                        withLabel={filter.withLabel}
                        value={currentFilter[filter.name]}
                        placeholder={filter.title}
                        handleChange={handleChange}
                    />
                );
            }
        } else if (filter.type === FilterType.Select) {
            input = (
                <SelectFilter
                    name={filter.name}
                    label={filter.title}
                    placeholder={filter.placeholder}
                    material={filter.material}
                    disabled={filter.disabled}
                    defaultValue={filter.defaultValue}
                    value={currentFilter[filter.name]}
                    options={filter.choices}
                    handleChange={handleChange}
                    innerClassName={filter.innerClassName}
                    classNamePrefix={filter.classNamePrefix}
                    customMenuPortalClass={filter.customMenuPortalClass}
                    clearable={filter.clearable}
                />
            );
        } else if (filter.type === FilterType.Statistics && filter.stats && filter.stats.length > 0) {
            input = (
                <Statistics stats={filter.stats}/>
            );
        } else if (filter.type === FilterType.CreditSelectWithCheckboxes) {
            input = (
                <SelectWithCheckboxes
                    filter={filter}
                    value={currentFilter[filter.name]}
                    handleChange={handleChange}
                    handleRequest={filter.handleRequest}
                    currentFilter={filter.currentFilter}
                    setFilters={filter.setFilters}
                    pageName={filterPageName}
                    disabled={filter.disabled}
                    idPrefix={filter.idPrefix}
                    isFilterValueObject={filter.isFilterValueObject}
                />
            );
        } else if (filter.type === FilterType.CreditSelectWithCheckboxesSync) {
            input = (
                <SelectWithCheckboxesSync
                    filter={filter}
                    value={currentFilter[filter.name]}
                    handleChange={handleChange}
                    disabled={filter.disabled}
                />
            );
        } else if (filter.type === FilterType.Input) {
            input = (
                <InputFilter
                    name={filter.name}
                    withLabel={filter.withLabel}
                    type={filter.inputType}
                    value={currentFilter[filter.name]}
                    placeholder={filter.title}
                    handleChange={handleChange}
                />
            );
        } else if (filter.type === FilterType.CreditDate) {
            let rangeEndings: RangeEndings = {
                start: '_gte',
                end: '_lte',
            };
            if (filter.rangeEndings) {
                rangeEndings = filter.rangeEndings;
            }
            input = (
                <DateFilter
                    title={filter.title}
                    displayFormat={filter.displayFormat}
                    withLabel={filter.withLabel}
                    name={filter.name}
                    value={currentFilter[filter.name]}
                    start={currentFilter[`${filter.name}${rangeEndings.start}`]}
                    end={currentFilter[`${filter.name}${rangeEndings.end}`]}
                    handleChange={handleChange}
                    rangeEndings={rangeEndings}
                />
            );
        } else if (filter.type === FilterType.SingleDate) {
            input = (
                <SingleDateFilter
                    id={`date-filter-${idx}`}
                    title={filter.title}
                    displayFormat={filter.displayFormat}
                    withLabel={filter.withLabel}
                    name={filter.name}
                    customInputIcon={filter.customInputIcon}
                    material={filter.material}
                    value={currentFilter[filter.name]}
                    handleChange={handleChange}
                />
            );
        } else if (filter.type === FilterType.Content) {
            input = filter.content || null;
        } else  if (filter.type === FilterType.Toggle) {
            input = (
                <ToggleFilter
                    name={filter.name}
                    withLabel={filter.withLabel}
                    placeholder={filter.title}
                    value={currentFilter[filter.name]}
                    handleChange={handleChange}
                />
            );
        } else if (filter.type === FilterType.SingleCheckbox) {
            input = (
                <SingleCheckboxFilter
                    disabled={filter.disabled}
                    name={filter.name}
                    label={filter.title}
                    value={currentFilter[filter.name]}
                    handleChange={handleChange}
                    idPrefix={filter.idPrefix}
                />
            );
        }
        return input;
    }
}
export default FilterPanel;
