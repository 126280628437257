import { AppState, TableHeader } from '../types';

export const getHeadersState = (state: AppState) => state.headers;

export const getHeaders = (state: AppState, props: {name: string}): TableHeader[] => {
    const headersState = getHeadersState(state);
    return headersState && headersState.hasOwnProperty(props.name)
        ? headersState[props.name]
        : [];
};

export const getHeadersVisibleCount = (state: AppState, props: {name: string}, ) => {
    const headersState = getHeadersState(state);
    let count = 0;
    if (headersState && headersState.hasOwnProperty(props.name)) {
        headersState[props.name].map((item: TableHeader) => {
            if (item.active) {
                count++;
            }
        });
    }
    return count;
};