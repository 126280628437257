import { DiligenceFilterNames } from './types';
import { FiltersState } from 'store/Filters/types';
import { DEFAULT_DILIGENCE_REQUEST_FILTER } from 'store/constants';

import {
    GET_IS_LOADING,
    GetFiltersAction, GetIsLoadingAction, SET_IS_LOADING, SetFiltersAction,
    SetIsLoadingAction, GET_FILTERS, SET_FILTERS, CHANGE_SAVED_ORDERING,
    FiltersAction, ChangeSavedOrderingAction
} from './actions';

const initialState: FiltersState = {
    savedOrdering: {
        flag: false,
        value: ''
    },
    isLoading: false,
    [DiligenceFilterNames.diligenceRequest]: {
        ...DEFAULT_DILIGENCE_REQUEST_FILTER
    }
};

export const diligenceFiltersReducer = (state: FiltersState = initialState, action: FiltersAction) => {
    switch (action.type) {
        case CHANGE_SAVED_ORDERING:
            action = (<ChangeSavedOrderingAction> action);
            return {
                ...state,
                savedOrdering: {
                    flag: action.flag,
                    value: action.value
                }
            };
        case SET_IS_LOADING:
            action = (<SetIsLoadingAction> action);
            return {
                ...state,
                isLoading: action.isLoading
            };
        case GET_IS_LOADING:
            action = (<GetIsLoadingAction> action);
            return {
                ...state
            };
        case GET_FILTERS:
            action = (<GetFiltersAction> action);
            return {
                ...state,
                [action.filterName]: action.payload
            };
        case SET_FILTERS:
            action = (<SetFiltersAction> action);
            return {
                ...state,
                [action.filterName]: action.payload
            };
        default:
            return state;
    }
};
