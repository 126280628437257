import CreditApiHelper, { API_REQUEST_URL, API_DATA_MANAGER_URL } from 'helpers/creditApiHelper';
import { ApiHelper } from 'helpers/apiHelper';

import { Id } from 'store/types';
import { deniedGetParams } from 'store/constants';
import { Frequency, FundRequestRelation, GetListResponse, RequestType } from 'store/Requests/types';
import { Request } from 'store/Requests/models/request';
import { PortfolioFilterModel } from 'store/Portfolio/models/filter';

export const getRequests = async (filter: any): Promise<GetListResponse> => {
    return CreditApiHelper.doRequest(`${API_REQUEST_URL}/funds/`, 'get', {
        params: filter
    });
};

export const getActiveRequests = async (filter: any): Promise<GetListResponse> => {
    return CreditApiHelper.doRequest(`${API_DATA_MANAGER_URL}/fund-requests/`, 'get', {
        params: filter
    });
};

export const getRequestsors = async (id: Id): Promise<GetListResponse> => {
    return CreditApiHelper.doRequest(`${API_DATA_MANAGER_URL}/fund-requests/${id}/`, 'get', {});
};

export const getPendingRequests = async (fundId?: Id): Promise<GetListResponse> => {
    let params = {};
    if (fundId) {
        params = {
            ...params,
            fund: fundId,
        };
    }
    return CreditApiHelper.doRequest(`${API_DATA_MANAGER_URL}/requests/pending-count/`, 'get', {params});
};

export const saveRequest = async (relation: FundRequestRelation, request: Request, withContacts = true) => {
    const requestId = relation.requestId;
    const method = requestId ? 'put' : 'post';
    const url = requestId ? `${API_REQUEST_URL}/${requestId}/` : `${API_REQUEST_URL}/`;

    return CreditApiHelper.doRequest(url, method, {
        ...prepareRequest(request, withContacts),
        fund: relation.fundId
    });
};

export const sendRequests = async(checkedIds: Id[], checkedAll: boolean, filter: any): Promise<any> => {
    let url = `${API_REQUEST_URL}/funds/send/`;
    const params = ApiHelper.getClearParams(filter, deniedGetParams);
    const body = {
        funds: checkedIds,
        checkedAll
    };
    const response = await CreditApiHelper.doRequest(url, 'post', body, '', true, params);

    return Promise.resolve(response);
};

export const sendRequest = async(fundId: Id): Promise<any> => {
    let url = `${API_REQUEST_URL}/funds/send/`;
    const response = await CreditApiHelper.doRequest(url, 'post', {funds: [fundId], checkedAll: false});

    return Promise.resolve(response);
};

export const bulkSaveRequest = async (
    relations: FundRequestRelation[],
    request: Request,
    withContacts = true,
    checkedAll: boolean,
    filter: PortfolioFilterModel,
) => {
    let url = `${API_REQUEST_URL}/funds/bulk-update/`;
    const params = ApiHelper.getClearParams(filter, deniedGetParams);
    const body = {
        ...prepareRequest(request, withContacts),
        relations: relations.map(rel => ({fund: rel.fundId, request: rel.requestId})),
        checkedAll,
    };
    return CreditApiHelper.doRequest(url, 'put', body, '', true, null, params);
};

export const bulkUpload = async (files: any) => {
    try {
        const response = await CreditApiHelper.post(
            `${API_REQUEST_URL}/upload/`,
            files,
            'multipart/form-data'
        );

        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getUploadHistory = async (filter: any): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_REQUEST_URL}/upload/`,
        'get',
        {
            params: filter
        }
    );

    return Promise.resolve(response);
};

export const cancelUpload = async (payload: any) => {
    const response = await CreditApiHelper.doRequest(
        `${API_REQUEST_URL}/upload/${payload.fileId}/cancel/`,
        'post',
        {}
    );

    return Promise.resolve(response);
};

export const exportFile = async (checkedIds: Id[], checkedAll: boolean, filter?: any): Promise<any> => {
    const filteredParams = filter ? ApiHelper.getClearParams(filter, deniedGetParams) : {};
    return CreditApiHelper.doRequest(`${API_REQUEST_URL}/funds/export-file/`, 'get', {
            params: {
                ...filteredParams,
                checkedAll,
                fund_id: checkedIds,
            }
        }
    );
};

function prepareRequest(request: Request, withContacts: boolean) {
    let preparedRequest: any = {
        requested_fields: request.requested_fields
            ? request.requested_fields.map(field => field.field_name)
            : [],
        due_date_unit: request.due_date_unit,
        due_date_unit_type: request.due_date_unit_type,
        frequency: request.frequency || Frequency.Monthly,
        data_start_date: request.data_start_date || null,
        send_date: request.send_date || null,
        type: request.type || RequestType.Recurring
    };

    if (withContacts) {
        preparedRequest.fund_contacts = request.fund_contacts || [];
    }

    return preparedRequest;
}
