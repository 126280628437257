import { ChipValueMappingType } from 'store/constants';
import { SelectItem } from 'store/types';
import { FUND_STATUS_TITLES } from 'store/Fund/constants';
import { DATASET_VERSION_TITLES } from 'store/DataManager/constants';
import { BackendFilterObject, Target } from 'store/AlertsManager/Rulesets/types';
import { FiltersState } from './types';
import { ALERT_STATUS } from 'store/AlertsManager/Alerts/constants';
import { GROUP_TYPE_TITLES } from 'store/Profiles/Groups/constants';

export function instanceOfSelectItem(object: any): object is SelectItem {
    return object && typeof object === 'object' && 'value' in object && 'label' in object;
}

export function getMappedTitleByValue(value: string, type: ChipValueMappingType): string {
    let title = '';
    switch (type) {
        case ChipValueMappingType.FundStatus:
            if (FUND_STATUS_TITLES[value]) {
                title = FUND_STATUS_TITLES[value];
            }
            break;
        case ChipValueMappingType.AlertStatus:
            if (ALERT_STATUS[value]) {
                title = ALERT_STATUS[value];
            }
            break;
        case ChipValueMappingType.DatasetVersion:
            if (DATASET_VERSION_TITLES[value]) {
                title = DATASET_VERSION_TITLES[value];
            }
            break;
        case ChipValueMappingType.GroupType:
            if (GROUP_TYPE_TITLES[value]) {
                title = GROUP_TYPE_TITLES[value];
            }
            break;
        case ChipValueMappingType.Boolean:
            if (typeof value === 'boolean') {
                title = value ? 'Yes' : 'No';
            }
            break;
        default:
            title = value;
            break;
    }
    return title;
}

export function prepareFilterState(filtersState: FiltersState | Target): FiltersState {
    const newFilterValue = {};
    Object.keys(filtersState).forEach((filterItemName: string) => {
        let newFilterItemValue = filtersState[filterItemName];
        if (Array.isArray(newFilterItemValue)) {
            newFilterItemValue = newFilterItemValue.map((filterValue: SelectItem | string) => {
                return instanceOfSelectItem(filterValue) ? filterValue.value : filterValue;
            });
        } else if (instanceOfSelectItem(newFilterItemValue)) {
            newFilterItemValue = newFilterItemValue.value;
        }
        newFilterValue[filterItemName] = newFilterItemValue;
    });
    return newFilterValue;
}

export const convertFilterObjectToSelectItem = (filterObjects: BackendFilterObject[]): SelectItem[] => {
    if (Array.isArray(filterObjects) && filterObjects.length > 0) {
        return filterObjects.map((filterObject: BackendFilterObject) => {
            return {
                value: filterObject.id,
                label: filterObject.name,
            };
        });
    }
    return [];
};

export function convertFilterObjectToTargetSection(filterObject: FiltersState ): Target {
    const filterFields = [
        'search', 'firm_id', 'credit_officer', 'product', 'strategy', 'portfolio_id', 'internal_id',
        'checked_all', 'fund_id'
    ];

    const target: Target = {};
    Object.keys(filterObject).forEach(key => {
        if (filterFields.indexOf(key) >= 0) {
            switch (key) {
                case 'firm_id':
                    target.firm_ids = filterObject[key];
                    break;
                case 'internal_id':
                    target.internal_ids = filterObject[key];
                    break;
                case 'portfolio_id':
                    target.portfolio_ids = filterObject[key];
                    break;
                case 'fund_id':
                    target.fund_ids = filterObject[key];
                    break;
                default:
                    target[key] = filterObject[key];
                    break;
            }
        }
    });

    return target;
}

export function convertTargetSectionToFilterObject(targetSection: Target, isResponder: boolean = true): FiltersState {
    const targetFields = [
        'search',
        'firm_ids', 'credit_officer', 'product', 'strategy', 'portfolio_ids', 'internal_ids',
        'checked_all', 'fund_ids'
    ];

    const filterObject: FiltersState = {};
    Object.keys(targetSection).forEach(key => {
        if (targetFields.indexOf(key) >= 0) {
            switch (key) {
                case 'firm_ids':
                    filterObject.firm_id = targetSection[key];
                    break;
                case 'internal_ids':
                    filterObject.internal_id = targetSection[key];
                    break;
                case 'portfolio_ids':
                    filterObject.portfolio_id = targetSection[key];
                    break;
                case 'fund_ids':
                    filterObject.fund_id = targetSection[key];
                    break;
                default:
                    filterObject[key] = targetSection[key];
                    break;
            }
        }
    });

    return filterObject;
}

export function isScrollerChange(data: FiltersState) {
    const scrollerKeys = ['scroller', 'limit', 'offset'];
    return Object.keys(data)
        .filter(key => scrollerKeys.indexOf(key) < 0).length  === 0;
}
