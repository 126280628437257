import * as React from 'react';
import { connect } from 'react-redux';
import { Id } from '../../store/types';

import FailMessage from '../../components/Shared/ProgressBar/FailMessage';
import { BulkUploadProgress, UploadAction } from '../../store/BulkUpload/types';
import { hideGlobalProgress } from '../../store/BulkUpload/actionCreator';
import { BULK_ITEM_NAME_DEFAULT, BULK_UPLOAD_COMPLETED_NAME } from '../../store/BulkUpload/constants';
import { formatText } from '../../helpers/tableHelper';
import { FormatTypeChoices } from '../../store/constants';

interface Props {
    progress: BulkUploadProgress;
    hideGlobalProgress: (id: Id) => any;
}

class FailItem extends React.PureComponent<Props> {
    handleHide = () => {
        this.props.hideGlobalProgress(this.props.progress.id);
    }

    render () {
        const {progress} = this.props;
        const type = progress.type;
        const action = progress.action === UploadAction.IMPORT ? 'Upload' : 'Download';
        let itemName = BULK_UPLOAD_COMPLETED_NAME[type] ? BULK_UPLOAD_COMPLETED_NAME[type] : BULK_ITEM_NAME_DEFAULT;
        itemName = formatText(itemName, FormatTypeChoices.FirstCharUpper);
        return (
            <FailMessage hide={this.handleHide} itemName={itemName} action={action}/>
        );
    }
}

const mapDispatchToProps = { hideGlobalProgress };

export default connect(null, mapDispatchToProps)(FailItem);