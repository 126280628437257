import * as React from 'react';
import { Modal as RSModal, ModalBody, ModalFooter } from 'reactstrap';

import ModalHeader from './ModalHeader';
import * as classNames from 'classnames';

interface Props {
    isOpen: boolean;
    title: React.ReactNode;
    body: React.ReactNode;
    toggle: () => void;
    buttons: any[];
    size?: string;
    className?: any;
    wrapClassName?: any;
    modalClassName?: string;
}

const styles = require('./Modal.scss');

const Modal: React.FC<Props> = (
    {isOpen, title, body, toggle, buttons, size, className, wrapClassName, modalClassName}
) => {
    const classesNames = classNames({
        [className]: !!className,
        [styles.modal]: true
    });

    const headerClassNames = classNames({
        [styles.header]: true,
        'modal-header': true,
    });

    return (
        <RSModal
            contentClassName={classesNames}
            isOpen={isOpen}
            toggle={toggle}
            size={size}
            wrapClassName={wrapClassName}
            modalClassName={modalClassName}
            zIndex="auto"
        >
            <ModalHeader className={headerClassNames} toggle={toggle}>
                {title}
            </ModalHeader>

            <ModalBody className={styles.body}>
                {body}
            </ModalBody>
            <ModalFooter className={styles.footer}>
                {buttons.map((button, idx) => (
                    <div key={idx} className="modal-buttons">
                        {button}
                    </div>
                ))}
            </ModalFooter>
        </RSModal>
    );
};

Modal.defaultProps = {
    size: 'md',
    className: '',
    wrapClassName: '',
    modalClassName: '',
};

export default Modal;
