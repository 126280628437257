import * as React from 'react';

import GlobalHelper from 'helpers/globalHelper';
import NumericHelper from 'helpers/NumericInputHelper';
import { FormatTypeChoices } from 'store/constants';

interface Props {
    row: any;
    valueField: string;
    currencyField?: string;
    currencyValue?: string;
    defaultValue?: string;
    format?: FormatTypeChoices;
    withCurrency?: boolean;
}

const DynamicFormatCurrency: React.FC<Props> = ( {
    row, valueField, currencyField, currencyValue, defaultValue, format = FormatTypeChoices.NumeralDefault, withCurrency
}) => {
    const currentValue = GlobalHelper.getValueFromObject(row, valueField);
    let currency;
    if (!currencyField && currencyValue) {
        currency = currencyValue;
    } else {
        currency = GlobalHelper.getValueFromObject(row, currencyField || 'fund.currency');
    }

    return (
      <>
          {NumericHelper.getFormattedNumeral(currentValue, currency, withCurrency, format, defaultValue)}
      </>
    );
};

export default DynamicFormatCurrency;
