import * as React from 'react';
import { debounce } from 'lodash';

import {
    ADVANCED_FILTERS_MODAL_NAME,
} from 'store/AdvancedFilters/constants';
import { FiltersState } from 'store/Filters/types';
import { DEFAULT_ITEMS_PER_PAGE, SEARCH_DEBOUNCE_INTERVAL } from 'store/constants';

interface AdvancedFilterDecorator {
    currentFilter: FiltersState;
    isModalOpen: boolean;
    openFiltersModal: (name: string) => void;
    closeFiltersModal: () => void;
    updateFiltersModal: (filter: FiltersState, filterName: string) => void;
    advancedFilter: FiltersState;
    advancedFilterName: string;
    searchRequestAttribute?: (attributeType: string, filter: FiltersState) => void;
    clearAsyncFilters: (pageName: string, filterName?: string) => void;
}

export const DiligenceAttributeTypes = [
    'survey',
    'requester',
    'statusDisplay',
    'respondingAccount',
    'respondingVehicle',
    'deadline',
    'dateSent',
    'dateClosed',
];

export interface AdvancedFilterDecoratorToggle {
    (): void;
}

export interface AdvancedFilterDecoratorUpdate {
    (filter: FiltersState): void;
}

export interface AdvancedFilterDecoratorSearch {
    (attributeType: string, filter: FiltersState): void;
}

export default function AdvancedFilter(WrappedComponent: any, filterName: string) {
    return class extends React.PureComponent<AdvancedFilterDecorator> {

        getDataAfterDebounce = debounce(
            (handleRequest: (data: FiltersState) => void, requestParams: FiltersState) => {
                handleRequest(requestParams);
            },
            SEARCH_DEBOUNCE_INTERVAL
        );

        render() {
            return (
                <div>
                    <WrappedComponent
                        {...this.props}
                        {...this.state}
                        handleToggleModal={this.handleToggleModal}
                        handleUpdateModal={this.handleUpdateModal}
                        handleAttributeSearch={this.handleAttributeSearch}
                    />
                </div>
            );
        }

        handleToggleModal: AdvancedFilterDecoratorToggle = (): void => {
            let {isModalOpen} = this.props;
            if (isModalOpen) {
                this.props.clearAsyncFilters(filterName);
                this.props.updateFiltersModal(this.props.currentFilter, filterName);
                this.props.closeFiltersModal();
            } else {
                if (this.props.advancedFilterName !== filterName) {
                    this.props.updateFiltersModal(this.props.currentFilter, filterName);
                }
                this.props.openFiltersModal(ADVANCED_FILTERS_MODAL_NAME);
            }
        }

        handleUpdateModal: AdvancedFilterDecoratorUpdate = (filter: FiltersState): void => {
            this.props.updateFiltersModal(filter, filterName);
        }

        handleAttributeSearch: AdvancedFilterDecoratorSearch = (attributeType: string, filter: FiltersState): void => {
            if (DiligenceAttributeTypes.indexOf(attributeType) < 0) { // credit
                if (filter.setFilters) {
                    const newFilter: FiltersState = {
                        ...filter.currentFilter,
                        search: filter.attributeSearch,
                        limit: DEFAULT_ITEMS_PER_PAGE,
                        offset: 0
                    };

                    filter.setFilters(filter.filterName, newFilter, filterName);
                }

                const requestParams: FiltersState = {
                    search: filter.attributeSearch,
                    limit: DEFAULT_ITEMS_PER_PAGE,
                    offset: 0
                };

                if (!filter.attributeSearch) {
                    requestParams.ordering = filter.currentFilter.hasOwnProperty('ordering')
                        ? filter.currentFilter.ordering
                        : '';
                }

                if (filter.handleRequest) {
                    this.getDataAfterDebounce(filter.handleRequest, requestParams);
                }
            } else { // diligence
                if (this.props.searchRequestAttribute) {
                    this.props.searchRequestAttribute(attributeType, filter);
                }
            }
        }

    };
}
