import { Action } from 'redux';

import { UserAccount } from 'store/Auth/models/userAccount';
import AccountUsersFilter from 'store/Account/models/filter';
import { InvitedUser } from 'store/Shared/models/invitedUser';
import { Id } from 'store/types';

export const GET_USERS = 'store/account/getUsers';
export const SET_USERS = 'store/account/setUsers';
export const DELETE_USER = 'store/account/delete';
export const DELETE_USERS = 'store/account/deleteUsers';
export const APPLY_FILTER = 'store/account/applyFilter';

export const START_EDITING_USER = 'store/account/startEditingUser';
export const CHANGE_EDITING_USER = 'store/account/changeEditingUser';
export const UPDATE_USER = 'store/account/updateUser';
export const UPDATE_USER_ROLE = 'store/account/updateUserRole';

export const START_INVITING_USER = 'store/account/startInvitingUser';
export const CHANGE_INVITING_USER = 'store/account/changeInvitingUser';
export const INVITE_USER = 'store/account/inviteUser';

export interface GetUsersAction extends Action {
    filter: AccountUsersFilter;
}

export interface SetUsersAction extends Action {
    count: number;
    users: UserAccount[];
}

export interface ApplyFilterAction extends Action {
    filter: AccountUsersFilter;
}

export interface DeleteUserAction extends Action {
    id: Id;
    filter: AccountUsersFilter;
    closeModal: boolean;
}

export interface DeleteUsersAction extends Action {
    ids: Id[];
    checkedAll: boolean;
}

export interface StartEditingUserAction extends Action {
    id: Id;
}

export interface ChangeEditingUserAction extends Action {
    userAccount: UserAccount;
}

export interface UpdateUserAction extends Action {
    userAccount: UserAccount;
    filter: AccountUsersFilter;
    closeModal: boolean;
}

export interface UpdateUserRoleAction extends Action {
    id: Id;
    role: string;
}

export interface StartInvitingUserAction extends Action {
}

export interface ChangeInvitingUserAction extends Action {
    invitedUser: InvitedUser;
}

export interface InviteUserAction extends Action {
    invitedUser: InvitedUser;
    filter: AccountUsersFilter;
    closeModal: boolean;
}

export type AccountAction = GetUsersAction | SetUsersAction |
    DeleteUserAction | ApplyFilterAction |
    StartEditingUserAction | ChangeEditingUserAction |
    UpdateUserAction | StartInvitingUserAction |
    ChangeInvitingUserAction | InviteUserAction;