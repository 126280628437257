import * as React from 'react';
import { connect } from 'react-redux';

import { AppState, SelectItem } from 'store/types';
import DropdownInputFilterResults from 'components/Diligence/Shared/Filters/DropdownInputFilterResults';
import { getRequestAttributesChoices } from 'store/Diligence/DiligenceRequest/selectors';
import { FiltersState } from 'store/Filters/types';
import { FilterItem } from 'store/types';
import { RequestFilterItem } from 'store/Diligence/types';
import { DiligenceFilterNames } from 'store/Diligence/Filters/types';

interface OuterProps {
    handleChange: (data: FiltersState) => void;
    filter: FilterItem;
    value: RequestFilterItem | SelectItem[];
    filterPageName?: string;
}

const mapStateToProps = (state: AppState, ownProps: OuterProps) => {
    let filterEntities;
    if (ownProps.filterPageName && ownProps.filterPageName === DiligenceFilterNames.diligenceRequest) {
        filterEntities = getRequestAttributesChoices(state, {type: ownProps.filter.name});
    } else {
        const choicesValues = (ownProps.filter.choices || []).map(choice => choice.value);
        filterEntities = [
            ...(ownProps.value as SelectItem[])
                .filter(selectedChoice => choicesValues.indexOf(selectedChoice.value) === -1),
            ...ownProps.filter.choices,
        ];
    }

    return {
        filterEntities
    };
};
export default connect(mapStateToProps, null)(DropdownInputFilterResults);
