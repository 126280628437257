import { createSelector } from 'reselect';
import { getModalsState } from 'store/Modals/selectors';
import { ModalsState } from 'store/Modals/types';

export const getAddItemsModalsState = createSelector(
    getModalsState,
    (modals: ModalsState) => modals.addItems
);

export const getFundListState = createSelector(
    getAddItemsModalsState,
    state => state.funds
);

export const getPeerGroupListState = createSelector(
    getAddItemsModalsState,
    state => state.peerGroups
);