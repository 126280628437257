import * as React from 'react';
import * as classNames from 'classnames';

interface InputType {
    className?: any;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    type?: string;
    material?: any;
    handleKeyPress?: (event: any) => void;
    handleBlur?: () => void;
    handleChange: (value: string) => void;
    focused?: boolean;
    label?: string;
    id?: string;
    autoFocus?: boolean;
}

const styles = require('./Input.scss');

class Input extends React.PureComponent<InputType> {
    static defaultProps = {
        type: 'text',
        material: false,
        disabled: false,
    };

    render() {
        const {
            value, disabled, type, placeholder,
            material, handleChange, handleKeyPress, handleBlur, className, id, label,
            autoFocus,
        } = this.props;

        const ClassNames = classNames({
            [className]: !!className,
            'ui-input': true,
            [styles.ui_input]: !!styles.ui_input,
            [styles.ui_input_material]: material,
        });

        return (
            <>
                <input
                    className={ClassNames}
                    type={type}
                    min={type === 'number' ? 0 : undefined}
                    value={value !== undefined && value !== null && String(value) || ''}
                    disabled={disabled}
                    placeholder={placeholder}
                    onClick={handleBlur}
                    onKeyPress={handleKeyPress}
                    onChange={(event: any) => handleChange(event.target.value)}
                    id={id}
                    autoFocus={autoFocus}
                />
                {label && id && <label htmlFor={id ? id : ''} className={styles.label}>{label}</label>}
            </>
        );
    }
}

export default Input;