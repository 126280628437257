import { SelectItem } from 'store/types';
import { BenchmarkType, GroupStatus, GroupType, GroupWeighting } from './types';

export const CONFIRM_DELETE_GROUP_MODAL_TITLE = 'Confirm Group Deletion';
export const CONFIRM_DELETE_GROUP_MODAL = 'CONFIRM_DELETE_GROUP_MODAL';
export const CONFIRM_DELETE_BENCHMARK_ITEM_MODAL_TITLE = 'Confirm Benchmark Item Deletion';
export const CONFIRM_DELETE_BENCHMARK_ITEM_MODAL = 'CONFIRM_DELETE_BENCHMARK_ITEM_MODAL';
export const ADD_ITEMS_TO_BENCHMARK_GROUP_MODAL = 'ADD_ITEMS_TO_BENCHMARK_GROUP_MODAL';
export const ADD_ITEMS_TO_BENCHMARK_GROUP_MODAL_TITLE = 'Add Another...';

export const DELETE_GROUPS_SUCCESS = 'Group was successfully removed';
export const DELETE_GROUP_SUCCESS = 'Groups were successfully removed';
export const DELETE_GROUP_FAIL = 'Group removal failed';

export const SAVE_GROUP_SUCCESS_MESSAGE = 'Group was successfully created';
export const SAVE_GROUP_FAIL_MESSAGE = 'Group saving failed';

export const TARGET_SECTION_COLUMNS_MODAL = 'TARGET_SECTION_COLUMNS_MODAL';

export const PUBLISH_GROUP_SUCCESS_MESSAGE = 'The group has been published';
export const PUBLISH_GROUP_FAIL_MESSAGE = 'Group publishing failed';

export const defaultGroupListPermissions = {
    can_create_group: true,
    can_bulk_delete_groups: true,
};

export const defaultGroupPermissions = {
    can_edit_group: true,
    can_publish_group: true,
    can_delete_group: true,
};

export const emptyGroup = {
    weighting: null,
    benchmark_items: [],
};

export const groupEditInitialState = {
    group: {
        weighting: null,
        benchmark_items: [],
    },
    targetSection: {
        fundList: {
            list: [],
            count: 0,
            firmCount: 0,
        },
        temporaryHeaders: [],
    },
};

export const initialState = {
    list: [],
    count: 0,
    edit: groupEditInitialState,
    permissions: defaultGroupListPermissions,
};

export const GROUP_TYPE_TITLES = {
    [GroupType.BenchmarkGroup]: 'Benchmark Group',
    [GroupType.PeerGroup]: 'Peer Group',
};

export const GROUP_WEIGHTING_TITLES = {
    [GroupWeighting.EqualWeighted]: 'Equal Weighted',
    [GroupWeighting.AumWeighted]: 'Aum Weighted',
};

export const BENCHMARK_TYPE: SelectItem[] = [
    {label: 'Fund', value: BenchmarkType.Fund},
    {label: 'Peer Group', value: BenchmarkType.FundGroup},
];

export const GROUP_TYPE: SelectItem[] = [
    {label: GROUP_TYPE_TITLES[GroupType.BenchmarkGroup], value: GroupType.BenchmarkGroup},
    {label: GROUP_TYPE_TITLES[GroupType.PeerGroup], value: GroupType.PeerGroup},
];

export const GROUP_WEIGHTING: SelectItem[] = [
    {label: GROUP_WEIGHTING_TITLES[GroupWeighting.EqualWeighted], value: GroupWeighting.EqualWeighted},
    {label: GROUP_WEIGHTING_TITLES[GroupWeighting.AumWeighted], value: GroupWeighting.AumWeighted},
];

export const GROUP_STATUS = {
    [GroupStatus.Active]: 'Active',
    [GroupStatus.ActiveWithChanges]: 'Active',
    [GroupStatus.Inactive]: 'Inactive',
};
