import * as React from 'react';
import { connect } from 'react-redux';

import { Id } from '../../store/types';
import SuccessPortfolioMessage from '../../components/Shared/ProgressBar/SuccessPortfolioMessage';
import { BulkUploadProgress, UploadType, UploadAction } from '../../store/BulkUpload/types';
import { hideGlobalProgress } from '../../store/BulkUpload/actionCreator';
import {
    BULK_ITEM_NAME_DEFAULT, BULK_ITEM_NAME_PLURAL, BULK_ITEM_NAME_PLURAL_DEFAULT,
    BULK_UPLOAD_COMPLETED_NAME
} from '../../store/BulkUpload/constants';
import { FormatTypeChoices } from '../../store/constants';
import { formatText } from '../../helpers/tableHelper';
import ProcessedWithErrorsMessage from '../../components/Shared/ProgressBar/ProcessedWithErrorsMessage';

interface Props {
    progress: BulkUploadProgress;
    hideGlobalProgress: (id: Id) => any;
}

class ProcessedWithErrorsItem extends React.PureComponent<Props> {
    handleHide = () => {
        this.props.hideGlobalProgress(this.props.progress.id);
    }

    render () {
        const {progress} = this.props;
        const type = progress.type;
        let uploadTypeName = BULK_UPLOAD_COMPLETED_NAME[type]
            ? BULK_UPLOAD_COMPLETED_NAME[type]
            : BULK_ITEM_NAME_DEFAULT;
        uploadTypeName = formatText(uploadTypeName, FormatTypeChoices.FirstCharUpper);
        const itemNamePlural = BULK_ITEM_NAME_PLURAL[type]
            ? BULK_ITEM_NAME_PLURAL[type]
            : BULK_ITEM_NAME_PLURAL_DEFAULT;
        let item;
        if (progress.action === UploadAction.IMPORT) {
            const action = 'Upload';
            if (type === UploadType.Portfolio) {
                item = (
                    <SuccessPortfolioMessage
                        progress={progress}
                        hide={this.handleHide}
                        uploadTypeName={uploadTypeName}
                    />
                );
            } else {
                item = (
                    <ProcessedWithErrorsMessage
                        action={action}
                        hide={this.handleHide}
                        uploadTypeName={uploadTypeName}
                        itemNamePlural={itemNamePlural}
                        fileId={progress.id}
                    />
                );
            }
        }

        return item;
    }
}

const mapDispatchToProps =  { hideGlobalProgress };

export default connect(null, mapDispatchToProps)(ProcessedWithErrorsItem);