import { DEFAULT_FILTER, defaultSortPagination } from '../constants';
import { SelectItem } from '../types';
import { AclRole, DatasetStatus, DatasetVersion, FundReportingHistoryStatus, HistoryColor } from './types';

export const CONFIRM_PUBLISH_MODAL = 'CONFIRM_PUBLISH_MODAL';
export const DECLINE_REQUESTOR_MODAL = 'DECLINE_REQUESTOR_MODAL';
export const SET_PENDING_PUBLISH_MODAL = 'SET_PENDING_PUBLISH_MODAL';
export const EDIT_PENDING_PUBLISH_MODAL = 'EDIT_PENDING_PUBLISH_MODAL';
export const DATASET_UPLOAD_MODAL = 'DATASET_UPLOAD_MODAL';
export const PUBLISHED_DATASETS_COLUMNS_MODAL = 'PUBLISHED_DATASETS_COLUMNS_MODAL';

export const DATASET_UPLOAD_NAME = 'dataset';

export const BULK_UPLOAD_TYPE_DATASET = '3';

export const messages = {
    CONFIRM_PUBLISH_DATASET_SUCCESS_MESSAGE: 'Dataset was successfully published',
    CONFIRM_PUBLISH_DATASET_FAIL_MESSAGE: 'Dataset publishing failed',
    SET_PENDING_PUBLISH_DATASET_SUCCESS_MESSAGE: 'Pending Publish Dataset was successfully set',
    SET_PENDING_PUBLISH_DATASET_FAIL_MESSAGE: 'Pending Publish Dataset set failed',
    EDIT_PENDING_PUBLISH_DATASET_SUCCESS_MESSAGE: 'Pending Publish Dataset was successfully updated',
    EDIT_PENDING_PUBLISH_DATASET_FAIL_MESSAGE: 'Pending Publish Dataset update failed',
    CANCEL_DATASET_UPLOAD_SUCCESS_MESSAGE: 'Dataset List Upload was successfully canceled',
    CANCEL_DATASET_UPLOAD_FAIL_MESSAGE: 'Dataset List Upload Cancel failed',

    CONFIRM_DELETE_ACL_USER_TITLE: 'Deleting user from ACL',
    CONFIRM_DELETE_ACL_USER_QUESTION: 'Are you sure you want delete user from ACL?',
    INVITE_ACL_USER_TITLE: 'Invite New User',
    INVITE_ACL_USER_SUCCESS_MESSAGE: 'The user has been invited',
    INVITE_ACL_USER_FAIL_MESSAGE: 'An unexpected error occurred inviting the user',
    DELETE_ACL_USER_SUCCESS_MESSAGE: 'The users were successfully removed',
    DELETE_ACL_USER_FAIL_MESSAGE: 'An unexpected error occurred removing the user',
    SET_ROLE_ACL_USER_SUCCESS_MESSAGE: 'The user\'s role was successfully changed',
    SET_ROLE_ACL_USER_FAIL_MESSAGE: 'An unexpected error occurred while change the user\'s role',

    BECOME_ACL_OWNER_SUCCESS_MESSAGE: 'You have successfully claimed ownership of the fund',
    BECOME_ACL_OWNER_FAIL_MESSAGE: 'An unexpected error occurred while attempting to claim ownership of the fund',

    REQUEST_ACL_ACCESS_SUCCESS_MESSAGE: 'The owner(s) have been notified of your access request',
    REQUEST_ACL_ACCESS_FAIL_MESSAGE: 'An unexpected error occurred while requesting access',
};

export const DefaultEntityFilterState = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        frequency: null
    },
    headerType: 'datasetList'
};

export const defaultDataManagerListPermissions = {
    can_bulk_upload_internal: true,
};

export const initialState = {
    datasetListCount: 0,
    datasetList: [],
    datasetDetailCount: 0,
    datasetDetail: [],
    publishedDatasetsCount: 0,
    publishedDatasets: [],
    permissions: {...defaultDataManagerListPermissions},
    datasetFund: {
        requested_fields: [],
    },
    currentDataset: null,
    temporaryFilter: {},
    temporaryHeaders: [],
    publishedDatasetsTemporaryHeaders: [],
    aclUsersCount: 0,
    aclUsers: [],
    parentId: 0,
    editingRowNumber: -1,
    confirmPublishModal: {
        requestors: [],
        requestorCheckboxes: [],
        allRequestorsToggle: true,
    },
    setPendingPublishModal: {
        requestors: [],
        daysBeforeDueDate: 0,
    },
    editPendingPublishModal: {
        daysBeforeDueDate: 0,
    },
    requestors: [],
    pendingRequests: {},
    internalData: [],
    internalDataCount: 0,
};

export const initialInvitedUser = {
    email: '',
    role: AclRole.NoAccess,
    last_name: '',
    first_name: ''
};

export const initialRequestor = {
    id: 0,
    requesting_account: {
        name: '',
    },
    due_date: 0,
    daysBeforeDueDate: 0,
};

export const DATASET_STATUS: SelectItem[] = [
    {label: 'In Progress', value: DatasetStatus.InProgress},
    {label: 'Not Yet Started', value: DatasetStatus.NotYetStarted},
    {label: 'Ready To Publish', value: DatasetStatus.ReadyToPublish},
    {label: 'Published', value: DatasetStatus.Published},
    {label: 'Past Due Or Data Has Breaches', value: DatasetStatus.PastDueOrDataHasBreaches},
];

export const DATASET_VERSION_TITLES = {
    [DatasetVersion.Final]: 'Final',
    [DatasetVersion.Estimate]: 'Estimate',
};

export const DATA_VERSION: SelectItem[] = [
    {label: DATASET_VERSION_TITLES[DatasetVersion.Final], value: DatasetVersion.Final},
    {label: DATASET_VERSION_TITLES[DatasetVersion.Estimate], value: DatasetVersion.Estimate},
];

export const DATASET_STATUS_TITLES = {
    [DatasetStatus.InProgress]: 'In Progress',
    [DatasetStatus.NotYetStarted]: 'Not Yet Started',
    [DatasetStatus.ReadyToPublish]: 'Ready To Publish',
    [DatasetStatus.Scheduled]: 'Scheduled',
    [DatasetStatus.ScheduledPartially]: 'Scheduled Partially',
    [DatasetStatus.Published]: 'Published',
    [DatasetStatus.PublishedPartially]: 'Published Partially',
    [DatasetStatus.PastDueOrDataHasBreaches]: 'Past Due',
    [FundReportingHistoryStatus.FundOnTime]: 'On Time',
    [FundReportingHistoryStatus.FundNoRequest]: 'No Request',
    [FundReportingHistoryStatus.FundLate]: 'Late',
    [FundReportingHistoryStatus.FundOverdue]: 'Overdue',
    [FundReportingHistoryStatus.FundPartial]: 'Partial Data',
    [FundReportingHistoryStatus.FundAwaitingData]: 'Awaiting Data',
};

export const HISTORY_COLORS = {
    [HistoryColor.Grey]: 'grey',
    [HistoryColor.DarkGrey]: 'dark-grey',
    [HistoryColor.Green]: 'green',
    [HistoryColor.Red]: 'red',
    [FundReportingHistoryStatus.FundOnTime]: 'jungle-green',
    [FundReportingHistoryStatus.FundNoRequest]: 'boulder',
    [FundReportingHistoryStatus.FundLate]: 'yellow',
    [FundReportingHistoryStatus.FundOverdue]: 'alizarin-crimson',
    [FundReportingHistoryStatus.FundPartial]: 'froly',
    [FundReportingHistoryStatus.FundAwaitingData]: 'timberwolf',
};

export const ACL_ROLES: SelectItem[] = [
    {label: 'No Access', value: AclRole.NoAccess},
    {label: 'Viewer', value: AclRole.Viewer},
    {label: 'Editor', value: AclRole.Editor},
    {label: 'Manager', value: AclRole.Manager},
    {label: 'Owner', value: AclRole.Owner},
];

export const ACL_ROLES_TITLES = {
    [AclRole.NoAccess]: 'No Access',
    [AclRole.Viewer]: 'Viewer',
    [AclRole.Editor]: 'Editor',
    [AclRole.Manager]: 'Manager',
    [AclRole.Owner]: 'Owner'
};

export const AclRoleInheritance = {
    [AclRole.NoAccess]: {parent: null},
    [AclRole.Viewer]: {parent: AclRole.NoAccess},
    [AclRole.Editor]: {parent: AclRole.Viewer},
    [AclRole.Manager]: {parent: AclRole.Editor},
    [AclRole.Owner]: {parent: AclRole.Manager},
};
