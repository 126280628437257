import { delay } from 'redux-saga';
import { all, put, select, takeLatest, takeEvery } from 'redux-saga/effects';

import * as rulesetApi from 'services/ruleset';
import * as portfolioApi from 'services/portfolio';
import { isResponder } from 'store/User/helpers';

import { prepareFilterState } from 'store/Filters/helpers';
import { FilterNames, FiltersState } from 'store/Filters/types';
import { bulkActionMessages } from 'store/constants';
import { startBulkUpload } from 'store/BulkUpload/actionCreator';
import { getPlainFundList } from 'store/Fund/sagas';
import {
    convertFilterObjectToSelectItem,
    convertFilterObjectToTargetSection,
    convertTargetSectionToFilterObject
} from 'store/Filters/helpers';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { callApplyFilters, clearChecked, setFilters, setIsLoading } from 'store/Filters/actionCreators';

import { closeModal } from 'store/Modals/General/actionCreators';
import { takeFirst } from 'store/Shared/sagas';

import {
    addRuleValidation,
    deleteRuleValidation,
    saveRuleset as saveRulesetActionCreator,
    setEditedRulesetPartial,
    touchAllRuleFields as touchAllRuleFieldsCreator,
    updateRuleValidation,
    updateTargetSectionFilter as updateTargetSectionFilterCreator,
} from 'store/AlertsManager/Rulesets/actionCreators';
import {
    getEdited as selectEditedRuleset
} from 'store/AlertsManager/Rulesets/selectors';

import { getFilters } from 'store/Filters/selectors';
import { getUser } from 'store/Auth/selectors';
import { isFieldValidation, validateRule, validateRuleset } from './ruleValidation';

import * as helper from './helpers';
import {
    AddRuleAction,
    BULK_UPLOAD,
    BulkUploadAction,
    CREATE_RULESET,
    DELETE_RULESET, DeleteRuleAction,
    DeleteRulesetAction, DISCRIMINATOR_ADD_RULE_ACTION, DISCRIMINATOR_DELETE_RULE_ACTION,
    DISCRIMINATOR_UPDATE_RULE_ACTION,
    DOWNLOAD_RULESET_LIST,
    DownloadRulesetListAction,
    GET_RULESET_LIST,
    GET_TARGET_FUND_LIST,
    GetRulesetListAction,
    GetTargetFundListAction,
    INIT_RULESET_CREATION,
    INIT_RULESET_EDITING,
    InitRulesetCreationAction,
    InitRulesetEditingAction,
    ModifyRulesetActions,
    ModifyRulesetTypes,
    PUBLISH_RULESET,
    PublishRulesetAction,
    SAVE_RULESET,
    SaveRulesetAction,
    TOUCH_ALL_RULE_FIELDS,
    TOUCH_RULE_FIELD,
    TouchAllRuleFieldsAction,
    TouchRuleFieldAction, UPDATE_TARGET_SECTION_FILTER,
    UpdateRuleAction, UpdateTargetSectionFilterAction,
} from './actions';

import {
    createRuleset as createRulesetCreator,
    setEditedRuleset,
    setRulesetList,
    setTargetFundList
} from './actionCreators';
import {
    BULK_UPLOAD_NAME,
    DELETE_RULESET_FAIL_MESSAGE,
    DELETE_RULESET_SUCCESS_MESSAGE,
    PUBLISH_RULESET_FAIL_MESSAGE,
    PUBLISH_RULESET_SUCCESS_MESSAGE,
    SAVE_RULESET_FAIL_MESSAGE
} from './constants';
import { RulesetStatus } from './types';

function* getRulesetList(action: GetRulesetListAction) {
    try {
        yield put(initAction(action.type));

        const response = yield rulesetApi.getList(action.filter);
        let withScroll = false;

        yield put(setIsLoading(true));
        if (action.filter.scroller) {
            withScroll = true;
        }

        yield put(setRulesetList(response.count, response.results, response.permissions, withScroll));
        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* initRulesetCreation(action: InitRulesetCreationAction) {
    try {
        yield put(initAction(action.type));

        const user = yield select(getUser);
        const filterName = helper.getFilterName(user);
        const filterDefault = helper.getFilterDefault(user);

        yield put(setEditedRuleset({status: RulesetStatus.Incomplete, rules: [{}]}));
        yield put(setFilters(filterName, filterDefault));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* initRulesetEditing(action: InitRulesetEditingAction) {
    try {
        yield put(initAction(action.type));

        const ruleset = yield rulesetApi.getRuleset(action.id, action.active);
        yield put(setEditedRuleset(ruleset));

        if (ruleset.hasOwnProperty('target_data')) {
            const updateTargetSectionAction = updateTargetSectionFilterCreator(
                ruleset.target_data, true,
                ruleset.entity_type, ruleset.id
            );
            yield* updateTargetSectionFilter(updateTargetSectionAction);
        }

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* saveRuleset(action: SaveRulesetAction) {
    try {
        yield put(initAction(action.type));

        if (action.ruleset.hasOwnProperty('target_data')) {
            action.ruleset.target_data = prepareFilterState(action.ruleset.target_data || {});
        }
        const ruleset = yield rulesetApi.save(action.ruleset);

        yield put(setEditedRulesetPartial({
            id: ruleset.id,
            status: ruleset.status,
            versions: ruleset.versions,
            changed_since_last_publish: ruleset.changed_since_last_publish,
        }));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, SAVE_RULESET_FAIL_MESSAGE));
    }
}

function* safeSaveRuleset(action: SaveRulesetAction) {
    if (!action.ruleset.id) {
        yield put(createRulesetCreator(action.ruleset));
    } else {
        yield* saveRuleset(action);
    }
}

function* updateTargetSectionFilter(action: UpdateTargetSectionFilterAction) {
    const user = yield select(getUser);
    const filterName = helper.getFilterName(user);
    const target = action.target || {};
    const filterDefault = helper.getFilterDefault(user);

    let newFilter: FiltersState = {
        ...filterDefault,
        ...convertTargetSectionToFilterObject(target, isResponder(user))
    };

    if (target.hasOwnProperty('firm_ids') && target.firm_ids && target.firm_ids.length > 0) {
        const firmFilterObjects = yield portfolioApi.getFilterLabels('firm', target.firm_ids);
        newFilter.firm_id = convertFilterObjectToSelectItem(firmFilterObjects.results);
    }
    if (target.hasOwnProperty('portfolio_ids') && target.portfolio_ids && target.portfolio_ids.length > 0) {
        const portfolioFilterObjects = yield portfolioApi.getFilterLabels('portfolio', target.portfolio_ids);
        newFilter.portfolio_id = convertFilterObjectToSelectItem(portfolioFilterObjects.results);
    }

    if (isResponder(user) && target.hasOwnProperty('fund_ids') && target.fund_ids && target.fund_ids.length > 0) {
        const fundFilterObjects = yield portfolioApi.getFilterLabels('fund', target.fund_ids);
        newFilter.fund_id = convertFilterObjectToSelectItem(fundFilterObjects.results);
    }

    yield put(setFilters(filterName, newFilter));
    if (action.applyFilter) {
        yield put(callApplyFilters(filterName, GET_TARGET_FUND_LIST));
    }
}

function* applyChangesInRuleset(action: ModifyRulesetActions) {
    if (action.hasOwnProperty('discriminator')) {
        if ((<AddRuleAction> action).discriminator === DISCRIMINATOR_ADD_RULE_ACTION) {
            yield put(addRuleValidation({valid: false}));
        } else if ((<UpdateRuleAction> action).discriminator === DISCRIMINATOR_UPDATE_RULE_ACTION) {
            const {index, rule, field} = (<UpdateRuleAction> action);
            const validation = validateRule(rule);
            if (validation.hasOwnProperty(field)) {
                validation[field].touched = true;
            }
            yield put(updateRuleValidation(index, validation));
        } else if ((<DeleteRuleAction> action).discriminator === DISCRIMINATOR_DELETE_RULE_ACTION) {
            yield put(deleteRuleValidation((<DeleteRuleAction> action).index));
        }
    }

    yield delay(1000);

    const user = yield select(getUser);
    const editedRuleset = yield select(selectEditedRuleset);

    const filterName = helper.getFilterName(user);

    const targetFilter = yield select(state => getFilters(state, filterName));
    editedRuleset.target_data = convertFilterObjectToTargetSection(targetFilter);

    yield put(saveRulesetActionCreator(editedRuleset));
}

function* deleteRuleset(action: DeleteRulesetAction) {
    try {
        yield put(initAction(action.type));

        if (action.ruleset.id) {
            yield rulesetApi.remove(action.ruleset.id);
            action.history.push('/rulesets');
        }

        yield put(doneActionSuccess(action.type, DELETE_RULESET_SUCCESS_MESSAGE));
        yield put(closeModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, DELETE_RULESET_FAIL_MESSAGE));
    }
}

function* publishRuleset(action: PublishRulesetAction) {
    if (action.ruleset.id) {
        const validation = validateRuleset(action.ruleset);
        if (!validation.valid) {
            if (action.ruleset.rules) {
                let index;
                for (index in action.ruleset.rules) {
                    if (action.ruleset.rules.hasOwnProperty(index)) {
                        const rule = action.ruleset.rules[index];
                        yield put(touchAllRuleFieldsCreator(rule, +index));
                    }
                }
            }
            yield put(closeModal());
        } else {
            try {
                yield put(initAction(action.type));

                const savedRuleset = yield rulesetApi.publish(action.ruleset.id);
                yield put(setEditedRulesetPartial({
                    id: savedRuleset.id,
                    status: savedRuleset.status,
                    versions: savedRuleset.versions,
                    changed_since_last_publish: savedRuleset.changed_since_last_publish,
                }));

                yield put(doneActionSuccess(action.type, PUBLISH_RULESET_SUCCESS_MESSAGE));
                yield put(closeModal());
            } catch (errors) {
                errors.forEach((value, index, arr) => {
                    // Replace 'rules' with 'Rule'
                    arr[index] = value.replace(/rules(?=\s#\d)/, 'Rule');
                });

                yield put(doneActionFail(action.type, errors, PUBLISH_RULESET_FAIL_MESSAGE));
            }
        }
    }
}

function* getTargetFundList(action: GetTargetFundListAction) {
    const setResponse = (response: any, withScroll: boolean) => {
        return setTargetFundList(response.count, response.count_firm, response.results, withScroll);
    };
    yield* getPlainFundList(action.type, action.filter, setResponse);
}

function* touchRuleField(action: TouchRuleFieldAction) {
    const {index, rule, field} = (<TouchRuleFieldAction> action);
    const validation = validateRule(rule);
    if (validation.hasOwnProperty(field)) {
        validation[field].touched = true;
    }
    yield put(updateRuleValidation(index, validation));
}

function* touchAllRuleFields(action: TouchAllRuleFieldsAction) {
    const {index, rule} = (<TouchAllRuleFieldsAction> action);
    const validation = validateRule(rule);

    Object.keys(validation).forEach(field => {
        if (isFieldValidation(field)) {
            validation[field].touched = true;
        }
    });
    validation.validOrUntouched = false;
    yield put(updateRuleValidation(index, validation));
}

function* downloadRulesetList(action: DownloadRulesetListAction) {
    try {
        yield put(initAction(action.type));
        yield rulesetApi.exportFile(action.checkedIds, action.checkedAll);

        yield put(doneActionSuccess(action.type));
        yield put(clearChecked(FilterNames.rulesetList));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.DOWNLOAD_FAILED));
    }
}

function* rulesetsBulkUpload(action: BulkUploadAction) {
    try {
        yield put(initAction(action.type));

        yield put(startBulkUpload(BULK_UPLOAD_NAME));
        yield rulesetApi.bulkUpload(action.files);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.UPLOAD_FILE_FAILED));
    }
}

function* watchGetRulesetList() {
    yield takeLatest(GET_RULESET_LIST, getRulesetList);
}

function* watchInitRulesetCreation() {
    yield takeLatest(INIT_RULESET_CREATION, initRulesetCreation);
}

function* watchInitRulesetEditing() {
    yield takeLatest(INIT_RULESET_EDITING, initRulesetEditing);
}

function* watchCreateRuleset() {
    yield takeFirst(CREATE_RULESET, saveRuleset);
}

function* watchDeleteRuleset() {
    yield takeLatest(DELETE_RULESET, deleteRuleset);
}

function* watchPublishRuleset() {
    yield takeLatest(PUBLISH_RULESET, publishRuleset);
}

function* watchSaveRuleset() {
    yield takeLatest(SAVE_RULESET, safeSaveRuleset);
}

function* watchRulesetChanges() {
    yield takeLatest(ModifyRulesetTypes, applyChangesInRuleset);
}

function* watchGetTargetFundList() {
    yield takeLatest(GET_TARGET_FUND_LIST, getTargetFundList);
}

function* watchTouchRuleField() {
    yield takeLatest(TOUCH_RULE_FIELD, touchRuleField);
}

function* watchTouchAllRuleFields() {
    yield takeLatest(TOUCH_ALL_RULE_FIELDS, touchAllRuleFields);
}

function* watchDownloadRulesetList() {
    yield takeEvery(DOWNLOAD_RULESET_LIST, downloadRulesetList);
}

function* watchBulkUpload() {
    yield takeLatest(BULK_UPLOAD, rulesetsBulkUpload);
}

function* watchUpdateTargetSectionFilter() {
    yield takeLatest(UPDATE_TARGET_SECTION_FILTER, updateTargetSectionFilter);
}

export default function* root() {
    yield all(
        [
            watchGetRulesetList(),
            watchInitRulesetEditing(),
            watchInitRulesetCreation(),
            watchCreateRuleset(),
            watchDeleteRuleset(),
            watchPublishRuleset(),
            watchSaveRuleset(),
            watchRulesetChanges(),
            watchGetTargetFundList(),
            watchTouchRuleField(),
            watchTouchAllRuleFields(),
            watchDownloadRulesetList(),
            watchBulkUpload(),
            watchUpdateTargetSectionFilter(),
        ]
    );
}
