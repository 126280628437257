import { put, takeLatest, all, select, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as profilesApi from 'services/profiles';
import * as portfolioApi from 'services/portfolio';
import * as gridConfigurationApi from 'services/gridConfiguration';

import GlobalHelper from 'helpers/globalHelper';

import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { callApplyFilters, clearChecked, setFilters, setIsLoading } from 'store/Filters/actionCreators';
import { FilterNames, FiltersState } from 'store/Filters/types';
import { closeModal } from 'store/Modals/General/actionCreators';
import { getExplicitlyCheckedIds, getFilters } from 'store/Filters/selectors';
import { getAllFundList, getPlainFundList } from 'store/Fund/sagas';
import {
    DELETE_GROUP_FAIL,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUPS_SUCCESS,
    PUBLISH_GROUP_FAIL_MESSAGE,
    PUBLISH_GROUP_SUCCESS_MESSAGE,
    SAVE_GROUP_FAIL_MESSAGE,
    emptyGroup,
} from './constants';
import {
    getEdited as selectEditedGroup, getEditedBenchmarksFundIds, getEditedBenchmarksGroupIds
} from 'store/Profiles/Groups/selectors';
import { prepareFilterState } from 'store/Filters/helpers';
import {
    GetGroupsAction,
    InitGroupCreationAction,
    InitGroupEditingAction,
    DeleteGroupsAction,
    GetTargetFundListAction,
    INIT_GROUP_EDITING,
    INIT_GROUP_CREATION,
    GET_GROUPS,
    DELETE_GROUPS,
    GET_TARGET_FUND_LIST,
    ModifyGroupTypes,
    ModifyGroupAction,
    SaveGroupAction,
    SAVE_GROUP,
    GET_FUND_LIST_FOR_ADD_ITEMS_MODAL,
    HANDLE_FUND_LIST_FROM_ADD_ITEMS_MODAL,
    HandleFundListFromAddItemsModalAction,
    DELETE_ITEM_LIST_FROM_BENCHMARKS,
    GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL,
    HandlePeerGroupListFromAddItemsModalAction,
    HANDLE_PEER_GROUP_LIST_FROM_ADD_ITEMS_MODAL,
    GET_GRID_CONFIGURATION,
    SAVE_TARGET_GROUP_COLUMNS,
    PublishGroupAction,
    PUBLISH_GROUP,
    SelectVersionAction,
    SELECT_VERSION,
} from './actions';
import {
    setEditedGroup,
    setGroups,
    getGroups,
    setTargetFundList,
    saveGroup as saveGroupCreator,
    addFundListToBenchmark,
    addPeerGroupListToBenchmark,
    setTargetTemporaryHeaders,
} from './actionCreators';
import { isGroupValid } from './validation';
import {
    convertFilterObjectToSelectItem, convertFilterObjectToTargetSection, convertTargetSectionToFilterObject
} from 'store/Filters/helpers';
import {
    GetFundListForAddItemsModalAction, GetPeerGroupListForAddItemsModalAction
} from 'store/Modals/AddItems/actions';
import { getFundListState, getPeerGroupListState } from 'store/Modals/AddItems/selectors';
import { getPlainPeerGroupList } from 'store/Modals/AddItems/sagas';
import { setFundListForAddItemsModal, setPeerGroupListForAddItemsModal } from 'store/Modals/AddItems/actionCreators';
import { GetFundListColumnsAction, SaveFundListColumnsAction } from 'store/Fund/actions';
import { setHeaders } from 'store/VisibleHeaders/actionCreators';
import { headerNames } from 'store/VisibleHeaders/types';

import { DefaultStateProfilesGroupTargetSection } from 'store/constants';
import { Group } from './types';

function* getGroupList(action: GetGroupsAction) {
    try {
        yield put(initAction(action.type));
        const response = yield profilesApi.getGroups(action.filter);
        let withScroll = false;

        yield put(setIsLoading(true));
        if (action.filter.scroller) {
            withScroll = true;
        }

        yield put(setGroups(response.results, response.count, response.permissions, withScroll));
        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
        yield put(setIsLoading(false));
    }
}

function* deleteGroupsByIds(action: DeleteGroupsAction) {
    try {
        yield put(initAction(action.type));

        const store = yield select();
        const filters = getFilters(store, FilterNames.profilesGroupList);
        yield profilesApi.deleteGroups(action.ids, action.checkedAll, filters);

        yield put(clearChecked(FilterNames.profilesGroupList));

        yield put(getGroups(filters));
        if (action.ids.length > 1) {
            yield put(doneActionSuccess(action.type, DELETE_GROUPS_SUCCESS));
        } else {
            yield put(doneActionSuccess(action.type, DELETE_GROUP_SUCCESS));
        }
        yield put(closeModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, DELETE_GROUP_FAIL));
    }
}

function* initGroupCreation(action: InitGroupCreationAction) {
    try {
        yield put(initAction(action.type));

        yield put(setEditedGroup(emptyGroup));
        yield put(setFilters(FilterNames.profilesGroupTargetSection, DefaultStateProfilesGroupTargetSection.filter));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* initGroupEditing(action: InitGroupEditingAction) {
    try {
        yield put(initAction(action.type));

        const group = yield profilesApi.getGroup(action.id);
        yield put(setEditedGroup(group));

        if (group.hasOwnProperty('target_data')) {
            yield* updateTargetSectionFilter(group, true);
        }

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getTargetFundList(action: GetTargetFundListAction) {
    const setResponse = (response: any, withScroll: boolean) => {
        return setTargetFundList(response.count, response.count_firm, response.results, withScroll);
    };

    yield* getAllFundList(action.type, action.filter, setResponse);
}

function* applyChangesInGroup(action: ModifyGroupAction) {
    yield delay(1000);

    const editedGroup = yield select(selectEditedGroup);

    const filterName = FilterNames.profilesGroupTargetSection;

    editedGroup.target_data = yield select(state => getFilters(state, filterName));

    yield put(saveGroupCreator(editedGroup));
}

function* saveGroup(action: SaveGroupAction) {
    if (!isGroupValid(action.group)) {
        return;
    }

    try {
        yield put(initAction(action.type));

        const groupToSave = {
            ...action.group
        };

        if (groupToSave.hasOwnProperty('target_data')) {
            const targetFilter = yield select(state => getFilters(state, FilterNames.profilesGroupTargetSection));
            groupToSave.target_data = convertFilterObjectToTargetSection(targetFilter);
            groupToSave.target_data = prepareFilterState(groupToSave.target_data || {});
        }

        const savedGroup = yield profilesApi.save(groupToSave);
        yield put(setEditedGroup(savedGroup));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, SAVE_GROUP_FAIL_MESSAGE));
    }
}

function* publishGroup(action: PublishGroupAction) {
    try {
        yield put(initAction(action.type));

        const publishedGroup = yield profilesApi.publish(action.groupId);
        yield put(setEditedGroup(publishedGroup));

        yield put(doneActionSuccess(action.type, PUBLISH_GROUP_SUCCESS_MESSAGE));
        yield put(closeModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, PUBLISH_GROUP_FAIL_MESSAGE));
    }
}

function* getGroupByVersion(action: SelectVersionAction) {
    try {
        yield put(initAction(action.type));

        let group;
        if (action.isHistoryVersion) {
            group = yield profilesApi.getGroupByVersion(action.id, action.version);
        } else {
            group = yield profilesApi.getGroup(action.id);
        }

        yield put(setEditedGroup(group));

        if (group.hasOwnProperty('target_data')) {
            yield* updateTargetSectionFilter(group);
        }

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* updateTargetSectionFilter(group: Group, applyFilter: boolean = true) {
    const target = group.target_data || {};

    let newFilter: FiltersState = {
        ...DefaultStateProfilesGroupTargetSection.filter,
        ...convertTargetSectionToFilterObject(target)
    };

    if (target.hasOwnProperty('firm_ids') && target.firm_ids && target.firm_ids.length > 0) {
        const firmFilterObjects = yield portfolioApi.getFilterLabels('firm', target.firm_ids);
        newFilter.firm_id = convertFilterObjectToSelectItem(firmFilterObjects.results);
    }
    if (target.hasOwnProperty('portfolio_ids') && target.portfolio_ids && target.portfolio_ids.length > 0) {
        const portfolioFilterObjects = yield portfolioApi.getFilterLabels('portfolio', target.portfolio_ids);
        newFilter.portfolio_id = convertFilterObjectToSelectItem(portfolioFilterObjects.results);
    }

    yield put(setFilters(FilterNames.profilesGroupTargetSection, newFilter));

    if (applyFilter) {
        yield put(callApplyFilters(FilterNames.profilesGroupTargetSection, GET_TARGET_FUND_LIST));
    }
}

function* getFundListForAddItemsModal(action: GetFundListForAddItemsModalAction) {
    const setResponse = (response: any, withScroll: boolean) => {
        return setFundListForAddItemsModal(response.count, response.count_firm, response.results, withScroll);
    };
    yield* getPlainFundList(action.type, action.filter, setResponse, getEditedBenchmarksFundIds);
}

function* getPeerGroupListForAddItemsModal(action: GetPeerGroupListForAddItemsModalAction) {
    const setResponse = (response: any, withScroll: boolean) => {
        return setPeerGroupListForAddItemsModal(response.count, response.results, withScroll);
    };
    yield* getPlainPeerGroupList(action.type, action.filter, setResponse, getEditedBenchmarksGroupIds);
}

function* handleFundListFromAddItemsModal(action: HandleFundListFromAddItemsModalAction) {
    try {
        yield put(initAction(action.type));

        let filters = yield select(getFilters, action.filterName);

        const funds = (yield select(getFundListState)).list;
        filters.checkedIds = yield select(getExplicitlyCheckedIds(funds), action.filterName);
        yield put(addFundListToBenchmark(funds.filter(fund => filters.checkedIds.indexOf(fund.id) >= 0)));

        yield put(closeModal());
        yield put(clearChecked(action.filterName));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* handlePeerGroupListFromAddItemsModal(action: HandlePeerGroupListFromAddItemsModalAction) {
    try {
        yield put(initAction(action.type));

        let filters = yield select(getFilters, action.filterName);

        const peerGroups = (yield select(getPeerGroupListState)).list;
        filters.checkedIds = yield select(getExplicitlyCheckedIds(peerGroups), action.filterName);
        yield put(addPeerGroupListToBenchmark(peerGroups.filter(group => filters.checkedIds.indexOf(group.id) >= 0)));

        yield put(closeModal());
        yield put(clearChecked(action.filterName));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* deleteItemListFromBenchmarks() {
    yield put(clearChecked(FilterNames.profilesGroupBenchmarks));
    yield put(closeModal());
}

function* getGridConfiguration(action: GetFundListColumnsAction) {
    try {
        yield put(initAction(action.type));
        const gridConfiguration = yield gridConfigurationApi.getSavedFundListColumns(
            action.instanceId, action.gridType
        );
        if (gridConfiguration && gridConfiguration.visible_columns) {
            yield put(setHeaders(headerNames.profilesGroupsTargetSection, gridConfiguration.visible_columns, true));
            const newStore = yield select();
            yield put(setTargetTemporaryHeaders(newStore.headers[headerNames.profilesGroupsTargetSection]));
        }
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* saveTargetGroupColumns(action: SaveFundListColumnsAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield gridConfigurationApi.saveFundListColumns(action.visibleHeaders, action.instanceId, action.gridType);
        yield put(doneActionSuccess(action.type));

        const temporaryHeaders = GlobalHelper.getValueFromObject(
            store,
            'profiles.groups.edit.targetSection.temporaryHeaders',
            null
        );
        if (temporaryHeaders) {
            yield put(setHeaders(headerNames.profilesGroupsTargetSection, temporaryHeaders));
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* watchGetGroupList() {
    yield takeLatest(GET_GROUPS, getGroupList);
}

function* watchDeleteGroups() {
    yield takeEvery(DELETE_GROUPS, deleteGroupsByIds);
}

function* watchGetTargetFundList() {
    yield takeLatest(GET_TARGET_FUND_LIST, getTargetFundList);
}

function* watchInitGroupCreation() {
    yield takeLatest(INIT_GROUP_CREATION, initGroupCreation);
}

function* watchInitGroupEditing() {
    yield takeLatest(INIT_GROUP_EDITING, initGroupEditing);
}

function* watchGroupChanges() {
    yield takeLatest(ModifyGroupTypes, applyChangesInGroup);
}

function* watchSaveGroup() {
    yield takeLatest(SAVE_GROUP, saveGroup);
}

function* watchPublishGroup() {
    yield takeLatest(PUBLISH_GROUP, publishGroup);
}

function* watchGetFundListForAddItemsModal() {
    yield takeLatest(GET_FUND_LIST_FOR_ADD_ITEMS_MODAL, getFundListForAddItemsModal);
}

function* watchGetPeerGroupListForAddItemsModal() {
    yield takeLatest(GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL, getPeerGroupListForAddItemsModal);
}

function* watchHandleFundListFromAddItemsMmodal() {
    yield takeLatest(HANDLE_FUND_LIST_FROM_ADD_ITEMS_MODAL, handleFundListFromAddItemsModal);
}

function* watchHandlePeerGroupListFromAddItemsMmodal() {
    yield takeLatest(HANDLE_PEER_GROUP_LIST_FROM_ADD_ITEMS_MODAL, handlePeerGroupListFromAddItemsModal);
}

function* watchDeleteItemListFromBenchmarks() {
    yield takeLatest(DELETE_ITEM_LIST_FROM_BENCHMARKS, deleteItemListFromBenchmarks);
}

function* watchGetGridConfiguration() {
    yield takeEvery(GET_GRID_CONFIGURATION, getGridConfiguration);
}

function* watchSaveTargetGroupColumns() {
    yield takeEvery(SAVE_TARGET_GROUP_COLUMNS, saveTargetGroupColumns);
}

function* watchGetGroupByVersion() {
    yield takeEvery(SELECT_VERSION, getGroupByVersion);
}

export default function* root() {
    yield all(
        [
            watchGetGroupList(),
            watchDeleteGroups(),
            watchGetTargetFundList(),
            watchInitGroupCreation(),
            watchInitGroupEditing(),
            watchGroupChanges(),
            watchSaveGroup(),
            watchPublishGroup(),
            watchGetFundListForAddItemsModal(),
            watchHandleFundListFromAddItemsMmodal(),
            watchDeleteItemListFromBenchmarks(),
            watchGetPeerGroupListForAddItemsModal(),
            watchHandlePeerGroupListFromAddItemsMmodal(),
            watchGetGridConfiguration(),
            watchSaveTargetGroupColumns(),
            watchGetGroupByVersion(),
        ]
    );
}
