import * as React from 'react';
import { FiltersState } from 'store/Filters/types';
import Checkbox from 'components/Diligence/Shared/Ui/Checkbox';
import { SelectItem } from 'store/types';
import { RequestFilterItem } from 'store/Diligence/types';

import { FilterItem } from 'store/types';
import { DiligenceFilterTypeDictionary } from 'store/Diligence/constants';
import { FilterType } from 'store/constants';

const styles = require('components/Shared/Filters/SelectWithCheckboxes.scss');
const checkboxStyles = require('components/Diligence/Shared/Ui/Checkbox.scss');

interface Props {
    filter: FilterItem;
    value: RequestFilterItem;
    handleChange: (data: FiltersState) => void;
}

const DropdownSelectWithCheckboxes: React.FC<Props> = (props) => {
    const { filter, value, handleChange } = props;
    return (
        <>
            <div className={styles.selectChoicesList}>
                {
                    filter && filter.choices &&
                    filter.choices.map((item: SelectItem, idx: number) => {
                        let checked = false;
                        let result: any = value ? value : [];
                        if (value !== undefined && Array.isArray(value)) {
                            const valueNames = value.map((v: any) => v.name);
                            const valueIndex = filter.type === FilterType.DiligenceSelectWithCheckboxesSync
                                ? valueNames.indexOf(item.label)
                                : value.indexOf(String(item.value));
                            checked = valueIndex >= 0;
                            result = checked
                                ? value.filter(val => val !== item.value)
                                : [...value, String(item.value)];
                        }

                        const uniqueKey = `${filter.name}-${item.value}-${idx}`;
                        const data = filter.type === FilterType.DiligenceSelectWithCheckboxesSync
                            ? {id: item.value, name: item.label, type: DiligenceFilterTypeDictionary[filter.name]}
                            : {[filter.name]: result};
                        return (
                            <div key={uniqueKey}>
                                <Checkbox
                                    idx={uniqueKey}
                                    className={`${checkboxStyles.checkbox} aligned-checkboxes`}
                                    checked={checked}
                                    handleChange={() => handleChange(data)}
                                >
                                    {item.label}
                                </Checkbox>
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
};

export default DropdownSelectWithCheckboxes;
