import asyncComponent from './Shared/AsyncComponent';

export const DashboardRequestor = asyncComponent(() =>
    import('./Dashboard/Requestor/DashboardPage').then(module => module.default)
);

export const DashboardResponder = asyncComponent(() =>
    import('./Dashboard/Responder/DashboardPage').then(module => module.default)
);

export const UserProfile = asyncComponent(() =>
    import('./User/Profile').then(module => module.default)
);

export const Account = asyncComponent(() =>
    import('./Account/Account').then(module => module.default)
);

export const AlertsManager = asyncComponent(() =>
    import('./AlertsManager/AlertsManager').then(module => module.default)
);

export const PortfolioList = asyncComponent(() =>
    import('./Portfolio/Portfolio').then(module => module.default)
);

export const Fund = asyncComponent(() =>
    import('./Fund/Fund').then(module => module.default)
);

export const Firm = asyncComponent(() =>
    import('./Firm/Firm').then(module => module.default)
);

export const Requests = asyncComponent(() =>
    import('./Requests/Requests').then(module => module.default)
);

export const DataManager = asyncComponent(() =>
    import('./DataManager/DataManager').then(module => module.default)
);

export const DiligenceSettings = asyncComponent(() =>
    import('./Diligence/User/Settings').then(module => module.default)
);

export const DiligenceRequests = asyncComponent(() =>
    import( './Diligence/DiligenceRequest/Request').then(module => module.default)
);

export const Profiles = asyncComponent(() =>
    import('./Profiles/Profiles').then(module => module.default)
);
