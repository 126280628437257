import {
    APPLY_FILTER,
    SET_REQUESTS,
    SET_REQUESTS_WITH_SCROLL,
    GET_REQUESTS,
    SEARCH_REQUEST_ATTRIBUTE,
    SET_REQUEST_ATTRIBUTES,
    GET_SAVED_DATA,
    SAVE_FILTER_DATA,
    SET_ALL_REQUESTS
} from './actions';
import { RequestModel } from './models/request';
import { FiltersState } from 'store/Filters/types';

export const applyFilter = (filter: FiltersState) => ({
    type: APPLY_FILTER,
    filter
});

export const setRequests = (count: number, requests: RequestModel[], withScroll = false) => ({
    type: withScroll
        ? SET_REQUESTS_WITH_SCROLL
        : SET_REQUESTS,
    count,
    requests
});

export const setAllRequests = (requests: RequestModel[]) => ({
    type: SET_ALL_REQUESTS,
    requests
});

export const getRequests = () => ({
    type: GET_REQUESTS,
});

export const searchRequestAttribute = (attributeType: string, filter: FiltersState) => ({
    type: SEARCH_REQUEST_ATTRIBUTE,
    attributeType,
    filter
});

export const setRequestAttributes = (
    attributeType: string,
    count: number,
    results: any[],
    withScroll: boolean = false
) => ({
    type: SET_REQUEST_ATTRIBUTES,
    results, count, attributeType, withScroll
});

export const getSavedFilters = (filterName: string) => ({
    type: GET_SAVED_DATA,
    filterName
});

export const saveFilterData = (filters: any[], sortOrder: any[]) => ({
    type: SAVE_FILTER_DATA,
    filters, sortOrder
});
