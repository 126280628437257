import ActiveUser from './models/activeUser';

export interface AuthState {
    isInitialized: boolean;
    user?: ActiveUser;
}

export enum UserType {
    Standard = 'standard',
    Adhoc = 'adhoc',
    Api = 'api'
}

export enum UserStatus {
    Pending = 'pending',
    Active = 'active',
    Suspended = 'suspended'
}

export enum UserAccountStatus {
    Pending = 'pending',
    Active = 'active',
    Suspended = 'suspended'
}

export enum UserAccountRole {
    AccountAdmin = 'account-admin',
    ContentAdmin = 'content-admin',
    User = 'user'
}

export enum UserGlobalRole {
    None = 'none',
    Concierge = 'concierge',
    Sysadmin = 'sysadmin'
}