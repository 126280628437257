import * as React from 'react';

import { formatText } from 'helpers/tableHelper';
import { FormatTypeChoices } from 'store/constants';
import RowActionsColumn from 'components/Shared/DataTable/RowActionsColumn';
import { TableRowAction } from 'store/types';

interface Props {
    row: any;
    actions?: TableRowAction[];
    rowIdx?: number;
}

const styles = require('./NotStartedRow.scss');

const ScheduledRow: React.FC<Props> = ({row, actions, rowIdx}) => {

    const keySalt = Math.random();
    return (
        <tbody className={styles.niceBody}>
        <tr className={styles.tr}>
            <td />
            <td>Will receive {row.count_ds} Datasets</td>
            <td />
            <td>{row.account && row.account.name}</td>
            <td>{formatText(row.due_date, FormatTypeChoices.DateFormat)}</td>
            <td>{formatText(row.publish_date, FormatTypeChoices.DateFormat)}</td>
            <td
                className="triangleTd"
            >
                Scheduled {formatText(row.publish_date, FormatTypeChoices.DateFormat)}
            </td>
            <td className={styles.actionsRow}>
                {
                    actions &&
                    actions.map((action: TableRowAction, actionId: number) => {
                        const hide = action.isVisible && !action.isVisible(row, rowIdx)
                            ? (!!action.hideClass
                                ? (typeof action.hideClass === 'function'
                                    ? action.hideClass(row)
                                    : action.hideClass)
                                : 'hidden-action')
                            : '';
                        return <RowActionsColumn
                            key={actionId + '-' + keySalt}
                            idx={actionId}
                            rowIdx={rowIdx}
                            action={action}
                            row={row}
                            className={'action-items ' + hide}
                            isEditing={false}
                        />;
                    })
                }
            </td>
        </tr>
        </tbody>
    );
};

export default ScheduledRow;
