import * as React from 'react';

import { closeIcon, FormatTypeChoices } from 'store/constants';
import { Id, TableHeader, TableRowAction } from 'store/types';

import {
    BulkUploadModalStatuses, BulkUploadProgress, UploadAction, UploadStatus, UploadType
} from 'store/BulkUpload/types';
import { formatText } from 'helpers/tableHelper';
import { BULK_ITEM_NAME, BULK_ITEM_NAME_PLURAL } from 'store/BulkUpload/constants';
import ActiveUser from 'store/Auth/models/activeUser';

import DropDownArea from '../DropDownArea/DropDownArea';
import DataUploadHistoryTable from '../DataTable/DataUploadHistoryTable';
import TableDifferentRow from '../Ui/Tables/TableDifferentRow';
import ProgressBar from '../ProgressBar/ModalProgressBar';

interface Props {
    progress: BulkUploadProgress[];
    uploadHistory: BulkUploadProgress[];
    rowActions: TableRowAction[];

    closeModal: () => any;
    cancelUpload: (lastFileId: Id) => void;
    editResult: (lastFileId: Id) => void;
    upload: (files: File[]) => void;
    entityNameSingle?: string;
    entityNamePlural?: string;
    entityNameFormatType?: FormatTypeChoices;
    children?: BulkUploadModalStatuses;
    user: ActiveUser;
}

const styles = require('./BulkUploadModalView.scss');

class BulkUploadModalView extends React.PureComponent<Props> {

    static defaultProps = {
        entityNameSingle: BULK_ITEM_NAME[UploadType.Portfolio],
        entityNamePlural: BULK_ITEM_NAME_PLURAL[UploadType.Portfolio],
        entityNameFormatType: FormatTypeChoices.CountFunds,
    };

    headers: TableHeader[] = [
        { name: 'created_at', title: 'Date', active: true, formatType: FormatTypeChoices.DateFormat },
        {
            name: 'count_total',
            title: formatText(
                this.props.entityNamePlural ? this.props.entityNamePlural : '',
                FormatTypeChoices.FirstCharUpper
            ),
            active: true,
            formatType: this.props.entityNameFormatType,
        },
        { name: 'status', title: 'Status', active: true, formatType: FormatTypeChoices.UploadStatus, colored: true },
    ];

    render() {
        const {
            status: lastFileStatus,
            id: lastImportId,
        } = this.props.uploadHistory[0] || {status: 0, id: ''};
        const children: BulkUploadModalStatuses | undefined = this.props.children;
        return (
            <div className={styles.uploadWrapper}>
                <img
                    src={closeIcon}
                    className={styles.mainCloser}
                    onClick={this.props.closeModal}
                />
                {
                    lastFileStatus === UploadStatus.Processing &&
                    (children && typeof children.processingMessage === 'undefined' || !children) &&
                    <div className={styles.uploadProcessingMessage}>
                        <div>
                            Upload Processing
                        </div>
                        <div>
                            You can close this window and keep on working while upload is processing.
                            We'll inform you as soon the upload is finished. Isn't that cool?
                        </div>
                    </div>

                }
                {
                    lastFileStatus === UploadStatus.Processing &&
                    children && children.processingMessage &&
                    <div className={styles.uploadProcessingMessage}>
                        {children.processingMessage}
                    </div>
                }
                {
                    lastFileStatus === UploadStatus.Success &&
                    (children && typeof children.successMessage === 'undefined' || !children) &&
                    <div className={styles.uploadSuccessMessage}>
                        <div>
                            Success! Upload Complete
                        </div>
                        <div>
                            You can now <span className="f-link" onClick={() => this.props.editResult(lastImportId)}>
                                view and edit
                            </span> your recently uploaded {this.props.entityNameSingle + ` `}
                            or upload new {this.props.entityNamePlural}
                        </div>
                    </div>
                }
                {
                    lastFileStatus === UploadStatus.Success &&
                    children && children.successMessage &&
                    <div className={styles.uploadSuccessMessage}>
                        {children.successMessage}
                    </div>
                }
                {
                    lastFileStatus === UploadStatus.Failed &&
                    (children && typeof children.failedMessage === 'undefined' || !children) &&
                    <div className={styles.uploadFailMessage}>
                        <div>Upload Failed</div>
                        <div>Please try again.</div>
                    </div>
                }
                {
                    lastFileStatus === UploadStatus.Failed &&
                    children && children.failedMessage &&
                    <div className={styles.uploadFailMessage}>
                        {children.failedMessage}
                    </div>
                }
                {
                    lastFileStatus === UploadStatus.ProcessedWithErrors &&
                    (children && typeof children.processedWithErrorsMessage === 'undefined' || !children) &&
                    <div className={styles.uploadSuccessMessage}>
                        <div>
                            Completed - contains errors! Upload Complete
                        </div>
                        <div>
                            You can now <span className="f-link" onClick={() => this.props.editResult(lastImportId)}>
                            view and edit
                        </span> your recently uploaded {this.props.entityNameSingle + ` `}
                            or upload new {this.props.entityNamePlural} below
                        </div>
                    </div>
                }
                {
                    lastFileStatus === UploadStatus.ProcessedWithErrors &&
                    children && children.processedWithErrorsMessage &&
                    <div className={styles.uploadSuccessMessage}>
                        {children.processedWithErrorsMessage}
                    </div>
                }
                {
                    lastFileStatus !== UploadStatus.Processing &&
                    <div className={styles.dragdropWrapper}>
                        <DropDownArea upload={this.props.upload} />
                    </div>
                }
                {
                    this.props.progress.filter(progress => {
                        return progress.status === UploadStatus.Processing && progress.action === UploadAction.IMPORT;
                    }).map((progress, idx) => (
                        <div key={idx}>
                            <div className={styles.progressWrapper}>
                                <ProgressBar progress={progress} />
                            </div>
                            {
                                progress && progress.created_by &&
                                progress.created_by === this.props.user.id &&
                                (
                                    <div>
                                        <div
                                            className={styles.cancelUpload}
                                            onClick={() => this.props.cancelUpload(progress.id)}
                                        >
                                            CANCEL UPLOAD
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    ))
                }

                <div>
                    <h2 className={styles.uploadHeader}>Upload History</h2>
                    <TableDifferentRow>
                        <DataUploadHistoryTable
                            rows={this.props.uploadHistory}
                            rowActions={this.props.rowActions}
                            headers={this.headers}
                            hasPagination={false}
                            hasSorting={false}
                            withCheckboxes={false}
                        />
                    </TableDifferentRow>
                </div>
            </div>
        );
    }
}

export default BulkUploadModalView;
