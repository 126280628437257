import * as React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import * as classNames from 'classnames';

export interface DropDownItem {
    header?: boolean;
    divider?: boolean;
    content?: string | JSX.Element;
    permission?: boolean;
    handleClick?: any;
}

export interface DropDownTypes {
    items: DropDownItem[];
    className?: any;
    inverse?: boolean;

}

const styles = require('./DropDown.scss');
const DropDownPanel: React.FC<DropDownTypes> = (
    {children, className, inverse, items}
) => {
    const dropdownClass = classNames({
        [className]: !!className,
        [styles.dropdown_default]: !!styles.dropdown_default,
        [styles.dropdown_default_inverse]: inverse,
    });

    return (
        <UncontrolledDropdown className={dropdownClass}>
            <DropdownToggle caret={true}>
                {children}
            </DropdownToggle>
            <DropdownMenu>
                {
                    items.map((item: DropDownItem, idx: number) => {
                        let content: string | JSX.Element | undefined;

                        if (item.permission !== undefined  && item.permission === false) {
                            return null;
                        }

                        if (item.header) {
                            content = <DropdownItem key={idx} header={true}>{item.content}</DropdownItem>;
                        } else if (item.divider) {
                            content = <DropdownItem key={idx} divider={true} />;
                        } else {
                            content = <DropdownItem key={idx} onClick={item.handleClick}>{item.content}</DropdownItem>;
                        }

                        return content;
                     })
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default DropDownPanel;
