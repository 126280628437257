import CreditApiHelper, { API_PORTFOLIO_URL, API_ERM_ENTITIES } from 'helpers/creditApiHelper';
import { ApiHelper } from 'helpers/apiHelper';

import { PortfolioFilterModel } from 'store/Portfolio/models/filter';
import { Id } from 'store/types';
import { FiltersState } from 'store/Filters/types';
import { defaultDeniedGetParams } from 'store/constants';

export const getFirms = async (filter: PortfolioFilterModel | FiltersState, userType: string): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/${userType}/firms/`,
        'get',
        {
            params: {
                ...filter
            }
        }
    );

    return Promise.resolve(response);
};

export const getFirmById = async (id: Id, userType: string): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/${userType}/firms/${id}/`,
        'get',
        {}
    );

    return Promise.resolve(response);
};

export const getFirmRemoteDetails = async (id: Id, userType: string): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/${userType}/firms/${id}/remote-details/`,
        'get',
        {}
    );

    return Promise.resolve(response);
};

export const getAllFirms = async ( filter: any): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_ERM_ENTITIES}/entities/`,
        'get',
        {
            params: {
                entity_type: 'firm',
                ...filter
            }
        }
    );

    return Promise.resolve(response);
};

export const getFunds = async (firmId: any, filter: any): Promise<any> => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    const response = await CreditApiHelper.doRequest(
        `${API_ERM_ENTITIES}/relationships/`,
        'get',
        {
            params: {
                entity_id: firmId,
                end_type: 'firm',
                start_type: 'fund',
                relationship_type: 'is_advised_by',
                ...params,
            }
        }
    );

    return Promise.resolve(response);
};
