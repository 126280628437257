import { AsyncFilterName, FilterNames } from 'store/Filters/types';
import { DEFAULT_ASYNC_FILTER } from 'store/constants';

export const defaultAsyncFilterState = {
    results: [],
    count: 0,
    filter: {
        ...DEFAULT_ASYNC_FILTER
    },
};

const initialStateForStandardFilters = {
    [AsyncFilterName.firm]: {
        ...defaultAsyncFilterState,
        filter: {
            ...DEFAULT_ASYNC_FILTER,
            ordering: 'name'
        }
    },
    [AsyncFilterName.creditOfficer]: {
        ...defaultAsyncFilterState,
        filter: {
            ...DEFAULT_ASYNC_FILTER,
            ordering: 'name'
        }
    },
    [AsyncFilterName.product]: {
        ...defaultAsyncFilterState,
        filter: {
            ...DEFAULT_ASYNC_FILTER,
            ordering: 'name'
        }
    },
    [AsyncFilterName.strategy]: {
        ...defaultAsyncFilterState,
        filter: {
            ...DEFAULT_ASYNC_FILTER,
            ordering: 'name'
        }
    },
    [AsyncFilterName.portfolio]: {
        ...defaultAsyncFilterState,
        filter: {
            ...DEFAULT_ASYNC_FILTER,
            ordering: 'name'
        }
    },
};

export const initialState = {
    [FilterNames.fundList]: {
        ...initialStateForStandardFilters,
        [AsyncFilterName.internalId]: {
            ...defaultAsyncFilterState,
        },
    },
    [FilterNames.rulesetRequestorTargetFundList]: {
        ...initialStateForStandardFilters,
        [AsyncFilterName.internalId]: {
            ...defaultAsyncFilterState,
        },
    },
    [FilterNames.rulesetResponderTargetFundList]: {
        [AsyncFilterName.fund]: {
            ...defaultAsyncFilterState,
        },
    },
    [FilterNames.requestorDashboard]: {
        ...initialStateForStandardFilters,
    },
    [FilterNames.requestorDashboardDispersion]: {
        ...initialStateForStandardFilters,
    },
    [FilterNames.internalDataTab]: {
        [AsyncFilterName.firm]: {
            ...defaultAsyncFilterState,
        },
        [AsyncFilterName.portfolio]: {
            ...defaultAsyncFilterState,
        },
    },
    [FilterNames.publishedDatasets]: {
        [AsyncFilterName.portfolio]: {
            ...defaultAsyncFilterState,
        },
    },
    [FilterNames.alertList]: {
        ...initialStateForStandardFilters,
        [AsyncFilterName.internalId]: {
            ...defaultAsyncFilterState,
        },
    },
    [FilterNames.alertView]: {
        [AsyncFilterName.rulesetName]: {
            ...defaultAsyncFilterState,
            filter: {
                ...DEFAULT_ASYNC_FILTER,
                ordering: 'name',
            },
        },
        [AsyncFilterName.counterparty]: {
            ...defaultAsyncFilterState,
            filter: {
                ...DEFAULT_ASYNC_FILTER,
                ordering: 'counterparty',
            },
        }
    },
    [FilterNames.requestorAddItemsModalFundList]: {
        ...initialStateForStandardFilters,
        [AsyncFilterName.internalId]: {
            ...defaultAsyncFilterState,
        },
    },
    [FilterNames.profilesGroupTargetSection]: {
        ...initialStateForStandardFilters,
        [AsyncFilterName.internalId]: {
            ...defaultAsyncFilterState,
        },
    }
};
