import { all, put, takeLatest } from 'redux-saga/effects';

import * as alertApi from 'services/alert';

import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { setIsLoading } from 'store/Filters/actionCreators';

import {
    GET_ALERT_LIST, GET_ALERT_STATUS_ITEMS,
    GetAlertListAction, GetAlertStatusItemsAction,
    GET_FUND_EVALUATION_RESULTS,
    GetFundEvaluationResultsAction,
    GetFundStatisticAction, GET_FUND_STATISTIC
} from './actions';
import {
    setAlertList, setAlertStatusItems,
    setFundEvaluationResults, setFundStatistic
} from './actionCreators';

function* getAlertList(action: GetAlertListAction) {
    try {
        yield put(initAction(action.type));

        const response = yield alertApi.getList(action.filter);
        let withScroll = false;

        yield put(setIsLoading(true));
        if (action.filter.scroller) {
            withScroll = true;
        }
        yield put(setAlertList(response.count, response.count_alerts, response.results, withScroll));
        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* getAlertStatusItems(action: GetAlertStatusItemsAction) {
    try {
        yield put(initAction(action.type));
        const response = yield alertApi.getAlertStatuses(action.period);
        yield put(setAlertStatusItems(response));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getFundStatisticView(action: GetFundStatisticAction) {
    try {
        yield put(initAction(action.type));
        const result = yield alertApi.getFundStatistic(action.id, action.period);

        yield put(setFundStatistic(result));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getFundEvaluationResults(action: GetFundEvaluationResultsAction) {
    try {
        yield put(initAction(action.type));
        yield put(setIsLoading(true));

        const results = yield alertApi.getFundEvaluationResults(action.id, action.filter);
        let withScroll = false;
        yield put(setIsLoading(true));
        if (action.filter.scroller) {
            withScroll = true;
        }
        yield put(setFundEvaluationResults(results, withScroll));

        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchGetAlertList() {
    yield takeLatest(GET_ALERT_LIST, getAlertList);
}

function* watchGetAlertStatusItems() {
    yield takeLatest(GET_ALERT_STATUS_ITEMS, getAlertStatusItems);
}

function* watchFundAlertsView() {
    yield takeLatest(GET_FUND_STATISTIC, getFundStatisticView);
}

function* watchGetFundEvaluationResults() {
    yield takeLatest(GET_FUND_EVALUATION_RESULTS, getFundEvaluationResults);
}

export default function* root() {
    yield all(
        [
            watchGetAlertList(),
            watchGetAlertStatusItems(),
            watchFundAlertsView(),
            watchGetFundEvaluationResults(),
        ]
    );
}