import * as React from 'react';
import Select from 'components/Shared/Ui/Select';
import { SelectItem } from 'store/types';

interface Props {
    name: string;
    placeholder?: string;
    value: any;
    options?: SelectItem[];
    disabled?: boolean;
    defaultValue?: any;
    label?: string;
    material?: boolean;
    handleChange: (data: any) => void;
    clearable?: boolean;
    innerClassName?: string;
    classNamePrefix?: string;
    customMenuPortalClass?: React.CSSProperties;
}

const styles = require('./SelectFilter.scss');
const SelectFilter: React.FC<Props> = ({
    name, value, placeholder, disabled, defaultValue,
    label, material, options, handleChange, clearable,
    innerClassName, classNamePrefix, customMenuPortalClass,
}) => (
        <div className={styles.select_filter}>
            {label && <h5 className={styles.label}>{label}</h5>}
            <Select
                placeholder={placeholder || ''}
                value={value}
                disabled={disabled}
                defaultValue={defaultValue}
                material={material}
                handleChange={(values: any) => handleChange({[name]: values ? values.value : ''})}
                options={options || []}
                clearable={clearable}
                className={innerClassName}
                classNamePrefix={classNamePrefix}
                customMenuPortalClass={customMenuPortalClass}
            />
        </div>
);

export default SelectFilter;
