import { Action } from 'redux';
import { FiltersState } from '../Filters/types';
import { PortfolioModel } from '../Portfolio/models/portfolio';
import { FundModel } from '../Fund/models/fund';
import { FirmModel } from '../Firm/models/firm';
import { Id } from 'store/types';
import { SearchFilterChoicesSource } from './types';

export const GET_CHOICES = 'store/asyncFiltersChoices/getChoices';
export const SET_CHOICES = 'store/asyncFiltersChoices/setChoices';
export const SET_CHOICES_WITH_SCROLL = 'store/asyncFiltersChoices/setChoicesWithScroll';
export const SET_ASYNC_FILTERS = 'store/asyncFiltersChoices/setFilters';
export const CLEAR_ASYNC_FILTERS = 'store/asyncFiltersChoices/clearFilters';

export const SEARCH_CHOICES = 'store/asyncFiltersChoices/searchChoices';
export const SET_GLOBAL_CHOICES = 'store/asyncFiltersChoices/setGlobalChoices';

export interface GetFilterChoicesAction extends Action {
    page: string;
    filterName: string;
    filter: FiltersState;
}

export interface GetChoicesByFundAction extends Action {
    page: string;
    filterName: string;
    filter: FiltersState;
    fundId: Id;
}

export interface SetChoicesAction extends Action {
    page: string;
    filterName: string;
    results: FirmModel[] | FundModel[] | PortfolioModel[];
    count: number;
}

export interface SetAsyncFiltersAction extends Action {
    filterName: string;
    filter: FiltersState;
    pageName: string;
}

export interface SearchFilterChoicesAction extends Action {
    query: string;
    filter: FiltersState;
    filterName: string;
    source: SearchFilterChoicesSource;
}

export interface SetGlobalFilterChoicesAction extends Action {
    results: FirmModel[] | FundModel[] | PortfolioModel[];
    count: number;
}

export interface ClearAsyncFiltersAction extends Action {
    pageName: string;
    filterName?: string;
}

export type GetChoicesAction = GetFilterChoicesAction | GetChoicesByFundAction;
export type AsyncFiltersChoicesAction = GetChoicesAction |
    SetChoicesAction |
    SetAsyncFiltersAction |
    SearchFilterChoicesAction |
    SetGlobalFilterChoicesAction |
    ClearAsyncFiltersAction;
