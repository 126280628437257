import { put, takeLatest, all, takeEvery, select, call } from 'redux-saga/effects';

import * as fundApi from 'services/fund';
import * as dataManagerApi from 'services/dataManager';
import * as gridConfigurationApi from 'services/gridConfiguration';

import GlobalHelper from 'helpers/globalHelper';
import { AppState, Id } from 'store/types';
import {
    CREATE_FUND_FAIL_MESSAGE, DELETE_FUND_CONTACT_SUCCESS_MESSAGE, DELETE_FUND_CONTACT_FAIL_MESSAGE,
    UPDATE_FUND_CONTACT_SUCCESS_MESSAGE, UPDATE_FUND_CONTACT_FAIL_MESSAGE, CREATE_FUND_CONTACT_SUCCESS_MESSAGE,
    CREATE_FUND_CONTACT_FAIL_MESSAGE, CREATE_FUND_SUCCESS_MESSAGE, DELETE_FUND_FAIL, DELETE_FUND_SUCCESS,
    FUND_ALREADY_EXISTS_MESSAGE, DELETE_FUNDS_SUCCESS, FUNDS_BULK_UPLOAD_NAME, initialFundModel,
    SAVE_REQUESTOR_DATASET_FAIL_MESSAGE,
} from './constants';
import {
    applyFilter as getPortfolioFunds, clearNewFund, setAddingContactResult, setContacts, setDatasets, setFirmFromErm,
    setFund, setFundAttributes, setFunds, setFundsDataEntries, setFundDatasetHistory,
    alterFundProfileStatistics, updateFundProfileUserSettings, setFundProfileUserSettings, setFundListTemporaryHeaders,
    updateRequestorDataset, updateRequestorAndHistoryDataset,
} from './actionCreators';
import {
    GET_FUND, APPLY_FILTER, CREATE_NEW_FUND, GET_CONTACTS, DELETE_CONTACTS,
    GetFundAction, GetContactsAction, ApplyFilterAction,
    DeleteContactsAction, EditContactAction, EDIT_CONTACT, ADD_CONTACT, AddContactAction,
    DELETE_FUNDS, DeleteFundsAction,
    GET_FUND_REPORTING_HISTORY, GetFundReportingHistoryAction, GetFirmFromErmAction, GET_FIRM_FROM_ERM,
    ApplyDatasetsFilterAction, APPLY_DATASETS_FILTER, GetFundDatasetHistoryAction, GET_FUND_DATASET_HISTORY,
    EXPORT_FUND_DATASETS, ExportFundDatasetsAction, DOWNLOAD_FUNDS, DownloadFundsAction, BULK_UPLOAD,
    BulkUploadAction, SAVE_FUND_LIST_COLUMNS, SaveFundListColumnsAction, GET_FUND_ATTRIBUTES, GetFundAttributesAction,
    GET_GRID_CONFIGURATION, GET_FUNDS_DATA_ENTRIES, GetFundsDataEntriesAction, GET_FUND_PROFILE_STATISTICS,
    GetFundProfileStatisticsAction, AddFundsToProfileStatisticsAction, ADD_FUNDS_TO_PROFILE_STATISTICS,
    ApplyFilterFundProfileAction, APPLY_FILTER_FUND_PROFILE,
    ADD_PEER_GROUPS_TO_PROFILE_STATISTICS, AddPeerGroupsToProfileStatisticsAction,
    UPDATE_FUND_PROFILE_USER_SETTINGS, UpdateFundProfileUserSettingsAction,
    REMOVE_ENTITY_FROM_PROFILE_STATISTICS, RemoveEntityFromProfileStatisticsAction,
    GET_FUND_LIST_FOR_ADD_ITEMS_MODAL, GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL,
    GetFundListColumnsAction, SaveRequestorDatasetAction, SAVE_REQUESTOR_DATASET, MAKE_DATASET_THE_LATEST,
    MakeDatasetTheLatestAction,
} from './actions';
import { updateUserAccountSettings } from 'store/Auth/actionCreators';
import { FilterNames, FiltersState } from '../Filters/types';
import { callApplyFilters, clearChecked, setFilters, setIsLoading } from '../Filters/actionCreators';
import { closeModal } from '../Modals/General/actionCreators';
import { setEditedRequest } from '../Requests/actionCreators';
import { headerNames } from '../VisibleHeaders/types';
import { setHeaders } from '../VisibleHeaders/actionCreators';
import { setDatasetDetailTemporaryHeaders } from '../DataManager/actionCreators';
import { startBulkUpload } from '../BulkUpload/actionCreator';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { getUser, getUserAccount, getUserAccountType } from 'store/Auth/selectors';
import { getExplicitlyCheckedIds, getFilters } from 'store/Filters/selectors';
import { prepareFilterState } from 'store/Filters/helpers';
import { isRequestor, isRequestorAccount } from 'store/User/helpers';
import { getFundStatisticIds, getPeerGroupStatisticIds, getSingleFundState } from './selectors';
import { bulkActionMessages, DEFAULT_ITEMS_PER_PAGE, ExportPageType, PAGINATION_LARGE_LIMIT } from 'store/constants';
import { getFundListState, getPeerGroupListState } from 'store/Modals/AddItems/selectors';
import { getPlainPeerGroupList } from 'store/Modals/AddItems/sagas';
import {
    GetFundListForAddItemsModalAction, GetPeerGroupListForAddItemsModalAction
} from 'store/Modals/AddItems/actions';
import { setFundListForAddItemsModal, setPeerGroupListForAddItemsModal } from 'store/Modals/AddItems/actionCreators';
import { FundProfileUserSettingsModel } from 'store/Fund/models/fund';
import { BenchmarkType } from 'store/Profiles/Groups/types';

import { DEFAULT_FILTER, DEFAULT_PORTFOLIO_FUNDS_FILTER } from 'store/constants';

function* applyFilter(action: ApplyFilterAction) {
    const setResponse = (response: any, withScroll: boolean) => {
        return setFunds(response.count, response.count_firm, response.results, response.permissions, withScroll);
    };
    yield* getAllFundList(action.type, action.filter, setResponse);
}

export function* getAllFundList(
    actionType: string,
    filter: FiltersState,
    setResponseActionCreator: (response: any, withScroll: boolean) => any
) {
    try {
        const withScroll = filter.scroller;

        yield put(initAction(actionType));
        if (withScroll) {
            yield put(setIsLoading(withScroll && true));
        }

        const store = yield select();
        const userType = store.auth.user.active_account.account.type;
        const response = yield fundApi.getFunds(filter, userType);

        yield put(setResponseActionCreator(response, withScroll));

        if (withScroll) {
            yield put(setIsLoading(false));
        }
        yield put(doneActionSuccess(actionType));
    } catch (errors) {
        yield put(doneActionFail(actionType, errors));
        yield put(setIsLoading(false));
    }
}

function* getGridConfiguration(action: GetFundListColumnsAction) {
    try {
        yield put(initAction(action.type));
        const gridConfiguration = yield gridConfigurationApi.getSavedFundListColumns(
            action.instanceId, action.gridType
        );
        if (gridConfiguration && gridConfiguration.visible_columns) {
            yield put(setHeaders(headerNames.fundList, gridConfiguration.visible_columns, true));
            const newStore = yield select();
            yield put(setFundListTemporaryHeaders(newStore.headers[headerNames.fundList]));
        }
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* applyFilterForFundDatasets(action: ApplyDatasetsFilterAction) {
    try {
        yield put(initAction(action.type));
        yield put(setIsLoading(true));

        let currentFund = yield select(getSingleFundState);
        if (!(currentFund && currentFund.id === action.filter.id && currentFund.ap_id !== undefined)) {
            currentFund = yield call(updateFundInStore, action.filter.id, false);
        }

        if (currentFund.fund_configuration && currentFund.fund_configuration.visible_fields) {
            yield put(setHeaders(headerNames.fundDatasets, currentFund.fund_configuration.visible_fields, true));
            const newStore = yield select();
            yield put(setDatasetDetailTemporaryHeaders(newStore.headers[headerNames.fundDatasets]));
        }

        // handle loading several pages per one request
        let extraLimit = 0;
        if (action.filter.extraLimit) {
            extraLimit = action.filter.extraLimit;
            action.filter.limit = extraLimit;
            action.filter.offset = 0;
            delete(action.filter.extraLimit);
        }
        const response = yield dataManagerApi.getRequestorsPublishedDatasets(action.filter, currentFund.ap_id);

        // return to default pagination limits and offsets
        if (extraLimit) {
            yield put(setFilters(
                FilterNames.datasetsTab,
                {
                    ...action.filter,
                    limit: DEFAULT_ITEMS_PER_PAGE,
                    offset: (extraLimit - DEFAULT_ITEMS_PER_PAGE)
                }
            ));
        }
        const withScroll = !!action.filter.scroller;
        yield put(setDatasets(response.count, response.results, withScroll));

        yield put(doneActionSuccess(action.type));
        yield put(setIsLoading(false));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* saveRequestorDataset(action: SaveRequestorDatasetAction) {
    try {
        yield put(initAction(action.type));
        const updatedDataset = yield dataManagerApi.saveRequestorDataset(action.dataset);
        yield put(updateRequestorDataset(updatedDataset));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, SAVE_REQUESTOR_DATASET_FAIL_MESSAGE));
    }
}

function* makeDatasetTheLatest(action: MakeDatasetTheLatestAction) {
    try {
        yield put(initAction(action.type));
        const updatedDataset = yield dataManagerApi.makeDatasetTheLatest(action.dataset);
        delete(updatedDataset.rowType);
        yield put(updateRequestorAndHistoryDataset(action.dataset, updatedDataset));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, SAVE_REQUESTOR_DATASET_FAIL_MESSAGE));
    }
}

function* getFundsDatasetsHistory(action: GetFundDatasetHistoryAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        const fundApId = store.funds.fund.ap_id;

        const response = yield dataManagerApi.getRequestorsDatasetHistory(
            {...DEFAULT_FILTER, period: action.dataset.period, ordering: '-received_at'},
            fundApId
        );

        yield put(setFundDatasetHistory(action.id, response.results));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* exportFundDatasets(action: ExportFundDatasetsAction) {
    try {
        yield put(initAction(action.type));
        yield dataManagerApi.exportRequestorsPublishedDatasets({checkedAll: true}, action.id);

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.DOWNLOAD_FAILED));
    }
}

function* downloadFunds(action: DownloadFundsAction) {
    try {
        yield put(initAction(action.type));

        const store = yield select();
        yield fundApi.exportFile(
            action.checkedIds,
            action.checkedAll,
            ExportPageType.Funds,
            store.filters[FilterNames.fundList]
        );
        yield put(clearChecked(FilterNames.fundList));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getFirmFromErm(action: GetFirmFromErmAction) {
    try {
        yield put(initAction(action.type));

        const response = yield fundApi.getAllFunds(action.filter);

        yield put(setFirmFromErm(response.results));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield  put(setIsLoading(false));
    }
}

function* updateFundInStore(fundId: Id, withRequest: boolean) {
    const userAccount = yield select(getUserAccount);
    let currentFund = yield select(getSingleFundState);

    yield put(setFund({...initialFundModel}));
    yield put(alterFundProfileStatistics([], true));

    const {currentFundData, allEntities} = yield all({
        currentFundData: call(fundApi.getFundById, fundId, withRequest, userAccount.type),
        allEntities: call(fundApi.getEntityDatasets, isRequestorAccount(userAccount), fundId, [], {}),
    });

    if (currentFundData.id === currentFund.id) {
        const perm = currentFund.permissions;
        currentFund = {...currentFund, ...currentFundData};
        currentFund.permissions = {...currentFund.permissions, ...perm};
    } else {
        currentFund = currentFundData;
    }

    yield put(setFund(currentFund));
    yield put(alterFundProfileStatistics(allEntities.results, true));

    if (currentFund.hasOwnProperty('request')) {
        yield put(setEditedRequest(currentFund.request));
    }
    return currentFund;
}

function* getFundById(action: GetFundAction) {
    try {
        yield put(initAction(action.type));
        yield call(updateFundInStore, action.id, action.withRequest);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getFundAttributes(action: GetFundAttributesAction) {
    try {
        yield put(initAction(action.type));

        const attributes = yield fundApi.getFundAttributes({...action.filter, attribute_type: action.attributeType});
        const withScroll = !!action.filter.scroller;
        yield put(setFundAttributes(action.attributeType, attributes.count, attributes.results, withScroll));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* createFund(action: any) {
    try {
        yield put(initAction(action.type));

        const store = yield select();
        const userType = store.auth.user.active_account.account.type;
        const answer = yield fundApi.createFund(action.newFund, userType);
        if (
            answer &&
            answer.__all__ &&
            answer.__all__[0] === 'This fund already exists.'
        ) {
            yield put(doneActionSuccess(action.type, FUND_ALREADY_EXISTS_MESSAGE));
        } else {
            yield put(doneActionSuccess(action.type, CREATE_FUND_SUCCESS_MESSAGE));
        }

        let callApplyType = FilterNames.fundList;
        if (action.newFund.portfolio_id) {
            callApplyType = FilterNames.portfolioDetail;
        }

        yield put(callApplyFilters(callApplyType, APPLY_FILTER));
        yield put(doneActionSuccess(action.type));
        yield put(clearNewFund());
        yield put(closeModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, CREATE_FUND_FAIL_MESSAGE));
    }
}

function* deleteFundsByIds(action: DeleteFundsAction) {
    try {
        yield put(initAction(action.type));

        const store = yield select();
        const userType = store.auth.user.active_account.account.type;

        yield fundApi.deleteFunds(
            action.ids,
            action.portfolioId,
            userType,
            action.checkedAll,
            store.filters[FilterNames.portfolioDetail]
        );
        yield put(clearChecked(FilterNames.portfolioDetail));
        yield put(getPortfolioFunds({
            ...DEFAULT_PORTFOLIO_FUNDS_FILTER,
            portfolio_id: action.portfolioId
        }));
        if (action.ids.length > 1) {
            yield put(doneActionSuccess(action.type, DELETE_FUNDS_SUCCESS));
        } else {
            yield put(doneActionSuccess(action.type, DELETE_FUND_SUCCESS));
        }
        yield put(closeModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, DELETE_FUND_FAIL));
    }
}

function* getContactsByFundId(action: GetContactsAction) {
    try {
        yield put(initAction(action.type));

        let currentFund = yield select(getSingleFundState);
        if (!(currentFund && currentFund.id === action.fundId && currentFund.ap_id !== undefined)) {
            currentFund = yield call(updateFundInStore, action.fundId, false);
        }
        const response = yield fundApi.getContactsList(currentFund.id);
        yield put(setContacts(response.results));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getFundsDataEntries(action: GetFundsDataEntriesAction) {
    try {
        yield put(initAction(action.type));
        const response = yield fundApi.getFundsDataEntries(action.period);
        yield put(setFundsDataEntries(response));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* updateContact(action: EditContactAction) {
    try {
        yield put(initAction(action.type));
        yield fundApi.updateContact(action.contactId, action.contact);
        yield put(doneActionSuccess(action.type, UPDATE_FUND_CONTACT_SUCCESS_MESSAGE));
        const response = yield fundApi.getContactsList(action.fundId);
        yield put(setContacts(response.results));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, UPDATE_FUND_CONTACT_FAIL_MESSAGE));
    }
}

function* addContact(action: AddContactAction) {
    try {
        yield put(initAction(action.type));
        yield fundApi.createContact(action.contact);
        yield put(doneActionSuccess(action.type, CREATE_FUND_CONTACT_SUCCESS_MESSAGE));
        const response = yield fundApi.getContactsList(action.contact.fund);
        yield put(setContacts(response.results));
        yield put(setAddingContactResult());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, CREATE_FUND_CONTACT_FAIL_MESSAGE));
    }
}

function* deleteContactsByIds(action: DeleteContactsAction) {
    try {
        yield put(initAction(action.type));
        yield fundApi.deleteContacts(action.contactIds);
        yield put(doneActionSuccess(action.type, DELETE_FUND_CONTACT_SUCCESS_MESSAGE));
        yield put(closeModal());
        const response = yield fundApi.getContactsList(action.fundId);
        yield put(setContacts(response.results));
        yield put(clearChecked(FilterNames.contactList));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, DELETE_FUND_CONTACT_FAIL_MESSAGE));
    }
}

function* getFundReportingHistory(action: GetFundReportingHistoryAction) {
    try {
        yield put(initAction(action.type));

        // const history = yield fundApi.getReportingHistory(action.fundId);
        // yield put(setFundReportingHistory(history));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* fundsBulkUpload(action: BulkUploadAction) {
    try {
        yield put(initAction(action.type));

        yield put(startBulkUpload(FUNDS_BULK_UPLOAD_NAME));
        yield fundApi.bulkUpload(action.files);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.UPLOAD_FILE_FAILED));
    }
}

function* saveFundListColumns(action: SaveFundListColumnsAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield gridConfigurationApi.saveFundListColumns(action.visibleHeaders, action.instanceId, action.gridType);
        yield put(doneActionSuccess(action.type));

        const temporaryHeaders = GlobalHelper.getValueFromObject(store, 'funds.fundListTemporaryHeaders', null);
        if (temporaryHeaders) {
            yield put(setHeaders(headerNames.fundList, temporaryHeaders));
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* getFundProfileStatistics(action: GetFundProfileStatisticsAction) {
    try {
        yield put(initAction(action.type));

        const user = yield select(getUser);

        yield put(alterFundProfileStatistics([], true));
        const allEntities = yield fundApi.getEntityDatasets(isRequestor(user), action.fundId, [], {});
        yield put(alterFundProfileStatistics(allEntities.results, true));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

export function* getPlainFundList(
    actionType: string,
    filter: FiltersState,
    setResponseActionCreator: (response: any, withScroll: boolean) => any,
    selectedIdsSelector: (state: AppState) => Id[] = getFundStatisticIds
) {
    try {
        const withScroll = filter.scroller;

        yield put(initAction(actionType));
        if (withScroll) {
            yield put(setIsLoading(true));
        }

        const userAccountType = yield select(getUserAccountType);
        const selectedIds = yield select(selectedIdsSelector);
        const response = yield fundApi.getPlainFunds(
            {...filter, selected_id: selectedIds},
            userAccountType
        );

        yield put(setResponseActionCreator(response, withScroll));

        if (withScroll) {
            yield put(setIsLoading(false));
        }

        yield put(doneActionSuccess(actionType));
    } catch (errors) {
        yield put(doneActionFail(actionType, errors));
        yield put(setIsLoading(false));
    }
}

function* addFundsToProfileStatistics(action: AddFundsToProfileStatisticsAction) {
    try {
        yield put(initAction(action.type));

        let filters = yield select(getFilters, action.filterName);
        const user = yield select(getUser);
        const results = (yield select(getFundListState)).list;
        filters.limit = PAGINATION_LARGE_LIMIT;
        filters.checkedIds = yield select(getExplicitlyCheckedIds(results), action.filterName);

        const statistics = yield fundApi.getEntityDatasets(
            isRequestor(user),
            action.baseFundId,
            filters.checkedIds,
            prepareFilterState(filters),
            BenchmarkType.Fund,
        );
        yield put(alterFundProfileStatistics(statistics.results));
        yield* updateSelectedFundsInFundProfileUserSettings(action);

        yield put(closeModal());
        yield put(clearChecked(action.filterName));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* applyFilterFundProfile(action: ApplyFilterFundProfileAction) {
    try {
        yield put(initAction(action.type));
        yield put(updateUserAccountSettings(action.filter));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getFundListForAddItemsModal(action: GetFundListForAddItemsModalAction) {
    const setResponse = (response: any, withScroll: boolean) => {
        return setFundListForAddItemsModal(response.count, response.count_firm, response.results, withScroll);
    };
    yield* getPlainFundList(action.type, action.filter, setResponse);
}

function* getPeerGroupListForAddItemsModal(action: GetPeerGroupListForAddItemsModalAction) {
    const setResponse = (response: any, withScroll: boolean) => {
        return setPeerGroupListForAddItemsModal(response.count, response.results, withScroll);
    };
    yield* getPlainPeerGroupList(action.type, action.filter, setResponse);
}

function* addPeerGroupsToProfileStatistics(action: AddPeerGroupsToProfileStatisticsAction) {
    try {
        yield put(initAction(action.type));

        let filters = yield select(getFilters, action.filterName);
        const user = yield select(getUser);
        const results = (yield select(getPeerGroupListState)).list;
        filters.limit = PAGINATION_LARGE_LIMIT;
        filters.checkedIds = yield select(getExplicitlyCheckedIds(results), action.filterName);

        const statistics = yield fundApi.getEntityDatasets(
            isRequestor(user),
            action.baseFundId,
            filters.checkedIds,
            prepareFilterState(filters),
            BenchmarkType.FundGroup,
        );

        yield put(alterFundProfileStatistics(statistics.results));
        yield* updateSelectedFundsInFundProfileUserSettings(action);

        yield put(closeModal());
        yield put(clearChecked(action.filterName));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* removeEntityFromProfileStatistics(action: RemoveEntityFromProfileStatisticsAction) {
    try {
        yield put(initAction(action.type));
        yield* updateSelectedFundsInFundProfileUserSettings(action);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* updateSelectedFundsInFundProfileUserSettings(
    action: RemoveEntityFromProfileStatisticsAction
        | AddFundsToProfileStatisticsAction
        | AddPeerGroupsToProfileStatisticsAction
) {
    try {
        if (action.rememberSelected) {
            const selectedFundIds = yield select(getFundStatisticIds);
            const fundsToRemember = selectedFundIds.filter((id: Id) => action.baseFundId !== id);
            const peerGroupsToRemember = yield select(getPeerGroupStatisticIds);

            let settings: FundProfileUserSettingsModel = {};
            if (fundsToRemember) {
                settings.remembered_selected_funds = fundsToRemember;
            }
            if (peerGroupsToRemember) {
                settings.remembered_selected_groups = peerGroupsToRemember;
            }
            yield put(updateFundProfileUserSettings(action.baseFundId, settings));
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* updateFundProfileSettings(action: UpdateFundProfileUserSettingsAction) {
    /**
     * Update Fund Profile settings saga
     */
    try {
        yield put(initAction(action.type));
        const response = yield fundApi.updateFundProfileUserSettings(action.fundId, action.settings);
        yield put(setFundProfileUserSettings(response));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* watchApplyFilter() {
    yield takeLatest(APPLY_FILTER, applyFilter);
}

function* watchApplyDatasetsFilter() {
    yield takeLatest(APPLY_DATASETS_FILTER, applyFilterForFundDatasets);
}

function* watchGetFund() {
    yield takeLatest(GET_FUND, getFundById);
}

function* watchGetFundAttributes() {
    yield takeLatest(GET_FUND_ATTRIBUTES, getFundAttributes);
}

function* watchDownloadFunds() {
    yield takeEvery(DOWNLOAD_FUNDS, downloadFunds);
}

function* watchGetFundsDatasetsHistory() {
    yield takeLatest(GET_FUND_DATASET_HISTORY, getFundsDatasetsHistory);
}

function* watchExportFundDatasets() {
    yield takeLatest(EXPORT_FUND_DATASETS, exportFundDatasets);
}

function* watchUpdateFund() {
    yield takeEvery(CREATE_NEW_FUND, createFund);
}

function* watchGetContactsByFund() {
    yield takeLatest(GET_CONTACTS, getContactsByFundId);
}

function* watchUpdateContact() {
    yield takeLatest(EDIT_CONTACT, updateContact);
}

function* watchAddContact() {
    yield takeLatest(ADD_CONTACT, addContact);
}

function* watchDeleteContacts() {
    yield takeEvery(DELETE_CONTACTS, deleteContactsByIds);
}

function* watchDeleteFunds() {
    yield takeEvery(DELETE_FUNDS, deleteFundsByIds);
}

function* watchGetFirmFromErm() {
    yield takeEvery(GET_FIRM_FROM_ERM, getFirmFromErm);
}

function* watchGetFundReportingHistory() {
    yield takeEvery(GET_FUND_REPORTING_HISTORY, getFundReportingHistory);
}

function* watchBulkUpload() {
    yield takeLatest(BULK_UPLOAD, fundsBulkUpload);
}

function* watchSaveFundListColumns() {
    yield takeEvery(SAVE_FUND_LIST_COLUMNS, saveFundListColumns);
}

function* watchGetGridConfiguration() {
    yield takeEvery(GET_GRID_CONFIGURATION, getGridConfiguration);
}

function* waitGetFundDataEntries() {
    yield takeEvery(GET_FUNDS_DATA_ENTRIES, getFundsDataEntries);
}

function* waitGetFundStatistics() {
    yield takeLatest(GET_FUND_PROFILE_STATISTICS, getFundProfileStatistics);
}

function* watchAddFundsToProfileStatistics() {
    yield takeLatest(ADD_FUNDS_TO_PROFILE_STATISTICS, addFundsToProfileStatistics);
}

function* watchGetFundListForAddItemsModal() {
    yield takeLatest(GET_FUND_LIST_FOR_ADD_ITEMS_MODAL, getFundListForAddItemsModal);
}

function *watchApplyFilterFundProfile() {
    yield takeLatest(APPLY_FILTER_FUND_PROFILE, applyFilterFundProfile);
}

function* watchGetPeerGroupListForAddItemsModal() {
    yield takeLatest(GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL, getPeerGroupListForAddItemsModal);
}

function* watchAddPeerGroupsToProfileStatistics () {
    yield takeLatest(ADD_PEER_GROUPS_TO_PROFILE_STATISTICS, addPeerGroupsToProfileStatistics);
}

function* watchUpdateFundProfileSettings() {
    yield takeLatest(UPDATE_FUND_PROFILE_USER_SETTINGS, updateFundProfileSettings);
}

function* watchEntityFundFromProfileStatistics() {
    yield takeEvery(REMOVE_ENTITY_FROM_PROFILE_STATISTICS, removeEntityFromProfileStatistics);
}

function* watchSaveRequestorDataset() {
    yield takeEvery(SAVE_REQUESTOR_DATASET, saveRequestorDataset);
}

function* watchMakeDatasetTheLatest() {
    yield takeEvery(MAKE_DATASET_THE_LATEST, makeDatasetTheLatest);
}

export default function* root() {
    yield all(
        [
            watchApplyFilter(),
            watchApplyDatasetsFilter(),
            watchDownloadFunds(),
            watchGetFund(),
            watchGetFundAttributes(),
            watchGetFundsDatasetsHistory(),
            watchExportFundDatasets(),
            watchDeleteFunds(),
            watchUpdateFund(),
            watchGetContactsByFund(),
            watchUpdateContact(),
            watchAddContact(),
            watchDeleteContacts(),
            watchGetFundReportingHistory(),
            watchGetFirmFromErm(),
            watchBulkUpload(),
            watchSaveFundListColumns(),
            watchGetGridConfiguration(),
            waitGetFundDataEntries(),
            waitGetFundStatistics(),
            watchGetFundListForAddItemsModal(),
            watchAddFundsToProfileStatistics(),
            watchApplyFilterFundProfile(),
            watchGetPeerGroupListForAddItemsModal(),
            watchAddPeerGroupsToProfileStatistics(),
            watchUpdateFundProfileSettings(),
            watchEntityFundFromProfileStatistics(),
            watchSaveRequestorDataset(),
            watchMakeDatasetTheLatest(),
        ]
    );
}
