import * as React from 'react';
import * as classNames from 'classnames';
import { TableTypes } from './TableTypes';

const styles = require('./UiDataTable.scss');
const UiDataTable: React.FC<TableTypes> = ({ children, className }) => {
    const tableClass = classNames({
        'ui-table': true,
        [className]: !!className,
        [styles.ui_data_table]: !!styles.ui_data_table,
    });

    return (
        <div className={tableClass}>
            {children}
        </div>
    );
};

export default UiDataTable;
