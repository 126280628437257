import * as _ from 'lodash';

import { BenchmarkItem, BenchmarkType, GroupEditState, GroupsState, GroupType } from './types';
import {
    ADD_FUND_LIST_TO_BENCHMARKS,
    ADD_PEER_GROUP_LIST_TO_BENCHMARKS,
    AddFundListToBenchmarksAction,
    AddPeerGroupListToBenchmarksAction,
    DELETE_ITEM_LIST_FROM_BENCHMARKS,
    DeleteItemListFromBenchmarksAction,
    ProfilesGroupsAction,
    PUBLISH_GROUP,
    SELECT_VERSION,
    SET_EDITED_GROUP,
    SET_GROUPS,
    SET_GROUPS_WITH_SCROLL,
    SET_TARGET_FUND_LIST,
    SET_TARGET_FUND_LIST_WITH_SCROLL,
    SET_TARGET_TEMPORARY_HEADERS,
    SetEditedGroupAction,
    SetGroupsAction,
    SetTargetFundListAction,
    SetTargetTemporaryHeadersAction,
    UPDATE_GROUP_PROPERTY,
    UpdateGroupPropertyAction
} from './actions';
import { groupEditInitialState, initialState } from './constants';
import { isFund, isFundGroup } from './helpers/benchmark';

const groupEditReducer = (state: GroupEditState = groupEditInitialState, action: ProfilesGroupsAction) => {
    let benchmarkItems: BenchmarkItem[];
    switch (action.type) {
        case SET_EDITED_GROUP:
            return {
                ...state,
                group: _.cloneDeep((<SetEditedGroupAction> action).group)
            };
        case SET_TARGET_FUND_LIST:
            return {
                ...state,
                targetSection: {
                    ...state.targetSection,
                    fundList: {
                        ...state.targetSection.fundList,
                        ...(<SetTargetFundListAction> action),
                    }
                }
            };
        case SET_TARGET_FUND_LIST_WITH_SCROLL: {
            const list = [
                ...state.targetSection.fundList.list,
                ...(<SetTargetFundListAction> action).list
            ];
            return {
                ...state,
                targetSection: {
                    ...state.targetSection,
                    fundList: {
                        ...state.targetSection.fundList,
                        list
                    },
                }
            };
        }
        case SET_TARGET_TEMPORARY_HEADERS:
            const { headers } = (<SetTargetTemporaryHeadersAction> action);
            return {
                ...state,
                targetSection: {
                    ...state.targetSection,
                    temporaryHeaders: [...headers]
                }
            };
        case UPDATE_GROUP_PROPERTY:
            const {name, value} = (<UpdateGroupPropertyAction> action);

            const group = {
                ...state.group,
                [name]: value
            };
            if (name === 'group_type' && value === GroupType.BenchmarkGroup) {
                group.weighting = null;
            }

            return {
                ...state,
                group
            };
        case ADD_FUND_LIST_TO_BENCHMARKS:
            const existedFunds = new Set(),
                fundsToAdd = (<AddFundListToBenchmarksAction> action).funds;

            benchmarkItems = state.group.benchmark_items || [];

            if (!fundsToAdd.length) {
                return state;
            }

            benchmarkItems.forEach((item: BenchmarkItem) => {
                if (isFund(item)) {
                    existedFunds.add(item.content_object.id);
                }
            });
            return {
                ...state,
                group: {
                    ...state.group,
                    benchmark_items: benchmarkItems.concat(fundsToAdd.map(fund => ({
                        content_type: BenchmarkType.Fund,
                        content_object: {...fund}
                    })))
                }
            };
        case ADD_PEER_GROUP_LIST_TO_BENCHMARKS:
            const existedPeerGroups = new Set(),
                peerGroupsToAdd = (<AddPeerGroupListToBenchmarksAction> action).groups;

            if (!peerGroupsToAdd.length) {
                return state;
            }

            benchmarkItems = state.group.benchmark_items || [];
            benchmarkItems.forEach((item: BenchmarkItem) => {
                if (isFundGroup(item)) {
                    existedPeerGroups.add(item.content_object.id);
                }
            });
            return {
                ...state,
                group: {
                    ...state.group,
                    benchmark_items: benchmarkItems.concat(peerGroupsToAdd.map(peerGroup => ({
                        content_type: BenchmarkType.FundGroup,
                        content_object: {...peerGroup}
                    })))
                }
            };
        case DELETE_ITEM_LIST_FROM_BENCHMARKS:
            benchmarkItems = state.group.benchmark_items || [];
            const {ids} = (<DeleteItemListFromBenchmarksAction> action);
            return {
                ...state,
                group: {
                    ...state.group,
                    benchmark_items: benchmarkItems.filter(item => {
                        return item.id && ids.indexOf(item.id) < 0;
                    })
                }
            };
        default:
            return state;
    }
};

export const groupsReducer = (state: GroupsState = initialState, action: ProfilesGroupsAction) => {
    switch (action.type) {
        case SET_GROUPS:
            return {
                ...state,
                list: (<SetGroupsAction> action).groups,
                count: (<SetGroupsAction> action).count,
                permissions: (<SetGroupsAction> action).permissions,
            };
        case SET_GROUPS_WITH_SCROLL: {
            const list = [
                ...state.list,
                ...(<SetGroupsAction> action).groups
            ];
            return {
                ...state,
                list,
            };
        }
        case PUBLISH_GROUP:
        case SELECT_VERSION:
        case SET_EDITED_GROUP:
        case SET_TARGET_FUND_LIST:
        case SET_TARGET_FUND_LIST_WITH_SCROLL:
        case UPDATE_GROUP_PROPERTY:
        case SET_TARGET_TEMPORARY_HEADERS:
        case ADD_FUND_LIST_TO_BENCHMARKS:
        case ADD_PEER_GROUP_LIST_TO_BENCHMARKS:
        case DELETE_ITEM_LIST_FROM_BENCHMARKS:
            return {
                ...state,
                edit: groupEditReducer(state.edit, action)
            };
        default:
            return state;
    }
};
