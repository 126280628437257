import ApiHelper, { API_ACL_URL } from 'helpers/creditApiHelper';

import { InvitedUser } from 'store/Shared/models/invitedUser';
import { AclRole } from 'store/DataManager/types';
import { Id } from 'store/types';

export const getAclUsers = async (fundId?: Id): Promise<any> => {
    let url;

    if (fundId) {
        url = `${API_ACL_URL}/users/${fundId}/`;
    } else {
        url = `${API_ACL_URL}/users/pending-users`;
    }
    return await ApiHelper.doRequest(
        url,
        'get'
    );
};

export const inviteAclUser = async (fundId: Id, invitedUser: InvitedUser) => {
    return await ApiHelper.doRequest(
        `${API_ACL_URL}/users/`,
        'post',
        {
            fund: fundId,
            ...invitedUser
        }
    );
};

export const deleteAclUsers = async (fundId: Id, ids: Id[]) => {
    return await ApiHelper.doRequest(
        `${API_ACL_URL}/users/${fundId}/bulk-delete/`,
        'post',
        { ids }
    );
};

export const setRoleAclUsers = async (fundId: Id, ids: Id[], role: AclRole) => {
    return await ApiHelper.doRequest(
        `${API_ACL_URL}/users/${fundId}/bulk-change-role/`,
        'patch',
        { ids, role }
    );
};

export const becomeAclOwner = async (fundId: Id) => {
    return await ApiHelper.doRequest(
        `${API_ACL_URL}/users/become-owner/`,
        'post',
        { fund: fundId }
    );
};

export const requestAclAccess = async (fundId: Id) => {
    return await ApiHelper.doRequest(
        `${API_ACL_URL}/users/request-access/`,
        'post',
        { fund: fundId }
    );
};