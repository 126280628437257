import * as React from 'react';

import { connect } from 'react-redux';

import UpgradeAdhocAccountModal from 'components/User/UpgradeAdhocAccountModal';
import { AppState } from 'store/types';
import { isModalOpen } from 'store/Modals/General/selectors';
import { closeModal } from 'store/Modals/General/actionCreators';
import { getUser } from 'store/Auth/selectors';
import ActiveUser from 'store/Auth/models/activeUser';
import { UPGRADE_ADHOC_ACCOUNT_MODAL_NAME } from 'store/User/constants';
import { updatePassword, upgradeAdhocAccount } from 'store/User/actionCreators';
import UpdatePasswordRequest from 'store/User/models/updatePasswordRequest';

interface State {
    agreed: boolean;
    password: string;
}

interface Props {
    user: ActiveUser;
    isOpen: boolean;
    closeModal: () => any;
    updatePassword: (request: UpdatePasswordRequest) => any;
    upgradeAdhocAccount: () => any;
}

class UpgradeAdhocAccountModalContainer extends React.Component<Props, State> {
    state = {
        agreed: false,
        password: ''
    };

    handleChangePassword = (password) => {
        this.setState({ password });
    }

    handleToggleAgreed = () => {
        this.setState(prevState => ({agreed: !prevState.agreed}));
    }

    handleUpgradeAdhocAccount = () => {
        if (this.props.user.is_internal) {
            this.props.updatePassword({new_password: this.state.password});
        } else {
            this.props.upgradeAdhocAccount();
        }
    }

    render() {
        const termsOfServiceLink = `${process.env.REACT_APP_TERMS_OF_SERVICE_LINK}`;
        return (
            <UpgradeAdhocAccountModal
                {...this.props}
                agreed={this.state.agreed}
                password={this.state.password}
                changePassword={this.handleChangePassword}
                upgradeAdhocAccount={this.handleUpgradeAdhocAccount}
                toggleAgreed={this.handleToggleAgreed}
                termsLink={termsOfServiceLink}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    user: getUser(state),
    isOpen: isModalOpen(state, {name: UPGRADE_ADHOC_ACCOUNT_MODAL_NAME})
});

const mapDispatchToProps = {
    closeModal, upgradeAdhocAccount, updatePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeAdhocAccountModalContainer);