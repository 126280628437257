import * as React from 'react';
import { Container } from 'reactstrap';

import SplitPane from 'react-split-pane';

import Logo from './Shared/Logo/Logo';
import NavigationPanel from './Shared/Navigation/Panel';
import { NavigationButton } from 'store/types';

import { ToastContainer } from 'react-toastify';

const styles = require('./AppPage.scss');

interface Props {
    buttons: NavigationButton[];

    minSize: number;
    maxSize: number;
    size?: number;
    logoSize?: number;
    handleDrag: (newSize: number) => void;
    handleDragStart: () => void;
    handleDragEnd: (newSize: number) => void;
    children: {
        header: React.ReactNode;
        content: React.ReactNode;
        globalProgress: React.ReactNode;
    };
    isModalOpen: boolean;
    setNewSize: () => void;
}

class AppPage extends React.Component<Props> {

    render() {
        let classNameForFixedBlock: string = '';
        if (this.props.logoSize === this.props.maxSize) {
            classNameForFixedBlock = 'is-block--opened';
        }

        return (
            <>
                <Container fluid={true} className="h-100">
                    <SplitPane
                        className={`${styles.splitPane}`}
                        split="vertical"
                        minSize={this.props.minSize}
                        maxSize={this.props.maxSize}
                        size={this.props.size}
                        onChange={this.props.handleDrag}
                        onDragStarted={this.props.handleDragStart}
                        onDragFinished={this.props.handleDragEnd}
                    >
                        <div className={`${styles.panel} d-flex flex-column`}>
                            <Logo
                                setNewSize={this.props.setNewSize}
                                size={this.props.logoSize}
                            />
                            <NavigationPanel buttons={this.props.buttons} />
                        </div>
                        <div className={`${styles.content} d-flex flex-column j-scroll ${classNameForFixedBlock}`}>
                            {this.props.children.header}
                            {this.props.children.content}
                        </div>
                    </SplitPane>
                    {!this.props.isModalOpen && this.props.children.globalProgress}
                </Container>
                <ToastContainer />
            </>
        );
    }
}

export default AppPage;
