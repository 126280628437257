import * as React from 'react';
import { RefObject } from 'react';

import { ScrollStatus } from 'smooth-scrollbar/interfaces';
import SmoothScrollbar from 'smooth-scrollbar';

import ColoredScrollbar from '../Scroll/ColoredScrollbar';
import Scrollbar from '../Scroll/Scrollbar';
import { TableHeader } from 'store/types';
import HorizontalScrollForTouchDevices from './HorizontalScrollForTouchDevices';

const styles = require('./DataTableHeaderScrollbar.scss');

interface Props {
    headers: TableHeader[];
    countFixedColumns: number;
    withCheckboxes: boolean;
    isAnyIdChecked: boolean;
    widthFixedColumns: number;

    mainHeaderRef: RefObject<HTMLTableRowElement>;
    fakeHeaderRef: RefObject<HTMLTableRowElement>;

    mainTableWrapperElement: HTMLElement | null;
    fakeTableWrapperElement: HTMLElement | null;

    verticalScrollbarWidth: number;
}

class DataTableHeaderScrollbar extends React.PureComponent<Props> {
    wrapperRef: RefObject<HTMLDivElement>;
    headerScrollRef: RefObject<HTMLDivElement>;
    headerScrollbarRef: RefObject<Scrollbar>;

    constructor(props: Props) {
        super(props);

        this.wrapperRef = React.createRef();

        this.headerScrollRef = React.createRef();
        this.headerScrollbarRef = React.createRef();
    }

    get mainTableWidth() {
        const {mainTableWrapperElement} = this.props;

        if (mainTableWrapperElement) {
            return mainTableWrapperElement.clientWidth;
        }

        return 0;
    }

    get firstRowWidth() {
        let rows: HTMLCollectionOf<HTMLTableRowElement> | null = null;
        const {mainHeaderRef, mainTableWrapperElement, verticalScrollbarWidth} = this.props;

        if (mainTableWrapperElement) {
            rows = mainTableWrapperElement.getElementsByTagName('tr');
        }

        if (rows && rows.length > 1) {
            return rows[1].clientWidth - verticalScrollbarWidth;
        } else if (mainHeaderRef && mainHeaderRef.current) {
            return mainHeaderRef.current.clientWidth;
        }

        return 0;
    }

    syncScroll = () => {
        const {mainHeaderRef, widthFixedColumns} = this.props;
        if (
            this.headerScrollRef && this.headerScrollRef.current &&
            this.headerScrollbarRef && this.headerScrollbarRef.current &&
            this.wrapperRef && this.wrapperRef.current &&
            mainHeaderRef && mainHeaderRef.current
        ) {
            const {scrollbar} = this.headerScrollbarRef.current;
            this.headerScrollRef.current.style.width = `${this.firstRowWidth}px`;

            this.wrapperRef.current.style.width = `${this.mainTableWidth}px`;
            scrollbar.contentEl.style.width = `${this.firstRowWidth + widthFixedColumns}px`;

            scrollbar.update();

            this.handleOnScroll({offset: scrollbar.offset, limit: scrollbar.limit}, scrollbar);
        }
    }

    handleHorizontalScroll = (offset: number) => {
        this.performScroll(offset);
    }

    handleOnScroll = (status: ScrollStatus, scrollbar: SmoothScrollbar) => {
        this.scrollTables(status);
    }

    render() {
        return (
            <div className={styles.wrapper} ref={this.wrapperRef}>
                <ColoredScrollbar
                    alwaysShowTracks={true}
                    scrollbarRef={this.headerScrollbarRef}
                    className={styles.headerScrollbar}
                    onScroll={this.handleOnScroll}
                >
                    <div ref={this.headerScrollRef} />
                </ColoredScrollbar>
                <HorizontalScrollForTouchDevices
                    handleScroll={this.handleHorizontalScroll}
                    tableElement={this.props.mainTableWrapperElement}
                />
            </div>
        );
    }

    componentDidMount() {
        (window as Window).addEventListener('resize', this.syncScroll);
    }

    componentWillUnmount() {
        (window as Window).removeEventListener('resize', this.syncScroll);
    }

    private scrollTables(status: ScrollStatus) {
        const {mainTableWrapperElement, fakeTableWrapperElement} = this.props;

        if (mainTableWrapperElement && fakeTableWrapperElement) {
            mainTableWrapperElement.scrollLeft = status.offset.x;
            fakeTableWrapperElement.scrollLeft = status.offset.x;
        }
    }

    private performScroll(offset: number) {
        if (this.headerScrollbarRef && this.headerScrollbarRef.current) {
            const {scrollbar} = this.headerScrollbarRef.current;

            const x = scrollbar.offset.x;
            scrollbar.scrollLeft = x + offset;
        }
    }
}

export default DataTableHeaderScrollbar;