import { Id } from '../types';
import { BulkUploadState } from './types';
import { initialState } from './constants';
import {
    SET_UPLOAD_HISTORY,
    BulkUploadAction,
    SetUploadHistoryAction,
    HIDE_GLOBAL_PROGRESS, HideGlobalProgressAction, START_BULK_UPLOAD,
    StartBulkUploadAction, SET_FULL_UPLOAD_PROGRESS, SetFullUploadProgressAction, UpdateUploadProgressAction,
    UPDATE_UPLOAD_PROGRESS, END_UPLOADING, START_UPLOADING, UpdateUploadStatusAction,
} from './actions';

export const bulkUploadReducer = (state: BulkUploadState = initialState, action: BulkUploadAction) => {
    let name, id: Id;
    switch (action.type) {
        case START_BULK_UPLOAD:
            ({name} = (<StartBulkUploadAction> action));
            return {
                ...state,
                [name]: {
                    ...state[name],
                    visibleGlobal: true
                }
            };
        case SET_FULL_UPLOAD_PROGRESS:
            return {
                ...state,
                in_progress: (<SetFullUploadProgressAction> action).progresses.map(pr => {
                    pr.visible = true;
                    return pr;
                })
            };
        case UPDATE_UPLOAD_PROGRESS:
            const progress = (<UpdateUploadProgressAction> action).progress;
            const uploadTypeKey = `_${progress.type}`;

            let exists = state.in_progress.find(pr => pr.id === progress.id);
            let historyExists = state.history && state.history[uploadTypeKey]
                && state.history[uploadTypeKey].find(pr => pr.id === progress.id);

            let inProgress, newHistory;
            if (exists) {
                inProgress = state.in_progress.map(pr => {
                    return pr.id === progress.id ? {...progress, visible: pr.visible} : pr;
                });
            } else {
                progress.visible = true;
                inProgress = [...state.in_progress, progress];
            }

            if (historyExists) {
                newHistory = state.history[uploadTypeKey].map(pr => {
                    return pr.id === progress.id ? {...progress} : pr;
                });
            } else {
                newHistory = state.history && state.history[uploadTypeKey]
                    ? [...state.history[uploadTypeKey]]
                    : [];
                newHistory.unshift(progress);
            }

            return {
                ...state,
                in_progress: inProgress,
                history: {
                    ...state.history,
                    [uploadTypeKey]: newHistory
                }
            };
        case SET_UPLOAD_HISTORY:
            const {uploadType, history} = (<SetUploadHistoryAction> action);
            const key = `_${uploadType}`;
            return {
                ...state,
                history: {
                    ...state.history,
                    [key]: history
                }
            };
        case HIDE_GLOBAL_PROGRESS:
            ({id} = (<HideGlobalProgressAction> action));
            const currentHiddenImports = localStorage.getItem('hiddenImports');
            const hiddenImports = currentHiddenImports ? JSON.parse(currentHiddenImports) : [];
            hiddenImports.push(id);
            localStorage.setItem('hiddenImports', JSON.stringify(hiddenImports));
            return {
                ...state,
                in_progress: state.in_progress.map(pr => pr.id !== id ? pr : {...pr, visible: false})
            };
        case START_UPLOADING:
        case END_UPLOADING: {
            const {status, type} = (<UpdateUploadStatusAction> action).progress;
            return {
                ...state,
                latestUploadStatus: {
                    ...state.latestUploadStatus,
                    [type]: status
                }
            };
        }
        default:
            return state;
    }
};