import * as React from 'react';
import { Table } from 'reactstrap';

import { TableBulkAction, TableHeader, TableRowAction } from 'store/types';
import { FilterDecoratorChange } from 'decorators/FilterDecorator';
import { TableBulkActionChoices } from 'store/constants';
import { DEFAULT_ITEMS_PER_PAGE, trashIcon, trashIconDisabled } from 'store/constants';
import GlobalHelper from 'helpers/globalHelper';
import { UploadAction } from 'store/BulkUpload/types';

import Loader from '../Ui/Loader';
import Checkbox from '../Ui/Checkbox';
import DataColumn from './DataColumn';
import HeaderColumn from './HeaderColumn';
import RowActionsColumn from './RowActionsColumn';

const styles = require('./DataUploadHistoryTable.scss');

interface Props {
    rows: any[];
    headers: TableHeader[];
    // -- function to apply filters (pagination, filters or sorting) --
    handleChange?: FilterDecoratorChange;
    // -- pagination ---------
    hasPagination: boolean;
    isLoading?: boolean;
    // -- sorting parameters -
    hasSorting: boolean;
    currentFilter?: any;
    // -- bulk actions -------
    withCheckboxes: boolean;
    bulkActions?: TableBulkAction[];
    checkedAll?: boolean;
    checkedIds?: number[];
    chooseAll?: () => void;
    chooseRow?: (id: number) => void;
    // -- row actions --------
    rowActions?: TableRowAction[];
    // -- inline changes -----
    editingRowNumber?: number;
    editingRow?: any;
    handleColumnChange?: (value: any, name: string) => void;
    isGridLoading?: boolean;
}

class DataUploadHistoryTable extends React.PureComponent<Props> {

    scrollElement: any = null;

    state = {
        isLoading: false
    };

    constructor(props: any) {
        super(props);
        this.scroller = this.scroller.bind(this);
    }

    scroller(e: any) {
        const wrapper = e.target;
        const list = wrapper.querySelector('table');
        const [scrollTop, wrapperHeight, listHeight] =
            [
                wrapper.scrollTop,
                wrapper.offsetHeight,
                list.offsetHeight
            ];

        const diffHeight = listHeight - wrapperHeight;

        if (this.props.handleChange && diffHeight <= scrollTop && !this.state.isLoading) {
            this.props.handleChange({ scroller: true, offset: DEFAULT_ITEMS_PER_PAGE });
            this.setState({isLoading: true});

            if (!GlobalHelper.isIE()) {
                wrapper.scrollBy(0, -5);
            }
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isLoading !== this.props.isLoading
        ) {
            this.setState({isLoading: nextProps.isLoading});
        }
    }

    componentDidMount() {
        if (this.scrollElement) {
            this.scrollElement.addEventListener('scroll', this.scroller);
        }
    }

    componentWillUnmount() {
        this.scrollElement.removeEventListener('scroll', this.scroller);
    }

    render() {
        const {headers, rows, hasSorting, checkedIds, withCheckboxes, rowActions } = this.props;
        const isAnyIdChecked = checkedIds && checkedIds.length !== 0;
        const colSpanForNoData = (withCheckboxes && !rowActions || rowActions && !withCheckboxes)
            ? headers.length + 1 : (withCheckboxes && rowActions
                ? headers.length + 2 : headers.length);
        return (
            <>
            <Table responsive={true} hover={true} className={styles.tableGrid}>
                <thead>
                <tr className={hasSorting ? 'hasSorting' : ''}>
                    {
                        withCheckboxes &&
                        <th className="checkboxHeader">
                            <Checkbox
                                idx="checkbox-header"
                                value={`checkedAll`}
                                checked={this.props.checkedAll}
                                handleChange={this.props.chooseAll}
                            />
                            {
                                this.props.bulkActions &&
                                this.props.bulkActions.map((action: any, actionId: number) => {
                                    return (
                                        <div key={actionId}>
                                            {
                                                action.type === TableBulkActionChoices.Delete && (
                                                    <img
                                                        src={isAnyIdChecked ? trashIcon : trashIconDisabled}
                                                        width="15"
                                                        alt="trash"
                                                        className={styles.tableIcon}
                                                        onClick={() => {
                                                            if (isAnyIdChecked) {action.handler(); }}
                                                        }
                                                    />
                                                )
                                            }
                                        </div>
                                    );
                                })
                            }
                        </th>
                    }
                    {
                        headers.map((header, idx) => (
                            <HeaderColumn
                                key={idx}
                                header={header}
                                hasSorting={this.props.hasSorting}
                                currentFilter={this.props.currentFilter}
                                handleClick={this.props.handleChange}
                            />
                        ))
                    }
                    {
                        rowActions &&  <th>Actions</th>
                    }
                </tr>
                </thead>
            </Table>
            <div
                className={this.props.hasPagination ? styles.scroller : ''}
                ref={(input: any) => { this.scrollElement = input; }}
            >

                <Table responsive={true} hover={true} className={styles.tableGrid}>
                    <tbody>
                    {
                        rows.length === 0 && !this.props.isGridLoading &&
                        <tr>
                            <td colSpan={colSpanForNoData}>No data</td>
                        </tr>
                    }
                    {
                        this.props.isGridLoading && !this.state.isLoading &&
                        <tr>
                            <td colSpan={colSpanForNoData}>
                                <Loader className={styles.loader_in_table} isLoading={this.props.isGridLoading}/>
                            </td>
                        </tr>
                    }

                    {   rows.length > 0 && rows.filter((row, rowIndex) => {
                            return row.action === UploadAction.IMPORT;
                        }).map((row, rowIdx) => {
                            return (
                                <tr key={rowIdx}>
                                    {
                                        withCheckboxes && (!this.props.isGridLoading || this.state.isLoading) &&
                                        <td scope="row" className="checkboxHeader">
                                            {
                                                checkedIds && this.props.chooseRow &&
                                                (
                                                    <Checkbox
                                                        idx={rowIdx}
                                                        value={`row-${rowIdx}`}
                                                        checked={checkedIds.indexOf(row.id) > -1}
                                                        handleChange={() => {
                                                            if (this.props.chooseRow) {
                                                                this.props.chooseRow(row.id);
                                                            }
                                                            return;
                                                        }}
                                                    />
                                                )
                                            }
                                        </td>
                                    }
                                    {
                                        (!this.props.isGridLoading || this.state.isLoading)
                                        && headers.map((item: any, i: number) => (
                                            <DataColumn
                                                key={i}
                                                header={item}
                                                row={this.props.editingRowNumber !== rowIdx
                                                    ? row
                                                    : this.props.editingRow}
                                                rowIdx={rowIdx}
                                                isEditing={this.props.editingRowNumber === rowIdx}
                                                handleChange={(event: any) => {
                                                    if (this.props.handleColumnChange) {
                                                        this.props.handleColumnChange(event, item.name);
                                                    } else {
                                                        return;
                                                    }
                                                }}
                                            />
                                        ))
                                    }
                                    {
                                        (!this.props.isGridLoading || this.state.isLoading) &&
                                        <td className={styles.rowActions}>
                                            {
                                                rowActions &&
                                                rowActions.map((action: any, actionId: number) => {
                                                    const hide = action.isVisible && !action.isVisible(row, rowIdx)
                                                        ? 'hidden-action'
                                                        : '';
                                                    return <RowActionsColumn
                                                        key={actionId}
                                                        idx={actionId}
                                                        action={action}
                                                        row={row}
                                                        className={'action-items ' + hide}
                                                        isEditing={this.props.editingRowNumber === rowIdx}
                                                    />;
                                                })
                                            }
                                        </td>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <Loader className={styles.loader} isLoading={this.state.isLoading}/>
            </>
        );
    }
}

export default DataUploadHistoryTable;
