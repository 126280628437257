import DiligenceApiHelper from 'helpers/diligenceApiHelper';
import { API_TFA_URL } from 'helpers/diligence/apiHelper';
import ActiveUser from 'store/Diligence/Auth/models/activeUser';

export const generateTFASecret = async (type: string, phone: string | null) => {
    return DiligenceApiHelper.doRequest(`${API_TFA_URL}/generate_secret`, 'post', {
        type, phone
    }).then(result => {
        return {
            qrURI: result.secret.qrURI,
            secret: result.secret.secret
        };
    });
};

export const verifyTFASetupCode = async (code: string) => {
    return DiligenceApiHelper.doRequest(`${API_TFA_URL}/verify_setup_code`, 'post', {
        code
    });
};

export const disableTwoFactorAuthSettings = async (user: ActiveUser) => {
    return DiligenceApiHelper.doRequest(`${API_TFA_URL}/disable`, 'post').then(result => {
        return result.data;
    });
};

export const updateCookie = async (rememberCookie: boolean) => {
    return DiligenceApiHelper.doRequest(`${API_TFA_URL}/cookie`, 'post', {
        rememberCookie
    }).then((result) => {
        return result.data;
    });
};