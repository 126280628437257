import * as React from 'react';
import Dropzone from 'react-dropzone';

import ButtonPrimary from '../Ui/Buttons/ButtonPrimary';
const styles = require('./DropDownArea.scss');

interface Props {
    upload: (files: File[]) => void;
}

class DropDownArea extends React.PureComponent<Props> {
    state = {
        files: []
    };

    onDrop = (files: any) => {
        this.setState({
            files
        });

        this.props.upload(files);
    }

    render() {
        return (
            <Dropzone onDrop={this.onDrop} className={styles.DropDownArea}>
                <div className={styles.DropDownAreaWrapper}>
                    <h2 className={styles.DropDownAreaHeader}>
                        Drop files anywhere to upload
                    </h2>
                    <div className={styles.DropDownAreaOr}>or</div>
                    <ButtonPrimary inverse={true} buttonLarge={true}>
                        Select Files
                    </ButtonPrimary>
                </div>
            </Dropzone>
        );
    }
}

export default DropDownArea;