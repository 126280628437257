import { createStore, applyMiddleware, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducer';
import { AppState } from './types';

import { composeWithDevTools } from 'redux-devtools-extension';

import AppSagas from './sagas';
import AccountSagas from './Account/sagas';
import ActionSagas from './Actions/sagas';
import AsyncFiltersChoicesSagas from './AsyncFiltersChoices/sagas';
import AuthSagas from './Auth/sagas';
import DiligenceAuthSagas from './Diligence/Auth/sagas';
import BulkUploadSagas from './BulkUpload/sagas';
import FiltersSagas from './Filters/sagas';
import FirmSagas from './Firm/sagas';
import FundSagas from './Fund/sagas';
import PorfolioSagas from './Portfolio/sagas';
import RequestsSagas from './Requests/sagas';
import UserSagas from './User/sagas';
import DiligenceUserSagas from './Diligence/User/sagas';
import DataManagerSagas from './DataManager/sagas';
import SocketSagas from './Socket/sagas';
import DashboardSagas from './Dashboard/sagas';
import AlertManagerSagas from './AlertsManager/sagas';
import ProfilesSagas from './Profiles/sagas';
import ModalSagas from './Modals/sagas';
import DiligenceDataManagerSagas from './Diligence/DataManager/sagas';
import DiligenceRequestSagas from './Diligence/DiligenceRequest/sagas';
import DiligenceFilterSagas from './Diligence/Filters/sagas';
import DiligenceFilterDropdownSagas from './Diligence/FilterDropdown/sagas';
import AdvancedFiltersModalSagas from './AdvancedFilters/sagas';

const isProduction = process.env.NODE_ENV === 'production';
const middleware: Middleware[] = [];

const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

export default function configureStore(initialState: AppState) {
    const enhancer = isProduction
        ? applyMiddleware(...middleware)
        : composeWithDevTools(applyMiddleware(...middleware));

    const store = createStore(rootReducer, initialState as any, enhancer);

    sagaMiddleware.run(AppSagas);
    sagaMiddleware.run(AccountSagas);
    sagaMiddleware.run(ActionSagas);
    sagaMiddleware.run(AsyncFiltersChoicesSagas);
    sagaMiddleware.run(AuthSagas);
    sagaMiddleware.run(BulkUploadSagas);
    sagaMiddleware.run(FiltersSagas);
    sagaMiddleware.run(FirmSagas);
    sagaMiddleware.run(FundSagas);
    sagaMiddleware.run(PorfolioSagas);
    sagaMiddleware.run(RequestsSagas);
    sagaMiddleware.run(UserSagas);
    sagaMiddleware.run(DataManagerSagas);
    sagaMiddleware.run(SocketSagas);
    sagaMiddleware.run(DashboardSagas);
    sagaMiddleware.run(AlertManagerSagas);
    sagaMiddleware.run(ProfilesSagas);
    sagaMiddleware.run(ModalSagas);
    sagaMiddleware.run(DiligenceAuthSagas);
    sagaMiddleware.run(DiligenceUserSagas);
    sagaMiddleware.run(DiligenceDataManagerSagas);
    sagaMiddleware.run(DiligenceRequestSagas);
    sagaMiddleware.run(DiligenceFilterSagas);
    sagaMiddleware.run(DiligenceFilterDropdownSagas);
    sagaMiddleware.run(AdvancedFiltersModalSagas);

    return store;
}