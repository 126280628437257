import * as React from 'react';
import { CSSProperties } from 'react';
import * as moment from 'moment';

import { AuthState } from './Auth/types';
import { ActionsState } from './Actions/types';
import { AccountState } from './Account/types';
import {
    Alignment,
    CellTypeChoices,
    FilterType,
    FormatTypeChoices,
    TableBulkActionChoices,
    TableRowActionIcon,
    TableRowActionType,
} from './constants';
import { ModalsState } from './Modals/types';
import { PortfolioState } from './Portfolio/types';
import { FundState } from './Fund/types';
import { FiltersState } from './Filters/types';
import { FirmState } from './Firm/types';
import { UserState } from './Diligence/User/types';
import { RequestsState } from './Requests/types';
import { DataManagerState } from './DataManager/types';
import { BulkUploadState } from './BulkUpload/types';
import { HeadersState } from './VisibleHeaders/types';
import { RedirectState } from './Redirect/types';
import { AsyncFiltersChoicesState } from './AsyncFiltersChoices/types';
import { DashboardState } from './Dashboard/types';
import { AlertsManagerState } from './AlertsManager/types';
import { ProfilesState } from './Profiles/types';
import { DiligenceDataManagerState } from './Diligence/DataManager/types';
import { DiligenceRequestState } from './Diligence/DiligenceRequest/types';
import { DiligenceAuthState } from './Diligence/Auth/types';
import { DiligenceFilterDropdownState } from './Diligence/FilterDropdown/types';
import { AdvancedFiltersModalState } from './AdvancedFilters/types';
import { ValueType } from 'react-select/lib/types';

export interface AppState {
    actions?: ActionsState;
    asyncFiltersChoices?: AsyncFiltersChoicesState;
    modals?: ModalsState;
    auth?: AuthState;
    account?: AccountState;
    portfolios?: PortfolioState;
    funds?: FundState;
    firms?: FirmState;
    filters?: FiltersState;
    user?: UserState;
    requests?: RequestsState;
    dataManager?: DataManagerState;
    redirect?: RedirectState;
    bulkUpload?: BulkUploadState;
    headers?: HeadersState;
    dashboard?: DashboardState;
    alertsManager?: AlertsManagerState;
    profiles?: ProfilesState;
    diligenceAuth?: DiligenceAuthState;
    diligenceDataManager?: DiligenceDataManagerState;
    diligenceRequests?: DiligenceRequestState;
    diligenceFilters?: FiltersState;
    diligenceFilterDropdown?: DiligenceFilterDropdownState;
    advancedFiltersModal?: AdvancedFiltersModalState;
}

export interface NavigationButton {
    path: string;
    title: string;
    accountAccess?: AccountAccessType;
    icon?: string;
    children?: NavigationButton[];
    roles?: string[];
    requiresDiligenceAccount?: boolean;
    exact?: boolean;
    // svg icons that are converted to the font must set this value to true
    hasFont?: boolean;
    disabled?: boolean;
    inNewTab?: boolean;
}

export interface AccountAccessType {
    responder: boolean;
    requestor: boolean;
}

export interface TableAction {
    name: string;
    title: string;
    handler: (id: Id) => void;
}

export type PopoverFunction = (row: any) => React.ReactNode;

export interface TableHeader {
    name: string;
    // columnName - name of the column to save on the backend side
    columnName?: string;
    placeholder?: string;
    // alias for field name (even long one) to set up styles per column, i.e. width
    alias?: string;
    title: string | React.ReactNode;
    active: boolean;
    disabled?: boolean;
    cellType?: CellTypeChoices;
    formatType?: FormatTypeChoices;
    defaultValue?: string;
    options?: SelectItem[];
    class?: any;
    colored?: boolean;
    actions?: TableAction[];
    minWidth?: number;
    linkTemplate?: string;

    params?: any;

    handleClickDispatcher?: string;
    excludeEditField?: (row?: any) => boolean;
    editable?: boolean;
    getColSpan?: (row: any, width?: number) => number;
    getRowSpan?: (row: any) => number;
    isVisible?: (row: any) => boolean;
    hasEditing?: (row: any) => boolean;
    isCellDisabled?: (row: any, idx: number) => boolean;
    isCellInvalid?: (row: any, idx: number) => boolean;
    transformer?: (row: any) => string | JSX.Element | JSX.Element[];
    extraTitle?: string;
    excludeSorting?: boolean;
    orderingName?: string;
    requested?: (row: any) => boolean | boolean;
    fixed?: boolean;
    headerAlignment?: Alignment;
    valueAlignment?: Alignment;
    popover?: PopoverFunction | React.ReactNode;
    isPopoverVisible?: (row: any, idx: number) => boolean;
    dropdownName?: string;
    getCustomCellStyles?: (row: any) => string[];
}

export interface TableRowAction {
    name?: string;
    type: TableRowActionType;
    icon?: TableRowActionIcon | ((row: any) => TableRowActionIcon);
    link?: any;
    tooltip?: string;
    handler?: (id: Id | string, row?: any) => void;
    disabled?: (row: any, tableRowId?: number) => boolean;
    // tableRowId - index of the row in table
    isVisible?: (row: any, tableRowId?: number) => boolean;
    hideClass?: string | ((row: any) => string);
    props?: {
        [key: string]: any
    };
    transformer?: (row: any) => React.ReactNode;
}

export interface TableBulkAction {
    type: TableBulkActionChoices;
    handler: () => void;
    isVisible: boolean;
    link?: string;
    download?: boolean;
}

export interface FilterItem {
    name: string;
    type: FilterType;
    title?: string;
    placeholder?: string;
    disabled?: boolean;
    displayFormat?: string;
    material?: boolean;
    customInputIcon?: boolean;
    className?: string;
    classNamePrefix?: string;
    customMenuPortalClass?: CSSProperties;
    innerClassName?: string;
    withLabel?: boolean;
    visible?: boolean;
    // if isShownInitially is false - item is hidden to Show More Filters part
    isShownInitially?: boolean;
    stats?: StatItem[];
    defaultValue?: string | SelectItem;
    choices?: SelectItem[];
    choicesCount?: number;
    handleRequest?: (data: FiltersState) => void;
    currentFilter?: FiltersState;
    setFilters?: (filterName: string, filter: FiltersState, pageName?: string) => void;
    filterName?: string;
    defaultFilter?: FiltersState;
    inputType?: 'string';
    rangeEndings?: RangeEndings;
    content?: React.ReactElement<any> | React.ReactElement<any>[];
    withSortingButtons?: boolean;
    firstInverse?: boolean;
    secondInverse?: boolean;
    idPrefix?: string;
    isFilterValueObject?: boolean;
    pageName?: string;
    clearable?: boolean;
}

export interface AdvancedFilterItem {
    name: string;
    type: FilterType;
    title?: string;
    choices?: SelectItem[];
    choicesCount?: number;
    withLabel?: boolean;
    visible?: boolean;
    handleRequest?: (data: FiltersState) => void;
    currentFilter?: FiltersState;
    setFilters?: (filterName: string, filter: FiltersState, pageName?: string) => void;
    filterName?: string;
    defaultFilter?: FiltersState;
    rangeEndings?: RangeEndings;
    isFilterValueObject?: boolean;
    isPermanentlyExpandedAccordionItem?: boolean;
}

export interface StatItem {
    name: string;
    value: string | number;
    withViewIcon?: boolean;
    handleIconClick?: () => void;
}

export type RequestChangeValue = ValueType<OptionValue> | moment.Moment | object[] | null;
export type OptionValue = string;

export interface SelectItem {
    label: string;
    value: OptionValue;
}

export interface RangeEndings {
    start: string | number;
    end: string | number;
}

export enum ButtonType {
    Primary = 'primary',
    Danger = 'danger'
}

export type Id = string;

export enum CustomTableRow {
    ColoredRow = 'COLORED_ROW',
    ScheduledRow = 'SCHEDULED_ROW',
    PublishedRow = 'PUBLISHED_ROW',
    DatasetDetail = 'DATASET_DETAIL',
    WhiteRow = 'WHITE_ROW',
    WhiteRowWithBorder = 'WHITE_ROW_WITH_BORDER',
    AddNewDataset = 'ADD_NEW_DATASET',
    RequestorHistoryDataset = 'REQUESTOR_HISTORY_DATASET',
}

export enum FileType {
    Pdf = 'pdf',
    Excel = 'excel',
    Docs = 'docs'
}

export interface ApiResponseData {
    error?: string;
    errors?: string[];
    value?: string;
    message?: string;
    detail?: string;
}
