import { Action } from 'redux';
import {
    CreateDatasetByPeriod, DatasetFund, DatasetRow, InternalData,
    PublishedDataset
} from './models/dataset';
import { RequestorModel } from './models/requestor';
import { CheckboxOption, DataManagerListPermissions, GetDatasetHistory, UpdatingDatasetDetailField } from './types';
import { AclUser } from './models/aclUser';
import { AclRole, FilterAclUsers } from './types';
import { InvitedUser } from 'store/Shared/models/invitedUser';
import { RequestStatus } from '../Requests/types';
import { Id, TableHeader } from '../types';
import { FiltersState } from '../Filters/types';

export const APPLY_FILTER = 'store/dataManager/datasetList/applyFilter';
export const SET_DATASET_LIST = 'store/dataManager/datasetList/set';
export const SET_DATASET_LIST_WITH_SCROLL = 'store/dataManager/datasetList/set/withScroll';
export const BECOME_ACL_OWNER = 'store/dataManager/datasetList/becomeAclOwner';
export const REQUEST_ACL_ACCESS = 'store/dataManager/datasetList/requestAclAccess';
export const DATASET_UPLOAD = 'store/dataManager/dataset/upload';
export const DATASET_LIST_DOWNLOAD = 'store/dataManager/datasetList/download';
export const DATASET_DOWNLOAD = 'store/dataManager/dataset/download';
export const PUBLISHED_DATASET_DOWNLOAD = 'store/dataManager/publishedDataset/download';
export const CANCEL_DATASET_UPLOAD = 'store/dataManager/dataset/cancelUpload';

export const APPLY_FILTER_DATASET_DETAIL = 'store/dataManager/applyFilter/datasetDetail';
export const SET_DATASET_DETAIL = 'store/dataManager/setDatasetDetail';
export const SET_DATASET_DETAIL_WITH_SCROLL = 'store/dataManager/setDatasetDetailWithScroll';

export const APPLY_FILTER_INTERNAL_DATA = 'store/dataManager/applyFilter/internalData';
export const SET_INTERNAL_DATA = 'store/dataManager/setInternalData';
export const SET_INTERNAL_DATA_WITH_SCROLL = 'store/dataManager/setInternalDataWithScroll';
export const INTERNAL_DATA_DOWNLOAD = 'store/dataManager/internalDataDownload';
export const COMBINED_DATA_DOWNLOAD = 'store/dataManager/combinedDataDownload';
export const INTERNAL_DATA_UPLOAD = 'store/dataManager/internalDataUpload';

export const APPLY_FILTER_PUBLISHED_DATASETS = 'store/dataManager/applyFilter/publishedDatasets';
export const SET_PUBLISHED_DATASETS = 'store/dataManager/setPublishedDatasets';
export const SET_PUBLISHED_DATASETS_WITH_SCROLL = 'store/dataManager/setPublishedDatasetsWithScroll';

export const APPLY_FILTER_ACL_USERS = 'store/dataManager/applyFilterAclUsers';
export const SET_ACL_USERS = 'store/dataManager/setAclUsers';
export const START_INVITING_ACL_USER = 'store/dataManager/startInvitingAclUser';
export const CHANGE_INVITING_ACL_USER = 'store/dataManager/changeInvitingAclUser';
export const INVITE_ACL_USER = 'store/dataManager/inviteAclUser';
export const INVITE_ACL_USER_SUCCESS = 'store/dataManager/inviteAclUserSuccess';
export const DELETE_ACL_USERS = 'store/dataManager/deleteAclUsers';
export const DELETE_ACL_USERS_SUCCESS = 'store/dataManager/deleteAclUsersSuccess';
export const SET_ROLE_ACL_USERS = 'store/dataManager/setRoleAclUsers';
export const SET_ROLE_ACL_USERS_SUCCESS = 'store/dataManager/setRoleAclUsersSuccess';

export const GET_REQUESTORS_TO_CONFIRM_PUBLISH = 'store/dataManager/confirmPublishModal/getRequestors';
export const SET_REQUESTORS_TO_CONFIRM_PUBLISH = 'store/dataManager/confirmPublishModal/setRequestors';
export const SET_ALL_REQUESTORS_TOGGLE = 'store/dataManager/confirmPublishModal/setAllRequestorsToggle';
export const SET_REQUESTOR_CHECKBOXES = 'store/dataManager/confirmPublishModal/setRequestorCheckboxes';
export const CONFIRM_PUBLISH_DATASET = 'store/dataManager/confirmPublishModal/publish';
export const CONFIRM_PUBLISH_DATASET_FROM_DETAIL = 'store/dataManager/confirmPublishModal/publishFromDetail';

export const GET_REQUESTORS_TO_SET_PENDING_PUBLISH = 'store/dataManager/setPendingPublishModal/getRequestors';
export const SET_REQUESTORS_TO_SET_PENDING_PUBLISH = 'store/dataManager/setPendingPublishModal/setRequestors';
export const SET_PENDING_PUBLISH_DATASET = 'store/dataManager/setPendingPublishModal/schedule';
export const SET_PENDING_PUBLISH_DATASET_DETAIL = 'store/dataManager/setPendingPublishModal/scheduleDatasetDetail';
export const SET_DAYS_BEFORE_DUE_DATE = 'store/dataManager/setPendingPublishModal/setDaysBeforeDueDate';

export const SET_DAYS_BEFORE_DUE_DATE_FOR_EDIT_MODAL = 'store/dataManager/editPendingPublishModal/setDaysBeforeDueDate';
export const EDIT_PENDING_PUBLISH_DATASET = 'store/dataManager/editPendingPublishModal/update';

export const SET_DATASET_DETAIL_TEMPORARY_FILTER = 'store/dataManager/setDatasetDetailTemporaryFilter';
export const SET_DATASET_DETAIL_TEMPORARY_HEADERS = 'store/dataManager/setDatasetDetailTemporaryHeaders';

export const SET_PUBLISHED_DATASETS_TEMPORARY_HEADERS = 'store/dataManager/setPublishedDatasetsTemporaryHeaders';

export const UPDATE_DATASET_DETAIL_FIELD = 'store/dataManager/updateDatasetDetailField';
export const SET_UPDATING_DATASET_DETAIL_FIELD = 'store/dataManager/setUpdatingDatasetDetailField';

export const UNLOCK_DATASET_DETAIL_ROW = 'store/dataManager/unlockDatasetDetailRow';

export const GET_DATASET_HISTORY = 'store/dataManager/getDatasetHistory';
export const SET_DATASET_HISTORY = 'store/dataManager/setDatasetHistory';
export const CLEAR_DATASET_HISTORY = 'store/dataManager/clearDatasetHistory';

export const GET_DATASET_FUND = 'store/dataManager/getDatasetFund';
export const SAVE_SETTINGS_DATASET_FUND = 'store/dataManager/setSettingsDatasetFund';
export const SET_DATASET_FUND = 'store/dataManager/setDatasetFund';
export const SET_DATASET_FUND_CLEAR = 'store/dataManager/setDatasetClearFund';

export const SET_CURRENT_DATASET = 'store/dataManager/setCurrentDataset';
export const CLEAR_CURRENT_DATASET = 'store/dataManager/clearCurrentDataset';

export const ADD_DATASET_TO_FUND = 'store/dataManager/addDatasetToFund';

export const SET_REQUESTORS = 'store/dataManager/setRequestors';
export const GET_REQUESTORS = 'store/dataManager/getRequestors';

export const ACCEPT_REQUEST = 'store/dataManager/acceptRequest';
export const DENY_REQUEST = 'store/dataManager/denyRequest';
export const UPDATE_REQUEST = 'store/dataManager/updateRequest';

export const CHANGE_CACHED_STATUS_FOR_FUND = 'store/dataManager/changeCachedStatusForFund';
export const SET_CHANGE_CACHED_STATUS_FOR_FUND = 'store/dataManager/setChangeCachedStatusForFund';

export const CLEAR_IS_EDITN_DATASET = 'store/dataManager/clearIsEdingDataset';
export const SET_IS_EDITING_DATASET = 'store/dataManager/setIsEditingDataset';

export const SET_PENDING_REQUESTS = 'store/dataManager/dataset/setPendingRequests';
export const SET_PENDING_NOTIFICATION_FLAG = 'store/dataManager/dataset/setPendingNotificationFlag';

export const SET_DATASET_FLAGS = 'store/dataManager/dataset/setDatasetFlags';

export interface RequestAction extends Action {
    requestId: any;
    status: RequestStatus;
    message?: string;
}

export interface GetRequestorsToDataManager extends Action {
    fundId: any;
}

export interface ApplyFilterAction extends Action {
    filter: any;
}

export interface ApplyFilterInternalDataAction extends Action {
    filter: FiltersState;
}

export interface ApplyFilterDatasetAction extends Action {
    filter: any;
}

export interface ApplyFilterPublishedDatasetsAction extends Action {
    filter: any;
}

export interface ApplyFilterAclUsersAction extends Action {
    filter: FilterAclUsers;
}

export interface SetDatasetListAction extends Action {
    datasetListCount: number;
    datasetList: DatasetFund[];
}

export interface SetDatasetDetailAction extends Action {
    datasetDetailCount: number;
    datasetDetail: any;
}

export interface SetPublishedDatasetsAction extends Action {
    publishedDatasetsCount: number;
    publishedDatasets: PublishedDataset[];
}

export interface GetAclUsersAction extends Action {
    fundId: Id;
}

export interface SetAclUsersAction extends Action {
    aclUsersCount: number;
    aclUsers: AclUser[];
    withScroll: boolean;
}

export interface SetInternalDataAction extends Action {
    results: InternalData[];
    count: number;
    permissions: DataManagerListPermissions;
    withScroll: boolean;
}

export interface StartInvitingAclUserAction extends Action {
}

export interface ChangeInvitingAclUserAction extends Action {
    invitedUser: InvitedUser;
}

export interface InviteAclUserAction extends Action {
    fundId: Id;
    invitedUser: InvitedUser;
    closeModal: boolean;
}

export interface InviteAclUserSuccessAction extends Action {
    aclUser: AclUser;
}

export interface DeleteAclUsersAction extends Action {
    fundId: Id;
    ids: Id[];
    closeModal: boolean;
}

export interface DeleteAclUsersSuccessAction extends Action {
    ids: Id[];
}

export interface SetRoleAclUsersAction extends Action {
    fundId: Id;
    ids: Id[];
    role: AclRole;
    closeModal: boolean;
    useFundIdForLoadList: boolean;
}

export interface SetRoleAclUsersSuccessAction extends Action {
    aclUsers: AclUser[];
}

export interface BecomeAclOwnerAction extends Action {
    fundId: Id;
}

export interface RequestAclAccessAction extends Action {
    fundId: Id;
}

export interface GetRequestorsToConfirmPublishAction extends Action {
    fundId: Id;
}

export interface SetRequestorsToConfirmPublishAction extends Action {
    requestors: RequestorModel[];
}

export interface SetAllRequestorsToggleAction extends Action {
    toggleValue: boolean;
}

export interface SetRequestorCheckboxesAction extends Action {
    requestorCheckboxes: CheckboxOption[];
}

export interface ConfirmPublishDatasetAction extends Action {
    fundId: Id;
    requestorIds: Id[];
}

export interface GetRequestorsToSetPendingPublishAction extends Action {
    fundId: Id;
}

export interface SetRequestorsToSetPendingPublishAction extends Action {
    requestors: RequestorModel[];
}

export interface SetPendingPublishDatasetAction extends Action {
    fundId: Id;
    daysBeforeDueDate: number;
    requestorIds: Id[];
}

export interface SetDaysBeforeDueDateAction extends Action {
    value: number;
}

export interface EditPendingPublishDatasetAction extends Action {
    fundId: Id;
    requestorId: Id;
    daysBeforeDueDate: number;
}

export interface SetDaysBeforeDueDateForEditModalAction extends Action {
    value: number;
}

export interface SetDatasetDetailTemporaryFilterAction extends Action {
    temporaryFilter: any;
}

export interface SetDatasetDetailTemporaryHeadersAction extends Action {
    temporaryHeaders: TableHeader[];
}

export interface SetPublishedDatasetsTemporaryHeadersAction extends Action {
    temporaryHeaders: TableHeader[];
}

export interface UpdateDatasetDetailFieldAction extends Action {
    payload: UpdatingDatasetDetailField;
}

export interface SetUpdatingDatasetDetailFieldAction extends Action {
    payload: UpdatingDatasetDetailField;
}

export interface UnlockDatasetDetailRowAction extends Action {
    datasetRow: DatasetRow;
    fundId: Id;
}

export interface SetUnlockedDatasetDetailRowAction extends Action {
    payload: any;
}

export interface GetDatasetHistoryAction extends Action {
    payload: GetDatasetHistory;
}

export interface SetDatasetHistoryAction extends Action {
    parentId: any;
    results: any;
}

export interface GetDatasetFundAction extends Action {
    id: any;
}

export interface SetDatasetFundAction extends Action {
    result: DatasetFund;
}

export interface DatasetUploadAction extends Action {
    files: any;
}

export interface InternalDataUploadAction extends Action {
    files: FormData;
}

export interface CancelDatasetUploadAction extends Action {
    payload: any;
}

export interface DatasetDownloadAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
}

export interface PublishedDatasetDownloadAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
}

export interface InternalDataDownloadAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
}

export interface CombinedDataDownloadAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
}

export interface GetDatasetUploadHistoryAction extends Action {
    filter: any;
}

export interface SetCurrentDatasetAction extends Action {
    dataset: any;
}

export interface ChangeCachedStatusForFundAction extends Action {
    period: string;
    status: string;
}

export interface SetChangeCachedStatusForFundAction extends Action {
    status: string;
}

export interface SetPendingRequestsAction extends Action {
    fundId: Id;
    count: number;
}

export interface SetPendingNotificationFlagAction extends Action {
    fundId: Id;
    flag: boolean;
}

export interface SetIsEditingDatasetAction extends Action {
    row: any;
}

export interface SetUnlockedDatasetRowAction extends Action {
    payload: any;
}

export interface SetRequestorsAction extends Action {
    requestors: any[];
}

export interface UpdateRequestAction extends Action {
    request: any;
}

export interface SetFlags extends Action {
    payload: any;
}

export interface AddDatasetToFundAction extends Action {
    payload: CreateDatasetByPeriod;
}

export interface SaveSettingsDatasetFundAction extends Action {
    id: string;
    payload: any;
    requestor: boolean;
}

export type DataManagerAction =
    ApplyFilterAction | ApplyFilterAclUsersAction |
    ApplyFilterPublishedDatasetsAction |
    ApplyFilterInternalDataAction |
    SetDatasetListAction |
    SetPublishedDatasetsAction |
    GetAclUsersAction | SetAclUsersAction | StartInvitingAclUserAction |
    ChangeInvitingAclUserAction | InviteAclUserAction |
    InviteAclUserSuccessAction | DeleteAclUsersAction |
    DeleteAclUsersSuccessAction | SetRoleAclUsersAction |
    SetRoleAclUsersSuccessAction | ApplyFilterDatasetAction |
    SetDatasetDetailAction | BecomeAclOwnerAction | RequestAclAccessAction |
    GetRequestorsToConfirmPublishAction | SetRequestorsToConfirmPublishAction |
    SetAllRequestorsToggleAction | SetRequestorCheckboxesAction |
    ConfirmPublishDatasetAction | GetRequestorsToSetPendingPublishAction |
    SetRequestorsToSetPendingPublishAction | SetPendingPublishDatasetAction |
    SetDaysBeforeDueDateAction | SetDaysBeforeDueDateForEditModalAction |
    EditPendingPublishDatasetAction | UpdateDatasetDetailFieldAction | SetUpdatingDatasetDetailFieldAction |
    UnlockDatasetDetailRowAction | SetUnlockedDatasetDetailRowAction | GetDatasetHistoryAction |
    SetDatasetHistoryAction | SetDatasetFundAction | GetDatasetFundAction |
    DatasetUploadAction | CancelDatasetUploadAction | GetDatasetUploadHistoryAction | DatasetDownloadAction |
    SetCurrentDatasetAction | InternalDataDownloadAction | CombinedDataDownloadAction |
    SetPublishedDatasetsTemporaryHeadersAction | ChangeCachedStatusForFundAction | SetChangeCachedStatusForFundAction |
    SetPendingRequestsAction | SetPendingNotificationFlagAction | SetIsEditingDatasetAction |
    SetUnlockedDatasetRowAction | SetRequestorsAction | UpdateRequestAction | SetFlags |
    PublishedDatasetDownloadAction | SaveSettingsDatasetFundAction | InternalDataUploadAction |
    SetInternalDataAction;