import * as React from 'react';

import { CustomTableRow, TableRowAction } from 'store/types';
import NotStartedRow from 'components/Requests/TableRows/NotStartedRow';
import DatasetRow from 'components/Requests/TableRows/DatasetRow';
import ScheduledRow from 'components/Requests/TableRows/ScheduledRow';
import PublishedRow from 'components/Requests/TableRows/PublishedRow';
import FundDatasetNewRow from 'components/Fund/TableRows/FundDatasetNewRow';
import RequestorDatasetHistoryRow from 'components/Fund/TableRows/RequestorDatasetHistoryRow';

class TableRowHelper {
    static getCustomRow(
        rowType: string,
        row: any,
        rowIdx: number,
        otherData: any,
        getCustomStyles?: any,
        actions?: TableRowAction[],
    ): JSX.Element | string {
        let answer: JSX.Element | string = '';
        switch (rowType) {
            case CustomTableRow.DatasetDetail:
                answer = (
                    <DatasetRow
                        key={rowIdx}
                        row={row}
                        getCustomStyles={getCustomStyles}
                        visibleHeaders={otherData.visibleHeaders}
                    />
                );
                break;
            case CustomTableRow.ScheduledRow:
                answer = <ScheduledRow key={rowIdx} rowIdx={rowIdx} row={row} actions={actions} />;
                break;
            case CustomTableRow.PublishedRow:
                answer = <PublishedRow key={rowIdx} row={row} actions={actions} />;
                break;
            case CustomTableRow.WhiteRow:
                answer = (
                    <tbody key={rowIdx}>
                        <tr>
                            <td colSpan={row.colspan} className="white-empty-row" />
                        </tr>
                    </tbody>
                );
                break;
            case CustomTableRow.WhiteRowWithBorder:
                answer = (
                    <tbody key={rowIdx}>
                        <tr>
                            <td colSpan={row.colspan} />
                        </tr>
                    </tbody>
                );
                break;
            case CustomTableRow.RequestorHistoryDataset:
                answer = (
                    <RequestorDatasetHistoryRow
                        key={rowIdx}
                        row={row}
                        rowIdx={rowIdx}
                        getCustomStyles={getCustomStyles}
                        visibleHeaders={otherData.visibleHeaders}
                        rowActions={actions}
                    />
                );
                break;
            case CustomTableRow.AddNewDataset:
                answer = (
                    <FundDatasetNewRow
                        key={rowIdx}
                        row={row}
                        visibleHeaders={otherData.visibleHeaders}
                        getCustomStyles={getCustomStyles}
                    />
                );
                break;
            default:
               answer = <NotStartedRow key={rowIdx} row={row} />;
        }

        return answer;
    }
}

export default TableRowHelper;
