import { Id } from 'store/types';

import { Rule, RulePeriod } from '../Rulesets/types';

export interface AlertFund {
    id: Id;
    name: string;
    currency?: string;
    firm: {
        id: Id;
        name: string;
    };
    evaluation_result: {
        status: EvaluationResultStatus;
        periods: {
            // [key: RulePeriod]: EvaluationPeriod,
            one_month: EvaluationPeriod,
            three_months: EvaluationPeriod,
            twelve_months: EvaluationPeriod,
            other: EvaluationPeriod,
        };
    };
    permissions?: {
        can_create_ruleset: boolean;
    };
}

export interface EvaluationPeriod {
    name: RulePeriod;
    count: number;
    evaluation_warnings: number;
    status: EvaluationResultStatus;
}

export interface AlertStatusItem {
    status: EvaluationResultStatus;
    count: number;
}

export interface EvaluationResult {
    alert_status: EvaluationResultStatus;
    field: AlertField;
    period: string;
    counterparty?: string;
    rule: Rule;
    ruleset_name: string;
}

export interface FundEvaluationResults {
    count: number;
    results: EvaluationResult[];
}

export interface ViewedFund {
    statistic: AlertFund;
    evaluation_results: FundEvaluationResults;
}

export interface AlertsState {
    list: AlertFund[];
    count: number;
    alertCount: number;
    alertStatusItems: AlertStatusItem[];

    viewedFund?: ViewedFund;
}

export interface AlertsOverview {
    period_date: string;
    status: EvaluationResultStatus;
    periods: {
        one_month: EvaluationPeriod,
        three_months: EvaluationPeriod,
        twelve_months: EvaluationPeriod,
        other: EvaluationPeriod,
    };
}

export enum EvaluationResultStatus {
    AwaitingData = 'awaiting_data',
    NoAlert = 'no_alert',
    Alert = 'alert',
    PartialData = 'partial_data',
    PartialDataAlert = 'partial_data_alert',
    NoRuleset = 'no_ruleset',
}

export enum AlertField {
    Threshold = 'threshold',
    PerfShare = 'perf_share',
    Amount = 'amount',
    ThresholdAndPerfShare = 'threshold,perf_share'
}
