import { Action } from 'redux';

export const GET_FILTERS = 'store/diligence/filters/GetFilters';
export const GET_IS_LOADING = 'store/diligence/filters/GetIsLoading';
export const SET_IS_LOADING = 'store/diligence/filters/SetIsLoading';
export const SET_FILTERS = 'store/diligence/filters/SetFilters';
export const CALL_APPLY_FILTERS = 'store/diligence/filters/CallApplyFilters';
export const CHANGE_SAVED_ORDERING = 'store/diligence/filters/changeSavedOrdering';

export interface GetFiltersAction extends Action {
    filterName: string;
    payload: Array<any>;
}

export interface SetFiltersAction extends Action {
    filterName: string;
    payload: Array<any>;
}

export interface SetIsLoadingAction extends Action {
    isLoading: boolean;
}

export interface GetIsLoadingAction extends Action {
    isLoading: boolean;
}

export interface CallApplyFiltersAction extends Action {
    currentFilter: string;
    actionType: string;
    otherFilter: any;
}

export interface ChangeSavedOrderingAction extends Action {
    flag: boolean;
    value: string;
}

export type FiltersAction =
    GetFiltersAction |
    SetFiltersAction |
    SetIsLoadingAction |
    GetIsLoadingAction |
    CallApplyFiltersAction |
    ChangeSavedOrderingAction;
