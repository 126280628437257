import * as React from 'react';
import { connect } from 'react-redux';

import Header from 'components/Shared/Header/Header';
import { AppState } from 'store/types';
import { getUser } from 'store/Auth/selectors';
import { getDiligenceUser } from 'store/Diligence/Auth/selectors';
import { logout, switchAccount } from 'store/Auth/actionCreators';
import { initUpgradeAdhocAccount } from 'store/User/actionCreators';
import UpgradeAdhocAccountModal from '../User/UpgradeAdhocAccountModal';

const HeaderContainer = (props) => {
    return (
        <>
            <Header {...props} />
            <UpgradeAdhocAccountModal />
        </>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        user: getUser(state),
        diligenceUser: getDiligenceUser(state)
    };
};

const mapDispatchToProps = {
    logout,
    switchAccount,
    initUpgradeAdhocAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);