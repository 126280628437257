import * as React from 'react';

import FilterPanel from 'components/Shared/Filters/FilterPanel';
import Checkbox from 'components/Shared/Ui/Checkbox';
import HeaderHelper from 'helpers/headerHelper';

interface Props {
    temporaryFilter: any;
    setDatasetDetailTemporaryFilter: any;
    temporaryHeaders: any;
    setDatasetDetailTemporaryHeaders: any;
    handleUpdate: (type: any) => void;
    currentFilter: any;
    headers: any;
    handleColumns: (columns: any) => void;
    handleFilterChange: (data: any) => void;
    setFilters: any;
    filters: any;
}

export const headersInModal = [
    'beginning_balance', 'ending_balance', 'subscriptions', 'redemptions', 'income',
     'nav_per_share', 'known_future_redemptions'
];

export default class NavConfigurationModal extends React.Component<Props> {

    componentDidMount () {
        this.props.setDatasetDetailTemporaryFilter(this.props.currentFilter);
        this.props.setDatasetDetailTemporaryHeaders(this.props.headers);
    }

    handleColumns = (column: any) => {
        const newColumns = this.props.temporaryHeaders.map((item: any) => {
            let answer = item;
            if (item.name === column.name) {
                answer = {
                    ...item,
                    ...column
                };
            }

            return answer;
        });

        this.props.setDatasetDetailTemporaryHeaders(newColumns);
    }

    render () {
        const headers = HeaderHelper.clearColumn(this.props.temporaryHeaders, headersInModal);
        const checkboxes = headers.map((item: any, idx: number) => {
            return (
                <div key={idx} className="col-md-6">
                    <Checkbox
                        idx={`nav-${idx}`}
                        className="aligned-checkboxes"
                        checked={item.active}
                        handleChange={(event) => this.handleColumns({ active: !item.active, name: item.name })}
                    >
                        {item.title}
                    </Checkbox>
                </div>
            );
        });

        return (
            <>
                <div className="wrapper-bottom">
                    <FilterPanel
                        filters={this.props.filters}
                        currentFilter={this.props.temporaryFilter}
                        handleChange={this.props.setDatasetDetailTemporaryFilter}
                    />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="label">Fields</h5>
                    </div>
                    {checkboxes}
                </div>
            </>
        );
    }
}
