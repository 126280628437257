import * as moment from 'moment';

import { DEFAULT_DATE_FORMAT } from 'store/constants';
import { defaultRulesetListPermissions, initialState } from './constants';
import {
    CREATE_RULESET,
    ADD_RULE,
    DELETE_RULE,
    SET_CREATED_RULESET,
    SET_EDITED_RULESET,
    SET_RULESET_LIST,
    SET_RULESET_LIST_WITH_SCROLL,
    SetCreatedRulesetAction,
    SetEditedRulesetAction,
    SetRulesetListAction,
    DeleteRuleAction,
    RulesetsAction,
    UPDATE_RULE,
    UpdateRuleAction,
    SetTargetFundListAction,
    SET_TARGET_FUND_LIST,
    SET_TARGET_FUND_LIST_WITH_SCROLL,
    UPDATE_RULESET_PROPERTY,
    UpdateRulesetPropertyAction,
    SetEditedRulesetPartialAction,
    SET_EDITED_RULESET_PARTIAL,
    UPDATE_RULE_VALIDATION,
    UpdateRuleValidationAction,
    DELETE_RULE_VALIDATION,
    DeleteRuleValidationAction,
    ADD_RULE_VALIDATION, AddRuleValidationAction,
} from './actions';
import { Rule, Ruleset, RulesetsState, RuleValidation } from './types';
import { isFieldValidOrUntouched, mergeRuleValidations, validateRuleset } from './ruleValidation';
import { SET_FUND_STATISTIC, SetFundStatisticAction } from 'store/AlertsManager/Alerts/actions';

export const rulesetsReducer = (state: RulesetsState = initialState, action: RulesetsAction) => {
    let rules: Rule[], index: number, ruleset: Ruleset,
        ruleValidation, newRuleValidations;
    switch (action.type) {
        case SET_RULESET_LIST:
            return {
                ...state,
                list: (<SetRulesetListAction> action).list,
                count: (<SetRulesetListAction> action).count,
                permissions: (<SetRulesetListAction> action).permissions,
            };
        case SET_RULESET_LIST_WITH_SCROLL: {
            const list = [
                ...state.list,
                ...(<SetRulesetListAction> action).list
            ];
            return {
                ...state,
                list
            };
        }
        case SET_EDITED_RULESET:
            ruleset = (<SetEditedRulesetAction> action).ruleset;
            return {
                ...state,
                edit: {...ruleset},
                editValidation: validateRuleset(ruleset)
            };
        case SET_EDITED_RULESET_PARTIAL:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    ...(<SetEditedRulesetPartialAction> action).ruleset
                }
            };
        case SET_CREATED_RULESET:
            if (state.edit.id) {
                return state;
            }

            ruleset = (<SetCreatedRulesetAction> action).ruleset;
            return {
                ...state,
                edit: {
                    ...state.edit,
                    id: ruleset.id
                }
            };
        case CREATE_RULESET:
            if (state.edit.id) {
                return {
                    ...state,
                    edit: {}
                };
            }
            return state;
        case UPDATE_RULESET_PROPERTY:
            const {name, value} = (<UpdateRulesetPropertyAction> action);

            const newValue = moment.isMoment(value)
                ? value.format(DEFAULT_DATE_FORMAT)
                : value;

            return {
                ...state,
                edit: {
                    ...state.edit,
                    [name]: newValue
                }
            };
        case ADD_RULE:
            rules = state.edit.rules || [];
            return {
                ...state,
                edit: {
                    ...state.edit,
                    rules: [...rules, {}]
                }
            };
        case DELETE_RULE:
            index = (<DeleteRuleAction> action).index;
            rules = state.edit.rules || [];
            return {
                ...state,
                edit: {
                    ...state.edit,
                    rules: rules.filter((item, idx) => idx !== index)
                }
            };
        case UPDATE_RULE:
            index = (<UpdateRuleAction> action).index;
            const {rule} = (<UpdateRuleAction> action);
            rules = state.edit.rules || [];
            return {
                ...state,
                edit: {
                    ...state.edit,
                    rules: rules.map((item, idx) => idx !== index ? item : {...rule})
                }
            };
        case SET_TARGET_FUND_LIST:
            return {
                ...state,
                targetFundList: {
                    ...state.targetFundList,
                    list: (<SetTargetFundListAction> action).list,
                    count: (<SetTargetFundListAction> action).count,
                    firmCount: (<SetTargetFundListAction> action).firmCount,
                }
            };
        case SET_TARGET_FUND_LIST_WITH_SCROLL: {
            const list = [
                ...state.targetFundList.list,
                ...(<SetTargetFundListAction> action).list
            ];
            return {
                ...state,
                targetFundList: {
                    ...state.targetFundList,
                    list,
                }
            };
        }
        case ADD_RULE_VALIDATION:
            newRuleValidations = [
                ...state.editValidation.rules ? state.editValidation.rules : [],
                (<AddRuleValidationAction> action).validation
            ];
            return {
                ...state,
                editValidation: {
                    ...state.editValidation,
                    valid: Object.keys(newRuleValidations)
                        .map(key => newRuleValidations[key])
                        .reduce((accum, curRule) => accum && curRule.valid, true),
                    rules: newRuleValidations
                }
            };
        case UPDATE_RULE_VALIDATION:
            index = (<UpdateRuleValidationAction> action).index;
            ruleValidation = (<UpdateRuleValidationAction> action).validation;

            const oldRules = (state.editValidation && state.editValidation.rules || []);

            let newValidation;
            if (oldRules[index]) {
                newValidation = mergeRuleValidations(oldRules[index], ruleValidation);
            } else {
                newValidation = {...ruleValidation};
            }
            newValidation.validOrUntouched = Object.keys(newValidation)
                .map(key => newValidation[key])
                .reduce((accum, curRule) => accum && isFieldValidOrUntouched(curRule), true);

            newRuleValidations = oldRules.map((validation: RuleValidation, idx: number) => {
                return idx === index
                    ? newValidation
                    : validation;
            });
            return {
                ...state,
                editValidation: {
                    ...state.editValidation,
                    valid: Object.keys(newRuleValidations)
                        .map(key => newRuleValidations[key])
                        .reduce((accum, curRule) => accum && !!curRule.valid, true),
                    validOrUntouched: Object.keys(newRuleValidations)
                        .map(key => newRuleValidations[key])
                        .reduce((accum, curRule) => accum && !!curRule.validOrUntouched, true),
                    rules: newRuleValidations
                }
            };
        case DELETE_RULE_VALIDATION:
            index = (<DeleteRuleValidationAction> action).index;
            newRuleValidations = state.editValidation.rules &&
                state.editValidation.rules.filter((validation: RuleValidation, idx) => idx !== index);
            return {
                ...state,
                editValidation: {
                    ...state.editValidation,
                    valid: Object.keys(newRuleValidations)
                        .map(key => newRuleValidations[key])
                        .reduce((accum, curRule) => accum && curRule.valid, true),
                    rules: newRuleValidations
                }
            };
        case SET_FUND_STATISTIC:
            const permissions = (<SetFundStatisticAction> action).statistic.permissions
                || defaultRulesetListPermissions;
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    can_create_ruleset: permissions.can_create_ruleset
                }
            };
        default:
            return state;
    }
};
