import { Id } from 'store/types';
import CreditApiHelper from 'helpers/creditApiHelper';

export const saveFundListColumns = async (
    visibleColumns: string[],
    instanceId: Id,
    gridType: string,
): Promise<any> => {
    return CreditApiHelper.doRequest(
        `/grid-configuration/`,
        'put',
        {
            visible_columns: visibleColumns,
            grid_type: gridType,
            instance_id: instanceId,
        }
    );
};
export const getSavedFundListColumns = async (
    instanceId: Id,
    gridType: string,
): Promise<any> => {
    return CreditApiHelper.doRequest(
        `/grid-configuration/`,
        'get',
        {
            params: {
                grid_type: gridType,
                instance_id: instanceId,
            }
        }
    );
};