import * as React from 'react';

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { sendResizeWindowEvent } from 'helpers/event';
import * as classnames from 'classnames';

export interface TabItem {
    title: string;
    content?: JSX.Element | string;
    disabled?: boolean;
}

export interface Props {
    items: TabItem[];
    activeTab?: string;
}

const styles = require('./Tabpanel.scss');

class TabPanel extends React.Component<Props> {

    static defaultProps = {
        activeTab: '1'
    };

    state = {
        activeTab: ''
    };

    componentDidMount() {
        this.setState({activeTab: this.props.activeTab});
    }

    toggle = (tab: string) => {
        if (this.state.activeTab !== tab) {

            // call the event to recalculate table headers width
            sendResizeWindowEvent();

            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { items } = this.props;
        const navItems =  items.map((item: TabItem, idx: number) => {
            return (
                <NavItem key={idx}>
                    <NavLink
                        disabled={item.disabled}
                        className={classnames({active: this.state.activeTab === `${idx + 1}`})}
                        onClick={() => { this.toggle(`${idx + 1}`); }}
                    >
                        {item.title}
                    </NavLink>
                </NavItem>
            );
        });

        const content = items.map((item: TabItem, idx: number) => {
            return (
                <TabPane key={idx} tabId={`${idx + 1}`}>
                    <Row>
                        <Col sm="12">
                            <div className="tab-body">
                                {item.content}
                            </div>
                        </Col>
                    </Row>
                </TabPane>
            );
        });

        return (
            <div className={styles.tabpanel}>
                <Nav tabs={true}>
                    {navItems}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {content}
                </TabContent>
            </div>
        );
    }
}

export default TabPanel;