import { DataEntry } from 'store/Fund/types';

export function isRequestor(dataEntry?: DataEntry | null) {
    return dataEntry && dataEntry === DataEntry.Requestor;
}

export function isAlphaPipe(dataEntry?: DataEntry | null) {
    return dataEntry && dataEntry === DataEntry.AlphaPipe;
}

export function isFundManager(dataEntry?: DataEntry | null) {
    return dataEntry && dataEntry === DataEntry.FundManager;
}
