import * as React from 'react';

import { TableHeader, TableRowAction } from 'store/types';
import DataColumn from 'components/Shared/DataTable/DataColumn';
import RowActionsColumn from 'components/Shared/DataTable/RowActionsColumn';

interface Props {
    row: any;
    rowIdx: number;
    visibleHeaders: TableHeader[];
    getCustomStyles?: (row: any) => string[];
    rowActions?: TableRowAction[];
}

const styles = require('./RequestorDatasetHistoryRow.scss');

const RequestorDatasetHistoryRow: React.FC<Props> = ({row, rowIdx, visibleHeaders, getCustomStyles, rowActions}) => {
    const emptyColumns = { period: true };

    let visibleHeadersCount: number = 0;
    visibleHeaders.forEach((item: TableHeader) => {
        if (item.active) {
            visibleHeadersCount++;
        }
    });

    let answer: any;
    const keySalt = Math.random();

    if (!row.errorMessage) {
        answer = visibleHeaders.map((item: TableHeader, idx: number) => {
            let currentAnswer: JSX.Element | null = null;

            if (emptyColumns[item.name]) {
                currentAnswer = <td className={styles['dataset-history-cell']} key={idx}/>;
            } else if (item.active) {
                currentAnswer = (
                    <DataColumn
                        key={idx + '-' + keySalt}
                        rowIdx={rowIdx}
                        hasSorting={false}
                        visibleHeaders={visibleHeaders}
                        header={item}
                        row={row}
                    />
                );
            }

            return currentAnswer;
        });
    } else {
        answer = (
            <>
                <td className={styles['dataset-history-cell']}/>
                <td colSpan={visibleHeadersCount - 1}>Not found history</td>
            </>
        );
    }
    const customStyles: string[] = typeof getCustomStyles === 'function' ? getCustomStyles(row) : [];

    return (
        <tbody className={styles['dataset-row']}>
            <tr className={[...customStyles].join(' ')}>
                {answer}
                {
                    rowActions && rowActions.length > 0 &&
                    <td className={`action-cell`}>
                        {
                            rowActions &&
                            rowActions.map((action: TableRowAction, actionId: number) => {
                                const hide = action.isVisible && !action.isVisible(row, rowIdx)
                                    ? (!!action.hideClass
                                        ? (typeof action.hideClass === 'function'
                                            ? action.hideClass(row)
                                            : action.hideClass)
                                        : 'hidden-action')
                                    : '';
                                return <RowActionsColumn
                                    key={actionId + '-' + keySalt}
                                    idx={actionId + rowIdx}
                                    action={action}
                                    row={row}
                                    rowIdx={rowIdx}
                                    className={'action-items ' + hide}
                                />;
                            })
                        }
                    </td>
                }
            </tr>
        </tbody>
    );
};

export default RequestorDatasetHistoryRow;
