import { Action } from 'redux';
import { HeaderWidthObject } from './types';

export const GET_HEADERS = 'store/headers/GetHeaders';
export const SET_HEADERS = 'store/headers/SetHeaders';
export const SET_HEADERS_WIDTH = 'store/headers/SetHeadersWidth';

export interface GetHeadersAction extends Action {
    block: string;
    headers: string[];
}
export interface SetHeadersAction extends Action {
    block: string;
    headers: string[];
    isFromBackend?: boolean;
}

export interface SetHeadersWidthAction extends Action {
    block: string;
    element: HeaderWidthObject;
}

export type VisibleHeadersAction = GetHeadersAction | SetHeadersAction | SetHeadersWidthAction;
