import * as React from 'react';

import { ButtonType } from 'store/types';

import Modal from './Modal';
import ButtonPrimary from '../Ui/Buttons/ButtonPrimary';
import ButtonDefault from '../Ui/Buttons/ButtonDefault';
import ButtonDanger from '../Ui/Buttons/ButtonDanger';

export interface Props {
    isOpen: boolean;
    title: React.ReactNode;
    body: React.ReactNode;
    toggle: () => any;
    chooseYes: () => void;
    chooseNo: () => void;

    yesTitle?: string;
    yesType?: ButtonType;
    noTitle?: string;
    yesDisabled?: boolean;
    noDisabled?: boolean;
    size?: string;
}

const ConfirmModal: React.FC<Props> = ({
    isOpen, size, title, yesTitle, noTitle, body, toggle, chooseYes, chooseNo, yesDisabled, noDisabled,
    yesType = ButtonType.Primary
}) => {
    let yesButton: React.ReactNode = null;
    switch (yesType) {
        case ButtonType.Primary:
            yesButton = (
                <ButtonPrimary buttonLarge={true} handleClick={chooseYes} disabled={yesDisabled}>
                    {yesTitle}
                </ButtonPrimary>
            );
            break;
        case ButtonType.Danger:
            yesButton = (
                <ButtonDanger buttonLarge={true} handleClick={chooseYes} disabled={yesDisabled}>
                    {yesTitle}
                </ButtonDanger>
            );
            break;
        default:
            yesButton = null;
    }

    const buttons = [
        yesButton,
        (
            <ButtonDefault buttonLarge={true} inverse={true} handleClick={chooseNo} disabled={noDisabled}>
                {noTitle}
            </ButtonDefault>
        )
    ];
    return (
        <Modal isOpen={isOpen} toggle={toggle} title={title} body={body} buttons={buttons} size={size} />
    );
};

ConfirmModal.defaultProps = {
    yesTitle: 'Yes',
    noTitle: 'No',
    yesDisabled: false,
    noDisabled: false
};

export default ConfirmModal;
