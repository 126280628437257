import UpdateProfileRequest from 'store/User/models/updateProfileRequest';
import UpdatePasswordRequest from 'store/User/models/updatePasswordRequest';
import ActiveUser from 'store/Auth/models/activeUser';
import { UserAccount } from 'store/Auth/models/userAccount';
import { Account } from 'store/Account/models/account';
import { UserType } from 'store/Auth/types';
import { AccountType } from 'store/Account/types';

export const getProfileForm = (user: ActiveUser): UpdateProfileRequest => {
    return {
        last_name: user.last_name,
        first_name: user.first_name
    };
};

export const getPasswordForm = (): UpdatePasswordRequest => {
    return {
        old_password: '',
        new_password: '',
    };
};

export const isAdhoc = (user: ActiveUser) => {
    return user.type === UserType.Adhoc;
};

export const isRequestorAccount = (account?: Account) => {
    return !!account && account.type === AccountType.Requestor;
};

export const isRequestorUserAccount = (userAccount: UserAccount) => {
    return isRequestorAccount(userAccount.account);
};

export const isRequestor = (user: ActiveUser) => {
    return isRequestorUserAccount(user.active_account);
};

export const isResponderAccount = (account?: Account) => {
    return !!account && account.type === AccountType.Responder;
};

export const isResponderUserAccount = (userAccount: UserAccount) => {
    return isResponderAccount(userAccount.account);
};

export const isResponder = (user: ActiveUser) => {
    return isResponderUserAccount(user.active_account);
};

export const isInternal = (user: ActiveUser) => {
    return user.is_internal;
};

export const hasDiligenceAccount = (user: ActiveUser) => {
    return user.has_diligence_account;
};
