import * as React from 'react';
import { Modal as RSModal } from 'reactstrap';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    size?: string;
}

const styles = require('./CustomModal.scss');

const CustomModal: React.FC<Props> = ({isOpen, toggle, size, children}) => {
    return (
        <RSModal
            isOpen={isOpen}
            toggle={toggle}
            size={size}
            className={styles.prettyModal}
            zIndex="auto"
        >
            {children}
        </RSModal>
    );
};

CustomModal.defaultProps = {
    size: 'md'
};

export default CustomModal;
