import { FundAttributes } from '../Fund/types';
import { DonutPiece } from '../Shared/types';

export interface DatePeriod {
    first_period: string;
    last_period: string;
}

export interface ChartColumn {
    count: number;
    extreme_items: {
        from: number;
        to: number;
    };
    value: number;
}

interface Chart {
    count: number;
    items: ChartColumn[];
}

export type ChartData = {
    [key: string]: Chart;
};

export interface ChartPeriodResult {
    period: string;
    data: ChartData;
}

export type ChartsDataForPeriod = {
    result: ChartPeriodResult[];
} & DatePeriod;

export interface BoxChartItem {
    fund_count: number;
    value: string;
    low: number;
    q1: number;
    median: number;
    q3: number;
    high: number;
}

export interface BoxChartResult {
    data: {
        [key: string]: {
            fund_count: number;
            items: BoxChartItem[];
        }
    };
    period: string;
}

export type BoxChartsDataForPeriod = {
    result: BoxChartResult[];
} & DatePeriod;

export interface DashboardState {
    rows: DashboardGridItem[];
    rowsCount: number;
    fundsCount: number;
    firmsCount: number;
    donuts: {
        performance_report?: DonutPiece[],
        alerts?: DonutPiece[]
    };
    chart: ChartsDataForPeriod;
    boxCharts: BoxChartsDataForPeriod;
}

export interface StatisticDonutContainerProps {
    donutType: StatisticDonutDataType;
    attrType?: FundAttributes;
    attrValue?: string;
}

export enum StatisticDonutDataType {
    PerformanceReport = 'performance_report',
    Alerts = 'alerts'
}

export interface DonutStatisticItemResponse {
    status: string;
    count: number;
}

export interface DashboardAttrFundData {
    alert: number;
    late: number;
    overdue: number;
    partial: number;
    partial_data: number;
    partial_data_alert: number;
}

export interface DashboardGridItem {
    identificator: string;
    value: string;
    funds_monitor: string;
    firms_monitor: string;
    attr_fund_data: DashboardAttrFundData;
}

export interface DashboardChartItem {
    end_balance: number;
    perf_month: number;
}

export const DonutPieceTitle = {
    on_time: 'On Time',
    late: 'Late',
    partial: 'Partial Data',
    partial_data: 'Partial Data',
    overdue: 'Overdue',
    awaiting_data: 'Awaiting Data',
    no_request: 'No Request',
    no_alert: 'No Alerts',
    partial_data_alert: 'Partial Data Alerts',
    alert: 'Alerts',
    no_rules: ' No Rules',
    no_ruleset: ' No Rules'
};

export interface AumChartItem {
    count: number;
    value: number;
    extreme_items: {
        from: number;
        to: number;
    };
}

export interface PerformanceChartItem {
    count: number;
    value: number;
    extreme_items: {
        from: number;
        to: number;
    };
}

export interface AvailablePeriodsStat {
    before: number;
    after: number;
}

export enum ChartType {
    Aum = 'AUM',
    Performance = 'Performance'
}

export enum BoxChartPeriodType {
    Month = 'month',
    Ytd = 'ytd',
    Year = 'year',
    Years3 = '3years',
    Years5 = '5years',
}

export type DispersionChartType = BoxChartPeriodType;

export enum DashboardChartType {
    AumDistribution = 'end_balance_type',
    PerfMonth = 'perf_month_type',
    PerfYtd = 'perf_ytd_type',
    PerfYear = 'perf_year_type',
    Perf3Years = 'perf_3years_type',
    Perf5Years = 'perf_5years_type',
    Alerts = 'alerts_type',
    ReportingPerformance = 'reporting_perf_type',
}

export enum DashboardChartGroup {
    AumDistributionPlusPerfMonth = 'end_balance_perf_month',
    PerfYtdPlusPerfYear = 'perf_ytd_perf_year',
    Perf3YearsPlusPerf5Years = 'perf_3years_perf_5years',
    AlertsPlusReportingPerformance = 'alerts_reporting_performance'
}
