import * as React from 'react';
import * as classNames from 'classnames';
import { PublishedDataset } from '../../../store/DataManager/models/dataset';

interface Props {
    row: PublishedDataset;
}

const styles = require('./PublishedDatasetsStatusCell.scss');

const PublishedDatasetStatusCell: React.FC<Props> = ({row}) => {
    let color = '';
    let delta = '';
    let status = '';
    if (row.is_history_delivery || row.is_revision_delivery) {
        color = 'grey';
        delta = '-';
        if (row.is_history_delivery) {
            status = 'Historical';
        }
        if (row.is_revision_delivery) {
            status = 'Revision';
        }
    } else if (row.delivery_delta) {
        delta = row.delivery_delta;
        if (row.delivery_delta > 0) {
            color = 'green';
            status = 'On time';
        }
        if (row.delivery_delta < 0) {
            color = 'red';
            status = 'Received Late';
        }
    }

    const dateClassName = classNames({
        [styles['dataset-period']]: true,
        [styles[`${color}-period`]]: true,
    });

    const statusClassName = classNames({
        [styles['dataset-status']]: true,
    });

    return (
        <>
            <div >
                <span className={dateClassName}>{delta}</span>
                <span className={statusClassName}>{status}</span>
            </div>
        </>
    );
};

export default PublishedDatasetStatusCell;
