import { put, all, takeLatest } from 'redux-saga/effects';

import * as userApi from 'services/user';
import {
    INITIALIZE_RESET_PASSWORD, InitializeResetPasswordAction, RESET_PASSWORD,
    GET_DILIGENCE_USER, GetDiligenceUserAction,
    ResetPasswordAction,
    SWITCH_ACCOUNT,
    SwitchAccountAction, CHANGE_REMEMBER_COOKIE,
    ChangeRememberCookieAction, DISABLE_TWO_FACTOR_AUTHENTICATION,
    DisableTwoFactorAuthAction, GENERATE_TFA_SECRET,
    GenerateTFASecretAction, VERIFY_SETUP_CODE,
    VerifySetupCodeAction,
    LOGOUT, LogoutAction,
} from './actions';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { setUser, setTFASecret, setTwoFactorAuthSettings, logout } from './actionCreators';

import { SWITCH_ACCOUNT_FAIL_MESSAGE, SWITCH_ACCOUNT_SUCCESS_MESSAGE, messages
} from './constants';
import * as tfaApi from 'services/diligence/twoFactorAuth';
import * as authApi from 'services/diligence/auth';
import { setIsLoading } from 'store/Filters/actionCreators';
import * as diligenceUserApi from 'services/diligence/user';

function* doLogout(action: LogoutAction) {
    try {
        yield put(initAction(action.type));
        yield authApi.logout();
        yield put(logout());
        yield put(doneActionSuccess(action.type, 'Bye'));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, 'Did not log out'));
    }
}

function* getDiligenceUser(action: GetDiligenceUserAction) {
    try {
        yield put(initAction(action.type));
        yield put(setIsLoading(true));
        const user = yield diligenceUserApi.getDiligenceUser();
        yield put(setUser(user));
        yield put(doneActionSuccess(action.type));
        yield put(setIsLoading(false));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* switchAccount(action: SwitchAccountAction) {
    try {
        yield put(initAction(action.type));

        yield userApi.switchAccount(action.relationId);
        window.location.replace(`/dashboard`);
        yield put(doneActionSuccess(action.type, SWITCH_ACCOUNT_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, SWITCH_ACCOUNT_FAIL_MESSAGE));
    }
}

function* initializeResetPassword(action: InitializeResetPasswordAction) {
    try {
        yield put(initAction(action.type));
        yield userApi.initializeResetPassword(action.email);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* resetPassword(action: ResetPasswordAction) {
    try {
        yield put(initAction(action.type));
        yield userApi.resetPassword(action.password, action.token, action.resetKey);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* generateTwoFactorAuthSecret(action: GenerateTFASecretAction) {
    try {
        yield put(initAction(action.type));
        const secret = yield tfaApi.generateTFASecret(action.tfaType, action.phone);
        yield put(setTFASecret(secret.secret, secret.qrURI));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.GENERATE_TFA_SECRET_FAIL));
    }
}

function* disableTwoFactorAuth(action: DisableTwoFactorAuthAction) {
    try {
        yield put(initAction(action.type));
        const data = yield tfaApi.disableTwoFactorAuthSettings(action.user);
        yield put(setTwoFactorAuthSettings(data, action.user));
        yield put(doneActionSuccess(action.type, messages.DISABLE_TWO_FACTOR_AUTH_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.DISABLE_TWO_FACTOR_AUTH_FAIL_MESSAGE));
    }
}

function* verifySetupCode(action: VerifySetupCodeAction) {
    try {
        yield put(initAction(action.type));
        const data = yield tfaApi.verifyTFASetupCode(action.code);
        yield put(setTwoFactorAuthSettings(data.data, action.user));
        yield put(doneActionSuccess(action.type, messages.TWO_FACTOR_AUTH_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.TWO_FACTOR_AUTH_FAIL_MESSAGE));
    }
}

function* updateRememberCookie(action: ChangeRememberCookieAction) {
    try {
        yield put(initAction(action.type));
        const data = yield tfaApi.updateCookie(action.remember);
        yield put(setTwoFactorAuthSettings(data, action.user));
        if (data.rememberCookie) {
            yield put(doneActionSuccess(action.type, messages.ENABLE_REMEMBER_COOKIE_SUCCESS_MESSAGE));
        } else {
            yield put(doneActionSuccess(action.type, messages.DISABLE_REMEMBER_COOKIE_SUCCESS_MESSAGE));
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.UPDATE_REMEMBER_COOKIE_FAIL_MESSAGE));
    }
}

function* watchDisableTwoFactorAuth() {
    yield takeLatest(DISABLE_TWO_FACTOR_AUTHENTICATION, disableTwoFactorAuth);
}

function* watchGenerateTFASecret() {
    yield takeLatest(GENERATE_TFA_SECRET, generateTwoFactorAuthSecret);
}

function* watchVerifySetupCode() {
    yield takeLatest(VERIFY_SETUP_CODE, verifySetupCode);
}

function* watchUpdateRememberCookie() {
    yield takeLatest(CHANGE_REMEMBER_COOKIE, updateRememberCookie);
}

function* watchGetDiligenceUser() {
    yield takeLatest(GET_DILIGENCE_USER, getDiligenceUser);
}

function* watchSwitchAccount() {
    yield takeLatest(SWITCH_ACCOUNT, switchAccount);
}

function* watchInitializeResetPassword() {
    yield takeLatest(INITIALIZE_RESET_PASSWORD, initializeResetPassword);
}

function* watchResetPassword() {
    yield takeLatest(RESET_PASSWORD, resetPassword);
}

function* watchDoLogout() {
    yield takeLatest(LOGOUT, doLogout);
}

export default function* root() {
    yield all([
        watchSwitchAccount(), watchInitializeResetPassword(), watchResetPassword(),
        watchGetDiligenceUser(), watchDisableTwoFactorAuth(), watchGenerateTFASecret(), watchVerifySetupCode(),
        watchUpdateRememberCookie(), watchDoLogout()
    ]);
}