import * as React from 'react';

import { FiltersState } from 'store/Filters/types';
import { FilterItem } from 'store/types';
import { RequestFilterItem } from 'store/Diligence/types';
import DropdownInputFilterResultsContainer from 'containers/Diligence/Shared/DropdownInputFilterResults';

interface Props {
    filter: FilterItem;
    handleAttributeSearch: (attributeType: string, filter: FiltersState) => void;
    value: RequestFilterItem;
    handleChange: (data: FiltersState) => void;
    currentFilter: FiltersState;
    filterPageName?: string;
}

const styles = require('./DropdownInputFilter.scss');

class DropdownInputFilter extends React.PureComponent<Props> {
    search: React.RefObject<HTMLInputElement>;

    handleSearch = (event: any) => {
        let {filter} = this.props;
        let value = event.target.value;
        this.props.handleAttributeSearch(filter.name, {...filter, attributeSearch: value});
    }

    constructor(props: Props) {
        super(props);
        this.search = React.createRef();
    }

    render() {
        const {filter, handleChange, value, filterPageName} = this.props;
        return (
            <>
                <input
                    className={`form-control ${styles.input}`}
                    ref={this.search}
                    type="text"
                    placeholder="Start typing to see suggestions"
                    onChange={this.handleSearch}
                />
                <DropdownInputFilterResultsContainer
                    handleChange={handleChange}
                    filter={filter}
                    value={value}
                    filterPageName={filterPageName}
                />
            </>
        );
    }
}

export default DropdownInputFilter;
