import * as _ from 'lodash';

import CreditApiHelper, { API_RULE_MANAGER } from 'helpers/creditApiHelper';
import { ApiHelper } from 'helpers/apiHelper';

import { defaultDeniedGetParams } from 'store/constants';
import { Id } from 'store/types';
import { FiltersState } from 'store/Filters/types';
import { Ruleset } from 'store/AlertsManager/Rulesets/types';
import { isFieldValidation, validateRuleset } from 'store/AlertsManager/Rulesets/ruleValidation';

export const getList = async (filter: FiltersState): Promise<any> => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    const response = await CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/unpublished-ruleset/`,
        'get',
        {
            params
        }
    );

    return Promise.resolve(response);
};

export const save = async (ruleset: Ruleset) => {
    const url = ruleset.id
        ? `${API_RULE_MANAGER}/unpublished-ruleset/${ruleset.id}/`
        : `${API_RULE_MANAGER}/unpublished-ruleset/`;

    const method = ruleset.id
        ? 'put'
        : 'post';

    const validation = validateRuleset(ruleset);

    const storedRuleset = _.cloneDeep(ruleset);

    // Clears disabled fields before send request to back-end
    (validation.rules || []).forEach((ruleValidation, index) => {
        Object.keys(ruleValidation).forEach(field => {
            if (storedRuleset.rules && isFieldValidation(field) && ruleValidation[field].disabled) {
                storedRuleset.rules[index][field] = null;
            }
        });
    });

    const response = await CreditApiHelper.doRequest(url, method, storedRuleset);
    return Promise.resolve(response);
};

export const view = async (id: Id) => {
    const response = await CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/unpublished-ruleset/${id}/`,
        'get'
    );

    return Promise.resolve(response);
};

export const viewVersion = async (id: Id) => {
    const response = await CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/published-ruleset/${id}/`,
        'get'
    );

    return Promise.resolve(response);
};

export const getRuleset = async (id: Id, active: boolean) => {
    if (active) {
        return view(id);
    }

    return viewVersion(id);
};

export const publish = async (id: Id) => {
    return await CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/unpublished-ruleset/${id}/publish/`,
        'post'
    );
};

export const remove = async (id: Id) => {
    return await CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/unpublished-ruleset/${id}/`,
        'delete'
    );
};

export const getRulesetNames = async (filter: FiltersState, fundId: Id) => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    return CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/published-ruleset-name/`,
        'get',
        {params: {...params, fund_id: fundId}}
    );
};

export const getCounterparties = async (filter: FiltersState, fundId: Id) => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    return CreditApiHelper.doRequest(
        `${API_RULE_MANAGER}/published-ruleset-counterparty/`,
        'get',
        {params: {...params, fund_id: fundId}}
    );
};

export const exportFile = async (checkedIds: Id[], checkedAll: boolean): Promise<any> => {
    return CreditApiHelper.doRequest(`${API_RULE_MANAGER}/unpublished-ruleset/export-file/`, 'get', {
            params: {
                checkedAll,
                ids: checkedIds,
            }
        }
    );
};

export const bulkUpload = async (files: FormData) => {
    try {
        const response = await CreditApiHelper.post(
            `${API_RULE_MANAGER}/unpublished-ruleset/import-file/`,
            files,
            'multipart/form-data'
        );

        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};
