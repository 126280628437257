import * as React from 'react';
import DeviceHelper from 'helpers/deviceHelper';

interface Props {
    handleScroll: (offset: number) => void;
    tableElement: HTMLElement | null;
}

class HorizontalScrollForTouchDevices extends React.PureComponent<Props> {
    isMoving: boolean = false;
    isHorizontalScroll: boolean = false;
    diffX: number;
    diffY: number;
    endX: number;
    endY: number;
    startX: number;
    startY: number;

    getCoordinate = (event: any, axis: string) => {
        return (event.originalEvent || event).changedTouches[0]['page' + axis];
    }

    onStart = (e: any) => {
        if (!this.isMoving) {
            this.isMoving = true;
            this.startX = this.getCoordinate(e, 'X');
            this.startY = this.getCoordinate(e, 'Y');
            this.diffX = 0;
            this.diffY = 0;
        }
    }

    onMove = (e: any) => {
        if (this.isMoving) {
            this.endX = this.getCoordinate(e, 'X');
            this.endY = this.getCoordinate(e, 'Y');
            this.diffX = this.endX - this.startX;
            this.diffY = this.endY - this.startY;
            this.startX = this.endX;
            this.startY = this.endY;
            if (!this.isHorizontalScroll) {
                if (Math.abs(this.diffX) > Math.abs(this.diffY)) {
                    this.isHorizontalScroll = true;
                }
            }

            if (this.isHorizontalScroll) {
                if (e.cancelable) {
                    e.preventDefault();
                    this.props.handleScroll(this.diffX * (-1));
                }
            }
        }
    }

    onEnd = (e: any) => {
        if (this.isMoving) {
            this.isMoving = false;
            if (this.isHorizontalScroll) {
                if (e.cancelable) {
                    e.preventDefault();
                    this.props.handleScroll(this.diffX * (-1));
                }
            }

            this.isHorizontalScroll = false;
        }
    }

    render() {
        return (null);
    }

    componentDidUpdate(prevProps: Props) {
        if (DeviceHelper.isTouchSupported()) {
            if (!prevProps.tableElement && this.props.tableElement
                && prevProps.tableElement !== this.props.tableElement
            ) {
                this.props.tableElement.addEventListener('touchstart', this.onStart, {passive: false});
                this.props.tableElement.addEventListener('touchmove', this.onMove, {passive: false});
                this.props.tableElement.addEventListener('touchend', this.onEnd, {passive: false});
                this.props.tableElement.addEventListener('touchcancel', this.onEnd, {passive: false});
            }
        }
    }

    componentWillUnmount() {
        if (DeviceHelper.isTouchSupported() && this.props.tableElement) {
            this.props.tableElement.removeEventListener('touchstart', this.onStart);
            this.props.tableElement.removeEventListener('touchmove', this.onMove);
            this.props.tableElement.removeEventListener('touchend', this.onEnd);
            this.props.tableElement.removeEventListener('touchcancel', this.onEnd);
        }
    }
}

export default HorizontalScrollForTouchDevices;