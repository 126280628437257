import {
    GET_FILTERS, SET_FILTERS, CALL_APPLY_FILTERS, CHANGE_SAVED_ORDERING
} from './actions';

export const getFilters = (filterName: string, payload: any) => ({
    type: GET_FILTERS,
    filterName, payload
});

export const setFilters = (filterName: string, payload: any) => ({
    type: SET_FILTERS,
    filterName, payload
});

export const callApplyFilters = (currentFilter: string, actionType: string, otherFilter: any = {}) => ({
    type: CALL_APPLY_FILTERS,
    currentFilter,
    actionType,
    otherFilter
});

export const changeSavedOrdering = (flag: boolean, value: string) => ({
    type: CHANGE_SAVED_ORDERING,
    flag, value
});