import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import * as classNames from 'classnames';

import { ButtonTypes } from './ButtonTypes';

const styles = require('./ButtonDanger.scss');
const ButtonDanger: React.FC<ButtonTypes> = (
    {children, className, inverse, size, disabled, handleClick, buttonLarge}
) => {
    const btnClass = classNames({
        'ui-btn': true,
        'ui-btn-lg': buttonLarge,
        [className]: !!className,
        [styles.button_danger]: !!styles.button_danger,
        [styles.button_danger_inverse]: inverse,
    });

    return (
        <Button
            className={btnClass}
            size={size}
            disabled={disabled}
            onClick={() => {
                return handleClick && handleClick();
            }}
        >
            {children}
        </Button>
    );
};

ButtonDanger.defaultProps = {
    buttonLarge: false,
    size: 'md',
    disabled: false,
};

export default ButtonDanger;
