import * as React from 'react';
import { connect } from 'react-redux';

import { AppState, Id } from 'store/types';
import { isModalOpen } from 'store/Modals/General/selectors';
import { closeModal } from 'store/Modals/General/actionCreators';
import { inviteAclUser, changeInvitingAclUser } from 'store/DataManager/actionCreators';
import { getInvitedAclUser } from 'store/DataManager/selectors';
import { ACL_ROLES, messages } from 'store/DataManager/constants';
import * as aclHelper from 'store/DataManager/aclHelper';
import { InvitedUser } from 'store/Shared/models/invitedUser';
import { getDatasetFundSelector } from 'store/DataManager/selectors';
import { FundModel } from 'store/Fund/models/fund';

import InviteUserModal from 'components/Shared/Modals/InviteUserModal';
import { AclRole } from '../../store/DataManager/types';

interface Props {
    isOpen: boolean;
    invitedAclUser: InvitedUser;
    fund: FundModel;
    closeModal: () => any;
    changeInvitingAclUser: (invitedUser: InvitedUser) => any;
    inviteAclUser: (fundId: Id, invitedUser: InvitedUser) => any;
}

export const name = 'DATA_MANAGER_INVITE_ACL_USER_MODAL';

class InviteUserModalContainer extends React.PureComponent<Props> {
    handleChange = (field: string, value: string) => {
        this.props.changeInvitingAclUser({
            ...this.props.invitedAclUser,
            [field]: value
        });
    }

    handleSave = () => {
        const {fund, invitedAclUser} = this.props;
        this.props.inviteAclUser(fund.id, invitedAclUser);
    }

    render() {
        const {fund, isOpen, closeModal: handleCloseModal, invitedAclUser} = this.props;

        return (
            <InviteUserModal
                isOpen={isOpen}
                title={messages.INVITE_ACL_USER_TITLE}
                toggle={handleCloseModal}
                invitedUser={invitedAclUser}
                change={this.handleChange}
                save={this.handleSave}
                roles={ACL_ROLES}
                roleIsPossible={aclHelper.hasRole(fund, AclRole.Owner)}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    isOpen: isModalOpen(state, {name}),
    invitedAclUser: getInvitedAclUser(state),
    fund: getDatasetFundSelector(state),
});

const mapDispatchToProps = { closeModal, changeInvitingAclUser, inviteAclUser };

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserModalContainer);