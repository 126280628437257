import { Id } from 'store/types';
import { FiltersState } from 'store/Filters/types';

import {
    GET_FUND_EVALUATION_RESULTS, SET_FUND_EVALUATION_RESULTS,
    SET_FUND_STATISTIC, GET_ALERT_LIST, GET_ALERT_STATUS_ITEMS,
    SET_ALERT_LIST, SET_ALERT_LIST_WITH_SCROLL, SET_ALERT_STATUS_ITEMS,

    GetAlertStatusItemsAction, SetAlertStatusItemsAction, SET_FUND_EVALUATION_RESULTS_WITH_SCROLL, GET_FUND_STATISTIC
} from './actions';
import { FundEvaluationResults, AlertFund, AlertStatusItem } from './types';

export const getFundStatistic = (id: Id, period: string) => ({
    type: GET_FUND_STATISTIC,
    id,
    period,
});

export const setFundStatistic = (statistic: AlertFund) => ({
    type: SET_FUND_STATISTIC,
    statistic
});

export const setFundEvaluationResults = (results: FundEvaluationResults, withScroll = false) => {
    let answer = {
        type: SET_FUND_EVALUATION_RESULTS,
        results
    };

    if (withScroll) {
        answer = {
            ...answer,
            type: SET_FUND_EVALUATION_RESULTS_WITH_SCROLL,
        };
    }

    return answer;
};

export const getFundEvaluationResults = (id: Id, filter: FiltersState) => ({
    type: GET_FUND_EVALUATION_RESULTS,
    id, filter
});

export const getAlertList = (filter: FiltersState) => ({
    type: GET_ALERT_LIST,
    filter
});

export const setAlertList = (count: number, alertCount: number, list: AlertFund[], withScroll = false) => {
    let answer = {
        type: SET_ALERT_LIST,
        count,
        alertCount,
        list,
    };

    if (withScroll) {
        answer = {
            ...answer,
            type: SET_ALERT_LIST_WITH_SCROLL,
        };
    }

    return answer;
};

export const getAlertStatusItems = (period: string): GetAlertStatusItemsAction => ({
    type: GET_ALERT_STATUS_ITEMS,
    period
});

export const setAlertStatusItems = (data: AlertStatusItem[]): SetAlertStatusItemsAction => ({
    type: SET_ALERT_STATUS_ITEMS,
    data
});