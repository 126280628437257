import { FundStatistics, FundStatisticsDataset, StatisticsDataType, StatsValue } from '../types';
import * as moment from 'moment';
import { Moment } from 'moment';

class FundStatisticCalculator {
    count: number = 0;
    perfMonthPeriods: number[] = [];
    partialData: boolean = false;
    statistics: {
        return: StatsValue,
        volatility: StatsValue,
        drawdown: StatsValue,
        data_type: StatisticsDataType,
    };

    static getCumulativePeriodData(perfMonthPeriods: number[]): number[] {
        let prevValue = 1;
        return perfMonthPeriods.map(value => prevValue = prevValue * value);
    }

    setPerfMonthPeriods(fundStatistics: FundStatistics, startDate: Moment, endDate: Moment) {
        /**
         * Set perf month value of period by filtered dataset
         */
        this.statistics = {data_type: StatisticsDataType.NoData, ...this.statistics};

        const data = fundStatistics.datasets.history_datasets,
            filteredData = data.filter(
                (dataset: FundStatisticsDataset) =>
                    startDate.diff(dataset.period) < 0 && endDate.diff(dataset.period) > 0
            );

        let countEmptyDataset = 0,
            isPartialData = false;
        this.perfMonthPeriods = filteredData.map(
            (item: FundStatisticsDataset) => {
                if (item.is_empty) {
                    countEmptyDataset++;
                }

                isPartialData = isPartialData || (!item.perf_month || item.is_empty);

                return item.perf_month
                    ? parseFloat(item.perf_month) / 100 + 1
                    : 0;
            }
        );

        if (this.perfMonthPeriods.length) {
            if (isPartialData && countEmptyDataset !== this.perfMonthPeriods.length) {
                this.statistics.data_type = StatisticsDataType.Partial;
            } else if (!countEmptyDataset) {
                this.statistics.data_type = StatisticsDataType.AllData;
            }
        }
    }

    getReturn(): number {
        /** Calculate return value for fund */
        const count = this.perfMonthPeriods.length,
            exponent = 12 / count,
            calculationPerfMonth = this.perfMonthPeriods.reduce((result: number, item: number) => result * item, 1);

        return ((calculationPerfMonth ** exponent) - 1) * 100;
    }

    getVolatility(): number {
        /** Calculate volatility value for fund */
        const count = this.perfMonthPeriods.length,
            averageValue = this.perfMonthPeriods.reduce((result: number, item: number) => result + item, 0) / count,
            standardDeviation = values => Math.sqrt(
                values
                    .map((value: number) => (value - averageValue) ** 2)
                    .reduce((result: number, item: number) => result + item)
                / (count - 1)
            );

        return (standardDeviation(this.perfMonthPeriods) * Math.sqrt(12)) * 100;
    }

    getDrawdown(): number {
        /** Calculate drawdown value for fund */
        let drawdown = 0;

        const cumReturnPeriodData = FundStatisticCalculator.getCumulativePeriodData(this.perfMonthPeriods);

        const maxCumReturnPeriod = Math.max(...cumReturnPeriodData),
            maxIndex = cumReturnPeriodData.indexOf(maxCumReturnPeriod);

        if (maxIndex !== (this.perfMonthPeriods.length - 1)) {
            const filteredCumReturnPeriodData = cumReturnPeriodData.filter((value: number, i) => i >= maxIndex),
                minCumReturnPeriod = Math.min(...filteredCumReturnPeriodData);

            if (minCumReturnPeriod === 0) {
                return 0;
            }

            drawdown = (minCumReturnPeriod / maxCumReturnPeriod - 1) * 100;
        }

        return drawdown;
    }

    getStatistics(fundStatistics: FundStatistics, firstPeriod: number = 0, lastPeriod: number = 0) {
        /**
         * @param firstPeriod timestamp with milliseconds
         * @param lastPeriod timestamp with milliseconds
         */
        let firstPeriodValue = moment.unix(firstPeriod),
            lastPeriodValue = moment.unix(lastPeriod);

        this.setPerfMonthPeriods(fundStatistics, firstPeriodValue, lastPeriodValue);

        if (this.statistics.data_type === StatisticsDataType.AllData) {
            this.statistics = {
                ...this.statistics,
                return: this.getReturn(),
                volatility: this.getVolatility(),
                drawdown: this.getDrawdown(),
            };
        }

        return this.statistics;
    }
}

export default FundStatisticCalculator;