import * as React from 'react';

import { Form, FormGroup, Label, Col, Input } from 'reactstrap';

import Checkbox from 'components/Shared/Ui/Checkbox';
import ConfirmModal from 'components/Shared/Modals/ConfirmModal';
import ActiveUser from 'store/Auth/models/activeUser';

interface Props {
    user: ActiveUser;
    agreed: boolean;
    toggleAgreed: () => void;
    password: string;
    changePassword: (password: string) => void;
    upgradeAdhocAccount: () => any;
    isOpen: boolean;
    closeModal: () => any;
    termsLink: string;
}

const styles = require('./UpgradeAdhocAccountModal.scss');

class UpgradeAdhocAccountModal extends React.PureComponent<Props> {
    render() {
        const {user, agreed, password, isOpen, closeModal, upgradeAdhocAccount} = this.props;

        return (
            <ConfirmModal
                isOpen={isOpen}
                title="Create Account"
                body={this.renderBody()}
                toggle={closeModal}
                chooseYes={upgradeAdhocAccount}
                chooseNo={closeModal}
                yesTitle="Send Activation Link"
                noTitle="Cancel"
                yesDisabled={!agreed || (user.is_internal && password === '')}
            />
        );
    }

    renderBody() {
        const {user, agreed, password, toggleAgreed, changePassword, termsLink} = this.props;
        return (
            <div className={styles.wrapper}>
                <div className={styles.textLine}>
                    Creating an account allows you to access your account with an email and password,
                    increasing security and avoiding access expiry that occurs with link-based access.
                </div>
                <div className={styles.textLine}>
                    Your account will be linked to the email address: <strong>{user.email}</strong>
                </div>

                {!user.is_internal &&
                    <div className={styles.textLine}>
                        You will receive an email with a link to set your password once account creation is complete.
                    </div>
                }

                {user.is_internal &&
                    <div className={styles.textLine}>
                        <Form>
                            <FormGroup row={true}>
                                <Label for="new-password" sm={2}>Password</Label>
                                <Col sm={10}>
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={event => changePassword(event.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                }

                <div className={styles.textLine}>
                    <Checkbox
                        idx={'upgrade-agreed'}
                        checked={agreed}
                        handleChange={toggleAgreed}
                        className={styles.checkboxWrapper}
                    >
                        I have read and agreed to the <a href={termsLink} target="_bank">Terms of Service</a>.
                    </Checkbox>
                </div>
            </div>
        );
    }
}

export default UpgradeAdhocAccountModal;