import { DiligenceAuthState } from './types';
import * as authHelper from 'helpers/diligence/authHelper';

import {
    GET_USER, SET_USER,
    AuthAction, SetUserAction, LOGOUT, LOGIN, SET_TFA_SECRET,
    SET_TWO_FACTOR_AUTH_SETTINGS, SetTFASecretAction, SetTwoFactorAuthSettingsAction
} from './actions';
import { initialState } from './constants';

export const diligenceAuthReducer = (state: DiligenceAuthState = initialState, action: AuthAction) => {
    switch (action.type) {
        case LOGIN:
            authHelper.redirectToLogin();
            return state;
        case LOGOUT:
            return initialState;
        case GET_USER:
            return state;
        case SET_USER:
            return {
                ...state,
                user: (<SetUserAction> action).user,
                isInitialized: true
            };
        case SET_TFA_SECRET:
            return {
                ...state,
                secret: (<SetTFASecretAction> action).secret,
                qrURI: (<SetTFASecretAction> action).qrURI
            };
        case SET_TWO_FACTOR_AUTH_SETTINGS:
            return {
                ...state,
                user: {
                    ...(<SetUserAction> action).user,
                    twoFactorAuthSettings: (<SetTwoFactorAuthSettingsAction> action).data
                }
            };
        default:
            return state;
    }
};