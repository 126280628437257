import { FilterNames, FiltersState } from './types';
import {
    CLEAR_CHECKED_IDS, ClearCheckedIdsAction, SET_CHECKED_IDS, SET_IS_LOADING, SetCheckedIdsAction,
    SetFiltersAction, SetIsLoadingAction,
} from './actions';
import { cloneDeep } from 'lodash';

import {
    SET_FILTERS,
    FiltersAction
} from './actions';
import {
    DEFAULT_FILTER,
    DEFAULT_PORTFOLIO_LIST_FILTER,
    DEFAULT_PORTFOLIO_FUNDS_FILTER,
    DEFAULT_FUNDS_LIST_FILTER,
    DEFAULT_UPLOAD_RESULTS_FILTER,
    DEFAULT_FIRMS_LIST_FILTER,
    DefaultStateDatasetDetail,
    DefaultStatePublishedDatasets,
    DefaultUsersList,
    DefaultStateDatasetsTab,
    DEFAULT_REQUEST_FUNDS_RESULTS_FILTER,
    DefaultStateDashboardAttributeList,
    DEFAULT_INTERNAL_DATA_FILTER,
    DefaultStateRulesetList,
    DefaultStateRulesetRequestorTarget,
    DefaultStateRulesetResponderTarget,
    DefaultStateAlertList,
    DefaultStateFundAlertsView,
    DefaultStateContactList,
    DefaultStateFirmFundsList,
    DefaultStateRequestorAddItemsModalFundList,
    DefaultStateResponderAddItemsModalFundList,
    DefaultStateProfilesGroupList,
    DefaultStateProfilesGroupTargetSection,
    DefaultStateRequestorAddItemsModalPeerGroupList, DefaultStateProfilesGroupBenchmarks,
} from 'store/constants';
import { DefaultDashboardDispersionFilterState, DefaultDashboardFilterState } from '../Dashboard/constants';

const initialState: FiltersState = {
    isLoading: false,
    [FilterNames.portfolioList]: {
        ...DEFAULT_PORTFOLIO_LIST_FILTER,
    },
    [FilterNames.portfolioDetail]: {
        ...DEFAULT_PORTFOLIO_FUNDS_FILTER,
    },
    [FilterNames.firmList]: {
        ...DEFAULT_FIRMS_LIST_FILTER,
    },
    [FilterNames.fundList]: {
        ...DEFAULT_FUNDS_LIST_FILTER,
    },
    [FilterNames.fundNew]: {
        ...DEFAULT_FILTER,
    },
    [FilterNames.firmFundsList]: {...cloneDeep(DefaultStateFirmFundsList.filter)},
    [FilterNames.firmProfileFunds]: {
        ...DEFAULT_FILTER,
    },
    [FilterNames.portfolioUploadedRows]: {
        ...DEFAULT_UPLOAD_RESULTS_FILTER,
    },
    [FilterNames.portfolioUploadedHistory]: {
        ...DEFAULT_FILTER,
    },
    [FilterNames.requestsList]: {
        ...DEFAULT_REQUEST_FUNDS_RESULTS_FILTER
    },
    [FilterNames.datasetData]: {
        ...DefaultStateDatasetDetail.filter
    },
    [FilterNames.requestManagerScreen]: {
        ...DEFAULT_FILTER
    },
    [FilterNames.datasetList]: {
        ...DefaultStateDatasetDetail.filter
    },
    [FilterNames.datasetsTab]: {
        ...DefaultStateDatasetsTab.filter
    },
    [FilterNames.publishedDatasets]: {
        ...DefaultStatePublishedDatasets.filter
    },
    [FilterNames.usersList]: {
        ...DefaultUsersList.filter
    },
    [FilterNames.requestorDashboard]: {
        ...DefaultDashboardFilterState.filter,
    },
    [FilterNames.requestorDashboardDispersion]: {
        ...DefaultDashboardDispersionFilterState.filter,
    },
    [FilterNames.internalDataTab]: DEFAULT_INTERNAL_DATA_FILTER,
    [FilterNames.rulesetList]: {...DefaultStateRulesetList.filter},
    [FilterNames.rulesetRequestorTargetFundList]: {...DefaultStateRulesetRequestorTarget.filter},
    [FilterNames.rulesetResponderTargetFundList]: {...DefaultStateRulesetResponderTarget.filter},
    [FilterNames.alertList]: {...DefaultStateAlertList.filter},
    [FilterNames.alertView]: {...cloneDeep(DefaultStateFundAlertsView.filter)},
    [FilterNames.contactList]: {...cloneDeep(DefaultStateContactList.filter)},
    [FilterNames.requestorAddItemsModalFundList]: {...cloneDeep(DefaultStateRequestorAddItemsModalFundList.filter)},
    [FilterNames.responderAddItemsModalFundList]: {...cloneDeep(DefaultStateResponderAddItemsModalFundList.filter)},
    [FilterNames.requestorAddItemsModalPeerGroupsList]: {
        ...cloneDeep(DefaultStateRequestorAddItemsModalPeerGroupList.filter)
    },
    [FilterNames.profilesGroupList]: {...cloneDeep(DefaultStateProfilesGroupList.filter)},
    [FilterNames.profilesGroupTargetSection]: {...cloneDeep(DefaultStateProfilesGroupTargetSection.filter)},
    [FilterNames.profilesGroupBenchmarks]: {...cloneDeep(DefaultStateProfilesGroupBenchmarks.filter)},
};

export const filtersReducer = (state: FiltersState = initialState, action: FiltersAction) => {
    switch (action.type) {
        case SET_IS_LOADING: {
            return {
                ...state,
                isLoading: (<SetIsLoadingAction> action).isLoading
            };
        }
        case SET_FILTERS: {
            return {
                ...state,
                [(<SetFiltersAction> action).filterName]: (<SetFiltersAction> action).payload
            };
        }
        case CLEAR_CHECKED_IDS: {
            const {filterName} = (<ClearCheckedIdsAction> action);
            return {
                ...state,
                [filterName]: {
                    ...state[filterName],
                    checkedIds: [],
                    checkedAll: false
                }
            };
        }
        case SET_CHECKED_IDS: {
            const {filterName, filter} = (<SetCheckedIdsAction> action);
            return {
                ...state,
                [filterName]: {
                    ...state[filterName],
                    checkedIds: [
                        ...filter.checkedIds
                    ],
                    checkedAll: filter.checkedAll
                }
            };
        }
        default:
            return state;
    }
};
