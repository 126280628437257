import * as React from 'react';
import User from 'store/Auth/models/user';

interface Props {
    user: User;
}

const UserName: React.FC<Props> = ({user}) => (
    <a href={`mailto:${user.email}`}>{user.email}</a>
);

export default UserName;
