import {
    APPLY_FILTER,
    CANCEL_UPLOAD,
    CHANGE_NEW_PORTFOLIO_FIELD,
    CHECK_IMPORT_PROGRESS,
    CREATE_NEW_PORTFOLIO,
    CREATE_IMPORT_ENTITIES,
    CLEAR_ADD_PORTFOLIO_MODAL,
    DELETE_UPLOADED_ROWS,
    DELETE_PORTFOLIOS,
    DOWNLOAD_FUNDS,
    GET_NOT_FOUND_SOLUTIONS,
    GET_PORTFOLIO,
    GET_UPLOADED_ROWS,
    HIDE_BOTTOM_AREA,
    IMPORT_FILES,
    REPROCESS_UPLOAD,
    SET_NOT_FOUND_SOLUTIONS,
    SET_PORTFOLIO,
    SET_PORTFOLIOS,
    SET_PORTFOLIOS_WITH_SCROLL,
    SET_UPLOADED_STATS,
    SET_UPLOADED_ROWS,
    SET_UPLOADED_ROWS_WITH_SCROLL,
    UPDATE_BULK_UPLOAD_ROW,
    UPDATE_DESCRIPTION,
    UPDATE_REPROCESSED_ROW,
    GET_UNCOMPLETED_PORTFOLIO_IMPORT,
    SET_UNCOMPLETED_PORTFOLIO_IMPORT,
} from './actions';

import { PortfolioModel, NewPortfolioModel } from './models/portfolio';
import { Id } from '../types';

export const applyFilter = (filter: any) => {
    return {
        type: APPLY_FILTER,
        filter
    };

};

export const cancelUpload = (payload: any) => ({
    type: CANCEL_UPLOAD,
    payload,
});

export const setPortfolios = (count: number, portfolios: PortfolioModel[], withScroll = false) => ({
    type: withScroll
        ? SET_PORTFOLIOS_WITH_SCROLL
        : SET_PORTFOLIOS,
    count,
    portfolios,
});

export const deletePortfolios = (checkedIds: Id[], checkedAll: boolean) => ({
    type: DELETE_PORTFOLIOS,
    checkedIds,
    checkedAll,
});

export const changeEditingPortfolio = (fieldName: string, fieldValue: string) => ({
    type: CHANGE_NEW_PORTFOLIO_FIELD,
    fieldName,
    fieldValue,
});

export const changeNewPortfolio = (fieldName: string, fieldValue: string) => ({
    type: CHANGE_NEW_PORTFOLIO_FIELD,
    fieldName,
    fieldValue,
});

export const checkImportProgress = (payload: any) => ({
    type: CHECK_IMPORT_PROGRESS,
    payload,
});

export const updatePortfolio = (fieldName: string, fieldValue: string) => ({
    type: CHANGE_NEW_PORTFOLIO_FIELD,
    fieldName,
    fieldValue,
});

export const createPortfolio = (newPortfolio: NewPortfolioModel) => ({
    type: CREATE_NEW_PORTFOLIO,
    newPortfolio,
});

export const downloadFunds = (checkedIds: Id[], checkedAll: boolean, portfolioId: Id) => ({
    type: DOWNLOAD_FUNDS,
    checkedIds,
    checkedAll,
    portfolioId,
});

export const getPortfolio = (id: Id) => ({
    type: GET_PORTFOLIO,
    id,
});

export const setPortfolio = (portfolio: PortfolioModel) => ({
    type: SET_PORTFOLIO,
    portfolio,
});

export const importFiles = (files: any, id: Id) => ({
    type: IMPORT_FILES,
    files,
    id,
});

export const getUploadedRows = (filter: any) => ({
    type: GET_UPLOADED_ROWS,
    filter
});

export const setUploadedStats = (payload: any) => ({
    type: SET_UPLOADED_STATS,
    payload
});

export const setUploadedRows = (payload: any, withScroll = false) => ({
    type: withScroll
        ? SET_UPLOADED_ROWS_WITH_SCROLL
        : SET_UPLOADED_ROWS,
    payload,
});

export const getNotFoundSolutions = (
    payload: any,
    status: string,
    fileId: Id,
    rowId: Id,
) => ({
    type: GET_NOT_FOUND_SOLUTIONS,
    payload,
    status,
    fileId,
    rowId,
});

export const setNotFoundSolutions = (payload: any) => ({
    type: SET_NOT_FOUND_SOLUTIONS,
    payload
});

export const createImportEntities = (id: Id, portfolioId: Id) => ({
    type: CREATE_IMPORT_ENTITIES,
    id,
    portfolioId,
});

export const updateBulkUploadRow = (payload: any) => ({
    type: UPDATE_BULK_UPLOAD_ROW,
    payload
});

export const deleteUploadedRows = (payload: any, fileId: Id) => ({
    type: DELETE_UPLOADED_ROWS,
    payload,
    fileId
});

export const updateDescription = (payload: any) => ({
    type: UPDATE_DESCRIPTION,
    payload
});

export const reprocessUpload = (fileId: Id, rowId: Id) => ({
    type: REPROCESS_UPLOAD,
    fileId,
    rowId,
});

export const updateReprocessedRow = (payload: any) => ({
    type: UPDATE_REPROCESSED_ROW,
    payload
});

export const hideBottomArea = (flag: boolean) => ({
    type: HIDE_BOTTOM_AREA,
    flag
});

export const clearAddPortfolioModal = () => ({
    type: CLEAR_ADD_PORTFOLIO_MODAL,
});

export const getUncompletedPortfolioImport = () => ({
    type: GET_UNCOMPLETED_PORTFOLIO_IMPORT,
});

export const setUncompletedPortfolioImport = (hasImport: boolean, portfolioId: Id, importId: Id) => ({
    type: SET_UNCOMPLETED_PORTFOLIO_IMPORT,
    hasImport,
    portfolioId,
    importId,
});