import * as React from 'react';
import * as moment from 'moment';

import { DATE_FORMAT } from 'store/constants';
import UiSelect, { OptionItem } from 'components/Shared/Ui/Select';
import { AclUser } from 'store/DataManager/models/aclUser';
import { ACL_ROLES } from 'store/DataManager/constants';
import { AclRole } from 'store/DataManager/types';

interface Props {
    aclUser: AclUser;
    change: (role: string | null) => void;
}

const styles = require('./AclUserRoleEditor.scss');

const AclUserRoleEditor: React.FC<Props> = ({aclUser, change}) => {
    const createdAt = moment().format(DATE_FORMAT);
    return (
        <div className="d-flex">
            <div className="col-md-6 clear-padding">
                <span className="input-label">
                    <UiSelect
                        placeholder="Role"
                        value={aclUser.role}
                        handleChange={(option: OptionItem) => change(option && (option.value || null))}
                        options={ACL_ROLES}
                        clearable={false}
                    />
                </span>
            </div>
            <div className="col-md-6">
                {
                    aclUser.role === AclRole.NoAccess &&
                    (
                        <div className={styles.requestorDiv}>
                            requested by {aclUser.requested_by} at {createdAt}
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default AclUserRoleEditor;
