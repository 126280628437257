import { EvaluationResultStatus } from './types';
import { SelectItem } from '../../types';

export const initialState = {
    list: [],
    count: 0,
    alertCount: 0,
    alertStatusItems: [],
};

export const EvaluationResultStatusTitles = {
    [EvaluationResultStatus.Alert]: 'Alert',
    [EvaluationResultStatus.NoAlert]: 'No Alert',
    [EvaluationResultStatus.PartialData]: 'Partial Data',
    [EvaluationResultStatus.PartialDataAlert]: 'Partial Data Alert',
    [EvaluationResultStatus.NoRuleset]: 'No Ruleset',
    [EvaluationResultStatus.AwaitingData]: 'Awaiting Data',
};

export const EvaluationResultShortStatusTitles = {
    [EvaluationResultStatus.Alert]: 'Alert',
    [EvaluationResultStatus.NoAlert]: 'No Alert',
    [EvaluationResultStatus.PartialData]: 'Partial',
    [EvaluationResultStatus.PartialDataAlert]: 'Partial',
    [EvaluationResultStatus.NoRuleset]: '-',
    [EvaluationResultStatus.AwaitingData]: '-',
};

export const ALERT_STATUS = {
    [EvaluationResultStatus.Alert]: EvaluationResultStatusTitles[EvaluationResultStatus.Alert],
    [EvaluationResultStatus.PartialData]: EvaluationResultStatusTitles[EvaluationResultStatus.PartialData],
    [EvaluationResultStatus.NoAlert]: EvaluationResultStatusTitles[EvaluationResultStatus.NoAlert],
};

export const ALERT_STATUS_CHOICES: SelectItem[] = [
    {label: EvaluationResultStatusTitles[EvaluationResultStatus.Alert], value: EvaluationResultStatus.Alert},
    {
        label: EvaluationResultStatusTitles[EvaluationResultStatus.PartialData],
        value: EvaluationResultStatus.PartialData
    },
    {
        label: EvaluationResultStatusTitles[EvaluationResultStatus.NoAlert],
        value: EvaluationResultStatus.NoAlert
    },
];