import { Action } from 'redux';
import { ModalParams } from 'store/Modals/General/types';

export const OPEN = 'store/modals/open';
export const CLOSE = 'store/modals/close';

export interface OpenModalAction extends Action {
    name: string;
    params: ModalParams;
}

export interface CloseModalAction extends Action {

}

export type ModalsAction = OpenModalAction | CloseModalAction;