import * as React from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import { debounce } from 'lodash';
import { ScrollStatus } from 'smooth-scrollbar/interfaces';

import { FiltersState } from 'store/Filters/types';
import Checkbox from 'components/Shared/Ui/Checkbox';
import ButtonGroup from 'components/Shared/Ui/Buttons/ButtonGroup';
import ColoredScrollbar from 'components/Shared/Scroll/ColoredScrollbar';
import Input from '../Ui/Input';
import { FilterItem, SelectItem } from 'store/types';
import { DEFAULT_ASYNC_FILTER, DEFAULT_ITEMS_PER_PAGE, SEARCH_DEBOUNCE_INTERVAL } from 'store/constants';

const styles = require('./SelectWithCheckboxes.scss');

type SelectWithCheckboxesValue = SelectItem[] | string[];
interface SelectWithCheckboxesData {
    [key: string]: SelectWithCheckboxesValue;
}

interface Props {
    filter: FilterItem;
    value: SelectWithCheckboxesValue;
    handleChange: (data: SelectWithCheckboxesData) => void;
    handleRequest?: (data: FiltersState) => void;
    currentFilter?: FiltersState;
    pageName?: string;
    setFilters?: (filterName: string, filter: FiltersState, pageName?: string) => void;
    disabled?: boolean;
    leaveFiltersBeforeUnmount?: boolean;
    idPrefix?: string;
    isFilterValueObject?: boolean;
}

class SelectWithCheckboxes extends React.PureComponent<Props> {
    defaultFilter: FiltersState;
    debouncedHandleRequest = this.props.handleRequest
        ? debounce(this.props.handleRequest, SEARCH_DEBOUNCE_INTERVAL)
        : (data: FiltersState) => undefined;

    componentDidMount() {
        this.defaultFilter = this.props.filter.defaultFilter !== undefined
            ? this.props.filter.defaultFilter
            : DEFAULT_ASYNC_FILTER;
    }

    handleFilterOpen = () => {
        if (this.props.handleRequest) {
            this.props.handleRequest(this.defaultFilter);
        }
        if (this.props.setFilters && this.props.filter.filterName) {
            this.props.setFilters(
                this.props.filter.filterName,
                this.defaultFilter,
                this.props.pageName,
            );
        }
    }

    componentWillUnmount() {
        if (!this.props.leaveFiltersBeforeUnmount && this.props.currentFilter && this.props.currentFilter.search) {
            if (this.props.setFilters && this.props.handleRequest && this.props.filter.filterName) {
                this.props.setFilters(
                    this.props.filter.filterName,
                    this.defaultFilter,
                    this.props.pageName,
                );
                this.props.handleRequest(this.defaultFilter);
            }
        }
    }

    handleSearch = (text: string) => {
        if (this.props.setFilters && this.props.filter.filterName) {
            this.props.setFilters(
                this.props.filter.filterName,
                {...this.props.currentFilter, search: text, limit: DEFAULT_ITEMS_PER_PAGE, offset: 0},
                this.props.pageName,
            );
            this.debouncedHandleRequest({...this.defaultFilter, search: text});
        }
    }

    handleSorting = (val: string) => {
        if (this.props.setFilters && this.props.handleRequest && this.props.filter.filterName) {
            this.props.setFilters(
                this.props.filter.filterName,
                {...this.props.currentFilter, ordering: val},
                this.props.pageName,
            );
            this.props.handleRequest({...this.defaultFilter, ordering: val});
        }
    }

    handleScroll = (status: ScrollStatus) => {
        if (this.props.setFilters && this.props.handleRequest) {
            const limit = this.props.currentFilter ? this.props.currentFilter.limit : 0;
            const offset = this.props.currentFilter ? this.props.currentFilter.offset : 0;
            const sum = limit + offset;

            if (this.props.filter.choicesCount && sum < this.props.filter.choicesCount) {
                const bottom = status.limit.y === status.offset.y;
                if (bottom && this.props.filter.filterName) {
                    this.props.setFilters(
                        this.props.filter.filterName,
                        {...this.props.currentFilter, offset: sum},
                        this.props.pageName,
                    );
                    this.props.handleRequest({
                        ...this.props.currentFilter,
                        offset: sum,
                        scroller: true,
                    });
                }
            }
        }
    }

    render () {
        const { filter, value, handleChange, currentFilter } = this.props;

        const firstClickHandler = filter.choices && filter.choices.length ? (() => false) : this.handleFilterOpen;
        let choicesLabels: string[] = [];
        if (value && value.length > 0) {
            if (this.props.isFilterValueObject) {
                choicesLabels = (value as SelectItem[]).map((valueItem: SelectItem) => valueItem.label);
            } else {
                choicesLabels = (value as string[]).map((valueItem: string) => valueItem);
            }
        }

        const filterTitle: JSX.Element = choicesLabels && choicesLabels.length
            ? (<div className={styles.activeChoicesTitle}>{choicesLabels.join(', ')}</div>)
            : (<div>All</div>);

        return (
            <UncontrolledDropdown className={styles.filterWithCheckboxes}>
                <DropdownToggle
                    disabled={this.props.disabled}
                    className={styles.filterTitle}
                    onClick={firstClickHandler}
                >
                    <h5 className={styles.label}>{filter.title}</h5>
                    {filterTitle}
                    <i/>
                </DropdownToggle>
                <DropdownMenu>
                    <div className={styles.dropHeader}>
                        {
                            filter.withSortingButtons &&
                            <ButtonGroup
                                className={styles.sortButtons}
                                handleClick={this.handleSorting}
                                firstValue="name"
                                secondValue="-name"
                                firstTitle="Ascending"
                                secondTitle="Descending"
                                firstInverse={filter.firstInverse}
                                secondInverse={filter.secondInverse}
                            />
                        }
                        <Input
                            className={['form-control', styles.searchLine].join(' ')}
                            type="text"
                            value={currentFilter && currentFilter.search}
                            placeholder={'Search'}
                            handleChange={this.handleSearch}
                            autoFocus={true}
                        />
                        <div className={styles.selectChoicesList}>
                            <ColoredScrollbar
                                alwaysShowTracks={true}
                                className="select-with-checkboxes"
                                onScroll={this.handleScroll}
                            >
                                {
                                    filter && filter.choices &&
                                    filter.choices.map((item: SelectItem, idx: number) => {
                                        let checked = false;
                                        let result: SelectWithCheckboxesValue;
                                        if (this.props.isFilterValueObject) {
                                            result = value as SelectItem[];
                                            if (result) {
                                                checked = !!(result.find(
                                                    (valueItem: SelectItem) => item.value === valueItem.value
                                                ));
                                                result = checked
                                                    ? result.filter(
                                                        (valueItem: SelectItem) => item.value !== valueItem.value
                                                    )
                                                    : [...result, {label: item.label, value: String(item.value)}];
                                            }
                                        } else {
                                            result = value as string[];
                                            if (result) {
                                                checked = !!(result.find(
                                                        (valueItem: string) => item.value === valueItem)
                                                );
                                                result = checked
                                                    ? result.filter((valueItem: string) => item.value !== valueItem)
                                                    : [...result, String(item.value)];
                                            }
                                        }
                                        let uniqueKey = `${filter.name}-${item.value}-${idx}`;
                                        if (this.props.idPrefix) {
                                            uniqueKey = this.props.idPrefix + '-' + uniqueKey;
                                        }

                                        return (
                                            <div key={uniqueKey} className={styles['checkbox-wrapper']}>
                                                <Checkbox
                                                    idx={uniqueKey}
                                                    className="aligned-checkboxes"
                                                    checked={checked}
                                                    handleChange={
                                                        () => handleChange(
                                                            {[filter.name]: result}
                                                        )
                                                    }
                                                >
                                                    {item.label}
                                                </Checkbox>
                                            </div>
                                        );
                                    })
                                }
                            </ColoredScrollbar>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}

export default SelectWithCheckboxes;
