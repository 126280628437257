import ActiveUser from '../store/Auth/models/activeUser';
import { UserGlobalRole } from '../store/Auth/types';

export const redirectToLogin = () => {
    window.location.replace(`${process.env.REACT_APP_LOGIN_URL}`);
};

export const redirectToLogout = () => {
    window.location.replace(`${process.env.REACT_APP_LOGOUT_URL}`);
};

export const hasGlobalRole = (user: ActiveUser, role: UserGlobalRole): boolean => {
    if (user.hasOwnProperty('global_role') ) {
        return user.global_role === role;
    }
    return false;
};