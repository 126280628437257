import { initialState } from 'store/AdvancedFilters/constants';
import {
    AdvancedFiltersModalAction,
    UPDATE_FILTERS,
    UpdateFiltersAction
} from './actions';
import { AdvancedFiltersModalState } from 'store/AdvancedFilters/types';

export const advancedFiltersModalReducer = (
    state: AdvancedFiltersModalState = initialState,
    action: AdvancedFiltersModalAction
) => {
    switch (action.type) {
        case UPDATE_FILTERS:
            return {
                ...state,
                filterName: (<UpdateFiltersAction> action).filterName,
                currentFilter: (<UpdateFiltersAction> action).filter
            };
        default:
            return state;
    }
};
