import { createSelector } from 'reselect';

import { ApiHelper } from 'helpers/apiHelper';

import { deniedGetParams, FILTERS_WITH_CHIPS_ENABLED } from 'store/constants';
import { AppState, SelectItem } from 'store/types';
import { Chip, ChipSetting, FilterNames, FiltersState, ItemWithId } from 'store/Filters/types';
import { getMappedTitleByValue, instanceOfSelectItem } from 'store/Filters/helpers';
import { getUserAccountSettings } from 'store/Auth/selectors';

export const getFilters = (state: AppState, filterName: string): FiltersState => {
    if (state.filters && state.filters[filterName]) {
        return state.filters[filterName];
    }
    return {};
};

export const getFiltersOrUserSettings = (state: AppState, filterName: string): FiltersState => {
    if (state.filters && state.filters[filterName]) {
        return state.filters[filterName];
    }

    return getUserAccountSettings(state);
};

export const getPeriod = (state: AppState) => {
    const filters = getFilters(state, FilterNames.fundList);

    return filters.period;
};

export const getDataEntry = (state: AppState) => {
    const filters = getFilters(state, FilterNames.fundList);

    return filters.data_entry;
};

export const getChips = (state: AppState, filterName: string): Chip[] => {
    let chips: Chip[] = [];
    if (state.filters && state.filters[filterName]) {
        const filters = state.filters[filterName];
        Object.keys(filters).filter((filterKey: string) => {
            return filters.hasOwnProperty(filterKey)
                && FILTERS_WITH_CHIPS_ENABLED[filterName].find(
                    (chipSetting: ChipSetting) => (chipSetting.name === filterKey)
                );
        }).forEach((filterKey: string) => {
            const chipSetting: ChipSetting | undefined = FILTERS_WITH_CHIPS_ENABLED[filterName].find(
                (setting: ChipSetting) => (setting.name === filterKey)
            );
            const filterItemValue = filters[filterKey];
            if (Array.isArray(filterItemValue)) {
                filterItemValue.forEach((filterValue: SelectItem | string) => {
                    let chip: Chip = {
                        name: filterKey,
                        value: instanceOfSelectItem(filterValue) ? String(filterValue.value) : filterValue,
                    };
                    if (chipSetting && chipSetting.nameTitle) {
                        chip.nameTitle = chipSetting.nameTitle;
                    }
                    if (chipSetting && chipSetting.mapType && !instanceOfSelectItem(filterValue)) {
                        chip.valueTitle = getMappedTitleByValue(
                            filterValue,
                            chipSetting.mapType,
                        );
                    } else if (instanceOfSelectItem(filterValue)) {
                        chip.valueTitle = filterValue.label;
                    }
                    chips.push(chip);
                });
            } else if (filterItemValue) {
                let chip: Chip = {
                    name: filterKey,
                    value: filterItemValue,
                };
                if (chipSetting && chipSetting.nameTitle) {
                    chip.nameTitle = chipSetting.nameTitle;
                }
                if (chipSetting && chipSetting.mapType && !instanceOfSelectItem(filterItemValue)) {
                    chip.valueTitle = getMappedTitleByValue(
                        filterItemValue,
                        chipSetting.mapType,
                    );
                } else if (instanceOfSelectItem(filterItemValue)) {
                    chip.valueTitle = filterItemValue.label;
                }
                chips.push(chip);
            }
        });
    }
    return chips;
};

export const getIsLoading = (state: AppState): any => {
    if (state.filters) {
        return state.filters.isLoading;
    }
};

export const getCheckedIds = (state: AppState, filterName: string): any => {
    if (state.filters && state.filters[filterName].checkedIds) {
        return state.filters[filterName].checkedIds;
    }
    return {};
};

export const getCheckedIdsString = (state: AppState, filterName: string, parameterName: string = 'fund_id'): any => {
    if (
        state &&
        state.filters &&
        state.filters[filterName] &&
        state.filters[filterName].checkedIds
    ) {
        let checkedIds = state.filters[filterName].checkedIds;
        let checkedAll = state.filters[filterName].checkedAll;
        let stringToReturn = '';
        let filter = state.filters[filterName];
        const params = ApiHelper.getClearParams(filter, deniedGetParams);
        stringToReturn += Object.keys(params).reduce(
            (previousValue, key) => previousValue + `${key}=${filter[key]}&`,
            ''
        );

        let count = 0;
        let numberSelected = 0;
        if (
            filterName === FilterNames.portfolioDetail &&
            state.portfolios &&
            state.portfolios.portfolio &&
            state.portfolios.portfolio.number_of_funds
        ) {
            count = state.portfolios.portfolio.number_of_funds;
        } else if (
            filterName === FilterNames.datasetList &&
            state.dataManager &&
            state.dataManager.datasetListCount
        ) {
            count = state.dataManager.datasetListCount;
        } else if (
            filterName === FilterNames.publishedDatasets &&
            state.dataManager &&
            state.dataManager.publishedDatasetsCount
        ) {
            count = state.dataManager.publishedDatasetsCount;
        }

        if (checkedIds && count) {
            if (checkedAll) {
                if (checkedIds.length > 0) {
                    numberSelected = count - checkedIds.length;
                } else {
                    numberSelected = count;
                }
            } else {
                if (checkedIds.length > 0) {
                    numberSelected = checkedIds.length;
                }
            }
        }

        if (numberSelected < count) {
            checkedIds.forEach((id: number) => {
               stringToReturn += `${parameterName}=${id}&`;
            });
        } else {
            stringToReturn += `checkedAll=${!checkedAll}`;
        }

        return stringToReturn;
    }

    return '';
};

export const getCheckedItemsCount = (count: number) => createSelector(
    getFilters,
    (filters: FiltersState) => {
        if (filters.hasOwnProperty('checkedAll') && filters.hasOwnProperty('checkedIds')) {
            const {checkedAll, checkedIds} = filters;
            if (!checkedAll) {
                return checkedIds.length !== 0 ? checkedIds.length : 0;
            } else {
                return checkedIds.length ? count - checkedIds.length : count;
            }
        }
        return 0;
    }
);

// get explicit array of checked ids when checkAll means only items loaded on the page
export const getExplicitlyCheckedIds = (list: ItemWithId[]) => createSelector(
    getFilters,
    (filters: FiltersState) => {
        if (filters.hasOwnProperty('checkedAll') && filters.hasOwnProperty('checkedIds') && list) {
            const {checkedAll, checkedIds} = filters;
            if (!checkedAll) {
                return checkedIds.length !== 0
                    ? list
                        .filter((item: ItemWithId) =>
                            checkedIds.indexOf(item.id) >= 0
                        ).map((item: ItemWithId) => item.id)
                    : [];
            } else {
                return checkedIds.length
                    ? list
                        .filter((item: ItemWithId) =>
                            checkedIds.indexOf(item.id) === -1
                        ).map((item: ItemWithId) => item.id)
                    : list
                        .map((item: ItemWithId) => item.id);
            }
        }
        return [];
    }
);
