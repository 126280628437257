import * as React from 'react';
import * as classNames from 'classnames';

import { formatText } from 'helpers/tableHelper';

import { DatasetPeriod } from 'store/DataManager/models/dataset';
import { DEFAULT_VALUE_DASH, FormatTypeChoices } from 'store/constants';
import { HISTORY_COLORS } from 'store/DataManager/constants';
import { HistoryColor } from 'store/DataManager/types';

interface Props {
    periods: DatasetPeriod[];
}

const styles = require('./StatusCell.scss');

const ReportingHistoryCell: React.FC<Props> = ({periods}) => {
    let history: JSX.Element[] = [<span key="1">-</span>];
    if (periods && periods.length > 0) {
        history = periods.slice(0).map((period: DatasetPeriod, index: number) => {
            const status = period.status ? HISTORY_COLORS[period.status] : HISTORY_COLORS[HistoryColor.Grey];
            const dateClassName = classNames({
                [styles['dataset-period']]: true,
                [styles[status + '-history']]: true,
            });

            const date = formatText(period.period, FormatTypeChoices.DateSlashWithoutYear, DEFAULT_VALUE_DASH);
            return(
                <div key={index} className={dateClassName}>{date}</div>
            );
        });
    }

    return (
        <div>
            {history}
        </div>
    );
};

export default ReportingHistoryCell;
