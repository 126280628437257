import { UserAccount } from 'store/Auth/models/userAccount';
import AccountUsersFilter from './models/filter';
import { InvitedUser } from 'store/Shared/models/invitedUser';

export interface AccountState {
    usersCount: number;
    users: UserAccount[];
    editedUser?: UserAccount;
    invitedUser?: InvitedUser;
    filter: AccountUsersFilter;
}

export enum AccountType {
    Requestor = 'requestor',
    Responder = 'responder'
}

export enum AccountStatus {
    Test = 'test',
    Client = 'client',
    CounterParty = 'counterparty'
}