import { initialState } from './constants';
import { DiligenceDataManagerState } from './types';
import {
    DataManagerAction,
    SetFilterEntitiesAction,
    SET_FILTER_ENTITIES
} from './actions';

export const diligenceDataManagerReducer = (state: DiligenceDataManagerState = initialState,
                                            action: DataManagerAction) => {
    switch (action.type) {
        case SET_FILTER_ENTITIES:
            return {
                ...state,
                filterEntities: (<SetFilterEntitiesAction> action).filters
            };
        default:
            return state;
    }
};