import { FundModel } from '../Fund/models/fund';
import { AclRoleInheritance } from './constants';
import { AclRole } from './types';
import { DatasetFund } from './models/dataset';

export const hasRole = (fund: FundModel | DatasetFund, role: AclRole): boolean => {
    if (fund.hasOwnProperty('acl_user') && fund.acl_user) {
        return isRoleAncestor(fund.acl_user.role, role);
    }
    return false;
};

function isRoleAncestor(descendantRole: AclRole, ancestorRole: AclRole): boolean {
    if (descendantRole === ancestorRole) {
        return true;
    }

    const currentParentRole = AclRoleInheritance[descendantRole].parent;
    if (currentParentRole === ancestorRole) {
        return true;
    }

    if (currentParentRole === null) {
        return false;
    }

    return isRoleAncestor(currentParentRole, ancestorRole);
}