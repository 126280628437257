import { CLOSE, OPEN } from 'store/Modals/General/actions';
import { ModalParams } from 'store/Modals/General/types';

export const openModal = (name: string, params: ModalParams) => ({
    type: OPEN,
    name, params
});

export const closeModal = () => ({
    type: CLOSE
});