import * as React from 'react';

import UserButton from 'components/Auth/UserButton';
import UserCircle from 'components/Auth/UserCircle';
import Notifications from 'components/Auth/Notifications';
import CreateAccountButton from 'components/Auth/CreateAccountButton';
import ActiveUser from 'store/Auth/models/activeUser';
import { Id } from 'store/types';
import { isAdhoc, isInternal } from 'store/User/helpers';
import DiligenceActiveUser from 'store/Diligence/Auth/models/activeUser';

const styles = require('./Header.scss');

interface Props {
    user: ActiveUser;
    diligenceUser: DiligenceActiveUser;
    switchAccount: (id: Id) => any;
    initUpgradeAdhocAccount: () => any;
    logout: () => any;
}

const Header: React.FC<Props> = ({
    user, switchAccount, initUpgradeAdhocAccount, logout, diligenceUser
}) => {
    const currentUser = (isInternal(user) || isAdhoc(user)) ? user : diligenceUser;
    return (
        <div className={styles.header}>
            <UserButton user={user} switchAccount={switchAccount} logout={logout} diligenceUser={diligenceUser}/>
            <UserCircle user={currentUser}/>
            <Notifications />
            {isAdhoc(user) && <CreateAccountButton createAccount={initUpgradeAdhocAccount}/>}
        </div>
    );
};

export default Header;
