import * as React from 'react';
import * as moment from 'moment';

import { DEFAULT_DATE_FORMAT } from 'store/constants';
import { RangeEndings } from 'store/types';
import DateRangePicker from '../Ui/DateRangePicker';

interface Props {
    name: string;
    title?: string;
    value: any;
    rangeEndings: RangeEndings;
    start?: any;
    end?: any;
    displayFormat?: string;
    withLabel?: boolean;
    handleChange: (data: any) => void;
}

const styles = require('./DateFilter.scss');

class DateFilter extends React.PureComponent<Props> {

    static defaultProps = {
        withLabel: false
    };

    state = {
        startDate: null,
        endDate: null,
    };

    handleChange = (startDate?: any, endDate?: any ) => {
        const  [ start, end ] = [
            `${this.props.name}${this.props.rangeEndings.start}`,
            `${this.props.name}${this.props.rangeEndings.end}`
        ];
        const valueDate = {};
        if (startDate) {
            valueDate[start] = startDate.format(DEFAULT_DATE_FORMAT);
        } else {
            valueDate[start] = startDate;
        }

        if (endDate) {
            valueDate[end] = endDate.format(DEFAULT_DATE_FORMAT);
        } else {
            valueDate[end] = endDate;
        }

        this.setState({ startDate, endDate });

        this.props.handleChange({...valueDate});
    }

    render() {

        return (
            <div className={styles.date_filter}>
                {this.props.withLabel && <h5 className="label">{this.props.title || ''}</h5>}
                <DateRangePicker
                    displayFormat={this.props.displayFormat}
                    startDate={this.props.start ? moment(this.props.start) : null}
                    endDate={this.props.end ? moment(this.props.end) : null}
                    endTitle={this.props.title ? this.props.title + ' end' : ''}
                    startTitle={this.props.title ? this.props.title + ' start' : ''}
                    handleChange={this.handleChange}
                />
            </div>
        );
    }
}

export default DateFilter;
