export const COUNT_OF_VISIBLE_OWNERS_OFFICERS = 5;

export const initialFirmDetailsModel = {
    lei: [],
    sec: [],
    crd: [],
    address: '',
    website: '',
    employees: '',
    raum: '',
    accounts: '',
};

export const initialDisclosuresModel = {
    felony_conviction: '',
    felony_charge: '',
    misdemeanor_conviction: '',
    misdemeanor_charge: '',
    false_statement: '',
    sec_cftc_violation: '',
    regulatory_violation: '',
    registration_suspension: '',
};

export const initialFirmModel = {
    id: '',
    ap_id: '',
    name: '',
    alias: '',
    internal_id: '',
    owners_officers: []
};

export const initialDetailedFirmModel = {
    ...initialFirmModel,
    firm_details: {
        ...initialFirmDetailsModel,
    },
    disclosures: {
        ...initialDisclosuresModel,
    },
    funds: [],
    number_of_funds: 0,
};

export const initialState = {
    list: [],
    count: 0,
    filter: {
        page: 0
    },
    firm: {
        ...initialDetailedFirmModel,
    },
    funds: [],
    fundCount: 0,
};
