import { FiltersState } from 'store/Filters/types';
import { TargetFund } from 'store/Fund/types';

import {
    ADD_RULE,
    DELETE_RULE,
    CREATE_RULESET,
    DELETE_RULESET,
    GET_RULESET_LIST,
    INIT_RULESET_CREATION,
    INIT_RULESET_EDITING,
    SAVE_RULESET,
    SET_CREATED_RULESET,
    SET_EDITED_RULESET,
    SET_RULESET_LIST,
    SET_RULESET_LIST_WITH_SCROLL,
    UPDATE_RULE,
    GET_TARGET_FUND_LIST,
    SET_TARGET_FUND_LIST,
    SET_TARGET_FUND_LIST_WITH_SCROLL,
    UPDATE_RULESET_PROPERTY,
    UPDATE_RULESET_TARGET,
    PUBLISH_RULESET,
    SET_EDITED_RULESET_PARTIAL,
    UPDATE_RULE_VALIDATION,
    TOUCH_RULE_FIELD,
    TOUCH_ALL_RULE_FIELDS,
    ADD_RULE_VALIDATION,
    DELETE_RULE_VALIDATION,
    DISCRIMINATOR_UPDATE_RULE_ACTION,
    DISCRIMINATOR_DELETE_RULE_ACTION, DISCRIMINATOR_ADD_RULE_ACTION,
    DOWNLOAD_RULESET_LIST,
    BULK_UPLOAD, UPDATE_TARGET_SECTION_FILTER,
} from './actions';
import {
    Ruleset,
    Rule,
    RulesetPropertyType,
    RuleValidation,
    Target,
    RulesetListPermissions
} from './types';
import { Id } from '../../types';
import { History } from 'history';
import { defaultRulesetListPermissions } from './constants';

export const getRulesetList = (filter: FiltersState) => ({
    type: GET_RULESET_LIST,
    filter
});

export const setRulesetList = (
    count: number,
    list: Ruleset[],
    permissions: RulesetListPermissions = defaultRulesetListPermissions,
    withScroll = false
) => ({
    type: withScroll
        ? SET_RULESET_LIST_WITH_SCROLL
        : SET_RULESET_LIST,
    count,
    list,
    permissions
});

export const downloadRulesetList = (checkedIds: Id[], checkedAll: boolean) => ({
    type: DOWNLOAD_RULESET_LIST,
    checkedIds,
    checkedAll,
});

export const bulkUpload = (files: FormData) => ({
    type: BULK_UPLOAD,
    files
});

export const initRulesetCreation = () => ({
    type: INIT_RULESET_CREATION,
});

export const initRulesetEditing = (id: Id, active: boolean = true) => ({
    type: INIT_RULESET_EDITING,
    id, active
});

export const setEditedRuleset = (ruleset: Ruleset) => ({
    type: SET_EDITED_RULESET,
    ruleset
});

export const setEditedRulesetPartial = (ruleset: Partial<Ruleset>) => ({
    type: SET_EDITED_RULESET_PARTIAL,
    ruleset
});

export const setCreatedRuleset = (ruleset: Ruleset) => ({
    type: SET_CREATED_RULESET,
    ruleset
});

export const createRuleset = (ruleset: Ruleset) => ({
    type: CREATE_RULESET,
    ruleset
});

export const saveRuleset = (ruleset: Ruleset) => ({
    type: SAVE_RULESET,
    ruleset
});

export const deleteRuleset = (ruleset: Ruleset, history: History) => ({
    type: DELETE_RULESET,
    ruleset, history
});

export const publishRuleset = (ruleset: Ruleset) => ({
    type: PUBLISH_RULESET,
    ruleset,
});

export const addRule = () => ({
    type: ADD_RULE, discriminator: DISCRIMINATOR_ADD_RULE_ACTION
});

export const deleteRule = (index: number) => ({
    type: DELETE_RULE,
    index, discriminator: DISCRIMINATOR_DELETE_RULE_ACTION
});

export const updateRule = (index: number, rule: Rule, field: string) => ({
    type: UPDATE_RULE,
    index, rule, field, discriminator: DISCRIMINATOR_UPDATE_RULE_ACTION
});

export const updateRulesetProperty = (name: string, value: RulesetPropertyType) => ({
   type: UPDATE_RULESET_PROPERTY,
   name, value
});

export const updateRulesetTarget = () => ({
    type: UPDATE_RULESET_TARGET
});

export const getTargetFundList = (filter: FiltersState, entityType?: string, entityId?: Id) => ({
    type: GET_TARGET_FUND_LIST,
    filter, entityType, entityId
});

export const setTargetFundList = (count: number, firmCount: number, list: TargetFund[], withScroll = false) => ({
    type: withScroll
        ? SET_TARGET_FUND_LIST_WITH_SCROLL
        : SET_TARGET_FUND_LIST,
    count,
    firmCount,
    list,
});

export const updateRuleValidation = (index: number, validation: RuleValidation) => ({
    type: UPDATE_RULE_VALIDATION,
    index, validation
});

export const addRuleValidation = (validation: RuleValidation) => ({
    type: ADD_RULE_VALIDATION,
    validation
});

export const deleteRuleValidation = (index: number) => ({
    type: DELETE_RULE_VALIDATION,
    index
});

export const touchRuleField = (rule: Rule, index: number, field: string) => ({
    type: TOUCH_RULE_FIELD,
    rule, index, field
});

export const touchAllRuleFields = (rule: Rule, index: number) => ({
    type: TOUCH_ALL_RULE_FIELDS,
    rule, index
});

export const updateTargetSectionFilter = (
    target: Target,
    applyFilter: boolean,
    entityType?: string,
    entityId?: Id
) => ({
    type: UPDATE_TARGET_SECTION_FILTER,
    target, applyFilter, entityType, entityId
});
