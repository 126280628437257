import { put, all, takeLatest } from 'redux-saga/effects';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';

import {
    SUGGEST_FILTERS,
    SuggestFiltersAction
} from './actions';
import { suggestRequests } from 'services/diligence/request';
import {
    setFilterEntities
} from './actionCreators';

function* suggestDiligenceFilters(action: SuggestFiltersAction) {
    try {
        yield put(initAction(action.type));
        const filterEntities = yield suggestRequests(action.request);
        yield put(setFilterEntities(filterEntities));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchSuggestDiligenceRequests() {
    yield takeLatest(SUGGEST_FILTERS, suggestDiligenceFilters);
}

export default function* root() {
    yield all([
        watchSuggestDiligenceRequests(),
    ]);
}