import * as React from 'react';
import { connect } from 'react-redux';

import ProcessingMessage from 'components/Shared/ProgressBar/ProcessingMessage';

import { AppState, Id } from 'store/types';
import { BulkUploadProgress, UploadAction } from 'store/BulkUpload/types';
import { hideGlobalProgress } from 'store/BulkUpload/actionCreator';
import { setCancelImportExport } from 'store/BulkUpload/actionCreator';
import { BULK_ITEM_NAME_PLURAL, BULK_ITEM_NAME_PLURAL_DEFAULT } from 'store/BulkUpload/constants';
import { getUser } from 'store/Auth/selectors';
import ActiveUser from 'store/Auth/models/activeUser';
import { openModal } from 'store/Modals/General/actionCreators';
import { CONFIRM_CANCEL_MODAL } from 'store/constants';

interface Props {
    progress: BulkUploadProgress;
    user: ActiveUser;
    setCancelImportExport: (fileId: Id) => any;
    hideGlobalProgress: (id: Id) => any;
    openModal: (name: string, params: any) => any;
}

class ProcessingItem extends React.PureComponent<Props> {
    handleHide = () => {
        this.props.hideGlobalProgress(this.props.progress.id);
    }

    handleCancel = () => {
        const {progress} = this.props;
        this.props.setCancelImportExport(progress.id);
    }

    handleCloseClick = () => {
        if (this.props.user.id === this.props.progress.created_by) {
            this.props.openModal(
                CONFIRM_CANCEL_MODAL,
                {
                    cancel: this.handleCancel,
                    hide: this.handleHide,
                    action: this.props.progress.action,
                },
            );
        } else {
            this.handleHide();
        }
    }

    render () {
        const {progress} = this.props;
        const itemTitle = BULK_ITEM_NAME_PLURAL[progress.type]
            ? BULK_ITEM_NAME_PLURAL[progress.type]
            : BULK_ITEM_NAME_PLURAL_DEFAULT;
        const actionName = progress.action === UploadAction.IMPORT ? 'Uploading' : 'Downloading';
        return (
            <ProcessingMessage
                actionName={actionName}
                itemTitle={itemTitle}
                progress={progress}
                handleCloseClick={this.handleCloseClick}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    user: getUser(state),
});

const mapDispatchToProps = {
    hideGlobalProgress,
    setCancelImportExport,
    openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingItem);