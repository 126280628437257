import { Action } from 'redux';

import { PortfolioModel, NewPortfolioModel } from './models/portfolio';
import { Id } from '../types';

export const APPLY_FILTER = 'store/portfolio/applyFilter';
export const CANCEL_UPLOAD = 'store/portfolio/cancelUpload';
export const CHANGE_NEW_PORTFOLIO_FIELD = 'store/portfolio/changeNewPortfolioField';
export const CLEAR_ADD_PORTFOLIO_MODAL = 'store/portfolio/clearAddPortfolioModal';
export const CHECK_IMPORT_PROGRESS = 'store/portfolio/checkImportProgress';
export const CREATE_NEW_PORTFOLIO = 'store/portfolio/createNewPortfolio';
export const CREATE_IMPORT_ENTITIES = 'store/portfolio/createImportEntities';
export const DELETE_PORTFOLIOS = 'store/portfolio/deletePortfolios';
export const DELETE_UPLOADED_ROWS = 'store/portfolio/deleteUploadedRows';
export const DOWNLOAD_FUNDS = 'store/portfolio/downloadFunds';
export const GET_PORTFOLIO = 'store/portfolio/getPortfolio';
export const GET_NOT_FOUND_SOLUTIONS = 'store/portfolio/getNotFoundSolutions';
export const GET_UPLOADED_ROWS = 'store/portfolio/getUploadedRows';
export const HIDE_BOTTOM_AREA = 'store/portfolio/hideBottomArea';
export const IMPORT_FILES = 'store/portfolio/importFiles';
export const REPROCESS_UPLOAD = 'store/portfolio/reprocessUpload';
export const SET_NOT_FOUND_SOLUTIONS = 'store/portfolio/setNotFoundSolutions';
export const SET_PORTFOLIO = 'store/portfolio/setPortfolio';
export const SET_PORTFOLIOS = 'store/portfolio/setPortfolios';
export const SET_PORTFOLIOS_WITH_SCROLL = 'store/portfolio/setPortfoliosWithScroll';
export const SET_UPLOADED_STATS = 'store/portfolio/setUploadedStats';
export const SET_UPLOADED_ROWS = 'store/portfolio/setUploadedRows';
export const SET_UPLOADED_ROWS_WITH_SCROLL = 'store/portfolio/setUploadedRowsWithScroll';
export const UPDATE_BULK_UPLOAD_ROW = 'store/portfolio/updateBulkUploadRow';
export const UPDATE_DESCRIPTION = 'store/portfolio/updateDescription';
export const UPDATE_REPROCESSED_ROW = 'store/portfolio/updateReprocessedRow';
export const GET_UNCOMPLETED_PORTFOLIO_IMPORT = 'store/portfolio/getUncompletedPortfolioImport';
export const SET_UNCOMPLETED_PORTFOLIO_IMPORT = 'store/portfolio/setUncompletedPortfolioImport';

export interface ApplyFilterAction extends Action {
    filter: any;
}

export interface SetPortfoliosAction extends Action {
    count: number;
    portfolios: PortfolioModel[];
}

export interface DeletePortfoliosAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
}

export interface DeleteUploadedRowsAction extends Action {
    payload: any;
    fileId: Id;
}

export interface ChangeNewPortfolioFieldAction extends Action {
    fieldName: string;
    fieldValue: string;
}

export interface CreateNewPortfolioAction extends Action {
    newPortfolio: NewPortfolioModel;
}

export interface GetPortfolioAction extends Action {
    id: Id;
}

export interface SetPortfolioAction extends Action {
    portfolio: PortfolioModel;
}

export interface ImportFilesAction extends Action {
    files: any;
    id: Id;
}

export interface CancelUploadAction extends Action {
    payload: any;
}

export interface CheckImportProgressAction extends Action {
    payload: any;
}

export interface GetUploadedRowsAction extends Action {
    filter: any;
}

export interface SetUploadedRowsAction extends Action {
    payload: any;
    withScroll: any;
}

export interface SetUploadedStatsAction extends Action {
    payload: any;
}

export interface DownloadFileAction extends Action {
    id: Id;
}

export interface GetNotFoundSolutionsAction extends Action {
    payload: any;
    status: string;
    fileId: Id;
    rowId: Id;
}

export interface SetNotFoundSolutionsAction extends Action {
    payload: any;
}

export interface CreateImportEntitiesAction extends Action {
    id: Id;
    portfolioId: Id;
}

export interface UpdateBulkUploadRowAction extends Action {
    payload: any;
    fileId: Id;
}

export interface UpdateDescriptionAction extends Action {
    payload: any;
}

export interface ExportFundsAction extends Action {
    payload: any;
}

export interface DownloadFundsAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
    portfolioId: Id;
}

export interface ReprocessUploadAction extends Action {
    fileId: Id;
    rowId: Id;
}

export interface UpdateReprocessedRowAction extends Action {
    payload: any;
}

export interface HideBottomAreaAction extends Action {
    flag: boolean;
}

export interface GetUncompletedPortfolioImportAction extends Action {}

export interface SetUncompletedPortfolioImportAction extends Action {
    hasImport: boolean;
    portfolioId: Id;
    importId: Id;
}

export type PortfolioAction = ApplyFilterAction |
    CancelUploadAction |
    ChangeNewPortfolioFieldAction |
    CheckImportProgressAction |
    CreateImportEntitiesAction |
    CreateNewPortfolioAction |
    DeletePortfoliosAction |
    DeleteUploadedRowsAction |
    DownloadFileAction |
    ExportFundsAction |
    GetNotFoundSolutionsAction |
    GetPortfolioAction |
    GetUploadedRowsAction |
    ImportFilesAction |
    ReprocessUploadAction |
    SetNotFoundSolutionsAction |
    SetPortfolioAction |
    SetPortfoliosAction |
    SetUploadedRowsAction |
    SetUploadedStatsAction |
    UpdateBulkUploadRowAction |
    UpdateDescriptionAction |
    UpdateReprocessedRowAction |
    HideBottomAreaAction |
    SetUncompletedPortfolioImportAction |
    DownloadFundsAction;
