import { AccountState } from './types';
import {
    AccountAction, ApplyFilterAction, ChangeEditingUserAction,
    SetUsersAction, StartEditingUserAction,
    APPLY_FILTER, GET_USERS, SET_USERS, CHANGE_EDITING_USER, START_EDITING_USER, START_INVITING_USER,
    CHANGE_INVITING_USER, ChangeInvitingUserAction
} from './actions';
import { initialInvitedUser, initialState } from './constants';

export const accountReducer = (state: AccountState = initialState, action: AccountAction) => {
    switch (action.type) {
        case GET_USERS:
            return state;
        case SET_USERS:
            return {
                ...state,
                usersCount: (<SetUsersAction> action).count,
                users: (<SetUsersAction> action).users,
            };
        case APPLY_FILTER:
            return {
                ...state,
                filter: (<ApplyFilterAction> action).filter
            };
        case START_EDITING_USER:
            const userId = (<StartEditingUserAction> action).id;
            return {
                ...state,
                editedUser: state.users
                    .find(relation => !!relation.user && relation.user.id === userId)
            };
        case CHANGE_EDITING_USER:
            return {
                ...state,
                editedUser: {
                    ...(<ChangeEditingUserAction> action).userAccount
                }
            };
        case START_INVITING_USER:
            return {
                ...state,
                invitedUser: initialInvitedUser
            };
        case CHANGE_INVITING_USER:
            return {
                ...state,
                invitedUser: {
                    ...(<ChangeInvitingUserAction> action).invitedUser
                }
            };
        default:
            return state;
    }
};