import CreditApiHelper, { API_GROUP_MANAGER } from 'helpers/creditApiHelper';
import { ApiHelper } from 'helpers/apiHelper';

import { defaultDeniedGetParams, deniedPostParams } from 'store/constants';
import { Id } from 'store/types';
import { FiltersState } from 'store/Filters/types';
import { Group } from 'store/Profiles/Groups/types';

export const getGroups = async (filter: FiltersState): Promise<any> => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    const response = await CreditApiHelper.doRequest(
        `${API_GROUP_MANAGER}/`,
        'get',
        {params}
    );

    return Promise.resolve(response);
};

export const deleteGroups = async (ids: Id[], checkedAll: boolean, filter: FiltersState): Promise<any> => {
    let url = `${API_GROUP_MANAGER}/bulk-delete/`;
    const params = ApiHelper.getClearParams(filter, deniedPostParams);
    const body = {
        ids,
        checkedAll,
    };
    return CreditApiHelper.doRequest(url, 'post', body, '', true, params);
};

export const getPeerGroups = async (filter: FiltersState): Promise<any> => {
    const params = ApiHelper.getClearParams(filter, defaultDeniedGetParams);
    return CreditApiHelper.doRequest(
        `${API_GROUP_MANAGER}/peer-groups/`,
        'get',
        {
            params
        }
    );
};

export const getGroup = async (id: Id) => {
    const response = await CreditApiHelper.doRequest(`${API_GROUP_MANAGER}/${id}/`, 'get');
    return Promise.resolve(response);
};

export const save = async (group: Group) => {
    const method = group.group_id
        ? 'put'
        : 'post';

    const url = group.group_id
        ? `${API_GROUP_MANAGER}/${group.group_id}/`
        : `${API_GROUP_MANAGER}/`;

    const response = await CreditApiHelper.doRequest(url, method, group);
    return Promise.resolve(response);
};

export const publish = async (id: Id) => {
    return await CreditApiHelper.doRequest(
        `${API_GROUP_MANAGER}/${id}/publish/`,
        'post',
    );
};

export const getGroupByVersion = async (id: Id, version: number) => {
    const response = await CreditApiHelper.doRequest(
        `${API_GROUP_MANAGER}/published/${id}?version=${version}`,
        'get',
    );
    return Promise.resolve(response);
};
