import { createSelector } from 'reselect';

import { FormatTypeChoices } from 'store/constants';
import { formatText } from 'helpers/tableHelper';

import { getAlertsManagerState } from '../selectors';
import { AlertsManagerState } from '../types';
import { Ruleset, RulesetsState, Version as RulesetVersion } from './types';
import { defaultRulesetListPermissions, initialState } from './constants';

export const getRulesetsState = createSelector(
    getAlertsManagerState,
    (manager: AlertsManagerState): RulesetsState => manager.rulesets || initialState
);

export const getList = createSelector(
    getRulesetsState,
    (state: RulesetsState) => {
        return state.list.map(ruleset => {
            ruleset.number_of_rules = ruleset.rules ? ruleset.rules.length : 0;
            return ruleset;
        });
    }
);

export const getCount = createSelector(
    getRulesetsState,
    (state: RulesetsState) => state.count
);

export const getRulesetListPermissions = createSelector(
    getRulesetsState,
    (state: RulesetsState) => state.permissions || defaultRulesetListPermissions
);

export const getEdited = createSelector(
    getRulesetsState,
    (state: RulesetsState) => {
        const ruleset = state.edit;

        if (ruleset.versions) {
            ruleset.versions = ruleset.versions.map(version => {
                const info = version.id === ruleset.id
                    ? 'Current'
                    : formatText(version.date, FormatTypeChoices.DateFormat);
                const versionName = version.version
                    ? `Version ${version.version}`
                    : `Unpublished draft`;
                return {...version, label: versionName + (info ? ` (${info})` : '')};
            });
        }

        return ruleset;
    }
);

export const getEditedValidation = createSelector(
    getRulesetsState,
    (state: RulesetsState) => state.editValidation
);

export const getEditedVersion = createSelector(
    getEdited,
    (ruleset: Ruleset): RulesetVersion | null => {
        if (ruleset.versions) {
            return ruleset.versions.find(version => version.id === ruleset.id) || null;
        }

        return null;
    }
);

export const getCurrentVersionName = createSelector(
    getEditedVersion,
    (version: RulesetVersion | null): string => {
        return version ? `Version ${version.version}` : '';
    }
);

export const isEditedActive = createSelector(
    getEditedVersion,
    (version: RulesetVersion) => version ? version.current : true
);

export const getTargetFundListObject = createSelector(
    getRulesetsState,
    (state: RulesetsState) => state.targetFundList
);
