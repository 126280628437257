import { Action } from 'redux';

import UpdateProfileRequest from './models/updateProfileRequest';
import UpdatePasswordRequest from './models/updatePasswordRequest';

export const UPDATE_PROFILE = 'store/user/updateProfile';
export const UPDATE_PASSWORD = 'store/user/updatePassword';
export const UPGRADE_ADHOC_ACCOUNT = 'store/user/upgradeAdhocAccount';
export const INIT_UPGRADE_ADHOC_ACCOUNT = 'store/user/initUpgradeAdhocAccount';

export interface UpdateProfileAction extends Action {
    request: UpdateProfileRequest;
}

export interface UpdatePasswordAction extends Action {
    request: UpdatePasswordRequest;
}

export type UpgradeAdhocAccountAction = Action;

export type InitUpgradeAdhocAccountAction = Action;

export type UserAction = UpdateProfileAction | UpdatePasswordAction |
    InitUpgradeAdhocAccountAction |UpgradeAdhocAccountAction;