import * as React from 'react';

import { closeIcon } from 'store/constants';

interface Props {
    className?: string;
    toggle?: () => void;
}

const styles = require('./ModalHeader.scss');

const ModalHeader: React.FC<Props> = ({className, toggle, children}) => (
    <div className={`${className} ${styles.header} d-flex alig-item-start justify-content-between`}>
        <h5 className={styles.modalTitle}>
            {children}
        </h5>
        <span className={styles.closer}>
            <img
                src={closeIcon}
                className={styles.mainCloser}
                onClick={toggle}
            />
        </span>
    </div>
);

export default ModalHeader;
