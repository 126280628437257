import * as React from 'react';
import Input from '../Ui/Input';

interface Props {
    name: string;
    placeholder?: string;
    type?: 'string';
    withLabel?: boolean;
    value: any;
    handleChange: (data: any) => void;
}

// const styles = require('./InputFilter.scss');
const InputFilter: React.FC<Props> = ({ name, type, value, placeholder, withLabel, handleChange }) => (
    <div>
        {withLabel && <h5 className="label">{placeholder || ''}</h5>}
        <Input
            type={type}
            className="form-control"
            value={value}
            handleChange={(currentValue: any) => handleChange({[name]: currentValue})}
            placeholder={placeholder || ''}
        />
    </div>
);
InputFilter.defaultProps = {
    withLabel: false
};
export default InputFilter;
