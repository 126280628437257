import * as React from 'react';
import { RefObject } from 'react';
import { NavLink } from 'react-router-dom';

import GlobalHelper from 'helpers/globalHelper';
import FundHelper from 'helpers/fundHelper';

import AlertDefault from 'components/Shared/Ui/Alerts/AlertDefault';
import UiWrapper from 'components/Shared/Ui/Wrappers/BaseWrapper';
import { DropDownItem, default as DropDownPanel } from 'components/Shared/Ui/DropDowns/DropDownPanel';
import TextColor from 'components/Shared/Ui/TextColor';
import { headersInModal } from 'components/DataManager/DatasetDetailModals/NavConfigurationModal';

import { Id } from 'store/types';
import { HeaderWidthObject } from 'store/VisibleHeaders/types';
import { DatasetFund } from 'store/DataManager/models/dataset';

interface Props {
    fund: DatasetFund;
    currentFilter: any;
    togglePerformanceConfigModal: () => void;
    toggleNavConfigModal: () => void;
    headerFieldsWithWidth: HeaderWidthObject[];
    dropdownItemsCollection: DropDownItem[];
    isDownloadAccessible: boolean;
    isUploadAccessible: boolean;
    pendingRequestsCount: number;
    pendingNotificationsFlag: boolean;
    setPendingNotificationFlag: (fundId: Id, flag: boolean) => void;
}

const styles = require('../DataManager.scss');
const panelStyles = require('./DatasetPanel.scss');

class DatasetPanel extends React.PureComponent<Props>  {

    static defaultProps = {
        isDownloadAccessible: false,
        isUploadAccessible: false,
    };

    wrapperRef: RefObject<HTMLTableRowElement>;

    constructor(props: Props) {
        super(props);

        this.wrapperRef = React.createRef();
    }

    handleAlertCloseClick = () => {
        this.props.setPendingNotificationFlag(this.props.fund && this.props.fund.id ? this.props.fund.id : '', false);
    }

    render() {
        const { currentFilter, toggleNavConfigModal, togglePerformanceConfigModal, fund,
            headerFieldsWithWidth
        } = this.props;

        const cachedDStatus =
            GlobalHelper.getValueFromObject(fund, 'current_unpublished_dataset.cached_ds_status');

        const { currency } = currentFilter;
        let currentCurrency = FundHelper.getCurrencyLabel(FundHelper.getCurrencyItem(currency));

        let widthForLine = 0;
        headersInModal.map((headeritem: string) => {
            headerFieldsWithWidth.map((item: any) => {
                if (item.name === headeritem) {
                    if (item.width) {
                        widthForLine += item.width;
                    }
                }
            });
        });

        let styleForLeft: React.CSSProperties =  {};
        let styleForRight: React.CSSProperties =  {};

        if (this.wrapperWidth) {
            const widthForLeft = widthForLine / this.wrapperWidth * 100;
            styleForLeft = {
                marginLeft: '70px',
                width: `${widthForLeft}%`,
            };

            const widthForRight = (this.wrapperWidth - widthForLine) / this.wrapperWidth * 100;
            styleForRight = {
                width: `calc(${widthForRight}% - 70px)`,
            };
        }

        let status: string = 'not_yet_started';

        if (cachedDStatus.length) {
            status = cachedDStatus;
        }

        return (
            <>
                {
                    this.props.pendingNotificationsFlag && this.props.pendingRequestsCount > 0 &&
                    (
                        <div className={panelStyles.alertBlock}>
                            <AlertDefault
                                hasCloseIcon={true}
                                handleClick={this.handleAlertCloseClick}
                            >
                                {`You have ${this.props.pendingRequestsCount} new data ` +
                                `request${this.props.pendingRequestsCount > 1 ? 's' : ''} pending.`}
                                <NavLink to={`/funds/${fund.id}/requestors`} className={styles.pendingLink}>
                                    {`View pending data requests.`}
                                </NavLink>
                            </AlertDefault>
                        </div>
                    )
                }
                <UiWrapper withoutMargin={true} className="text-right">
                    <div className="ui-inlineBlock b-panel-center">
                        Status:&nbsp;
                        <TextColor type={status}>
                            {GlobalHelper.ucFirst(GlobalHelper.replaceElemInString(status))}
                        </TextColor>
                    </div>
                    <DropDownPanel
                        className="dataset-actions ui-inlineBlock"
                        items={this.props.dropdownItemsCollection}
                    >
                        Actions
                    </DropDownPanel>
                </UiWrapper>
                <UiWrapper forwardedRef={this.wrapperRef} withoutMargin={true}>
                    <div className={[styles.rowModified, 'row'].join(' ')}>
                        <div style={styleForLeft} className="navRow">
                            <div className="row">
                                <button className="clear-button" onClick={toggleNavConfigModal}>
                                    <span className="titleNav">NAV</span>
                                    <span className="CurrencyLabel">{currentCurrency}</span>
                                </button>
                            </div>
                        </div>
                        <div style={styleForRight} className="width-line-style">
                            <button className="clear-button" onClick={togglePerformanceConfigModal}>
                                <span className="titleNav">Performance (%)</span>
                            </button>
                        </div>
                    </div>
                </UiWrapper>
            </>
        );
    }

    private get wrapperWidth() {
        return this.wrapperRef.current ? this.wrapperRef.current.clientWidth : 0;
    }
}

export default DatasetPanel;