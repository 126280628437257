import { Action } from 'redux';

import { FiltersState } from 'store/Filters/types';
import { PeerGroup, TargetFund } from 'store/Fund/types';

export const SET_FUND_LIST = 'store/shared/modals/addItems/setFundList';
export const SET_FUND_LIST_WITH_SCROLL = 'store/shared/modals/addItems/setFundList/withScroll';
export const SET_PEER_GROUP_LIST = 'store/shared/modals/addItems/setPeerGroupList';
export const SET_PEER_GROUP_LIST_WITH_SCROLL = 'store/shared/modals/addItems/setPeerGroupList/withScroll';

export interface GetFundListForAddItemsModalAction extends Action {
    filter: FiltersState;
}

export interface SetFundListForAddItemsModalAction extends Action {
    count: number;
    list: TargetFund[];
}

export interface GetPeerGroupListForAddItemsModalAction extends Action {
    filter: FiltersState;
}

export interface SetPeerGroupListForAddItemsModalAction extends Action {
    count: number;
    list: PeerGroup[];
}

export type AddItemsModalAction =
    GetFundListForAddItemsModalAction |
    GetPeerGroupListForAddItemsModalAction |
    SetFundListForAddItemsModalAction |
    SetPeerGroupListForAddItemsModalAction;