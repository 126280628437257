import * as React from 'react';

const styles = require('./GlobalProgressBar.scss');

interface Props {
}

class GlobalProgressBar extends React.PureComponent<Props> {
    render() {
        return (
            <div className={styles.fixedWrapper}>
                {this.props.children}
            </div>
        );
    }
}

export default GlobalProgressBar;