import { connect } from 'react-redux';

import { AppState, FilterItem } from 'store/types';
import { currencyItems } from 'store/constants';
import NavConfigurationModal from 'components/DataManager/DatasetDetailModals/NavConfigurationModal';
import { getFilters } from 'store/Filters/selectors';
import { FilterNames } from 'store/Filters/types';
import { getHeaders } from 'store/VisibleHeaders/selectors';
import { headerNames } from 'store/VisibleHeaders/types';
import { getDatasetDetailTemporaryFilter, getDatasetDetailTemporaryHeaders } from 'store/DataManager/selectors';
import { setDatasetDetailTemporaryFilter, setDatasetDetailTemporaryHeaders } from 'store/DataManager/actionCreators';
import { FilterType } from 'store/constants';

const styles = require('./ConfigurationModal.scss');
const filterName = FilterNames.datasetData;

export interface ChooseType {
    label: string;
    value: string;
    icon: string;
}

const filters: FilterItem[] = [
    {
        name: 'currency',
        classNamePrefix: 'material-select',
        className: 'col-md-6',
        innerClassName: styles['select-dropdown'],
        type: FilterType.Select,
        title: 'Currency',
        choices: currencyItems,
        withLabel: true, material: true,
        customMenuPortalClass: {
            zIndex: 9999,
            fontSize: '12',
        },
        clearable: true,
    },
];

const mapStateToProps = (state: AppState, ownProps: any) => ({
    temporaryFilter: getDatasetDetailTemporaryFilter(state),
    temporaryHeaders: getDatasetDetailTemporaryHeaders(state),
    currentFilter: getFilters(state, filterName),
    headers: getHeaders(state, {name: headerNames.datasetDetail}),
    filters: ownProps.isEditor ? filters : [],
});

const mapDispatchToProps = {
    setDatasetDetailTemporaryFilter,
    setDatasetDetailTemporaryHeaders,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavConfigurationModal);
