import * as React from 'react';
import { connect } from 'react-redux';

import ButtonDanger from 'components/Shared/Ui/Buttons/ButtonDanger';
import ButtonDefault from 'components/Shared/Ui/Buttons/ButtonDefault';
import Modal from 'components/Shared/Modals/Modal';

import { getModalParams, isModalOpen } from 'store/Modals/General/selectors';
import { closeModal } from 'store/Modals/General/actionCreators';
import { CONFIRM_CANCEL_MODAL } from 'store/constants';
import { AppState } from 'store/types';
import { UploadAction } from 'store/BulkUpload/types';

interface Props {
    isOpen: boolean;
    closeModal: () => any;
    params: {
        cancel: () => void;
        hide: () => void;
        action: string;
    };
}

class ConfirmCancelModal extends React.PureComponent<Props> {
    handleToggle = () => {
        this.props.closeModal();
    }

    handleYesClick = () => {
        this.props.params.cancel();
        this.props.params.hide();
        this.props.closeModal();
    }

    render() {
        const { isOpen, params } = this.props;
        const { action } = params;
        const actionName = action && UploadAction[action].toLowerCase();
        const body = (
            <p>
                Are you sure that you want to cancel a running {actionName}?
            </p>
        );
        const title = <>Confirm {actionName} cancel</>;
        const buttons = [
            (
                <ButtonDanger
                    buttonLarge={true}
                    handleClick={this.handleYesClick}
                >Yes, cancel {actionName}
                </ButtonDanger>),
            (
                <ButtonDefault buttonLarge={true} inverse={true} handleClick={this.handleToggle}>
                    No
                </ButtonDefault>)
        ];

        return (
            <Modal
                className="ui-modal--confirm"
                isOpen={isOpen}
                toggle={this.handleToggle}
                title={title}
                body={body}
                buttons={buttons}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    isOpen: isModalOpen(state, {name: CONFIRM_CANCEL_MODAL}),
    params: getModalParams(state) || [],
});

const mapDispatchToProps = {
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCancelModal);