import * as React from 'react';
import * as classNames from 'classnames';

import { formatText } from 'helpers/tableHelper';

import { DATASET_STATUS_TITLES } from 'store/DataManager/constants';
import { DatasetStatus } from 'store/DataManager/types';
import { DEFAULT_VALUE_DASH, FormatTypeChoices } from 'store/constants';

interface Props {
    status?: string;
    period?: string;
}

const styles = require('./StatusCell.scss');

const StatusCell: React.FC<Props> = ({status, period}) => {
    const datasetStatus = status ? status : DatasetStatus.NotYetStarted;
    const dateClassName = classNames({
        [styles['dataset-period']]: true,
        [styles[datasetStatus + '-current-period']]: true,
    });

    const statusClassName = classNames({
        [styles['dataset-status']]: true,
        [styles[datasetStatus]]: true,
    });

    const date = formatText(period, FormatTypeChoices.DateSlashWithoutYear, DEFAULT_VALUE_DASH);
    const statusValue = status ? DATASET_STATUS_TITLES[status] : DATASET_STATUS_TITLES[DatasetStatus.NotYetStarted];
    return (
        <>
            <div >
                <span className={dateClassName}>{date}</span>
                <span className={statusClassName}>{statusValue}</span>
            </div>
        </>
    );
};

export default StatusCell;
