import ActiveUser from './models/activeUser';
import {
    GET_USER,
    GET_DILIGENCE_USER,
    INITIALIZE_RESET_PASSWORD,
    LOGIN,
    LOGOUT,
    RESET_PASSWORD,
    SET_USER,
    SWITCH_ACCOUNT,
    CHANGE_USER_ACCOUNT_SETTINGS,
    UPDATE_USER_ACCOUNT_SETTINGS,
    UPDATE_ACCOUNT_SETTINGS,
    SET_ACCOUNT_SETTINGS
} from './actions';
import { UserAccountSettings } from './models/userAccount';
import { Id } from '../types';
import { AccountSettings } from 'store/Account/models/account';

export const getUser = () => ({
    type: GET_USER
});

export const setUser = (user: ActiveUser) => ({
    type: SET_USER,
    user
});

export const getDiligenceUser = () => ({
    type: GET_DILIGENCE_USER
});

export const switchAccount = (relationId: Id) => ({
    type: SWITCH_ACCOUNT,
    relationId
});

export const logout = () => ({
    type: LOGOUT
});

export const login = () => ({
    type: LOGIN
});

export const initializeResetPassword = (email: string) => ({
    type: INITIALIZE_RESET_PASSWORD,
    email
});

export const resetPassword = (password: string, token: string, resetKey: string) => ({
    type: RESET_PASSWORD,
    password, token, resetKey
});

export const changeUserAccountSettings = (userAccountSettings: UserAccountSettings) => ({
    type: CHANGE_USER_ACCOUNT_SETTINGS,
    userAccountSettings
});

export const updateUserAccountSettings = (
    userAccountSettings: UserAccountSettings,
    waitResponse: boolean = false
) => ({
    type: UPDATE_USER_ACCOUNT_SETTINGS,
    userAccountSettings, waitResponse
});

export const updateAccountSettings = (settings: AccountSettings) => ({
    type: UPDATE_ACCOUNT_SETTINGS,
    settings
});

export const setAccountSettings = (settings: AccountSettings) => ({
    type: SET_ACCOUNT_SETTINGS,
    settings
});
