import * as numeral from 'numeral';

import FundHelper from 'helpers/fundHelper';
import { FormatTypeChoices, NUMERAL_FORMATS } from 'store/constants';
import { DEFAULT_CURRENCY_VALUE, DEFAULT_VALUE_DASH } from 'store/constants';

class NumericHelper {

    static getFormattedNumeral(
        value: string | null,
        currency: string = DEFAULT_CURRENCY_VALUE,
        withCurrency: boolean = false,
        format: FormatTypeChoices = FormatTypeChoices.NumeralDefault,
        defaultValue: string = DEFAULT_VALUE_DASH,
    ) {
        let currencyIcon: string | null = '';
        if (withCurrency) {
            const currencyItem = FundHelper.getCurrencyItem(currency);
            currencyIcon = FundHelper.getCurrencyIcon(currencyItem);
        }

        const numeralValue = numeral(value);
        const numberValue = numeralValue.value();
        const positiveValue = Math.abs(numberValue);
        const signed = numberValue < 0;

        format = NUMERAL_FORMATS.hasOwnProperty(format) ? NUMERAL_FORMATS[format] : format;
        let stringValue = numeral(positiveValue).format(format);

        if (value && value.length === 0 || (!value && numberValue !== 0)) {
            stringValue = defaultValue;
        } else if (signed) {
            stringValue = `-${currencyIcon}${stringValue}`;
        } else {
            stringValue = `${currencyIcon}${stringValue}`;
        }

        return stringValue;
    }
}

export const getOrdinalPostfix = (num: number) => {
    const postfixes = ['th', 'st', 'nd', 'rd'];
    const remainder = num % 100;

    return (postfixes[(remainder - 20) % 10] || postfixes[remainder] || postfixes[0]);
};

// register custom locale to have ability use abbreviations, to reset locale use numeral.locale('en')
numeral.register('locale', 'ap', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T',
    },
    ordinal: getOrdinalPostfix,
    currency: {
        symbol: '$'
    }
});

numeral.locale('ap');

export default NumericHelper;
