import * as React from 'react';
import { notificationsIcon } from 'store/constants';

const styles = require('./Notifications.scss');

interface Props {}

const Notifications: React.FC<Props> = () => (
    <div>
        <div className={styles.notifications}>
            Notifications
        </div>
        <div className={styles.circleWrapper}>
            <div className={styles.circle} />
            <img src={notificationsIcon} />
        </div>
    </div>
);

export default Notifications;
