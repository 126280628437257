import { Action } from 'redux';

import { FiltersState } from '../Filters/types';
import { DonutPiece } from '../Shared/types';
import {
    BoxChartsDataForPeriod,
    ChartsDataForPeriod, DashboardChartType,
    DashboardGridItem, DispersionChartType,
    StatisticDonutDataType
} from './types';
import { UserAccountSettings } from '../Auth/models/userAccount';

export const APPLY_FILTER = 'store/dashboard/applyFilter';
export const APPLY_DISPERSION_FILTER = 'store/dashboard/applyDispersionFilter';
export const GET_DASHBOARD_DATA = 'store/dashboard/dashboard/getData';
export const SET_DASHBOARD_DATA = 'store/dashboard/dashboard/setData';
export const SET_DASHBOARD_DATA_WITH_SCROLL = 'store/dashboard/dashboard/setDataWithScroll';
export const GET_DASHBOARD_ATTRIBUTES = 'store/dashboard/getDashboardAttributes';
export const SET_DASHBOARD_ATTRIBUTES = 'store/dashboard/setDashboardAttributes';
export const SET_DASHBOARD_ATTRIBUTES_WITH_SCROLL = 'store/dashboard/setDashboardAttributesWithScroll';
export const GET_STATISTIC_DONUT_REPORT = 'store/dashboard/getStatisticDonutReport';
export const GET_PERFORMANCE_DONUT_REPORT = 'store/dashboard/getPerformanceDonutReport';
export const GET_ALERT_DONUT_REPORT = 'store/dashboard/getAlertDonutReport';
export const GET_CHART_DATA = 'store/dashboard/getChartData';
export const SET_CHART_DATA = 'store/dashboard/setChartData';
export const GET_BOX_CHART_DATA = 'store/dashboard/getBoxChartData';
export const SET_BOX_CHART_DATA = 'store/dashboard/setBoxChartData';
export const SET_STATISTIC_DONUT_REPORT = 'store/dashboard/setStatisticDonutReport';
export const UPDATE_DISPERSION_SETTINGS = 'store/dashboard/updateDispersionSettings';
export const UPDATE_DASHBOARD_SETTINGS = 'store/dashboard/updateDashboardSettings';

export interface ApplyFilterAction extends Action {
    filter: FiltersState;
}

export interface ApplyDispersionFilterAction extends Action {
    filter: FiltersState;
}

export interface GetDashboardDataAction extends Action {
    filter: FiltersState;
}

export interface SetDashboardDataAction extends Action {
    filter: FiltersState;
    rows: DashboardGridItem[];
    rowsCount: number;
    fundsCount: number;
    firmsCount: number;
}

export interface GetDashboardAttributesAction extends Action {
    attributeType: string;
    filter: FiltersState;
}

export interface SetDashboardAttributesAction extends Action {
    attributeType: string;
    count: number;
    results: any; // change to be the actual type
}

export interface GetStatisticDonutReportAction extends Action {
    filter: FiltersState;
    dataType: StatisticDonutDataType;
}

export interface GetPerformanceDonutReportAction extends Action {
    filter: FiltersState;
}

export interface GetAlertDonutReportAction extends Action {
    filter: FiltersState;
}

export interface SetStatisticDonutReportAction extends Action {
    dataType: StatisticDonutDataType;
    data: DonutPiece[];
}

export interface GetChartDataAction extends Action {
    filter: FiltersState;
    force: boolean;
}

export interface SetChartDataAction extends Action {
    data: ChartsDataForPeriod;
    force: boolean;
}

export interface GetBoxChartDataAction extends Action {
    filter: FiltersState;
}

export interface SetBoxChartDataAction extends Action {
    data: BoxChartsDataForPeriod;
}

export interface UpdateDispersionSettingsAction extends Action {
    filterName: string;
    userAccountSettings: UserAccountSettings;
    waitResponse: boolean;
}

export interface UpdateDashboardSettingsAction extends Action {
    filterName: string;
    userAccountSettings: UserAccountSettings;
    waitResponse: boolean;
}

export type DashboardAction =
    ApplyFilterAction |
    ApplyDispersionFilterAction |
    GetDashboardAttributesAction |
    SetDashboardAttributesAction |
    SetDashboardDataAction |
    GetStatisticDonutReportAction |
    GetPerformanceDonutReportAction |
    GetAlertDonutReportAction |
    SetStatisticDonutReportAction |
    GetChartDataAction |
    SetChartDataAction |
    GetBoxChartDataAction |
    SetBoxChartDataAction |
    UpdateDispersionSettingsAction |
    UpdateDashboardSettingsAction;
