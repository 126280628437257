import * as React from 'react';
import * as classNames from 'classnames';

import { lilLogo, bigLogo } from 'store/constants';

const styles = require('./Logo.scss');

interface Props {
    size?: any;
    setNewSize: () => void;
}

class Logo extends React.Component<Props> {
    render() {
        const open = this.props.size && this.props.size > 120;
        const logoClass = classNames({
            [styles.logo]: true,
        });
        let logo;
        if (open) {
            logo = bigLogo;
        } else {
            logo = lilLogo;
        }
        return (
            <div
                onClick={this.props.setNewSize}
                className={styles.logoWrapper}
            >
                <div className={logoClass}>
                    <img src={logo} />
                </div>
            </div>
        );
    }
}

export default Logo;
