import * as React from 'react';
import * as classnames from 'classnames';
import Checkbox from '../Ui/Checkbox';
import { FiltersState } from 'store/Filters/types';

interface Props {
    disabled?: boolean;
    name: string;
    label?: string;
    value: boolean;
    handleChange: (data: FiltersState) => void;
    idPrefix?: string;
}
const styles = require('./SingleCheckboxFilter.scss');

class SingleCheckboxFilter extends React.PureComponent<Props> {

    static defaultProps = {
        disabled: false
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        return this.props.handleChange({[this.props.name]: event.target.checked});
    }

    render() {
        const { value, label, name, disabled } = this.props;

        const className = classnames({
            [styles.singleCheckboxFilter]: true,
            [styles.disabled]: disabled
        });

        const uniqueKey = this.props.idPrefix ?  this.props.idPrefix + '-' + name : name;
        return (
            <div className={className}>
                <Checkbox
                    disabled={disabled}
                    idx={uniqueKey}
                    checked={value}
                    handleChange={this.handleChange}
                >
                    {label && label}
                </Checkbox>
            </div>
        );
    }
}

export default SingleCheckboxFilter;