import * as React from 'react';

import { Modal as RSModal, ModalBody } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle } from 'react-accessible-accordion';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import * as classNames from 'classnames';

import { AdvancedFilterItem, RangeEndings } from 'store/types';
import { DiligenceFilterNames } from 'store/Diligence/Filters/types';
import ModalHeader from '../ModalHeader';
import { FiltersState } from 'store/Filters/types';
import { FilterType } from 'store/constants';
import DropdownSelectWithCheckboxes from 'components/Diligence/Shared/Filters/DropdownSelectWithCheckboxes';
import DropdownDateFilter from 'components/Diligence/Shared/Filters/DateFilter';
import { DiligenceFilterAttributeDictionary } from 'store/Diligence/constants';
import ButtonPrimary from 'components/Shared/Ui/Buttons/ButtonPrimary';
import DropdownInputFilter from 'components/Diligence/Shared/Filters/DropdownInputFilter';
import ToggleFilter from 'components/Shared/Filters/ToggleFilter';

export interface Props {
    isOpen: boolean;
    title: string;
    toggle: () => void;
    filters: AdvancedFilterItem[];
    size?: string;
    className?: any;
    wrapClassName?: any;
    modalClassName?: string;
    currentFilter: FiltersState;
    filterPageName?: string;
    handleChange: (data: FiltersState) => void;
    handleUpdate: (filter: FiltersState) => void;
    handleSearch: (attributeType: string, filter: FiltersState) => void;
    advancedFilter: FiltersState;
}

interface State {
    hasChanges: boolean;
    expandedIds: Object;
}

const styles = require('./AdvancedFiltersModal.scss');

class AdvancedFiltersModal extends React.PureComponent<Props, State> {

    static defaultProps = {
        className: '',
        modalClassName: '',
    };

    state: State = {
        hasChanges: false,
        expandedIds: {},
    };

    handleChangeDiligenceCurrentFilter = (data: FiltersState) => {
        let currentFilterCopy = cloneDeep(this.props.advancedFilter);
        if (currentFilterCopy) {
            if (data.start || data.end) {
                currentFilterCopy = {
                    ...currentFilterCopy,
                    [DiligenceFilterAttributeDictionary[data.type]]: data
                };
            } else {
                const filter: FiltersState[] = currentFilterCopy[DiligenceFilterAttributeDictionary[data.type]];
                if (filter) {
                    const removeIndex = filter.map((entity: any) => {
                        return (data.name === entity.name && data.type === entity.type);
                    }).indexOf(true);
                    if (removeIndex !== -1) {
                        currentFilterCopy[DiligenceFilterAttributeDictionary[data.type]].splice(removeIndex, 1);
                    } else {
                        currentFilterCopy[DiligenceFilterAttributeDictionary[data.type]].push(data);
                    }
                }
            }
            this.setState({
                hasChanges: !isEqual(currentFilterCopy, this.props.currentFilter)
            });
            this.props.handleUpdate(currentFilterCopy);
        }
    }

    handleChangeCreditCurrentFilter = (data: FiltersState) => {
        let currentFilterCopy = cloneDeep(this.props.advancedFilter);
        if (currentFilterCopy) {
            currentFilterCopy = {...currentFilterCopy, ...data};
            this.setState({
                hasChanges: !isEqual(currentFilterCopy, this.props.currentFilter)
            });
            this.props.handleUpdate(currentFilterCopy);
        }
    }

    handleApplyChanges = () => {
        this.setState({hasChanges: false}, () => {
            this.props.handleChange({
                ...this.props.advancedFilter,
                offset: 0,
                scroller: false
            });
            this.handleToggle();
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
            if (isEmpty(this.props.advancedFilter)) {
                this.props.handleUpdate(this.props.currentFilter);
            }
        } else if (!isEqual(this.props.currentFilter, prevProps.currentFilter)) {
            this.props.handleUpdate(this.props.currentFilter);
        }
    }

    render() {
        const {isOpen, size, filters, className,
            wrapClassName, modalClassName = '', advancedFilter} = this.props;

        const modalClassNames = classNames({
            [modalClassName]: !!modalClassName,
            [styles.slideIn]: isOpen,
        });

        const classesNames = classNames({
            [className]: !!className,
            [styles.modal]: true
        });

        const wrapClassNames = classNames({
            [wrapClassName]: !!wrapClassName,
            [styles.wrap]: true
        });

        const headerClassNames = classNames({
            [styles.header]: true,
            'modal-header': true,
        });

        return (
            <RSModal
                contentClassName={classesNames}
                isOpen={isOpen}
                toggle={this.handleToggle}
                size={size}
                wrapClassName={wrapClassNames}
                modalClassName={modalClassNames}
                fade={false}
                zIndex="auto"
            >
                <ModalHeader className={headerClassNames} toggle={this.handleToggle}>
                    {this.renderHeader()}
                </ModalHeader>
                <ModalBody className={styles.body}>
                    <Accordion
                        className={styles.accordion}
                        accordion={false}
                        onChange={this.handleAccordionChange}
                    >
                        {filters && filters.map((filter, idx: number) => {
                            let bodyClassNames = classNames({
                                [styles.accordion__body]: true,
                                [styles.withPadding]: filter.type !== FilterType.Select,
                                [styles.withPaddingWithoutTitle]: filter.isPermanentlyExpandedAccordionItem
                            });
                            let rangeEndings: RangeEndings = filter.rangeEndings || {
                                start: '_gte',
                                end: '_lte',
                            };
                            const chosenDatesNumber = +(!!advancedFilter[`${filter.name}${rangeEndings.start}`]) +
                                +(!!advancedFilter[`${filter.name}${rangeEndings.end}`]);

                            if (filter.isPermanentlyExpandedAccordionItem) {
                                return (
                                    <div key={idx}>
                                        <AccordionItem className={styles.accordion__item}>
                                            <AccordionItemBody
                                                uuid={idx}
                                                className={bodyClassNames}
                                            >
                                                {
                                                    this.getFilterElement(
                                                        filter,
                                                        this.handleChangeDiligenceCurrentFilter,
                                                        this.handleChangeCreditCurrentFilter
                                                    )
                                                }
                                            </AccordionItemBody>
                                        </AccordionItem>
                                    </div>
                                );
                            }

                            return (
                                <div key={idx}>
                                    <AccordionItem
                                        onClick={() => this.handleClick(idx, filter)}
                                        uuid={idx}
                                        className={styles.accordion__item}
                                    >
                                        <AccordionItemTitle
                                            className={styles.accordion__title}
                                        >
                                            <div
                                                className={styles['u-position-relative']}
                                            >
                                                <React.Fragment>
                                                    {`${filter.title} `}
                                                    {
                                                    <>
                                                        {
                                                            advancedFilter[filter.name] &&
                                                            parseInt(advancedFilter[filter.name], 10) >= 0 &&
                                                            <React.Fragment>
                                                                ({advancedFilter[filter.name]})
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            advancedFilter[filter.name] &&
                                                            Array.isArray(advancedFilter[filter.name]) &&
                                                            advancedFilter[filter.name].length > 0 &&
                                                            <React.Fragment>
                                                                ({advancedFilter[filter.name].length})
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            !!chosenDatesNumber &&
                                                            <React.Fragment>({chosenDatesNumber})</React.Fragment>
                                                        }
                                                    </>
                                                    }
                                                </React.Fragment>
                                                <div className={styles.accordion__arrow} role={'presentation'} />
                                            </div>
                                        </AccordionItemTitle>
                                        <AccordionItemBody
                                            className={bodyClassNames}
                                            hideBodyClassName={styles['accordion__body--hidden']}
                                        >
                                            {
                                                this.getFilterElement(
                                                    filter,
                                                    this.handleChangeDiligenceCurrentFilter,
                                                    this.handleChangeCreditCurrentFilter
                                                )
                                            }
                                        </AccordionItemBody>
                                    </AccordionItem>
                                </div>
                            );
                        })}
                    </Accordion>
                </ModalBody>
            </RSModal>
        );
    }

    private handleAccordionChange = (itemIds) => {
        const {expandedIds} = this.state;
        itemIds.forEach(id => expandedIds[`id${id}`] = true);
        this.setState({
            expandedIds: {...expandedIds}
        });
    }

    private handleClick(id: number, filter: AdvancedFilterItem) {
        const {expandedIds} = this.state;
        if (!expandedIds.hasOwnProperty(`id${id}`)) {
            this.props.handleSearch(filter.name, {...filter, attributeSearch: undefined});
        }
    }

    private getFilterElement(
        filter: AdvancedFilterItem,
        diligenceHandleChange: (data: FiltersState) => void,
        creditHandleChange: (data: FiltersState) => void
    ) {
        let input: React.ReactElement<any> | React.ReactElement<any>[] | null = null;
        const {advancedFilter, handleSearch, filterPageName} = this.props;
        switch (filter.type) {
            case FilterType.DiligenceDate:
                input = (
                    <DropdownDateFilter
                        attributeName={filter.name}
                        currentFilter={advancedFilter}
                        handleChangeCurrentFilter={diligenceHandleChange}
                        value={advancedFilter[filter.name]}
                    />
                );
                break;
            case FilterType.CreditDate:
                let rangeEndings: RangeEndings = {
                    start: '_gte',
                    end: '_lte',
                };
                if (filter.rangeEndings) {
                    rangeEndings = filter.rangeEndings;
                }
                input = (
                    <DropdownDateFilter
                        attributeName={filter.name}
                        currentFilter={advancedFilter}
                        handleChangeCurrentFilter={creditHandleChange}
                        start={advancedFilter[`${filter.name}${rangeEndings.start}`]}
                        end={advancedFilter[`${filter.name}${rangeEndings.end}`]}
                        rangeEndings={rangeEndings}
                    />
                );
                break;
            case FilterType.DiligenceSelectWithCheckboxes:
            case FilterType.CreditSelectWithCheckboxes:
                input = (
                    <DropdownInputFilter
                        filter={filter}
                        handleAttributeSearch={handleSearch}
                        value={advancedFilter[filter.name]}
                        handleChange={filterPageName === DiligenceFilterNames.diligenceRequest
                            ? diligenceHandleChange
                            : creditHandleChange
                        }
                        currentFilter={advancedFilter}
                        filterPageName={filterPageName}
                    />
                );
                break;
            case FilterType.DiligenceSelectWithCheckboxesSync:
            case FilterType.CreditSelectWithCheckboxesSync:
                input = (
                    <DropdownSelectWithCheckboxes
                        filter={filter}
                        value={advancedFilter[filter.name]}
                        handleChange={filterPageName === DiligenceFilterNames.diligenceRequest
                            ? diligenceHandleChange
                            : creditHandleChange
                        }
                    />
                );
                break;
            case FilterType.Toggle:
                input = (
                    <ToggleFilter
                        name={filter.name}
                        withLabel={filter.withLabel}
                        placeholder={filter.title}
                        value={advancedFilter[filter.name]}
                        handleChange={creditHandleChange}
                    />
                );
                break;
            default:
        }
        return input;
    }

    private renderHeader() {
        let renderedTitle: React.ReactElement<any> = <>{this.props.title}</>;
        const applyButtonStyle = {
            position: 'absolute' as 'absolute',
            top: 20,
            right: 110,
        };
        if (this.state.hasChanges) {
            renderedTitle = (
                <div style={applyButtonStyle}>
                    <ButtonPrimary buttonLarge={true} handleClick={this.handleApplyChanges}>
                        Apply Changes
                    </ButtonPrimary>
                </div>
            );
        }
        return renderedTitle;
    }

    private handleToggle = () => {
        this.setState({hasChanges: false, expandedIds: {}}, () => this.props.toggle());
    }
}

export default AdvancedFiltersModal;
