import * as React from 'react';
import CustomModal from '../Modals/CustomModal';

import { Id, TableRowAction } from '../../../store/types';
import { BulkUploadModalStatuses, BulkUploadProgress } from '../../../store/BulkUpload/types';

import BulkUploadModalView from './BulkUploadModalView';
import { FormatTypeChoices } from '../../../store/constants';
import ActiveUser from '../../../store/Auth/models/activeUser';

interface Props {
    isOpen: boolean;
    size?: string;

    progress: BulkUploadProgress[];
    uploadHistory: any;
    rowActions: TableRowAction[];
    user: ActiveUser;
    closeModal: () => any;
    cancelUpload: (lastFileId: Id) => void;
    editResult: (lastFileId: Id) => void;
    upload: (files: File[]) => void;
    entityNameSingle?: string;
    entityNamePlural?: string;
    entityNameFormatType?: FormatTypeChoices;
    children?: BulkUploadModalStatuses;
}

export const BulkUploadModal: React.FC<Props> = (props) => (
    <CustomModal
        isOpen={props.isOpen}
        toggle={props.closeModal}
        size={props.size}
    >
        <BulkUploadModalView
            rowActions={props.rowActions}
            uploadHistory={props.uploadHistory}
            progress={props.progress}
            upload={props.upload}
            cancelUpload={props.cancelUpload}
            closeModal={props.closeModal}
            editResult={props.editResult}
            entityNameSingle={props.entityNameSingle}
            entityNamePlural={props.entityNamePlural}
            entityNameFormatType={props.entityNameFormatType}
            user={props.user}
        >
            {props.children}
        </BulkUploadModalView>
    </CustomModal>
);
