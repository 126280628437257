import UpdateProfileRequest from 'store/Diligence/User/models/updateProfileRequest';
import UpdatePasswordRequest from 'store/Diligence/User/models/updatePasswordRequest';
import DiligenceApiHelper, { transformUserData } from 'helpers/diligenceApiHelper';
import { API_USER_URL } from 'helpers/diligenceApiHelper';
import ActiveUser from 'store/Auth/models/activeUser';

export const updateProfile = async (request: UpdateProfileRequest) => {
    return DiligenceApiHelper.doRequest(`${API_USER_URL}/${request.userId}/`, 'put', {...request}).then(
        (r) => transformUserData(r)
    );
};

export const updatePassword = async (request: UpdatePasswordRequest) => {
    return DiligenceApiHelper.doRequest(`${API_USER_URL}/current/change_password/`, 'post', {
        oldPassword: request.old_password,
        newPassword: request.new_password,
        newPassword_confirmation: request.new_password_confirmation
    });
};

export const getDiligenceUser = async () => {
    return DiligenceApiHelper.doRequest(`${API_USER_URL}/current/`, 'get').then(
        (r) => transformUserData(r)
    );
};

export const getDiligenceUserInfo = async (): Promise<ActiveUser> => {
    return await DiligenceApiHelper.doRequest(`${API_USER_URL}/current/`, 'get');
};
