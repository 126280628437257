import {
    APPLY_FILTER, APPLY_FILTER_FOR_FUNDS, GET_FIRM, SET_FIRM, SET_FIRM_FUNDS_WITH_SCROLL, SET_FIRMS,
    SET_FIRMS_WITH_SCROLL, SET_FIRM_FUNDS,
} from './actions';
import { FirmModel } from './models/firm';
import { FundModel } from '../Fund/models/fund';
import { Id } from 'store/types';
import { FiltersState } from 'store/Filters/types';

export const applyFilter = (filter: FiltersState) => ({
    type: APPLY_FILTER,
    filter
});

export const applyFilterForFunds = (firmId: Id, filter: any) => ({
    type: APPLY_FILTER_FOR_FUNDS,
    firmId,
    filter,
});

export const setFirms = (count: number, firms: FirmModel[], withScroll = false) => ({
    type: withScroll
        ? SET_FIRMS_WITH_SCROLL
        : SET_FIRMS,
    count,
    firms,
});

export const getFirm = (firmId: Id) => ({
    type: GET_FIRM,
    firmId,
});

export const setFirm = (firm: FirmModel) => ({
    type: SET_FIRM,
    firm,
});

export const setFundsForFirm = (count: number, funds: FundModel[], withScroll: boolean = false) => ({
    type: withScroll
        ? SET_FIRM_FUNDS_WITH_SCROLL
        : SET_FIRM_FUNDS,
    count,
    funds,
});