import { Action } from 'redux';
import { BulkUploadProgress, UploadType } from './types';
import { Id } from '../types';

export const START_BULK_UPLOAD = 'store/bulkUpload/startBulkUpload';
export const UPDATE_IMPORT_PROGRESS = 'store/bulkUpload/updateImportProgress';
export const SET_UPLOAD_HISTORY = 'store/bulkUpload/setUploadHistory';
export const GET_RUNNING_IMPORTS = 'store/bulkUpload/getRunningImports';
export const SET_CANCEL_IMPORT_EXPORT = 'store/bulkUpload/setCancelImportExport';
export const DOWNLOAD_EXPORT_FILE = 'store/bulkUpload/downloadExportFile';

export const HIDE_GLOBAL_PROGRESS = 'store/bulkUpload/hideGlobalProgress';
export const GET_FULL_UPLOAD_PROGRESS = 'store/bulkUpload/getFullUploadProgress';
export const SET_FULL_UPLOAD_PROGRESS = 'store/bulkUpload/setFullUploadProgress';
export const UPDATE_UPLOAD_PROGRESS = 'store/bulkUpload/updateUploadProgress';
export const UPDATE_UPLOAD_PROGRESS_DEBOUNCE = 'store/bulkUpload/updateUploadProgressDebounce';
export const GET_UPLOAD_HISTORY = 'store/bulkUpload/getUploadHistory';
export const CLEAN_LOCAL_STORAGE = 'store/bulkUpload/cleanLocalStorage';
export const START_UPLOADING = 'store/bulkUpload/start';
export const END_UPLOADING = 'store/bulkUpload/end';

export interface StartBulkUploadAction extends Action {
    name: string;
}

export interface UpdateImportProgressAction extends Action {
    name: string;
    progress: BulkUploadProgress;
}

export interface SetUploadHistoryAction extends Action {
    uploadType: UploadType;
    history: BulkUploadProgress[];
}

export interface GetRunningImportsAction extends Action {
    payload: any;
}

export interface FileDownloadAction extends Action {
    link: string;
    uploadProgress: BulkUploadProgress;
}

export interface HideGlobalProgressAction extends Action {
    id: Id;
}

type GetFullUploadProgressAction = Action;

export interface SetFullUploadProgressAction extends Action {
    progresses: BulkUploadProgress[];
}

export interface UpdateUploadProgressAction extends Action {
    progress: BulkUploadProgress;
}

export interface UpdateUploadProgressDebounceAction extends Action {
    progress: BulkUploadProgress;
}

export interface GetUploadHistoryAction extends Action {
    uploadType: UploadType;
    filter: any;
}

export interface SetCancelImportExportAction extends Action {
    fileId: Id;
}

export interface UpdateUploadStatusAction extends Action {
    progress: BulkUploadProgress;
}

export type CleanLocalStorageAction = Action;

export type BulkUploadAction = SetUploadHistoryAction |
    GetRunningImportsAction |
    UpdateImportProgressAction |
    HideGlobalProgressAction |
    StartBulkUploadAction |
    GetFullUploadProgressAction |
    SetFullUploadProgressAction |
    UpdateUploadProgressAction |
    UpdateUploadProgressDebounceAction |
    GetUploadHistoryAction |
    SetCancelImportExportAction |
    FileDownloadAction |
    CleanLocalStorageAction |
    UpdateUploadStatusAction;