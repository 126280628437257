import { put, takeLatest, all, takeEvery, select } from 'redux-saga/effects';

import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import {
    CLOSE, CloseDropdownAction
} from './actions';

import { setIsLoading } from 'store/Filters/actionCreators';
import { applyFilter } from 'store/Diligence/DiligenceRequest/actionCreators';
import { setFilters } from 'store/Diligence/Filters/actionCreators';

function* applyDropdownFilters(action: CloseDropdownAction) {
    try {
        yield put(initAction(action.type));
        yield put(applyFilter(action.currentFilter));
        yield put(setFilters(action.filterName, action.currentFilter));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* watchApplyFilter() {
    yield takeLatest(CLOSE, applyDropdownFilters);
}

export default function* root() {
    yield all(
        [
            watchApplyFilter(),
        ]
    );
}