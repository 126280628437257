import ApiHelper, { API_ACCOUNT_URL, API_USER_URL } from 'helpers/creditApiHelper';

import UpdateProfileRequest from 'store/User/models/updateProfileRequest';
import UpdatePasswordRequest from 'store/User/models/updatePasswordRequest';
import { Id } from 'store/types';
import { UserAccountSettings } from 'store/Auth/models/userAccount';
import { AccountSettings } from 'store/Account/models/account';

export const getInfo = async () => {
    return ApiHelper.doRequest(`${API_USER_URL}/info/`, 'get');
};

export const updateUserAccountSettings = async (userSettings: UserAccountSettings) => {
    return ApiHelper.doRequest(`${API_USER_URL}/update-user-account-settings/`, 'patch', {
        ...userSettings
    });
};

export const updateAccountSettings = async (accountSettings: AccountSettings) => {
    return ApiHelper.doRequest(`${API_ACCOUNT_URL}/update-account-settings/`, 'put', accountSettings);
};

export const switchAccount = async (relationId: Id) => {
    return ApiHelper.doRequest(`${API_USER_URL}/switch-account/`, 'patch', {
        relation_id: relationId
    });
};

export const initializeResetPassword = async (email: string) => {
    return ApiHelper.doRequest(`${API_USER_URL}/reset-password/`, 'post', {
        email
    });
};

export const resetPassword = async (password: string, token: string, resetKey: string) => {
    return ApiHelper.doRequest(`${API_USER_URL}/reset-password/${token}/${resetKey}/`, 'patch', {
        password
    });
};

export const updateProfile = async (request: UpdateProfileRequest) => {
    return ApiHelper.doRequest(`${API_USER_URL}/profile/`, 'patch', {...request});
};

export const updatePassword = async (request: UpdatePasswordRequest) => {
    return ApiHelper.doRequest(`${API_USER_URL}/password/`, 'patch', {...request});
};

export const upgradeAdhocAccount = async () => {
    return ApiHelper.doRequest(`${API_USER_URL}/adhoc-upgrade/`, 'patch');
};
