import * as moment from 'moment';

import {
    DATE_FORMAT,
    DEFAULT_CURRENCY_VALUE,
    DEFAULT_DATE_FORMAT,
    DEFAULT_VALUE_NONE,
    BooleanFieldText,
    DATE_FORMATS,
    FormatTypeChoices,
    RowStatusesText,
    UploadStatusesText
} from 'store/constants';
import { TableHeader } from 'store/types';
import NumericHelper from './NumericInputHelper';
import GlobalHelper from './globalHelper';

export const replaceParam = (template: string, row: any): string => {
    let templateString = template;
    const paramExpression = /{([^{}]+)}/g;
    let match = paramExpression.exec(templateString);
    while (match != null) {
        const parameter = match[1];
        const searchValue = match[0];
        if (parameter) {
            const parameterValue = GlobalHelper.getValueFromObject(row, parameter, '');
            templateString = templateString.replace(searchValue, parameterValue);
        }
        match = paramExpression.exec(templateString);
    }

    return templateString;
};

// checks if date has default YYYY-MM-DD format
export const isPureDate = (text: any): boolean => {
    if (text) {
        const paramExpression = /^\d{4}-\d{1,2}-\d{1,2}$/g;
        const match = paramExpression.test(text);
        return match && moment(text, DEFAULT_DATE_FORMAT).isValid();
    }
    return false;
};

export const formatDictItem = (value: string, dict: {[key: string]: string}, defaultValue: string) => {
    if (value === null || value === undefined) {
        return defaultValue;
    }

    return dict.hasOwnProperty(value) ? dict[value] : value;
};

export const formatText = (text: any, textType?: FormatTypeChoices, defaultValue: string = ''): string => {
    let value = text;
    if (textType) {
        switch (textType) {
            case FormatTypeChoices.Boolean:
                value = text ? BooleanFieldText.Yes : BooleanFieldText.No;
                break;
            case FormatTypeChoices.ContainsUnderscore:
                value = value ? value.split('_').join(' ') : value;
                value = value && value[0].toUpperCase() + value.slice(1);
                break;
            case FormatTypeChoices.FirstCharUpper:
                value = defaultValue;
                if (text) {
                    value = text[0].toUpperCase() + text.slice(1);
                }
                break;
            case FormatTypeChoices.CountFunds:
                value = `${text} Fund${text === 1 ? '' : 's'}`;
                break;
            case FormatTypeChoices.CountDatasets:
                value = `${text} Dataset${text === 1 ? '' : 's'}`;
                break;
            case FormatTypeChoices.CountRulesets:
                value = `${text} Ruleset${text === 1 ? '' : 's'}`;
                break;
            case FormatTypeChoices.DefaultDateFormat:
            case FormatTypeChoices.DatetimeFormat:
            case FormatTypeChoices.DateFormat:
            case FormatTypeChoices.DateFormatShort:
            case FormatTypeChoices.FullMonthAndYear:
            case FormatTypeChoices.DateSlashWithoutYear:
                value = defaultValue;
                if (text) {
                    if (isPureDate(text)) {
                        value = moment(text, DEFAULT_DATE_FORMAT).format(DATE_FORMATS[textType]);
                    } else {
                        value = moment(new Date(text).toUTCString()).isValid()
                            ? moment(new Date(text).toUTCString()).format(DATE_FORMATS[textType])
                            : text;
                    }
                }
                break;
            case FormatTypeChoices.RowStatus:
                value = text ? RowStatusesText[text] : DEFAULT_VALUE_NONE;
                break;
            case FormatTypeChoices.ToUppercase:
                value = value.toUpperCase();
                break;
            case FormatTypeChoices.UploadStatus:
                value = text ? UploadStatusesText[text] : DEFAULT_VALUE_NONE;
                break;
            case FormatTypeChoices.Percent:
                value = defaultValue;
                if (text || text === 0) {
                    value = NumericHelper.getFormattedNumeral(
                        '' + (+text / 100),
                        DEFAULT_CURRENCY_VALUE,
                        false,
                        textType,
                        defaultValue
                    );
                }
                break;
            case FormatTypeChoices.NumeralDefault:
            case FormatTypeChoices.NumeralTwoDecimalPlaces:
            case FormatTypeChoices.NumeralShortenDecimalLower:
            case FormatTypeChoices.NumeralOneDecimalLower:
            case FormatTypeChoices.NumeralThreeDecimalLower:
                value = NumericHelper.getFormattedNumeral(text, DEFAULT_CURRENCY_VALUE, false, textType, defaultValue);
                break;
            case FormatTypeChoices.FromNow:
                value = moment(text).fromNow();
                break;
            case FormatTypeChoices.FromNowIfLessThanAMonth:
                value = defaultValue;
                if (text) {
                    const diff = moment().diff(moment(text), 'months', true);
                    value = (diff > 1)
                        ? moment(text).format(DATE_FORMAT)
                        : moment(text).fromNow();
                }
                break;
            case FormatTypeChoices.ToNow:
                value = moment(text).toNow();
                break;
            case FormatTypeChoices.TimeDiff:
                if (!text) {
                    return defaultValue;
                }
                let diffInSeconds = moment().diff(moment(text), 'seconds');

                if (diffInSeconds < 60) {
                    return 'a few seconds';
                }

                value = '';

                // days
                if (diffInSeconds / 86400 > 1) {
                    const days = Math.floor(diffInSeconds / 86400);
                    value = `${days} ${days === 1 ? 'day' : 'days'}`;
                    diffInSeconds %= 86400;
                }
                // hours
                if (diffInSeconds / 3600 > 1) {
                    const hours = Math.floor(diffInSeconds / 3600);
                    value = `${value} ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
                    diffInSeconds %= 3600;
                }
                // minutes
                if (diffInSeconds / 60 > 1) {
                    const minutes = Math.floor(diffInSeconds / 60);
                    value = `${value} ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
                    diffInSeconds %= 60;
                }
                // seconds
                // value = `${value} ${Math.floor(diffInSeconds)} seconds`;
                break;
            case FormatTypeChoices.String:
            default:
                value = text ? text : defaultValue;
                break;
        }
    }
    return value;
};

export const isHideColumn = (visibleHeaders: TableHeader[] | undefined | null, currentColumn: TableHeader) => {
    let answer = false;

    if (visibleHeaders) {
        const currentHeader: TableHeader | undefined = visibleHeaders.find((item: TableHeader) => {
            return currentColumn.name === item.name;
        });

        if (currentHeader && !currentHeader.active) {
            answer = true;
        }
    }

    return answer;
};

export const joinListOfStrings = (list: string[] | null, separator: string = ', ') => {
    return list && list.length ? list.join(separator) : '';
};
