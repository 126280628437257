import * as React from 'react';
import { connect } from 'react-redux';

import ConfirmPublishModal from 'components/DataManager/PublishModals/ConfirmPublishModal';
import Modal from 'components/Shared/Modals/Modal';
import ButtonDefault from 'components/Shared/Ui/Buttons/ButtonDefault';
import ButtonPrimary from 'components/Shared/Ui/Buttons/ButtonPrimary';

import { AppState } from 'store/types';
import { closeModal } from 'store/Modals/General/actionCreators';
import { getModalParams, isModalOpen } from 'store/Modals/General/selectors';
import { CONFIRM_PUBLISH_MODAL } from 'store/DataManager/constants';
import {
    setAllRequestorsToggle, setRequestorCheckboxes, getRequestorsToConfirmPublish,
    setRequestorsToConfirmPublish, confirmPublishDataset
} from 'store/DataManager/actionCreators';
import { getConfirmPublishModal } from 'store/DataManager/selectors';
import { CheckboxOption } from 'store/DataManager/types';
import { RequestorModel } from 'store/DataManager/models/requestor';
import { GET_REQUESTORS_TO_CONFIRM_PUBLISH } from 'store/DataManager/actions';
import { getActionIsInProgress } from 'store/Actions/selectors';
const styles = require('../../../components/DataManager/PublishModals/PublishModals.scss');

interface Props {
    isOpen: boolean;
    closeModal: () => any;
    allowChooseRequestors: boolean;
    fundId: any;
    fundName: string;
    allRequestorsToggle: boolean;
    requestors: RequestorModel[];
    requestorCheckboxes: CheckboxOption[];
    confirmPublishDataset: (fundId: any, requestorIds: any) => any;
    customConfirmPublishDataset?: (fundId: any, requestorIds: any) => any;
    getRequestors: (fundId: any) => any;
    setRequestors: (requestors: RequestorModel[]) => any;
    setAllRequestorsToggle: (value: boolean) => any;
    setRequestorCheckboxes: (requestorCheckboxes: CheckboxOption[]) => any;
    isModalLoading: boolean;
}

class ConfirmPublish extends React.Component<Props> {

    publishData = () => {
        let selectedRequestorIds: any = [];
        if (this.props.allRequestorsToggle) {
            selectedRequestorIds = this.props.requestorCheckboxes.map(
                (requestorCheckbox: CheckboxOption) => requestorCheckbox.value
            );
        } else {
            selectedRequestorIds = this.props.requestorCheckboxes
                .filter((requestorCheckbox: CheckboxOption) => requestorCheckbox.checked)
                .map((requestorCheckbox: CheckboxOption) => requestorCheckbox.value);
        }
        if (this.props.customConfirmPublishDataset) {
            this.props.customConfirmPublishDataset(this.props.fundId, selectedRequestorIds);
        } else {
            this.props.confirmPublishDataset(this.props.fundId, selectedRequestorIds);
        }
    }

    render () {
        const body = (
            <ConfirmPublishModal
                allowChooseRequestors={this.props.allowChooseRequestors}
                allRequestorsToggle={this.props.allRequestorsToggle}
                fundId={this.props.fundId}
                fundName={this.props.fundName}
                requestorCheckboxes={this.props.requestorCheckboxes}
                getRequestors={this.props.getRequestors}
                setRequestors={this.props.setRequestors}
                setAllRequestorsToggle={this.props.setAllRequestorsToggle}
                setRequestorCheckboxes={this.props.setRequestorCheckboxes}
                isModalLoading={this.props.isModalLoading}
            />
        );

        const checkedRequestors = this.props.requestorCheckboxes.filter(
            (requestor: CheckboxOption) => requestor.checked
        );
        const publishDisabled = !this.props.allRequestorsToggle && checkedRequestors.length === 0;
        const buttons = [
            (
                <ButtonPrimary buttonLarge={true} handleClick={this.publishData} disabled={publishDisabled}>
                    Publish Now
                </ButtonPrimary>
            ),
            (
                <ButtonDefault buttonLarge={true} inverse={true} handleClick={this.props.closeModal}>
                    Cancel
                </ButtonDefault>
            )
        ];

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.closeModal}
                title="Confirm Publish"
                body={body}
                buttons={buttons}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => {
    const modalParams = getModalParams(state) || {};
    const fundId = modalParams && modalParams.fundId ? modalParams.fundId : 0;
    const fundName = modalParams && modalParams.fundName ? modalParams.fundName : '';
    return {
        isOpen: isModalOpen(state, {name: CONFIRM_PUBLISH_MODAL}),
        requestors: getConfirmPublishModal(state).requestors,
        requestorCheckboxes: getConfirmPublishModal(state).requestorCheckboxes,
        allRequestorsToggle: getConfirmPublishModal(state).allRequestorsToggle,
        isModalLoading: getActionIsInProgress(state, {name: GET_REQUESTORS_TO_CONFIRM_PUBLISH}),
        fundId,
        fundName,
    };
};

const mapDispatchToProps = {
    closeModal,
    confirmPublishDataset,
    getRequestors: getRequestorsToConfirmPublish,
    setRequestors: setRequestorsToConfirmPublish,
    setAllRequestorsToggle,
    setRequestorCheckboxes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPublish);