import { Action } from 'redux';
import { Filter, SuggestFiltersRequest } from './types';

export const SUGGEST_FILTERS = 'store/diligence/dataManager/suggestFilters';
export const SET_FILTER_ENTITIES = 'store/diligence/dataManager/setFilterEntites';

export interface SuggestFiltersAction extends Action {
    request: SuggestFiltersRequest;
}

export interface SetFilterEntitiesAction extends Action {
    filters: Filter[];
}

export type DataManagerAction = SuggestFiltersAction | SetFilterEntitiesAction;