import { RequestsState } from './types';
import {
    SET_REQUESTS,
    SET_REQUESTORS,
    CLOSE_ROW,
    SET_MANAGER_SCREEN_REQUESTS,
    CHANGE_EDITED_REQUEST, RequestsAction, SetRequestsAction, ChangeEditedRequestAction,
    SET_EDITED_REQUEST, SetEditedRequestAction, BULK_UPDATE_REQUESTS, BulkUpdateRequestsAction,
    SetManagerScreenRequestsAction,
    SetRequestorsAction,
    CloseRowAction,
    SET_FUND_CONTACTS,
    SetFundContactsAction,
    SET_PENDING_REQUESTS,
    SetPendingRequestsAction,
    SET_PENDING_NOTIFICATION_FLAG,
    SetPendingNotificationFlagAction, TOGGLE_EDIT_MODE, ToggleEditMode,
} from './actions';
import { initialState } from './constants';
import { Fund } from './models/fund';
import { Request } from './models/request';
import GlobalHelper from 'helpers/globalHelper';
import { CustomTableRow } from 'store/types';

export const requestsReducer = (state: RequestsState = initialState, action: RequestsAction) => {
    switch (action.type) {
        case SET_REQUESTS:
            const {count: requestsCount, funds, permissions, withScroll} = (<SetRequestsAction> action);
            return {
                ...state,
                requests: [
                    ...(withScroll ? state.requests : []),
                    ...funds
                ],
                requestsCount,
                permissions,
            };
        case SET_MANAGER_SCREEN_REQUESTS:
            const {
                count: managerScreenRequestsCount,
            } = (<SetManagerScreenRequestsAction> action);
            return {
                ...state,
                managerScreenRequests: [
                    ...((<SetManagerScreenRequestsAction> action).withScroll ? state.managerScreenRequests : []),
                    ...(<SetManagerScreenRequestsAction> action).requests,
                ],
                managerScreenRequestsCount
            };
        case CLOSE_ROW:
            const {id} = (<CloseRowAction> action);
            let pureState;
            pureState = GlobalHelper.clearAllRowTypes(state.managerScreenRequests);
            const rowIndex = GlobalHelper.getIndexInArray(pureState, id);
            pureState[rowIndex].activeRow = false;
            return {
                ...state,
                managerScreenRequests: [
                    ...pureState
                ]
            };
        case SET_REQUESTORS:
            const {requestors, parentId, status} = (<SetRequestorsAction> action);
            let childrenItems;
            let clearState;

            if (status === 'scheduled') {
                childrenItems = GlobalHelper.setRowType(requestors, CustomTableRow.ScheduledRow);
            } else if (status === 'published') {
                childrenItems = GlobalHelper.setRowType(requestors, CustomTableRow.PublishedRow);
            } else {
                childrenItems = GlobalHelper.setRowType(requestors);
            }
            clearState = GlobalHelper.clearRowType(state.managerScreenRequests);

            const parentIndex = GlobalHelper.getIndexInArray(clearState, parentId);
            clearState[parentIndex] = {
                ...clearState[parentIndex],
                activeRow: true,
            };

            clearState.splice(parentIndex + 1, 0, ...childrenItems);

            return {
                ...state,
                managerScreenRequests: [
                    ...clearState
                ]
            };
        case SET_PENDING_REQUESTS:
            const { count } = (<SetPendingRequestsAction> action);
            return {
                ...state,
                pendingRequestsCount: count,
            };
        case SET_PENDING_NOTIFICATION_FLAG:
            const { flag } = (<SetPendingNotificationFlagAction> action);
            return {
                ...state,
                pendingNotificationsFlag: flag,
            };
        case BULK_UPDATE_REQUESTS:
            const { requests } = (<BulkUpdateRequestsAction> action);

            return {
                ...state,
                requests: state.requests.map((fund: Fund) => {
                    const updatedRequest = requests.find((req: Request) => !!req.fund && req.fund.id === fund.id);
                    if (updatedRequest) {
                        return {
                            ...fund,
                            request: {
                                ...updatedRequest
                            }
                        };
                    }
                    return fund;
                })
            };
        case CHANGE_EDITED_REQUEST:
            const {field, value} = (<ChangeEditedRequestAction> action);
            return {
                ...state,
                editor: {
                    ...state.editor,
                    request: {
                        ...state.editor.request,
                        [field]: value
                    }
                }
            };
        case SET_EDITED_REQUEST:
            const {request} = (<SetEditedRequestAction> action);
            return {
                ...state,
                editor: {
                    ...state.editor,
                    request
                }
            };
        case SET_FUND_CONTACTS:
            const {contacts} = (<SetFundContactsAction> action);
            return {
                ...state,
                editor: {
                    ...state.editor,
                    contacts
                }
            };
        case TOGGLE_EDIT_MODE:
            const oldMode = state.editor.isEditMode;
            const {mode} = (<ToggleEditMode> action);
            return {
                ...state,
                editor: {
                    ...state.editor,
                    isEditMode: mode === undefined ? !oldMode : mode
                }
            };
        default:
            return state;
    }
};
