import * as React from 'react';

import SmoothScrollbar from 'smooth-scrollbar';
import * as I from 'smooth-scrollbar/interfaces/';

interface Props {
    className?: string;
    damping?: number;
    thumbMinSize?: number;
    syncCallbacks?: boolean;
    renderByPixels?: boolean;
    alwaysShowTracks?: boolean;
    continuousScrolling?: boolean;
    wheelEventTarget?: HTMLDivElement;
    plugins?: object;
    onScroll?: (status: I.ScrollStatus, scrollbar: SmoothScrollbar) => void;
    options?: Partial<I.ScrollbarOptions>;
}

type ScrollbarCallback = (scroll: SmoothScrollbar) => void;

export const GetScrollbarContext = React.createContext((cb: ScrollbarCallback) => {
    return;
});

export default class Scrollbar extends React.Component<Props> {
    scrollbar: SmoothScrollbar;
    callbacks: ScrollbarCallback[];
    container: React.RefObject<HTMLElement>;

    constructor(props: Props) {
        super(props);

        this.container = React.createRef();
        this.callbacks = [];
    }

    getScrollbar = (cb: ScrollbarCallback) => {
        if (typeof cb !== 'function') {
            return;
        }

        if (this.scrollbar) {
            setTimeout(() => cb(this.scrollbar));
        } else {
            this.callbacks.push(cb);
        }
    }

    componentDidMount() {
        if (this.container && this.container.current) {
            this.scrollbar = SmoothScrollbar.init(this.container.current, this.props as Partial<I.ScrollbarOptions>);

            this.callbacks.forEach((cb) => {
                requestAnimationFrame(() => cb(this.scrollbar));
            });

            this.scrollbar.addListener(this.handleScroll);
        }
    }

    componentWillUnmount() {
        if (this.scrollbar) {
            this.scrollbar.destroy();
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        Object.keys(nextProps).forEach((key: string) => {
            if (!this.scrollbar.options.hasOwnProperty(key)) {
                return;
            }

            if (key === 'plugins' && nextProps.plugins) {
                Object.keys(nextProps.plugins).forEach((pluginName) => {
                    if (nextProps.plugins) {
                        this.scrollbar.updatePluginOptions(pluginName, nextProps.plugins[pluginName]);
                    }
                });
            } else {
                this.scrollbar.options[key] = nextProps[key];
            }
        });
    }

    componentDidUpdate() {
        if (this.scrollbar) {
            this.scrollbar.update();
        }
    }

    handleScroll = (status: I.ScrollStatus) => {
        if (this.props.onScroll) {
            this.props.onScroll(status, this.scrollbar);
        }
    }

    handleMouseDown = () => {
        window.getSelection().removeAllRanges();
    }

    render() {
        const {
            damping,
            thumbMinSize,
            syncCallbacks,
            renderByPixels,
            alwaysShowTracks,
            continuousScrolling,
            wheelEventTarget,
            plugins,

            onScroll,
            children,
            ...others
        } = this.props;

        return (
            <GetScrollbarContext.Provider value={this.getScrollbar}>
                <section data-scrollbar={true} ref={this.container} onMouseDown={this.handleMouseDown} {...others}>
                    <div>{children}</div>
                </section>
            </GetScrollbarContext.Provider>
        );
    }
}