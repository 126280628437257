import { put, all, takeEvery, takeLatest, select } from 'redux-saga/effects';
import DataManagerHelper from 'helpers/dataManagerHelper';
import { DatasetDetailFund } from 'services/dataManager';
import FundHelper from 'helpers/fundHelper';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import {
    APPLY_FILTER,
    APPLY_FILTER_DATASET_DETAIL,
    APPLY_FILTER_PUBLISHED_DATASETS,
    APPLY_FILTER_ACL_USERS,
    DELETE_ACL_USERS,
    SET_ROLE_ACL_USERS,
    INVITE_ACL_USER,
    BECOME_ACL_OWNER,
    REQUEST_ACL_ACCESS,
    SET_PENDING_PUBLISH_DATASET,
    CONFIRM_PUBLISH_DATASET,
    GET_REQUESTORS_TO_CONFIRM_PUBLISH,
    GET_REQUESTORS_TO_SET_PENDING_PUBLISH,
    EDIT_PENDING_PUBLISH_DATASET,
    ApplyFilterAction,
    ApplyFilterDatasetAction,
    ApplyFilterPublishedDatasetsAction,
    GetRequestorsToConfirmPublishAction,
    ApplyFilterAclUsersAction,
    InviteAclUserAction,
    DeleteAclUsersAction,
    SetRoleAclUsersAction,
    BecomeAclOwnerAction,
    SetPendingPublishDatasetAction,
    ConfirmPublishDatasetAction,
    RequestAclAccessAction,
    GetRequestorsToSetPendingPublishAction,
    EditPendingPublishDatasetAction,
    UpdateDatasetDetailFieldAction,
    UPDATE_DATASET_DETAIL_FIELD,
    UnlockDatasetDetailRowAction,
    UNLOCK_DATASET_DETAIL_ROW,
    GetDatasetHistoryAction,
    GET_DATASET_HISTORY,
    GET_DATASET_FUND,
    GetDatasetFundAction,
    ADD_DATASET_TO_FUND,
    CANCEL_DATASET_UPLOAD,
    CancelDatasetUploadAction,
    DATASET_UPLOAD,
    DatasetUploadAction,
    GET_REQUESTORS,
    GetRequestorsToDataManager,
    RequestAction,
    ACCEPT_REQUEST,
    DENY_REQUEST,
    SAVE_SETTINGS_DATASET_FUND,
    ChangeCachedStatusForFundAction,
    CHANGE_CACHED_STATUS_FOR_FUND,
    CONFIRM_PUBLISH_DATASET_FROM_DETAIL,
    SET_PENDING_PUBLISH_DATASET_DETAIL, DatasetDownloadAction, DATASET_LIST_DOWNLOAD, DATASET_DOWNLOAD,
    PUBLISHED_DATASET_DOWNLOAD, PublishedDatasetDownloadAction, AddDatasetToFundAction, SaveSettingsDatasetFundAction,
    ApplyFilterInternalDataAction, APPLY_FILTER_INTERNAL_DATA, InternalDataDownloadAction, INTERNAL_DATA_DOWNLOAD,
    CombinedDataDownloadAction, COMBINED_DATA_DOWNLOAD, InternalDataUploadAction, INTERNAL_DATA_UPLOAD,
} from './actions';
import {
    setDatasetList, setRequestorCheckboxes, setDatasetDetail, setAclUsers, deleteAclUsersSuccess, inviteAclUserSuccess,
    setRequestorsToSetPendingPublish, setRoleAclUsersSuccess,
    setDatasetHistory, setDatasetFund, cleartDatasetHistory, setCurrentDataset,
    setRequestors, updateRequest, setPublishedDatasets, changeCachedStatusForFund, setChangeCachedStatusForFund,
    getDatasetFund as getDatasetFundCreator, clearIsEdingDataset, setPendingRequests as setPendingRequestsForFund,
    setDatasetFundFlags, setInternalData
} from './actionCreators';
import { callApplyFilters, clearChecked, setFilters, setIsLoading } from '../Filters/actionCreators';
import * as dataManagerApi from 'services/dataManager';
import * as requestsApi from 'services/request';
import * as aclApi from 'services/acl';
import { messages } from './constants';
import { closeModal } from '../Modals/General/actionCreators';
import { FilterNames } from '../Filters/types';
import { getUploadHistory } from '../BulkUpload/actionCreator';
import GlobalHelper from '../../helpers/globalHelper';
import { setHeaders } from '../VisibleHeaders/actionCreators';
import { headerNames } from '../VisibleHeaders/types';
import { RequestStatus } from '../Requests/types';
import { setPendingRequests } from '../Requests/actionCreators';
import { UploadType } from '../BulkUpload/types';
import { bulkActionMessages } from '../constants';

function* applyFilter(action: ApplyFilterAction) {
    try {
        yield put(initAction(action.type));
        const response = yield dataManagerApi.getDatasetList(action.filter);
        let withScroll = false;
        if (action.filter.scroller) {
            yield put(setIsLoading(true));
            withScroll = true;
        }
        yield put(setDatasetList(response.count, response.results, withScroll));
        const pendingRequests = yield requestsApi.getPendingRequests();
        yield put(setPendingRequests(pendingRequests.count));
        yield put(doneActionSuccess(action.type));
        yield put(setIsLoading(false));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* applyFilterInternalData(action: ApplyFilterInternalDataAction) {
    try {
        yield put(initAction(action.type));
        yield put(setIsLoading(action.filter.scroller));
        const response = yield dataManagerApi.getInternalData(action.filter);
        yield put(setInternalData(response.count, response.results, response.permissions, action.filter.scroller));
        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* applyFilterPublishedDatasets(action: ApplyFilterPublishedDatasetsAction) {
    try {
        yield put(initAction(action.type));
        const response = yield dataManagerApi.getPublishedDatasets(action.filter);
        let withScroll = false;
        if (action.filter.scroller) {
            yield put(setIsLoading(true));
            withScroll = true;
        }
        yield put(setPublishedDatasets(response.count, response.results, withScroll));
        yield put(doneActionSuccess(action.type));
        yield put(setIsLoading(false));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* applyFilterDataset(action: ApplyFilterDatasetAction) {
    try {
        yield put(initAction(action.type));

        let withScroll = false;
        if (action.filter.scroller) {
            yield put(setIsLoading(true));
            withScroll = true;
        }

        const response = yield DatasetDetailFund(action.filter);

        const state = yield select();
        const datasetFund =  GlobalHelper.getValueFromObject(state,
                                                             'dataManager.datasetFund',
                                                             null);
        yield put(setFilters(FilterNames.datasetData,
                             { ...action.filter, currency: datasetFund.currency.toUpperCase() }));
        const fundConfiguration = GlobalHelper.getValueFromObject(datasetFund,
                                                                  'fund_configuration',
                                                                  null);

        if (fundConfiguration && fundConfiguration.visible_fields) {
            yield put(setHeaders(headerNames.datasetDetail, fundConfiguration.visible_fields, true));
        }
        yield put(setDatasetDetail(response.count, response.results, withScroll));

        // force updating status for current dataset
        yield put(getDatasetFundCreator(action.filter.fund_id));

        const pendingRequests = yield requestsApi.getPendingRequests(action.filter.fund_id);
        yield put(setPendingRequestsForFund(action.filter.fund_id, pendingRequests.count));
        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(setIsLoading(false));
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* saveSettingsFundDataset(action: SaveSettingsDatasetFundAction) {
    try {
        yield put(initAction(action.type));
        if (action.requestor) {
            yield dataManagerApi.SaveSettingsRequestorDatasetFund(action.id, action.payload);
        } else {
            yield dataManagerApi.SaveSettingsDatasetFund(action.id, action.payload);
            yield put({ type: GET_DATASET_FUND , id: action.id});

            const state = yield select();
            const currentFilter = state.filters[FilterNames.datasetData];

            const response = yield DatasetDetailFund({
                ...currentFilter,
                fund: action.payload.ap_id, fund_id: action.id, currency: action.payload.currency });
            yield put(setDatasetDetail(response.count, response.results));
            yield put(doneActionSuccess(action.type));

            const temporaryHeaders = GlobalHelper.getValueFromObject(state, 'dataManager.temporaryHeaders', null);
            const temporaryFilters = GlobalHelper.getValueFromObject(state, 'dataManager.temporaryFilter', null);
            if (temporaryFilters) {
                yield put(setFilters(FilterNames.datasetData, temporaryFilters));
            }

            if (temporaryHeaders) {
                yield put(setHeaders(headerNames.datasetDetail, temporaryHeaders));
            }
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* applyFilterAclUsers(action: ApplyFilterAclUsersAction) {
    try {
        yield put(initAction(action.type));
        // yield put(setIsLoading(true));
        const response = yield aclApi.getAclUsers(action.filter.fundId);
        // const withScroll = action.filter.hasOwnProperty('scroller') && (action.filter.scroller || false);
        yield put(setAclUsers(response.length, response, false));
        // yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* inviteAclUser(action: InviteAclUserAction) {
    try {
        yield put(initAction(action.type));

        const aclUser = yield aclApi.inviteAclUser(action.fundId, action.invitedUser);
        yield put(inviteAclUserSuccess(aclUser));

        yield put(doneActionSuccess(action.type, messages.INVITE_ACL_USER_SUCCESS_MESSAGE));
        if (action.closeModal) {
            yield put(closeModal());
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.INVITE_ACL_USER_FAIL_MESSAGE));
    }
}

function* deleteAclUsers(action: DeleteAclUsersAction) {
    try {
        yield put(initAction(action.type));

        yield aclApi.deleteAclUsers(action.fundId, action.ids);
        yield put(deleteAclUsersSuccess(action.ids));
        yield put(doneActionSuccess(action.type, messages.DELETE_ACL_USER_SUCCESS_MESSAGE));
        if (action.closeModal) {
            yield put(closeModal());
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.DELETE_ACL_USER_FAIL_MESSAGE));
    }
}

function* setRoleAclUsers(action: SetRoleAclUsersAction) {
    try {
        yield put(initAction(action.type));

        const aclUsers = yield aclApi.setRoleAclUsers(action.fundId, action.ids, action.role);
        yield put(setRoleAclUsersSuccess(aclUsers));
        yield put(doneActionSuccess(action.type, messages.SET_ROLE_ACL_USER_SUCCESS_MESSAGE));
        const fundFilter = action.useFundIdForLoadList ? {fundId: action.fundId} : {};

        yield put(callApplyFilters(FilterNames.aclUsers, APPLY_FILTER_ACL_USERS, fundFilter));
        if (action.closeModal) {
            yield put(closeModal());
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.SET_ROLE_ACL_USER_FAIL_MESSAGE));
    }
}

function* becomeAclOwner(action: BecomeAclOwnerAction) {
    try {
        yield put(initAction(action.type));
        yield aclApi.becomeAclOwner(action.fundId);
        yield put(doneActionSuccess(action.type, messages.BECOME_ACL_OWNER_SUCCESS_MESSAGE));

        yield put(callApplyFilters(FilterNames.datasetList, APPLY_FILTER));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.BECOME_ACL_OWNER_FAIL_MESSAGE));
    }
}

function* requestAclAccess(action: RequestAclAccessAction) {
    try {
        yield put(initAction(action.type));
        yield aclApi.requestAclAccess(action.fundId);
        yield put(doneActionSuccess(action.type, messages.REQUEST_ACL_ACCESS_SUCCESS_MESSAGE));

        yield put(callApplyFilters(FilterNames.datasetList, APPLY_FILTER));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.REQUEST_ACL_ACCESS_FAIL_MESSAGE));
    }
}

function* getRequestorsToConfirmPublish(action: GetRequestorsToConfirmPublishAction) {
    try {
        yield put(initAction(action.type));
        const response = yield dataManagerApi.getRequestors(action.fundId);

        const requestorCheckboxes = DataManagerHelper.getCheckboxesForRequestors(response.results);
        yield put(setRequestorCheckboxes(requestorCheckboxes));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getRequestorsToDataManager(action: GetRequestorsToDataManager) {
    try {
        yield put(initAction(action.type));

        const response = yield dataManagerApi.getRequestors(action.fundId, {with_fund : 1});

        yield put(setRequestors(response.results));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* changeRequestStatusFromDataManager(action: RequestAction) {
    try {
        yield put(initAction(action.type));

        const request = yield dataManagerApi.changeRequestStatus(action.requestId, action.status, action.message);

        let message = `The Request from ${request.requesting_account.name} has been successfully `;

        if (action.status === RequestStatus.Denied) {
            yield put(closeModal());

            message += 'denied';
        } else {
            message += 'approved';
        }

        yield put(updateRequest(request));

        yield put(doneActionSuccess(action.type, message));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getRequestorsToSetPendingPublish(action: GetRequestorsToSetPendingPublishAction) {
    try {
        yield put(initAction(action.type));
        yield put(setIsLoading(true));
        const response = yield dataManagerApi.getRequestors(action.fundId);
        yield put(setRequestorsToSetPendingPublish(response.results));
        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* confirmPublishDataset(action: ConfirmPublishDatasetAction) {
    try {
        yield put(initAction(action.type));
        yield dataManagerApi.publishDataset(action.fundId, action.requestorIds);
        yield put(doneActionSuccess(action.type, messages.CONFIRM_PUBLISH_DATASET_SUCCESS_MESSAGE));
        yield put(closeModal());
        yield put(callApplyFilters(FilterNames.datasetList, APPLY_FILTER));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.CONFIRM_PUBLISH_DATASET_FAIL_MESSAGE));
    }
}

function* confirmPublishDatasetFromDataset(action: any) {
    try {
        yield put(initAction(action.type));
        yield dataManagerApi.publishDataset(action.fundId, action.requestorIds);
        yield put(closeModal());

        yield put(getDatasetFundCreator(action.fundId));
        yield put(clearIsEdingDataset());

        const store = yield select();
        const currentFilter = store.filters[FilterNames.datasetData];

        const response = yield DatasetDetailFund(currentFilter);
        yield put(setDatasetDetail(response.count, response.results));

        yield put(doneActionSuccess(action.type, messages.CONFIRM_PUBLISH_DATASET_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.CONFIRM_PUBLISH_DATASET_FAIL_MESSAGE));
    }
}

function* setPendingPublishDataset(action: SetPendingPublishDatasetAction) {
    try {
        yield put(initAction(action.type));
        yield dataManagerApi.setPendingPublishDataset(
            action.fundId,
            action.daysBeforeDueDate,
            action.requestorIds
        );
        yield put(doneActionSuccess(action.type, messages.SET_PENDING_PUBLISH_DATASET_SUCCESS_MESSAGE));
        yield put(closeModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.SET_PENDING_PUBLISH_DATASET_FAIL_MESSAGE));
    }
}

function* setPendingPublishDatasetDetail(action: SetPendingPublishDatasetAction) {
    try {
        yield put(initAction(action.type));
        yield dataManagerApi.setPendingPublishDataset(
            action.fundId,
            action.daysBeforeDueDate,
            action.requestorIds
        );
        yield put(closeModal());

        yield put(getDatasetFundCreator(action.fundId));
        yield put(clearIsEdingDataset());

        const store = yield select();
        const currentFilter = store.filters[FilterNames.datasetData];

        const response = yield DatasetDetailFund(currentFilter);
        yield put(setDatasetDetail(response.count, response.results));

        yield put(doneActionSuccess(action.type, messages.SET_PENDING_PUBLISH_DATASET_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.SET_PENDING_PUBLISH_DATASET_FAIL_MESSAGE));
    }
}

function* editPendingPublishDataset(action: EditPendingPublishDatasetAction) {
    try {
        yield put(initAction(action.type));
        yield dataManagerApi.editPendingPublishDataset(
            action.fundId,
            action.requestorId,
            action.daysBeforeDueDate,
        );
        yield put(doneActionSuccess(action.type, messages.EDIT_PENDING_PUBLISH_DATASET_SUCCESS_MESSAGE));
        yield put(closeModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.EDIT_PENDING_PUBLISH_DATASET_FAIL_MESSAGE));
    }
}

function* updateDatasetDetailField(action: UpdateDatasetDetailFieldAction) {
    try {
        yield put(initAction(action.type));
        const response = yield dataManagerApi.updateDatasetDetailField(action.payload);
        yield put(changeCachedStatusForFund(response.period, response.cached_ds_status));

        const { has_datasets_for_publish, has_datasets_for_schedule } = response;
        yield put(setDatasetFundFlags({
            has_datasets_for_publish,
            has_datasets_for_schedule
        }));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, 'errors'));
    }
}

function* changeCachedStatusFund(action: ChangeCachedStatusForFundAction) {
    try {
        yield put(initAction(action.type));

        const state = yield select();
        const currentPeriod =  GlobalHelper.getValueFromObject(state,
                                                               'dataManager.datasetFund.current_period',
                                                               null);
        if (action.period === currentPeriod) {
            yield put(setChangeCachedStatusForFund(action.status));
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, 'errors'));
    }
}

function* unlockDatasetRow(action: UnlockDatasetDetailRowAction) {
    try {
        yield put(initAction(action.type));

        const store = yield select();
        const currentFilter = store.filters[FilterNames.datasetData];

        yield dataManagerApi.unlockDatasetDetailRow(action.datasetRow);

        const newFilter = FundHelper.setLimitAndOffset(currentFilter);

        const response = yield DatasetDetailFund(newFilter);
        yield put(setDatasetDetail(response.count, response.results));
        yield put(getDatasetFundCreator(action.fundId));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, 'errors'));
    }
}

function* getDatasetFund(action: GetDatasetFundAction) {
    try {
        yield put(initAction(action.type));
        const result = yield dataManagerApi.getDatasetFund(action.id);

        yield put(setDatasetFund(result));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, 'errors'));
    }
}

function* addDatasetToFund(action: AddDatasetToFundAction) {
    try {
        yield put(initAction(action.type));
        const result = yield dataManagerApi.addDatasetToFund(action.payload);
        yield put(setCurrentDataset(result));
        yield put({ type: GET_DATASET_FUND , id: action.payload.fund_id});

        const store = yield select();
        const currentFilter = store.filters[FilterNames.datasetData];

        const newFilter = FundHelper.setLimitAndOffset(currentFilter);

        const response = yield DatasetDetailFund(newFilter);
        yield put(setDatasetDetail(response.count, response.results));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, 'errors'));
    }
}

function* getDatasetHistory(action: GetDatasetHistoryAction) {
    try {
        yield put(initAction(action.type));

        const store = yield select();
        const currentFilter = store.filters[FilterNames.datasetData];

        const newFilter = FundHelper.setLimitAndOffset(currentFilter);

        const response = yield DatasetDetailFund(newFilter);
        yield put(setDatasetDetail(response.count, response.results));

        const parentIdInStore = store.dataManager.parentId;

        if (parentIdInStore === action.payload.parentId) {
            yield put(cleartDatasetHistory(parentIdInStore));
            return;
        }
        const answer = yield dataManagerApi.getDatasetHistory(action.payload);

        yield put(setDatasetHistory(action.payload.parentId, answer.results));

    } catch (errors) {
        yield put(doneActionFail(action.type, errors, 'errors'));
    }
}

function* cancelDatasetUpload(action: CancelDatasetUploadAction) {
    try {
        yield put(initAction(action.type));
        yield dataManagerApi.cancelDatasetUpload(action.payload);
        yield put(getUploadHistory(UploadType.DataManager));
        yield put(doneActionSuccess(action.type, messages.CANCEL_DATASET_UPLOAD_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.CANCEL_DATASET_UPLOAD_FAIL_MESSAGE));
    }
}

function* downloadDataset(action: DatasetDownloadAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield dataManagerApi.exportFile(
            action.checkedIds,
            action.checkedAll,
            action.type === DATASET_DOWNLOAD ? null : store.filters[FilterNames.datasetList]
        );

        yield put(doneActionSuccess(action.type));
        if (action.type === DATASET_LIST_DOWNLOAD) {
            yield put(clearChecked(FilterNames.datasetList));
        }
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.DOWNLOAD_FAILED));
    }
}

function* downloadPublishedDataset(action: PublishedDatasetDownloadAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield dataManagerApi.exportPublishedDatasetFile(
            action.checkedIds,
            action.checkedAll,
            store.filters[FilterNames.publishedDatasets]
        );

        yield put(doneActionSuccess(action.type));
        yield put(clearChecked(FilterNames.publishedDatasets));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.DOWNLOAD_FAILED));
    }
}

function* downloadInternalData(action: InternalDataDownloadAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield dataManagerApi.exportInternalDataFile(
            action.checkedIds,
            action.checkedAll,
            store.filters[FilterNames.internalDataTab]
        );
        yield put(doneActionSuccess(action.type));
        yield put(clearChecked(FilterNames.internalDataTab));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.DOWNLOAD_FAILED));
    }
}

function* downloadCombinedData(action: CombinedDataDownloadAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield dataManagerApi.exportCombinedDataFile(
            action.checkedIds,
            action.checkedAll,
            store.filters[FilterNames.internalDataTab]
        );
        yield put(doneActionSuccess(action.type));
        yield put(clearChecked(FilterNames.internalDataTab));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.DOWNLOAD_FAILED));
    }
}

function* datasetUpload(action: DatasetUploadAction) {
    try {
        yield put(initAction(action.type));
        yield dataManagerApi.datasetUpload(action.files);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.UPLOAD_FILE_FAILED));
    }
}

function* internalDataUpload(action: InternalDataUploadAction) {
    try {
        yield put(initAction(action.type));
        yield dataManagerApi.internalDataUpload(action.files);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.UPLOAD_FILE_FAILED));
    }
}

function* watchApplyFilter() {
    yield takeEvery(APPLY_FILTER, applyFilter);
}

function* watchApplyFilteInternalData() {
    yield takeEvery(APPLY_FILTER_INTERNAL_DATA, applyFilterInternalData);
}

function* watchApplyFilterPublishedDatasets() {
    yield takeEvery(APPLY_FILTER_PUBLISHED_DATASETS, applyFilterPublishedDatasets);
}

function* watchApplyFilterDataset() {
    yield takeEvery(APPLY_FILTER_DATASET_DETAIL, applyFilterDataset);
}

function* watchApplyFilterAclUsers() {
    yield takeEvery(APPLY_FILTER_ACL_USERS, applyFilterAclUsers);
}

function* watchInviteAclUser() {
    yield takeEvery(INVITE_ACL_USER, inviteAclUser);
}

function* watchDeleteAclUsers() {
    yield takeEvery(DELETE_ACL_USERS, deleteAclUsers);
}

function* watchSetRoleAclUsers() {
    yield takeEvery(SET_ROLE_ACL_USERS, setRoleAclUsers);
}

function* watchBecomeAclOwner() {
    yield takeEvery(BECOME_ACL_OWNER, becomeAclOwner);
}

function* watchRequestAclAccess() {
    yield takeEvery(REQUEST_ACL_ACCESS, requestAclAccess);
}

function* watchGetRequestorsToConfirmPublish() {
    yield takeEvery(GET_REQUESTORS_TO_CONFIRM_PUBLISH, getRequestorsToConfirmPublish);
}

function* watchGetRequestorsToSetPendingPublish() {
    yield takeEvery(GET_REQUESTORS_TO_SET_PENDING_PUBLISH, getRequestorsToSetPendingPublish);
}

function* watchPublishDataset() {
    yield takeEvery(CONFIRM_PUBLISH_DATASET, confirmPublishDataset);
}

function* watchPublishDatasetFromDetail() {
    yield takeEvery(CONFIRM_PUBLISH_DATASET_FROM_DETAIL, confirmPublishDatasetFromDataset);
}

function* watchSetPendingPublishDataset() {
    yield takeEvery(SET_PENDING_PUBLISH_DATASET, setPendingPublishDataset);
}

function* watchSetPendingPublishDatasetDetail() {
    yield takeEvery(SET_PENDING_PUBLISH_DATASET_DETAIL, setPendingPublishDatasetDetail);
}

function* watchEditPendingPublishDataset() {
    yield takeEvery(EDIT_PENDING_PUBLISH_DATASET, editPendingPublishDataset);
}

function* watchUpdateDatasetDetailField() {
    yield takeEvery(UPDATE_DATASET_DETAIL_FIELD, updateDatasetDetailField);
}

function* watchUnlockDatasetRow() {
    yield takeLatest(UNLOCK_DATASET_DETAIL_ROW, unlockDatasetRow);
}
function* watchGetDatasetHistory() {
    yield takeLatest(GET_DATASET_HISTORY, getDatasetHistory);
}
function* watchGetDatasetFund() {
    yield takeLatest(GET_DATASET_FUND, getDatasetFund);
}
function* watchAddDatasetToFund() {
    yield takeLatest(ADD_DATASET_TO_FUND, addDatasetToFund);
}

function* watchCancelDatasetUpload() {
    yield takeEvery(CANCEL_DATASET_UPLOAD, cancelDatasetUpload);
}

function* watchDatasetUpload() {
    yield takeLatest(DATASET_UPLOAD, datasetUpload);
}

function* watchInternalDataUpload() {
    yield takeLatest(INTERNAL_DATA_UPLOAD, internalDataUpload);
}

function* watchDatasetDownload() {
    yield takeLatest(DATASET_LIST_DOWNLOAD, downloadDataset);
    yield takeLatest(DATASET_DOWNLOAD, downloadDataset);
}

function* watchPublishedDatasetDownload() {
    yield takeLatest(PUBLISHED_DATASET_DOWNLOAD, downloadPublishedDataset);
}

function* watchInternalDataDownload() {
    yield takeLatest(INTERNAL_DATA_DOWNLOAD, downloadInternalData);
}

function* watchCombinedDataDownload() {
    yield takeLatest(COMBINED_DATA_DOWNLOAD, downloadCombinedData);
}

function* watchGetRequestorsToDataManager() {
    yield takeEvery(GET_REQUESTORS, getRequestorsToDataManager);
}

function* watchSaveSettingsFundDataset() {
    yield takeEvery(SAVE_SETTINGS_DATASET_FUND, saveSettingsFundDataset);
}

function* watchChangeCachedStatusForFund() {
    yield takeEvery(CHANGE_CACHED_STATUS_FOR_FUND, changeCachedStatusFund);
}

function* watchChangeRequestStatusFromDataManager() {
    yield takeEvery(ACCEPT_REQUEST, changeRequestStatusFromDataManager);
    yield takeEvery(DENY_REQUEST, changeRequestStatusFromDataManager);
}

export default function* root() {
    yield all(
        [
            watchApplyFilter(),
            watchApplyFilteInternalData(),
            watchApplyFilterPublishedDatasets(),
            watchApplyFilterAclUsers(),
            watchInviteAclUser(),
            watchDeleteAclUsers(),
            watchSetRoleAclUsers(),
            watchApplyFilterDataset(),
            watchBecomeAclOwner(),
            watchRequestAclAccess(),
            watchGetRequestorsToConfirmPublish(),
            watchGetRequestorsToSetPendingPublish(),
            watchPublishDataset(),
            watchSetPendingPublishDataset(),
            watchSetPendingPublishDatasetDetail(),
            watchEditPendingPublishDataset(),
            watchUnlockDatasetRow(),
            watchUpdateDatasetDetailField(),
            watchGetDatasetHistory(),
            watchGetDatasetFund(),
            watchAddDatasetToFund(),
            watchCancelDatasetUpload(),
            watchDatasetUpload(),
            watchInternalDataUpload(),
            watchDatasetDownload(),
            watchPublishedDatasetDownload(),
            watchInternalDataDownload(),
            watchCombinedDataDownload(),
            watchGetRequestorsToDataManager(),
            watchChangeRequestStatusFromDataManager(),
            watchSaveSettingsFundDataset(),
            watchChangeCachedStatusForFund(),
            watchPublishDatasetFromDetail(),
        ]
    );
}
