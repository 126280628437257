import CreditApiHelper, { API_PORTFOLIO_URL } from 'helpers/creditApiHelper';
import { ApiHelper } from 'helpers/apiHelper';

import { PortfolioFilterModel } from 'store/Portfolio/models/filter';
import { NewPortfolioModel } from 'store/Portfolio/models/portfolio';
import { Id } from 'store/types';
import { deniedGetParams } from 'store/constants';
import { FiltersState } from 'store/Filters/types';

export const getPortfolios = async (filter: PortfolioFilterModel | FiltersState): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/portfolios/`,
        'get',
        {
            params: filter
        }
    );

    return Promise.resolve(response);
};

export const getPortfolioById = async (id: Id): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/portfolios/${id}/`,
        'get',
        {}
    );

    return Promise.resolve(response);
};

export const deletePortfolios = async (ids: Id[], checkedAll: boolean, filter: PortfolioFilterModel): Promise<any> => {
    let url = `${API_PORTFOLIO_URL}/portfolios/bulk-delete/`;
    const params = ApiHelper.getClearParams(filter, deniedGetParams);
    const body = {
        ids,
        checkedAll
    };
    const response = await CreditApiHelper.doRequest(url, 'post', body, '', true, params);

    return Promise.resolve(response);
};

export const createPortfolio = async (newPortfolio: NewPortfolioModel) => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/portfolios/`,
        'post',
        {
            ...newPortfolio
        }
    );

    return Promise.resolve(response);
};

export const importFiles = async (files: any) => {
    try {
        const response = await CreditApiHelper.post(
            `${API_PORTFOLIO_URL}/import-files/`,
            files,
            'multipart/form-data'
        );
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getUploadHistory = async (filter: any): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/`,
        'get',
        {
            params: filter
        }
    );

    return Promise.resolve(response);
};

export const getUploadedRows = async (payload: any): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/${payload.id}/rows`,
        'get',
        {
            params: payload
        }
    );

    return Promise.resolve(response);
};

export const getUploadedStats = async (payload: any): Promise<any> => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/${payload.id}/stats`,
        'get',
        {
            ...payload
        }
    );

    return Promise.resolve(response);
};

export const exportFile = async (checkedIds: Id[], checkedAll: boolean, portfolioId: Id, filter?: any) => {
    const filteredParams = filter ? ApiHelper.getClearParams(filter, deniedGetParams) : {};
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/requestor/funds/export-funds/`,
        'get',
        {
            params: {
                ...filteredParams,
                checkedAll,
                fund_id: checkedIds,
                portfolio_id: portfolioId,
            }
        }
    );

    return Promise.resolve(response);
};

export const createImportEntities = async (importId: Id) => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/${importId}/create-entities/`,
        'post',
        {}
    );

    return Promise.resolve(response);
};

export const updateBulkUploadRow = async (importId: Id, rowId: Id, data: any) => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/${importId}/rows/${rowId}/`,
        'put',
        {
            ...data
        }
    );

    return Promise.resolve(response);
};

export const deleteUploadedRows = async (payload: any, fileId: Id, checkedAll: boolean) => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/${fileId}/rows/bulk-delete/`,
        'post',
        {
            rows: payload,
            checkedAll,
        }
    );

    return Promise.resolve(response);
};

export const updateDescription = async (payload: any) => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/portfolios/${payload.portfolio.id}/`,
        'patch',
        {
            ...payload.portfolio,
            description: payload.value,
        }
    );

    return Promise.resolve(response);
};

export const cancelUpload = async (payload: any) => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/${payload.fileId}/cancel/`,
        'post',
        {}
    );

    return Promise.resolve(response);
};

export const reprocessUpload = async (fileId: Id, rowId: Id) => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/${fileId}/rows/${rowId}/reprocess/`,
        'post',
        {}
    );

    return Promise.resolve(response);
};

export const getSuggestions = async (fileId: Id, rowId: Id) => {
    const response = await CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/${fileId}/rows/${rowId}/suggestions/`,
        'get',
        {}
    );

    return Promise.resolve(response);
};

export const getFilterLabels = async (type: string, id: string[]): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/labels/`,
        'get',
        {params: {type, id, limit: id.length}}
    );
};

export const getUncompletedPortfolioImport = async (): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_PORTFOLIO_URL}/import-files/uncompleted-import/`,
        'get',
        {}
    );
};
