import ActiveUser from './models/activeUser';
import {
    GET_USER, GET_DILIGENCE_USER, INITIALIZE_RESET_PASSWORD, LOGIN, LOGOUT, RESET_PASSWORD, SET_USER,
    SWITCH_ACCOUNT, CHANGE_REMEMBER_COOKIE,
    DISABLE_TWO_FACTOR_AUTHENTICATION,
    GENERATE_TFA_SECRET,
    SET_TFA_SECRET, SET_TWO_FACTOR_AUTH_SETTINGS,
    VERIFY_SETUP_CODE
} from './actions';
import { Id } from 'store/types';
import TwoFactorAuthSettings from './models/twoFactorAuthSettings';

export const getUser = () => ({
    type: GET_USER
});

export const setUser = (user: ActiveUser) => ({
    type: SET_USER,
    user
});

export const getDiligenceUser = () => ({
    type: GET_DILIGENCE_USER
});

export const switchAccount = (relationId: Id) => ({
    type: SWITCH_ACCOUNT,
    relationId
});

export const logout = () => ({
    type: LOGOUT
});

export const login = () => ({
    type: LOGIN
});

export const initializeResetPassword = (email: string) => ({
    type: INITIALIZE_RESET_PASSWORD,
    email
});

export const resetPassword = (password: string, token: string, resetKey: string) => ({
    type: RESET_PASSWORD,
    password, token, resetKey
});

export const setTFASecret = (secret: string, qrURI: string) => ({
    type: SET_TFA_SECRET,
    secret, qrURI
});

export const disableTwoFactorAuth = (user: ActiveUser) => ({
    type: DISABLE_TWO_FACTOR_AUTHENTICATION,
    user
});

export const generateTFASecret = (tfaType: string, phone: string | null) => ({
    type: GENERATE_TFA_SECRET,
    tfaType, phone
});

export const verifySetupCode = (code: string, user: ActiveUser) => ({
    type: VERIFY_SETUP_CODE,
    code, user
});

export const setTwoFactorAuthSettings = (data: TwoFactorAuthSettings, user: ActiveUser) => ({
    type: SET_TWO_FACTOR_AUTH_SETTINGS,
    data, user
});

export const changeRememberCookie = (remember: boolean, user: ActiveUser) => ({
    type: CHANGE_REMEMBER_COOKIE,
    remember, user
});