import axios, { AxiosInstance } from 'axios';
import * as qs from 'qs';

import { ApiHelper } from 'helpers/apiHelper';
import GlobalHelper from 'helpers/globalHelper';

export const BASE_DILIGENCE_URL = `${process.env.REACT_APP_DILIGENCE_URL}`;
export const BASE_API_URL = `${BASE_DILIGENCE_URL}/api`;
export const API_USER_URL = `${BASE_API_URL}/users`;

export const transformUserData = (response: any) => {
    const user = response.data;
    const activeAccount = user.accounts.find(account => {
        return account._id.id === user.activeAccountId.id;
    });

    return {
        ...user,
        account_relations: [],
        active_account: activeAccount ? {
            account: {
                id:     activeAccount._id.id,
                name:   activeAccount.name,
                status: activeAccount.status,
                type:   activeAccount.type
            }
        } : undefined,
        first_name: user.firstName,
        last_name: user.lastName,
        is_authenticated: true,
        has_diligence_account: true
    };
};

const diligenceAxiosClient: AxiosInstance = axios.create({
    baseURL: BASE_API_URL,
    withCredentials: true,
    paramsSerializer: (params: any) => {
        return qs.stringify(params, {arrayFormat: 'repeat'});
    },
});

diligenceAxiosClient.interceptors.response.use(
    function (response: any): any {
        if (response.status === 204 && !GlobalHelper.isIE()) {
            return new Response(null, response);
        }
        return new Response(JSON.stringify(response.data), response);
    },
    function (error: any): any {
        const response = error.response;
        return Promise.resolve(new Response(JSON.stringify(response.data), response));
    }
);

const diligenceApiHelper = new ApiHelper(diligenceAxiosClient);

export default diligenceApiHelper;
