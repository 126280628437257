import * as React from 'react';
import * as classNames from 'classnames';
import { Chip as ChipType } from 'store/Filters/types';

interface CheckboxType {
    disabled?: boolean;
    name: string;
    value: string;
    nameTitle?: string;
    valueTitle?: string;
    handleClose: (chip: ChipType) => void;
}

const styles = require('./Chip.scss');
const Chip: React.FC<CheckboxType> = ({disabled, name, value, nameTitle, valueTitle, handleClose}) => {
    const ClassNames = classNames({
        [styles.chip]: true,
        [styles.disabled]: disabled
    });

    const handleClick = () => {
        if (!disabled) {
            handleClose({name, value, valueTitle});
        }
    };

    return (
        <div className={ClassNames}>
            <span className={styles['chip-value']}>
                {`${nameTitle || name}: ${valueTitle || value}`}
            </span>
            <span className={styles['close-icon']} onClick={handleClick} />
        </div>
    );
};

Chip.defaultProps = {
    disabled: false
};

export default Chip;
