import { delay } from 'redux-saga';
import { all, put } from 'redux-saga/effects';
import { doAppHeartBeat } from './actions';

function* heartBeat() {
    while (true) {
        yield delay(5000);
        yield put(doAppHeartBeat());
    }
}

export default function* root() {
    yield all(
        [
            heartBeat()
        ]
    );
}