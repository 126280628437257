import * as React from 'react';

import SmoothScrollbar from 'smooth-scrollbar';
import { ScrollbarOptions, ScrollStatus } from 'smooth-scrollbar/interfaces';

import Scrollbar from './Scrollbar';

const styles = require('./ColoredScrollbar.scss');

interface Props {
    className?: string;
    alwaysShowTracks?: boolean;
    onScroll?: (status: ScrollStatus, scrollbarInstance: SmoothScrollbar) => void;
    ignore?: boolean;
    scrollbarRef?: React.RefObject<Scrollbar>;
    options?: Partial<ScrollbarOptions>;
}

class ColoredScrollbar extends React.PureComponent<Props> {
    static defaultProps = {
        alwaysShowTracks: false
    };

    render() {
        const {className, alwaysShowTracks, onScroll, scrollbarRef, options} = this.props;
        let output = (
            <Scrollbar
                className={`${styles.scrollbar} ${className}`}
                alwaysShowTracks={alwaysShowTracks}
                onScroll={onScroll}
                options={options}
                ref={scrollbarRef}
            >
                {this.props.children}
            </Scrollbar>
        );
        if (this.props.ignore) {
            output = (
               <>
                {this.props.children}
               </>
            );
        }
        return output;
    }
}

export default ColoredScrollbar;