import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import { connect } from 'react-redux';

import * as authHelper from 'helpers/authHelper';
import { isAuthenticated, isInitialized, getUser } from 'store/Auth/selectors';
import { AppState } from 'store/types';
import { logout, getDiligenceUser } from 'store/Auth/actionCreators';
import ActiveUser from 'store/Auth/models/activeUser';
import { isInternal } from 'store/User/helpers';

interface Props {
    component: React.ComponentClass;
    isAuth: boolean;
    isInit: boolean;
    logout: () => any;
    getDiligenceUser: () => void;
    user: ActiveUser;
}

class ProtectedRoute extends React.Component<Props & RouteProps> {
    componentDidUpdate(prevProps: Props) {
        /*
        * When the route component changes, make another request get the user to help
        * ensure that the diligence session doesn't expire
        * */
        /*
        if (prevProps.component !== this.props.component) {
            const {user} = this.props;
            if (user && isInternal(user)) {
                this.props.getDiligenceUser();
            }
        }
        */
    }

    render() {
        const {component: Component, isAuth, isInit, ...rest} = this.props;

        if (!isAuth) {
            if (isInit) {
                authHelper.redirectToLogout();
            }
        } else {
            return (
                <Route
                    {...rest}
                    render={(props) => (
                        <Component {...props} />
                    )}
                />
            );
        }

        return null;
    }
}

const mapStateToProps = (state: AppState) => ({
    isAuth: isAuthenticated(state),
    isInit: isInitialized(state),
    user: getUser(state)
});

const mapDispatchToProps = { logout, getDiligenceUser };

export default connect(mapStateToProps, mapDispatchToProps, null, {pure: false})(ProtectedRoute);
