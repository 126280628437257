import * as React from 'react';
import * as classnames from 'classnames';

interface CheckboxType {
    id?: string;
    idx?: number | string;
    disabled?: boolean;
    value?: string | string[] | number;
    className?: any;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
}

const styles = require('./Checkbox.scss');
const Checkbox: React.FC<CheckboxType> = ({
    id, checked, disabled, children, idx, value, className, handleChange
}) => {
    const ClassNames = classnames({
        [className]: !!className,
        'ui-input': true,
        [styles.ui_checkbox]: !!styles.ui_checkbox,
    });
    const labelClassNames = classnames({
        [styles.disabled]: disabled,
        [styles.checkbox]: true,
    });

    const index = id || `item-${idx}`;
    checked = checked ? checked : false;
    return (
        <div className={ClassNames}>
            <input
                type="checkbox"
                value={value}
                id={index}
                data-id={idx}
                onChange={handleChange}
                checked={checked}
                disabled={disabled}
            />
            <label className={labelClassNames} htmlFor={index}>
                <span>
                    {children || <>&nbsp;</>}
                </span>
            </label>
        </div>
    );
};

export default Checkbox;
