import * as React from 'react';
import * as moment from 'moment';
import * as classNames from 'classnames';

import { DEFAULT_DATE_FORMAT } from 'store/constants';
import UiDatePicker from '../Ui/DatePicker';

interface Props {
    name: string;
    id: string;
    title?: string;
    value?: any;
    displayFormat?: string;
    withLabel?: boolean;
    customInputIcon?: boolean;
    inputIconPosition?: 'before' | 'after';
    material?: boolean;
    handleChange: (data: any) => void;
    className?: any;
    isOutsideRange?: (date: any) => boolean;
    showClearDate?: boolean;
    keepOpenOnDateSelect?: boolean;
}

const styles = require('./DateFilter.scss');

class SingleDateFilter extends React.PureComponent<Props> {

    static defaultProps = {
        withLabel: false
    };

    onChangeHandler = (date) => {
        this.props.handleChange({[this.props.name]: date ? date.format(DEFAULT_DATE_FORMAT) : null });
    }

    render() {
        const {
            className, withLabel, id, title, value, displayFormat, material,
            customInputIcon, inputIconPosition, isOutsideRange,
            showClearDate, keepOpenOnDateSelect
        } = this.props;

        const resultClassName = classNames({
            [styles.date_filter]: !className,
            [className]: !!className,
        });

        return (
            <div className={resultClassName}>
                {withLabel && <h5 className="label">{title || ''}</h5>}
                <UiDatePicker
                    date={value ? moment(value) : null}
                    displayFormat={displayFormat}
                    material={material}
                    customInputIcon={customInputIcon}
                    inputIconPosition={inputIconPosition}
                    id={id}
                    handleChange={this.onChangeHandler}
                    isOutsideRange={isOutsideRange}
                    showClearDate={showClearDate}
                    keepOpenOnDateSelect={keepOpenOnDateSelect}
                />
            </div>
        );
    }
}

export default SingleDateFilter;
