import { Dataset, DatasetFund } from './models/dataset';
import { AclRole } from './types';
import { hasRole } from './aclHelper';
import { hasGlobalRole } from '../../helpers/authHelper';
import { UserGlobalRole } from '../Auth/types';
import ActiveUser from '../Auth/models/activeUser';

export const isNotAccessible = (row: DatasetFund) => !!row.acl && !row.acl_user && !row.datasets_are_empty;
export const datasetsAreEmpty = (row: DatasetFund) => !!row.acl && !!row.acl_user && hasRole(row, AclRole.Viewer)
    && row.datasets_are_empty;
export const datasetsWithData = (row: DatasetFund) => !!row.acl && !!row.acl_user && hasRole(row, AclRole.Viewer)
    && !row.datasets_are_empty;
export const isAccessible = (row: DatasetFund) => !!row.acl && !!row.acl_user && hasRole(row, AclRole.Viewer);
export const awaitingAccess = (row: DatasetFund) => !!row.acl && !!row.acl_user && !hasRole(row, AclRole.Viewer);
export const isAbleToBecomeOwner = (row: DatasetFund) => !row.acl || !!row.acl && !row.acl_user
    && row.datasets_are_empty;
export const getCustomRowStyle = (row: DatasetFund) => {
    return isNotAccessible(row) ? ['translucent'] : [];
};

export const datasetDetailPermission = (fund: DatasetFund) => hasRole(fund, AclRole.Editor);
export const datasetAlphaPipePermission = (user: ActiveUser) =>
    hasGlobalRole(user, UserGlobalRole.Concierge) || hasGlobalRole(user, UserGlobalRole.Sysadmin);

export const isPublished = (row: DatasetFund) => !!row.last_published_dataset;

export const hasHistory = (row: Dataset) => !!row.has_history;
export const isMadeLatest = (row: Dataset) => !!row.made_latest_by;