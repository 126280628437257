import { Action } from 'redux';
import { FiltersState } from 'store/Filters/types';
import { Id } from 'store/types';

import { AlertFund, AlertStatusItem, FundEvaluationResults } from './types';

export const GET_ALERT_LIST = 'store/alertsManager/alerts/getAlertList';
export const SET_ALERT_LIST = 'store/alertsManager/alerts/setAlertList';
export const SET_ALERT_LIST_WITH_SCROLL = 'store/alertsManager/alerts/setAlertListWithScroll';

export const GET_ALERT_STATUS_ITEMS = 'store/alertsManager/alerts/getAlertStatusItems';
export const SET_ALERT_STATUS_ITEMS = 'store/alertsManager/alerts/setAlertStatusItems';

export const GET_FUND_STATISTIC = 'store/alertsManager/alerts/getFundStatistic';
export const SET_FUND_STATISTIC = 'store/alertsManager/alerts/setFundStatistic';
export const SET_FUND_EVALUATION_RESULTS = 'store/alertsManager/alerts/setFundEvaluationResults';
export const SET_FUND_EVALUATION_RESULTS_WITH_SCROLL = 'store/alertsManager/alerts/setFundEvaluationResultsWithScroll';
export const GET_FUND_EVALUATION_RESULTS = 'store/alertsManager/alerts/getFundEvaluationResults';

export interface GetAlertListAction extends Action {
    filter: FiltersState;
}

export interface SetAlertListAction extends Action {
    count: number;
    alertCount: number;
    list: AlertFund[];
}

export interface GetAlertStatusItemsAction extends Action {
    period: string;
}

export interface SetAlertStatusItemsAction extends Action {
    data: AlertStatusItem[];
}

export interface GetFundStatisticAction extends Action {
    id: Id;
    period: string;
}

export interface SetFundStatisticAction extends Action {
    statistic: AlertFund;
}

export interface SetFundEvaluationResultsAction extends Action {
    results: FundEvaluationResults;
}

export interface GetFundEvaluationResultsAction extends Action {
    id: Id;
    filter: FiltersState;
}

export type AlertsAction = GetFundStatisticAction
    | SetFundStatisticAction
    | SetFundEvaluationResultsAction
    | GetFundEvaluationResultsAction
    | GetAlertListAction
    | SetAlertListAction
    | GetAlertStatusItemsAction
    | SetAlertStatusItemsAction;