import { headerNames, HeadersState } from './types';
import { SET_HEADERS_WIDTH, SetHeadersAction, SetHeadersWidthAction } from './actions';

import { GET_HEADERS, SET_HEADERS } from './actions';
import { headers as datasetHeaders } from 'components/DataManager/Dataset';
import { headers as publishedDatasetsHeaders } from 'components/DataManager/PublishedDatasetsView';
import { fundDatasetsHeaders, fundListHeaders } from 'containers/Fund/constants';
import { TableHeader } from '../types';
import { DEFAULT_VISIBLE_HEADERS } from './constants';
import { targetSectionHeaders } from 'containers/Profiles/Groups/constants';

const initialState: HeadersState = {
    [headerNames.datasetDetail]: datasetHeaders,
    [headerNames.publishedDatasets]: publishedDatasetsHeaders,
    [headerNames.fundDatasets]: fundDatasetsHeaders,
    [headerNames.fundList]: fundListHeaders,
    [headerNames.profilesGroupsTargetSection]: targetSectionHeaders
};

export const headersReducer = (state: HeadersState = initialState, action: any) => {
    switch (action.type) {
        case GET_HEADERS:
            return {
                ...state,
                [action.block]: action.headers
            };
        case SET_HEADERS:
            action = (<SetHeadersAction> action);
            let setHeadersAnswer: any = null;

            if (action.isFromBackend) {
                let newSetsHeaders: any[] = [];

                newSetsHeaders = state[action.block].map((headerItem: TableHeader) => {
                    let currentItem: TableHeader = { ...headerItem, active: false };
                    if (currentItem.columnName && (action.headers.indexOf(currentItem.columnName) !== -1
                        || DEFAULT_VISIBLE_HEADERS[action.block].indexOf(currentItem.columnName) !== -1) ||
                        DEFAULT_VISIBLE_HEADERS[action.block].indexOf(currentItem.name) !== -1
                    ) {
                        currentItem.active = true;
                    }

                    return currentItem;
                });

                setHeadersAnswer = {
                    ...state,
                    [action.block]: newSetsHeaders
                };

            } else {
                setHeadersAnswer = {
                    ...state,
                    [action.block]: action.headers
                };
            }
            return setHeadersAnswer;
        case SET_HEADERS_WIDTH:
            action = (<SetHeadersWidthAction> action);
            return {
                ...state,
                [action.block]: state[action.block].map((item: any) => {
                    let answer = item;

                    if (action.element.hasOwnProperty(item.name)) {
                        answer = {
                            ...item,
                            width: action.element[item.name]
                        };
                    }

                    return answer;
                })
            };
        default:
            return state;
    }
};