import { DEFAULT_FILTER, defaultSortPagination } from 'store/constants';
import { SelectItem } from '../types';
import { DueDateType, Frequency, RequestedField, RequestType } from './types';

export const REQUESTS_BULK_UPLOAD_NAME = 'requests';

export const CONTACTS_MODAL = 'REQUESTS_CONTACTS_MODAL';
export const EDIT_MODAL = 'REQUESTS_EDIT_MODAL';
export const BULK_UPLOAD_MODAL = 'BULK_UPLOAD_MODAL';
export const SEND_BULK_REQUESTS_MODAL = 'SEND_BULK_REQUESTS_MODAL';
export const RESEND_BULK_REQUESTS_MODAL = 'RESEND_BULK_REQUESTS_MODAL';
export const SEND_BULK_REQUESTS_MODAL_TITLE = 'Confirm Bulk Action';
export const SEND_REQUEST_MODAL_TITLE = 'Confirm Send Action';
export const RESEND_REQUEST_MODAL_TITLE = 'Confirm Resend';

export const BULK_UPLOAD_TYPE_REQUEST = '2';

export const messages = {
    SAVE_REQUEST_SUCCESS_MESSAGE: 'Request was successfully saved',
    SAVE_REQUEST_FAIL_MESSAGE: 'Request saving failed',
};

export const defaultRequestPermission = {
    can_send_request: true,
    can_edit_request: true,
    can_create_request: true,
};

export const defaultRequestListPermissions = {
    can_bulk_upload_requests: true,
    can_bulk_edit_requests: true,
    can_bulk_send_requests: true,
};

export const DefaultEntityFilterState = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        frequency: null,
        ordering: 'fund_name',
    },
    headerType: 'requests'
};

export const initialState = {
    requestsCount: 0,
    requests: [],
    permissions: {...defaultRequestListPermissions},
    managerScreenRequestsCount: 0,
    managerScreenRequests: [],
    pendingRequestsCount: 0,
    pendingNotificationsFlag: true,
    editor: {
        request: null,
        contacts: [],
        isEditMode: false
    }
};

export const FREQUENCY_SELECT: SelectItem[] = [
    {label: 'Weekly', value: Frequency.Weekly},
    {label: 'Monthly', value: Frequency.Monthly},
    {label: 'Quarterly', value: Frequency.Quarterly},
    {label: 'Yearly', value: Frequency.Yearly},
];

export const DUE_DATE_TYPE_SELECT: SelectItem[] = [
    {label: 'Calendar', value: DueDateType.Calendar},
    {label: 'Business', value: DueDateType.Business},
];

export const REQUEST_TYPE_SELECT: SelectItem[] = [
    {label: 'Recurring', value: RequestType.Recurring},
    {label: 'Adhoc', value: RequestType.Adhoc}
];

export const REQUESTED_FIELDS_SELECT: SelectItem[] = [
    {label: 'Beginning Balance', value: RequestedField.BeginningBalance},
    {label: 'Ending Balance', value: RequestedField.EndingBalance},
    {label: 'Subscriptions', value: RequestedField.Subscriptions},
    {label: 'Redemptions', value: RequestedField.Redemptions},
    {label: 'Known Future Redemptions', value: RequestedField.KnownFutureRedemptions},
    {label: 'Income', value: RequestedField.Income},
    {label: 'Nav Per Share', value: RequestedField.NavPerShare},
    {label: 'Perf - Daily', value: RequestedField.PerfDaily},
    {label: 'Perf - Weekly', value: RequestedField.PerfWeekly},
    {label: 'Perf - Month', value: RequestedField.PerfMonth},
    {label: 'Perf - MTD', value: RequestedField.PerfMtd},
    {label: 'Perf - QTD', value: RequestedField.PerfQtd},
    {label: 'Perf - YTD', value: RequestedField.PerfYtd},
];

export const REQUESTED_FIELDS_TITLES = {
    [RequestedField.BeginningBalance]: 'Beginning Balance',
    [RequestedField.EndingBalance]: 'Ending Balance',
    [RequestedField.Subscriptions]: 'Subscriptions',
    [RequestedField.Redemptions]: 'Redemptions',
    [RequestedField.KnownFutureRedemptions]: 'Known Future Redemptions',
    [RequestedField.Income]: 'Income',
    [RequestedField.NavPerShare]: 'Nav Per Share',
    [RequestedField.PerfDaily]: 'Perf - Daily',
    [RequestedField.PerfWeekly]: 'Perf - Weekly',
    [RequestedField.PerfMonth]: 'Perf - Month',
    [RequestedField.PerfMtd]: 'Perf - MTD',
    [RequestedField.PerfQtd]: 'Perf - QTD',
    [RequestedField.PerfYtd]: 'Perf - YTD'
};

export const DUE_DATE_TYPE_TITLES = {
    [DueDateType.Business]: 'Business Day',
    [DueDateType.Calendar]: 'Calendar Day',
};

export const TYPE_TITLES = {
    [RequestType.Recurring]: 'Recurring',
    [RequestType.Adhoc]: 'Adhoc',
};
