import {
    SET_FUND_LIST, SET_FUND_LIST_WITH_SCROLL, SET_PEER_GROUP_LIST, SET_PEER_GROUP_LIST_WITH_SCROLL
} from './actions';
import { PeerGroup, TargetFund } from 'store/Fund/types';

export const setFundListForAddItemsModal = (
    count: number, firmCount: number, list: TargetFund[], withScroll = false
) => ({
    type: withScroll
        ? SET_FUND_LIST_WITH_SCROLL
        : SET_FUND_LIST,
    count,
    firmCount,
    list,
});

export const setPeerGroupListForAddItemsModal = (count: number, list: PeerGroup[], withScroll = false) => ({
    type: withScroll
        ? SET_PEER_GROUP_LIST_WITH_SCROLL
        : SET_PEER_GROUP_LIST,
    count,
    list,
});
