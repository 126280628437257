import * as React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as classNames from 'classnames';

import { TableRowActionIcon } from 'store/constants';
import { getIconSourceByName } from 'store/helpers';

const styles = require('./DropdownIcon.scss');

interface Props {
    icon: TableRowActionIcon;
    right?: boolean;
    items: DropdownIconItem[];
    onClick: (item: DropdownIconItem) => void;
    className?: string;
}

interface State {
    isOpen: boolean;
}

export interface DropdownIconItem {
    id: number | string;
    label: string;
}

export default class DropdownIcon extends React.PureComponent<Props, State> {
    state = {
        isOpen: false
    };

    toggle = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    render() {
        const { icon, items, onClick } = this.props;
        const iconSource = getIconSourceByName(icon);

        const iconClass = classNames({test: true});

        return (
            <UncontrolledDropdown tag="span" className={styles['dropdown-icon']}>
                <DropdownToggle tag="a">
                    <img src={iconSource} alt={icon} className={iconClass} />
                </DropdownToggle>
                <DropdownMenu>
                    {
                        items.map((item: DropdownIconItem) =>
                            (
                                <DropdownItem key={item.id} tag="button" onClick={() => onClick(item)}>
                                    {item.label}
                                </DropdownItem>
                            )
                        )
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}
