import { all } from 'redux-saga/effects';

import alertsSagas from './Alerts/sagas';
import rulesetsSagas from './Rulesets/sagas';

export default function* root() {
    yield all(
        [
            alertsSagas(),
            rulesetsSagas(),
        ]
    );
}