import * as React from 'react';

import { SetCheckedIdsAction } from 'store/Filters/actions';
import { FiltersState } from 'store/Filters/types';

interface ActionProps {
    currentFilter: FiltersState;
    setCheckedIds: (filterName: string, payload: FiltersState) => SetCheckedIdsAction;
    count: number;
}

export default function Action(WrappedComponent: any, filterName: string = '') {

    return class extends React.PureComponent<ActionProps> {
        render() {
            return (
                <div>
                    <WrappedComponent
                        {...this.props}
                        chooseRow={this.chooseRow}
                        chooseAll={this.chooseAll}
                    />
                </div>
            );
        }

        chooseRow = (id: any) => {
            const {checkedIds, checkedAll} = this.props.currentFilter;
            const count = this.props.count;
            let checkedAllNew = checkedAll;
            let newIds: any;
            if (checkedIds.indexOf(id) > -1) {
                newIds = checkedIds.filter((idx: any) => {
                    return idx !== id;
                });
            } else {
                newIds = checkedIds.concat(id);
            }

            if (newIds && count && newIds.length === count || !newIds) {
                checkedAllNew = !checkedAllNew;
                newIds = [];
            }

            if (filterName.length && this.props.setCheckedIds) {
                this.props.setCheckedIds(
                    filterName,
                    {checkedAll: checkedAllNew, checkedIds: newIds}
                );
            }
        }

        chooseAll = () => {
            if (filterName.length && this.props.setCheckedIds) {
                this.props.setCheckedIds(
                    filterName,
                    {checkedAll: !this.props.currentFilter.checkedAll, checkedIds: []}
                );
            }
        }
    };
}
