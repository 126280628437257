import { AxiosInstance } from 'axios';
import * as _ from 'lodash';

import { deniedGetParams } from 'store/constants';
import { ApiResponseData } from 'store/types';

export class ApiHelper {
    instance: AxiosInstance;

    static getErrors(data: ApiResponseData | string, defaultError: string = 'Undefined error') {
        if (_.isString(data)) {
            return [data];
        } else if (_.isObject(data)) {
            if (_.isEmpty(data)) {
                return null;
            } else if (data.hasOwnProperty('error')) {
                return [data.error];
            } else if (data.hasOwnProperty('message')) {
                return [data.message];
            } else if (data.hasOwnProperty('errors')) {
                return data.errors;
            } else if (data.hasOwnProperty('detail')) {
                return [data.detail];
            }

            const errors: string[] = [];

            Object.keys(data).forEach(key => {
                if (Array.isArray(data[key]) && data[key].length > 0 && _.isObject(data[key][0])) {
                    data[key].forEach((item: any, idx: number) => {
                        const errorKey = `${key} #${idx + 1}`;
                        const errorMessages = ApiHelper.getErrors(item);
                        if (errorMessages) {
                            if (Array.isArray(errorMessages)) {
                                errorMessages.forEach(errorMessage => errors.push(`${errorKey}: ${errorMessage}`));
                            } else {
                                errors.push(`${errorKey}: ${errorMessages}`);
                            }
                        }
                    });
                } else {
                    errors.push(`${key}: ${data[key]}`);
                }
            });

            return errors;
        } else {
            return defaultError;
        }
    }

    static getCsrfToken() {
        const name = `${process.env.REACT_APP_CSRF_COOKIE_NAME}`;
        let cookieValue: any = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie: any = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    // returns object that contains filter values after clearing using exceptionArray
    static getClearParams(filter: any, exceptionArray: string[] = deniedGetParams): any {
        return Object.keys(filter)
            .filter((key) => {
                return filter.hasOwnProperty(key) && filter[key] !== null && filter[key] !== ''
                    && exceptionArray.indexOf(key) === -1;
            })
            .reduce(
                (previousValue: any, currentItem: any): any => {
                    return {
                        ...previousValue,
                        [currentItem]: filter[currentItem]
                    };
                },
                {}
            );
    }

    constructor(instance: AxiosInstance) {
        this.instance = instance;
    }

    post(
        url: string, body: {}, contentTypeParam?: string, isStringify: boolean = true, params: object = {}
    ): Promise<Response> {
        const contentType = contentTypeParam || 'application/json';
        let finalBody;
        if (contentTypeParam && contentTypeParam === 'multipart/form-data') {
            finalBody = body;
        } else {
            finalBody = isStringify ? JSON.stringify(body) : body;
        }

        const headers = {
            'Content-Type': contentType,
            'X-CSRFToken': ApiHelper.getCsrfToken(),
        };

        return this.instance.post(url, finalBody, { headers, params }) as any;
    }

    put(
        url: string,
        body: {},
        contentTypeParam?: string,
        isStringify: boolean = true,
        onProgress?: (res: any) => any,
        params: object = {},
    ): Promise<Response> {
        const contentType = contentTypeParam || 'application/json';
        const finalBody = isStringify ? JSON.stringify(body) : body;

        const headers = {
            'Content-Type': contentType,
            'X-CSRFToken': ApiHelper.getCsrfToken(),
        };

        return this.instance.put(url, finalBody, {
            headers,
            params,
            onUploadProgress: onProgress
        }) as any;
    }

    patch(url: string, body: {}, contentTypeParam?: string, isStringify: boolean = true): Promise<Response> {
        const contentType = contentTypeParam || 'application/json';
        const finalBody = isStringify ? JSON.stringify(body) : body;

        return this.instance.patch(url, finalBody, {
            headers: {
                'Content-Type': contentType,
                'X-CSRFToken': ApiHelper.getCsrfToken(),
            }
        }) as any;
    }

    get(url: string, config: object): Promise<Response> {
        return this.instance.get(url, {
            ...config,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': ApiHelper.getCsrfToken(),
            }
        }) as any;
    }

    delete(url: string): Promise<Response> {
        return this.instance.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': ApiHelper.getCsrfToken(),
            }
        }) as any;
    }

    async doRequest(url: string, type: string, ...rest: any[]): Promise<any> {
        try {
            const response = await this[type](url, ...rest);

            let data = {};
            if (!(response.status === 204 && (response.body === null || !response.body))) {
                data = await response.json();
            }

            if (!response.ok) {
                return Promise.reject(ApiHelper.getErrors(data));
            }

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject([error.message]);
        }
    }
}
