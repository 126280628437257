import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import {
    AdvancedFilterDecoratorSearch, AdvancedFilterDecoratorToggle, AdvancedFilterDecoratorUpdate
} from 'decorators/AdvancedFilterDecorator';

import UiDataTable from 'components/Shared/Ui/Tables/UiDataTable';
import DataTable from 'components/Shared/DataTable/DataTable';
import ButtonPrimary from 'components/Shared/Ui/Buttons/ButtonPrimary';
import FirmFundNamesCell from 'components/Shared/DataTable/Cells/FirmFundNames';
import PublishedDatasetStatusCell from 'components/DataManager/TableCells/PublishedDatasetStatusCell';
import DynamicFormatCurrency from 'components/DataManager/TableCells/DynamicFormatCurrency';
import PublishedDatasetsColumnsModal from 'containers/DataManager/PublishedDatasetsColumnsModal';
import ConfirmPublishModal from 'containers/Shared/PublishModals/ConfirmPublishModal';

import { Dataset } from 'store/DataManager/models/dataset';
import {
    AdvancedFilterItem,
    FilterItem,
    Id,
    SelectItem,
    TableBulkAction,
    TableHeader,
    TableRowAction
} from 'store/types';
import {
    CellTypeChoices, FilterType, FormatTypeChoices, TableBulkActionChoices, TableRowActionIcon,
    TableRowActionType, Alignment, DEFAULT_VALUE_DASH, DEFAULT_ASYNC_FILTER,
} from 'store/constants';
import { PUBLISHED_DATASETS_COLUMNS_MODAL, DATA_VERSION } from 'store/DataManager/constants';
import { Chip } from 'store/Filters/types';
import { AsyncFilterName, FilterNames, FiltersState } from 'store/Filters/types';
import { OpenModalAction } from 'store/Modals/General/actions';
import { SetHeadersAction } from 'store/VisibleHeaders/actions';
import { FilterEntity } from 'store/Diligence/DiligenceRequest/types';
import { PortfolioModel } from 'store/Portfolio/models/portfolio';
import { userAccountHasMasterPortfolio } from 'store/Account/helpers';
import { UserAccount } from 'store/Auth/models/userAccount';

export interface Props {
    userAccount: UserAccount;
    results: Dataset[];
    count: number;
    currentFilter: FiltersState;
    isLoading: boolean;
    isGridLoading: boolean;
    handleChange: (data: any) => void;
    handleColumns: (columns: any) => void;
    checkedAll: boolean;
    checkedIds: Id[];
    chooseAll: () => void;
    chooseRow: (id: Id) => void;
    openModal: (name: string, params: any) => OpenModalAction;
    requestAccess: (fundId: Id) => void;
    becomeOwner: (fundId: Id) => void;
    visibleHeaders: TableHeader[];
    setHeaders: (block: string, headers: TableHeader[]) => SetHeadersAction;
    download: (checkedIds: Id[], checkedAll: boolean) => any;
    filterPageName?: string;
    chips: Chip[];
    portfolioChoices: SelectItem[];
    portfolioCount: number;
    portfolioFilter: FiltersState;
    getChoices: (page: string, filterName: string, filter: FiltersState) => void;
    setAsyncFilters: (filterName: string, filter: FiltersState, pageName: string) => void;
    /* ADVANCED FILTERS */
    searchFilterEntities: FilterEntity[];
    setGlobalChoices: (results: PortfolioModel[], count: number) => void;
    handleToggleModal?: AdvancedFilterDecoratorToggle;
    handleUpdateModal?: AdvancedFilterDecoratorUpdate;
    handleAttributeSearch?: AdvancedFilterDecoratorSearch;
    isModalOpen: boolean;
    advancedFilter?: FiltersState;
}

const styles = require('./DataManager.scss');

export const headers: TableHeader[] = [
        {
            name: 'firm_fund',
            title: 'Firm\nFund',
            headerAlignment: Alignment.Left,
            alias: 'firm-fund',
            active: true,
            cellType: CellTypeChoices.Custom,
            transformer: row => (
                <FirmFundNamesCell
                    firm={row.fund && row.fund.firm ? row.fund.firm.name : ''}
                    fund={row.fund ? row.fund.name : ''}
                />
            )
        },
        {
            name: 'published_ct_dataset.period',
            title: 'Period',
            active: true,
            orderingName: 'period',
            formatType: FormatTypeChoices.DateFormat,
        },
        {
            name: 'received_at',
            title: 'Date Received',
            extraTitle: 'Date Received',
            active: true,
            formatType: FormatTypeChoices.FromNowIfLessThanAMonth,
            defaultValue: DEFAULT_VALUE_DASH,
        },
        {
            name: 'published_ct_dataset.beginning_balance',
            title: 'BB',
            extraTitle: 'Beginning Balance',
            valueAlignment: Alignment.Right,
            active: false,
            cellType: CellTypeChoices.Custom,
            transformer: row => (
                <DynamicFormatCurrency
                    row={row}
                    valueField="published_ct_dataset.beginning_balance"
                />
            ),
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.ending_balance',
            title: 'EB',
            extraTitle: 'Ending Balance',
            valueAlignment: Alignment.Right,
            active: true,
            cellType: CellTypeChoices.Custom,
            transformer: row => (
                <DynamicFormatCurrency
                    row={row}
                    valueField="published_ct_dataset.ending_balance"
                />
            ),
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.subscriptions',
            title: 'Sub',
            extraTitle: 'Subscriptions',
            valueAlignment: Alignment.Right,
            active: false,
            cellType: CellTypeChoices.Custom,
            transformer: row => (
                <DynamicFormatCurrency
                    row={row}
                    valueField="published_ct_dataset.subscriptions"
                />
            ),
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.redemptions',
            title: 'Reds',
            extraTitle: 'Redemptions',
            valueAlignment: Alignment.Right,
            active: false,
            cellType: CellTypeChoices.Custom,
            transformer: row => (
                <DynamicFormatCurrency
                    row={row}
                    valueField="published_ct_dataset.redemptions"
                />
            ),
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.income',
            title: 'Income',
            extraTitle: 'Income',
            valueAlignment: Alignment.Right,
            active: false,
            cellType: CellTypeChoices.Custom,
            transformer: row => (
                <DynamicFormatCurrency
                    row={row}
                    valueField="published_ct_dataset.income"
                />
            ),
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.nav_per_share',
            title: 'NAV p/s',
            extraTitle: 'NAV per Share',
            valueAlignment: Alignment.Right,
            active: false,
            cellType: CellTypeChoices.Custom,
            transformer: row => (
                <DynamicFormatCurrency
                    row={row}
                    valueField="published_ct_dataset.nav_per_share"
                />
            ),
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.known_future_redemptions',
            title: 'Fut. Reds',
            extraTitle: 'Known Future Redemptions',
            valueAlignment: Alignment.Right,
            active: false,
            cellType: CellTypeChoices.Custom,
            transformer: row => (
                <DynamicFormatCurrency
                    row={row}
                    valueField="published_ct_dataset.known_future_redemptions"
                />
            ),
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.perf_daily',
            title: 'Daily',
            extraTitle: 'Daily',
            valueAlignment: Alignment.Right,
            active: false,
            formatType: FormatTypeChoices.Percent,
            defaultValue: DEFAULT_VALUE_DASH,
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.perf_weekly',
            title: 'Weekly',
            extraTitle: 'Weekly',
            valueAlignment: Alignment.Right,
            active: false,
            formatType: FormatTypeChoices.Percent,
            defaultValue: DEFAULT_VALUE_DASH,
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.perf_month',
            title: 'Month',
            extraTitle: 'Month',
            valueAlignment: Alignment.Right,
            active: true,
            formatType: FormatTypeChoices.Percent,
            defaultValue: DEFAULT_VALUE_DASH,
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.perf_mtd',
            title: 'MTD',
            extraTitle: 'MTD',
            valueAlignment: Alignment.Right,
            active: false,
            formatType: FormatTypeChoices.Percent,
            defaultValue: DEFAULT_VALUE_DASH,
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.perf_qtd',
            title: 'QTD',
            extraTitle: 'QTD',
            valueAlignment: Alignment.Right,
            active: false,
            formatType: FormatTypeChoices.Percent,
            defaultValue: DEFAULT_VALUE_DASH,
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.perf_ytd',
            title: 'YTD',
            extraTitle: 'YTD',
            valueAlignment: Alignment.Right,
            active: false,
            formatType: FormatTypeChoices.Percent,
            defaultValue: DEFAULT_VALUE_DASH,
            excludeSorting: true,
        },
        {
            name: 'published_ct_dataset.data_version',
            title: 'Version',
            active: true,
            orderingName: 'data_version',
            formatType: FormatTypeChoices.FirstCharUpper,
        },
        {
            name: 'status',
            title: 'Status',
            extraTitle: 'Status',
            active: true,
            cellType: CellTypeChoices.Custom,
            excludeSorting: true,
            transformer: row => <PublishedDatasetStatusCell row={row} />,
        },
    ];

class PublishedDatasetsView extends React.Component<Props & RouteComponentProps<{}>> {
    rowActions: TableRowAction[] = [
        {
            type: TableRowActionType.NavLink,
            icon: TableRowActionIcon.View,
            link: '/funds/{fund.id}/data-reporting',
        }
    ];

    bulkActions: TableBulkAction[] = [{
        type: TableBulkActionChoices.ExportDatasets,
        handler: () => this.props.download(this.props.checkedIds, this.props.checkedAll),
        isVisible: true,
    }];

    handleBulkUpload = () => {
        this.props.openModal(PUBLISHED_DATASETS_COLUMNS_MODAL, {});
    }

    handleDownload = () => {
        this.props.download([], true);
    }

    render() {
        const filters: FilterItem[] = [{
            name: 'search',
            type: FilterType.Search,
            title: 'Search',
            className: 'col-md-12',
            pageName: FilterNames.publishedDatasets,
        }];

        const advancedFilters: AdvancedFilterItem[] = [
            {
                name: 'period_range',
                type: FilterType.CreditDate,
                title: 'Period     ',
                rangeEndings: {
                    start: '_after',
                    end: '_before',
                },
                currentFilter: this.props.currentFilter,
            },
            {
                name: 'received_at_range',
                type: FilterType.CreditDate,
                title: 'Date received',
                rangeEndings: {
                    start: '_after',
                    end: '_before',
                },
                currentFilter: this.props.currentFilter
            },
            {
                name: 'portfolio_id',
                type: FilterType.CreditSelectWithCheckboxes,
                title: 'Portfolio',
                choices: this.props.portfolioChoices,
                choicesCount: this.props.portfolioCount,
                visible: !userAccountHasMasterPortfolio(this.props.userAccount),
                handleRequest: (filter: FiltersState) => {
                    this.props.getChoices(FilterNames.publishedDatasets, AsyncFilterName.portfolio, filter);
                },
                currentFilter: this.props.portfolioFilter,
                setFilters: this.props.setAsyncFilters,
                filterName: AsyncFilterName.portfolio,
                defaultFilter: DEFAULT_ASYNC_FILTER,
                isFilterValueObject: true,
            },
            {
                name: 'published_ct_dataset__data_version',
                type: FilterType.CreditSelectWithCheckboxesSync,
                title: 'Data Version',
                choices: DATA_VERSION,
                choicesCount: DATA_VERSION.length,
                currentFilter: this.props.currentFilter,
            },
            {
                name: 'latest',
                type: FilterType.Toggle,
                title: 'Show only latest datasets',
                withLabel: true,
                currentFilter: this.props.currentFilter,
                isPermanentlyExpandedAccordionItem: true
            }
        ];
        const buttons = [(
            <ButtonPrimary className="float-right ml-3" inverse={true} handleClick={this.handleBulkUpload}>
                Columns...
            </ButtonPrimary>
        ), (
            <ButtonPrimary className="float-right ml-3" inverse={true} handleClick={this.handleDownload}>
                Download
            </ButtonPrimary>
        )];
        return (
            <div className={styles.wrapper}>
                <UiDataTable>
                    <DataTable
                        rows={this.props.results}
                        count={this.props.count}
                        headers={headers}
                        visibleHeaders={this.props.visibleHeaders}
                        filters={filters}
                        currentFilter={this.props.currentFilter}
                        handleChange={this.props.handleChange}
                        buttons={buttons}
                        hasPagination={true}
                        hasSorting={true}
                        rowActions={this.rowActions}
                        actionsTitle={' '}
                        withCheckboxes={true}
                        bulkActions={this.bulkActions}
                        isLoading={this.props.isLoading}
                        checkedAll={this.props.checkedAll}
                        checkedIds={this.props.checkedIds}
                        chooseAll={this.props.chooseAll}
                        chooseRow={this.props.chooseRow}
                        isGridLoading={this.props.isGridLoading}
                        chips={this.props.chips}
                        filterPageName={this.props.filterPageName}

                        handleToggleModal={this.props.handleToggleModal}
                        handleUpdateModal={this.props.handleUpdateModal}
                        handleAttributeSearch={this.props.handleAttributeSearch}
                        isFilterModalOpen={this.props.isModalOpen}
                        advancedFilters={advancedFilters}
                        searchFilterEntities={this.props.searchFilterEntities}
                        currentModalFilter={this.props.advancedFilter}
                    />
                </UiDataTable>
                <ConfirmPublishModal allowChooseRequestors={true}/>
                <PublishedDatasetsColumnsModal
                    handleColumns={this.props.handleColumns}
                />
            </div>
        );
    }
}

export default PublishedDatasetsView;
