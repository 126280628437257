import { DatasetFund, InternalData, PublishedDataset } from './models/dataset';
import { RequestorModel } from './models/requestor';
import { AclUser } from './models/aclUser';
import { InvitedUser } from '../Shared/models/invitedUser';
import { Id, TableHeader } from '../types';

export const DATASET_CONFIGURATION_MODAL = 'DATASET_CONFIGURATION_MODAL';
export const NAV_CONFIGURATION_MODAL = 'NAV_CONFIGURATION_MODAL';
export const PERFORMANCE_CONFIGURATION_MODAL = 'PERFORMANCE_CONFIGURATION_MODAL';
export const PUBLISHED_DATASETS_COLUMNS_MODAL = 'PUBLISHED_DATASETS_COLUMNS_MODAL';

export interface DataManagerState {
    datasetList: DatasetFund[];
    datasetListCount: number;
    publishedDatasets: PublishedDataset[];
    publishedDatasetsCount: number;
    permissions: DataManagerListPermissions;
    currentDataset: null | object;
    confirmPublishModal: ConfirmPublishModal;
    setPendingPublishModal: SetPendingPublishModal;
    editPendingPublishModal: EditPendingPublishModal;
    aclUsersCount: number;
    datasetFund: any;
    requestors: any[];
    aclUsers: AclUser[];
    invitedAclUser?: InvitedUser;
    datasetDetailCount: number;
    datasetDetail: any;
    parentId: number;
    temporaryFilter: any;
    temporaryHeaders: any;
    editingRowNumber: number;
    publishedDatasetsTemporaryHeaders: TableHeader[];
    pendingRequests: {
        [fundId: string]: PendingRequestInfo
    };
    internalData: InternalData[];
    internalDataCount: number;
}

export interface DatasetFund {
    id: Id;
    ap_id: string;
    alias?: string;
    internal_id?: string;
    sec_id?: number;
    lei_id?: null;
    firm: any;
    acl: any;
    acl_user: any;
    current_period: string;
    has_datasets: boolean;
    has_requests: boolean;
    is_accessible: boolean;
    created_by?: string;
    created_at?: string;
    updated_by?: string;
    updated_at?: string;
    type: number;
    status: number;
    account: number;
    target_account?: any;
}

export interface ConfirmPublishModal {
    requestors: RequestorModel[];
    requestorCheckboxes: CheckboxOption[];
    allRequestorsToggle: boolean;
}

export interface SetPendingPublishModal {
    requestors: RequestorModel[];
    daysBeforeDueDate: number;
}

export interface EditPendingPublishModal {
    daysBeforeDueDate: number;
}

export interface GetListResponse {
    count: number;
    results: DatasetFund[];
    next: string;
    previous: string;
}

export enum DatasetVersion {
    Final = 'final',
    Estimate = 'estimate',
}

export enum DatasetStatus {
    InProgress = 'in_progress',
    NotYetStarted = 'not_yet_started',
    ReadyToPublish = 'ready_to_publish',
    Scheduled = 'scheduled',
    ScheduledPartially = 'scheduled_partially',
    Published = 'published',
    PublishedPartially = 'published_partially',
    PastDueOrDataHasBreaches = 'past_due',
}

export enum HistoryColor {
    Grey = 'grey',
    DarkGrey = 'dark_grey',
    Green = 'green',
    Red = 'red',
}

export enum FundReportingHistoryStatus {
    FundPartial = 'partial',
    FundOnTime = 'on_time',
    FundLate = 'late',
    FundOverdue = 'overdue',
    FundAwaitingData = 'awaiting_data',
    FundNoRequest = 'no_request',
}

export interface CheckboxOption {
    label: string;
    checked: boolean;
    value: number | string;
}

export enum AclRole {
    NoAccess = 'no_access',
    Viewer = 'viewer',
    Editor = 'editor',
    Manager = 'manager',
    Owner = 'owner'
}

export enum AclType {
    CreditTransparency = 'credit_transparency',
    Custom = 'custom'
}

export enum AclRequestedByType {
    Request = 'request',
    User = 'user'
}

export enum AclTargetEntityType {
    Fund = 'fund'
}

export interface GetDatasetHistory {
    fundId: Id;
    parentId: Id;
    parentObject: any;
}

export interface FilterAclUsers {
    fundId?: Id;
    scroller?: boolean;
}

export interface UpdatingDatasetDetailField {
    id: string | number;
    fund_ap_id: string;
    editableRow: any;
}

export interface PendingRequestInfo {
    count: number;
    notificationFlag: boolean;
}

export interface DataManagerListPermissions {
    can_bulk_upload_internal: boolean;
}