import ActiveUser from './models/activeUser';

import { Action } from 'redux';
import { Id } from '../types';
import { UserAccountSettings } from 'store/Auth/models/userAccount';
import { AccountSettings } from 'store/Account/models/account';

export const LOGIN = 'store/user/login';
export const LOGOUT = 'store/user/logout';
export const GET_USER = 'store/user/getUser';
export const SET_USER = 'store/user/setUser';
export const GET_DILIGENCE_USER = 'store/user/getDiligenceUser';
export const SWITCH_ACCOUNT = 'store/user/switchAccount';
export const INITIALIZE_RESET_PASSWORD = 'store/user/initializeResetPassword';
export const RESET_PASSWORD = 'store/user/resetPassword';
export const CHANGE_USER_ACCOUNT_SETTINGS = 'store/user/setUserAccountSettings';
export const UPDATE_USER_ACCOUNT_SETTINGS = 'store/user/updateUserAccountSettings';
export const DISABLE_TWO_FACTOR_AUTHENTICATION = 'store/user/disableTwoFactorAuthentication';
export const GENERATE_TFA_SECRET = 'store/user/generateTFASecret';
export const SET_TFA_SECRET = 'store/user/setTFASecret';
export const VERIFY_SETUP_CODE = 'store/user/verifySetupCode';
export const SET_TWO_FACTOR_AUTH_SETTINGS = 'store/user/setTwoFactAuthSettings';
export const CHANGE_REMEMBER_COOKIE = 'store/user/changeRememberCookie';
export const UPDATE_ACCOUNT_SETTINGS = 'store/user/updateAccountSettings';
export const SET_ACCOUNT_SETTINGS = 'store/user/setAccountSettings';

export type GetUserAction = Action;
export type GetDiligenceUserAction = Action;

export interface SetUserAction extends Action {
    user: ActiveUser;
}

export interface SwitchAccountAction extends Action {
    relationId: Id;
}

export interface ChangeUserAccountSettingsAction extends Action {
    userAccountSettings: UserAccountSettings;
}

export interface UpdateUserAccountSettingsAction extends Action {
    userAccountSettings: UserAccountSettings;
    waitResponse: boolean;
}

type LogoutAction = Action;
type LoginAction = Action;

export interface InitializeResetPasswordAction extends Action {
    email: string;
}

export interface ResetPasswordAction extends Action {
    password: string;
    token: string;
    resetKey: string;
}

export interface UpdateAccountSettingsAction extends Action {
    settings: AccountSettings;
}

export interface SetAccountSettingsAction extends Action {
    settings: AccountSettings;
}

export type AuthAction = GetUserAction | SetUserAction | SwitchAccountAction | LogoutAction | LoginAction |
    InitializeResetPasswordAction | ResetPasswordAction | UpdateAccountSettingsAction |
    ChangeUserAccountSettingsAction;
