import { FileType, NavigationButton } from './types';
import { UserAccountRole } from './Auth/types';

import { headerNames } from 'store/VisibleHeaders/types';
import DataManagerHelper from 'helpers/dataManagerHelper';
import { FilterChipSettings, FilterNames } from 'store/Filters/types';
import { EvaluationResultStatus } from 'store/AlertsManager/Alerts/types';
import { DiligenceFilterNames } from 'store/Diligence/Filters/types';
import { DiligenceFilterTypeDictionary } from 'store/Diligence/constants';
import { ChooseType } from 'containers/DataManager/DatasetDetailModals/NavConfigurationModal';
import { BASE_DILIGENCE_URL } from 'helpers/diligenceApiHelper';
import { UserAccount } from 'store/Auth/models/userAccount';
import { hasDiligenceId, userAccountHasMasterPortfolio } from 'store/Account/helpers';

export const ANY_CHART_LICENSE_KEY = 'alphapipe.com-b5d74de3-e11215d0';
export const CURRENT_SESSION_ID = Math.round(Math.random() * 1000000);
export const CONFIRM_CANCEL_MODAL = 'CONFIRM_CANCEL_MODAL';

export const LEFT_PANEL_MIN_WIDTH = 89;
export const LEFT_PANEL_MAX_WIDTH = 220;
export const PAGINATION_LARGE_LIMIT = 1000000;

// numeral formats
export const NUMERAL_FORMAT_DEFAULT = '0,0.00';
export const NUMERAL_FORMAT_TWO_DECIMAL_PLACES = '0.00';
export const NUMERAL_FORMAT_SHORTEN_DECIMAL_LOWER = '(0a)';
export const NUMERAL_FORMAT_ONE_DECIMAL_LOWER = '0.[0a]';
export const NUMERAL_FORMAT_THREE_DECIMAL_LOWER = '0.[000a]';
export const NUMERAL_FORMAT_ONE_DECIMAL = '0.(0)';
export const NUMERAL_FORMAT_PERCENT = '0,0.00%';
export const NUMERAL_FORMAT_INTEGER = '0,0';

export const DEFAULT_CURRENCY_VALUE = 'usd';

// Default Date Format from backend
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

// Date time Formats
export const DATETIME_FORMAT = 'MMM D, YYYY HH:mm:ss';
// Date and Short Date Formats
export const DATE_FORMAT = 'MMM D, YYYY';
export const DATE_FORMAT_SHORT = 'MMM D, YY';

export const FULL_MONTH_AND_YEAR = 'MMM YYYY';
export const DATE_SLASH_WITHOUT_YEAR = 'M/DD';

// Date to use in date pickers by default
export const DATE_PICKER_DISPLAY_FORMAT = 'MMM D, YYYY';

export const DEFAULT_VALUE_DASH = '-';
export const DEFAULT_VALUE_NONE = 'None';
export const THOUSAND_SEPARATOR = ',';
export const DECIMAL_SEPARATOR = '.';

export const RESET_OFFSET_AND_SCROLL = {
    offset: 0,
    scroller: false
};

export const DEFAULT_CHECKED_VALUES = {
    checkedIds: [],
    checkedAll: false,
};

// export const DEFAULT_ITEMS_PER_PAGE = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE || '20', 10);
export const DEFAULT_ITEMS_PER_PAGE = 30;
export const SEARCH_DEBOUNCE_INTERVAL = 500;

export const leftPanelButton = (user: UserAccount): NavigationButton[] => [
    {
        path: '/dashboard',
        title: 'Dashboard',
        icon: 'dashboard',
        hasFont: true,
    },
    {
        path: '/funds',
        title: 'Funds',
        icon: 'fund',
        hasFont: true,
    },
    {
        path: '/firms',
        title: 'Firms',
        icon: 'firm',
        hasFont: true,
        accountAccess: {
            requestor: true,
            responder: false,
        },
    },
    {
        path: '/profiles/groups',
        title: 'Profiles',
        icon: 'profiles',
        hasFont: true,
        accountAccess: {
            requestor: true,
            responder: false,
        },
    },
    {
        path: '/alerts',
        title: 'Alerts',
        icon: 'breach',
        hasFont: true,
        children: [
            {path: '/rulesets', title: 'Rulesets'},
            {path: '/ruleset-versions', title: 'Rulesets'}
        ]
    },
    {
        path: '/requests',
        title: 'Requests',
        icon: 'requests',
        hasFont: true,
    },
    {
        path: '/data-manager',
        title: 'Data Manager',
        icon: 'data-manager',
        hasFont: true,
        accountAccess: {
            requestor: true,
            responder: false,
        },
    },
    {
        path: hasDiligenceId(user) ? BASE_DILIGENCE_URL + '/settings?page=users' : '/account',
        title: 'Users',
        icon: 'add-user',
        hasFont: true,
        roles: [UserAccountRole.AccountAdmin],
        inNewTab: hasDiligenceId(user),
    },
    {
        path: '/portfolio',
        title: 'Portfolios',
        icon: 'portfolio',
        hasFont: true,
        accountAccess: {
            requestor: !userAccountHasMasterPortfolio(user),
            responder: false,
        },
    },
    {
        path: '/pending-users',
        title: 'Users',
        icon: 'add-user',
        hasFont: true,
        accountAccess: {
            requestor: false,
            responder: true
        }
    },
    // Diligence buttons
    {
        path: `${process.env.REACT_APP_DILIGENCE_URL}`,
        title: 'Go To Diligence',
        icon: 'diligence-logo',
        hasFont: false,
        requiresDiligenceAccount: true,
        accountAccess: {
            requestor: true,
            responder: false
        }
    },
    // {
    //     path: `/diligence/requests`,
    //     title: 'Diligence Requests',
    //     icon: 'diligence-logo',
    //     hasFont: false,
    //     requiresDiligenceAccount: true,
    //     accountAccess: {
    //         requestor: false,
    //         responder: true
    //     }
    // }
];

export const CREATE_NEW_ENTITY_ID = 'create';
export const PAGE_SIZE = (parseInt(process.env.REACT_APP_ITEMS_PER_PAGE || '20', 10));

// Determines what should be displayed in header when check rows
export enum TableBulkActionChoices {
    AssignManager = 'ASSIGN_MANAGER',
    Delete = 'DELETE',
    Edit = 'EDIT',
    ExportFunds = 'DOWNLOAD_FUNDS',
    DownloadInternalData = 'INTERNAL_EXPORT',
    DownloadCombinedData = 'COMBINED_EXPORT',
    ExportDatasets = 'EXPORT_DATASETS',
    ExportRequests = 'EXPORT_REQUESTS',
    Send = 'SEND',
    DownloadRulesets = 'DOWNLOAD_RULESETS',
}

export enum FormatTypeChoices {
    String = 'STRING',
    FirstCharUpper = 'FIRST_CHAR_UPPER',
    ToUppercase = 'TO_UPPERCASE',
    ContainsUnderscore = 'CONTAINS_UNDERSCORE',
    CountFunds = 'COUNT_FUNDS',
    CountDatasets = 'COUNT_DATASETS',
    CountRulesets = 'COUNT_RULESETS',
    RowStatus = 'ROW_STATUS',
    UploadStatus = 'UPLOAD_STATUS',
    Boolean = 'BOOLEAN',

    DatePickerDisplayFormat = 'DATE_PICKER_DISPLAY_FORMAT',
    DefaultDateFormat = 'DEFAULT_DATE_FORMAT',
    DatetimeFormat = 'DATETIME_FORMAT',
    DateFormat = 'DATE_FORMAT',
    DateFormatShort = 'DATE_FORMAT_SHORT',
    DateSlashWithoutYear = 'DATE_SLASH_WITHOUT_YEAR',

    FullMonthAndYear = 'FULL_MONTH_AND_YEAR',
    FromNow = 'FROM_NOW',
    FromNowIfLessThanAMonth = 'FROM_NOW_IF_LESS_THAN_MONTH',
    ToNow = 'TO_NOW',
    TimeDiff = 'TIME_DIFF',
    Percent = 'PERCENT',
    NumeralDefault = 'NUMERAL_DEFAULT',
    NumeralTwoDecimalPlaces = 'NUMERAL_TWO_DECIMAL_PLACES',
    NumeralShortenDecimalLower = 'NUMERAL_SHORTEN_DECIMAL_LOWER',
    NumeralOneDecimalLower = 'NUMERAL_ONE_DECIMAL_LOWER',
    NumeralThreeDecimalLower = 'NUMERAL_THREE_DECIMAL_LOWER',
    NumeralOneDecimal = 'NUMERAL_ONE_DECIMAL',
    NumeralInteger = 'NUMERAL_FORMAT_INTEGER',
}

export const DATE_FORMATS = {
    [FormatTypeChoices.DatePickerDisplayFormat]: DATE_PICKER_DISPLAY_FORMAT,
    [FormatTypeChoices.DefaultDateFormat]: DEFAULT_DATE_FORMAT,
    [FormatTypeChoices.DatetimeFormat]: DATETIME_FORMAT,
    [FormatTypeChoices.DateFormat]: DATE_FORMAT,
    [FormatTypeChoices.DateFormatShort]: DATE_FORMAT_SHORT,

    [FormatTypeChoices.FullMonthAndYear]: FULL_MONTH_AND_YEAR,
    [FormatTypeChoices.DateSlashWithoutYear]: DATE_SLASH_WITHOUT_YEAR,
};

export const NUMERAL_FORMATS = {
    [FormatTypeChoices.NumeralDefault]: NUMERAL_FORMAT_DEFAULT,
    [FormatTypeChoices.NumeralTwoDecimalPlaces]: NUMERAL_FORMAT_TWO_DECIMAL_PLACES,
    [FormatTypeChoices.NumeralShortenDecimalLower]: NUMERAL_FORMAT_SHORTEN_DECIMAL_LOWER,
    [FormatTypeChoices.NumeralOneDecimalLower]: NUMERAL_FORMAT_ONE_DECIMAL_LOWER,
    [FormatTypeChoices.NumeralThreeDecimalLower]: NUMERAL_FORMAT_THREE_DECIMAL_LOWER,
    [FormatTypeChoices.NumeralOneDecimal]: NUMERAL_FORMAT_ONE_DECIMAL,
    [FormatTypeChoices.Percent]: NUMERAL_FORMAT_PERCENT,
    [FormatTypeChoices.NumeralInteger]: NUMERAL_FORMAT_INTEGER,
};

export enum InfoTableTypeChoices {
    Boolean = 'BOOLEAN',
    Link = 'Link',
}
export enum DataPointChoices {
    Balance = 'BALANCE',
    PerformanceMonth = 'PERFORMANCE_MONTH',
    PerformanceYTD = 'PERFORMANCE_YTD',
}

export enum FixedElementType {
    TableFilter = 'TABLE_FILTER',
    TableHeader = 'TABLE_HEADER',
}

export enum ScrollableElementType {
    FullPage = 'FULL_PAGE',
    TableBody = 'TABLE_BODY',
}

export const UploadStatusesText = {
    1: 'New',
    2: 'Processing...',
    3: 'Success',
    4: 'Failed',
    5: 'Canceled',
    6: 'Completed - contains errors'
};

export const RowStatusesText = {
    1: 'NEW',
    2: 'FOUND',
    3: 'NOT FOUND',
    4: 'ACCEPTED',
    5: 'MISMATCH',
    6: 'ERROR',
};

export const ColorsTextClasses = {
    success: 'text-success',
    primary: 'text-primary',
    warning: 'text-warning',
    danger: 'text-danger',
    successRow: 'text-success font-weight-bold',
    primaryRow: 'text-primary font-weight-bold',
    warningRow: 'text-warning font-weight-bold',
    dangerRow: 'text-danger font-weight-bold',
};

export enum CellTypeChoices {
    Link = 'LINK',
    Text = 'TEXT',
    Select = 'SELECT',
    Status = 'STATUS',
    Numeric = 'NUMERIC',
    Date = 'DATE',
    Custom = 'CUSTOM'
}

export enum FilterType {
    Search = 'SEARCH',
    DiligenceSearch = 'DILIGENCE_SEARCH',
    Input = 'INPUT',
    Statistics = 'STATISTICS',
    Select = 'SELECT',
    DiligenceSelectWithCheckboxes = 'DILIGENCE_SELECT_WITH_CHECKBOXES',
    CreditSelectWithCheckboxes = 'CREDIT_SELECT_WITH_CHECKBOXES',
    DiligenceSelectWithCheckboxesSync = 'DILIGENCE_SELECT_WITH_CHECKBOXES_SYNC',
    CreditSelectWithCheckboxesSync = 'CREDIT_SELECT_WITH_CHECKBOXES_SYNC',
    CreditDate = 'CREDIT_DATE',
    DiligenceDate = 'DILIGENCE_DATE',
    SingleDate = 'SINGLE_DATE',
    Content = 'CONTENT',
    Toggle = 'TOGGLE',
    DottedToggle = 'DOTTED_TOGGLE',
    SingleCheckbox = 'SINGLE_CHECKBOX',
}

export enum BooleanFieldText {
    Yes = 'Yes',
    No = 'No',
}

export enum ShortBooleanFieldText {
    Yes = 'Y',
    No = 'N',
}

export enum TableRowActionType {
    NavLink = 'NAV_LINK',
    Link = 'LINK',
    Image = 'IMAGE',
    Dropdown = 'DROPDOWN',
    Custom = 'CUSTOM'
}

export enum TableRowActionIcon {
    View = 'view',
    Trash = 'trash',
    BigTrash = 'bigTrash',
    Pencil = 'pencil',
    BigPencil = 'bigPencil',
    Close = 'close',
    Refresh = 'refresh',
    Download = 'download',
    Upload = 'upload',
    BulkUpload = 'bulkUpload',
    Clock = 'clock',
    Locked = 'locked',
    Unlocked = 'unlocked',
    Discard = 'discardIcon',
    Send = 'sendIcon',
    Cross = 'cross',
    CrossGray = 'crossGray',
    UpdateInDB = 'updateInDB',
}

export enum HistoryPeriodChoices {
    YTD = 'ytd',
    OneYear = '1yr',
    ThreeYears = '3yr',
    FiveYears = '5yr',
}

export const bulkActionMessages = {
    DOWNLOAD_FAILED: 'Download failed',
    UPLOAD_FILE_FAILED: 'Uploading failed',
};

export enum Alignment {
    Center = 'center',
    Left = 'left',
    Right = 'right',
}

export enum ExportPageType {
    Funds = 'funds',
    Portfolio = 'portfolio',
}

export const TypesFormat = {
    nav: 'nav',
    performance: 'performance'
};

export const YES_NO_TITLES = {
    [BooleanFieldText.Yes]: 'Yes',
    [BooleanFieldText.No]: 'No',
    [ShortBooleanFieldText.Yes]: 'Yes',
    [ShortBooleanFieldText.No]: 'No'
};

export const DEFAULT_FILTER = {
    search: '',
    status: '',
    ordering: '-created_at',
    created_at_lte: null,
    created_at_gte: null,
    updated_at_lte: null,
    updated_at_gte: null,
    created_at: null,
    updated_at: null,
    limit: DEFAULT_ITEMS_PER_PAGE,
    offset: 0,
    checkedAll: false,
    checkedIds: [],
};

export const DEFAULT_INTERNAL_DATA_FILTER = {
    ...DEFAULT_FILTER,
    ordering: 'firm_name,fund_name'
};

export const DEFAULT_PORTFOLIO_LIST_FILTER = {
    ...DEFAULT_FILTER,
    ordering: 'name',
};

export const DEFAULT_ASYNC_FILTER = {
    search: '',
    limit: DEFAULT_ITEMS_PER_PAGE,
    offset: 0,
};

export const DEFAULT_ASYNC_COUNTERPARTY_FILTER = {
    ...DEFAULT_ASYNC_FILTER,
    ordering: 'counterparty',
};

export const DEFAULT_ASYNC_RULESET_NAME_FILTER = {
    ...DEFAULT_ASYNC_FILTER,
    ordering: 'name',
};

export const DEFAULT_FUNDS_LIST_FILTER = {
    search: '',
    status: '',
    data_entry: '',
    ordering: 'firm_name,name',
    created_at_lte: null,
    created_at_gte: null,
    updated_at_lte: null,
    updated_at_gte: null,
    created_at: null,
    updated_at: null,
    limit: DEFAULT_ITEMS_PER_PAGE,
    offset: 0,
    checkedAll: false,
    checkedIds: [],
    portfolio_id: [],
    period: DataManagerHelper.getActivePeriod(),
    credit_officer: [],
    product: [],
    strategy: [],
    delivery_status: [],
    firm_id: [],
    fund_id: [],
    internal_id: [],
};

export const DEFAULT_FIRMS_LIST_FILTER = {
    search: '',
    status: '',
    ordering: 'name',
    created_at_lte: null,
    created_at_gte: null,
    updated_at_lte: null,
    updated_at_gte: null,
    created_at: null,
    updated_at: null,
    limit: DEFAULT_ITEMS_PER_PAGE,
    offset: 0,
    checkedAll: false,
    checkedIds: [],
};

export const DEFAULT_PORTFOLIO_FUNDS_FILTER = {
    search: '',
    status: '',
    ordering: 'name',
    created_at_lte: null,
    created_at_gte: null,
    updated_at_lte: null,
    updated_at_gte: null,
    created_at: null,
    updated_at: null,
    limit: DEFAULT_ITEMS_PER_PAGE,
    offset: 0,
    checkedAll: false,
    checkedIds: [],
};

export const DEFAULT_UPLOAD_RESULTS_FILTER = {
    search: '',
    status: '',
    ordering: '',
    created_at_lte: null,
    created_at_gte: null,
    updated_at_lte: null,
    updated_at_gte: null,
    created_at: null,
    updated_at: null,
    limit: DEFAULT_ITEMS_PER_PAGE,
    offset: 0,
};

export const DEFAULT_REQUEST_FUNDS_RESULTS_FILTER = {
    ...DEFAULT_FILTER,
    ordering: 'fund_name',
};

export const DEFAULT_DATE_RANGE_ENDINGS = {
    start: '',
    end: ''
};

export const DEFAULT_DILIGENCE_REQUEST_FILTER = {
    search: '',
    survey: [],
    requester: [],
    statusDisplay: [],
    sort: [],
    respondingAccount: [],
    respondingVehicle: [],
    campaign: [],
    deadline: {
        ...DEFAULT_DATE_RANGE_ENDINGS,
        id: DiligenceFilterTypeDictionary.deadline,
        type: DiligenceFilterTypeDictionary.deadline
    },
    dateSent: {
        ...DEFAULT_DATE_RANGE_ENDINGS,
        id: DiligenceFilterTypeDictionary.dateSent,
        type: DiligenceFilterTypeDictionary.dateSent
    },
    dateClosed: {
        ...DEFAULT_DATE_RANGE_ENDINGS,
        id: DiligenceFilterTypeDictionary.dateClosed,
        type: DiligenceFilterTypeDictionary.dateClosed
    },
    // for scrolling
    offset: 0,
    limit: DEFAULT_ITEMS_PER_PAGE,
    ordering: ''
};

export const defaultSortPagination = {
    currentPage: 0,
    ordering: 'created_at',
    orderSign: 'desc',
};

export const dataSourcesTypes = {
    lei: 'lei',
    sec: 'sec',
    crd: 'crd'
};

export const defaultDeniedGetParams = [
    'checkedAll', 'checkedIds', 'scroller', 'entity_type', 'currentPage',
];

export const dashboardDeniedGetParams = [
    'scroller', 'ordering', 'currentPage', 'checkedAll'
];

export const donutDeniedGetParams = [
    'scroller', 'ordering', 'currentPage', 'checkedAll', 'limit', 'offset', 'chart_types'
];

export const deniedGetParams = [
    ...defaultDeniedGetParams,
    'limit', 'offset',
];

export const deniedPostParams = [
    ...deniedGetParams,
    'ordering',
];

export const datasetsDeniedGetParams = [
    ...defaultDeniedGetParams,
    'id',
];

export const DefaultStatePortfolioList = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_PORTFOLIO_LIST_FILTER,
        entity_type: '',
    },
    headerType: 'erm'
};

export const DefaultStatePortfolioUploadedRows = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_UPLOAD_RESULTS_FILTER,
        entity_type: ''
    },
    headerType: 'portfolio'
};

export const DefaultStatePortfolioDetail = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        ordering: 'name',
    },
    headerType: 'erm'
};

export const DefaultUsersList = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        ordering: 'name',
    },
    headerType: 'account'
};

export const DefaultStateFundList = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FUNDS_LIST_FILTER,
    },
    headerType: headerNames.fundList,
};

export const DefaultStateFirmList = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FIRMS_LIST_FILTER,
        number_of_funds: '',
    },
    headerType: 'firm'
};

export const DefaultStateDatasetDetail = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        frequency: '',
        currency: DEFAULT_CURRENCY_VALUE,
        ordering: '-period',
        start_date: null,
        end_date: null,
    },
    headerType: headerNames.datasetDetail
};

export const DefaultStateDatasetsTab = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_FILTER,
        ordering: '-period',
    },
    headerType: headerNames.fundDatasets
};

export const DefaultStateRulesetList = {
    filter: {
        ordering: 'name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        checkedAll: false,
        checkedIds: [],
    }
};

export const DefaultStateAlertList = {
    filter: {
        ordering: 'firm_name,name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        search: '',
        period: DataManagerHelper.getActivePeriod(),
        alert_status: '',
        firm_id: [],
        credit_officer: [],
        product: [],
        strategy: [],
        portfolio_id: [],
        internal_id: [],
    }
};

export const DefaultStateFundAlertsView = {
    filter: {
        ordering: 'ruleset_name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        alert_status: [EvaluationResultStatus.Alert, EvaluationResultStatus.PartialData],
        ruleset_name: [],
        counterparty: [],
    }
};

export const DefaultStateRequestorAddItemsModalFundList = {
    filter: {
        ordering: 'firm_name,name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        firm_id: [],
        delivery_status: [],
        fund_id: [],
        credit_officer: [],
        product: [],
        strategy: [],
        portfolio_id: [],
        internal_id: [],
        checkedAll: false,
        checkedIds: [],
    }
};

export const DefaultStateResponderAddItemsModalFundList = {
    filter: {
        ordering: 'firm_name,name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        checkedAll: false,
        checkedIds: [],
    }
};

export const DefaultStateRequestorAddItemsModalPeerGroupList = {
    filter: {
        ordering: 'name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        search: '',
        checkedAll: false,
        checkedIds: [],
    }
};

export const DefaultStateContactList = {
    filter: {
        checkedAll: false,
        checkedIds: [],
    }
};

export const DefaultStateRulesetRequestorTarget = {
    filter: {
        ordering: 'firm_name,name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        search: '',
        firm_id: [],
        credit_officer: [],
        product: [],
        strategy: [],
        portfolio_id: [],
        internal_id: [],
        checkedAll: true,
        checkedIds: [],
    }
};

export const DefaultStateRulesetResponderTarget = {
    filter: {
        ordering: 'firm_name,name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        checked_all: true,
        fund_id: [],
    }
};

export const DefaultStatePublishedDatasets = {
    ...defaultSortPagination,
    filter: {
        search: '',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        latest: false,
        published_ct_dataset__data_version: [],
        portfolio_id: [],
        period_range_after: null,
        period_range_before: null,
        received_at_range_after: null,
        received_at_range_before: null,
        ordering: '-received_at',
    },
    headerType: headerNames.publishedDatasets
};

export const DefaultStateFirmFundsList = {
    filter: {
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
    },
};

export const DefaultStateDashboardAttributeList = {
    filter: {
        search: '',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        portfolio_id: [],
        credit_officer: [],
        product: [],
        strategy: [],
        firm_id: [],
    },
};

export const DefaultStateRequestList = {
    ...defaultSortPagination,
    filter: {
        ...DEFAULT_DILIGENCE_REQUEST_FILTER,
        type: null

    }
};

export const DefaultStateProfilesGroupList = {
    filter: {
        ordering: 'name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        checkedAll: false,
        checkedIds: [],
        search: '',
        group_type: [],
    }
};

export const DefaultStateProfilesGroupTargetSection = {
    filter: {
        ordering: 'firm_name,name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        search: '',
        firm_id: [],
        credit_officer: [],
        product: [],
        strategy: [],
        portfolio_id: [],
        internal_id: [],
        checkedAll: true,
        checkedIds: [],
    },
    headerType: headerNames.profilesGroupsTargetSection,
};

export const DefaultStateProfilesGroupBenchmarks = {
    filter: {
        ordering: 'firm_name,name',
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: 0,
        checkedAll: false,
        checkedIds: [],
    }
};

export enum ChipValueMappingType {
    FundStatus = 'FUND_STATUS',
    AlertStatus = 'ALERT_STATUS',
    DatasetVersion = 'DATASET_VERSION',
    GroupType = 'GROUP_TYPE',
    Boolean = 'BOOLEAN',
}

export const FILTERS_WITH_CHIPS_ENABLED: FilterChipSettings = {
    [FilterNames.fundList]: [
        {name: 'firm_id', nameTitle: 'Firm', emptyValue: []},
        {name: 'fund_id', nameTitle: 'Fund', emptyValue: []},
        {name: 'credit_officer', nameTitle: 'Coverage', emptyValue: []},
        {name: 'product', nameTitle: 'Product', emptyValue: []},
        {name: 'strategy', nameTitle: 'Strategy', emptyValue: []},
        {name: 'portfolio_id', nameTitle: 'Portfolio', emptyValue: []},
        {name: 'delivery_status', nameTitle: 'Status', mapType: ChipValueMappingType.FundStatus, emptyValue: []},
        {name: 'internal_id', nameTitle: 'Internal ID', emptyValue: []},

        {name: 'created_at_gte', nameTitle: 'Created after', emptyValue: null},
        {name: 'created_at_lte', nameTitle: 'Created before', emptyValue: null},
        {name: 'updated_at_gte', nameTitle: 'Updated after', emptyValue: null},
        {name: 'updated_at_lte', nameTitle: 'Updated before', emptyValue: null}
    ],
    [FilterNames.internalDataTab]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
        {name: 'firm_id', nameTitle: 'Firm', emptyValue: []},
        {name: 'portfolio_id', nameTitle: 'Portfolio', emptyValue: []},
    ],
    [FilterNames.requestsList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
    ],
    [FilterNames.portfolioList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
    ],
    [FilterNames.portfolioDetail]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
    ],
    [FilterNames.firmList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
    ],
    [FilterNames.datasetList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
    ],
    [FilterNames.requestManagerScreen]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
    ],
    [FilterNames.requestorDashboard]: [
        {name: 'firm_id', nameTitle: 'Firm', emptyValue: []},
        {name: 'credit_officer', nameTitle: 'Coverage', emptyValue: []},
        {name: 'product', nameTitle: 'Product', emptyValue: []},
        {name: 'strategy', nameTitle: 'Strategy', emptyValue: []},
        {name: 'portfolio_id', nameTitle: 'Portfolio', emptyValue: []},
    ],
    [FilterNames.requestorDashboardDispersion]: [
        {name: 'firm_id', nameTitle: 'Firm', emptyValue: []},
        {name: 'credit_officer', nameTitle: 'Coverage', emptyValue: []},
        {name: 'product', nameTitle: 'Product', emptyValue: []},
        {name: 'strategy', nameTitle: 'Strategy', emptyValue: []},
        {name: 'portfolio_id', nameTitle: 'Portfolio', emptyValue: []},
    ],
    [FilterNames.rulesetRequestorTargetFundList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
        {name: 'firm_id', nameTitle: 'Firm', emptyValue: []},
        {name: 'credit_officer', nameTitle: 'Coverage', emptyValue: []},
        {name: 'product', nameTitle: 'Product', emptyValue: []},
        {name: 'strategy', nameTitle: 'Strategy', emptyValue: []},
        {name: 'portfolio_id', nameTitle: 'Portfolio', emptyValue: []},
        {name: 'internal_id', nameTitle: 'Internal ID', emptyValue: []},
    ],
    [FilterNames.rulesetResponderTargetFundList]: [
        {name: 'fund_id', nameTitle: 'Fund', emptyValue: []},
        {name: 'checked_all', nameTitle: 'Checked All', mapType: ChipValueMappingType.Boolean, emptyValue: false},
    ],
    [FilterNames.alertList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
        {name: 'firm_id', nameTitle: 'Firm', emptyValue: []},
        {name: 'credit_officer', nameTitle: 'Coverage', emptyValue: []},
        {name: 'product', nameTitle: 'Product', emptyValue: []},
        {name: 'strategy', nameTitle: 'Strategy', emptyValue: []},
        {name: 'portfolio_id', nameTitle: 'Portfolio', emptyValue: []},
        {name: 'internal_id', nameTitle: 'Internal ID', emptyValue: []},
    ],
    [FilterNames.alertView]: [
        {name: 'alert_status', nameTitle: 'Alert Status', mapType: ChipValueMappingType.AlertStatus, emptyValue: []},
        {name: 'ruleset_name', nameTitle: 'Ruleset Name', emptyValue: []},
        {name: 'counterparty', nameTitle: 'Counterparty', emptyValue: []},
    ],
    [FilterNames.publishedDatasets]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
        {name: 'portfolio_id', nameTitle: 'Portfolio', emptyValue: []},
        {
            name: 'published_ct_dataset__data_version',
            nameTitle: 'Data Version',
            mapType: ChipValueMappingType.DatasetVersion,
            emptyValue: []
        },
        {name: 'period_range_after', nameTitle: 'Period from', emptyValue: null},
        {name: 'period_range_before', nameTitle: 'Period to', emptyValue: null},
        {name: 'received_at_range_after', nameTitle: 'Date received from', emptyValue: null},
        {name: 'received_at_range_before', nameTitle: 'Date received to', emptyValue: null},
        {
            name: 'latest',
            nameTitle: 'Show only latest datasets',
            mapType: ChipValueMappingType.Boolean,
            emptyValue: false
        },
    ],
    [FilterNames.requestorAddItemsModalFundList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
        {name: 'firm_id', nameTitle: 'Firm', emptyValue: []},
        {name: 'delivery_status', nameTitle: 'Status', mapType: ChipValueMappingType.FundStatus, emptyValue: []},
        {name: 'credit_officer', nameTitle: 'Coverage', emptyValue: []},
        {name: 'product', nameTitle: 'Product', emptyValue: []},
        {name: 'strategy', nameTitle: 'Strategy', emptyValue: []},
        {name: 'portfolio_id', nameTitle: 'Portfolio', emptyValue: []},
        {name: 'internal_id', nameTitle: 'Internal ID', emptyValue: []},
    ],
    [FilterNames.responderAddItemsModalFundList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
    ],
    [FilterNames.profilesGroupList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
        {name: 'group_type', nameTitle: 'Type', mapType: ChipValueMappingType.GroupType, emptyValue: []},
    ],
    [FilterNames.profilesGroupTargetSection]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
        {name: 'firm_id', nameTitle: 'Firm', emptyValue: []},
        {name: 'credit_officer', nameTitle: 'Coverage', emptyValue: []},
        {name: 'product', nameTitle: 'Product', emptyValue: []},
        {name: 'strategy', nameTitle: 'Strategy', emptyValue: []},
        {name: 'portfolio_id', nameTitle: 'Portfolio', emptyValue: []},
        {name: 'internal_id', nameTitle: 'Internal ID', emptyValue: []},
    ],
    [FilterNames.requestorAddItemsModalPeerGroupsList]: [
        {name: 'search', nameTitle: 'Search', emptyValue: ''},
    ],
    [DiligenceFilterNames.diligenceRequest]: [
        {
            name: DiligenceFilterTypeDictionary.dateSent,
            nameTitle: DiligenceFilterTypeDictionary.dateSent,
            emptyValue: {...DEFAULT_DATE_RANGE_ENDINGS, name: DiligenceFilterTypeDictionary.dateSent}
        },
        {
            name: DiligenceFilterTypeDictionary.deadline,
            nameTitle: DiligenceFilterTypeDictionary.deadline,
            emptyValue: {...DEFAULT_DATE_RANGE_ENDINGS, name: DiligenceFilterTypeDictionary.deadline}
        },
        {
            name: DiligenceFilterTypeDictionary.dateClosed,
            nameTitle: DiligenceFilterTypeDictionary.dateClosed,
            emptyValue: {...DEFAULT_DATE_RANGE_ENDINGS, name: DiligenceFilterTypeDictionary.dateClosed}
        }
    ],
};

export const FileTypeTitles = {
    [FileType.Pdf]: 'PDF',
    [FileType.Excel]: 'Excel',
    [FileType.Docs]: 'Docs',
};

// SVG constants
export const lilLogo = require(`../assets/img/logos/lil-logo.svg`);
export const bigLogo = require(`../assets/img/logos/big-logo.svg`);
export const checkMark = require(`../assets/img/sharedIcons/check-mark.svg`);
export const redNo = require(`../assets/img/sharedIcons/red-no.svg`);
export const editIcon = require(`../assets/img/sharedIcons/edit.svg`);
export const refreshIcon = require(`../assets/img/sharedIcons/refresh.svg`);
export const warningIcon = require(`../assets/img/sharedIcons/warning.svg`);
export const alertIcon = require(`../assets/img/sharedIcons/alert.svg`);
export const discardIcon = require(`../assets/img/sharedIcons/discard.svg`);
export const closeIcon = require(`../assets/img/sharedIcons/close.svg`);
export const closeYellowIcon = require(`../assets/img/sharedIcons/close-yellow.svg`);
export const closeWhiteIcon = require(`../assets/img/sharedIcons/close-cross-white.svg`);
export const closeRedIcon = require(`../assets/img/sharedIcons/close-cross-red.svg`);
export const closeGreenIcon = require(`../assets/img/sharedIcons/close-cross-green.svg`);
export const closeGrayIcon = require(`../assets/img/sharedIcons/close-cross-gray.svg`);
export const viewIcon = require(`../assets/img/sharedIcons/eye.svg`);
export const bulkUploadIcon = require(`../assets/img/sharedIcons/bulk-upload.svg`);
export const downloadIcon = require(`../assets/img/sharedIcons/download.svg`);
export const trashIcon = require(`../assets/img/sharedIcons/trash.svg`);
export const trashIconDisabled = require(`../assets/img/sharedIcons/trash-disabled.svg`);
export const uploadIcon = require(`../assets/img/sharedIcons/upload.svg`);
export const searchWhiteIcon = require(`../assets/img/sharedIcons/search-white.svg`);
export const searchIcon = require(`../assets/img/sharedIcons/search.svg`);
export const lockedYellowIcon = require(`../assets/img/sharedIcons/locked-yellow.svg`);
export const lockedDarkGray = require(`../assets/img/sharedIcons/locked-dark-gray.svg`);
export const unlockedIcon = require(`../assets/img/sharedIcons/unlocked.svg`);
export const addGreyIcon = require(`../assets/img/sharedIcons/add-grey.svg`);
export const addBlueIcon = require(`../assets/img/sharedIcons/add.svg`);
export const crossIcon = require(`../assets/img/sharedIcons/cross.svg`);
export const clockIcon = require(`../assets/img/sharedIcons/clock.svg`);
export const questionMarkIcon = require(`../assets/img/sharedIcons/question-mark.png`);
export const chevronRightIcon = require(`../assets/img/sharedIcons/chevron-right.svg`);
export const chevronDownIcon = require(`../assets/img/sharedIcons/chevron-down.svg`);
export const chevronDownBlackIcon = require(`../assets/img/sharedIcons/chevron-down-black.svg`);
export const chevronUpIcon = require(`../assets/img/sharedIcons/chevron-up.svg`);
export const downArrowRedIcon = require(`../assets/img/sharedIcons/down-arrow-red.svg`);
export const upArrowGreenIcon = require(`../assets/img/sharedIcons/up-arrow-green.svg`);
export const calendarIcon = require(`../assets/img/sharedIcons/calendar.svg`);
export const calendarGrayIcon = require(`../assets/img/sharedIcons/calendar-gray.svg`);
export const tooltipIcon = require(`../assets/img/sharedIcons/tooltip.svg`);
export const stateAdd = require(`../assets/img/sharedIcons/state-add.svg`);
export const stateAwaiting = require(`../assets/img/sharedIcons/state-awaiting.svg`);
export const stateLocked = require(`../assets/img/sharedIcons/state-locked.svg`);
export const stateReady = require(`../assets/img/sharedIcons/state-ready.svg`);
export const rightArrow = require(`../assets/img/sharedIcons/right-arrow.svg`);
export const notificationsIcon = require(`../assets/img/sharedIcons/notifications.svg`);
export const moreIcon = require('../assets/img/sharedIcons/more.svg');
export const sendIcon = require('../assets/img/sharedIcons/send.svg');
export const gearIcon = require('../assets/img/sharedIcons/gear.svg');

export const currencyItems: ChooseType[] = [
    {label: 'US$', value: 'USD', icon: '$'},
    {label: '€', value: 'EUR', icon: '€'},
    {label: '¥', value: 'JPY', icon: '¥'},
    {label: '£', value: 'GBP', icon: '£'},
    {label: 'A$', value: 'AUD', icon: 'A$'},
    {label: 'C$', value: 'CAD', icon: 'C$'},
    {label: 'Fr', value: 'CHF', icon: 'Fr'},
    {label: '元', value: 'CNY', icon: '元'},
    {label: 'kr', value: 'SEK', icon: 'kr'},
    {label: 'NZ$', value: 'NZD', icon: 'NZ$'},
    {label: 'Mex$', value: 'MXN', icon: 'Mex$'},
    {label: 'S$', value: 'SGD', icon: 'S$'},
    {label: 'HK$', value: 'HKD', icon: 'HK$'},
    {label: 'kr', value: 'NOK', icon: 'kr'},
    {label: '₩', value: 'KRW', icon: '₩'},
    {label: '₺', value: 'TRY', icon: '₺'},
    {label: '₽', value: 'RUB', icon: '₽'},
    {label: '₹', value: 'INR', icon: '₹'},
    {label: 'R$', value: 'BRL', icon: 'R$'},
    {label: 'R', value: 'ZAR', icon: 'R'},
];
