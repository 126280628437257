import { Action } from 'redux';
import { FiltersState } from 'store/Filters/types';
import { DropdownParams } from './types';

export const OPEN = 'store/diligence/filterDropdown/open';
export const CLOSE = 'store/diligence/filterDropdown/close';

export interface OpenDropdownAction extends Action {
    name: string;
    params: DropdownParams;
}

export interface CloseDropdownAction extends Action {
    currentFilter: FiltersState;
    filterName: string;
}

export type DiligenceFilterDropdownAction = OpenDropdownAction | CloseDropdownAction;