import * as React from 'react';

import { ColorsTextClasses, UploadStatusesText, RowStatusesText } from 'store/constants';
import { get, isEmpty, isEqual, cloneDeep } from 'lodash';
import { CustomTableRow, Id } from 'store/types';

export const Browsers = {
    Opera: 'Opera',
    Chrome: 'Chrome',
    Safari: 'Safari',
    Firefox: 'Firefox',
    IE: 'MSIE'
};

class GlobalHelper {
    static getStatusClass (status?: string) {
        let value: string = '';
        switch (status) {
            case UploadStatusesText[1]:
                value = ColorsTextClasses.primary;
                break;
            case RowStatusesText[2]:
                value = ColorsTextClasses.primaryRow;
                break;
            case UploadStatusesText[2]:
                value = ColorsTextClasses.warning;
                break;
            case UploadStatusesText[6]:
                value = ColorsTextClasses.warning;
                break;
            case RowStatusesText[1]:
                value = ColorsTextClasses.warningRow;
                break;
            case UploadStatusesText[3]:
                value = ColorsTextClasses.success;
                break;
            case RowStatusesText[3]:
                value = ColorsTextClasses.dangerRow;
                break;
            case RowStatusesText[5]:
                value = ColorsTextClasses.dangerRow;
                break;
            case RowStatusesText[6]:
                value = ColorsTextClasses.dangerRow;
                break;
            case UploadStatusesText[4]:
                value = ColorsTextClasses.danger;
                break;
            case RowStatusesText[4]:
                value = ColorsTextClasses.successRow;
                break;
            default:
                value = 'default';
                break;
        }

        return value;
    }

    static getValueFromObject(object: any, value: string, defaultValue: string | null = '') {
        return get(object, value, defaultValue);
    }

    static getFontSize(value: string) {
        const length = value.length;
        let returnValue = '2rem';
        if (length === 3) {
            returnValue = '1.5rem';
        } else if (length > 3 && length < 5) {
            returnValue = '1rem';
        } else if (length >= 5) {
            const numVal = 1 - (length * 0.04);
            returnValue = `${numVal}rem`;
        }
        return returnValue;
    }

    static isEqualArrays (arrayFirst: any[], arraySecond: any[] ) {
        const array1 = cloneDeep(arrayFirst);
        const array2 = cloneDeep(arraySecond);
        return isEqual(array1.sort(), array2.sort());
    }

    static isEmptyData (data: any) {
        return isEmpty(data);
    }

    static breakLine(text: string) {
        const regex = /(<br \/>)/g;
        return text.split(regex).map((line: string, index: number) => {
            return line.match(regex) ? React.createElement('br', {key: index}) : line;
        });
    }

    static addBreaks (value: string) {
        let output = value;
        const outputSplitted = output.split(' ');
        if (output.length > 30 && outputSplitted.length > 1) {
            output = '';
            let currentLine = '';
            outputSplitted.forEach((chunk: string) => {
                if (currentLine.length > 18) {
                    output += `<br />${chunk} `;
                    currentLine = `${chunk} `;
                } else {
                    currentLine += `${chunk} `;
                    output += `${chunk} `;
                }
            });
        }

        return output;
    }

    static replaceElemInString (currentElement: string, before: RegExp = /_/g, after: string = ' ') {
        if (typeof currentElement !== 'string') {
            return currentElement;
        }
        return currentElement.replace(before, after);
    }

    static ucFirst(str: string) {

        if (!str) { return str; }

        return str[0].toUpperCase() + str.slice(1);
    }

    static setRowType (collection: any[], rowType: string = CustomTableRow.ColoredRow): any[] {
        let answer: any[] = [];
        if (collection && collection.length) {
            answer = collection.map((item: any) => {
                return {
                    ...item,
                    rowType: rowType
                };
            });
        } else {
            answer.push({rowType: rowType, errorMessage: 'not data found'});
        }

        return answer;
    }

    static clearRowType (collection: any[]) {
        let answer: any[] = [];
        if (collection) {
            answer = collection.filter((item: any) => !item.rowType).map((item: any) => {
                return {...item, activeRow: false};
            });

        }

        return answer;
    }

    static clearAllRowTypes(collection: any[]) {
        let answer: any[] = [];
        if (collection) {
            answer = collection.filter((item: any) => !item.rowType);
        }

        return answer;
    }

    static getIndexInArray(currentArray: any[], id: Id) {
        return currentArray.findIndex((row: any) => {
            return row.id === id;
        });
    }

    static checkBrowser() {

        let currentBrowser: null | string = null;
        if ((navigator.userAgent.indexOf(Browsers.Opera) || navigator.userAgent.indexOf('OPR')) !== -1 ) {
            currentBrowser = Browsers.Opera;
        } else if (navigator.userAgent.indexOf(Browsers.Chrome) !== -1 ) {
            currentBrowser = Browsers.Chrome;
        } else if (navigator.userAgent.indexOf(Browsers.Safari) !== -1) {
            currentBrowser = Browsers.Safari;
        } else if (navigator.userAgent.indexOf(Browsers.Firefox) !== -1 ) {
            currentBrowser = Browsers.Firefox;
        } else if ((navigator.userAgent.indexOf(Browsers.IE) !== -1 ) || !!document.DOCUMENT_NODE) {
            currentBrowser = Browsers.IE;
        }

        return currentBrowser;
    }

    static isIE() {
        return GlobalHelper.checkBrowser() === Browsers.IE;
    }

    static getWidthOfScrollBar(): number {
        let outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        outer.style.msOverflowStyle = 'scrollbar';
        if (document.body == null) {
            return 0;
        }
        document.body.appendChild(outer);
        const widthNoScroll = outer.offsetWidth;
        outer.style.overflow = 'scroll';

        let inner = document.createElement('div');
        inner.style.width = '100%';
        outer.appendChild(inner);
        const widthWithScroll = inner.offsetWidth;
        if (outer.parentNode == null) {
            return 0;
        }

        outer.parentNode.removeChild(outer);

        return widthNoScroll - widthWithScroll;

    }

    static elementHasClass(element: Element, className: string): boolean {
        return element.classList.contains(className);
    }

    static findLastIndex(array: any[], searchKey: string, searchValue: any) {
        const index = array.slice().reverse().findIndex(x => x[searchKey] === searchValue);
        const count = array.length - 1;
        return index >= 0 ? count - index : index;
    }

}

export default GlobalHelper;
