import { all, put, takeLatest } from 'redux-saga/effects';

import * as userApi from 'services/diligence/user';
import { setUser } from 'store/Diligence/Auth/actionCreators';
import { messages } from './constants';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';

import {
    UPDATE_PASSWORD, UPDATE_PROFILE, UpdatePasswordAction, UpdateProfileAction
} from './actions';

function* updateProfile(action: UpdateProfileAction) {
    try {
        yield put(initAction(action.type));
        const user = yield userApi.updateProfile(action.request);
        yield put(setUser(user));
        yield put(doneActionSuccess(action.type, messages.UPDATE_PROFILE_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.UPDATE_PROFILE_FAIL_MESSAGE));
    }
}

function* updatePassword(action: UpdatePasswordAction) {
    try {
        yield put(initAction(action.type));
        yield userApi.updatePassword(action.request);
        yield put(doneActionSuccess(action.type, messages.UPDATE_PASSWORD_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.UPDATE_PASSWORD_FAIL_MESSAGE));
    }
}

function* watchUpdateProfile() {
    yield takeLatest(UPDATE_PROFILE, updateProfile);
}

function* watchUpdatePassword() {
    yield takeLatest(UPDATE_PASSWORD, updatePassword);
}

export default function* root() {
    yield all([
        watchUpdateProfile(), watchUpdatePassword()
    ]);
}