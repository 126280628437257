import axios, { AxiosInstance } from 'axios';
import * as qs from 'qs';

import mockApi from 'mock/mockApi';
import { redirectToLogout } from 'helpers/authHelper';
import GlobalHelper from 'helpers/globalHelper';
import { ApiHelper } from 'helpers/apiHelper';

export const BASE_API_URL = `${process.env.REACT_APP_API_URL}`;
export const API_ERM_ENTITIES = `${BASE_API_URL}/erm`;
export const API_USER_URL = `${BASE_API_URL}/users`;
export const API_ACCOUNT_URL = `${BASE_API_URL}/accounts`;

export const API_PORTFOLIO_URL = `${BASE_API_URL}/portfolio-management`;
export const API_FUND_CONTACTS_URL = `${API_PORTFOLIO_URL}/fund-contacts`;
export const API_FUND_REQUESTOR_URL = `${API_PORTFOLIO_URL}/requestor`;
export const API_FUND_RESPONDER_URL = `${API_PORTFOLIO_URL}/responder`;

export const API_REQUEST_URL = `${BASE_API_URL}/requests`;
export const API_DATA_MANAGER_URL = `${BASE_API_URL}/data-manager`;
export const API_ACL_URL = `${BASE_API_URL}/acl`;
export const API_BULK_IMPORT_URL = `${BASE_API_URL}/bulk-import`;
export const API_DASHBOARD = `${BASE_API_URL}/dashboard`;
export const API_DASHBOARD_REQUESTOR = `${API_DASHBOARD}/requestor`;
export const API_RULE_MANAGER = `${BASE_API_URL}/rule-manager`;
export const API_GROUP_MANAGER = `${BASE_API_URL}/group-manager`;
export const API_GLOBAL_SEARCH = `${BASE_API_URL}/global-search`;

const axiosClient: AxiosInstance = axios.create({
    baseURL: BASE_API_URL,
    withCredentials: process.env.REACT_APP_ENV === 'local',
    paramsSerializer: (params: any) => {
        return qs.stringify(params, {arrayFormat: 'repeat'});
    },
});

if (parseInt(process.env.REACT_APP_MOCK_ENABLED || '0', 10)) {
    mockApi(axiosClient);
}

axiosClient.interceptors.response.use(
    function (response: any): any {
        if (response.status === 204 && !GlobalHelper.isIE()) {
            return new Response(null, response);
        } else if ([403, 401].indexOf(response.status) >= 0) {
            redirectToLogout();
        }
        return new Response(JSON.stringify(response.data), response);
    },
    function (error: any): any {
        const response = error.response;
        if ([403, 401].indexOf(response.status) >= 0) {
            redirectToLogout();
        }
        return Promise.resolve(new Response(JSON.stringify(response.data), response));
    }
);

const creditApiHelper = new ApiHelper(axiosClient);

export default creditApiHelper;
