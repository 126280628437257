import * as React from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';

import { FiltersState } from 'store/Filters/types';
import Checkbox from '../Ui/Checkbox';
import ButtonGroup from '../Ui/Buttons/ButtonGroup';
import ColoredScrollbar from '../Scroll/ColoredScrollbar';
import Input from '../Ui/Input';
import { FilterItem, SelectItem } from 'store/types';

const styles = require('./SelectWithCheckboxes.scss');

interface Props {
    filter: FilterItem;
    value: string[];
    handleChange: (data: FiltersState) => void;
    disabled?: boolean;
}

class SelectWithCheckboxesSync extends React.PureComponent<Props> {
    state = {
        search: '',
    };

    handleSearch = (text: string) => {
        this.setState({search: text});
    }

    handleSorting = (val: string) => {
        // Todo: clarify the sorting behavior
    }

    render () {
        const { filter, value, handleChange } = this.props;
        const { search } = this.state;
        const choicesItemsArray = value && filter.choices && filter.choices.filter(
            (choice: SelectItem) => value.indexOf(String(choice.value)) >= 0
        );
        const choicesLabels = choicesItemsArray && choicesItemsArray.map((choice: SelectItem) => choice.label);
        let filteredChoices = filter.choices;
        if (search) {
            filteredChoices = filter.choices && filter.choices.filter(
                (choice: SelectItem) => String(choice.label.toLowerCase()).indexOf(search.toLowerCase()) >= 0
            );
        }

        const filterTitle: JSX.Element = choicesLabels && choicesLabels.length ?
            (<div className={styles.activeChoicesTitle}>{choicesLabels.join(', ')}</div>) :
            (<div>{'All'}</div>);

        return (
            <UncontrolledDropdown className={styles.filterWithCheckboxes}>
                <DropdownToggle
                    disabled={this.props.disabled}
                    className={styles.filterTitle}
                >
                    <h5 className={styles.label}>{filter.title}</h5>
                    {filterTitle}
                    <i/>
                </DropdownToggle>
                <DropdownMenu>
                    <div className={styles.dropHeader}>
                        {
                            filter.withSortingButtons &&
                            <ButtonGroup
                                className={styles.sortButtons}
                                handleClick={this.handleSorting}
                                firstValue="name"
                                secondValue="-name"
                                firstTitle="Ascending"
                                secondTitle="Descending"
                                firstInverse={filter.firstInverse}
                                secondInverse={filter.secondInverse}
                            />
                        }
                        <Input
                            className={['form-control', styles.searchLine].join(' ')}
                            type="text"
                            value={this.state.search}
                            placeholder={'Search'}
                            handleChange={this.handleSearch}
                            autoFocus={true}
                        />
                        <div className={styles.selectChoicesList}>
                            <ColoredScrollbar
                                alwaysShowTracks={true}
                                className="select-with-checkboxes"
                            >
                                {
                                    filteredChoices &&
                                    filteredChoices.map((item: SelectItem, idx: number) => {
                                        let checked = false;
                                        let result = value;
                                        if (value) {
                                            const valueIndex = value.indexOf(String(item.value));
                                            checked = valueIndex >= 0;
                                            result = checked ?
                                                value.filter(val => val !== item.value) :
                                                [...value, String(item.value)];
                                        }

                                        return (
                                            <div
                                                key={`${filter.name}-${item.value}-${idx}`}
                                                className={styles['checkbox-wrapper']}
                                            >
                                                <Checkbox
                                                    idx={`${filter.name}-${item.value}-${idx}`}
                                                    className="aligned-checkboxes"
                                                    checked={checked}
                                                    handleChange={
                                                        () => handleChange(
                                                            {[filter.name]: result}
                                                        )
                                                    }
                                                >
                                                    {item.label}
                                                </Checkbox>
                                            </div>
                                        );
                                    })
                                }
                            </ColoredScrollbar>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}

export default SelectWithCheckboxesSync;