import * as React from 'react';

import { Dataset } from 'store/DataManager/models/dataset';
import { formatText } from 'helpers/tableHelper';
import { isMadeLatest } from 'store/DataManager/datasetHelper';
import { FormatTypeChoices } from 'store/constants';

interface Props {
    row: Dataset;
    id?: string;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
}
const LastUpdatedCell: React.FC<Props> = ({row, ...otherProps}) => (
    <div {...otherProps}>
        {formatText(row.updated_at, FormatTypeChoices.FromNowIfLessThanAMonth)}
        {isMadeLatest(row) ? ' *' : ''}
    </div>
);

export default LastUpdatedCell;
