import { takeLatest, take, put, all, takeEvery, select, call, Effect } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { isEqual } from 'lodash';

import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import * as dashboardApi from 'services/dashboard';
import { getChartDataAvailablePeriodsStat, getChartResult as getChartResultSelector } from 'store/Dashboard/selectors';
import {
    APPLY_DISPERSION_FILTER,
    APPLY_FILTER,
    ApplyFilterAction,
    GET_ALERT_DONUT_REPORT,
    GET_BOX_CHART_DATA,
    GET_CHART_DATA,
    GET_DASHBOARD_DATA,
    GET_PERFORMANCE_DONUT_REPORT,
    GET_STATISTIC_DONUT_REPORT,
    GetAlertDonutReportAction,
    GetBoxChartDataAction,
    GetChartDataAction,
    GetPerformanceDonutReportAction,
    GetStatisticDonutReportAction,
    UPDATE_DASHBOARD_SETTINGS,
    UPDATE_DISPERSION_SETTINGS, UpdateDashboardSettingsAction,
    UpdateDispersionSettingsAction,
} from './actions';
import {
    getStatisticDonutReport as getStatisticDonutReportCreator,
    setStatisticDonutReport as setStatisticDonutReportCreator,
    getDashboardData as getDashboardDataCreator, setDashboardData,
    getPerformanceDonutReport as getPerformanceDonutReportCreator,
    getAlertDonutReport as getAlertDonutReportCreator,
    getChartData as getChartDataCreator,
    setChartData as setChartDataCreator,
    getBoxChartData as getBoxChartDataCreator,
    setBoxChartData as setBoxChartDataCreator,
} from './actionCreators';
import {
    DashboardChartType,
    DonutPieceTitle,
    DonutStatisticItemResponse,
    StatisticDonutDataType
} from './types';
import { DonutPiece } from '../Shared/types';
import { FiltersState } from '../Filters/types';
import { setIsLoading } from '../Filters/actionCreators';
import { MIN_AVAILABLE_PERIODS_FOR_CHART } from './constants';
import { updateUserAccountSettings } from '../Auth/actionCreators';
import { getFilters } from '../Filters/selectors';
import { getSelectedDashboardChartsTypes, getSelectedDispersionChartsTypes } from '../Auth/selectors';

function* getDashboardData(action: ApplyFilterAction) {
    yield delay(200);

    try {
        yield put(initAction(action.type));

        const response = yield dashboardApi.getData({
            ...action.filter
        });

        yield put(setIsLoading(true));

        yield put(setDashboardData(
            action.filter,
            response.results,
            response.count,
            response.count_firm,
            response.count_fund,
            !!action.filter.scroller
        ));

        yield put(setIsLoading(false));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getStatisticDonutReport(action: GetStatisticDonutReportAction) {
    try {
        let filter = action.filter;

        let data: DonutPiece[] = [];
        if (action.dataType === StatisticDonutDataType.PerformanceReport) {
            const statistic = yield dashboardApi.getPerformanceReport(filter);
            data = statistic.map((item: DonutStatisticItemResponse) => ({
                name: item.status,
                title: DonutPieceTitle[item.status],
                value: item.count
            }));
        } else {
            const statistic = yield dashboardApi.getAlertsReport(filter);
            data = statistic.map((item: DonutStatisticItemResponse) => ({
                name: item.status,
                title: DonutPieceTitle[item.status],
                value: item.count
            }));
        }

        const {dataType} = action;
        yield put(setStatisticDonutReportCreator(data, dataType));
    } catch (errors) {
    }
}

function* getPerformanceDonutReport(action: GetPerformanceDonutReportAction) {
    yield delay(200);

    yield put(getStatisticDonutReportCreator(
        action.filter,
        StatisticDonutDataType.PerformanceReport
    ));
}

function* getAlertDonutReport(action: GetAlertDonutReportAction) {
    yield delay(200);

    yield put(getStatisticDonutReportCreator(
        action.filter,
        StatisticDonutDataType.Alerts
    ));
}

function* getChartData(action: GetChartDataAction) {
    const periodStat = yield select(getChartDataAvailablePeriodsStat, {chartPeriod: action.filter.period}),
        chartResult = yield select(getChartResultSelector, {chartPeriod: action.filter.period});

    // Check only 'before' because we starts load data from current day, so we will have all data started from now.
    if (action.force || !chartResult || (periodStat.before <= MIN_AVAILABLE_PERIODS_FOR_CHART)) {
        try {
            yield put(initAction(action.type));

            const chartTypes = yield select(getSelectedDashboardChartsTypes);

            const data = yield dashboardApi.getDatasetData({...action.filter, chart_types: chartTypes});
            yield put(setChartDataCreator(data, action.force));

            yield put(doneActionSuccess(action.type));
        } catch (errors) {
            yield put(doneActionFail(action.type, errors));
        }
    }
}

function* getDashboardChartData(
    filter: FiltersState, filterChanged: boolean,
    chartTypes: DashboardChartType[] | null = null
) {
    if (chartTypes === null) {
        chartTypes = (yield select(getSelectedDashboardChartsTypes));
    }

    if (chartTypes) {
        const effects: Effect[] = [];

        let perfDonut = false;
        if (chartTypes.indexOf(DashboardChartType.ReportingPerformance) >= 0) {
            perfDonut = true;
        }

        let alertDonut = false;
        if (chartTypes.indexOf(DashboardChartType.Alerts) >= 0) {
            alertDonut = true;
        }

        const chartsCount = chartTypes.length - +perfDonut - +alertDonut;
        if (chartsCount > 0) {
            effects.push(put(getChartDataCreator(filter, filterChanged)));
        }

        if (perfDonut) {
            effects.push(put(getPerformanceDonutReportCreator(filter)));
        }

        if (alertDonut) {
            effects.push(put(getAlertDonutReportCreator(filter)));
        }

        yield all(effects);
    }
}

function* getBoxChartData(action: GetBoxChartDataAction) {
    yield delay(200);

    try {
        yield put(initAction(action.type));

        let chartTypes = yield select(getSelectedDispersionChartsTypes);

        const response = yield dashboardApi.getDispersionData({...action.filter, period_type: chartTypes});
        yield put(setBoxChartDataCreator(response));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* updateDispersionSettings(action: UpdateDispersionSettingsAction) {
    const chartTypes = yield select(getSelectedDispersionChartsTypes);
    const newChartTypes = (action.userAccountSettings.dispersion_charts || [])
        .filter(chartType => chartTypes.indexOf(chartType) === -1);

    if (newChartTypes.length) {
        const filter = yield select(getFilters, action.filterName);
        yield put(getBoxChartDataCreator(filter));
    }

    yield put(updateUserAccountSettings(action.userAccountSettings, action.waitResponse));
}

function* updateDashboardSettings(action: UpdateDashboardSettingsAction) {
    const chartTypes = yield select(getSelectedDashboardChartsTypes);
    const newChartTypes = (action.userAccountSettings.dashboard_charts || [])
        .filter(chartType => chartTypes.indexOf(chartType) === -1);

    yield put(updateUserAccountSettings(action.userAccountSettings, action.waitResponse));

    if (newChartTypes.length) {
        const filter = yield select(getFilters, action.filterName);
        yield getDashboardChartData(filter, true, newChartTypes);
    }
}

function* watchDashboardTabApplyFilterLoop() {
    let prevFilter: FiltersState = {}, prevPeriod = '';

    while (true) {
        let action: ApplyFilterAction = yield take(APPLY_FILTER);
        const {
            attribute_type, limit, offset, scroller, currentPage, period,
            ...filterForCompare
        } = action.filter;

        if (action.filter) {
            const filterChanged = !isEqual(prevFilter, filterForCompare);
            if (filterChanged || (period !== prevPeriod)) {
                yield getDashboardChartData({...filterForCompare, period}, filterChanged);
            }
            yield put(getDashboardDataCreator(action.filter));

            prevFilter = filterForCompare;
            prevPeriod = period;
        }
    }
}

function* watchDispersionTabApplyFilterLoop() {
    let prevFilter: FiltersState = {}, prevPeriod = '';

    while (true) {
        let action: ApplyFilterAction = yield take(APPLY_DISPERSION_FILTER);

        const {
            limit, offset, scroller, currentPage,
            ...filterForCompare
        } = action.filter;

        if (action.filter) {
            const filterChanged = !isEqual(prevFilter, filterForCompare);
            if (filterChanged) {
                yield put(getBoxChartDataCreator(filterForCompare));
            }
            yield put(getDashboardDataCreator(action.filter));

            prevFilter = filterForCompare;
        }
    }
}

function* watchApplyFilter() {
    yield takeLatest(GET_DASHBOARD_DATA, getDashboardData);
}

function* watchGetPerformanceDonutReport() {
    yield takeLatest(GET_PERFORMANCE_DONUT_REPORT, getPerformanceDonutReport);
}

function* watchGetAlertDonutReport() {
    yield takeLatest(GET_ALERT_DONUT_REPORT, getAlertDonutReport);
}

function* watchGetStatisticDonutReport() {
    yield takeEvery(GET_STATISTIC_DONUT_REPORT, getStatisticDonutReport);
}

function* watchGetChartData() {
    yield takeEvery(GET_CHART_DATA, getChartData);
}

function* watchGetBoxChartData() {
    yield takeEvery(GET_BOX_CHART_DATA, getBoxChartData);
}

function* watchUpdateDispersionSettings() {
    yield takeEvery(UPDATE_DISPERSION_SETTINGS, updateDispersionSettings);
}

function* watchUpdateDashboardSettings() {
    yield takeEvery(UPDATE_DASHBOARD_SETTINGS, updateDashboardSettings);
}

export default function* root() {
    yield all([
        watchApplyFilter(),
        watchGetStatisticDonutReport(),
        watchGetPerformanceDonutReport(),
        watchGetAlertDonutReport(),
        watchDashboardTabApplyFilterLoop(),
        watchDispersionTabApplyFilterLoop(),
        watchGetChartData(),
        watchGetBoxChartData(),
        watchUpdateDispersionSettings(),
        watchUpdateDashboardSettings(),
    ]);
}
