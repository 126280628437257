import { put, all, takeEvery, select } from 'redux-saga/effects';

import { RESET_OFFSET_AND_SCROLL } from 'store/constants';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { CALL_APPLY_FILTERS, CallApplyFiltersAction } from './actions';

function* callApplyFilters(action: CallApplyFiltersAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        const filter = store.filters[action.currentFilter];
        yield put({ type: action.actionType, filter: { ...filter, ...RESET_OFFSET_AND_SCROLL, ...action.otherFilter}});
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchCallApplyFilters() {
    yield takeEvery(CALL_APPLY_FILTERS, callApplyFilters);
}

export default function* root() {
    yield all(
        [
            watchCallApplyFilters(),

        ]
    );
}
