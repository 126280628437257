import * as React from 'react';

import { TableHeader } from 'store/types';
import Checkbox from 'components/Shared/Ui/Checkbox';
import HeaderHelper from 'helpers/headerHelper';

interface Props {
    temporaryHeaders: any;
    setDatasetDetailTemporaryHeaders: any;
    headers: TableHeader[];
    handleColumns: (column: any) => void;
}

export default class PerformanceConfigurationModal extends React.Component<Props> {
    headersInModal = [
        'perf_daily', 'perf_mtd', 'perf_weekly', 'perf_qtd', 'perf_month',  'perf_ytd'
    ];

    componentDidMount () {
        this.props.setDatasetDetailTemporaryHeaders(this.props.headers);
    }

    handleColumns = (column: any) => {
        const newColumns = this.props.temporaryHeaders.map((item: any) => {
            let answer = item;
            if (item.name === column.name) {
                answer = {
                    ...item,
                    ...column
                };
            }

            return answer;
        });
        this.props.setDatasetDetailTemporaryHeaders(newColumns);
    }

    render () {

        const headers = HeaderHelper.clearColumn(this.props.temporaryHeaders, this.headersInModal);
        const checkboxes = headers.map((item: any, idx: number) => {
            return (
                <div key={idx} className="col-md-6">
                    <Checkbox
                        key={idx}
                        className="aligned-checkboxes"
                        idx={`performance-${idx}`}
                        checked={item.active}
                        handleChange={(event) => this.handleColumns({ active: !item.active, name: item.name })}
                    >
                        {item.title}
                    </Checkbox>
                </div>
            );
        });

        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="label">Fields</h5>
                    </div>
                    {checkboxes}
                </div>
            </>
        );
    }
}