import { FirmState } from './types';
import {
    FirmAction, ApplyFilterAction, SetFirmsAction, SetFirmAction, SetFirmFundsAction,
    APPLY_FILTER, SET_FIRMS, SET_FIRM, SET_FIRMS_WITH_SCROLL, SET_FIRM_FUNDS,
    ApplyFilterForFundsAction, APPLY_FILTER_FOR_FUNDS, SET_FIRM_FUNDS_WITH_SCROLL,
} from './actions';
import { initialState } from './constants';

export const firmReducer = (state: FirmState = initialState, action: FirmAction) => {
    switch (action.type) {
        case APPLY_FILTER:
            return {
                ...state,
                filter: (<ApplyFilterAction> action).filter
            };
        case SET_FIRMS:
            return {
                ...state,
                list: (<SetFirmsAction> action).firms,
                count: (<SetFirmsAction> action).count
            };
        case SET_FIRM:
            return {
                ...state,
                firm: {
                    ...state.firm,
                    ...(<SetFirmAction> action).firm
                },
            };
        case SET_FIRMS_WITH_SCROLL:
            const list = [
                ...state.list,
                ...(<SetFirmsAction> action).firms
            ];
            return {
                ...state,
                list
            };
        case SET_FIRM_FUNDS_WITH_SCROLL:
            const newFunds = (<SetFirmFundsAction> action).funds.map((fund) => fund.start);
            const allFunds = [
                ...state.funds,
                ...newFunds
            ];
            return {
                ...state,
                funds: allFunds
            };
        case APPLY_FILTER_FOR_FUNDS:
            return {
                ...state,
                filter: (<ApplyFilterForFundsAction> action).filter
            };
        case SET_FIRM_FUNDS:
            const funds = (<SetFirmFundsAction> action).funds.map((fund) => fund.start);
            return {
                ...state,
                funds,
                fundCount: (<SetFirmFundsAction> action).count,
            };
        default:
            return state;
    }
};
