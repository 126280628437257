import * as React from 'react';
import * as classNames from 'classnames';
import { TableTypes } from './TableTypes';

const styles = require('./TableDifferentRow.scss');
const TableDifferentRow: React.FC<TableTypes> = ({children, className, inverse}) => {
    const tableClass = classNames({
        'ui-table': true,
        [className]: !!className,
        [styles.ui_different_row]: !!styles.ui_different_row,
        [styles.ui_different_row_inverse]: inverse,
    });

    return (
        <div className={tableClass}>{children}</div>
    );
};

export default TableDifferentRow;
