import * as React from 'react';

import { StatItem } from 'store/types';
import { viewIcon } from 'store/constants';

interface Props {
    stats: StatItem[];
}

const styles = require('./Statistics.scss');
class Statistics extends React.PureComponent<Props> {

    handleClick = (item: StatItem) => {
        if (item.withViewIcon && item.handleIconClick) {
            item.handleIconClick();
        }
    }

    render () {
        return (
            <div className={styles['stats-panel']}>
                {
                    this.props && this.props.stats.map((item: StatItem, key: number) => {

                        return (
                            <div key={key} className={styles.stat}>
                                <span className={styles['stat-name']}>{`${item.name}: `}</span>
                                <span className={styles['stat-value']}>{item.value}</span>
                                {
                                    item.withViewIcon &&
                                    <img
                                        src={viewIcon}
                                        alt={'view'}
                                        className={`${styles['stat-icon']}`}
                                        onClick={(event: React.MouseEvent<HTMLElement>) => this.handleClick(item)}
                                    />
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default Statistics;
