import ApiHelper, { API_ACCOUNT_URL } from 'helpers/creditApiHelper';

import AccountUsersFilter from 'store/Account/models/filter';
import { PAGE_SIZE } from 'store/constants';
import { UserAccount } from 'store/Auth/models/userAccount';
import { messages } from 'store/Account/constants';
import { InvitedUser } from 'store/Shared/models/invitedUser';
import { Id } from 'store/types';

export const getUsers = async (filter: AccountUsersFilter): Promise<any> => {
    return await ApiHelper.doRequest(
        `${API_ACCOUNT_URL}/users/`,
        'get',
        {
            params: {
                limit: PAGE_SIZE,
                offset: filter.page * PAGE_SIZE
            }
        }
    );
};

export const deleteUser = async (id: Id): Promise<any> => {
    return await ApiHelper.doRequest(
        `${API_ACCOUNT_URL}/users/${id}/`, 'delete'
    );
};

export const deleteUsers = async (ids: Id[], checkedAll: boolean): Promise<any> => {
    return await ApiHelper.doRequest(
        `${API_ACCOUNT_URL}/users/bulk-delete/`,
        'post',
        {
            ids,
            checkedAll,
        }
    );
};

export const updateUser = async (userAccount: UserAccount) => {
    if (!userAccount.user) {
        return Promise.reject(messages.USER_IS_NOT_DEFINED);
    }

    return await ApiHelper.doRequest(
        `${API_ACCOUNT_URL}/users/${userAccount.user.id}/`, 'patch', {
            status: userAccount.status,
            role: userAccount.role
        }
    );
};

export const updateUserRole = async (id: Id, role: string) => {
    return await ApiHelper.doRequest(
        `${API_ACCOUNT_URL}/users/${id}/`, 'patch', {
            role
        }
    );
};

export const inviteUser = async (invitedUser: InvitedUser) => {
    return await ApiHelper.doRequest(
        `${API_ACCOUNT_URL}/users/`, 'post', {
            email: invitedUser.email,
            first_name: invitedUser.first_name,
            last_name: invitedUser.last_name,
            role: invitedUser.role
        }
    );
};