import * as React from 'react';
import { cloneDeep } from 'lodash';

import { DiligenceFilterTypeTitle, FilterEntity } from 'store/Diligence/DiligenceRequest/types';
import { FiltersState } from 'store/Filters/types';
import { DiligenceFilterNames } from 'store/Diligence/Filters/types';

interface Props {
    filterEntities?: FilterEntity[];
    handleChange: (data: FiltersState) => void;
    filterPageName?: string;
    currentFilter: FiltersState;
}
const styles = require('./SearchFilterResults.scss');

class SearchFilterResults extends React.Component<Props> {
    render() {
        const {filterEntities, filterPageName} = this.props;
        return (
            <div className={styles.results}>
                {filterEntities && filterEntities.map((entity, idx: number) => {
                    const uniqueKey = `${entity.name}-${entity.type}-${idx}`;

                    return (
                        <div
                            key={uniqueKey}
                            className={`${styles.search_result}`}
                            onClick={() => this.handleClick(entity)}
                        >
                            <div>{entity.name}</div>
                            <div>
                            <span className={styles.type}>
                                {
                                    filterPageName && filterPageName === DiligenceFilterNames.diligenceRequest
                                        ? DiligenceFilterTypeTitle[entity.type]
                                        : (entity.type === 'credit_officer' ? 'coverage' : entity.type)
                                }
                            </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    private handleClick = (entity: FilterEntity) => {
        const {handleChange, filterPageName, currentFilter} = this.props;

        let data = {};
        if (filterPageName !== DiligenceFilterNames.diligenceRequest) {
            const value = this.getFilterValueByType(entity.type),
                name = this.getFilterNameByType(entity.type);
            if (name && value && Array.isArray(value)) {
                let result = cloneDeep(value);
                const currentValues = value.map((v) => v.value);
                if (currentValues.indexOf(entity.id) < 0) {
                    result.push({label: entity.name, value: entity.id});
                }
                data = {
                    search: '',
                    [name]: result
                };
            }
        } else {
            data = {name: entity.name, type: entity.type};
        }

        handleChange(data);
    }

    private getFilterNameByType(type: string) {
        const {currentFilter} = this.props;

        if (currentFilter.hasOwnProperty(`${type}_id`)) {
            return `${type}_id`;
        } else if (currentFilter.hasOwnProperty(type)) {
            return type;
        }

        return undefined;
    }

    private getFilterValueByType(type: string) {
        const {currentFilter} = this.props;

        const name = this.getFilterNameByType(type);
        if (name) {
            return currentFilter[name];
        }

        return undefined;
    }
}

export default SearchFilterResults;
