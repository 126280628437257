import * as React from 'react';
import * as classNames from 'classnames';

import { closeYellowIcon } from 'store/constants';
import { AlertTypes } from './AlertTypes';

const styles = require('./AlertDefault.scss');
const AlertDefault: React.FC<AlertTypes> = ({
    children, className, inverse, hasCloseIcon, handleClick
}) => {
    const alertClass = classNames({
        [className]: !!className,
        [styles['alert-default']]: !!styles['alert-default'],
        [styles['alert-default-inverse']]: inverse,
    });

    return (
        <div className={alertClass}>
            {
                hasCloseIcon &&
                <span className={styles.closer}>
                    <img
                        src={closeYellowIcon}
                        className={styles.closerIcon}
                        onClick={handleClick}
                    />
                </span>
            }
            {children}
        </div>
    );
};

AlertDefault.defaultProps = {
};

export default AlertDefault;
