import CreditApiHelper, { API_DATA_MANAGER_URL } from 'helpers/creditApiHelper';
import { ApiHelper } from 'helpers/apiHelper';

import { GetDatasetHistory, GetListResponse, UpdatingDatasetDetailField } from 'store/DataManager/types';
import {
    deniedGetParams, datasetsDeniedGetParams, defaultDeniedGetParams,
    PAGINATION_LARGE_LIMIT
} from 'store/constants';
import { RequestStatus } from 'store/Requests/types';
import { Id } from 'store/types';
import { FiltersState } from 'store/Filters/types';
import { Dataset, DatasetRow } from 'store/DataManager/models/dataset';
import { prepareFilterState } from 'store/Filters/helpers';

export const getDatasetList = async (filter: any): Promise<GetListResponse> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/fund-datasets/`,
        'get',
        {params: filter}
    );
};

export const getPublishedDatasets = async (filter: any): Promise<GetListResponse> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requestor/published-dataset-history/`,
        'get',
        {params: filter}
    );
};

export const getRequestors = async (
    fundId?: Id,
    params: any = {status: 'active'}): Promise<GetListResponse> => {
    let query = '?';

    if (fundId) {
        query += `fund=${fundId}&`;
    }

    query += Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requests/${query}` +
        `&limit=${PAGINATION_LARGE_LIMIT}`,
        'get'
    );
};

export const changeRequestStatus = (requestId: Id, status: RequestStatus, message?: string) => {
    const request: any = {
        status: status
    };

    if (status === RequestStatus.Denied) {
        request.rejection_message = message;
    }

    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requests/${requestId}/`,
        'patch',
        request
    );
};

export const publishDataset = async (fundId: Id, requestors: any): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/fund-datasets/${fundId}/publish/`,
        'post',
        {requestors}
    );
};

export const setPendingPublishDataset = async (
    fundId: any,
    daysBeforeDueDate: number,
    requestorIds: any
): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/fund-datasets/${fundId}/schedule/`,
        'post',
        {
            days_before_due_date: daysBeforeDueDate,
            requestors: requestorIds,
        }
    );
};

export const editPendingPublishDataset = async (
    fundId: any,
    requestorId: any,
    daysBeforeDueDate: number,
): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/fund-datasets/${fundId}/schedule/`,
        'patch',
        {
            days_before: daysBeforeDueDate,
            requestor_id: requestorId,
        }
    );
};

export const DatasetDetailFund = async (filter: any): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/unpublished-datasets/`,
        'get',
        {params: filter}
    );
};

export const SaveSettingsDatasetFund = async (id: string, payload: any): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/fund-configuration/${id}/`,
        'patch',
        {...payload}
    );
};

export const SaveSettingsRequestorDatasetFund = async (id: string, payload: any): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requestor/fund-configuration/${id}/`,
        'patch',
        {...payload},
    );
};

export const getDatasetFund = async (id: any): Promise<any> => {
    return CreditApiHelper.doRequest(`${API_DATA_MANAGER_URL}/fund-datasets/${id}/`, 'get');
};

export const updateDatasetDetailField = async (payload: UpdatingDatasetDetailField): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/unpublished-datasets/${payload.id}/`,
        'patch',
        {...payload.editableRow}
    );
};

export const unlockDatasetDetailRow = async (datasetRow: DatasetRow): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/unpublished-datasets/${datasetRow.dataset.id}/locking/`,
        'post',
        {
            is_locked: datasetRow.locked
        }
    );
};

export const addDatasetToFund = async (payload: any): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/unpublished-datasets/`,
        'post',
        {...payload}
    );
};

export const exportFile = async (checkedIds: Id[], checkedAll: boolean, filter?: any): Promise<any> => {
    const filteredParams = filter ? ApiHelper.getClearParams(filter, deniedGetParams) : {};
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/fund-datasets/export-file/`,
        'get',
        {
            params: {
                ...filteredParams,
                checkedAll,
                fund_id: checkedIds,
            }
        }
    );
};

export const exportPublishedDatasetFile = async (checkedIds: Id[], checkedAll: boolean, filter?: any): Promise<any> => {
    let filteredParams = filter ? ApiHelper.getClearParams(filter, deniedGetParams) : {};
    filteredParams = prepareFilterState(filteredParams);
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requestor/published-dataset-history/export-file/`,
        'get',
        {
            params: {
                ...filteredParams,
                checkedAll,
                dataset_id: checkedIds,
            }
        }
    );
};

export const exportInternalDataFile = async (checkedIds: Id[], checkedAll: boolean, filter?: any): Promise<any> => {
    let filteredParams = filter ? ApiHelper.getClearParams(filter, deniedGetParams) : {};
    filteredParams = prepareFilterState(filteredParams);
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requestor/fund-datasets/internal-export/`,
        'get',
        {
            params: {
                ...filteredParams,
                checkedAll,
                fund_id: checkedIds,
            }
        }
    );
};

export const exportCombinedDataFile = async (checkedIds: Id[], checkedAll: boolean, filter?: any): Promise<any> => {
    let filteredParams = filter ? ApiHelper.getClearParams(filter, deniedGetParams) : {};
    filteredParams = prepareFilterState(filteredParams);
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requestor/fund-datasets/combine-export/`,
        'get',
        {
            params: {
                ...filteredParams,
                checkedAll,
                fund_id: checkedIds,
            }
        }
    );
};

export const getDatasetHistory = async (row: GetDatasetHistory): Promise<any> => {
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/published-datasets/`,
        'get',
        {
            params: {
                unpublished_dataset: row.parentId,
                ordering: '-created_at'
            }
        }
    );
};

export const getRequestorsPublishedDatasets = async (filter: FiltersState, fundApId: Id): Promise<any> => {
    const filteredParams = filter ? ApiHelper.getClearParams(filter, datasetsDeniedGetParams) : {};
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requestor/datasets/?fund=${fundApId}`,
        'get',
        {
            params: {
                ...filteredParams
            }
        }
    );
};

export const getRequestorsDatasetHistory = async (filter: FiltersState, fundApId: Id): Promise<any> => {
    const filteredParams = filter ? ApiHelper.getClearParams(filter, datasetsDeniedGetParams) : {};
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requestor/dataset-history/?fund=${fundApId}`,
        'get',
        {
            params: {
                ...filteredParams
            }
        }
    );
};

export const saveRequestorDataset = async (dataset: Dataset) => {
    const datasetId = dataset.internal_dataset_id;
    const url = datasetId
        ? `${API_DATA_MANAGER_URL}/requestor/datasets/${datasetId}/`
        : `${API_DATA_MANAGER_URL}/requestor/datasets/`;

    const method = datasetId
        ? 'put'
        : 'post';

    const response = await CreditApiHelper.doRequest(url, method, dataset);
    return Promise.resolve(response);
};

export const makeDatasetTheLatest = async (dataset: Dataset) => {
    const url = `${API_DATA_MANAGER_URL}/requestor/dataset-history/${dataset.id}/make-latest/`;
    const response = await CreditApiHelper.doRequest(url, 'put');
    return Promise.resolve(response);
};

export const exportRequestorsPublishedDatasets = async (filter: FiltersState, fundApId: Id): Promise<any> => {
    const filteredParams = filter ? ApiHelper.getClearParams(filter, deniedGetParams) : {};
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requestor/fund-datasets/combine-export/?fund_id=${fundApId}`,
        'get',
        {
            params: {
                ...filteredParams
            }
        }
    );
};

export const cancelDatasetUpload = async (payload: any) => {
    const response = await CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/import-file/${payload.fileId}/cancel/`,
        'post',
        {}
    );

    return Promise.resolve(response);
};

export const datasetUpload = async (files: any) => {
    try {
        const response = await CreditApiHelper.post(
            `${API_DATA_MANAGER_URL}/import-file/`,
            files,
            'multipart/form-data'
        );

        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const internalDataUpload = async (files: any) => {
    try {
        const response = await CreditApiHelper.post(
            `${API_DATA_MANAGER_URL}/requestor/import-file/`,
            files,
            'multipart/form-data'
        );

        const data = await response.json();
        if (!response.ok) {
            return Promise.reject(ApiHelper.getErrors(data));
        }

        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject([error.message]);
    }
};

export const getInternalData = async (filter: FiltersState): Promise<GetListResponse> => {
    const filteredParams = filter ? ApiHelper.getClearParams(filter, defaultDeniedGetParams) : {};
    return CreditApiHelper.doRequest(
        `${API_DATA_MANAGER_URL}/requestor/internal-data/`,
        'get',
        {
            params: filteredParams
        }
    );
};
