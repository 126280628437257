import * as React from 'react';

import { TableHeader } from 'store/types';
import {
    Alignment, CellTypeChoices, FormatTypeChoices, TypesFormat, DEFAULT_VALUE_DASH, currencyItems
} from 'store/constants';

import FirmFundNamesCell from 'components/Shared/DataTable/Cells/FirmFundNames';
import DynamicFormatCurrency from 'components/DataManager/TableCells/DynamicFormatCurrency';
import ReportingHistoryCell from 'components/DataManager/TableCells/ReportiongHistoryCell';
import StatusCell from 'components/DataManager/TableCells/StatusCell';
import AttributesCell from 'components/Fund/TableCells/AttributesCell';
import { dataVersion } from 'containers/DataManager/DatasetDetailModals/DatasetConfigurationModal';
import { classVersion, clssNameNav, clssNamePerformance } from 'components/DataManager/Dataset';
import DataSourceCell from 'components/Fund/TableCells/DataSourceCell';
import { Dataset } from 'store/DataManager/models/dataset';
import { isMadeLatest } from 'store/DataManager/datasetHelper';
import LastUpdatedCell from 'components/Fund/TableCells/LastUpdatedCell';
import LastUpdatedPopover from 'components/Fund/TableCells/LastUpdatedPopover';

export const fundListHeaders: TableHeader[] = [
    {
        name: 'firm_fund',
        title: 'Firm\nFund',
        headerAlignment: Alignment.Left,
        alias: 'wide-firm-fund',
        active: true,
        cellType: CellTypeChoices.Custom,
        orderingName: 'firm_name,name',
        fixed: true,
        minWidth: 350,
        transformer: row => (
            <FirmFundNamesCell
                firm={row.firm ? row.firm.name : ''}
                fund={row.name}
                link={`funds/${row.id}`}
            />
        )
    },
    {
        name: 'dataset.beginning_balance',
        columnName: 'beginning_balance',
        title: 'Beginning Balance',
        extraTitle: 'Beginning Balance',
        valueAlignment: Alignment.Right,
        active: false,
        cellType: CellTypeChoices.Custom,
        transformer: row => (
            <DynamicFormatCurrency
                row={row}
                valueField="dataset.beginning_balance"
                currencyField="currency"
                defaultValue={DEFAULT_VALUE_DASH}
            />
        ),
        excludeSorting: true,
    },
    {
        name: 'dataset.subscriptions',
        columnName: 'subscriptions',
        title: 'Subscriptions',
        extraTitle: 'Subscriptions',
        valueAlignment: Alignment.Right,
        active: false,
        cellType: CellTypeChoices.Custom,
        transformer: row => (
            <DynamicFormatCurrency
                row={row}
                valueField="dataset.subscriptions"
                currencyField="currency"
                defaultValue={DEFAULT_VALUE_DASH}
            />
        ),
        excludeSorting: true,
    },
    {
        name: 'dataset.redemptions',
        columnName: 'redemptions',
        title: 'Redemptions',
        extraTitle: 'Redemptions',
        valueAlignment: Alignment.Right,
        active: false,
        cellType: CellTypeChoices.Custom,
        transformer: row => (
            <DynamicFormatCurrency
                row={row}
                valueField="dataset.redemptions"
                currencyField="currency"
                defaultValue={DEFAULT_VALUE_DASH}
            />
        ),
        excludeSorting: true,
    },
    {
        name: 'dataset.income',
        columnName: 'income',
        title: 'Income',
        extraTitle: 'Income',
        valueAlignment: Alignment.Right,
        active: false,
        cellType: CellTypeChoices.Custom,
        transformer: row => (
            <DynamicFormatCurrency
                row={row}
                valueField="dataset.income"
                currencyField="currency"
                defaultValue={DEFAULT_VALUE_DASH}
            />
        ),
        excludeSorting: true,
    },
    {
        name: 'dataset.ending_balance',
        columnName: 'ending_balance',
        title: 'Ending Balance',
        extraTitle: 'Ending Balance',
        valueAlignment: Alignment.Right,
        active: true,
        cellType: CellTypeChoices.Custom,
        transformer: row => (
            <DynamicFormatCurrency
                row={row}
                valueField="dataset.ending_balance"
                currencyField="currency"
                defaultValue={DEFAULT_VALUE_DASH}
            />
        ),
        excludeSorting: true,
    },
    {
        name: 'dataset.known_future_redemptions',
        columnName: 'known_future_redemptions',
        title: 'Known Future Redemptions',
        extraTitle: 'Known Future Redemptions',
        valueAlignment: Alignment.Right,
        active: false,
        cellType: CellTypeChoices.Custom,
        transformer: row => (
            <DynamicFormatCurrency
                row={row}
                valueField="dataset.known_future_redemptions"
                currencyField="currency"
                defaultValue={DEFAULT_VALUE_DASH}
            />
        ),
        excludeSorting: true,
    },
    {
        name: 'dataset.nav_per_share',
        columnName: 'nav_per_share',
        title: 'NAV per Share',
        extraTitle: 'NAV per Share',
        valueAlignment: Alignment.Right,
        active: false,
        cellType: CellTypeChoices.Custom,
        transformer: row => (
            <DynamicFormatCurrency
                row={row}
                valueField="dataset.nav_per_share"
                currencyField="currency"
                defaultValue={DEFAULT_VALUE_DASH}
            />
        ),
        excludeSorting: true,
    },
    {
        name: 'dataset.perf_daily',
        columnName: 'perf_daily',
        title: 'Daily',
        extraTitle: 'Daily',
        valueAlignment: Alignment.Right,
        active: false,
        formatType: FormatTypeChoices.Percent,
        defaultValue: DEFAULT_VALUE_DASH,
        excludeSorting: true,
    },
    {
        name: 'dataset.perf_weekly',
        columnName: 'perf_weekly',
        title: 'Weekly',
        extraTitle: 'Weekly',
        valueAlignment: Alignment.Right,
        active: false,
        formatType: FormatTypeChoices.Percent,
        defaultValue: DEFAULT_VALUE_DASH,
        excludeSorting: true,
    },
    {
        name: 'dataset.perf_month',
        columnName: 'perf_month',
        title: 'Month',
        extraTitle: 'Month',
        valueAlignment: Alignment.Right,
        active: true,
        formatType: FormatTypeChoices.Percent,
        defaultValue: DEFAULT_VALUE_DASH,
        excludeSorting: true,
    },
    {
        name: 'dataset.perf_qtd',
        columnName: 'perf_qtd',
        title: 'QTD',
        extraTitle: 'QTD',
        valueAlignment: Alignment.Right,
        active: false,
        formatType: FormatTypeChoices.Percent,
        defaultValue: DEFAULT_VALUE_DASH,
        excludeSorting: true,
    },
    {
        name: 'dataset.perf_mtd',
        columnName: 'perf_mtd',
        title: 'MTD',
        extraTitle: 'MTD',
        valueAlignment: Alignment.Right,
        active: false,
        formatType: FormatTypeChoices.Percent,
        defaultValue: DEFAULT_VALUE_DASH,
        excludeSorting: true,
    },
    {
        name: 'dataset.perf_ytd',
        columnName: 'perf_ytd',
        title: 'YTD',
        extraTitle: 'YTD',
        valueAlignment: Alignment.Right,
        active: false,
        formatType: FormatTypeChoices.Percent,
        defaultValue: DEFAULT_VALUE_DASH,
        excludeSorting: true,
    },
    {
        name: 'datasources.sec',
        columnName: 'fund_sec',
        title: 'Fund SEC',
        extraTitle: 'SEC',
        active: false,
        excludeSorting: true,
        formatType: FormatTypeChoices.String,
        defaultValue: DEFAULT_VALUE_DASH,
    },
    {
        name: 'datasources.lei',
        columnName: 'fund_lei',
        title: 'Fund LEI',
        extraTitle: 'LEI',
        active: false,
        excludeSorting: true,
        formatType: FormatTypeChoices.String,
        defaultValue: DEFAULT_VALUE_DASH,
    },
    {
        name: 'internal_id',
        columnName: 'fund_internal_id',
        title: 'Fund Internal ID',
        extraTitle: 'Internal ID',
        active: false,
        excludeSorting: true,
        cellType: CellTypeChoices.Custom,
        transformer: row => <AttributesCell fields={row.fund_attributes} attributeType="internal_id" />
    },
    {
        name: 'currency',
        columnName: 'fund_currency',
        title: 'Currency',
        extraTitle: 'Currency',
        active: false,
        excludeSorting: true,
        formatType: FormatTypeChoices.String,
        defaultValue: DEFAULT_VALUE_DASH,
    },
    {
        name: 'firm.datasources.crd',
        columnName: 'firm_crd',
        title: 'Firm CRD',
        extraTitle: 'CRD',
        active: false,
        excludeSorting: true,
        formatType: FormatTypeChoices.String,
        defaultValue: DEFAULT_VALUE_DASH,
    },
    {
        name: 'firm.datasources.sec',
        columnName: 'firm_sec',
        title: 'Firm SEC',
        extraTitle: 'SEC',
        active: false,
        excludeSorting: true,
        formatType: FormatTypeChoices.String,
        defaultValue: DEFAULT_VALUE_DASH,
    },
    {
        name: 'firm.datasources.lei',
        columnName: 'firm_lei',
        title: 'Firm LEI',
        extraTitle: 'LEI',
        active: false,
        excludeSorting: true,
        formatType: FormatTypeChoices.String,
        defaultValue: DEFAULT_VALUE_DASH,
    },
    {
        name: 'firm.internal_id',
        columnName: 'firm_internal_id',
        title: 'Firm Internal ID',
        extraTitle: 'Internal ID',
        active: false,
        excludeSorting: true,
        formatType: FormatTypeChoices.String,
        defaultValue: DEFAULT_VALUE_DASH,
    },
    {
        name: 'data.date_received',
        columnName: 'date_received',
        title: 'Date Received',
        extraTitle: 'Date Received',
        active: false,
        formatType: FormatTypeChoices.DateFormat,
        defaultValue: DEFAULT_VALUE_DASH,
        excludeSorting: true,
    },
    {
        name: 'reporting_history',
        columnName: 'reporting_history',
        title: 'Reporting History',
        active: true,
        excludeSorting: true,
        cellType: CellTypeChoices.Custom,
        minWidth: 250,
        transformer: row => (
            <ReportingHistoryCell periods={row.data && row.data.reporting_history} />
        )
    },
    {
        name: 'status',
        columnName: 'data_status',
        title: 'Status',
        active: true,
        excludeSorting: true,
        cellType: CellTypeChoices.Custom,
        transformer: row => (
            <StatusCell
                status={row.data.status && row.data.status.status}
                period={row.data.status && row.data.status.period}
            />
        ),
    },
    {
        name: 'data.entry_type',
        columnName: 'entry_type',
        title: 'Data Entry Type',
        extraTitle: 'Data Entry Type',
        active: false,
        excludeSorting: true,
    },
    {
        name: 'dataset.credit_officer',
        columnName: 'credit_officer',
        title: 'Coverage',
        extraTitle: 'Coverage',
        active: true,
        cellType: CellTypeChoices.Custom,
        excludeSorting: true,
        transformer: row => <AttributesCell fields={row.fund_attributes} attributeType="credit_officer" />},
    {
        name: 'dataset.product',
        columnName: 'product',
        title: 'Product',
        extraTitle: 'Product',
        active: true,
        cellType: CellTypeChoices.Custom,
        excludeSorting: true,
        transformer: row => <AttributesCell fields={row.fund_attributes} attributeType="product" />},
    {
        name: 'dataset.strategy',
        columnName: 'strategy',
        title: 'Strategy',
        extraTitle: 'Strategy',
        active: true,
        cellType: CellTypeChoices.Custom,
        excludeSorting: true,
        transformer: row => <AttributesCell fields={row.fund_attributes} attributeType="strategy" />
    },
];

export const fundDatasetsHeaders: TableHeader[] = [
    {
        name: 'period',
        title: 'Period',
        class: 'primary w-min',
        formatType: FormatTypeChoices.DateFormatShort,
        active: true,
        excludeEditField: () => { return true; },
        minWidth: 70,
        valueAlignment: Alignment.Right,
    },
    {
        name: 'beginning_balance',
        columnName: 'beginning_balance',
        title: 'Beginning Balance',
        extraTitle: 'Beginning Balance',
        valueAlignment: Alignment.Right,
        formatType: FormatTypeChoices.NumeralDefault,
        class: clssNameNav,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: true,
    },
    {
        name: 'subscriptions',
        columnName: 'subscriptions',
        title: 'Subscriptions',
        extraTitle: 'Subscriptions',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: true,
    },
    {
        name: 'redemptions',
        columnName: 'redemptions',
        title: 'Redemptions',
        extraTitle: 'Redemptions',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: true,
    },
    {
        name: 'income',
        columnName: 'income',
        title: 'Income',
        extraTitle: 'Income',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: false,
    },
    {
        name: 'ending_balance',
        columnName: 'ending_balance',
        title: 'Ending Balance',
        extraTitle: 'Ending Balance',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: true,
    },
    {
        name: 'known_future_redemptions',
        columnName: 'known_future_redemptions',
        title: 'Known Future Redemptions',
        extraTitle: 'Known Future Redemptions',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: false,
    },
    {
        name: 'nav_per_share',
        columnName: 'nav_per_share',
        title: 'NAV per Share',
        extraTitle: 'NAV per Share',
        valueAlignment: Alignment.Right,
        class: clssNameNav,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        params: {
            type: TypesFormat.nav
        },
        active: false,
    },
    {
        name: 'perf_daily',
        columnName: 'perf_daily',
        title: 'Daily',
        extraTitle: 'Daily',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: false,
    },
    {
        name: 'perf_weekly',
        columnName: 'perf_weekly',
        title: 'Weekly',
        extraTitle: 'Weekly',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: false,
    },
    {
        name: 'perf_month',
        columnName: 'perf_month',
        title: 'Month',
        extraTitle: 'Month',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: true,
    },
    {
        name: 'perf_mtd',
        columnName: 'perf_mtd',
        title: 'MTD',
        extraTitle: 'MTD',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: false,
    },
    {
        name: 'perf_qtd',
        columnName: 'perf_qtd',
        title: 'QTD',
        extraTitle: 'QTD',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: false,
    },
    {
        name: 'perf_ytd',
        columnName: 'perf_ytd',
        title: 'YTD',
        extraTitle: 'YTD',
        valueAlignment: Alignment.Right,
        class: clssNamePerformance,
        formatType: FormatTypeChoices.NumeralDefault,
        cellType: CellTypeChoices.Numeric,
        editable: true,
        active: true,
    },
    {
        name: 'currency',
        title: 'Currency',
        placeholder: 'currency',
        valueAlignment: Alignment.Right,
        class: classVersion,
        cellType: CellTypeChoices.Select,
        options: currencyItems,
        editable: true,
        active: true,
    },
    {
        name: 'data_version',
        title: 'Version',
        valueAlignment: Alignment.Right,
        class: classVersion,
        cellType: CellTypeChoices.Select,
        options: dataVersion,
        editable: true,
        active: true,
    },
    {
        name: 'data_source',
        title: 'Data Source',
        valueAlignment: Alignment.Right,
        editable: false,
        active: true,
        class: 'data-source',
        cellType: CellTypeChoices.Custom,
        transformer: row => (<DataSourceCell row={row} />),
        excludeSorting: true,
    },
    {
        name: 'updated_at',
        title: 'Last Updated',
        editable: false,
        active: true,
        class: 'last-updated-at',
        valueAlignment: Alignment.Right,
        cellType: CellTypeChoices.Custom,
        transformer: (row: Dataset) => (<LastUpdatedCell row={row}/>),
        popover: (row: Dataset) => (<LastUpdatedPopover row={row}/>),
        isPopoverVisible: isMadeLatest,
    },
];
