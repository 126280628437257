import * as React from 'react';
import { Switch, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { sendResizeWindowEvent } from 'helpers/event';

import { LEFT_PANEL_MAX_WIDTH, LEFT_PANEL_MIN_WIDTH } from 'store/constants';

import { leftPanelButton } from 'store/constants';
import { AppState } from 'store/types';
import { getUser } from 'store/Auth/selectors';
import { isModalOpen } from 'store/Modals/General/selectors';
import { ADD_BULK_UPLOAD_MODAL } from 'store/Portfolio/constants';
import { BULK_UPLOAD_MODAL as REQUEST_BULK_UPLOAD_MODAL } from 'store/Requests/constants';
import ActiveUser from 'store/Auth/models/activeUser';
import * as userHelper from 'store/User/helpers';
import GlobalProgress from 'containers/GlobalProgress/GlobalProgress';
import ProtectedRoute from 'containers/Shared/ProtectedRoute';
import Header from 'containers/Shared/Header';

import AppPage from 'components/AppPage';

import { openSocket, disconnectSocket } from 'store/Socket/actionCreators';
import { getDiligenceUser } from 'store/Auth/actionCreators';
import { isInternal } from 'store/User/helpers';

import {
    DashboardRequestor, DashboardResponder,
    DiligenceSettings, DiligenceRequests,
    DataManager, AlertsManager,
    UserProfile, Account, PortfolioList, Fund, Firm, Requests, Profiles,
} from 'containers/AsyncComponents';
import { DATASET_UPLOAD_MODAL } from 'store/DataManager/constants';
import { getActionInfo } from 'store/Actions/selectors';
import AclPendingUsers from 'containers/DataManager/AclPendingUsers';
import { GetDiligenceUserAction } from 'store/Auth/actions';
import { getDiligenceUser as getAndSetDiligenceUser } from 'store/Diligence/Auth/actionCreators';

interface Props {
    isModalOpen: boolean;
    user: ActiveUser;
    openSocket: any;
    disconnectSocket: any;
    globalAction: boolean;
    getDiligenceUser: () => GetDiligenceUserAction;
    getAndSetDiligenceUser: () => GetDiligenceUserAction;
}

type FinalProps = Props & RouteComponentProps;

interface State {
    dragging: boolean;
    size?: number;
}

class WithPanelLayout extends React.Component<FinalProps, State> {
    size?: number;
    state = {
        size: undefined,
        dragging: false,
    };

    constructor(props: FinalProps, state: State) {
        super(props, state);

        this.props.history.listen(this.handleRouteChange);
    }

    componentDidMount() {
        this.props.openSocket();
        if (!isInternal(this.props.user)) {
            this.props.getAndSetDiligenceUser();
        }
    }

    componentWillUnmount() {
        this.props.disconnectSocket();
    }

    render() {
        const {user} = this.props;
        const panelButtons = leftPanelButton(user.active_account).filter(button => (
            (
                !button.roles ||
                button.roles.indexOf(user.active_account.role) >= 0
            ) && (
                !button.accountAccess ||
                button.accountAccess[user.active_account.account.type]
            ) && (
                !button.requiresDiligenceAccount ||
                user.has_diligence_account
            )
        ));

        const header = <Header />;
        const uuidReg = `[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}`;
        const content = (
            <Switch>
                <ProtectedRoute
                    path="/dashboard"
                    component={userHelper.isRequestor(user)
                        ? DashboardRequestor
                        : DashboardResponder
                    }
                />
                <ProtectedRoute path="/profile" component={UserProfile} />
                <ProtectedRoute path="/account" component={Account} />
                <ProtectedRoute path="/portfolio" component={PortfolioList} />
                <ProtectedRoute path="/firms" exact={true} component={Firm} />
                <ProtectedRoute path="/firms/:id" component={Firm} />
                <ProtectedRoute path="/funds" exact={true} component={Fund} />
                <ProtectedRoute path={`/funds/:id(${uuidReg})`} component={Fund}/>
                <ProtectedRoute path="/(alerts|rulesets|ruleset-versions)" component={AlertsManager} />
                <ProtectedRoute path="/requests" component={Requests} />
                <ProtectedRoute path="/data-manager"  component={DataManager} />
                <ProtectedRoute path="/pending-users"  component={AclPendingUsers} />
                <ProtectedRoute path="/settings" component={DiligenceSettings} />
                <ProtectedRoute path="/diligence/requests" component={DiligenceRequests} />
                <ProtectedRoute path="/profiles"  component={Profiles} />

                <Redirect from="*" to="/dashboard" />
            </Switch>
        );

        const globalProgress = <GlobalProgress />;

        return (
            <AppPage
                buttons={panelButtons}
                minSize={LEFT_PANEL_MIN_WIDTH}
                maxSize={LEFT_PANEL_MAX_WIDTH}
                size={this.state.dragging ? undefined : this.state.size}
                logoSize={this.size}
                handleDrag={this.handleDrag}
                handleDragStart={this.handleDragStart}
                handleDragEnd={this.handleDragEnd}
                isModalOpen={this.props.isModalOpen}
                setNewSize={this.setNewSize}
            >
                {{header, content, globalProgress}}
            </AppPage>
        );
    }

    private getDiligenceUser() {
        if (!isInternal(this.props.user)) {
            this.props.getDiligenceUser();
        }
    }

    private handleRouteChange = () => {
        this.getDiligenceUser();
    }

    private handleDragStart = () => {
        this.setState({
            dragging: true,
            size: undefined
        });

        sendResizeWindowEvent();
    }

    private handleDragEnd = () => {
        this.setState({
            dragging: false,
            size: this.size
        });

        sendResizeWindowEvent();
    }

    private handleDrag = (width: number) => {
        const middle = LEFT_PANEL_MIN_WIDTH + (LEFT_PANEL_MAX_WIDTH - LEFT_PANEL_MIN_WIDTH) / 2;
        if (width >= LEFT_PANEL_MIN_WIDTH && width <= middle) {
            this.size = LEFT_PANEL_MIN_WIDTH;
        } else if (width > middle && width <= LEFT_PANEL_MAX_WIDTH) {
            this.size = LEFT_PANEL_MAX_WIDTH;
        } else {
            this.size = undefined;
        }

        sendResizeWindowEvent();
    }

    private setNewSize = () => {
        let newSize = LEFT_PANEL_MAX_WIDTH;
        if (this.state.size === LEFT_PANEL_MAX_WIDTH) {
            newSize = LEFT_PANEL_MIN_WIDTH;
        }
        this.size = newSize;
        this.setState({
            size: newSize,
        });

        sendResizeWindowEvent();
    }
}

const mapStateToProps = (state: AppState) => ({
    isModalOpen: isModalOpen(state, {name: ADD_BULK_UPLOAD_MODAL}) ||
        isModalOpen(state, {name: REQUEST_BULK_UPLOAD_MODAL}) ||
        isModalOpen(state, {name: DATASET_UPLOAD_MODAL}),
    user: getUser(state),
    globalAction: getActionInfo(state, {name: 'globalAction'}),
});

const mapDispatchToProps = {
    openSocket,
    disconnectSocket,
    getDiligenceUser,
    getAndSetDiligenceUser
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(WithPanelLayout);
