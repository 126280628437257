import { UserAccountRole } from '../Auth/types';

export const CONFIRM_DELETE_USERS_MODAL = 'CONFIRM_DELETE_USERS_MODAL';

export const messages = {
    DELETE_ACCOUNT_USER_SUCCESS_MESSAGE: 'User was successfully deleted',
    DELETE_ACCOUNT_USER_FAIL_MESSAGE: 'User deleting failed',
    UPDATE_ACCOUNT_USER_SUCCESS_MESSAGE: 'User was successfully updated',
    UPDATE_ACCOUNT_USER_FAIL_MESSAGE: 'User updating failed',
    INVITE_ACCOUNT_USER_SUCCESS_MESSAGE: 'User was successfully invited',
    INVITE_ACCOUNT_USER_FAIL_MESSAGE: 'User inviting failed',
    USER_IS_NOT_DEFINED: 'User is not defined',

    CONFIRM_DELETE_USER_TITLE: 'Confirm User Removal',
    CONFIRM_DELETE_USER_QUESTION: 'Are you sure you want remove the user from account?',
    EDIT_USER_TITLE: 'Edit User',
    INVITE_USER_TITLE: 'Invite New User',
};

export const initialState = {
    usersCount: 0,
    users: [],
    filter: {
        page: 0
    }
};

export const initialInvitedUser = {
    email: '',
    role: UserAccountRole.User,
    last_name: '',
    first_name: ''
};