import * as React from 'react';

import { FilterNames } from 'store/Filters/types';
import { DatasetFund } from 'store/DataManager/models/dataset';
import { DEFAULT_VISIBLE_HEADERS } from 'store/VisibleHeaders/constants';
import { headerNames } from 'store/VisibleHeaders/types';
import { TableHeader } from 'store/types';

import NavConfigurationModal from 'containers/DataManager/DatasetDetailModals/NavConfigurationModal';
import PerformanceConfigurationModal from 'containers/DataManager/DatasetDetailModals/PerformanceConfigurationModal';

import TabPanel, { TabItem } from 'components/Shared/Tabs/TabPanel';
import ButtonPrimary from 'components/Shared/Ui/Buttons/ButtonPrimary';
import Modal from 'components/Shared/Modals/Modal';
import FilterPanel from 'components/Shared/Filters/FilterPanel';
import ButtonDefault from 'components/Shared/Ui/Buttons/ButtonDefault';

interface Props {
    temporaryFilter: any;
    setDatasetDetailTemporaryFilter: any;
    saveSettingsDatasetFund: any;
    fund: DatasetFund;
    clearIsEdingDataset: any;
    handleFilterChange: (data: any) => void;
    handleColumns: (columns: any) => void;
    temporaryHeaders: any;
    setFilters: any;
    isOpen: boolean;
    closeModal: () => any;
    currentFilter: any;
    filters: any;
    modalParams: any;
    isEditor?: boolean;
}

const styles = require('../DataManager.scss');

export const handleChangeTab = {
    datasetConfiguration:  'datasetConfiguration',
    navConfiguration: 'navConfiguration',
    performanceConfiguration: 'performanceConfiguration'
};

export default class DatasetConfigurationModal extends React.Component<Props> {

    componentDidMount () {
        this.props.setDatasetDetailTemporaryFilter(this.props.currentFilter);
    }

    handleUpdate = (type: any) => {
        const visibleFields: string[] = [];
        this.props.temporaryHeaders.map( (item: TableHeader) => {
            if (item.active && item.columnName
                && DEFAULT_VISIBLE_HEADERS[headerNames.datasetDetail].indexOf(item.columnName) === -1
            ) {
                visibleFields.push(item.columnName);
            }
        });

        const payload = {
             ap_id: this.props.fund.ap_id,
             currency: this.props.temporaryFilter.currency.toUpperCase(),
             start_date: this.props.temporaryFilter.start_date,
             end_date: this.props.temporaryFilter.end_date,
             visible_fields: visibleFields
        };
        this.props.saveSettingsDatasetFund(this.props.fund.id, payload);
        this.props.clearIsEdingDataset();

        this.props.closeModal();
    }

    handleCloseModal = () => {
        this.props.setFilters(FilterNames.datasetData, this.props.currentFilter );
        this.props.setDatasetDetailTemporaryFilter(this.props.currentFilter);
        this.props.closeModal();
    }

    render () {
        const {isOpen, isEditor} = this.props;

        let tabItems: TabItem[] = [];

        if (isEditor) {
            tabItems.push(
                {title: 'Datasets', content: (
                    <FilterPanel
                        filters={this.props.filters}
                        currentFilter={this.props.temporaryFilter}
                        handleChange={this.props.setDatasetDetailTemporaryFilter}
                    />
                )}
            );
        }

        tabItems = [
            ...tabItems,
            {title: 'Nav', content: (
            <NavConfigurationModal
                    isEditor={isEditor}
                    handleUpdate={this.handleUpdate}
                    handleColumns={this.props.handleColumns}
                    handleFilterChange={this.props.handleFilterChange}
                    setFilters={this.props.setFilters}
            />
            )
            },
            {title: 'Performance', content: (
                <PerformanceConfigurationModal
                    handleColumns={this.props.handleColumns}
                />
            )
            },
        ];

        const buttons = [
            (
                <ButtonPrimary
                   buttonLarge={true}
                   handleClick={() => this.handleUpdate({type: handleChangeTab.datasetConfiguration})}
                >
                    Update
                </ButtonPrimary>),
            (
                <ButtonDefault buttonLarge={true} inverse={true} handleClick={this.handleCloseModal}>
                    Cancel
                </ButtonDefault>
            )
        ];

        const body = (
            <div className={styles.dataset_configuration_modal}>
                <TabPanel items={tabItems}/>
            </div>
        );

        return (
            <Modal
                isOpen={isOpen}
                className={styles.dataset_configuration_modal}
                toggle={this.handleCloseModal}
                title="Configure Fund Reporting"
                body={body}
                buttons={buttons}
            />
        );
    }
}