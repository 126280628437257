import * as React from 'react';
import ActiveUser from 'store/Auth/models/activeUser';
import DiligenceActiveUser from 'store/Diligence/Auth/models/activeUser';
import { formatText } from 'helpers/tableHelper';
import { FormatTypeChoices } from 'store/constants';

const styles = require('./UserCircle.scss');

interface Props {
    user: ActiveUser | DiligenceActiveUser;
}

const UserCircle: React.FC<Props> = ({user}) => {
    const { first_name, last_name, email } = user;
    const firstName = first_name ? formatText(first_name[0], FormatTypeChoices.FirstCharUpper) : '';
    const lastName = last_name ? formatText(last_name[0], FormatTypeChoices.FirstCharUpper) : '';
    const emailValue = !firstName && !lastName && email
        ? formatText(email.substring(0, 2), FormatTypeChoices.ToUppercase)
        : '';

    return (
        <div className={styles.userCircle}>
            <div className={styles.userShortName}>
                {firstName}{lastName}{emailValue}
            </div>
        </div>
    );
};

export default UserCircle;
