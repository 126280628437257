import * as React from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import CenteredWindowLayout from '../Shared/Layouts/CenteredWindowLayout';
import { ActionInfo, ActionStatus } from '../../store/Actions/types';
import { INITIALIZE_RESET_PASSWORD_SUCCESS_MESSAGE } from '../../store/Auth/constants';

const styles = require('./InitializeResetPasswordPage.scss');

interface Props {
    actionInfo?: ActionInfo;
    initializeResetPassword: (email: string) => any;
}

const InitializeResetPasswordPage: React.FC<Props> = (props) => {
    let emailElement: HTMLInputElement | null;
    const {actionInfo} = props;
    return (
        <CenteredWindowLayout>
            <div className={styles.resetFormWrapper}>
                {(!actionInfo || [ActionStatus.InProgress, ActionStatus.Success].indexOf(actionInfo.status) === -1) &&
                    <Form>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                innerRef={el => emailElement = el}
                            />
                        </FormGroup>
                        <Button
                            color=""
                            onClick={() => emailElement && props.initializeResetPassword(emailElement.value)}
                        >
                            Reset password
                        </Button>
                    </Form>
                }
                {actionInfo && actionInfo.status === ActionStatus.Success &&
                    <div>{INITIALIZE_RESET_PASSWORD_SUCCESS_MESSAGE}</div>
                }
                {actionInfo && actionInfo.status === ActionStatus.Fail &&
                    <div>
                        {actionInfo.errors.map((error, idx) => (
                            <p className="text-danger" key={idx}>{error}</p>
                        ))}
                    </div>
                }
            </div>
        </CenteredWindowLayout>
    );
};

export default InitializeResetPasswordPage;
