import * as React from 'react';
import ToggleSwitch from '../Ui/ToggleSwitch';

interface Props {
    name: string;
    placeholder?: string;
    withLabel?: boolean;
    value: any;
    handleChange: (data: any) => void;
}

const styles = require('./SelectFilter.scss');
const ToggleFilter: React.FC<Props> = ({ name, value, placeholder, withLabel, handleChange }) => {
    return (
        <ToggleSwitch
            value={value}
            title={placeholder || ''}
            withLabel={withLabel}
            handleChange={(checked: boolean) => handleChange({[name]: checked ? checked : null})}
        />
    );
};

ToggleFilter.defaultProps = {
    withLabel: false
};

export default ToggleFilter;
