import { AuthState } from './types';
import * as authHelper from 'helpers/authHelper';

import {
    GET_USER,
    SET_USER,
    AuthAction,
    SetUserAction,
    LOGOUT,
    LOGIN,
    CHANGE_USER_ACCOUNT_SETTINGS,
    SET_ACCOUNT_SETTINGS,
    SetAccountSettingsAction, ChangeUserAccountSettingsAction
} from './actions';
import { initialState } from './constants';

export const authReducer = (state: AuthState = initialState, action: AuthAction) => {
    switch (action.type) {
        case LOGIN:
            authHelper.redirectToLogin();
            return state;
        case LOGOUT:
            localStorage.setItem('hiddenImports', '');
            authHelper.redirectToLogout();
            return initialState;
        case GET_USER:
            return state;
        case SET_USER:
            return {
                ...state,
                user: (<SetUserAction> action).user,
                isInitialized: true
            };
        case CHANGE_USER_ACCOUNT_SETTINGS:
            if (state.user === undefined) {
                return state;
            }

            return {
                ...state,
                user: {
                    ...state.user,
                    active_account: {
                        ...state.user.active_account,
                        user_account_settings: {
                            ...state.user.active_account.user_account_settings,
                            ...(<ChangeUserAccountSettingsAction> action).userAccountSettings
                        }
                    }
                }
            };
        case SET_ACCOUNT_SETTINGS: {
            if (!state.user) {
                return state;
            }

            return {
                ...state,
                user: {
                    ...state.user,
                    active_account: {
                        ...state.user.active_account,
                        account: {
                            ...state.user.active_account.account,
                            account_settings: {
                                ...state.user.active_account.account.account_settings,
                                ...(<SetAccountSettingsAction> action).settings
                            }
                        }
                    }
                }
            };
        }
        default:
            return state;
    }
};
