import { Action } from 'redux';

export const SET_REDIRECT = 'store/redirect/set';
export const CLEAR_REDIRECT = 'store/redirect/clear';

export interface SetRedirectAction extends Action {
    name: string;
    params: any;
}

export interface ClearRedirectAction extends Action {}

export type RedirectAction = SetRedirectAction;