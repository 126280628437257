import * as React from 'react';
import * as classNames from 'classnames';
import { formatText } from 'helpers/tableHelper';

import { TableHeader } from 'store/types';
import { FormatTypeChoices, stateAdd } from 'store/constants';

interface Props {
    row: any;
    visibleHeaders: TableHeader[];
    getCustomStyles?: (row: any) => string[];
}

const styles = require('./FundDatasetNewRow.scss');

const FundDatasetNewRow: React.FC<Props> = ({row, visibleHeaders, getCustomStyles}) => {
    let visibleHeadersCount: number = 0;
    visibleHeaders.forEach((item: TableHeader) => {
        if (item.active) {
            visibleHeadersCount++;
        }
    });

    const onClickHandler = (event: any) => row.handleClick && row.handleClick(row.period);
    const customStyles: string[] = typeof getCustomStyles === 'function' ? getCustomStyles(row) : [];

    let tdClassName = {
        triangledBlueTd: true,
        ['text-center']: true,
    };
    customStyles.forEach((style: string) => tdClassName[style] = true);

    return (
        <tbody className="j-body_items">
            <tr className={styles['new-row']}>
                <td className="primary w-min text-right">
                    {formatText(row.period, FormatTypeChoices.DateFormatShort)}
                </td>
                <td className={classNames(tdClassName)} colSpan={visibleHeadersCount}>
                    <button className="ui-button--clear" onClick={onClickHandler}>
                        <img src={stateAdd} alt=""/>
                        <span className="ui-inlineBlock ui-pl-5">Click here to provide data for &nbsp;</span>
                        {formatText(row.period, FormatTypeChoices.DateFormat)}
                    </button>
                </td>
            </tr>
        </tbody>
    );
};

export default FundDatasetNewRow;
