import { delay } from 'redux-saga';
import { put, takeLatest, all } from 'redux-saga/effects';

import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import * as requestApi from 'services/diligence/request';

import {
    DiligenceFilterAttributeDictionary,
    DiligenceDateFilters,
    DiligenceOrderingTypeDictionary,
} from 'store/Diligence/constants';
import {
    DEFAULT_DATE_RANGE_ENDINGS,
    DEFAULT_DILIGENCE_REQUEST_FILTER
} from 'store/constants';

import {
    setRequests, setRequestAttributes, applyFilter, saveFilterData, setAllRequests
} from './actionCreators';
import {
    ApplyFilterAction, GetRequestsAction,
    APPLY_FILTER, GET_REQUESTS, SEARCH_REQUEST_ATTRIBUTE,
    SearchRequestAttributeAction, GetSavedDataAction, GET_SAVED_DATA, SAVE_FILTER_DATA,
    SaveFilterDataAction
} from './actions';

import { setIsLoading } from 'store/Filters/actionCreators';
import { getRequests } from 'services/diligence/request';

import { DiligenceRequestStatus } from './types';
import { setFilters } from 'store/Diligence/Filters/actionCreators';
import { prepareOrderingField } from 'helpers/sortHelper';

function* applyFilterDiligenceRequest(action: ApplyFilterAction) {
    try {
        yield put(initAction(action.type));

        const withScroll = action.filter.scroller;
        if (withScroll) {
            yield put(setIsLoading(true));
        }

        let allFilters = action.filter.survey
            .concat(action.filter.requester)
            .concat(action.filter.respondingAccount)
            .concat(action.filter.respondingVehicle)
            .concat(action.filter.campaign)
            .concat(action.filter.statusDisplay.map((entity: any) => {
                return {
                    ...entity,
                    id: DiligenceRequestStatus[entity.name.replace(' ', '')]
                };
            }));
        if (action.filter.deadline.start !== '' || action.filter.deadline.end !== '') {
            allFilters.push(action.filter.deadline);
        }
        if (action.filter.dateSent.start !== '' || action.filter.dateSent.end !== '') {
            allFilters.push(action.filter.dateSent);
        }
        if (action.filter.dateClosed.start !== '' || action.filter.dateClosed.end !== '') {
            allFilters.push(action.filter.dateClosed);
        }
        const request = {
            accountId: 'current',
            from: action.filter.offset,
            limit: action.filter.limit,
        };

        const response = yield getRequests(request, allFilters, action.filter.sort);
        yield put(setRequests(response.total, response.requests, withScroll));
        yield put(saveFilterData(allFilters, action.filter.sort));
        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* getDiligenceRequests(action: GetRequestsAction) {
    try {
        yield put(initAction(action.type));

        const response = yield getRequests();
        yield put(setAllRequests(response.requests));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* searchRequestAttribute(action: SearchRequestAttributeAction) {
    try {
        yield delay(500);
        yield put(initAction(action.type));

        const search = action.filter.attributeSearch || '';

        if (search) {
            let attributes = yield requestApi.suggestRequests({
                accountId: 'current',
                search: search,
                suggestionsResourceType: action.attributeType
            });

            attributes.count = attributes.results.length;
            const withScroll = !!action.filter.scroller;
            yield put(setRequestAttributes(action.attributeType, attributes.count, attributes.results, withScroll));
        } else {
            yield put(setRequestAttributes(action.attributeType, 0, [], false));
        }
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getSavedData(action: GetSavedDataAction) {
    try {
        yield put(initAction(action.type));
        const requestData = yield requestApi.getSavedData(
            {
                _longload: true,
                plaintext: 1
            },
            'requests'
        );
        const responseData = yield requestApi.getSavedData(
            {
                _longload: true,
                plaintext: 1
            },
            'responses'
        );
        let data = {
            filters: requestData.filters.concat(responseData.filters),
            sortOrder: requestData.sortOrder.concat(responseData.sortOrder)
        };
        let filters = {};
        for (let i = 0; i < data.filters.length; i++) {
            if (DiligenceDateFilters.includes(DiligenceFilterAttributeDictionary[data.filters[i].type])) {
                filters[DiligenceFilterAttributeDictionary[data.filters[i].type]] = {
                    ...DEFAULT_DATE_RANGE_ENDINGS,
                    ...data.filters[i]
                };
            } else if (filters[DiligenceFilterAttributeDictionary[data.filters[i].type]]) {
                filters[DiligenceFilterAttributeDictionary[data.filters[i].type]].push(data.filters[i]);
            } else {
                filters[DiligenceFilterAttributeDictionary[data.filters[i].type]] = [data.filters[i]];
            }
        }
        let filter = {
            ...DEFAULT_DILIGENCE_REQUEST_FILTER,
            ...filters,
            sort: data.sortOrder
        };

        if (data.sortOrder) {
            let sortElem = data.sortOrder[0];
            let preparedOrderingField = '';

            if (sortElem.type === 'dateReceived') {
                preparedOrderingField = 'receivedOrPublished';
            } else {
                for (let key in  DiligenceOrderingTypeDictionary) {
                    if (DiligenceOrderingTypeDictionary[key] === sortElem.type) {
                        preparedOrderingField = key;
                        break;
                    }
                }
            }

            filter = {
                ...filter, ordering: prepareOrderingField(preparedOrderingField, sortElem.order)
            };
        }

        yield put(setFilters(action.filterName, filter));
        yield put(applyFilter(filter));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* saveData(action: SaveFilterDataAction) {
    try {
        yield delay(1000);
        yield put(initAction(action.type));
        yield requestApi.saveData(action.filters, action.sortOrder);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchApplyFilter() {
    yield takeLatest(APPLY_FILTER, applyFilterDiligenceRequest);
}

function* watchGetDiligenceRequests() {
    yield takeLatest(GET_REQUESTS, getDiligenceRequests);
}

function* watchGetRequestAttributes() {
    yield takeLatest(SEARCH_REQUEST_ATTRIBUTE, searchRequestAttribute);
}

function* watchGetSavedFilters() {
    yield takeLatest(GET_SAVED_DATA, getSavedData);
}

function* watchSaveFilterData() {
    yield takeLatest(SAVE_FILTER_DATA, saveData);
}

export default function* root() {
    yield all(
        [
            watchApplyFilter(),
            watchGetDiligenceRequests(),
            watchGetRequestAttributes(),
            watchGetSavedFilters(),
            watchSaveFilterData()
        ]
    );
}
