import { connect } from 'react-redux';

import { AppState } from '../../store/types';
import { initializeResetPassword } from '../../store/Auth/actionCreators';
import InitializeResetPasswordPage from '../../components/Auth/InitializeResetPasswordPage';
import { getActionInfo } from '../../store/Actions/selectors';
import { INITIALIZE_RESET_PASSWORD } from '../../store/Auth/actions';

const mapStateToProps = (state: AppState) => ({
    actionInfo: getActionInfo(state, {name: INITIALIZE_RESET_PASSWORD})
});

const mapDispatchToProps = { initializeResetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(InitializeResetPasswordPage);