import { PortfolioProgress } from './models/portfolio_progress';
import { Id } from '../types';
import { ReactNode } from 'react';

export interface BulkUploadState {
    in_progress: BulkUploadProgress[];
    history: {
        [key: string]: BulkUploadProgress[]
    };
    latestUploadStatus?: {
        [key: number]: UploadStatus
    };
}

export interface BulkUploadInfo {
    id?: Id;
    name: string;
    progress: BulkUploadProgress;
    history: GetListResponse;
    visibleGlobal: boolean;
}

export interface BulkUploadProgress {
    id: Id;
    file: Id;
    action: UploadAction;
    count_processed: number;
    count_total: number;
    created_by: Id;
    status: UploadStatus;
    type: UploadType;
    portfolio?: Id | null;
    account?: Id | null;
    visible: boolean;
}

export interface GetListResponse {
    count: number;
    results: PortfolioProgress[];
    next: string;
    previous: string;
}

export interface SelectorProps {
    type?: UploadType;
    portfolioId?: Id;
}

export enum UploadStatus {
    New = 1,
    Processing = 2,
    Success = 3,
    Failed = 4,
    Cancelled = 5,
    ProcessedWithErrors = 6
}

export enum UploadType {
    Undefined = 0,
    Portfolio = 1,
    Request = 2,
    DataManager = 3,
    // 4 value - is not used on backend right now
    DataManagerRequestor = 5,
    PublishedDatasetHistory = 6,
    Fund = 7,
    Ruleset = 9,
}

export enum UploadAction {
    IMPORT = 1,
    EXPORT = 2,
    ERROR = 3,
}

export enum SocketActions {
    InProgressFull = 'in_progress_full',
    InProgressUpdate = 'in_progress_update',
    History = 'history',
    Cancel = 'cancel',
    FileDownload = 'file_download'
}

export interface BulkUploadModalStatuses {
    processingMessage?: ReactNode;
    successMessage?: ReactNode;
    failedMessage?: ReactNode;
    processedWithErrorsMessage?: ReactNode;
}

export interface StorageDownloadFileSession {
    sessionId: number;
    expireAt: number;
}