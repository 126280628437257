import * as React from 'react';
import { closeWhiteIcon } from 'store/constants';

const styles = require('./BottomProgressBar.scss');

interface Props {
    hide: () => void;
    itemName: string;
    action: string;
}

class FailMessage extends React.PureComponent<Props> {
    render() {
        return (
            <div className="progressBlock">
                <div className={styles['progress-area']}>
                    <div className={styles['completed-process-text']}>
                        <span className={styles['fail-message']}>
                            {`${this.props.itemName} ${this.props.action} Failed!`}
                        </span>
                    </div>
                    <img
                        src={closeWhiteIcon}
                        className={styles['img-close']}
                        onClick={this.props.hide}
                    />
                </div>
            </div>
        );
    }
}

export default FailMessage;
