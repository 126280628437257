import { AppState } from '../types';
import { createSelector } from 'reselect';
import { defaultDataManagerListPermissions, initialState } from './constants';
import { DataManagerState } from './types';

export const getDataManagerState = (state: AppState) => state.dataManager || initialState;

export const getConfirmPublishModal = createSelector(
    getDataManagerState,
    state => state.confirmPublishModal
);

export const getSetPendingPublishModal = createSelector(
    getDataManagerState,
    state => state.setPendingPublishModal
);

export const getEditPendingPublishModal = createSelector(
    getDataManagerState,
    state => state.editPendingPublishModal
);

export const getDatasetList = createSelector(
    getDataManagerState,
    state => state.datasetList
);

export const getPublishedDatasets = createSelector(
    getDataManagerState,
    state => state.publishedDatasets
);

export const getPublishedDatasetsCount = createSelector(
    getDataManagerState,
    state => state.publishedDatasetsCount
);

export const getPublishedDatasetsPermissions = createSelector(
    getDataManagerState,
    (state: DataManagerState) => state.permissions || defaultDataManagerListPermissions
);

export const getRequestors = createSelector(
    getDataManagerState,
    state => state.requestors
);

export const getDatasetDetail = createSelector(
    getDataManagerState,
    state => state.datasetDetail
);

export const getDatasetFundSelector = createSelector(
    getDataManagerState,
    state => state.datasetFund
);

export const getDatasetDetailCount = createSelector(
    getDataManagerState,
    state => state.datasetDetailCount
);

export const getDatasetDetailEditing = createSelector(
    getDataManagerState,
    state => state.editingRowNumber
);

export const getDatasetDetailTemporaryFilter = createSelector(
    getDataManagerState,
    state => state.temporaryFilter
);

export const getDatasetDetailTemporaryHeaders = createSelector(
    getDataManagerState,
    state => state.temporaryHeaders
);

export const getPublishedDatasetsTemporaryHeaders = createSelector(
    getDataManagerState,
    state => state.publishedDatasetsTemporaryHeaders
);

export const getDatasetListCount = createSelector(
    getDataManagerState,
    state => state.datasetListCount
);

export const getInternalData = createSelector(
    getDataManagerState,
    state => state.internalData
);

export const getInternalDataCount = createSelector(
    getDataManagerState,
    state => state.internalDataCount
);

export const getCurrentDataset = createSelector(
    getDataManagerState,
    state => state.currentDataset
);

export const getAclUsersCount = createSelector(
    getDataManagerState,
    state => state.aclUsersCount
);

export const getAclUsers = createSelector(
    getDataManagerState,
    state => state.aclUsers
);

export const getInvitedAclUser = createSelector(
    getDataManagerState,
    (state: DataManagerState) => state.invitedAclUser
);

export const getPendingRequestsInfo = createSelector(
    getDataManagerState,
    state => state.pendingRequests
);

export const getPendingRequestsCount = createSelector(
    getDatasetFundSelector,
    getPendingRequestsInfo,
    (getDatasetFund, requestsInfo) => requestsInfo[getDatasetFund.id]
        ? requestsInfo[getDatasetFund.id].count
        : 0
);

export const getPendingNotificationsFlag = createSelector(
    getDatasetFundSelector,
    getPendingRequestsInfo,
    (getDatasetFund, requestsInfo) => requestsInfo[getDatasetFund.id]
        ? requestsInfo[getDatasetFund.id].notificationFlag
        : false
);

export const getIsPublishAccessible = createSelector(
    getDatasetFundSelector,
    fund => fund.has_datasets_for_publish
);

export const getIsScheduleAccessible = createSelector(
    getDatasetFundSelector,
    fund => fund.has_datasets_for_schedule
);