import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { NavigationButton } from 'store/types';

const styles = require('./Menu.scss');

interface Props {
    items: NavigationButton[];
    subItems?: React.ReactNode[];
}

const Menu: React.FC<Props> = ({items, subItems = []}) => (
    <div className={styles.menu}>
        {
            items.map((item, idx) => {
                if (!!item.disabled) {
                    return (
                        <div key={idx} className={`${styles['item-disabled']} d-flex align-items-center`}>
                            <div className={styles.title}>
                                {item.title}
                            </div>
                        </div>
                    );
                }

                const isActive = (match, location) => {
                    if (match) {
                        return true;
                    }

                    if (!item.children) {
                        return false;
                    }

                    return !!item.children.find(child => location.pathname.indexOf(child.path) !== -1);
                };

                return (
                    <NavLink
                        key={idx}
                        to={item.path}
                        exact={item.hasOwnProperty('exact') ? item.exact : true}
                        activeClassName={styles.selected}
                        className={`${styles.item} d-flex align-items-center`}
                        isActive={isActive}
                    >
                        <div className={styles.title}>
                            {item.title}
                        </div>
                    </NavLink>
                );
            })
        }
        <div className={styles.settings}>
            {subItems}
        </div>
    </div>
);

export default Menu;
