import { Placement } from 'popper.js';

import { ChipValueMappingType } from 'store/constants';
import { SelectItem } from 'store/types';

export const DiligenceFilterNames = {
    diligenceRequest: 'diligence_request',
    surveyName: 'survey_name',
    filters: 'diligence_filters',
    requester: 'initiatingAccountName',
    responder: 'targetAccountName',
};

export interface Chip {
    name: string;
    value: string;
    nameTitle?: string;
    valueTitle?: string;
}

export interface ChipSetting {
    name: string;
    nameTitle?: string;
    mapType?: ChipValueMappingType;
    // is set in case filter value is cleared using chip, set filterPageName to apply logic with emptyValue
    emptyValue?: any;
}

export interface FilterChipSettings {
    [key: string]: ChipSetting[];
}

export interface SortOrder {
    type: string;
    order: string;
}

export interface PopoverFilter {
    placement: Placement;
    headerIndex: number;
    sections: PopoverSection[];
}

export interface PopoverSection {
    title: string;
    filterType: string;
    attribute: string;
    filterChoices?: SelectItem[];
    filterChoicesCount?: number;
    sortType?: string;
    attributeType: string;
    value?: string;
}
