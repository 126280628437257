import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'store/types';
import { BulkUploadProgress, UploadAction, UploadStatus } from 'store/BulkUpload/types';
import { hideGlobalProgress } from 'store/BulkUpload/actionCreator';
import { getBulkUploadProgressVisible } from 'store/BulkUpload/selectors';

import GlobalProgressBar from 'components/Shared/ProgressBar/GlobalProgressBar';

import SuccessItem from './SuccessItem';
import FailItem from './FailItem';
import ProcessingItem from './ProcessingItem';
import ProcessedWithErrorsItem from './ProcessedWithErrorsItem';
import ConfirmCancelModal from './ConfirmCancelModal';

interface Props {
    uploadProgress: BulkUploadProgress[];
    hideGlobalProgress: (name: string) => any;
}

class GlobalProgress extends React.PureComponent<Props> {
    render() {

        return (
            <GlobalProgressBar>
                {this.props.uploadProgress.map((progress: BulkUploadProgress, idx: number) => {
                    if (
                        progress.status === UploadStatus.Cancelled ||
                        progress.status === UploadStatus.New ||
                        (
                            progress.status === UploadStatus.Success &&
                            progress.action === UploadAction.EXPORT
                        )
                    ) {
                        return false;
                    }
                    return (
                        <div key={idx}>
                            {
                                progress.status === UploadStatus.Processing &&
                                <ProcessingItem progress={progress} />
                            }
                            {
                                progress.status === UploadStatus.Failed &&
                                <FailItem progress={progress} />
                            }
                            {
                                progress.status === UploadStatus.Success &&
                                progress.action !== UploadAction.EXPORT &&
                                <SuccessItem progress={progress} />
                            }
                            {
                                progress.status === UploadStatus.ProcessedWithErrors &&
                                <ProcessedWithErrorsItem progress={progress} />
                            }
                        </div>
                    );
                })}
                <ConfirmCancelModal />
            </GlobalProgressBar>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    uploadProgress: getBulkUploadProgressVisible(state, {}),
});

const mapDispatchToProps = { hideGlobalProgress };

export default connect(mapStateToProps, mapDispatchToProps)(GlobalProgress);