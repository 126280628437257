import * as React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import * as classNames from 'classnames';

import Input from 'components/Shared/Ui/Input';
import SearchFilterResults from 'components/Diligence/Shared/Filters/SearchFilterResults';
import { FiltersState } from 'store/Filters/types';
import { FilterEntity } from 'store/Diligence/DiligenceRequest/types';
import Popover from 'components/Shared/Ui/Popover';
import DeviceHelper from 'helpers/deviceHelper';

interface Props {
    name?: string;
    placeholder?: string;
    value: string;
    withLabel?: boolean;
    handleChange: (data: FiltersState) => void;
    handleToggle: () => void;
    className?: string;

    filterEntities?: FilterEntity[];
    currentFilter: FiltersState;
    filterPageName?: string;
}

interface State {
    isOpen: boolean;
}

const styles = require('./SearchFilter.scss');
class SearchFilter extends React.PureComponent<Props, State> {

    static defaultProps = {
        withLabel: false
    };

    constructor(props: Props) {
        super(props);
        this.state = {isOpen: false};
    }

    render () {
        const {
            className, currentFilter, placeholder, name,
            handleChange, handleToggle, filterEntities, filterPageName
        } = this.props;
        let content: string | JSX.Element = '';
        const inputValue = this.props.value !== undefined && this.props.value !== null ? this.props.value : '';
        const dropdownClasses = classNames({
            [styles.dropdown]: true,
            [styles.dropdown_active]: filterEntities && filterEntities.length > 0 && this.state.isOpen
        });
        if (placeholder && name && handleChange) {
            content = (
                <Dropdown
                    className={`${styles['filter-search']}`}
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                >
                    <div className={className || 'col-md-12'}>
                        {this.props.withLabel && <h5 className="label">{placeholder}</h5>}
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className={'icon-search'} />
                                </div>
                                <DropdownToggle
                                    className={`${styles['input-wrapper']}`}
                                    tag="span"
                                    data-toggle={styles.filter_search}
                                    aria-expanded={this.state.isOpen}
                                >
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={inputValue}
                                        placeholder={placeholder}
                                        handleChange={this.handleChangeSearch}
                                    />
                                </DropdownToggle>
                                <div className="input-group-append">
                                    <Popover
                                        id={`popover-advanced-menu-button`}
                                        body={`Click here to show Advanced Filters`}
                                        enabled={!DeviceHelper.isTouchSupported()}
                                    >
                                        <button
                                            id="popover-advanced-menu-button"
                                            onClick={handleToggle}
                                            className={styles.toggle_button}
                                        >
                                            <span className={'icon-settings'} />
                                        </button>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DropdownMenu className={dropdownClasses}>
                        <SearchFilterResults
                            handleChange={this.handleSelectItem}
                            filterEntities={filterEntities}
                            filterPageName={filterPageName}
                            currentFilter={currentFilter}
                        />
                    </DropdownMenu>
                </Dropdown>
            );
        }
        return content;
    }

    private toggle = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen
        }));
    }

    private handleSelectItem = (data: FiltersState) => {
        this.setState({
            isOpen: false
        });
        this.props.handleChange(data);
    }

    private handleChangeSearch = (value: string) => {
        const {name} = this.props;
        if (name) {
            this.props.handleChange({[name]: value});
            this.setState({
                isOpen: !!value
            });
        }
    }
}

export default SearchFilter;
