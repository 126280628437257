import { AppState } from '../types';
import { createSelector } from 'reselect';
import { BulkUploadProgress, BulkUploadState, SelectorProps, UploadAction, UploadStatus, UploadType } from './types';
import { emptyHistory, initialState } from './constants';

export const getBulkUploadState = (state: AppState) => state.bulkUpload || initialState;

export const getBulkUploadType = (state: AppState, props: SelectorProps) => props.type || UploadType.Undefined;

export const getBulkUploadHistory = createSelector(
    getBulkUploadState,
    getBulkUploadType,
    (state: BulkUploadState, type: UploadType) => {
        let history: BulkUploadProgress[] = emptyHistory;
        if (type !== UploadType.Undefined && state.history.hasOwnProperty(`_${type}`)) {
            history = state.history[`_${type}`].filter((historyItem: BulkUploadProgress) => {
                return historyItem.action === UploadAction.IMPORT;
            });
        }

        return history;
    }
);

export const getBulkUploadProgress = createSelector(
    getBulkUploadState,
    getBulkUploadType,
    (state: BulkUploadState, type: UploadType) => {
        if (type === UploadType.Undefined) {
            return state.in_progress;
        }
        return state.in_progress.filter(progress => progress.type === type);
    }
);

export const getBulkUploadProgressVisible = createSelector(
    getBulkUploadProgress,
    progresses => {
        const currentHiddenImports = localStorage.getItem('hiddenImports');
        const hiddenImports = currentHiddenImports ? JSON.parse(currentHiddenImports) : [];
        return progresses.filter(progress => {
            return (!progress.hasOwnProperty('visible') || progress.visible) &&
                hiddenImports.indexOf(progress.id) === -1;
        });
    },
);

export const getLatestUploadStatus = createSelector(
    getBulkUploadState,
    getBulkUploadType,
    (bulkUploadState: BulkUploadState, type: UploadType): UploadStatus => {
        if (type !== UploadType.Undefined
            && bulkUploadState.latestUploadStatus && bulkUploadState.latestUploadStatus[type]
        ) {
            return bulkUploadState.latestUploadStatus[type];
        }
        return UploadStatus.Failed;
    }
);