import { Action } from 'redux';

import { Id } from 'store/types';
import { FiltersState } from 'store/Filters/types';
import { TargetFund } from 'store/Fund/types';
import { Ruleset, Rule, RulesetPropertyType, Target, RuleValidation, RulesetListPermissions } from './types';
import { History } from 'history';

export const GET_RULESET_LIST = 'store/alertsManager/rulesets/getRulesetList';
export const SET_RULESET_LIST = 'store/alertsManager/rulesets/setRulesetList';
export const SET_RULESET_LIST_WITH_SCROLL = 'store/alertsManager/rulesets/setRulesetListWithScroll';
export const INIT_RULESET_CREATION = 'store/alertsManager/rulesets/initCreation';
export const INIT_RULESET_EDITING = 'store/alertsManager/rulesets/initEditing';
export const SET_CREATED_RULESET = 'store/alertsManager/rulesets/setCreatedRuleset';
export const SET_EDITED_RULESET = 'store/alertsManager/rulesets/setEditedRuleset';
export const SET_EDITED_RULESET_PARTIAL = 'store/alertsManager/rulesets/setEditedRulesetPartial';
export const SAVE_RULESET = 'store/alertsManager/rulesets/saveRuleset';

export const CREATE_RULESET = 'store/alertsManager/rulesets/createRuleset';
export const DELETE_RULESET = 'store/alertsManager/deleteRuleset';
export const PUBLISH_RULESET = 'store/alertsManager/publishRuleset';

export const ADD_RULE = 'store/alertsManager/addRule';
export const DELETE_RULE = 'store/alertsManager/deleteRule';
export const UPDATE_RULE = 'store/alertsManager/updateRule';
export const UPDATE_RULE_VALIDATION = 'store/alertsManager/updateRuleFieldValidation';
export const ADD_RULE_VALIDATION = 'store/alertsManager/addRuleFieldValidation';
export const DELETE_RULE_VALIDATION = 'store/alertsManager/deleteRuleFieldValidation';
export const UPDATE_RULESET_PROPERTY = 'store/alertsManager/updateRulesetProperty';
export const UPDATE_RULESET_TARGET = 'store/alertsManager/updateRulesetTarget';
export const TOUCH_RULE_FIELD = 'store/alertsManager/touchRuleField';
export const TOUCH_ALL_RULE_FIELDS = 'store/alertsManager/touchAllRuleFields';
export const UPDATE_TARGET_SECTION_FILTER = 'store/alertsManager/updateTargetSectionFilter';

export const GET_TARGET_FUND_LIST = 'store/alertsManager/rulesets/getTargetFundList';
export const SET_TARGET_FUND_LIST = 'store/alertsManager/rulesets/setTargetFundList';
export const SET_TARGET_FUND_LIST_WITH_SCROLL = 'store/alertsManager/rulesets/setTargetFundListWithScroll';
export const DOWNLOAD_RULESET_LIST = 'store/alertsManager/rulesets/downloadRulesetList';
export const BULK_UPLOAD = 'store/alertsManager/rulesets/bulkUpload';

export interface GetRulesetListAction extends Action {
    filter: FiltersState;
}

export interface SetRulesetListAction extends Action {
    count: number;
    list: Ruleset[];
    permissions: RulesetListPermissions;
}

export interface DownloadRulesetListAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
}

export interface BulkUploadAction extends Action {
    files: FormData;
}

export interface InitRulesetCreationAction extends Action {}
export interface InitRulesetEditingAction extends Action {
    id: Id;
    active: boolean;
}

export interface SetEditedRulesetAction extends Action {
    ruleset: Ruleset;
}

export interface SetEditedRulesetPartialAction extends Action {
    ruleset: Partial<Ruleset>;
}

export interface SetCreatedRulesetAction extends Action {
    ruleset: Ruleset;
}

export interface SaveRulesetAction extends Action {
    ruleset: Ruleset;
}

export interface DeleteRulesetAction extends Action {
    ruleset: Ruleset;
    history: History;
}

export interface PublishRulesetAction extends Action {
    ruleset: Ruleset;
}

export const DISCRIMINATOR_ADD_RULE_ACTION = 'AddRuleAction';
export interface AddRuleAction extends Action {
    discriminator: 'AddRuleAction';
}

export const DISCRIMINATOR_DELETE_RULE_ACTION = 'DeleteRuleAction';
export interface DeleteRuleAction extends Action {
    discriminator: 'DeleteRuleAction';
    index: number;
}

export const DISCRIMINATOR_UPDATE_RULE_ACTION = 'UpdateRuleAction';
export interface UpdateRuleAction extends Action {
    discriminator: 'UpdateRuleAction';
    index: number;
    rule: Rule;
    field: string;
}

export interface UpdateRulesetPropertyAction extends Action {
    name: string;
    value: RulesetPropertyType;
}

export interface UpdateRulesetTargetAction extends Action {
    target: Target;
}

export interface GetTargetFundListAction extends Action {
    entityType?: string;
    entityId?: Id;
    filter: FiltersState;
}

export interface SetTargetFundListAction extends Action {
    count: number;
    firmCount: number;
    list: TargetFund[];
}

export interface UpdateRuleValidationAction extends Action {
    index: number;
    validation: RuleValidation;
}

export interface AddRuleValidationAction extends Action {
    validation: RuleValidation;
}

export interface DeleteRuleValidationAction extends Action {
    index: number;
}

export interface TouchRuleFieldAction extends Action {
    rule: Rule;
    index: number;
    field: string;
}

export interface TouchAllRuleFieldsAction extends Action {
    rule: Rule;
    index: number;
}

export interface UpdateTargetSectionFilterAction extends Action {
    target: Target;
    applyFilter: boolean;
    entityType?: string;
    entityId?: Id;
}

export type ModifyRulesetActions = AddRuleAction
    | DeleteRuleAction
    | UpdateRuleAction
    | UpdateRulesetPropertyAction
    | UpdateRulesetTargetAction;

export const ModifyRulesetTypes = [
    ADD_RULE, DELETE_RULE, UPDATE_RULE,
    UPDATE_RULESET_PROPERTY, UPDATE_RULESET_TARGET
];

export type RulesetsAction = GetRulesetListAction
    | SetRulesetListAction
    | InitRulesetCreationAction
    | InitRulesetEditingAction
    | SetCreatedRulesetAction
    | SetEditedRulesetAction
    | SetEditedRulesetPartialAction
    | SaveRulesetAction
    | DeleteRulesetAction
    | PublishRulesetAction
    | AddRuleAction
    | DeleteRuleAction
    | UpdateRuleAction
    | UpdateRulesetPropertyAction
    | UpdateRulesetTargetAction
    | GetTargetFundListAction
    | SetTargetFundListAction
    | UpdateRuleValidationAction
    | AddRuleValidationAction
    | DeleteRuleValidationAction
    | DownloadRulesetListAction
    | BulkUploadAction;
