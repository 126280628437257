export const DiligenceFilterTypeDictionary = {
    survey: 'survey',
    requester: 'requester',
    statusDisplay: 'status',
    respondingAccount: 'account',
    respondingVehicle: 'vehicle',
    campaign: 'campaign',
    deadline: 'dateDue',
    dateSent: 'dateSent',
    dateClosed: 'dateReceived'
};

// Mapping table column to ordering field type
export const DiligenceOrderingTypeDictionary = {
    targetAccountName: 'account',
    surveyName: 'survey',
    deadline: 'dateDue',
    initiatingAccountName: 'requester'
};

export const sentOrClosedDateFields = ['dateReceived', 'dateSent'];

export const DiligenceFilterAttributeDictionary = Object.keys(DiligenceFilterTypeDictionary).reduce(
    (obj: Object, key: string) => {
        obj[DiligenceFilterTypeDictionary[key]] = key;
        return obj;
    },
    {}
);

export enum DiligenceHeaderOptions {
    Survey = 'surveyName',
    Requester = 'initiatingAccountName',
    Status = 'statusDisplay',
    DueDate = 'deadline',
    SentOrClosedDate = 'receivedOrPublished',
    Target = 'targetAccountName'
}

export const DiligenceDateFilters = ['deadline', 'dateClosed', 'dateSent'];
