export const initialState = {

};

export const messages = {
    UPDATE_PROFILE_SUCCESS_MESSAGE: 'User\'s profile was successfully updated',
    UPDATE_PROFILE_FAIL_MESSAGE: 'User\'s profile update failed',
    UPDATE_PASSWORD_SUCCESS_MESSAGE: 'User\'s password was successfully updated',
    UPDATE_PASSWORD_FAIL_MESSAGE: 'User\'s password update failed',
    UPGRADE_ADHOC_ACCOUNT_SUCCESS_MESSAGE: 'User\'s account was upgraded to Standard successfully',
    UPGRADE_ADHOC_ACCOUNT_FAIL_MESSAGE: 'User\'s account upgrade failed',
};

export const UPGRADE_ADHOC_ACCOUNT_MODAL_NAME = 'UPGRADE_ADHOC_ACCOUNT_MODAL';