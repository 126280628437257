import { all } from 'redux-saga/effects';

import groupsSagas from './Groups/sagas';

export default function* root() {
    yield all(
        [
            groupsSagas(),
        ]
    );
}