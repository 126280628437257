import { delay } from 'redux-saga';
import { put, takeLatest, all, takeEvery, select } from 'redux-saga/effects';

import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { closeModal } from 'store/Modals/General/actionCreators';
import * as portfolioApi from 'services/portfolio';
import * as fundApi from 'services/fund';

import {
    CREATE_ENTITIES_FAIL_MESSAGE, CREATE_ENTITIES_SUCCESS_MESSAGE, CREATE_PORTFOLIO_FAIL_MESSAGE,
    PORTFOLIO_BULK_UPLOAD_NAME
} from './constants';
import { getUploadHistory, startBulkUpload } from '../BulkUpload/actionCreator';
import {
    clearAddPortfolioModal,
    hideBottomArea,
    setNotFoundSolutions,
    setPortfolio,
    setPortfolios,
    setUploadedStats,
    setUploadedRows,
    updateReprocessedRow,
    setUncompletedPortfolioImport,
} from './actionCreators';
import {
    ApplyFilterAction,
    CancelUploadAction,
    CreateNewPortfolioAction,
    CreateImportEntitiesAction,
    DeletePortfoliosAction,
    GetNotFoundSolutionsAction,
    GetPortfolioAction,
    ImportFilesAction,
    ReprocessUploadAction,
    UpdateBulkUploadRowAction,
    APPLY_FILTER,
    CANCEL_UPLOAD,
    CREATE_NEW_PORTFOLIO,
    CREATE_IMPORT_ENTITIES,
    DELETE_UPLOADED_ROWS,
    DELETE_PORTFOLIOS,
    DOWNLOAD_FUNDS,
    GET_NOT_FOUND_SOLUTIONS,
    GET_PORTFOLIO,
    GET_UPLOADED_ROWS,
    IMPORT_FILES,
    REPROCESS_UPLOAD,
    UPDATE_BULK_UPLOAD_ROW,
    UPDATE_DESCRIPTION,
    DownloadFundsAction,
    GET_UNCOMPLETED_PORTFOLIO_IMPORT,
} from './actions';
import { callApplyFilters, clearChecked, setIsLoading } from '../Filters/actionCreators';
import { FilterNames } from '../Filters/types';
import { setRedirect } from '../Redirect/actionCreators';
import { PORTFOLIO_REDIRECT } from '../Redirect/constants';
import { UploadType } from '../BulkUpload/types';
import { bulkActionMessages, ExportPageType } from '../constants';

function* applyFilter(action: ApplyFilterAction) {
    try {
        yield put(initAction(action.type));
        const response = yield portfolioApi.getPortfolios(action.filter);
        let withScroll = false;
        yield  put(setIsLoading(true));
        if (action.filter.scroller) {

            withScroll = true;
        }
        yield put(setPortfolios(response.count, response.results, withScroll));
        yield put(doneActionSuccess(action.type));
        yield  put(setIsLoading(false));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* cancelUpload(action: CancelUploadAction) {
    try {
        yield put(initAction(action.type));
        yield portfolioApi.cancelUpload(action.payload);
        yield put(getUploadHistory(UploadType.Portfolio));
        yield put(doneActionSuccess(action.type, 'Upload has been canceled'));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* deletePortfoliosByIds(action: DeletePortfoliosAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield portfolioApi.deletePortfolios(
            action.checkedIds,
            action.checkedAll,
            store.filters[FilterNames.portfolioList]
        );
        yield put(closeModal());
        yield put(callApplyFilters(FilterNames.portfolioList, APPLY_FILTER));
        yield put(clearChecked(FilterNames.portfolioList));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getPorfolioById(action: GetPortfolioAction) {
    try {
        yield put(initAction(action.type));

        const response = yield portfolioApi.getPortfolioById(action.id);
        yield put(setPortfolio(response));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* createPortfolio(action: CreateNewPortfolioAction) {
    try {
        yield put(initAction(action.type));

        const newPort = yield portfolioApi.createPortfolio(action.newPortfolio);
        yield put(setRedirect(PORTFOLIO_REDIRECT, newPort));
        yield put(clearAddPortfolioModal());
        yield put(closeModal());
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, CREATE_PORTFOLIO_FAIL_MESSAGE));
    }
}

function* importPortfolioFiles(action: ImportFilesAction) {
    try {

        yield put(initAction(action.type));
        yield put(hideBottomArea(false));
        yield put(startBulkUpload(PORTFOLIO_BULK_UPLOAD_NAME));
        yield portfolioApi.importFiles(action.files);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.UPLOAD_FILE_FAILED));
    }
}

function* getUploadedRows(action: any) {
    try {
        yield put(initAction(action.type));
        const uploadedRows = yield portfolioApi.getUploadedRows(action.filter);
        const uploadedStats = yield portfolioApi.getUploadedStats(action.filter);
        let withScroll = false;
        yield  put(setIsLoading(true));
        if (action.filter.scroller) {
            withScroll = true;
        }
        yield put(setUploadedRows(uploadedRows, withScroll));
        yield put(setUploadedStats(uploadedStats));

        yield put(doneActionSuccess(action.type));
        yield  put(setIsLoading(false));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* getNotFoundSolutions(action: GetNotFoundSolutionsAction) {
    yield put(setNotFoundSolutions([]));  // clear previous suggestions

    yield delay(500);

    try {
        yield put(initAction(action.type));
        let suggestions;

        if (action.status === 'mismatch') {
            suggestions = yield portfolioApi.getSuggestions(action.fileId, action.rowId);
        } else {    // (action.status === 'notFound')
            suggestions = yield fundApi.getAllFunds(action.payload);
        }
        yield put(setNotFoundSolutions(suggestions));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* createImportEntities(action: CreateImportEntitiesAction) {
    try {
        yield put(initAction(action.type));
        yield portfolioApi.createImportEntities(action.id);
        yield put(doneActionSuccess(action.type, CREATE_ENTITIES_SUCCESS_MESSAGE));
        yield put(setRedirect(PORTFOLIO_REDIRECT, {id: action.portfolioId}));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, CREATE_ENTITIES_FAIL_MESSAGE));
    }
}

function* updateBulkUploadRow(action: UpdateBulkUploadRowAction) {
    try {
        yield put(initAction(action.type));
        yield portfolioApi.updateBulkUploadRow(
            action.payload.fileId,
            action.payload.rowId,
            action.payload.data
        );
        yield put(callApplyFilters(FilterNames.portfolioUploadedRows, GET_UPLOADED_ROWS));
        yield put(doneActionSuccess(action.type, 'Row has been accepted!'));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, ' '));
    }
}

function* updateDescription(action: UpdateBulkUploadRowAction) {
    try {
        yield put(initAction(action.type));
        yield portfolioApi.updateDescription(action.payload);
        const response = yield portfolioApi.getPortfolioById(action.payload.portfolio.id);
        yield put(setPortfolio(response));
        yield put(doneActionSuccess(action.type, 'Description has been updated!'));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, ' '));
    }
}

function* deleteUploadedRows(action: UpdateBulkUploadRowAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield portfolioApi.deleteUploadedRows(
            action.payload,
            action.fileId,
            store.filters[FilterNames.portfolioUploadedRows].checkedAll,
        );
        yield put(clearChecked(FilterNames.portfolioUploadedRows));
        yield put(callApplyFilters(FilterNames.portfolioUploadedRows, GET_UPLOADED_ROWS));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* downloadFunds(action: DownloadFundsAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield fundApi.exportFile(
            action.checkedIds,
            action.checkedAll,
            ExportPageType.Portfolio,
            store.filters[FilterNames.portfolioDetail]
        );

        yield put(doneActionSuccess(action.type));
        yield put(clearChecked(FilterNames.portfolioDetail));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.DOWNLOAD_FAILED));
    }
}

function* reprocessUpload(action: ReprocessUploadAction) {
    try {
        yield put(initAction(action.type));
        const reprocessedRow = yield portfolioApi.reprocessUpload(action.fileId, action.rowId);
        yield put(updateReprocessedRow(reprocessedRow));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getUncompletedImport(action: ReprocessUploadAction) {
    try {
        yield put(initAction(action.type));
        const result = yield portfolioApi.getUncompletedPortfolioImport();
        yield put(setUncompletedPortfolioImport(result.has_import, result.portfolio_id, result.import_id));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchApplyFilter() {
    yield takeEvery(APPLY_FILTER, applyFilter);
}

function* watchCancelUpload() {
    yield takeLatest(CANCEL_UPLOAD, cancelUpload);
}

function* watchCreateImportEntities() {
    yield takeEvery(CREATE_IMPORT_ENTITIES, createImportEntities);
}

function* watchDeletePortfolios() {
    yield takeEvery(DELETE_PORTFOLIOS, deletePortfoliosByIds);
}

function* watchDeleteUploadedRows() {
    yield takeEvery(DELETE_UPLOADED_ROWS, deleteUploadedRows);
}

function* watchDownloadFunds() {
    yield takeEvery(DOWNLOAD_FUNDS, downloadFunds);
}

function* watchUpdateBulkUploadRow() {
    yield takeEvery(UPDATE_BULK_UPLOAD_ROW, updateBulkUploadRow);
}

function* watchGetPortfolio() {
    yield takeLatest(GET_PORTFOLIO, getPorfolioById);
}

function* watchGetUploadedRows() {
    yield takeLatest(GET_UPLOADED_ROWS, getUploadedRows);
}

function* watchImportFiles() {
    yield takeLatest(IMPORT_FILES, importPortfolioFiles);
}

function* watchUpdateAccountUser() {
    yield takeEvery(CREATE_NEW_PORTFOLIO, createPortfolio);
}

function* watchUpdateDescription() {
    yield takeEvery(UPDATE_DESCRIPTION, updateDescription);
}

function* watchGetNotFoundSolutions() {
    yield takeLatest(GET_NOT_FOUND_SOLUTIONS, getNotFoundSolutions);
}

function* watchReprocessUpload() {
    yield takeEvery(REPROCESS_UPLOAD, reprocessUpload);
}

function* watchGetUncompletedImport() {
    yield takeEvery(GET_UNCOMPLETED_PORTFOLIO_IMPORT, getUncompletedImport);
}

export default function* root() {
    yield all(
        [
            watchApplyFilter(),
            watchCancelUpload(),
            watchCreateImportEntities(),
            watchDeletePortfolios(),
            watchDeleteUploadedRows(),
            watchDownloadFunds(),
            watchGetNotFoundSolutions(),
            watchGetPortfolio(),
            watchGetUploadedRows(),
            watchImportFiles(),
            watchReprocessUpload(),
            watchUpdateAccountUser(),
            watchUpdateBulkUploadRow(),
            watchUpdateDescription(),
            watchGetUncompletedImport(),
        ]
    );
}