import * as React from 'react';

import ButtonDefault from '../Shared/Ui/Buttons/ButtonDefault';

const styles = require('./CreateAccountButton.scss');

interface Props {
    createAccount: () => void;
}

const CreateAccountButton: React.FC<Props> = ({createAccount}) => (
    <div className={styles.wrapper}>
        <ButtonDefault inverse={true} handleClick={createAccount}>
            Create Account
        </ButtonDefault>
    </div>
);

export default CreateAccountButton;
