import { Action } from 'redux';
import { FundAttributeModel, FundModel, NewFundModel, FundProfileUserSettingsModel } from './models/fund';
import { ContactModel, NewContactModel, UpdateContactModel } from './models/contact';
import {
    DataEntryPart, FundAttributes, FundReportingHistory, FundStatistics, FundProfileFilterState, FundListPermissions,
} from './types';
import { HistoryPeriodChoices } from '../constants';
import { Id, TableHeader } from '../types';
import { FiltersState } from '../Filters/types';
import { Dataset, DatasetWithRowType } from 'store/DataManager/models/dataset';

export const APPLY_FILTER = 'store/fund/applyFilter';
export const APPLY_DATASETS_FILTER = 'store/fund/applyDatasetsFilter';
export const BULK_UPLOAD = 'store/fund/bulkUpload';
export const DOWNLOAD_FUNDS = 'store/fund/downloadFunds';
export const SET_FUNDS = 'store/fund/setFunds';
export const GET_FIRM_FROM_ERM = 'store/fund/getFrimFromErm';
export const CLEAR_FIRM_FROM_ERM = 'store/fund/clearFrimFromErm';
export const SET_FIRM_FROM_ERM = 'store/fund/setFrimFromErm';
export const CHANGE_NEW_FUND_FIELD = 'store/fund/changeNewFundField';
export const CREATE_NEW_FUND = 'store/fund/createNewFund';
export const CLEAR_NEW_FUND_FIELD = 'store/fund/clearNewFund';
export const CLEAR_FUND = 'store/fund/clearFund';
export const GET_FUND = 'store/fund/getFund';
export const GET_FUNDS_DATA_ENTRIES = 'store/fund/getFundsDataEntries';
export const GET_FUND_ATTRIBUTES = 'store/fund/getFundAttributes';
export const SET_FUND_ATTRIBUTES = 'store/fund/setFundAttributes';
export const SET_FUND_ATTRIBUTES_WITH_SCROLL = 'store/fund/setFundAttributesWithScroll';
export const GET_FUND_DATASET_HISTORY = 'store/fund/getFundDatasetHistory';
export const SET_FUND_DATASET_HISTORY = 'store/fund/setFundDatasetHistory';
export const CLEAR_FUND_DATASET_HISTORY = 'store/fund/clearFundDatasetHistory';
export const EXPORT_FUND_DATASETS = 'store/fund/exportFundDatasets';
export const SAVE_REQUESTOR_DATASET = 'store/fund/saveRequestorDataset';
export const MAKE_DATASET_THE_LATEST = 'store/fund/makeDatasetTheLatest';
export const UPDATE_REQUESTOR_DATASET = 'store/fund/updateRequestorDataset';
export const UPDATE_REQUESTOR_AND_HISTORY_DATASET = 'store/fund/updateRequestorAndHistoryDataset';
export const DELETE_FUNDS = 'store/fund/deleteFunds';
export const SET_FUND = 'store/fund/setFund';
export const SET_FUNDS_WITH_SCROLL = 'store/funds/setFundsWithScroll';
export const SET_DATASETS = 'store/fund/setDatasets';
export const SET_DATASETS_WITH_SCROLL = 'store/funds/setDatasetsWithScroll';
export const SET_DATASET_LIST_IS_UPDATED = 'store/funds/datasetList/setIsUpdated';
export const GET_CONTACTS = 'store/fund/getContacts';
export const SET_CONTACTS = 'store/fund/setContacts';
export const ADD_CONTACT = 'store/fund/addContact';
export const SET_ADDING_CONTACT_RESULT = 'store/fund/setAddingContactResult';
export const UNSET_ADDING_CONTACT_RESULT = 'store/fund/unsetAddingContactResult';
export const EDIT_CONTACT = 'store/fund/editContact';
export const DELETE_CONTACTS = 'store/fund/deleteContacts';
export const CHANGE_FUND = 'store/fund/changeFund';
export const CHANGE_FUND_REQUEST = 'store/fund/changeFundRequest';
export const GET_FUND_REPORTING_HISTORY = 'store/fund/getFundReportingHistory';
export const SET_FUND_REPORTING_HISTORY = 'store/fund/setFundReportingHistory';
export const SET_FUND_PROFILE_HISTORY_PERIOD = 'store/fund/profile/setHistoryPeriod';
export const SET_FUND_LIST_TEMPORARY_HEADERS = 'store/fund/setFundListTemporaryHeaders';
export const SET_FUNDS_DATA_ENTRIES = 'store/fund/setFundsDataEntries';
export const SAVE_FUND_LIST_COLUMNS = 'store/fund/saveFundListColumns';
export const GET_GRID_CONFIGURATION = 'store/fund/getGridConfiguration';
export const GET_FUND_PROFILE_STATISTICS = 'store/fund/profile/getStatistics';
export const ADD_FUNDS_TO_PROFILE_STATISTICS = 'store/fund/profile/statistics/addFunds';
export const ADD_PEER_GROUPS_TO_PROFILE_STATISTICS = 'store/fund/profile/statistics/addPeerGroups';
export const ALTER_FUND_PROFILE_STATISTICS = 'store/fund/profile/alterStatistics';
export const REMOVE_ENTITY_FROM_PROFILE_STATISTICS = 'store/fund/profile/removeStatisticsEntity';

export const SET_FUND_PROFILE_USER_SETTINGS = 'store/fund/profile/setSettings';
export const UPDATE_FUND_PROFILE_USER_SETTINGS = 'store/fund/profile/updateSettings';

export const GET_FUND_LIST_FOR_ADD_ITEMS_MODAL = 'store/fund/profile/addItemsModal/getFundList';
export const GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL = 'store/fund/profile/addItemsModal/getPeerGroupList';

export const APPLY_FILTER_FUND_PROFILE = 'store/fund/profile/applyFilter';
export const CHANGE_CHART_INTERVAL = 'store/fund/profile/changeChartInterval';

export const SELECT_PERIOD = 'store/fund/profile/selectedPeriod/set';
export const CLEAR_SELECTED_PERIOD = 'store/fund/profile/selectPeriod/clear';

export interface ApplyFilterAction extends Action {
    filter: any;
}

export interface ApplyDatasetsFilterAction extends Action {
    filter: FiltersState;
}

export interface BulkUploadAction extends Action {
    files: FormData;
}

export interface DownloadFundsAction extends Action {
    checkedIds: Id[];
    checkedAll: boolean;
}

export interface GetFirmFromErmAction extends Action {
    filter: any;
}

export interface SetFirmsFromErmAction extends Action {
    potentialFunds: any;
}

export interface SetFundsAction extends Action {
    count: number;
    firmCount: number;
    funds: FundModel[];
    permissions: FundListPermissions;
}

export interface SetDatasetsAction extends Action {
    count: number;
    datasets: Dataset[];
}

export interface SetDatasetListIsUpdatedAction extends Action {
    flag: boolean;
}

export interface ChangeNewFundFieldAction extends Action {
    fieldName: string;
    fieldValue: string;
}

export interface CreateNewFundAction extends Action {
    newFund: NewFundModel;
}

export interface DeleteFundsAction extends Action {
    ids: Id[];
    portfolioId: Id;
    checkedAll: boolean;
}

export interface GetFundAction extends Action {
    id: Id;
    withRequest: boolean;
}

export interface GetFundAttributesAction extends Action {
    attributeType: FundAttributes;
    filter: FiltersState;
}

export interface SetFundAttributesAction extends Action {
    attributeType: string;
    count: number;
    results: FundAttributeModel[];
}

export interface GetFundDatasetHistoryAction extends Action {
    id: Id;
    dataset: Dataset;
}

export interface SetFundDatasetHistoryAction extends Action {
    parentId: Id;
    results: Dataset[];
}

export interface ClearFundDatasetHistoryAction extends Action {
    id: Id;
    period?: string;
}

export interface ExportFundDatasetsAction extends Action {
    id: Id;
}

export interface SaveRequestorDatasetAction extends Action {
    dataset: Dataset;
}

export interface MakeDatasetTheLatestAction extends Action {
    dataset: DatasetWithRowType;
}

export interface UpdateRequestorDatasetAction extends Action {
    dataset: Dataset;
}

export interface UpdateRequestorAndHistoryDatasetAction extends Action {
    historyDataset: Dataset;
    updatedDataset: Dataset;
}

export interface SetFundAction extends Action {
    fund: FundModel;
    count: number;
}

export interface GetContactsAction extends Action {
    fundId: Id;
}

export interface SetContactsAction extends Action {
    contacts: ContactModel[];
}

export interface AddContactAction extends Action {
    contact: NewContactModel;
}

export interface EditContactAction extends Action {
    fundId: Id;
    contactId: Id;
    contact: UpdateContactModel;
}

export interface DeleteContactsAction extends Action {
    fundId: Id;
    contactIds: Id[];
}

export interface ChangeFundAction extends Action {
    field: string;
    value: any;
}

export interface ChangeFundRequestAction extends Action {
    field: string;
    value: any;
}

export interface GetFundReportingHistoryAction extends Action {
    fundId: Id;
}

export interface SetFundReportingHistoryAction extends Action {
    history: FundReportingHistory[];
}

export interface SetFundProfileHistoryPeriodAction extends Action {
    historyPeriod: HistoryPeriodChoices;
}

export interface SetFundListTemporaryHeadersAction extends Action {
    temporaryHeaders: TableHeader[];
}

export interface SaveFundListColumnsAction extends Action {
    visibleHeaders: string[];
    instanceId: Id;
    gridType: string;
}

export interface GetFundListColumnsAction extends Action {
    instanceId: Id;
    gridType: string;
}

export interface GetFundsDataEntriesAction extends Action {
    period?: string;
}

export interface SetFundsDataEntriesAction extends Action {
    data: DataEntryPart[];
}

export interface GetFundProfileStatisticsAction extends Action {
    fundId: Id;
}

export interface AlterFundProfileStatisticsAction extends Action {
    data: FundStatistics[];
    initial: boolean;
}

export interface RemoveEntityFromProfileStatisticsAction extends Action {
    baseFundId: Id;
    removedEntityId: Id;
    rememberSelected?: boolean;
}

export interface AddFundsToProfileStatisticsAction extends Action {
    baseFundId: Id;
    filterName: string;
    rememberSelected?: boolean;
}

export interface AddPeerGroupsToProfileStatisticsAction extends Action {
    baseFundId: Id;
    filterName: string;
    rememberSelected?: boolean;
}

export interface ApplyFilterFundProfileAction extends Action {
    filter: FundProfileFilterState;
}

export interface UpdateFundProfileUserSettingsAction extends Action {
    fundId: Id;
    settings: FundProfileUserSettingsModel;
}

export interface SetFundProfileUserSettingsAction extends Action {
    settings: FundProfileUserSettingsModel;
}

export interface ChangeChartIntervalAction extends Action {
    start: number;
    end: number;
}

export interface SelectPeriodAction extends Action {
    period: string | null;
}

export interface ClearSelectedPeriodAction extends Action {}

export type FundAction = ApplyFilterAction |
    SetFundsAction |
    ChangeNewFundFieldAction |
    CreateNewFundAction |
    GetFundAction |
    GetFundAttributesAction |
    SetFundAttributesAction |
    SetFundAction |
    DeleteFundsAction |
    DownloadFundsAction |
    GetContactsAction |
    SetContactsAction |
    AddContactAction |
    EditContactAction |
    DeleteContactsAction |
    ChangeFundAction |
    ChangeFundRequestAction |
    GetFundReportingHistoryAction |
    GetFirmFromErmAction |
    SetFirmsFromErmAction |
    SetFundReportingHistoryAction |
    SetFundProfileHistoryPeriodAction |
    ClearFundDatasetHistoryAction |
    GetFundDatasetHistoryAction |
    SetFundDatasetHistoryAction |
    ExportFundDatasetsAction |
    SaveRequestorDatasetAction |
    MakeDatasetTheLatestAction |
    UpdateRequestorDatasetAction |
    UpdateRequestorAndHistoryDatasetAction |
    BulkUploadAction |
    SetDatasetsAction |
    SetFundListTemporaryHeadersAction |
    SaveFundListColumnsAction |
    GetFundsDataEntriesAction |
    SetFundsDataEntriesAction |
    GetFundProfileStatisticsAction |
    AlterFundProfileStatisticsAction |
    RemoveEntityFromProfileStatisticsAction |
    AddFundsToProfileStatisticsAction |
    AddPeerGroupsToProfileStatisticsAction |
    ApplyFilterFundProfileAction |
    UpdateFundProfileUserSettingsAction |
    SetFundProfileUserSettingsAction |
    ChangeChartIntervalAction |
    SelectPeriodAction |
    ClearSelectedPeriodAction |
    SetDatasetListIsUpdatedAction
    ;