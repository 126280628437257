import { put, takeLatest, all, select, call } from 'redux-saga/effects';

import * as firmApi from 'services/firm';

import { initialDetailedFirmModel } from './constants';
import { setFirms, setFirm, setFundsForFirm } from './actionCreators';
import {
    ApplyFilterAction, GetFirmAction, ApplyFilterForFundsAction, GET_FIRM, APPLY_FILTER, APPLY_FILTER_FOR_FUNDS,
} from './actions';
import { getSingleFirmState } from './selectors';
import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import { setIsLoading } from 'store/Filters/actionCreators';
import { getUserAccountType } from 'store/Auth/selectors';
import { Id } from 'store/types';

function* applyFilter(action: ApplyFilterAction) {
    try {
        yield put(initAction(action.type));

        const store = yield select();
        const userType = store.auth.user.active_account.account.type;
        const response = yield firmApi.getFirms(action.filter, userType);
        let withScroll = false;

        yield put(setIsLoading(true));
        if (action.filter.scroller) {
            withScroll = true;
        }

        yield put(setFirms(response.count, response.results, withScroll));
        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* updateFirmInStore(firmId: Id) {
    const userAccountType = yield select(getUserAccountType);
    yield put(setFirm({...initialDetailedFirmModel}));
    const {currentFirmData, firmRemoteDetails} = yield all({
        currentFirmData: call(firmApi.getFirmById, firmId, userAccountType),
        firmRemoteDetails: call(firmApi.getFirmRemoteDetails, firmId, userAccountType)
    });
    yield put(setFirm({...currentFirmData, ...firmRemoteDetails}));
    return currentFirmData;
}

function* getFirmById(action: GetFirmAction) {
    try {
        yield put(initAction(action.type));
        yield call(updateFirmInStore, action.firmId);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* applyFilterForFunds(action: ApplyFilterForFundsAction) {
    try {
        yield put(initAction(action.type));

        let currentFirm = yield select(getSingleFirmState);
        if (!(currentFirm && currentFirm.id === action.firmId && currentFirm.ap_id !== undefined)) {
            currentFirm = yield call(updateFirmInStore, action.firmId);
        }
        const response = yield firmApi.getFunds(currentFirm.ap_id, action.filter);
        let withScroll = false;
        yield put(setIsLoading(true));
        if (action.filter.scroller) {
            withScroll = true;
        }
        yield put(setFundsForFirm(response.count, response.results, withScroll));
        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
        yield put(setIsLoading(false));
    }
}

function* watchApplyFilter() {
    yield takeLatest(APPLY_FILTER, applyFilter);
}

function* watchGetFirm() {
    yield takeLatest(GET_FIRM, getFirmById);
}

function* watchGetFundsForFirm() {
    yield takeLatest(APPLY_FILTER_FOR_FUNDS, applyFilterForFunds);
}

export default function* root() {
    yield all(
        [
            watchApplyFilter(),
            watchGetFirm(),
            watchGetFundsForFirm(),
        ]
    );
}