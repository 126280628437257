import * as React from 'react';
import * as classNames from 'classnames';

interface Props {
    title: string;
    value?: boolean;
    withLabel?: boolean;
    handleChange: (checked: boolean) => void;
}

const styles = require('./ToggleSwitch.scss');
const ToggleSwitch: React.FC<Props> = ({title, value, withLabel, handleChange}) => {
    const ClassNames = classNames({
        [styles.ui_toggle_switch]: !!styles.ui_toggle_switch,
    });

    return (
        <div className={ClassNames}>
            <label className="switch">
                <input
                    type="checkbox"
                    onChange={(event: any) => handleChange(event.target.checked)}
                    checked={!!value}
                />
                <span className="slider round"/>
            </label>
            <div className={styles.title}>{withLabel && title}</div>
        </div>
    );
};

ToggleSwitch.defaultProps = {
    value: false
};

export default ToggleSwitch;
