import { TableHeader } from '../store/types';

class HeaderHelper {
        static clearColumn(columns: TableHeader[], needFields: string[]): any {
            let answer: TableHeader[] | any[] = [];
            if (columns) {
                let newArray: TableHeader[] = [];

                needFields.forEach((field: string) => {
                    newArray = [
                        ...newArray,
                        ...columns.filter((column: TableHeader) => field === column.name)
                    ];
                });

                answer =  newArray;
            }

            return answer;
        }

}

export default HeaderHelper;