import DiligenceApiHelper from 'helpers/diligenceApiHelper';
import { API_REQUEST_URL, API_USER_FILTERS_URL } from 'helpers/diligence/apiHelper';
import { Filter, SuggestFiltersRequest } from 'store/Diligence/DataManager/types';

export const getRequests = async (request: any = {}, filters: Filter[] = [], orders: any = []) => {
    return DiligenceApiHelper.doRequest(`${API_REQUEST_URL}/search`, 'get', {
        params: {
            ...request,
            'filters[]': filters.map((filter: Filter) => JSON.stringify(filter)),
            'sortOrder[]': orders.map((order: any) => JSON.stringify(order))
        }
    });
};

export const suggestRequests = async (request: SuggestFiltersRequest) => {
    return DiligenceApiHelper.doRequest(
        `${API_REQUEST_URL}/suggestions`,
        'get',
        {
            params: {
                ...request
            }
        }).then((result) => {
            return {
                results: result.filterEntities,
                count: result.filterEntities.length
            };
    });
};

export const getSavedData = async (request: any, dataType: string) => {
    return DiligenceApiHelper.doRequest(`${API_USER_FILTERS_URL}/${dataType}`, 'get', {
        params: {
            ...request
        }
    }).then((result) => {
        return {
            filters: result.filters ? result.filters : [],
            sortOrder: result.sortOrder ? result.sortOrder : []
        };
    });
};

export const saveData = async (filters: any[], sortOrder: any[]) => {
    return DiligenceApiHelper.doRequest(`${API_USER_FILTERS_URL}/requests`, 'put', {
        userId: 'current',
        plaintext: 1,
        filters: filters,
        sortOrder: sortOrder
    });
};