import * as React from 'react';
import { connect } from 'react-redux';
import { Id } from '../../store/types';

import SuccessPortfolioMessage from '../../components/Shared/ProgressBar/SuccessPortfolioMessage';
import SuccessMessage from '../../components/Shared/ProgressBar/SuccessMessage';
import { BulkUploadProgress, UploadType, UploadAction } from '../../store/BulkUpload/types';
import { hideGlobalProgress } from '../../store/BulkUpload/actionCreator';
import { BULK_ITEM_NAME_DEFAULT, BULK_UPLOAD_COMPLETED_NAME } from '../../store/BulkUpload/constants';
import { FormatTypeChoices } from '../../store/constants';
import { formatText } from '../../helpers/tableHelper';

interface Props {
    progress: BulkUploadProgress;
    hideGlobalProgress: (id: Id) => any;
}

class SuccessItem extends React.PureComponent<Props> {
    handleHide = () => {
        this.props.hideGlobalProgress(this.props.progress.id);
    }

    render () {
        const {progress} = this.props;
        const type = progress.type;
        let uploadTypeName = BULK_UPLOAD_COMPLETED_NAME[type]
            ? BULK_UPLOAD_COMPLETED_NAME[type]
            : BULK_ITEM_NAME_DEFAULT;
        uploadTypeName = formatText(uploadTypeName, FormatTypeChoices.FirstCharUpper);

        const action = progress.action === UploadAction.IMPORT ? 'Upload' : 'Download';
        let item;
        if (type === UploadType.Portfolio && progress.action === UploadAction.IMPORT) {
            item = (
                <SuccessPortfolioMessage
                    progress={progress}
                    hide={this.handleHide}
                    uploadTypeName={uploadTypeName}
                />
            );
        } else {
            item = <SuccessMessage action={action} hide={this.handleHide} uploadTypeName={uploadTypeName}/>;
        }

        return item;
    }
}

const mapDispatchToProps = { hideGlobalProgress };

export default connect(null, mapDispatchToProps)(SuccessItem);