import { createSelector } from 'reselect';

import * as benchmarkHelper from './helpers/benchmark';

import { BenchmarkItem, GroupEditState, GroupsState } from './types';
import { defaultGroupPermissions, groupEditInitialState, initialState } from './constants';

import { getProfilesState } from '../selectors';
import { ProfilesState } from '../types';

export const getGroupsState = createSelector(
    getProfilesState,
    (state: ProfilesState): GroupsState => state.groups || initialState
);

export const getGroupListPermissions = createSelector(
    getGroupsState,
    (state: GroupsState) => state.permissions || defaultGroupPermissions
);

export const getGroupEditState = createSelector(
    getGroupsState,
    (state: GroupsState) => state.edit || groupEditInitialState
);

export const getEdited = createSelector(
    getGroupEditState,
    (state: GroupEditState) => state.group
);

export const getEditedBenchmarkItems = createSelector(
    getEdited,
    group => group.benchmark_items || []
);

export const getTargetSectionState = createSelector(
    getGroupEditState,
    (groupState: GroupEditState) => groupState.targetSection
);

export const getTargetFundList = createSelector(
    getTargetSectionState,
    state => state.fundList
);

export const getTargetSectionTemporaryHeaders = createSelector(
    getTargetSectionState,
    state => state.temporaryHeaders
);

export const getEditedBenchmarksFundIds = createSelector(
    getEditedBenchmarkItems,
    items => items
        .filter((item: BenchmarkItem) => benchmarkHelper.isFund(item))
        .map(item => item.content_object.id)
);

export const getEditedBenchmarksGroupIds = createSelector(
    getEditedBenchmarkItems,
    items => items
        .filter((item: BenchmarkItem) => benchmarkHelper.isFundGroup(item))
        .map(item => item.content_object.id)
);