import * as moment from 'moment';

import { CheckboxOption } from 'store/DataManager/types';
import { RequestorModel } from 'store/DataManager/models/requestor';
import User from 'store/Auth/models/user';
import { ADHOC_NAME } from 'store/Auth/constants';
import { DEFAULT_DATE_FORMAT } from 'store/constants';

class DataManagerHelper {

    static requestorsToString(requestors: CheckboxOption[]): string {
        let result;
        result = requestors.filter((requestor, index) => {
            return index < requestors.length - 1;
        }).map(requestor => requestor.label).join(', ');
        if (requestors.length > 1) {
            result += ' and ' + requestors[requestors.length - 1].label;
        } else if (requestors.length === 1) {
            result += requestors[0].label;
        }
        return result;
    }

    static getCheckboxesForRequestors(requestors: RequestorModel[]): CheckboxOption[] {
        return requestors.map((requestor: RequestorModel, index: number) => {
            return {
                label: requestor.requesting_account.name,
                checked: index === 0,
                value: requestor.requesting_account.id
            };
        });
    }

    static getUserName(user: User): string {
        return user.first_name !== ADHOC_NAME && user.last_name !== ADHOC_NAME && user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : `${user.email}`;
    }

    static getUserNamesString(users: User[]): string {
        let userNames = '';
        if (users) {
            users.forEach((user: User, index: number) => {
                userNames += (index !== 0 ? ', ' : '') + this.getUserName(user);
            });
        }
        return userNames;
    }

    static getTheNearestPeriod(date: string): string | null {
        let resultDate: string | null = null;
        if (date) {
            const currentDate = moment();
            const chosenDate = moment(date);
            // current period
            if (chosenDate.month() === currentDate.month() && chosenDate.day() <= currentDate.day()) {
                resultDate = currentDate.clone().date(0).format(DEFAULT_DATE_FORMAT);
            // previous period
            } else if (chosenDate.month() < currentDate.month()) {
                resultDate = chosenDate.clone().endOf('month').format(DEFAULT_DATE_FORMAT);
            // future period
            } else {
                // forbidden values
                resultDate = null;
            }
        }

        return resultDate;
    }

    // TODO: add frequency parameter and appropriate logic when system has not only month periods
    static getActivePeriod(): string {
        return moment().date(0).format(DEFAULT_DATE_FORMAT);
    }

    // period: string with format YYYY-MM-DD
    static isFuturePeriod(period: string): boolean {
        const activePeriod = DataManagerHelper.getActivePeriod();
        return moment(period).isAfter(activePeriod, 'day');
    }
}

export default DataManagerHelper;
