import {
    bulkUploadIcon, clockIcon, closeGrayIcon,
    closeIcon, discardIcon,
    downloadIcon,
    editIcon, lockedYellowIcon, refreshIcon, sendIcon,
    TableRowActionIcon,
    trashIcon, unlockedIcon,
    uploadIcon, viewIcon
} from './constants';

export const getIconSourceByName = (icon?: TableRowActionIcon) => {
    switch (icon) {
        case TableRowActionIcon.Pencil:
        case TableRowActionIcon.BigPencil:
            return editIcon;
        case TableRowActionIcon.Trash:
        case TableRowActionIcon.BigTrash:
            return trashIcon;
        case TableRowActionIcon.Download:
            return downloadIcon;
        case TableRowActionIcon.Upload:
            return uploadIcon;
        case TableRowActionIcon.BulkUpload:
            return bulkUploadIcon;
        case TableRowActionIcon.Close:
        case TableRowActionIcon.Cross:
            return closeIcon;
        case TableRowActionIcon.CrossGray:
            return closeGrayIcon;
        case TableRowActionIcon.Refresh:
            return refreshIcon;
        case TableRowActionIcon.Clock:
            return clockIcon;
        case TableRowActionIcon.Locked:
            return lockedYellowIcon;
        case TableRowActionIcon.Unlocked:
            return unlockedIcon;
        case TableRowActionIcon.Discard:
            return discardIcon;
        case TableRowActionIcon.Send:
            return sendIcon;
        case TableRowActionIcon.View:
        default:
            return viewIcon;
    }
};
