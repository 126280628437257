import * as React from 'react';
import * as classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import GlobalHelper from 'helpers/globalHelper';
import ActiveUser from 'store/Auth/models/activeUser';
import { UserAccount } from 'store/Auth/models/userAccount';
import { Id } from 'store/types';
import { isAdhoc, isInternal } from 'store/User/helpers';
import { formatText } from 'helpers/tableHelper';
import { FormatTypeChoices } from 'store/constants';
import DiligenceActiveUser from 'store/Diligence/Auth/models/activeUser';

const styles = require('./UserButton.scss');

interface Props {
    user: ActiveUser;
    switchAccount: (id: Id) => void;
    logout: () => void;

    diligenceUser: DiligenceActiveUser;
}
interface Params {

}

const UserButton: React.FC<Props & RouteComponentProps<Params>> = ({history, switchAccount, logout,
                                                                        user, diligenceUser}) => {

    const currentUser = (isInternal(user) || isAdhoc(user)) ? user : diligenceUser;

    const firstName = currentUser ? formatText(currentUser.first_name, FormatTypeChoices.FirstCharUpper) : '';
    const lastName = currentUser ? formatText(currentUser.last_name, FormatTypeChoices.FirstCharUpper) : '';
    const emailValue = !firstName && !lastName && currentUser && currentUser.email ? currentUser.email : '';

    const userNameClass = classNames({
        [styles.user]: true,
        [styles.email]: !!emailValue,
    });
    return (
        <UncontrolledDropdown className={styles.userButton}>
            <DropdownToggle>
                {`${firstName} ${lastName}`}
                <i />
            </DropdownToggle>
            <DropdownMenu>
                <div className={styles.dropHeader}>
                    <div className={styles.info}>Logged in as:</div>
                    <div className={userNameClass}>{`${emailValue}${firstName} ${lastName}`}</div>
                </div>
                { isInternal(user) &&
                    <div className={styles.dropBody}>
                        <DropdownItem onClick={() => history.push('/profile')}>
                            Profile
                        </DropdownItem>
                    </div>
                }
                { !isInternal(user) && !isAdhoc(user) &&
                    <div className={styles.dropBody}>
                        <DropdownItem onClick={() => history.push('/settings')}>
                            Settings
                        </DropdownItem>
                    </div>
                }
                {user.account_relations.length > 0 &&
                (<div className={styles.dropAccounts}>
                    {
                        user.account_relations.map((relation: UserAccount, idx) => {
                            return (
                                <DropdownItem
                                    className={styles.dropButton}
                                    key={idx}
                                    active={user.active_account.account.id === relation.account.id}
                                    onClick={() => switchAccount(relation.id)}
                                >
                                    {
                                        GlobalHelper.breakLine(
                                            GlobalHelper.addBreaks(relation.account.name)
                                        )
                                    }
                                </DropdownItem>
                            );
                        })
                    }
                </div>)
                }
                <div className={styles.dropFooter}>
                    <DropdownItem onClick={() => logout()}>
                        Logout
                    </DropdownItem>
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default withRouter(UserButton);
