import { delay } from 'redux-saga';
import { put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import * as fundApi from 'services/fund';

import { doneActionFail, doneActionSuccess, initAction } from 'store/Actions/actionCreators';
import * as requestsApi from 'services/request';
import { bulkActionMessages } from '../constants';

import {
    APPLY_FILTER,
    ApplyFilterAction,
    APPLY_FILTER_MANAGER_SCREEN,
    ApplyFilterManagerScreenAction,
    CANCEL_BULK_UPLOAD,
    CancelBulkUploadAction,
    GET_REQUESTORS,
    GetRequestorsAction,
    SAVE_EDITED_REQUEST,
    SaveEditedRequestAction, BULK_UPLOAD, BulkUploadAction,
    GetFundContactsAction, GET_FUND_CONTACTS,
    DownloadRequestsAction, DOWNLOAD_REQUESTS, SendBulkRequestsAction, SEND_BULK_REQUESTS, SendRequestAction,
    SEND_REQUEST, SEND_REQUEST_FROM_DATA_REPORTING
} from './actions';
import {
    bulkUpdateRequests, setManagerScreenRequests, setRequestors,
    setFundContacts, setRequests, setPendingRequests, setEditedRequest, toggleEditMode,
} from './actionCreators';
import { messages } from './constants';
import { callApplyFilters, clearChecked, setIsLoading } from '../Filters/actionCreators';
import { getUploadHistory, startBulkUpload } from '../BulkUpload/actionCreator';
import { REQUESTS_BULK_UPLOAD_NAME } from './constants';
import { UploadType } from '../BulkUpload/types';
import { FilterNames } from '../Filters/types';
import { closeModal } from '../Modals/General/actionCreators';
import { getFund } from '../Fund/actionCreators';

function* applyFilter(action: ApplyFilterAction) {
    try {
        yield put(initAction(action.type));
        yield put(setIsLoading(true));
        const response = yield requestsApi.getRequests(action.filter);
        const withScroll = action.filter.hasOwnProperty('scroller') && action.filter.scroller;
        yield put(setRequests(response.count, response.results, response.permissions, withScroll));

        yield put(setIsLoading(false));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* applyFilterManagerScreen(action: ApplyFilterManagerScreenAction) {
    try {
        yield put(initAction(action.type));
        const response = yield requestsApi.getActiveRequests(action.filter);
        const withScroll = action.filter.hasOwnProperty('scroller') && action.filter.scroller;

        yield put(setIsLoading(true));
        yield put(setManagerScreenRequests(response.count, response.results, withScroll));

        yield put(setIsLoading(false));
        const pendingRequests = yield requestsApi.getPendingRequests();
        yield put(setPendingRequests(pendingRequests.count));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* saveRequest(action: SaveEditedRequestAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();

        const requests = yield requestsApi.bulkSaveRequest(
            action.relations,
            action.request,
            action.withContacts,
            action.checkedAll,
            store.filters[FilterNames.requestsList],
        );
        yield put(bulkUpdateRequests(requests));

        if (requests.length === 1) {
            yield put(setEditedRequest(requests[0]));
        }

        yield put(toggleEditMode(false));

        yield put(doneActionSuccess(action.type, messages.SAVE_REQUEST_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.SAVE_REQUEST_FAIL_MESSAGE));
    }
}

function* cancelBulkUpload(action: CancelBulkUploadAction) {
    try {
        yield put(initAction(action.type));

        yield requestsApi.cancelUpload(action.payload);
        yield put(getUploadHistory(UploadType.Request));
        yield put(doneActionSuccess(action.type, messages.SAVE_REQUEST_SUCCESS_MESSAGE));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, messages.SAVE_REQUEST_FAIL_MESSAGE));
    }
}

function* getRequestors(action: GetRequestorsAction) {
    try {
        yield put(initAction(action.type));
        const response = yield requestsApi.getRequestsors(action.id);
        yield put(setRequestors(response.requests, action.id, action.status));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* importRequestsFiles(action: BulkUploadAction) {
    try {
        yield put(initAction(action.type));
        yield put(startBulkUpload(REQUESTS_BULK_UPLOAD_NAME));
        yield requestsApi.bulkUpload(action.files);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.UPLOAD_FILE_FAILED));
    }
}

function* downloadRequests(action: DownloadRequestsAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield requestsApi.exportFile(
            action.checkedIds,
            action.checkedAll,
            store.filters[FilterNames.requestsList]
        );

        yield put(doneActionSuccess(action.type));
        yield put(clearChecked(FilterNames.requestsList));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, bulkActionMessages.DOWNLOAD_FAILED));
    }
}

function* getFundContacts(action: GetFundContactsAction) {
    try {
        yield put(initAction(action.type));
        const response = yield fundApi.getContactsList(action.fundId);
        yield put(setFundContacts(response.results));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* sendBulkRequests(action: SendBulkRequestsAction) {
    try {
        yield put(initAction(action.type));
        const store = yield select();
        yield requestsApi.sendRequests(
            action.checkedIds,
            action.checkedAll,
            store.filters[FilterNames.requestsList]
        );
        yield delay(500);
        yield put(closeModal());

        yield put(callApplyFilters(FilterNames.requestsList, APPLY_FILTER));
        yield put(clearChecked(FilterNames.requestsList));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* sendRequest(action: SendRequestAction) {
    try {
        yield put(initAction(action.type));
        yield requestsApi.sendRequest(action.fundId);
        yield delay(500);
        yield put(closeModal());

        yield put(callApplyFilters(FilterNames.requestsList, APPLY_FILTER));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* sendRequestFromDataReporting(action: SendRequestAction) {
    try {
        yield put(initAction(action.type));
        yield requestsApi.sendRequest(action.fundId);
        yield delay(500);
        yield put(closeModal());

        yield put(getFund(action.fundId, true));
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* watchApplyFilter() {
    yield takeEvery(APPLY_FILTER, applyFilter);
}

function* watchApplyFilterManagerScreen() {
    yield takeEvery(APPLY_FILTER_MANAGER_SCREEN, applyFilterManagerScreen);
}

function* watchDownloadRequests() {
    yield takeEvery(DOWNLOAD_REQUESTS, downloadRequests);
}

function* watchCancelBulk() {
    yield takeEvery(CANCEL_BULK_UPLOAD, cancelBulkUpload);
}

function* watchBulkUpload() {
    yield takeLatest(BULK_UPLOAD, importRequestsFiles);
}

function* watchGetFundContacts() {
    yield takeLatest(GET_FUND_CONTACTS, getFundContacts);
}

function* watchGetRequestors() {
    yield takeEvery(GET_REQUESTORS, getRequestors);
}

function* watchSaveRequest() {
    yield takeLatest(SAVE_EDITED_REQUEST, saveRequest);
}

function* watchSendRequestsByIds() {
    yield takeLatest(SEND_BULK_REQUESTS, sendBulkRequests);
}

function* watchSendRequest() {
    yield takeLatest(SEND_REQUEST, sendRequest);
}

function* watchSendRequestFromDataReporting() {
    yield takeLatest(SEND_REQUEST_FROM_DATA_REPORTING, sendRequestFromDataReporting);
}

export default function* root() {
    yield all(
        [
            watchApplyFilter(),
            watchApplyFilterManagerScreen(),
            watchCancelBulk(),
            watchGetRequestors(),
            watchSaveRequest(),
            watchBulkUpload(),
            watchGetFundContacts(),
            watchDownloadRequests(),
            watchSendRequestsByIds(),
            watchSendRequest(),
            watchSendRequestFromDataReporting(),
        ]
    );
}