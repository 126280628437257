import { createSelector } from 'reselect';

import { FirmState } from './types';
import { initialState, initialDetailedFirmModel } from './constants';
import { Disclosures, FirmDetails, FirmModel, OwnerOfficer } from './models/firm';

import { joinListOfStrings } from 'helpers/tableHelper';
import NumericHelper from 'helpers/NumericInputHelper';
import { FormatTypeChoices, InfoTableTypeChoices, DEFAULT_CURRENCY_VALUE } from 'store/constants';

import { AppState } from 'store/types';
import { InfoTableData } from 'components/Shared/DataTable/InfoTable';

export const getFirmState = (state: AppState): FirmState => state.firms || initialState;
export const getSingleFirmState = (state: AppState): FirmModel =>
    state.firms && state.firms.firm
        ? state.firms.firm
        : initialDetailedFirmModel;

export const getFirmDetailsData = createSelector(
    getSingleFirmState,
    (firm: FirmModel): InfoTableData[] => {
        const firmDetails: FirmDetails = firm.firm_details;
        let firmDetailsData: InfoTableData[] = [];
        if (firmDetails) {
            firmDetailsData.push({header: 'LEI:', value: joinListOfStrings(firmDetails.lei)});
            firmDetailsData.push({header: 'SEC:', value: joinListOfStrings(firmDetails.sec)});
            firmDetailsData.push({header: 'CRD:', value: joinListOfStrings(firmDetails.crd)});
            firmDetailsData.push({header: 'Internal ID:', value: firm.internal_id});
            firmDetailsData.push({header: 'Address:', value: firmDetails.address});
            firmDetailsData.push({header: 'Website:', value: firmDetails.website, type: InfoTableTypeChoices.Link});
            firmDetailsData.push({header: 'Employees:', value: firmDetails.employees});
            // this value comes from ADV and currency should be USD
            firmDetailsData.push({
                header: 'RAUM:',
                value: NumericHelper.getFormattedNumeral(
                    firmDetails.raum,
                    DEFAULT_CURRENCY_VALUE,
                    true,
                    FormatTypeChoices.NumeralDefault,
                    ''
                )
            });
            firmDetailsData.push({header: 'Accounts:', value: firmDetails.accounts});
        }
        return firmDetailsData;
    }
);

export const getDisclosuresData = createSelector(
    getSingleFirmState,
    (firm: FirmModel): InfoTableData[] => {
        const disclosures: Disclosures = firm.disclosures;
        let disclosuresData: InfoTableData[] = [];
        if (disclosures) {
            disclosuresData.push({
                header: 'Felony Conviction:', value: disclosures.felony_conviction, type: InfoTableTypeChoices.Boolean,
            });
            disclosuresData.push({
                header: 'Felony Charge:', value: disclosures.felony_charge, type: InfoTableTypeChoices.Boolean,
            });
            disclosuresData.push({
                header: 'Misdemeanor Conviction:',
                value: disclosures.misdemeanor_conviction,
                type: InfoTableTypeChoices.Boolean,
            });
            disclosuresData.push({
                header: 'Misdemeanor Charge:', value: disclosures.misdemeanor_charge, type: InfoTableTypeChoices.Boolean
            });
            disclosuresData.push({
                header: 'False Statement:', value: disclosures.false_statement, type: InfoTableTypeChoices.Boolean,
            });
            disclosuresData.push({
                header: 'SEC/CFTC Violation:', value: disclosures.sec_cftc_violation, type: InfoTableTypeChoices.Boolean
            });
            disclosuresData.push({
                header: 'Regulatory Violation:',
                value: disclosures.regulatory_violation,
                type: InfoTableTypeChoices.Boolean,
            });
            disclosuresData.push({
                header: 'Registration Suspension:',
                value: disclosures.registration_suspension,
                type: InfoTableTypeChoices.Boolean,
            });
        }
        return disclosuresData;
    }
);

export const getOwnersOfficers = createSelector(
    getSingleFirmState,
    (firm: FirmModel): OwnerOfficer[] => firm.owners_officers
);
