import { RedirectState } from './types';
import { initialState } from './constants';
import { SET_REDIRECT, CLEAR_REDIRECT, RedirectAction } from './actions';

export const redirectReducer = (state: RedirectState = initialState, action: RedirectAction) => {
    switch (action.type) {
        case SET_REDIRECT:
            const { name, params } = action;
            return {
                [name]: params
            };
        case CLEAR_REDIRECT:
            return initialState;
        default:
            return state;
    }
};