import * as React from 'react';

import { InvitedUser } from 'store/Shared/models/invitedUser';

import InviteUserForm from './InviteUserForm';
import ConfirmModal from './ConfirmModal';
import { SelectItem } from '../../../store/types';

interface Props {
    isOpen: boolean;
    title: React.ReactNode;
    toggle: () => void;
    invitedUser: InvitedUser;
    change: (field: string, value: string) => void;
    save: () => void;
    roles: SelectItem[];
    roleIsPossible?: boolean;
}

class InviteUserModal extends React.PureComponent<Props> {
    static defaultProps = {
        roleIsPossible: true
    };

    render() {
        const {isOpen, title, toggle, invitedUser, change, save, roles, roleIsPossible} = this.props;
        const body = (
            <InviteUserForm
                invitedUser={invitedUser}
                roles={roles}
                roleIsPossible={roleIsPossible}
                change={change}
            />
        );
        return (
            <ConfirmModal
                isOpen={isOpen}
                toggle={toggle}
                title={title}
                yesTitle="Add User"
                noTitle="Cancel"
                body={body}
                chooseYes={save}
                chooseNo={toggle}
            />
        );
    }
}

export default InviteUserModal;