import {
    AddItemsModalAction, SET_FUND_LIST, SET_FUND_LIST_WITH_SCROLL,
    SET_PEER_GROUP_LIST, SET_PEER_GROUP_LIST_WITH_SCROLL,
    SetFundListForAddItemsModalAction, SetPeerGroupListForAddItemsModalAction
} from './actions';
import { AddItemsModalState } from './types';
import { initialState } from './constants';

export const addItemsModalReducer = (state: AddItemsModalState = initialState, action: AddItemsModalAction) => {
    switch (action.type) {
        case SET_FUND_LIST: {
            const {count, list} = (<SetFundListForAddItemsModalAction> action);
            return {
                ...state,
                funds: {
                    ...state.funds,
                    list,
                    count,
                }
            };
        }
        case SET_FUND_LIST_WITH_SCROLL: {
            const list = [
                ...state.funds.list,
                ...(<SetFundListForAddItemsModalAction> action).list
            ];
            return {
                ...state,
                funds: {
                    ...state.funds,
                    list,
                }
            };
        }
        case SET_PEER_GROUP_LIST: {
            const {count, list} = (<SetPeerGroupListForAddItemsModalAction> action);
            return {
                ...state,
                peerGroups: {
                    ...state.peerGroups,
                    list,
                    count,
                }
            };
        }
        case SET_PEER_GROUP_LIST_WITH_SCROLL: {
            const list = [
                ...state.peerGroups.list,
                ...(<SetPeerGroupListForAddItemsModalAction> action).list
            ];
            return {
                ...state,
                peerGroups: {
                    ...state.peerGroups,
                    list,
                }
            };
        }
        default:
            return state;
    }
};
