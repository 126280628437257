import * as React from 'react';
import * as classNames from 'classnames';
import DataEntryCircle from 'components/Shared/DataTable/Cells/DataEntryCircle';
import { DataEntry } from 'store/Fund/types';
import { Account } from 'store/Account/models/account';
import { DATA_ENTRIES } from 'store/Fund/constants';
import { isRequestorAccount } from 'store/User/helpers';

interface Props {
    title: string;
    value?: boolean;
    withLabel?: boolean;
    handleChange: (checked: boolean) => void;
    account?: Account;
}

const styles = require('./DottedToggleSwitch.scss');
const DottedToggleSwitch: React.FC<Props> = ({title, value, withLabel, handleChange, account}) => {
    const ClassNames = classNames({
        [styles.ui_toggle_switch]: !!styles.ui_toggle_switch,
        [styles.container]: true,
    });

    return (
        <div className={ClassNames}>
            <label className="switch">
                <input
                    type="checkbox"
                    onChange={(event: any) => handleChange(event.target.checked)}
                    checked={value}
                />
                <span className="slider round"/>
            </label>
            <div className={styles.title}>
                <span>{withLabel && title}</span>
            </div>
            {
                value &&
                <div className={styles.dots}>
                    <div className={styles['dot-wrapper']}>
                        <DataEntryCircle dataEntry={DataEntry.FundManager} value="FundManager"/>
                        <span className={styles['dot-label']}>{DATA_ENTRIES[DataEntry.FundManager]}</span>
                    </div>
                    <div className={styles['dot-wrapper']}>
                        <DataEntryCircle dataEntry={DataEntry.AlphaPipe} value="Alphapipe"/>
                        <span className={styles['dot-label']}>{DATA_ENTRIES[DataEntry.AlphaPipe]}</span>
                    </div>
                    {
                        isRequestorAccount(account) &&
                        <div className={styles['dot-wrapper']}>
                            <DataEntryCircle dataEntry={DataEntry.Requestor} value="Requestor"/>
                            <span className={styles['dot-label']}>
                                {account && account.name || DATA_ENTRIES[DataEntry.Requestor]}
                            </span>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

DottedToggleSwitch.defaultProps = {
    value: false
};

export default DottedToggleSwitch;
