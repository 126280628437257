import * as React from 'react';
import memoize from 'memoize-one';

import { FilterDecoratorChange } from 'decorators/FilterDecorator';
import UiDataTable from 'components/Shared/Ui/Tables/UiDataTable';
import DataTable from 'components/Shared/DataTable/DataTable';
import ButtonPrimary from 'components/Shared/Ui/Buttons/ButtonPrimary';
import FirmFundNamesCell from 'components/Shared/DataTable/Cells/FirmFundNames';
import UserNameCell from 'components/Shared/DataTable/Cells/UserName';
import UserEmailCell from 'components/Shared/DataTable/Cells/UserEmail';
import AclUserRoleEditor from 'components/DataManager/TableCells/AclUserRoleEditor';
import AclUserRoleView from 'components/DataManager/TableCells/AclUserRoleView';

import { AclUser } from 'store/DataManager/models/aclUser';
import { Id, TableHeader, TableRowAction } from 'store/types';
import { Alignment, CellTypeChoices, TableRowActionIcon, TableRowActionType } from 'store/constants';
import { FundModel } from 'store/Fund/models/fund';
import { AclRole } from 'store/DataManager/types';
import ActiveUser from 'store/Auth/models/activeUser';
import { FiltersState } from 'store/Filters/types';

const styles = require('./AclUsers.scss');

export interface Props {
    fund?: FundModel;
    user?: ActiveUser;
    results: AclUser[];
    count: number;
    currentFilter: FiltersState;
    handleChange: FilterDecoratorChange;
    isLoading: boolean;

    editIsPossible: boolean;
    inviteIsPossible: boolean;
    openInviteModal: () => void;
    remove: (id: Id, checkedIds: Id[]) => void;
    changeRoles: (id: Id, checkedIds: Id[], role: AclRole) => void;

    checkedAll: boolean;
    checkedIds: Id[];
    chooseAll: () => void;
    chooseRow: (id: Id) => void;
    withCheckboxes: boolean;
    withFund?: boolean;
}

class AclUsers extends React.PureComponent<Props> {
    getHeaders = memoize((withFund, editIsPossible, changeRoles, checkedIds, isCurrentUserRow): TableHeader[] => {
        const headers = [{
            name: 'user.name',
            title: 'Name',
            active: true,
            cellType: CellTypeChoices.Custom,
            transformer: row => <UserNameCell user={row.user} />
        }, {
            name: 'user.email',
            title: 'Email',
            active: true,
            cellType: CellTypeChoices.Custom,
            transformer: row => <UserEmailCell user={row.user} />
        }, {
            name: 'user.role',
            title: 'Role',
            active: true,
            cellType: CellTypeChoices.Custom,
            transformer: (row: AclUser) => {
                return editIsPossible && !isCurrentUserRow(row)
                    ? (
                        <AclUserRoleEditor
                            aclUser={row}
                            change={(role: AclRole) => changeRoles(row.id, checkedIds, role)}
                        />
                    )
                    : <AclUserRoleView aclUser={row} />;
            }
        }];

        if (withFund) {
            return [
                {
                    name: 'fund.name',
                    title: 'Firm\nFund',
                    headerAlignment: Alignment.Left,
                    active: true,
                    cellType: CellTypeChoices.Custom,
                    transformer: ({fund}) => {
                        if (fund) {
                            return <FirmFundNamesCell firm={fund.firm ? fund.firm.name : ''} fund={fund.name} />;
                        }
                        return <></>;
                    }

                },
                ...headers
            ];
        } else {
            return headers;
        }
    });

    getRowActions = memoize((editIsPossible, remove, isCurrentUserRow): TableRowAction[] => {
        if (editIsPossible) {
            return [];
        } else {
            return [
                {
                    type: TableRowActionType.Image,
                    icon: TableRowActionIcon.BigTrash,
                    handler: (id: Id) => this.props.remove(id, this.props.checkedIds),
                    isVisible: (row: AclUser) => !isCurrentUserRow(row)
                },
            ];
        }
    });

    private static getCustomRowStyles = (row: AclUser) => {
        if (row.role === AclRole.NoAccess) {
            return ['highlited'];
        }

        return [];
    }

    isCurrentUserRow = (row: AclUser) => {
        return this.props.user && this.props.user.id === row.user.id;
    }

    render() {
        const {
            results, count, handleChange, currentFilter, remove,
            checkedAll, checkedIds, chooseAll, chooseRow, changeRoles,
            openInviteModal, inviteIsPossible, editIsPossible, withCheckboxes
        } = this.props;

        return (
            <div className={styles.wrapper}>
                {inviteIsPossible &&
                    <div className={styles.buttons}>
                        <ButtonPrimary className="float-right ml-3" inverse={true} handleClick={openInviteModal}>
                            Invite new user...
                        </ButtonPrimary>
                        <div className="clearfix" />
                    </div>
                }

                <div>
                    <UiDataTable>
                        <DataTable
                            rows={results}
                            headers={this.getHeaders(
                                this.props.withFund,
                                editIsPossible,
                                changeRoles,
                                checkedIds,
                                this.isCurrentUserRow,
                            )}
                            handleChange={handleChange}
                            hasPagination={false}
                            hasSorting={false}
                            currentFilter={currentFilter}
                            withCheckboxes={withCheckboxes}
                            rowActions={this.getRowActions(editIsPossible, remove, this.isCurrentUserRow)}
                            isLoading={false}
                            count={count}
                            checkedAll={checkedAll}
                            checkedIds={checkedIds}
                            chooseAll={chooseAll}
                            chooseRow={chooseRow}
                            getCustomRowStyles={AclUsers.getCustomRowStyles}
                            showRowActionsTitle={true}
                            actionsTitle={' '}
                        />
                    </UiDataTable>
                </div>
            </div>
        );
    }
}

export default AclUsers;