import * as React from 'react';
import * as classNames from 'classnames';
import { HeaderTypes } from './HeaderTypes';
import HeaderFilterPanel from '../../Filters/HeaderFilterPanel';

const styles = require('./MainHeader.scss');
class MainHeader extends React.PureComponent<HeaderTypes> {

    render() {
        const {children, title, className, inverse, filters, currentFilter, handleChange, account} = this.props;

        const classes = classNames({
            'ui-header': true,
            [className]: !!className,
            [styles.ui_main_header]: !!styles.ui_main_header,
            [styles.ui_main_header_inverse]: inverse,
        });

        let renderContent: React.ReactNode = '';

        if (title) {
            renderContent = (
                <div className={classes}>
                    {children}
                    <div className={styles['header-title-and-filters']}>
                        <div className={styles['header-title']}>{title}</div>
                        <HeaderFilterPanel
                            account={account}
                            filters={filters}
                            currentFilter={currentFilter}
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            );
        }

        return renderContent;
    }
}

export default MainHeader;