import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { Id } from 'store/types';

interface Props {
    firm: string;
    fund: string;
    link?: Id;
}

const styles = require('./FirmFundNames.scss');

const FirmFundNames: React.FC<Props> = ({firm, fund, link}) => {
    const fundName = fund.toUpperCase();
    const fundNameBlock = link
        ? <NavLink to={link}>{fundName}</NavLink>
        : fundName;

    return (
        <div className={styles.cellWrapper}>
            <span className={styles.firm}>{firm}</span>
            <br/>
            <span className={styles.fund}>
                {fundNameBlock}
            </span>
        </div>
    );
};

export default FirmFundNames;
