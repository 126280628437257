import * as React from 'react';
import { ReactElement, RefObject } from 'react';
import { Table } from 'reactstrap';
import * as classNames from 'classnames';
import { ScrollStatus } from 'smooth-scrollbar/interfaces';

import { DEFAULT_ITEMS_PER_PAGE, FixedElementType, ScrollableElementType } from 'store/constants';
import { FilterDecoratorChange } from 'decorators/FilterDecorator';
import TableRowHelper from 'helpers/tableRowHelper';
import GlobalHelper from 'helpers/globalHelper';
import { sendResizeWindowEvent } from 'helpers/event';
import { AdvancedFilterItem, FilterItem, Id, TableBulkAction, TableHeader, TableRowAction } from 'store/types';

import Loader from '../Ui/Loader';
import TableFilterPanel from '../Panels/TableFilterPanel';
import ColoredScrollbar from '../Scroll/ColoredScrollbar';
import { Chip, FiltersState } from 'store/Filters/types';
import DataTableHeader from './DataTableHeader';
import DataTableHeaderScrollbar from './DataTableHeaderScrollbar';
import { checkboxColumnWidth, defaultFixedWidth } from './constants';
import DataTableNoDataRow from './DataTableNoDataRow';
import DataTableRow from './DataTableRow';
import { ID_FOR_NEW_ROW } from 'store/Fund/constants';
import {
    AdvancedFilterDecoratorSearch,
    AdvancedFilterDecoratorToggle,
    AdvancedFilterDecoratorUpdate
} from 'decorators/AdvancedFilterDecorator';
import FilterPanel from 'components/Shared/Filters/FilterPanel';
import { FilterEntity } from 'store/Diligence/DiligenceRequest/types';

const styles = require('./DataTable.scss');
const columnStyle = require('./DataColumn.scss');
export const SCROLL_DIFF_THRESHOLD = 2;

interface Props {
    // filters
    filters?: FilterItem[];
    buttons?: React.ReactNode[];
    customContent?: JSX.Element | string;
    // used both for sorting and filters
    currentFilter?: any;
    disabledFilter?: boolean;
    //
    rows: any[];
    count?: number;
    headers: TableHeader[];
    visibleHeaders?: TableHeader[];
    isHeaderFixed?: boolean;
    // -- function to apply filters (pagination, filters or sorting) --
    handleChange?: FilterDecoratorChange;
    // -- pagination ---------
    hasPagination: boolean;
    isLoading?: boolean;
    // -- sorting parameters -
    hasSorting: boolean;
    // -- bulk actions -------
    withCheckboxes: boolean;
    disabledCheckboxes?: boolean;
    bulkActions?: TableBulkAction[];
    checkedAll?: boolean;
    checkedIds?: Id[];
    chooseAll?: () => void;
    chooseRow?: (id: Id) => void;
    highlightHeaderOnCheck?: boolean;
    textForBulkActions?: string | JSX.Element;
    // -- row actions --------
    rowActions?: TableRowAction[];
    // -- inline changes -----
    editingRowNumber?: number;
    editingRow?: any;
    handleColumnChange?: (value: string, name: string, row: number) => void;
    handleOnBlurInput?: (row: any, rowIdx: number, field: string) => void;
    handleOnFocusInput?: (id: Id) => void;
    isGridLoading?: boolean;

    handleClickDispatcher?: any;
    portfolioId?: any;
    checkedIdsString?: string;

    customDataNotFound?: any;
    withoutNoDataFound?: boolean;
    getCustomRowStyles?: (row: any) => string[];

    handleSetWidthHeaders?: (headerObject: any) => void;
    useColgroup?: boolean;
    actionsTitle?: string;
    excludeSorting?: boolean;
    orderingName?: string;
    useColoredScrollBar?: boolean;
    showRowActionsTitle?: boolean;
    filterPageName?: string;
    // -- chip property --------
    chips?: Chip[];
    scrollableHeader?: boolean;
    scrollClassName?: string;
    onScroll?: (status: ScrollStatus) => void;
    scrollableElementClassName?: string;
    possibleFixedElementClassName?: string;
    fixedElementClassName?: string;
    fixedElementType?: FixedElementType;
    scrollableElementType?: ScrollableElementType;
    expandableContent?: (row: any) => ReactElement<HTMLTableRowElement> | null;

    FooterRow?: React.ComponentClass<{}> | React.FC<{}>;

    searchFilterEntities?: FilterEntity[];
    /* ADVANCED FILTERS */
    handleToggleModal?: AdvancedFilterDecoratorToggle;
    handleUpdateModal?: AdvancedFilterDecoratorUpdate;
    handleAttributeSearch?: AdvancedFilterDecoratorSearch;
    isFilterModalOpen?: boolean;
    advancedFilters?: AdvancedFilterItem[];
    currentModalFilter?: FiltersState;
}

class DataTable extends React.PureComponent<Props> {
    static defaultProps = {
        disabledFilter: false,
        scrollableHeader: false,
        expandableContent: (row: any) => null,
        scrollableElementClassName: 'j-scroll',
        possibleFixedElementClassName: 'j-filter',
        fixedElementClassName: 'j-fixed',
        fixedElementType: FixedElementType.TableFilter,
        scrollableElementType: ScrollableElementType.FullPage,
        highlightHeaderOnCheck: true,
        isHeaderFixed: true,
        withCheckboxes: false,
    };

    scrollableElementClassName: string;
    possibleFixedElementClassName: string;
    fixedElementClassName: string;
    fixedElementType: FixedElementType;
    scrollableElementType: ScrollableElementType;
    scrollableElement: Element | null;

    fixedElementTopPosition: number;
    fixedBlockHeight: number;
    scrollTopDiff: number;

    mainHeaderRef: RefObject<HTMLTableRowElement>;
    fakeHeaderRef: RefObject<HTMLTableRowElement>;
    headerScrollbarRef: RefObject<DataTableHeaderScrollbar>;

    mainTableWrapperElement: HTMLElement | null;
    fakeTableWrapperElement: HTMLElement | null;
    highlightHeaderOnCheck: boolean;
    withCheckboxes: boolean;
    state = {
        isLoading: false,
        selectedRows: [],
        scrollbarWidth: 0,
        isFixed: false,
    };

    constructor(props: Props) {
        super(props);

        this.scroller = this.scroller.bind(this);
        this.horizontalScroller = this.horizontalScroller.bind(this);

        this.mainHeaderRef = React.createRef();
        this.fakeHeaderRef = React.createRef();
        this.headerScrollbarRef = React.createRef();
        this.scrollableElementClassName = this.props.scrollableElementClassName
            || DataTable.defaultProps.scrollableElementClassName;
        this.possibleFixedElementClassName = this.props.possibleFixedElementClassName
            || DataTable.defaultProps.possibleFixedElementClassName;
        this.fixedElementClassName = this.props.fixedElementClassName || DataTable.defaultProps.fixedElementClassName;
        this.fixedElementType = this.props.fixedElementType || DataTable.defaultProps.fixedElementType;
        this.scrollableElementType = this.props.scrollableElementType || DataTable.defaultProps.scrollableElementType;
        this.highlightHeaderOnCheck = this.props.highlightHeaderOnCheck !== undefined
            ? this.props.highlightHeaderOnCheck
            : DataTable.defaultProps.highlightHeaderOnCheck;

        this.withCheckboxes = this.props.withCheckboxes &&
            (!!this.props.bulkActions && !!this.props.bulkActions.filter(action => action.isVisible).length
                || !this.props.bulkActions);
    }

    get widthFixedColumns() {
        const {headers} = this.props;
        let width = 0;

        for (let i = 0; i < headers.length; i++) {
            if (headers[i] && headers[i].hasOwnProperty('fixed') && headers[i].fixed) {
                width += headers[i].minWidth || defaultFixedWidth;
            } else {
                break;
            }
        }

        if (width === 0) {
            return width;
        }

        return width + (this.withCheckboxes ? checkboxColumnWidth : 0);
    }

    get countFixedColumns() {
        const {headers} = this.props;
        let count = 0;

        for (let i = 0; i < headers.length; i++) {
            if (headers[i] && headers[i].hasOwnProperty('fixed') && headers[i].fixed) {
                count++;
            } else {
                break;
            }
        }

        if (count === 0) {
            return count;
        }

        return count + +this.withCheckboxes;
    }

    get isAnyIdChecked() {
        const {checkedAll, checkedIds, count} = this.props;
        return (!checkedAll && checkedIds && checkedIds.length !== 0) ||
            (checkedAll && checkedIds && checkedIds.length !== count) ||
            false;
    }

    get highlightHeader() {
        return this.highlightHeaderOnCheck;
    }

    get isScrollableHeader() {
        return this.props.scrollableHeader;
    }

    scroller(e: any) {
        if (!this.props.hasPagination) {
            return;
        }
        const wrapper = e.target;
        const fixedBlock = this.props.isHeaderFixed &&
            wrapper.querySelector(`.${this.possibleFixedElementClassName}`);

        if (fixedBlock) {
            const scrollTop = wrapper.scrollTop;
            if (!this.fixedElementTopPosition) {
                // calculate height from the 'top border of possibly fixed element' to the 'top border of the page'
                // when it's calculated on componentDidMount event - the value is incorrect
                const fixedBoundingBox = fixedBlock.getBoundingClientRect();
                const fixedTopHeight = fixedBoundingBox.top;
                this.fixedElementTopPosition = fixedTopHeight + scrollTop;
                this.fixedBlockHeight = fixedBoundingBox.height;
            }

            if (
                !GlobalHelper.elementHasClass(fixedBlock, this.fixedElementClassName)
                && scrollTop > this.fixedElementTopPosition
            ) {
                fixedBlock.classList.add(this.fixedElementClassName);
                this.scrollTopDiff = scrollTop - wrapper.scrollTop;
                this.setState({
                    scrollbarWidth: GlobalHelper.getWidthOfScrollBar(),
                    isFixed: true
                });
                // add padding to the UiDataTable in order to account for it jumping to the top of the window
                fixedBlock.parentElement.style.paddingTop = `${this.fixedBlockHeight}px`;
            } else if (
                GlobalHelper.elementHasClass(fixedBlock, this.fixedElementClassName)
                && scrollTop + this.scrollTopDiff < this.fixedElementTopPosition
                // && this.scrollableElementType === ScrollableElementType.FullPage
            ) {
                fixedBlock.classList.remove(this.fixedElementClassName);
                this.setState({
                    scrollbarWidth: this.fixedElementType === FixedElementType.TableHeader
                        ? GlobalHelper.getWidthOfScrollBar()
                        : 0,
                    isFixed: this.fixedElementType === FixedElementType.TableHeader || false
                });
                // remove the padding when the fixed block is not fixed
                fixedBlock.parentElement.style.paddingTop = `0`;
            }
        }

        const diffHeight = wrapper.scrollHeight - wrapper.scrollTop - wrapper.clientHeight;

        const limit = this.props.currentFilter ? this.props.currentFilter.limit : 0;
        const offset = this.props.currentFilter ? this.props.currentFilter.offset : 0;
        const sum = limit + offset;

        // Calculating of scrollHeight, scrollTop and clientHeight is performed with an error
        // in measuring (+/-1px) during the zoom in and out in different browsers due to subpixel co-ordinates.
        // As the solution, SCROLL_DIFF_THRESHOLD is used.
        if (this.props.handleChange && Math.abs(diffHeight) <= SCROLL_DIFF_THRESHOLD
            && !(this.props.count !== undefined && this.props.count !== null && this.props.count <= sum)
            &&  !this.state.isLoading
        ) {
            this.props.handleChange({ scroller: true, offset: DEFAULT_ITEMS_PER_PAGE });

            if (!GlobalHelper.isIE() && wrapper) {
                wrapper.scrollTop -= 5;
            }

            this.setState({isLoading: true});
        }
    }

    horizontalScroller(e: any) {
        if (this.isScrollableHeader && this.headerScrollbarRef && this.headerScrollbarRef.current) {
            if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
                e.preventDefault();
                this.headerScrollbarRef.current.handleHorizontalScroll(e.deltaX);
            }
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isLoading !== this.props.isLoading
        ) {
            this.setState({isLoading: nextProps.isLoading});
        }
    }

    componentDidMount() {
        const document: Document = window.document;
        this.scrollableElement = document.querySelector(`.${this.scrollableElementClassName}`);
        if (this.scrollableElement) {
            this.scrollableElement.addEventListener('scroll', this.scroller);
        }
        this.setState({
            scrollbarWidth: GlobalHelper.getWidthOfScrollBar(),
            isFixed: this.fixedElementType === FixedElementType.TableHeader || false
        });

        (window as Window).addEventListener('resize', this.syncHeader);
        (window as Window).addEventListener('wheel', this.horizontalScroller, {passive: false});
        this.syncHeader();

        if (this.isScrollableHeader) {
            this.configureTableWrapperWidth();
        }

        this.syncRowHeight();

        this.mainTableWrapperElement = this.getElementWrapper(this.mainHeaderRef.current);
        this.fakeTableWrapperElement = this.getElementWrapper(this.fakeHeaderRef.current);
        this.fixedElementTopPosition = 0;
        this.scrollTopDiff = 0;
    }

    componentDidUpdate() {
        if (this.isScrollableHeader) {
            this.syncHeader();
        }
        this.syncRowHeight();
    }

    componentWillUnmount() {
        (window as Window).removeEventListener('resize', this.syncHeader);
        (window as Window).removeEventListener('wheel', this.horizontalScroller);

        if (this.scrollableElement) {
            this.scrollableElement.scrollTop = 0;
            this.scrollableElement.removeEventListener('scroll', this.scroller);
        }
    }

    renderTableHeader(ref?: RefObject<HTMLTableRowElement>, countFixedColumns: number = 0) {
        const {
            currentFilter, headers, visibleHeaders, hasSorting, checkedIds, rowActions, checkedAll,
            count, actionsTitle, textForBulkActions, showRowActionsTitle, bulkActions, chooseAll,
            handleSetWidthHeaders, handleChange, handleClickDispatcher, highlightHeaderOnCheck, disabledCheckboxes
        } = this.props;

        const {isFixed} = this.state;

        return (
            <DataTableHeader
                isFixed={isFixed}

                currentFilter={currentFilter}
                headers={headers}
                countFixedColumns={countFixedColumns}
                visibleHeaders={visibleHeaders}
                hasSorting={hasSorting}
                checkedIds={checkedIds}
                withCheckboxes={this.withCheckboxes}
                disabledCheckboxes={disabledCheckboxes}
                rowActions={rowActions}
                checkedAll={checkedAll}
                highlightHeaderOnCheck={highlightHeaderOnCheck}
                count={count}
                actionsTitle={actionsTitle}
                textForBulkActions={textForBulkActions}
                showRowActionsTitle={showRowActionsTitle}
                bulkActions={bulkActions}

                chooseAll={chooseAll}
                handleSetWidthHeaders={handleSetWidthHeaders}
                handleChange={handleChange}
                handleClickDispatcher={handleClickDispatcher}

                isMainHeader={ref === this.mainHeaderRef}
                forwardedRef={ref}

                possibleFixedElementClassName={`${this.possibleFixedElementClassName} ` +
                    `${ref ? styles['header-on-top'] : ''}`}
                fixedElementType={this.fixedElementType}

                editingRowCount={(this.props.editingRow && this.props.editingRow.id === ID_FOR_NEW_ROW) && 1 || 0}
            />
        );
    }

    render() {
        sendResizeWindowEvent();

        const {
            headers, rows, checkedIds, rowActions, checkedAll,
            filters, buttons, chips, currentFilter, handleChange, filterPageName, scrollableHeader, scrollClassName,
            FooterRow, handleAttributeSearch, handleUpdateModal, handleToggleModal, isFilterModalOpen, advancedFilters,
            searchFilterEntities, currentModalFilter, disabledCheckboxes, disabledFilter
        } = this.props;

        const expandableContent = this.props.expandableContent || DataTable.defaultProps.expandableContent;

        const colSpanForNoData = (this.withCheckboxes && !rowActions || rowActions && !this.withCheckboxes)
            ? headers.length + 1 : (this.withCheckboxes && rowActions
                ? headers.length + 2 : headers.length);

        const classesForFakeTableHeader = classNames({
            'ap-data-table': true,
            [styles.tableGrid]: true,
        });

        const classesForFakeWrapper = classNames({
            'fake-header': true,
            'invisible': !this.state.isFixed,
            'fake-header-fixed': this.fixedElementType === FixedElementType.TableHeader,
        });

        const classesForTableContent = classNames({
            'ap-data-table': true,
            [styles.tableGrid]: true,
            [styles.scrollableHeader]: this.props.scrollableHeader,
        });

        let mainTableStyle = {}, fakeTableStyle = {};

        const widthFixedColumns = this.widthFixedColumns,
            countFixedColumns = this.countFixedColumns;
        if (widthFixedColumns > 0) {
            mainTableStyle = {
                ...mainTableStyle,
                marginLeft: `${widthFixedColumns}px`
            };
            if (!this.isAnyIdChecked) {
                fakeTableStyle = {
                    ...fakeTableStyle,
                    marginLeft: `${widthFixedColumns}px`,
                    width: '100%'
                };
            }
        }

        let filterPanelContent: JSX.Element | string = (
            <>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <FilterPanel
                            filters={filters}
                            currentFilter={currentFilter}
                            handleChange={handleChange}
                            handleToggleModal={handleToggleModal}
                            handleUpdateModal={handleUpdateModal}
                            handleAttributeSearch={handleAttributeSearch}
                            chips={chips}
                            disabled={disabledFilter}
                            filterPageName={filterPageName}
                            isFilterModalOpen={isFilterModalOpen}
                            advancedFilters={advancedFilters}
                            searchFilterEntities={searchFilterEntities}
                            currentModalFilter={currentModalFilter}
                        />
                    </div>
                </div>
                {
                    this.props.buttons &&
                    <div className="row">
                        <div className="col-lg-12 col-md-12 pb-3">
                            {this.props.buttons
                                .filter(button => !!button)
                                .map((button, idx) => (
                                <div key={idx} className="ml-1 button-item">
                                    {button}
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </>
        );

        let customContent: JSX.Element | string = '';
        if (this.props.customContent) {
            customContent = this.props.customContent;
        }

        const classNamesForNoData = classNames({
            [styles['no-data-with-fixed-columns']]: countFixedColumns > 0,
        });

        const coloredScrollClass = scrollClassName ? scrollClassName : 'data-table-scroll';
        const tableClassName = classNames({
            [this.scrollableElementClassName]: this.scrollableElementType === ScrollableElementType.TableBody,
            'ui-table--responsive': true,
        });

        return (
            <>
                <TableFilterPanel
                    scrollbarWidth={this.state.scrollbarWidth}
                    isFixed={this.state.isFixed}
                    initialMargin={this.props.fixedElementType !== FixedElementType.TableFilter ? 0 : undefined}
                    possibleFixedElementClassName={this.possibleFixedElementClassName}
                    fixedElementType={this.fixedElementType}
                >
                    {(filters || buttons) && filterPanelContent}
                    {customContent}

                    {scrollableHeader &&
                        <DataTableHeaderScrollbar
                            ref={this.headerScrollbarRef}
                            headers={headers}
                            countFixedColumns={countFixedColumns}
                            isAnyIdChecked={this.isAnyIdChecked}
                            withCheckboxes={this.withCheckboxes}
                            widthFixedColumns={widthFixedColumns}
                            mainHeaderRef={this.mainHeaderRef}
                            fakeHeaderRef={this.fakeHeaderRef}
                            mainTableWrapperElement={this.mainTableWrapperElement}
                            fakeTableWrapperElement={this.fakeTableWrapperElement}
                            verticalScrollbarWidth={this.state.scrollbarWidth}
                        />
                    }
                    {
                        this.props.isHeaderFixed &&
                        <div className={classesForFakeWrapper}>
                            <Table
                                responsive={true}
                                hover={true}
                                className={classesForFakeTableHeader}
                                style={fakeTableStyle}
                            >
                                {this.renderTableHeader(
                                    this.fakeHeaderRef,
                                    (this.isAnyIdChecked ? 0 : countFixedColumns)
                                )}
                            </Table>
                        </div>
                    }
                </TableFilterPanel>

                <div
                    className={this.props.hasPagination ? styles.scroller : ''}
                >
                    <ColoredScrollbar
                        className={coloredScrollClass}
                        alwaysShowTracks={false}
                        ignore={!this.props.useColoredScrollBar}
                        onScroll={this.props.onScroll}
                    >
                        <div className={tableClassName} style={{position: 'relative'}}>
                            {
                                this.isAnyIdChecked && this.highlightHeader &&
                                // This header will cover main table's header if some checkboxes are checked
                                <Table
                                    responsive={true}
                                    hover={true}
                                    className={classesForTableContent}
                                    style={{position: 'absolute'}}
                                >
                                    {this.renderTableHeader()}
                                </Table>
                            }

                            <Table
                                responsive={true}
                                hover={true}
                                className={classesForTableContent}
                                style={mainTableStyle}
                            >
                                {this.renderTableHeader(this.mainHeaderRef, countFixedColumns)}

                                {
                                    this.props.isGridLoading && !this.state.isLoading &&
                                    <tbody className="j-body_items">
                                        <tr>
                                            <td colSpan={colSpanForNoData}>
                                                <Loader
                                                    className={styles.loader_in_table}
                                                    isLoading={this.props.isGridLoading}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                                {
                                    this.props.useColgroup &&
                                    <colgroup>
                                        {this.withCheckboxes && <col className="checkboxHeader"/>}
                                        {
                                            headers.map((header: TableHeader, idx: number) => {
                                                if (!header.active) {
                                                    return null;
                                                }

                                                const thClass = header.alias ? columnStyle[header.alias] : '';
                                                const columnClassName = classNames({
                                                    [header.class]: !!header.class,
                                                    [thClass]: true,
                                                });

                                                return (<col key={idx} className={columnClassName}/>);
                                            })
                                        }
                                        {
                                            rowActions && <col className="action-cell"/>
                                        }
                                    </colgroup>
                                }
                                {
                                    rows && rows.length > 0 &&
                                    rows.map((row, rowIdx) => {
                                        if (!this.props.isGridLoading && row.rowType) {
                                            return TableRowHelper.getCustomRow(
                                                row.rowType,
                                                row,
                                                rowIdx,
                                                { visibleHeaders: this.props.visibleHeaders },
                                                this.props.getCustomRowStyles,
                                                rowActions
                                            );
                                        }

                                        let className = '';

                                        if (typeof this.props.getCustomRowStyles === 'function') {
                                            className = this.props.getCustomRowStyles(row).join(' ');
                                        }

                                        const uniqueKey = row.id ? `-${row.id}` : '';

                                        let RowComponent = row.component || DataTableRow;

                                        return (
                                            <tbody key={`${rowIdx}${uniqueKey}`} className="j-body_items">
                                                <RowComponent
                                                    row={row}
                                                    rowIdx={rowIdx}
                                                    headers={headers}
                                                    visibleHeaders={this.props.visibleHeaders}
                                                    className={className}
                                                    countFixedColumns={countFixedColumns}
                                                    currentFilter={currentFilter}
                                                    withCheckboxes={this.withCheckboxes}
                                                    disabledCheckboxes={disabledCheckboxes}
                                                    isGridLoading={this.props.isGridLoading}
                                                    isLoading={this.state.isLoading}
                                                    hasSorting={this.props.hasSorting}
                                                    scrollableHeader={scrollableHeader}
                                                    checkedAll={checkedAll}
                                                    checkedIds={checkedIds}
                                                    chooseRow={this.props.chooseRow}
                                                    rowsCount={rows.length}
                                                    editingRowNumber={this.props.editingRowNumber}
                                                    editingRow={this.props.editingRow}
                                                    handleColumnChange={this.props.handleColumnChange}
                                                    handleOnFocusInput={this.props.handleOnFocusInput}
                                                    handleOnBlurInput={this.props.handleOnBlurInput}
                                                    expandableContent={expandableContent}
                                                    rowActions={rowActions}
                                                />
                                            </tbody>
                                        );
                                    })
                                }
                                {
                                    rows &&
                                    rows.length === 0 &&
                                    !this.props.isGridLoading &&
                                    !this.props.withoutNoDataFound &&
                                    <tbody className="j-body_items">
                                    <DataTableNoDataRow
                                        className={classNamesForNoData}
                                        colSpan={colSpanForNoData}
                                    />
                                    </tbody>
                                }
                                {
                                    this.props.customDataNotFound
                                }
                                {
                                    FooterRow &&
                                    <tbody className="j-body_items">
                                    {FooterRow && <FooterRow />}
                                    </tbody>
                                }
                            </Table>
                        </div>
                    </ColoredScrollbar>
                </div>
                <Loader className={styles.loader} isLoading={this.state.isLoading}/>
            </>
        );
    }

    private getElementWrapper(childEl: HTMLElement | null, wrapperClass: string = 'table-responsive') {
        if (childEl) {
            let element = childEl.parentElement;

            while (element && !element.classList.contains(wrapperClass)) {
                element = element.parentElement;
            }

            return element;
        }

        return null;
    }

    private configureTableWrapperWidth() {
        if (this.mainHeaderRef && this.mainHeaderRef.current) {
            let element = this.getElementWrapper(this.mainHeaderRef.current, 'ap-data-table');
            if (element) {
                const width = element.clientWidth - this.widthFixedColumns;
                element.style.width = `${width}px`;
            }
        }
    }

    private getTableWrapperWidth() {
        if (this.mainHeaderRef && this.mainHeaderRef.current) {
            let element = this.getElementWrapper(this.mainHeaderRef.current, 'table-responsive');
            return element ? element.clientWidth : 0;
        }

        return 0;
    }

    private syncHeader = () => {
        if (this.isScrollableHeader) {
            this.syncHeaderWidth();
            this.syncHeaderScrollbar();
        }

        this.syncColumnsWidth();
    }

    private syncColumnsWidth() {
        if (
            !this.isAnyIdChecked &&
            this.mainHeaderRef && this.mainHeaderRef.current &&
            this.fakeHeaderRef && this.fakeHeaderRef.current
        ) {
            const fakeCells = this.fakeHeaderRef.current.cells;
            Array.from(this.mainHeaderRef.current.cells)
                .forEach((th: HTMLTableHeaderCellElement, index: number) => {
                    if (fakeCells[index]) {
                        fakeCells[index].style.width = `${th.clientWidth}px`;
                    }
                });
        }
    }

    private syncHeaderWidth() {
        if (
            !this.isAnyIdChecked &&
            this.mainHeaderRef && this.mainHeaderRef.current &&
            this.fakeHeaderRef && this.fakeHeaderRef.current
        ) {
            const fakeHeaderWrapper = this.getElementWrapper(this.fakeHeaderRef.current, 'fake-header');
            if (fakeHeaderWrapper) {
                fakeHeaderWrapper.style.maxWidth = `${this.getTableWrapperWidth()}px`;
            }

            let element = this.getElementWrapper(this.fakeHeaderRef.current, 'ap-data-table');
            if (element) {
                element.style.width = `${this.mainHeaderRef.current.clientWidth}px`;
            }
        }
    }

    private syncHeaderScrollbar() {
        if (this.headerScrollbarRef && this.headerScrollbarRef.current) {
            this.headerScrollbarRef.current.syncScroll();
        }
    }

    private syncRowHeight() {
        let i, j;

        if (this.mainTableWrapperElement && this.props.scrollableHeader) {
            const rows = this.mainTableWrapperElement.getElementsByTagName('tr');
            for (i = 0; i < rows.length; i++) {
                const columns = rows[i].getElementsByTagName('td');

                let max = rows[i].offsetHeight;
                for (j = 0; j < Math.min(columns.length, this.countFixedColumns); j++) {
                    max = Math.max(max, columns[j].offsetHeight);
                }

                if (max > rows[i].offsetHeight) {
                    rows[i].style.height = `${max}px`;
                }

                // we have to set height for all fixed columns because they all have different height
                for (j = 0; j < Math.min(columns.length, this.countFixedColumns); j++) {
                    columns[j].style.height = `${rows[i].offsetHeight}px`;
                }
            }
        }
    }
}

export default DataTable;
