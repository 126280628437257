import { PortfolioState } from './types';
import {
    APPLY_FILTER,
    CHANGE_NEW_PORTFOLIO_FIELD,
    CHECK_IMPORT_PROGRESS,
    CLEAR_ADD_PORTFOLIO_MODAL,
    HIDE_BOTTOM_AREA,
    SET_NOT_FOUND_SOLUTIONS,
    SET_PORTFOLIO,
    SET_PORTFOLIOS,
    SET_PORTFOLIOS_WITH_SCROLL,
    SET_UPLOADED_STATS,
    SET_UPLOADED_ROWS,
    SET_UPLOADED_ROWS_WITH_SCROLL,
    UPDATE_REPROCESSED_ROW,
    ApplyFilterAction,
    ChangeNewPortfolioFieldAction,
    CheckImportProgressAction,
    HideBottomAreaAction,
    PortfolioAction,
    SetNotFoundSolutionsAction,
    SetPortfoliosAction,
    SetPortfolioAction,
    SetUploadedRowsAction,
    SetUploadedStatsAction,
    UpdateReprocessedRowAction,
    SET_UNCOMPLETED_PORTFOLIO_IMPORT,
    SetUncompletedPortfolioImportAction,
} from './actions';
import { initialState, initialPortfolioModel } from './constants';

export const portfolioReducer = (state: PortfolioState = initialState, action: PortfolioAction) => {
    switch (action.type) {
        case APPLY_FILTER:
            return {
                ...state,
                filter: (<ApplyFilterAction> action).filter
            };
        case CLEAR_ADD_PORTFOLIO_MODAL:
            return {
            ...state,
            newPortfolio: {
                ...initialPortfolioModel
            }
        };
        case CHANGE_NEW_PORTFOLIO_FIELD:
            const {fieldName, fieldValue} = (<ChangeNewPortfolioFieldAction> action);

            return {
                ...state,
                newPortfolio: {
                    ...state.newPortfolio,
                    [fieldName]: fieldValue
                }
            };
        case CHECK_IMPORT_PROGRESS:
            return {
                ...state,
                importProgress: (<CheckImportProgressAction> action).payload
            };

        case HIDE_BOTTOM_AREA:
            return {
                ...state,
                hideBottomArea: (<HideBottomAreaAction> action).flag
            };

        case SET_NOT_FOUND_SOLUTIONS:
            return {
                ...state,
                notFoundSolutions: (<SetNotFoundSolutionsAction> action).payload.results,
            };
        case SET_UPLOADED_ROWS:
            return {
                ...state,
                uploadedRows: (<SetUploadedRowsAction> action).payload.results,
                countUploadedRows: (<SetUploadedRowsAction> action).payload.count,
            };
        case SET_UPLOADED_STATS:
            return {
                ...state,
                uploadedStats: (<SetUploadedStatsAction> action).payload,
            };
        case SET_UPLOADED_ROWS_WITH_SCROLL:
            const uploadedRows = [
                ...(state.uploadedRows || []),
                ...(<SetUploadedRowsAction> action).payload.results
            ];
            return {
                ...state,
                uploadedRows,
            };
        case SET_PORTFOLIOS:
            return {
                ...state,
                count: (<SetPortfoliosAction> action).count,
                list: (<SetPortfoliosAction> action).portfolios
            };
        case SET_PORTFOLIOS_WITH_SCROLL:
            const list = [
                ...state.list,
                ...(<SetPortfoliosAction> action).portfolios
            ];
            return {
                ...state,
                list
            };
        case SET_PORTFOLIO:
            return {
                ...state,
                portfolio: (<SetPortfolioAction> action).portfolio
            };
        case UPDATE_REPROCESSED_ROW:
            const newUploadedRows = state.uploadedRows && state.uploadedRows.map((row: any) => {
                let rowToReturn = row;
                if (row.id === (<UpdateReprocessedRowAction> action).payload.id) {
                    rowToReturn = (<UpdateReprocessedRowAction> action).payload;
                }
                return rowToReturn;
            });
            return {
                ...state,
                uploadedRows: newUploadedRows,
            };
        case SET_UNCOMPLETED_PORTFOLIO_IMPORT:
            const {hasImport, portfolioId, importId} = (<SetUncompletedPortfolioImportAction> action);
            return {
                ...state,
                uncompletedImport: {
                    hasImport,
                    portfolioId,
                    importId
                }
            };
        default:
            return state;
    }
};
