import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../store/types';
import { resetPassword } from '../../store/Auth/actionCreators';
import ResetPasswordPage from '../../components/Auth/ResetPasswordPage';
import { getActionInfo } from '../../store/Actions/selectors';
import { RESET_PASSWORD } from '../../store/Auth/actions';
import { ActionInfo } from '../../store/Actions/types';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props {
    actionInfo: ActionInfo | undefined;
    resetPassword: (password: string, token: string, resetKey: string) => any;
}

interface State {
    confirmPasswordIsInvalid: boolean;
}

interface RouteProps {
    token: string;
    resetKey: string;
}

class ResetPassword extends React.PureComponent<Props & RouteComponentProps<RouteProps>> {
    state: State = {
        confirmPasswordIsInvalid: false
    };

    handleResetPassword = (password: string, confirmPassword: string) => {
        const {token, resetKey} = this.props.match.params;
        if (password !== confirmPassword) {
            this.setState({confirmPasswordIsInvalid: true});
        } else {
            this.props.resetPassword(password, token, resetKey);
        }
    }

    render() {
        const {actionInfo} = this.props;
        return (
            <ResetPasswordPage
                actionInfo={actionInfo}
                resetPassword={this.handleResetPassword}
                confirmPasswordIsInvalid={this.state.confirmPasswordIsInvalid}
            />
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    actionInfo: getActionInfo(state, {name: RESET_PASSWORD}),
});

const mapDispatchToProps = { resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));