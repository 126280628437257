import { createSelector } from 'reselect';

import { AppState } from 'store/types';
import ActiveUser from 'store/Auth/models/activeUser';
import { UserAccount, UserAccountSettings } from 'store/Auth/models/userAccount';
import { Account, AccountSettings } from 'store/Account/models/account';
import { initialState } from './constants';
import { AuthState } from './types';
import { isAuthenticated as isAuthenticatedHelper } from 'store/Auth/userHelper';
import {
    dashboardChartGroups,
    dashboardChartGroupsMapping,
    dispersionChartsTypes
} from 'store/Dashboard/constants';
import { DashboardChartGroup, DashboardChartType } from '../Dashboard/types';

export const getAuthState = (state: AppState): AuthState => state.auth || initialState;

export const isAuthenticated = createSelector(
    getAuthState,
    (state: AuthState) => {
        return isAuthenticatedHelper(state.user);
    }
);

export const isInitialized = createSelector(
    getAuthState,
    (state: AuthState) => {
        return state.isInitialized;
    }
);

export const getUser = createSelector(
    getAuthState,
    (state: AuthState) => state.user
);

export const getActiveAccount = createSelector(
    getUser,
    (user: ActiveUser) => user.active_account
);

export const getActiveAccountId = createSelector(
    getActiveAccount,
    (activeAccount: UserAccount) => activeAccount.id
);

export const getUserAccount = createSelector(
    getActiveAccount,
    (state: UserAccount) => state.account
);

export const getUserAccountType = createSelector(
    getUserAccount,
    (state: Account) => state.type
);

export const getUserAccountSettings = createSelector(
    getActiveAccount,
    (state: UserAccount) => state.user_account_settings
);

export const getRememberSelectedFund = createSelector(
    getUserAccountSettings,
    (settings: UserAccountSettings) => settings.remember_selected_funds
);

export const getSelectedDispersionChartsTypes = createSelector(
    getUserAccountSettings,
    (settings: UserAccountSettings) => {
        if (settings.hasOwnProperty('dispersion_charts') && settings.dispersion_charts) {
            return dispersionChartsTypes.filter(
                period => (settings.dispersion_charts || []).indexOf(period) >= 0
            );
        }

        return dispersionChartsTypes;
    }
);

export const getSelectedDashboardChartsGroups = createSelector(
    getUserAccountSettings,
    (settings: UserAccountSettings) => {
        if (settings.hasOwnProperty('dashboard_charts') && settings.dashboard_charts) {
            const enabledCharts = settings.dashboard_charts;

            return dashboardChartGroups.filter(group => {
                const groupCharts = dashboardChartGroupsMapping[group];

                const unchecked = groupCharts
                    .find(groupChart => enabledCharts.indexOf(groupChart) === -1);

                return !unchecked;
            });
        }

        return dashboardChartGroups;
    }
);

export const getSelectedDashboardChartsTypes = createSelector(
    getSelectedDashboardChartsGroups,
    (groups: DashboardChartGroup[]) => {
        let types: DashboardChartType[] = [];
        groups.forEach(group => types = types.concat(dashboardChartGroupsMapping[group]));
        return types;
    }
);

export const getAccount = createSelector(
    getActiveAccount,
    (state: UserAccount) => state.account
);

export const getAccountSettings = createSelector(
    getAccount,
    (account: Account) => account.account_settings
);

export const getEnableShowDataSource = createSelector(
    getAccountSettings,
    (accountSettings: AccountSettings) => accountSettings.display_fund_data_source
);
