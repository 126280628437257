import * as React from 'react';
import { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import * as classNames from 'classnames';
import * as moment from 'moment';

import { formatText, isHideColumn, replaceParam } from 'helpers/tableHelper';
import GlobalHelper from 'helpers/globalHelper';
import { getSortClass } from 'helpers/sortHelper';

import Input from 'components/Shared/Ui/Input';
import UiNumericInput from 'components/Shared/Ui/NumericInput';
import UiDatePicker from 'components/Shared/Ui/DatePicker';
import UiSelect from 'components/Shared/Ui/Select';
import UiPopover from 'components/Shared/Ui/Popover';

import { PopoverFunction, SelectItem, TableHeader } from 'store/types';
import {
    DECIMAL_SEPARATOR, DEFAULT_DATE_FORMAT, DEFAULT_VALUE_DASH, THOUSAND_SEPARATOR,
    Alignment, CellTypeChoices, DATE_FORMATS, FormatTypeChoices
} from 'store/constants';
import { defaultColumnMinWidth } from './constants';
import { FiltersState } from 'store/Filters/types';

const styles = require('./DataTable.scss');
const columnStyles = require('./DataColumn.scss');

interface Props {
    header: TableHeader;
    visibleHeaders?: TableHeader[];
    row: any;
    rowIdx: number;
    isEditing?: boolean;
    hasSorting?: boolean;
    currentFilter?: FiltersState;
    handleChange?: (value: string) => void;
    handleOnBlurInput?: (row: any, rowIdx: number, field: string) => void;
    handleOnFocusInput?: () => void;

    setDefaultMinWidth?: boolean;
    className?: string;
    style?: Partial<CSSProperties>;
}

export const formatCellTypeValue = (fieldValue: any, header: TableHeader, row: any): React.ReactNode => {
    let value = fieldValue;

    switch (header.cellType) {
        case CellTypeChoices.Link:
            const linkTemplate = header.linkTemplate ? header.linkTemplate : '';
            const route = replaceParam(linkTemplate, row);
            value = (
                <NavLink to={route}><span className="input-label">{fieldValue}</span></NavLink>
            );
            break;
        case CellTypeChoices.Custom:
            value = header.transformer && header.transformer(row);
            break;
        case CellTypeChoices.Numeric:
            const classNameForNumeric = classNames({
                'input-label': true
            });
            const finalValue = formatText(fieldValue, header.formatType, DEFAULT_VALUE_DASH);

            value = (
                <span className={classNameForNumeric}>{finalValue}</span>
            );
            break;
        case CellTypeChoices.Date:
            const classNameForDate = classNames({
                'input-label': true
            });

            value = (
                <span className={classNameForDate}>{fieldValue || DEFAULT_VALUE_DASH}</span>
            );
            break;
        case CellTypeChoices.Select: {
            const choices = header.options || [];
            const foundChoiceValue = choices.find((choice: SelectItem) => choice.value === fieldValue);
            value = (
                <span className="input-label">
                    {foundChoiceValue && foundChoiceValue.label || fieldValue || DEFAULT_VALUE_DASH}
                </span>
            );
            break;
        }
        case CellTypeChoices.Text:
        default:
            const className = classNames({
                'input-label': true,
                [GlobalHelper.getStatusClass(fieldValue)]: true
            });
            value = (
                <span className={className}>{fieldValue}</span>
            );
            break;
    }

    return value;
};

export const formatEditableCellTypeValue = (
    fieldValue: any,
    header: TableHeader,
    row: any,
    rowIdx: number,
    handleCellChange: (value: string | null) => void,
    handleOnBlurInput?: () => void,
    handleOnFocusInput?: () => void,
): any => {
    let value = fieldValue;
    const blocked = (header.isCellDisabled && header.isCellDisabled(row, rowIdx));
    const disabled = header.disabled || blocked || false;
    const invalid = (header.isCellInvalid && header.isCellInvalid(row, rowIdx)) || false;

    const inputClassNames = classNames({
        'input-label': true,
        [columnStyles.disabled]: disabled,
        [columnStyles.invalid]: invalid,
        [columnStyles.blocked]: blocked,
    });

    switch (header.cellType) {
        case CellTypeChoices.Select:
            let options: SelectItem[] = header.options ? header.options : [];
            if (header.options && header.params && header.params.hasOwnProperty('optionsFunc')) {
                options = header.params.optionsFunc(header.options, header.name, row[header.params.field]);
            }
            value = (
                <div className={inputClassNames}>
                    <UiSelect
                        placeholder={header.placeholder ? header.placeholder : `version`}
                        options={options}
                        disabled={disabled}
                        value={fieldValue}
                        clearable={false}
                        handleClick={handleOnFocusInput}
                        handleChange={(item: SelectItem) => handleCellChange(item ? item.value : null)}
                    />
                </div>
            );
            break;
        case CellTypeChoices.Numeric:
            let thousandSeparator: boolean | string = THOUSAND_SEPARATOR;
            let decimalSeparator: boolean | string = DECIMAL_SEPARATOR;
            let suffix: string | undefined;

            if (header.params && header.params.suffix) {
                suffix = header.params.suffix;
            }

            value = (
                <div className={inputClassNames}>
                    <UiNumericInput
                        disabled={disabled}
                        value={fieldValue}
                        handleFocus={handleOnFocusInput}
                        handleOnBlurInput={handleOnBlurInput}
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                        handleChange={handleCellChange}
                        suffix={suffix}
                    />
                </div>
            );
            break;
        case CellTypeChoices.Date:
            const dateHandleChange = (date: moment.Moment) => {
                handleCellChange(date ? date.format(DEFAULT_DATE_FORMAT) : '');
            };

            const customFormat = header.formatType
                ? DATE_FORMATS[header.formatType]
                : DATE_FORMATS[FormatTypeChoices.DefaultDateFormat];
            if (header.formatType && fieldValue) {
                // format date with default format to avoid deprecation warning of moment.js library
                fieldValue = moment(fieldValue, customFormat).isValid()
                    ? moment(fieldValue, customFormat).format(DATE_FORMATS[FormatTypeChoices.DefaultDateFormat])
                    : null;
            }

            value = (
                <div className={inputClassNames}>
                    <UiDatePicker
                        disabled={disabled}
                        date={moment(fieldValue).isValid() ? moment(fieldValue) : null}
                        displayFormat={customFormat}
                        id={`date-${row.id}`}
                        handleChange={dateHandleChange}
                        handleFocus={handleOnFocusInput}
                        handleBlur={handleOnBlurInput}
                        customInputIcon={true}
                        material={true}
                        showClearDate={false}
                        placeholder={``}
                        keepOpenOnDateSelect={true}
                        anchorDirection={`right`}
                    />
                </div>
            );
            break;
        case CellTypeChoices.Text:
        default:
            value = (
                <div className={inputClassNames}>
                    <Input
                        disabled={disabled}
                        type="text"
                        value={fieldValue}
                        handleBlur={handleOnBlurInput}
                        handleChange={event => handleCellChange(event)}
                        className={styles.editableInputCell}
                    />
                </div>
            );
            break;
    }

    return value;
};

class DataColumn extends React.PureComponent<Props> {
    handleOnBlur = () => {
        if (this.props.handleOnBlurInput) {
            const {row, rowIdx, header} = this.props;
            this.props.handleOnBlurInput(row, rowIdx, header.name);
        }
    }

    render() {
        const {
            row, header, rowIdx, isEditing, handleChange, hasSorting,
            currentFilter, visibleHeaders, handleOnFocusInput,
            style, className = '', setDefaultMinWidth = false
        } = this.props;

        if (isHideColumn(visibleHeaders, header)) {
            return null;
        }

        const columnStyle: CSSProperties = {
            ...style
        };

        if (header.minWidth || (style && style.minWidth)) {
            columnStyle.minWidth = header.minWidth || (style && style.minWidth);
        } else if (setDefaultMinWidth) {
            columnStyle.minWidth = defaultColumnMinWidth;
        }

        const item = GlobalHelper.getValueFromObject(row, header.name);
        const fieldValue = formatText(item, header.formatType, header.defaultValue);
        let cellValue;

        if (!!isEditing && handleChange
            && (header.excludeEditField && !header.excludeEditField(row) || !header.excludeEditField)
        ) {
            cellValue = formatEditableCellTypeValue(
                fieldValue, header, row, rowIdx,
                handleChange, this.handleOnBlur, handleOnFocusInput
            );
        } else if (header.editable && header.hasEditing && header.hasEditing(row) && handleChange) {
            cellValue = formatEditableCellTypeValue(
                fieldValue, header, row, rowIdx,
                handleChange, this.handleOnBlur, handleOnFocusInput
            );
        } else {
            cellValue = formatCellTypeValue(fieldValue, header, row);
        }

        const colSpan = header.getColSpan ? header.getColSpan(row) : 1;
        const rowSpan = header.getRowSpan ? header.getRowSpan(row) : 1;
        const popoverEnabled = !!(header.popover && (header.isPopoverVisible && header.isPopoverVisible(row, rowIdx)));

        let classes: any = {
            [header.class]: !!header.class,
            [className]: !!className,
            [columnStyles.requestedField]: header.requested && !fieldValue,
            [columnStyles['align-center']]: !!header.valueAlignment && header.valueAlignment === Alignment.Center,
            [columnStyles['align-right']]: !!header.valueAlignment && header.valueAlignment === Alignment.Right,
            [columnStyles['align-left']]: !!header.valueAlignment && header.valueAlignment === Alignment.Left
                || !header.valueAlignment,
            is_sorted: !!hasSorting && !!getSortClass(header.name, currentFilter && currentFilter.ordering),
            [header.alias && columnStyles[header.alias]]: !!header.alias,
            [columnStyles['with-popover']]: popoverEnabled && !!header.popover,
        };

        if (header.getCustomCellStyles) {
            const customStyles = header.getCustomCellStyles(row);
            customStyles.forEach((cellStyle: string) => {
                classes[cellStyle] = true;
            });

        }

        const fullClassNames = classNames(classes);
        const columnId = `column-${rowIdx}-${header.name}`;

        let popoverBody: React.ReactNode | string = '';
        if (header.popover && typeof header.popover === 'function') {
            popoverBody = (header.popover as PopoverFunction)(row);
        } else if (header.popover) {
            popoverBody = header.popover;
        }

        return (
            <td className={fullClassNames} style={columnStyle} colSpan={colSpan} rowSpan={rowSpan}>
                <UiPopover
                    id={columnId}
                    body={popoverBody}
                    enabled={popoverEnabled}
                >
                    {cellValue}
                </UiPopover>
            </td>
        );
    }
}

export default DataColumn;
