import CreditApiHelper, { API_DASHBOARD_REQUESTOR } from 'helpers/creditApiHelper';
import { ApiHelper } from 'helpers/apiHelper';
import { FiltersState } from 'store/Filters/types';
import { dashboardDeniedGetParams, defaultDeniedGetParams, donutDeniedGetParams } from 'store/constants';

export const getPerformanceReport = async (filter: FiltersState) => {
    const params = ApiHelper.getClearParams(filter, donutDeniedGetParams);
    return CreditApiHelper.doRequest(
        `${API_DASHBOARD_REQUESTOR}/reporting-performance/`,
        'get',
        {params}
    );
};

export const getAlertsReport = async (filter: FiltersState) => {
    const params = ApiHelper.getClearParams(filter, donutDeniedGetParams);
    return CreditApiHelper.doRequest(
        `${API_DASHBOARD_REQUESTOR}/alert/`,
        'get',
        {params}
    );
};

export const getData = async (filter: FiltersState) => {
    const {search, ...newFilter} = filter;
    const params = ApiHelper.getClearParams(newFilter, dashboardDeniedGetParams);
    return CreditApiHelper.doRequest(
        `${API_DASHBOARD_REQUESTOR}/`,
        'get',
        {params}
    );
};

export const getDatasetData = async (filter: FiltersState) => {
    const filteredParams = filter ? ApiHelper.getClearParams(filter, defaultDeniedGetParams) : {};
    return CreditApiHelper.doRequest(
        `${API_DASHBOARD_REQUESTOR}/dataset-data/`,
        'get',
        {
            params: filteredParams
        }
    );
};

export const getDispersionData = async (filter: FiltersState) => {
    const filteredParams = filter ? ApiHelper.getClearParams(filter, defaultDeniedGetParams) : {};
    return CreditApiHelper.doRequest(
        `${API_DASHBOARD_REQUESTOR}/dispersion/`,
        'get',
        {
            params: filteredParams
        }
    );
};
