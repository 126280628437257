import * as React from 'react';
import { debounce, cloneDeep } from 'lodash';

import { TableHeader } from 'store/types';
import { prepareFilterState } from 'store/Filters/helpers';
import { FiltersState } from 'store/Filters/types';
import {
    DEFAULT_CHECKED_VALUES, RESET_OFFSET_AND_SCROLL, SEARCH_DEBOUNCE_INTERVAL
} from 'store/constants';
import { SetFiltersAction } from 'store/Filters/actions';

interface FilterDecorator {
    applyFilter: (filterObject: FiltersState) => any;
    setFilters: (filterName: string, payload: FiltersState) => SetFiltersAction;
    setHeaders?: any;
    currentFilter: FiltersState;
}

export interface FilterDecoratorChange {
    (data: FiltersState, useApplyFilter?: boolean): void;
}

export default function Filter(WrappedComponent: any, defaultState: any,
                               filterName: string, savingFilters: boolean = false,
                               applyOnDidMount: boolean = true) {
    const defaultStateCopy = cloneDeep(defaultState);
    return class extends React.PureComponent<FilterDecorator> {
        state = {
            ...defaultStateCopy
        };

        getDataAfterDebounce = debounce(
            (filter: FiltersState) => {
                this.props.applyFilter(prepareFilterState(filter));
            },
            SEARCH_DEBOUNCE_INTERVAL
        );

        componentDidMount() {
            this.setState({filter: {...this.state.filter, ...this.props.currentFilter}, currentPage: 1}, () => {
                if (applyOnDidMount) {
                    this.props.applyFilter(prepareFilterState(this.state.filter));
                }
                if (this.props.setFilters) {
                    const applyFilters = { ...this.state.filter };
                    this.props.setFilters(filterName, applyFilters);
                }
            });
        }

        componentWillUnmount () {
            if (!savingFilters) {
                const newFilter = {
                    ...defaultStateCopy.filter,
                    ...RESET_OFFSET_AND_SCROLL,
                    ...DEFAULT_CHECKED_VALUES,
                };
                this.props.setFilters(filterName, newFilter);
            }
        }

        render() {
            return (
                <div>
                    <WrappedComponent
                        {...this.props}
                        {...this.state}
                        handleColumns={this.handleColumns}
                        handlePageChange={this.handlePageChange}
                        handleChange={this.handleChange}
                    />
                </div>
            );
        }

        handleColumns = (newValues: TableHeader[]): void => {
            this.props.setHeaders(this.state.headerType, newValues);
        }

        handlePageChange = (limit: number, offset: number): void => {
            const filterParam = {limit, offset};
            const currentPage = offset / limit + 1;
            this.setState({filter: {...this.state.filter, ...filterParam}, currentPage }, () => {
                const applyFilters = { ...this.state.filter, ...filterParam };
                this.props.setFilters(filterName, applyFilters);
                this.getDataAfterDebounce(applyFilters);
            });
        }

        handleChange: FilterDecoratorChange = (data: FiltersState, useApplyFilters: boolean = true): void => {
            let newData = {...this.props.currentFilter, ...data};

            // when the scroller exists
            if (data.scroller) {
                newData.offset = data.offset + this.props.currentFilter.offset;
                newData.scroller = true;
            } else if (useApplyFilters) {
                newData = {
                    ...newData,
                    ...RESET_OFFSET_AND_SCROLL
                };
            }

            this.setState({filter: {...this.props.currentFilter, ...newData}, currentPage: 1}, () => {
                const applyFilters = { ...this.props.currentFilter, ...newData, currentPage: 1 };
                this.props.setFilters(filterName, applyFilters);
                if (useApplyFilters) {
                    this.getDataAfterDebounce(applyFilters);
                }
            });
        }

    };
}
