import * as React from 'react';
import NumberFormat from 'react-number-format';

interface NumericInputType {
    disabled?: boolean;
    className?: any;
    value?: string;
    handleFocus?: () => void;
    handleOnBlurInput?: () => void;
    handleChange:  any;
    fixedDecimalScale?: boolean;
    thousandSeparator?: boolean | string;
    decimalSeparator?: boolean | string;
    decimalScale?: number;
    suffix?: string;
}

const styles = require('./Input.scss');
const UiNumericInput: React.FC<NumericInputType> = ({
    value, decimalSeparator, decimalScale, handleChange, handleFocus, thousandSeparator, handleOnBlurInput,
    fixedDecimalScale, suffix, disabled = false
}) => {
    const onBlurHandler = () => {
        if (handleOnBlurInput) {
            handleOnBlurInput();
        }
    };

    const onFocusHandler = () => {
        if (handleFocus) {
            handleFocus();
        }
    };

    const onClickHandler = (event) => {
        event.target.select();
    };

    const onValueChangeHandler = (values, e) => {
        if (e.type === 'change') {
            const resultValue = values.value === '-0.00' ? '-0' : values.floatValue;
            handleChange(resultValue);
        }
    };

    return (
        <span className="react-numeric-input">
            <NumberFormat
                className={styles.ui_input}
                disabled={disabled}
                value={value}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                onClick={onClickHandler}
                onValueChange={onValueChangeHandler}
                thousandSeparator={thousandSeparator || undefined}
                decimalSeparator={decimalSeparator || undefined}
                decimalScale={decimalSeparator ? decimalScale : 0}
                fixedDecimalScale={fixedDecimalScale}
                suffix={suffix}
                placeholder={suffix || ''}
            />
        </span>
    );
};

UiNumericInput.defaultProps = {
    decimalScale: 2,
    decimalSeparator: true,
    fixedDecimalScale: true,
};

export default UiNumericInput;
