import { put, all, takeLatest, select } from 'redux-saga/effects';
import { isEqual } from 'lodash';

import * as userApi from 'services/user';
import * as diligenceUserApi from 'services/diligence/user';
import {
    GET_USER, GetUserAction, INITIALIZE_RESET_PASSWORD, InitializeResetPasswordAction, RESET_PASSWORD,
    GET_DILIGENCE_USER, GetDiligenceUserAction,
    ResetPasswordAction,
    SWITCH_ACCOUNT,
    SwitchAccountAction,
    UpdateUserAccountSettingsAction, UPDATE_USER_ACCOUNT_SETTINGS, UpdateAccountSettingsAction, UPDATE_ACCOUNT_SETTINGS
} from './actions';
import { doneActionFail, doneActionSuccess, initAction } from '../Actions/actionCreators';
import { changeUserAccountSettings, setAccountSettings, setUser } from './actionCreators';

import { SWITCH_ACCOUNT_FAIL_MESSAGE } from './constants';
import { isAuthenticated } from 'store/Auth/userHelper';
import { isInternal, isAdhoc } from 'store/User/helpers';
import { getUserAccountSettings } from './selectors';

function* getUser(action: GetUserAction) {
    try {
        yield put(initAction(action.type));

        let user = yield userApi.getInfo();

        if (isAuthenticated(user) && !isInternal(user) && !isAdhoc(user)) {
            try {
                const response = yield diligenceUserApi.getDiligenceUserInfo();
                const diligenceUser = response.data;
                if (diligenceUser && diligenceUser.accounts && diligenceUser.accounts.length > 0) {
                    user.has_diligence_account = true;
                }
            } catch (diligenceErrors) {}
        }
        yield put(setUser(user));

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* getDiligenceUser(action: GetDiligenceUserAction) {
    try {
        yield put(initAction(action.type));
        yield diligenceUserApi.getDiligenceUserInfo();

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* switchAccount(action: SwitchAccountAction) {
    try {
        yield put(initAction(action.type));

        yield userApi.switchAccount(action.relationId);
        window.location.replace(`/dashboard`);
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, SWITCH_ACCOUNT_FAIL_MESSAGE));
    }
}

function* initializeResetPassword(action: InitializeResetPasswordAction) {
    try {
        yield put(initAction(action.type));
        yield userApi.initializeResetPassword(action.email);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* resetPassword(action: ResetPasswordAction) {
    try {
        yield put(initAction(action.type));
        yield userApi.resetPassword(action.password, action.token, action.resetKey);
        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* updateUserAccountSettings(action: UpdateUserAccountSettingsAction) {
    try {
        yield put(initAction(action.type));

        const prevSettings = yield select(getUserAccountSettings),
            isChanged = !isEqual(prevSettings, action.userAccountSettings);

        if (isChanged) {
            if (action.waitResponse) {
                const settings = yield userApi.updateUserAccountSettings(
                    action.userAccountSettings
                );
                yield put(changeUserAccountSettings(settings));
            } else {
                userApi.updateUserAccountSettings(
                    action.userAccountSettings
                );
                yield put(changeUserAccountSettings(action.userAccountSettings));
            }
        }

        yield put(doneActionSuccess(action.type));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors));
    }
}

function* updateAccountSettings(action: UpdateAccountSettingsAction) {
    try {
        yield put(initAction(action.type));

        const response = yield userApi.updateAccountSettings(action.settings);
        yield put(setAccountSettings(response));
    } catch (errors) {
        yield put(doneActionFail(action.type, errors, errors));
    }
}

function* watchGetUser() {
    yield takeLatest(GET_USER, getUser);
}

function* watchGetDiligenceUser() {
    yield takeLatest(GET_DILIGENCE_USER, getDiligenceUser);
}

function* watchSwitchAccount() {
    yield takeLatest(SWITCH_ACCOUNT, switchAccount);
}

function* watchInitializeResetPassword() {
    yield takeLatest(INITIALIZE_RESET_PASSWORD, initializeResetPassword);
}

function* watchResetPassword() {
    yield takeLatest(RESET_PASSWORD, resetPassword);
}

function* watchUpdateUserAccountSettings() {
    yield takeLatest(UPDATE_USER_ACCOUNT_SETTINGS, updateUserAccountSettings);
}

function* watchUpdateAccountSettings() {
    yield takeLatest(UPDATE_ACCOUNT_SETTINGS, updateAccountSettings);
}

export default function* root() {
    yield all([
        watchGetUser(),
        watchSwitchAccount(),
        watchInitializeResetPassword(),
        watchResetPassword(),
        watchUpdateUserAccountSettings(),
        watchUpdateAccountSettings(),
        watchGetDiligenceUser()
    ]);
}
