import * as React from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap';
import CenteredWindowLayout from '../Shared/Layouts/CenteredWindowLayout';
import { ActionInfo, ActionStatus } from '../../store/Actions/types';
import { RESET_PASSWORD_SUCCESS_MESSAGE } from '../../store/Auth/constants';

const styles = require('./ResetPasswordPage.scss');

interface Props {
    actionInfo?: ActionInfo;
    resetPassword: (password: string, confirmPassword: string) => any;
    confirmPasswordIsInvalid: boolean;
}

class ResetPasswordPage extends React.PureComponent<Props> {
    passwordRef: React.RefObject<HTMLInputElement>;
    confirmPasswordRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.passwordRef = React.createRef();
        this.confirmPasswordRef = React.createRef();
    }

    handleResetPassword = () => {
        if (this.passwordRef.current && this.confirmPasswordRef.current) {
            this.props.resetPassword(this.passwordRef.current.value, this.confirmPasswordRef.current.value);
        }
    }

    get resetAvailable() {
        const {actionInfo} = this.props;
        return (!actionInfo || [ActionStatus.InProgress, ActionStatus.Success].indexOf(actionInfo.status) === -1);
    }

    get isSuccess() {
        const {actionInfo} = this.props;
        return actionInfo && actionInfo.status === ActionStatus.Success;
    }

    get isFail() {
        const {actionInfo} = this.props;
        return actionInfo && actionInfo.status === ActionStatus.Fail;
    }

    render() {
        const {actionInfo, confirmPasswordIsInvalid} = this.props;
        return (
            <CenteredWindowLayout>
                <div className={styles.resetFormWrapper}>
                    { this.resetAvailable &&
                    <Form>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                id="password"
                                placeholder="Enter your password"
                                innerRef={this.passwordRef}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="confirm-password">Confirm Password</Label>
                            <Input
                                type="password"
                                id="confirm-password"
                                placeholder="Confirm your password"
                                invalid={confirmPasswordIsInvalid}
                                innerRef={this.confirmPasswordRef}
                            />
                            {confirmPasswordIsInvalid &&
                            <FormFeedback>Confirm password isn't equal to Password</FormFeedback>}
                        </FormGroup>

                        <Button
                            color=""
                            onClick={this.handleResetPassword}
                        >
                            Reset password
                        </Button>
                    </Form>
                    }
                    {this.isSuccess &&
                    <div>{RESET_PASSWORD_SUCCESS_MESSAGE}</div>
                    }
                    {this.isFail &&
                    <div>
                        {actionInfo && actionInfo.errors.map((error, idx) => (
                            <p className="text-danger" key={idx}>{error}</p>
                        ))}
                    </div>
                    }
                </div>
            </CenteredWindowLayout>
        );
    }
}

export default ResetPasswordPage;