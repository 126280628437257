import {
    SET_FILTERS, SET_IS_LOADING, CALL_APPLY_FILTERS, SET_CHECKED_IDS, CLEAR_CHECKED_IDS,
} from './actions';
import { FiltersState } from './types';

export const setFilters = (filterName: string, payload: FiltersState) => ({
    type: SET_FILTERS,
    filterName, payload
});

export const setIsLoading = (isLoading?: boolean) => ({
    type: SET_IS_LOADING,
    isLoading
});

export const callApplyFilters = (
    currentFilter: string,
    actionType: string,
    otherFilter: any = {},
    actionParams: Object = {}
) => ({
    type: CALL_APPLY_FILTERS,
    currentFilter,
    actionType,
    otherFilter,
    actionParams
});

export const setCheckedIds = (filterName: string, filter: FiltersState) => ({
    type: SET_CHECKED_IDS,
    filterName,
    filter,
});

export const clearChecked = (filterName: string) => ({
    type: CLEAR_CHECKED_IDS,
    filterName,
});
