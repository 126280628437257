import * as React from 'react';
import { CSSProperties } from 'react';
import * as classNames from 'classnames';

import { getSortClass, getSortValue } from 'helpers/sortHelper';
import { isHideColumn } from 'helpers/tableHelper';
import { TableHeader } from 'store/types';
import { HeaderWidthObject } from 'store/VisibleHeaders/types';

import { defaultColumnMinWidth } from './constants';
import { Alignment } from 'store/constants';
import Popover from 'components/Shared/Ui/Popover';
import DeviceHelper from 'helpers/deviceHelper';

const styles = require('./DataColumn.scss');

interface Props {
    header: TableHeader;
    visibleHeaders?: TableHeader[];
    hasSorting: boolean;
    currentFilter?: any;
    handleClick?: any;
    handleClickDispatcher?: any;
    handleSetWidthHeaders?: (headerObject: HeaderWidthObject) => void;

    width?: number;
    className?: string;
    style?: Partial<CSSProperties>;
    isMainHeader?: boolean;
}

class HeaderColumn extends React.PureComponent<Props> {
    static defaultProps = {
        style: {},
        className: ''
    };

    headerElement: HTMLElement = HTMLElement.constructor('th');

    private oldWidth = 0;

    get orderingName() {
        const {header} = this.props;
        return header.orderingName || header.name;
    }

    updateWidth = () => {
        const { header, handleSetWidthHeaders } = this.props;
        if (
            handleSetWidthHeaders && this.headerElement.clientWidth &&
            (this.oldWidth !== this.headerElement.clientWidth)
        ) {
            this.oldWidth = this.headerElement.clientWidth;
            handleSetWidthHeaders({
                name: header.name,
                width: this.headerElement.clientWidth,
                active: header.active,
            });
        }
    }

    componentDidMount() {
        setTimeout(this.updateWidth, 200);

        (window as Window).addEventListener('resize', this.updateWidth);
    }

    componentWillUnmount() {
        (window as Window).removeEventListener('resize', this.updateWidth);
    }

    render () {
        const {
            header, hasSorting, currentFilter, visibleHeaders, width, style, className
        } = this.props;

        if (isHideColumn(visibleHeaders, header)) {
            return null;
        }

        const title = this.renderTitle(),
            thClass = header.alias ? styles[header.alias] : '';

        let headerStyle: CSSProperties = {
            ...this.props.style,
            minWidth: header.minWidth || (style && style.minWidth) || defaultColumnMinWidth
        };

        if (width) {
            headerStyle.width = width;
        }

        let classes: {[key: string]: boolean} = {
            [header.class]: !!header.class,
            [thClass]: true,
            [styles['align-center']]: !!header.headerAlignment && header.headerAlignment === Alignment.Center
                || !header.headerAlignment,
            [styles['align-right']]: !!header.headerAlignment && header.headerAlignment === Alignment.Right,
            [styles['align-left']]: !!header.headerAlignment && header.headerAlignment === Alignment.Left,
        };

        let onClick;
        if (hasSorting && !header.excludeSorting) {
            classes = {
                ...classes,
                'with-sorting': true,
                [getSortClass(this.orderingName, currentFilter.ordering, )]: true,
            };
            onClick = this.handleSortingClick;
        } else {
            onClick = this.handleSort;
        }

        return (
            <th
                ref={(element) => {
                    if (element) {
                        this.headerElement = element;
                        this.updateWidth();
                    }
                }}
                style={headerStyle}
                className={`${className} ${classNames(classes)}`}
                onClick={onClick}
            >
                {title}
            </th>
        );
    }

    private renderTitle(): React.ReactNode | React.ReactNode[] {
        const {header} = this.props,
            title = header.title;

        if (typeof title === 'string') {
            if (title.indexOf('\n') !== -1) {
                const lastKey = title.split('\n').length - 1;
                return title.split('\n').map((item, key) => {
                    if (lastKey === key) {
                        return <span key={key}>{item}</span>;
                    }
                    return <div key={key}>{item}</div>;
                });
            } else if (header.requested) {
                return title.split('\n').map((item, key) => {
                    return (
                        <div key={key} className={'requested-item-wrapper'}>
                            <Popover
                                id={`popover-${header.name}-${this.props.isMainHeader ? 'main' : 'fake'}`}
                                body={`This field has been requested by the requestor`}
                                className={'requested-item-popover'}
                                enabled={!DeviceHelper.isTouchSupported()}
                            >
                                <span className={'requested-item'}>{item} *</span>
                            </Popover>
                        </div>
                    );
                });
            } else {
                return title.split('\n').map((item, key) => <span key={key}>{item}</span>);
            }
        } else {
            return title;
        }
    }

    private handleSort = () => {
        const {header, handleClickDispatcher} = this.props;
        if (header.handleClickDispatcher && handleClickDispatcher) {
            handleClickDispatcher(header.handleClickDispatcher);
        }
    }

    private handleSortingClick = () => {
        const {handleClick, currentFilter} = this.props;
        this.handleSort();
        return handleClick({ordering: getSortValue(currentFilter.ordering, this.orderingName)});
    }
}

export default HeaderColumn;
