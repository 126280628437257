import * as React from 'react';
import memoize from 'memoize-one';

import ButtonPrimary from 'components/Shared/Ui/Buttons/ButtonPrimary';
import Modal from 'components/Shared/Modals/Modal';

import { TableHeader } from 'store/types';
import Checkbox from 'components/Shared/Ui/Checkbox';
import ButtonDefault from 'components/Shared/Ui/Buttons/ButtonDefault';
import HeaderHelper from 'helpers/headerHelper';
import GlobalHelper from 'helpers/globalHelper';
import { SetPublishedDatasetsTemporaryHeadersAction } from 'store/DataManager/actions';

interface Props {
    temporaryHeaders: TableHeader[];
    setPublishedDatasetsTemporaryHeaders: (temporaryHeaders: TableHeader[]) =>
        SetPublishedDatasetsTemporaryHeadersAction;
    isOpen: boolean;
    headers: TableHeader[];
    handleColumns: (column: any) => void;
    closeModal: () => any;
    modalParams: any;
}

export default class PublishedDatasetColumnsModal extends React.Component<Props> {
    navHeaders = [
        'published_ct_dataset.beginning_balance',
        'published_ct_dataset.ending_balance',
        'published_ct_dataset.subscriptions',
        'published_ct_dataset.redemptions',
        'published_ct_dataset.income',
        'published_ct_dataset.nav_per_share',
        'published_ct_dataset.known_future_redemptions',
    ];
    performanceHeaders = [
        'published_ct_dataset.perf_daily',
        'published_ct_dataset.perf_mtd',
        'published_ct_dataset.perf_weekly',
        'published_ct_dataset.perf_qtd',
        'published_ct_dataset.perf_month',
        'published_ct_dataset.perf_ytd',
    ];
    otherHeaders = [
        'received_at',
        'status',
    ];

    getButtons = memoize((handleUpdate, handleCloseModal) => [
        (<ButtonPrimary buttonLarge={true} handleClick={handleUpdate}>Update</ButtonPrimary>),
        (<ButtonDefault buttonLarge={true} inverse={true} handleClick={handleCloseModal}>Cancel</ButtonDefault>)
    ]);

    componentDidMount () {
        this.props.setPublishedDatasetsTemporaryHeaders(this.props.headers);
    }

    handleUpdate = () => {
        this.props.handleColumns(this.props.temporaryHeaders);
        this.props.closeModal();
    }

    handleCloseModal = () => {
        this.props.handleColumns(this.props.headers);
        this.props.setPublishedDatasetsTemporaryHeaders(this.props.headers);
        this.props.closeModal();
    }

    handleColumns = (column: any) => {
        const newColumns = this.props.temporaryHeaders.map((item: any) => {
            let answer = item;
            if (item.name === column.name) {
                answer = {
                    ...item,
                    ...column
                };
            }

            return answer;
        });
        this.props.setPublishedDatasetsTemporaryHeaders(newColumns);
    }

    render () {
        const {isOpen} = this.props;
        const headers = HeaderHelper.clearColumn(this.props.temporaryHeaders, this.navHeaders);
        const navHeaders = headers.map((item: any, idx: number) => {
            return (
                <div key={idx} className="col-md-6">
                    <Checkbox
                        idx={`nav-${idx}`}
                        className="aligned-checkboxes"
                        checked={item.active}
                        handleChange={(event) => this.handleColumns({ active: !item.active, name: item.name })}
                    >
                        {GlobalHelper.replaceElemInString(item.extraTitle)}
                    </Checkbox>
                </div>
            );
        });
        const perfHeaders = HeaderHelper.clearColumn(this.props.temporaryHeaders, this.performanceHeaders);
        const performanceHeaders = perfHeaders.map((item: any, idx: number) => {
            return (
                <div key={idx} className="col-md-6">
                    <Checkbox
                        idx={`perf-${idx}`}
                        className="aligned-checkboxes"
                        checked={item.active}
                        handleChange={(event) => this.handleColumns({ active: !item.active, name: item.name })}
                    >
                        {GlobalHelper.replaceElemInString(item.extraTitle)}
                    </Checkbox>
                </div>
            );
        });
        const othHeaders = HeaderHelper.clearColumn(this.props.temporaryHeaders, this.otherHeaders);
        const otherHeaders = othHeaders.map((item: any, idx: number) => {
            return (
                <div key={idx} className="col-md-6">
                    <Checkbox
                        idx={`other-${idx}`}
                        className="aligned-checkboxes"
                        checked={item.active}
                        handleChange={(event) => this.handleColumns({ active: !item.active, name: item.name })}
                    >
                        {GlobalHelper.replaceElemInString(item.extraTitle)}
                    </Checkbox>
                </div>
            );
        });

        const body = (
            <div className="publ-datasets-show-hide-modal-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-title">NAV</div>
                    </div>
                    {navHeaders}
                    <div className="col-md-12">
                        <div className="block-title">Performance</div>
                    </div>
                    {performanceHeaders}
                    <div className="col-md-12">
                        <div className="block-title">Other</div>
                    </div>
                    {otherHeaders}
                </div>
            </div>
        );

        return (
            <Modal
                isOpen={isOpen}
                toggle={this.handleCloseModal}
                title="Show/hide Columns"
                body={body}
                buttons={this.getButtons(this.handleUpdate, this.handleCloseModal)}
            />
        );
    }
}