import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import * as classNames from 'classnames';

import { ButtonTypes } from './ButtonTypes';

const styles = require('./ButtonPrimary.scss');
const ButtonPrimary: React.FC<ButtonTypes> = (
    {children, className, size, inverse, disabled, handleClick, buttonLarge}
) => {
    const btnClass = classNames({
        'ui-btn': true,
        'ui-btn-lg': buttonLarge,
        [className]: !!className,
        [styles.button_primary]: !!styles.button_primary,
        [styles.button_primary_inverse]: inverse,
    });

    return (
        <Button
            className={btnClass}
            size={size}
            disabled={disabled}
            onClick={() => handleClick && handleClick()}
        >
            {children}
        </Button>
    );
};

ButtonPrimary.defaultProps = {
    buttonLarge: false,
    size: 'md',
    disabled: false,
};

export default ButtonPrimary;
