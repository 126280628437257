import { Id } from 'store/types';
import { Moment } from 'moment';
import { FundModel } from 'store/Fund/models/fund';

export type RulesetPropertyType = string | number | Moment;

export enum RulesetType {
    None = 'none',
    Isda = 'isda',
    Fx = 'fx',
    MarginLoan = 'margin_loan',
    Pb = 'pb',
    PbMargin = 'pb_margin',
}

export enum EffectiveDateType {
    Rolling = 'rolling',
}

export enum RulesetStatus {
    Active = 'active',
    Inactive = 'inactive',
    Incomplete = 'incomplete',
}

export enum RulePeriod {
    OneMonth = 'one_month',
    ThreeMonths = 'three_months',
    SixMonths = 'six_months',
    TwelveMonths = 'twelve_months',
    ITD = 'itd',
    Other = 'other',
}

export enum RuleType {
    StartingBalance = 'starting_balance',
    PeakBalance = 'peak_balance',
    Perf = 'perf',
    Floor = 'floor'
}

export enum RuleExclusion {
    None = 'none',
    Redemptions = 'redemptions',
    NetFlow = 'net_flow'
}

export interface FieldValidation {
    touched?: boolean;
    valid?: boolean;
    disabled?: boolean;
    validValues?: any[];
}

export interface Rule {
    id?: Id;
    period?: RulePeriod;
    threshold?: number;
    type?: RuleType;
    amount?: number;
    exclusions?: RuleExclusion;
    perf_share?: number;
}

export type RuleValidation = {
    [key in keyof Rule]: FieldValidation;
} & {
    // all fields are valid
    valid?: boolean;
    // all fields are valid or untouched
    validOrUntouched?: boolean;
};

export interface Version {
    id: Id;
    date: string;
    version: number;
    current: boolean;
    label: string;
}

export interface Target {
    search?: string;
    firm_ids?: Id[];
    credit_officer?: string[];
    product?: string[];
    strategy?: string[];
    portfolio_ids?: Id[];
    internal_ids?: string[];
    checked_all?: boolean;
    fund_ids?: Id[];
}

export interface Ruleset {
    id?: Id;
    name?: string;
    entity_type?: RulesetEntityType;
    type?: RulesetType;
    eff_start_date?: EffectiveDateType | Moment | string;
    eff_end_date?: EffectiveDateType | Moment | string;
    number_of_rules?: number;
    counterparty?: string;
    status?: RulesetStatus;
    versions?: Version[];
    changed_since_last_publish?: boolean;

    rules?: Rule[];
    target_data?: Target;
    permissions?: RulesetPermissions;
}

export interface RulesetValidation {
    valid?: boolean;
    validOrUntouched?: boolean;
    rules?: RuleValidation[];
}

export interface RulesetsState {
    list: Ruleset[];
    count: number;
    permissions: RulesetListPermissions;
    edit: Ruleset;
    editValidation: RulesetValidation;
    targetFundList: {
        list: FundModel[],
        count: number;
        firmCount: number;
    };
}

export interface BackendFilterObject {
    id: Id;
    name: string;
}

export enum RulesetEntityType {
    UnpublishedRuleset = 'unpublished_ruleset',
    PublishedRuleset = 'published_ruleset'
}

export interface RulesetListPermissions {
    can_bulk_upload_rulesets: boolean;
    can_create_ruleset: boolean;
}

export interface RulesetPermissions {
    can_delete_ruleset: boolean;
    can_edit_ruleset: boolean;
    can_publish_ruleset: boolean;
}
