import { BulkUploadProgress, SocketActions, UploadType, UploadAction } from './types';
import {
    HIDE_GLOBAL_PROGRESS,
    START_BULK_UPLOAD,
    GET_FULL_UPLOAD_PROGRESS,
    SET_FULL_UPLOAD_PROGRESS,
    UPDATE_UPLOAD_PROGRESS,
    GET_UPLOAD_HISTORY,
    SET_UPLOAD_HISTORY,
    SET_CANCEL_IMPORT_EXPORT,
    DOWNLOAD_EXPORT_FILE,
    CLEAN_LOCAL_STORAGE,
    UPDATE_UPLOAD_PROGRESS_DEBOUNCE,
    START_UPLOADING,
    END_UPLOADING
} from './actions';
import { Id } from '../types';

export const startBulkUpload = (name: string) => ({
    type: START_BULK_UPLOAD,
    name
});

export const hideGlobalProgress = (id: Id) => ({
    type: HIDE_GLOBAL_PROGRESS,
    id
});

export const getFullUploadProgress = () => ({type: GET_FULL_UPLOAD_PROGRESS});

export const setFullUploadProgress = (progresses: BulkUploadProgress[]) => ({
    type: SET_FULL_UPLOAD_PROGRESS,
    progresses
});

export const updateUploadProgress = (progress: BulkUploadProgress) => ({
    type: UPDATE_UPLOAD_PROGRESS,
    progress
});

export const updateUploadProgressDebounce = (progress: BulkUploadProgress) => ({
    type: UPDATE_UPLOAD_PROGRESS_DEBOUNCE,
    progress
});

export const getUploadHistory = (uploadType: UploadType, filter: any = {}) => ({
    type: GET_UPLOAD_HISTORY,
    action: UploadAction.IMPORT,
    uploadType, filter
});

export const setUploadHistory = (uploadType: UploadType, history: BulkUploadProgress[]) => ({
    type: SET_UPLOAD_HISTORY,
    uploadType, history
});

export const setCancelImportExport = (fileId: Id) => ({
    type: SET_CANCEL_IMPORT_EXPORT,
    fileId
});

export const startDownload = (link: string, uploadProgress: BulkUploadProgress) => ({
    type: DOWNLOAD_EXPORT_FILE,
    link, uploadProgress
});

export const cleanLocalStorage = () => ({
    type: CLEAN_LOCAL_STORAGE
});

export const startUploading = (progress: BulkUploadProgress) => ({
    type: START_UPLOADING,
    progress
});

export const endUploading = (progress: BulkUploadProgress) => ({
    type: END_UPLOADING,
    progress
});

export const getBySocketMessageData = (data: any) => {
    switch (data.action) {
        case SocketActions.InProgressFull:
            return setFullUploadProgress(data.items);
        case SocketActions.InProgressUpdate:
            return updateUploadProgressDebounce(data.item);
        case SocketActions.History:
            return setUploadHistory(data.type, data.items);
        case SocketActions.Cancel:
            return setCancelImportExport(data.fileId);
        case SocketActions.FileDownload:
            return startDownload(data.download_link, data.item);
        default:
            throw new Error('Invalid action');
    }
};