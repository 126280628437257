import ActiveUser from 'store/Auth/models/activeUser';
import { isRequestor } from 'store/User/helpers';
import { FilterNames } from 'store/Filters/types';
import { DefaultStateRulesetResponderTarget, DefaultStateRulesetRequestorTarget } from 'store/constants';

export const getFilterName = (user: ActiveUser) => {
    return isRequestor(user)
        ? FilterNames.rulesetRequestorTargetFundList
        : FilterNames.rulesetResponderTargetFundList;
};

export const getFilterDefault = (user: ActiveUser) => {
    return isRequestor(user)
        ? DefaultStateRulesetRequestorTarget.filter
        : DefaultStateRulesetResponderTarget.filter;
};
