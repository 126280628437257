export const initialState = {
    funds: {
        list: [],
        count: 0,
    },
    peerGroups: {
        list: [],
        count: 0,
    }
};
