import * as React from 'react';
import * as classNames from 'classnames';

interface CheckboxType {
    idx?: number | string;
    value?: string;
    className?: string;
    handleChange: (event: any) => void;
    checked: boolean;
}

interface State {
    isChecked: boolean;
}

class Checkbox extends React.PureComponent<CheckboxType, State> {

    state = {
        isChecked: this.props.checked
    };

    componentDidUpdate(prevProps: CheckboxType) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({
                isChecked: this.props.checked
            });
        }
    }

    handleToggle = (e) => {
        this.setState({isChecked: !this.state.isChecked}, () => {
            this.props.handleChange(e);
        });
    }

    render() {
        const {idx, value, className, children} = this.props;
        const index = `item-${idx}`;
        const ClassNames = classNames({
            [`${className}`]: !!className,
        });
        return (
            <div className={ClassNames}>
                <input
                    type="checkbox"
                    value={value}
                    id={index}
                    data-id={idx}
                    onChange={this.handleToggle}
                    checked={this.state.isChecked}
                />
                <label htmlFor={index}><span>{children}</span></label>
            </div>
        );
    }
}

export default Checkbox;