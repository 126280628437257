import * as React from 'react';
import { ReactElement } from 'react';
import { CSSProperties } from 'react';
import * as classNames from 'classnames';

import { Id, TableHeader, TableRowAction } from 'store/types';
import { FiltersState } from 'store/Filters/types';

import { checkboxColumnWidth, defaultColumnMinWidth } from './constants';
import Checkbox from '../Ui/Checkbox';
import DataColumn from './DataColumn';
import RowActionsColumn from './RowActionsColumn';
import { ID_FOR_NEW_ROW } from 'store/Fund/constants';

const styles = require('./DataTable.scss');

export interface DataTableRowProps {
    // 'any' because we don't know exact type of row
    row: any;
    headers: TableHeader[];
    visibleHeaders?: TableHeader[];
    className: string;
    countFixedColumns?: number;
    currentFilter?: FiltersState;
    withCheckboxes?: boolean;
    disabledCheckboxes?: boolean;
    isGridLoading?: boolean;
    isLoading?: boolean;
    scrollableHeader?: boolean;
    hasSorting: boolean;
    checkedAll?: boolean;
    checkedIds?: Id[];
    chooseRow?: (id: Id) => void;
    rowIdx: number;
    rowsCount: number;
    editingRowNumber?: number;
    editingRow?: any;
    handleColumnChange?: (value: string, name: string, row: number) => void;
    handleOnBlurInput?: (row: any, owIdx: number, field: string) => void;
    handleOnFocusInput?: (id: Id) => void;
    expandableContent?: (row: any) => ReactElement<HTMLTableRowElement> | null;
    rowActions?: TableRowAction[];
}

class DataTableRow extends React.PureComponent<DataTableRowProps> {
    static defaultProps = {
        expandableContent: () => null,
        countFixedColumns: 0,
        scrollableHeader: false,
        withCheckboxes: false,
    };

    private keySalt: number;

    constructor(props: DataTableRowProps) {
        super(props);

        this.keySalt = Math.random();
    }

    render() {
        const {
            row, headers, visibleHeaders, currentFilter,
            className, withCheckboxes, isGridLoading, isLoading, hasSorting, scrollableHeader,
            checkedIds, chooseRow, rowIdx, editingRow, rowActions, disabledCheckboxes
        } = this.props;

        const expandableContent = this.props.expandableContent || DataTableRow.defaultProps.expandableContent,
            lastFixedColumnIndex = this.lastFixedColumnIndex;

        const fixedStyle: CSSProperties = {
            position: 'absolute',
            top: 'auto',
            left: 0,
            display: 'flex',
            alignItems: 'start',
        };
        let offset = withCheckboxes ? checkboxColumnWidth : 0;

        return (
            <>
            <tr className={className}>
                {
                    withCheckboxes && (!isGridLoading || isLoading) &&
                    <td
                        scope="row"
                        className="checkboxHeader"
                        style={(this.hasFixedColumns
                                ? {
                                    ...fixedStyle,
                                    width: checkboxColumnWidth,
                                    minWidth: checkboxColumnWidth,
                                    maxWidth: checkboxColumnWidth,
                                }
                                : {}
                        )}
                    >
                        {
                            checkedIds && chooseRow && !(this.isEditedRow && row.id === ID_FOR_NEW_ROW) &&
                            (
                                <Checkbox
                                    idx={rowIdx + '-' + this.keySalt}
                                    value={`row-${rowIdx}`}
                                    checked={this.checkboxValue}
                                    handleChange={this.handleChooseRow}
                                    disabled={!!disabledCheckboxes}
                                />
                            )
                        }
                    </td>
                }
                {
                    (!isGridLoading || isLoading) && headers.map((header, idx) => {
                        // Cell isn't visible
                        if (header.isVisible && !header.isVisible(row)) {
                            return null;
                        }

                        fixedStyle.left = offset;
                        const columnWidth = header.minWidth || defaultColumnMinWidth;
                        offset += columnWidth;

                        return (
                            <DataColumn
                                key={idx + '-' + this.keySalt}
                                style={(idx <= lastFixedColumnIndex
                                        ? {
                                            ...fixedStyle,
                                            width: columnWidth,
                                            minWidth: columnWidth,
                                            maxWidth: columnWidth,
                                        }
                                        : {}
                                )}
                                className={
                                    classNames({
                                        [styles.lastFixedColumn]: (idx === lastFixedColumnIndex)
                                    })
                                }
                                rowIdx={rowIdx}
                                setDefaultMinWidth={scrollableHeader}
                                hasSorting={hasSorting}
                                currentFilter={currentFilter}
                                visibleHeaders={visibleHeaders}
                                header={header}
                                row={!this.isEditedRow ? row : editingRow}
                                isEditing={this.isEditedRow}
                                handleOnFocusInput={this.handleOnFocusInput}
                                handleOnBlurInput={this.props.handleOnBlurInput}
                                handleChange={(value: string) => this.handleColumnChange(header,  value)}
                            />
                        );
                    })
                }
                {
                    (!isGridLoading || isLoading) && rowActions && rowActions.length > 0 &&
                    <td className={`action-cell`}>
                        {
                            rowActions &&
                            rowActions.map((action: TableRowAction, actionId: number) => {
                                const hide = action.isVisible
                                && !action.isVisible(row, rowIdx)
                                    ? (!!action.hideClass
                                        ? (typeof action.hideClass === 'function'
                                            ? action.hideClass(row)
                                            : action.hideClass)
                                        : 'hidden-action')
                                    : '';
                                return <RowActionsColumn
                                    key={actionId + '-' + this.keySalt}
                                    idx={actionId}
                                    action={action}
                                    row={row}
                                    rowIdx={rowIdx}
                                    className={'action-items ' + hide}
                                    isEditing={this.isEditedRow}
                                />;
                            })
                        }
                    </td>
                }
            </tr>
            {row.expanded && expandableContent(row)}
            </>
        );
    }

    private get checkboxValue() {
        const {row, checkedAll, checkedIds} = this.props;

        if (checkedIds) {
            if (checkedAll) {
                return checkedIds.indexOf(row.id) <= -1;
            } else {
                return checkedIds.indexOf(row.id) > -1;
            }
        }

        return false;
    }

    private get isEditedRow() {
        const {rowIdx, editingRowNumber} = this.props;
        return editingRowNumber === rowIdx;
    }

    private get hasFixedColumns() {
        const {countFixedColumns} = this.props;
        return countFixedColumns && countFixedColumns > 0;
}

    private handleChooseRow = () => {
        const {row, chooseRow} = this.props;

        if (chooseRow) {
            chooseRow(row.id);
        }
    }

    private handleColumnChange = (header: TableHeader, value: string) => {
        const {handleColumnChange, rowIdx} = this.props;
        if (handleColumnChange) {
            handleColumnChange(value, header.name, rowIdx);
        }
    }

    private handleOnFocusInput = () => {
        const {row, handleOnFocusInput} = this.props;
        if (handleOnFocusInput) {
            handleOnFocusInput(row.id);
        }
    }

    private get lastFixedColumnIndex() {
        const {withCheckboxes, countFixedColumns} = this.props;

        return (countFixedColumns || 0) - 1 - +(withCheckboxes || 0);
    }
}

export default DataTableRow;
