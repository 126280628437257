import { APPLY_FILTER as portfoliosFilter, GET_NOT_FOUND_SOLUTIONS, GET_UPLOADED_ROWS } from '../Portfolio/actions';
import { APPLY_FILTER as firmsFilter, GET_FIRM } from '../Firm/actions';
import {
    APPLY_DATASETS_FILTER, APPLY_FILTER as fundsFilter, GET_FUND, GET_FUND_ATTRIBUTES,
    GET_FUND_DATASET_HISTORY
} from '../Fund/actions';
import { APPLY_FILTER as accountsFilter } from '../Account/actions';
import {
    APPLY_FILTER as datasetsFilter, APPLY_FILTER_ACL_USERS, APPLY_FILTER_DATASET_DETAIL, GET_REQUESTORS,
    APPLY_FILTER_INTERNAL_DATA
} from 'store/DataManager/actions';
import {
    APPLY_FILTER as creditTransparencyFilter,
    APPLY_FILTER_MANAGER_SCREEN as activeRequestsFilter,
} from '../Requests/actions';
import { UPDATE_PROFILE, UPDATE_PASSWORD } from '../Diligence/User/actions';
import {
    CHANGE_REMEMBER_COOKIE,
    DISABLE_TWO_FACTOR_AUTHENTICATION,
    GENERATE_TFA_SECRET,
    GET_DILIGENCE_USER,
    SET_TFA_SECRET, VERIFY_SETUP_CODE
} from '../Diligence/Auth/actions';
import { GET_REQUESTS, APPLY_FILTER } from '../Diligence/DiligenceRequest/actions';
import { GET_CHART_DATA, GET_DASHBOARD_DATA, GET_STATISTIC_DONUT_REPORT } from '../Dashboard/actions';

export const initialState = {};

export const actionsThatNeedLoader: string[] = [
    firmsFilter,
    portfoliosFilter,
    fundsFilter,
    accountsFilter,
    activeRequestsFilter,
    creditTransparencyFilter,
    GET_FUND,
    GET_FIRM,
    GET_REQUESTORS,
    datasetsFilter,
    APPLY_FILTER_DATASET_DETAIL,
    APPLY_FILTER_ACL_USERS,
    GET_NOT_FOUND_SOLUTIONS,
    GET_UPLOADED_ROWS,
    GET_FUND_DATASET_HISTORY,
    APPLY_DATASETS_FILTER,
    GET_FUND_ATTRIBUTES,
    UPDATE_PROFILE,
    UPDATE_PASSWORD,
    GET_DILIGENCE_USER,
    SET_TFA_SECRET,
    DISABLE_TWO_FACTOR_AUTHENTICATION,
    GENERATE_TFA_SECRET,
    VERIFY_SETUP_CODE,
    CHANGE_REMEMBER_COOKIE,
    GET_REQUESTS,
    APPLY_FILTER,

    // Dashboard actions
    GET_STATISTIC_DONUT_REPORT,
    GET_CHART_DATA,
    GET_DASHBOARD_DATA,

    APPLY_FILTER_INTERNAL_DATA,
];
