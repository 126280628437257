import * as React from 'react';
import { AclUser } from '../../../store/DataManager/models/aclUser';
import { ACL_ROLES_TITLES } from '../../../store/DataManager/constants';
import { AclRole } from '../../../store/DataManager/types';

interface Props {
    aclUser: AclUser;
}

const styles = require('./AclUserRoleEditor.scss');

const AclUserRoleView: React.FC<Props> = ({aclUser}) => (
    <div>
        <span>{ACL_ROLES_TITLES[aclUser.role]}</span>
        {
            aclUser.role === AclRole.NoAccess &&
            (
                <div className={styles.requestorDiv}>
                    requested by {aclUser.requested_by}
                </div>
            )
        }
    </div>
);

export default AclUserRoleView;
