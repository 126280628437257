import * as React from 'react';

import { FundAttributeModel } from 'store/Fund/models/fund';
import { DEFAULT_VALUE_DASH } from 'store/constants';

const styles = require('./AttributesCell.scss');

interface Props {
    fields: FundAttributeModel[];
    attributeType: string;
}

const AttributesCell: React.FC<Props> = ({fields, attributeType}) => {
    const fieldValues = fields
        .filter((field: FundAttributeModel) => field.attribute_type === attributeType)
        .map((field: FundAttributeModel) => field.value);

    const output = fieldValues.length ? fieldValues.join(', ') : DEFAULT_VALUE_DASH;

    return (
        <div className={styles.attrValue}>
            {output}
        </div>
    );
};

export default AttributesCell;
