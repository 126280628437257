import * as React from 'react';

import { API_BULK_IMPORT_URL } from 'helpers/creditApiHelper';
import { Id } from 'store/types';
import { closeWhiteIcon } from 'store/constants';

const styles = require('./BottomProgressBar.scss');

interface Props {
    hide: () => void;
    uploadTypeName: string;
    itemNamePlural: string;
    action: string;
    fileId: Id;
}

class ProcessedWithErrorsMessage extends React.PureComponent<Props> {
    render() {
        const {itemNamePlural, uploadTypeName, action, fileId} = this.props;

        return (
            <div className="progressBlock">
                <div className={styles['progress-area']}>
                    <div className={styles['completed-process-text']}>
                        <span className={styles['danger-message']}>
                            {`${uploadTypeName} ${action} Incomplete`}
                        </span>
                        .
                        <span>
                            <a
                                className={styles['link-to-file']}
                                href={`${API_BULK_IMPORT_URL}/files/${fileId}/error/`}
                                type={`download`}
                            >
                                Click here
                            </a>
                            to download the invalid {itemNamePlural}.
                        </span>
                    </div>
                    <img
                        src={closeWhiteIcon}
                        className={styles['img-close']}
                        onClick={this.props.hide}
                    />
                </div>
            </div>
        );
    }
}

export default ProcessedWithErrorsMessage;
