import { combineReducers } from 'redux';

import { accountReducer } from './Account/reducer';
import { actionsReducer } from './Actions/reducer';
import { asyncFiltersChoicesReducer } from './AsyncFiltersChoices/reducer';
import { authReducer } from './Auth/reducer';
import { bulkUploadReducer } from './BulkUpload/reducer';
import { filtersReducer } from './Filters/reducer';
import { firmReducer } from './Firm/reducer';
import { fundReducer } from './Fund/reducer';
import { modalsReducer } from './Modals/reducer';
import { portfolioReducer } from './Portfolio/reducer';
import { userReducer } from './User/reducer';
import { diligenceUserReducer } from './Diligence/User/reducer';
import { requestsReducer } from './Requests/reducer';
import { redirectReducer } from './Redirect/reducer';
import { dataManagerReducer } from './DataManager/reducer';
import { headersReducer } from './VisibleHeaders/reducer';
import { dashboardReducer } from './Dashboard/reducer';
import { alertsManagerReducer } from './AlertsManager/reducer';
import { profilesReducer } from './Profiles/reducer';
import { diligenceAuthReducer } from './Diligence/Auth/reducer';
import { diligenceDataManagerReducer } from './Diligence/DataManager/reducer';
import { diligenceRequestReducer } from './Diligence/DiligenceRequest/reducer';
import { diligenceFiltersReducer } from './Diligence/Filters/reducer';
import { diligenceFilterDropdownReducer } from './Diligence/FilterDropdown/reducer';
import { advancedFiltersModalReducer } from './AdvancedFilters/reducer';

export default combineReducers({
    account: accountReducer,
    actions: actionsReducer,
    asyncFiltersChoices: asyncFiltersChoicesReducer,
    auth: authReducer,
    bulkUpload: bulkUploadReducer,
    filters: filtersReducer,
    firms: firmReducer,
    funds: fundReducer,
    modals: modalsReducer,
    portfolios: portfolioReducer,
    requests: requestsReducer,
    dataManager: dataManagerReducer,
    user: userReducer,
    headers: headersReducer,
    redirect: redirectReducer,
    dashboard: dashboardReducer,
    alertsManager: alertsManagerReducer,
    profiles: profilesReducer,
    diligenceAuth: diligenceAuthReducer,
    diligenceUser: diligenceUserReducer,
    diligenceDataManager: diligenceDataManagerReducer,
    diligenceRequests: diligenceRequestReducer,
    diligenceFilters: diligenceFiltersReducer,
    diligenceFilterDropdown: diligenceFilterDropdownReducer,
    advancedFiltersModal: advancedFiltersModalReducer
});