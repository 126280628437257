import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { getUser } from  'store/Auth/actionCreators';
import WithPanelLayout from './Layouts/WithPanelLayout';
import InitializeResetPassword from './Auth/InitializeResetPassword';
import ResetPassword from './Auth/ResetPassword';
import ProtectedRoute from './Shared/ProtectedRoute';
import { Route } from 'react-router';

interface Props {
    getUser: () => void;
}

class App extends React.Component<Props> {
    componentDidMount() {
        this.props.getUser();
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/reset-password" exact={true} component={InitializeResetPassword} />
                    <Route path="/reset-password/:token/:resetKey" component={ResetPassword} />
                    <ProtectedRoute path="/" component={WithPanelLayout} />
                </Switch>
            </Router>
        );
    }
}

const mapDispatchToProps = {
    getUser,
};

export default connect(null, mapDispatchToProps)(App);
