import { Action } from 'redux';

import { FiltersState } from 'store/Filters/types';
import { Group, GroupListPermissions, GroupProperty } from './types';
import { Id, TableHeader } from 'store/types';
import { PeerGroup, TargetFund } from 'store/Fund/types';
import { Target } from 'store/AlertsManager/Rulesets/types';
import { FundModel } from 'store/Fund/models/fund';

export const GET_GROUPS = 'store/profiles/groups/getList';
export const SET_GROUPS = 'store/profiles/groups/setList';
export const SET_GROUPS_WITH_SCROLL = 'store/profiles/groups/setListWithScroll';
export const SET_EDITED_GROUP = 'store/profiles/groups/SetEditedGroup';
export const DELETE_GROUPS = 'store/profiles/groups/delete';

export const INIT_GROUP_CREATION = 'store/profiles/groups/initCreation';
export const INIT_GROUP_EDITING = 'store/profiles/groups/initEditing';
export const UPDATE_GROUP_PROPERTY = 'store/profiles/groups/updateProperty';
export const UPDATE_GROUP_TARGET = 'store/profiles/groups/updateTarget';
export const SAVE_GROUP = 'store/profiles/groups/save';

export const GET_TARGET_FUND_LIST = 'store/profiles/groups/getTargetFundList';
export const SET_TARGET_FUND_LIST = 'store/profiles/groups/setTargetFundList';
export const SET_TARGET_FUND_LIST_WITH_SCROLL = 'store/profiles/groups/setTargetFundListWithScroll';
export const SET_TARGET_TEMPORARY_HEADERS = 'store/profiles/groups/setTargetTemporaryHeaders';

export const GET_FUND_LIST_FOR_ADD_ITEMS_MODAL = 'store/profiles/groups/addItemsModal/getFundList';
export const GET_PEER_GROUP_LIST_FOR_ADD_ITEMS_MODAL = 'store/profiles/groups/addItemsModal/getPeerGroupList';
export const HANDLE_FUND_LIST_FROM_ADD_ITEMS_MODAL = 'store/profiles/groups/addItemsModal/handleFundList';
export const HANDLE_PEER_GROUP_LIST_FROM_ADD_ITEMS_MODAL = 'store/profiles/groups/addItemsModal/handlePeerGroupList';
export const ADD_FUND_LIST_TO_BENCHMARKS = 'store/profiles/groups/addFundListToBenchmarks';
export const ADD_PEER_GROUP_LIST_TO_BENCHMARKS = 'store/profiles/groups/addPeerGroupListToBenchmarks';
export const DELETE_ITEM_LIST_FROM_BENCHMARKS = 'store/profiles/groups/deleteItemListFromBenchmarks';
export const GET_GRID_CONFIGURATION = 'store/profiles/groups/getGridConfiguration';
export const SAVE_TARGET_GROUP_COLUMNS = 'store/profiles/groups/saveFundListColumns';
export const PUBLISH_GROUP = 'store/profiles/groups/publishGroup';
export const SELECT_VERSION = 'store/profiles/groups/selectVersion';

export interface GetGroupsAction extends Action {
    filter: FiltersState;
}

export interface SetGroupsAction extends Action {
    groups: Group[];
    count: number;
    permissions: GroupListPermissions;
    withScroll: boolean;
}

export interface SetEditedGroupAction extends Action {
    group: Partial<Group>;
}

export interface DeleteGroupsAction extends Action {
    ids: Id[];
    checkedAll: boolean;
}

export interface InitGroupCreationAction extends Action {}
export interface InitGroupEditingAction extends Action {
    id: Id;
}

export interface GetTargetFundListAction extends Action {
    entityType?: string;
    entityId?: Id;
    filter: FiltersState;
}

export interface SetTargetFundListAction extends Action {
    count: number;
    firmCount: number;
    list: TargetFund[];
}

export interface UpdateGroupPropertyAction extends Action {
    name: string;
    value: GroupProperty;
}

export interface UpdateGroupTargetAction extends Action {
    target: Target;
}

export interface SaveGroupAction extends Action {
    group: Group;
}

export interface SetTargetTemporaryHeadersAction extends Action {
    headers: TableHeader[];
}

export interface HandleFundListFromAddItemsModalAction extends Action {
    filterName: string;
}

export interface HandlePeerGroupListFromAddItemsModalAction extends Action {
    filterName: string;
}

export interface AddFundListToBenchmarksAction extends Action {
    funds: FundModel[];
}

export interface AddPeerGroupListToBenchmarksAction extends Action {
    groups: PeerGroup[];
}

export interface DeleteItemListFromBenchmarksAction extends Action {
    ids: Id[];
}

export interface PublishGroupAction extends Action {
    groupId: Id;
    id: Id[];
}

export interface SelectVersionAction extends Action {
    id: Id;
    version: number;
    isHistoryVersion: boolean;
}

export type ModifyGroupAction =
    | UpdateGroupPropertyAction
    | UpdateGroupTargetAction
    | AddFundListToBenchmarksAction
    | DeleteItemListFromBenchmarksAction;

export const ModifyGroupTypes = [
    UPDATE_GROUP_PROPERTY, UPDATE_GROUP_TARGET,
    ADD_FUND_LIST_TO_BENCHMARKS, DELETE_ITEM_LIST_FROM_BENCHMARKS,
    ADD_PEER_GROUP_LIST_TO_BENCHMARKS,
];

export type ProfilesGroupsAction =
    GetGroupsAction |
    SetGroupsAction |
    SetEditedGroupAction |
    DeleteGroupsAction |
    InitGroupCreationAction |
    InitGroupEditingAction |
    GetTargetFundListAction |
    SetTargetFundListAction |
    UpdateGroupPropertyAction |
    UpdateGroupTargetAction |
    SaveGroupAction |
    SetTargetTemporaryHeadersAction |
    HandleFundListFromAddItemsModalAction |
    HandlePeerGroupListFromAddItemsModalAction |
    AddFundListToBenchmarksAction |
    DeleteItemListFromBenchmarksAction |
    PublishGroupAction |
    SelectVersionAction;
