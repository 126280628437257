import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classNames from 'classnames';
import Select, { components } from 'react-select';
import { ActionMeta, ValueType } from 'react-select/lib/types';
import { OptionValue, SelectItem } from 'store/types';
import { MenuPortalProps } from 'react-select/lib/components/Menu';

const styles = require('./Select.scss');

export type OptionItem = SelectItem | null;

// create custom scroll event
const customEventName = 'scroll-for-select';
let customEvent = document.createEvent('Event');
customEvent.initEvent(customEventName, true, true);

class Menu extends React.PureComponent<MenuPortalProps<OptionItem>> {

    private scrollElement: Element | null = null;

    componentDidMount() {
        this.scrollElement = this.props.selectProps.selectDOM;
        if (this.scrollElement) {
            this.scrollElement.addEventListener(customEventName, this.handleScroll);
        }
    }

    componentWillUnmount() {
        if (this.scrollElement) {
            this.scrollElement.removeEventListener(customEventName, this.handleScroll);
        }
    }

    render() {
        return (
            <components.MenuPortal {...this.props}>
                {this.props.children}
            </components.MenuPortal>
        );
    }

    private handleScroll = () => {
        this.forceUpdate();
    }
}

interface Props {
    placeholder: string;
    value: ValueType<OptionValue>;
    options: OptionItem[];
    handleChange: (newValue: ValueType<OptionItem>, actionMeta?: ActionMeta) => void;
    handleClick?: () => void;
    clearable?: boolean;
    disabled?: boolean;
    className?: string;
    classNamePrefix?: string;
    defaultValue?: OptionItem;
    multi?: boolean;
    material?: boolean;
    customMenuPortalClass?: React.CSSProperties;
}

class UiSelect extends React.PureComponent<Props> {
    static defaultProps = {
        multi: false,
        className: 'react-select-container',
        classNamePrefix: 'alphapipe-select',
    };

    private selectDOM: Element | Text | null = null;
    private selectRef: React.RefObject<HTMLDivElement>;

    private static isOptionSelected = (option: OptionItem, selectValue: OptionItem[]) => (
        !!option && !!selectValue.find(
            (item: OptionItem) => !!item && option.value === item.value
        )
    )

    constructor(props: Props) {
        super(props);

        this.selectRef = React.createRef();
    }

    componentDidMount() {
        if (this.selectRef && this.selectRef.current) {
            this.selectDOM = ReactDOM.findDOMNode(this.selectRef.current);
        }
    }

    render() {
        const {
            value, placeholder, options, handleChange, clearable, material,
            disabled, handleClick, className, multi, classNamePrefix, defaultValue,
            customMenuPortalClass
        } = this.props;

        let ClassNames = {
            [styles.ui_select_material]: material,
            'ui-input--material': material,
        };
        if (className) {
            ClassNames[className] = !!className;
        }

        let customStyles = {};
        if (customMenuPortalClass) {
            customStyles = {
                menuPortal: (provided, state) => ({
                    ...provided,
                    ...state,
                    ...customMenuPortalClass,
                })
            };
        }

        return (
            <div ref={this.selectRef}>
                <Select
                    value={
                        !defaultValue &&
                        options.filter((option: SelectItem | null) => {
                            if (option) {
                                if (Array.isArray(value)) {
                                    return value.indexOf(option.value) !== -1;
                                } else {
                                    return option.value === value;
                                }
                            }
                            return option;
                        }) ||
                        undefined
                    }
                    getOptionLabel={this.getOptionLabel}
                    getOptionValue={this.getOptionValue}
                    isOptionSelected={UiSelect.isOptionSelected}
                    onChange={handleChange}
                    options={options}
                    isMulti={multi}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    onMenuOpen={handleClick}
                    isClearable={clearable}
                    delimiter=";"
                    menuPortalTarget={document.body}
                    components={this.selectRef && this.selectRef.current && {MenuPortal: Menu} || undefined}
                    selectDOM={this.selectRef && this.selectRef.current || null}
                    closeMenuOnScroll={this.closeMenuOnScroll}
                    className={classNames(ClassNames)}
                    classNamePrefix={classNamePrefix}
                    styles={customStyles}
                    defaultValue={defaultValue}
                />
            </div>
        );
    }

    private getOptionLabel = (option: OptionItem) => {
        return option && option.label || this.props.defaultValue && this.props.defaultValue.label || '';
    }

    private getOptionValue = (option: OptionItem) => {
        return option && option.value || this.props.defaultValue && this.props.defaultValue.value || '';
    }

    private closeMenuOnScroll = (evt: Event) => {
        if (this.selectDOM) {
            this.selectDOM.dispatchEvent(customEvent);
        }
        return false;
    }
}

export default UiSelect;
