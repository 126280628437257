import * as React from 'react';
import * as classnames from 'classnames';

import { searchIcon } from 'store/constants';
import Input from '../Ui/Input';

interface Props {
    disabled?: boolean;
    name?: string;
    placeholder?: string;
    value?: string;
    withLabel?: boolean;
    handleChange?: any;
    className?: string;

    handleToggle?: () => void;
}

interface State {
    filtersOpen: boolean;
}

const styles = require('./SearchFilter.scss');
class SearchFilter extends React.PureComponent<Props, State> {

    static defaultProps = {
        withLabel: false,
        disabled: false
    };

    render () {
        const { disabled, className, placeholder, name, handleChange, handleToggle } = this.props;
        let content: string | JSX.Element = '';

        const searchButtonClassNames = classnames({
            disabled: disabled
        });

        const inputValue = this.props.value !== undefined && this.props.value !== null ? this.props.value : '';

        if (placeholder && name && handleChange) {
            content = (
                <div className={styles.filter_search}>
                    <div className="row">
                        <div className={className || 'col-md-12'}>
                            {this.props.withLabel && <h5 className="label">{placeholder}</h5>}
                            <div className="form-group">
                                <div className="input-group">
                                    <Input
                                        disabled={disabled}
                                        className="form-control"
                                        type="text"
                                        value={inputValue}
                                        placeholder={placeholder}
                                        handleChange={(value) => handleChange({[name]: value})}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            className={`btn btn-default ${searchButtonClassNames}`}
                                            type="button"
                                            onClick={handleToggle}
                                        >
                                            <img
                                                src={searchIcon}
                                                alt="search"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return content;
    }
}

export default SearchFilter;
